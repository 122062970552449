import React from 'react';

import { useUnitPriceHanberiMakingRow } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/hooks/price/priceRow/useUnitPriceHanberiMakingRow';

import MakingOptionPriceRow, { MakingOptionPriceRowProps } from '../Common/MakingOptionPriceRow';

type Props = Omit<MakingOptionPriceRowProps, 'pricePropertyName' | 'cylMakingPrice'>;

/** 版べり再版 行コンポーネント */
const HanberiOptionPriceRow: React.VFC<Props> = (props) => {
  const { price } = useUnitPriceHanberiMakingRow();
  return (
    <MakingOptionPriceRow
      label={props.label}
      pricePropertyName='hanberiPrice'
      rowPropertyName={props.rowPropertyName}
      cylMakingPrice={price}
    />
  );
};

export default React.memo(HanberiOptionPriceRow);
