import { useCallback } from 'react';

/** レコードID発番Hooks */
export const useId = (): {
  generateId: () => number;
} => {
  const generateId = useCallback(() => {
    // 負の値の乱数でIDを発番する
    return -Math.ceil(Math.random() * 10 ** 16);
  }, []);

  return {
    generateId,
  };
};
