import BigNumber from 'bignumber.js';
import React, { ReactNodeArray, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FlexoOrderEditForm } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { usePriceCalculater } from '../../hooks/price/usePriceCalculater';
import { useFormSetState } from '../../hooks/useFormSetState';
import { EditNumericField } from '../Fields';
import { MakingNumericField } from './Field';
import { RowOne } from './Rows';

/**
 * Boxを中央揃えに
 */
const Box = styled(MuiBox)(() => ({
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'row',
  justifyContent: 'cneter',
  alignItems: 'center',
}));

export const SizeRow: React.FC<{ children: ReactNodeArray }> = ({
  children: [widthInput, widthUnit, grainInput, grainUnit],
}) => {
  return (
    <>
      <Box>
        <Box>
          <BodyLabel>巾</BodyLabel>
          <Box mx={1}>{widthInput}</Box>
          <Box width={'2em'}>{widthUnit}</Box>
        </Box>
        <Box mx={2}>
          <BodyLabel>×</BodyLabel>
        </Box>
        <Box>
          <BodyLabel>流</BodyLabel>
          <Box mx={1}>{grainInput}</Box>
          <Box width={'2em'}>{grainUnit}</Box>
        </Box>
      </Box>
    </>
  );
};

type Props = {
  propertyName: 'makingSizeMain' | 'makingSizeReverse';
};

/** 製版サイズ */
const MakingSize: React.VFC<Props> = ({ propertyName }) => {
  // コンテキスト
  const { control, setValue } = useFormContext<FlexoOrderEditForm>();
  const { afterFormSet } = useFormSetState();

  const { calcSurfaceArea } = usePriceCalculater();

  const [
    plateWidth,
    plateGrain,
    frontPageSizeWidth,
    frontPageSizeGrain,
    impositioningWidth,
    impositioningGrain,
  ] = useWatch({
    control,
    name: [
      `making.${propertyName}.plateWidth`,
      `making.${propertyName}.plateGrain`,
      `making.${propertyName}.frontPageSizeWidth`,
      `making.${propertyName}.frontPageSizeGrain`,
      `making.${propertyName}.impositioningWidth`,
      `making.${propertyName}.impositioningGrain`,
    ],
  });

  function roundToTwoDecimalPlaces(num: number): number {
    const numTimes = new BigNumber(num ?? 0).times(100).toNumber();
    const roundNum = Math.round(numTimes);
    if (roundNum == 0) {
      return 0;
    }
    return new BigNumber(roundNum).div(100).toNumber();
  }

  useEffect(() => {
    // 初期表示時は値セットを行わない
    if (!afterFormSet) return;

    // 刷版寸法 から表面積を自動計算
    if (plateWidth !== null && plateGrain !== null) {
      const plateArea = calcSurfaceArea(plateWidth, plateGrain);
      const roundPlateArea = roundToTwoDecimalPlaces(plateArea);
      setValue(`making.${propertyName}.plateArea`, roundPlateArea);
    }
  }, [plateWidth, plateGrain]);

  useEffect(() => {
    // 初期表示時は値セットを行わない
    if (!afterFormSet) return;

    // １面寸法 * 面付 から全面寸法 巾を自動計算
    if (frontPageSizeWidth !== null && impositioningWidth !== null) {
      const width = new BigNumber(frontPageSizeWidth ?? 0)
        .times(impositioningWidth ?? 0)
        .toNumber();
      const roundWidth = Math.round(width);
      setValue(`making.${propertyName}.overAllSizeWidth`, roundWidth);
    }
  }, [frontPageSizeWidth, impositioningWidth]);

  useEffect(() => {
    // 初期表示時は値セットを行わない
    if (!afterFormSet) return;

    // １面寸法 * 面付 から全面寸法	流を自動計算
    if (frontPageSizeGrain !== null && impositioningGrain !== null) {
      const grain = new BigNumber(frontPageSizeGrain ?? 0)
        .times(impositioningGrain ?? 0)
        .toNumber();
      const roundGrain = roundToTwoDecimalPlaces(grain);
      setValue(`making.${propertyName}.overAllSizeGrain`, roundGrain);
    }
  }, [frontPageSizeGrain, impositioningGrain]);

  return (
    <>
      <RowOne>
        <HeadLabel>リピート長</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <Controller
            name={`making.${propertyName}.repeatLength`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>mm</BodyLabel>
        </Box>
      </RowOne>
      <RowOne>
        <HeadLabel>刷版寸法</HeadLabel>
        <Box>
          <SizeRow>
            <Controller
              name={`making.${propertyName}.plateWidth`}
              control={control}
              render={({ field }) => (
                <MakingNumericField
                  max={9999.99}
                  min={0}
                  decimalDigits={2}
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  sx={{ width: '6em' }}
                />
              )}
            />
            <BodyLabel>mm</BodyLabel>
            <Controller
              name={`making.${propertyName}.plateGrain`}
              control={control}
              render={({ field }) => (
                <MakingNumericField
                  max={9999.99}
                  min={0}
                  decimalDigits={2}
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  sx={{ width: '6em' }}
                />
              )}
            />
            <BodyLabel>mm</BodyLabel>
          </SizeRow>
          <Box ml={6}>
            <BodyLabel>表面積：</BodyLabel>
            <Box mx={1}>
              <Controller
                name={`making.${propertyName}.plateArea`}
                control={control}
                render={({ field }) => (
                  <MakingNumericField
                    max={99999999}
                    min={0}
                    decimalDigits={0}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    sx={{ width: '7em' }}
                    labelWidth={'shrink'}
                  />
                )}
              />
            </Box>
            <BodyLabel>cm2</BodyLabel>
          </Box>
        </Box>
      </RowOne>
      <RowOne>
        <HeadLabel>仕上寸法</HeadLabel>
        <SizeRow>
          <Controller
            name={`making.${propertyName}.finishSizeWidth`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>mm</BodyLabel>
          <Controller
            name={`making.${propertyName}.finishSizeGrain`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>mm</BodyLabel>
        </SizeRow>
      </RowOne>
      <RowOne>
        <HeadLabel>バリヤー比</HeadLabel>
        <SizeRow>
          <Controller
            name={`making.${propertyName}.barrierRatioWidth`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>％</BodyLabel>
          <Controller
            name={`making.${propertyName}.barrierRatioGrain`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>％</BodyLabel>
        </SizeRow>
      </RowOne>
      <RowOne>
        <HeadLabel>１面寸法</HeadLabel>
        <SizeRow>
          <Controller
            name={`making.${propertyName}.frontPageSizeWidth`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>mm</BodyLabel>
          <Controller
            name={`making.${propertyName}.frontPageSizeGrain`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>mm</BodyLabel>
        </SizeRow>
      </RowOne>
      <RowOne>
        <HeadLabel>面付</HeadLabel>
        <SizeRow>
          <Controller
            name={`making.${propertyName}.impositioningWidth`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '3em' }}
              />
            )}
          />
          <BodyLabel>面</BodyLabel>
          <Controller
            name={`making.${propertyName}.impositioningGrain`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '3em' }}
              />
            )}
          />
          <BodyLabel>面</BodyLabel>
        </SizeRow>
      </RowOne>
      <RowOne>
        <HeadLabel>全面寸法</HeadLabel>
        <SizeRow>
          <Controller
            name={`making.${propertyName}.overAllSizeWidth`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>mm</BodyLabel>
          <Controller
            name={`making.${propertyName}.overAllSizeGrain`}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <BodyLabel>mm</BodyLabel>
        </SizeRow>
      </RowOne>
    </>
  );
};

export default React.memo(MakingSize);
