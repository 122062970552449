/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { ResponseMessage } from 'api/types/common/apiResponse';

import useApiClient from './useApiClient';
import Encoding from 'encoding-japanese';

function getFileName(response: AxiosResponse<any>) {
  const contentDisposition = response.headers['content-disposition'];
  let fileName = contentDisposition.substring(
    contentDisposition.indexOf("''") + 2,
    contentDisposition.length,
  );
  //デコードするとスペースが"+"になるのでスペースへ置換
  fileName = decodeURI(fileName).replace(/\+/g, ' ');

  return fileName;
}

const useFileDownload = (
  messageHandler: (messages: ResponseMessage[]) => void = () => undefined,
): {
  downloadCsv: (url: string, httpMethod?: 'GET' | 'POST', data?: any, isShiftJis?: boolean) => void;
  loading: boolean;
} => {
  const { apiClient, loading } = useApiClient(messageHandler);

  const downloadFile = (
    type: string,
    url: string,
    httpMethod: 'GET' | 'POST' = 'GET',
    data?: any,
    isShiftJis?: boolean,
  ) => {
    (async () => {
      try {
        const response = await (() => {
          switch (httpMethod) {
            case 'GET':
              return apiClient.axios.get(url, data);
            case 'POST':
              return apiClient.axios.post(url, data);
          }
        })();
        
        const fileData = response.data;
        if (fileData) {
          
          let tmpFileData;

          if(isShiftJis)
          {
            // Shift-JISの場合は文字コードを変換する
            const strFileData = Buffer.from(fileData).toString();
            const unicodeList = [];

            for(let i = 0; i < strFileData.length; i+=1) 
            {
                unicodeList.push(strFileData.charCodeAt(i));
            } 
            const conData = Encoding.convert(unicodeList, 'SJIS', 'UNICODE');
            tmpFileData = new Uint8Array(conData);
          } 
          else
          {
            tmpFileData = fileData;
          } 
          
          const blob = new Blob([tmpFileData], {
            type: type,
          });
          const fileName = getFileName(response);
          saveAs(blob, fileName);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  const downloadCsv = (url: string, httpMethod?: 'GET' | 'POST', data?: any, isShiftJis?: boolean) => {
    downloadFile('text/csv', url, httpMethod, data, isShiftJis);
  };

  return { downloadCsv, loading };
};
export default useFileDownload;
