import React from 'react';

import { Heading } from '../../Heading';
import MakingOptionPriceRowHeader from '../Common/MakingOptionPriceRowHeader';
import MakingPriceRowHeader from '../Common/MakingPriceRowHeader';
import NormalOptionPriceRow from './NormalOptionPriceRow';
import NormalPriceRow from './NormalPriceRow';

/** 価格情報 フルコース、 改版、増版 コンポーネント*/
const NormalPrice: React.VFC = () => {
  return (
    <>
      <Heading>フルコース、 改版、増版</Heading>

      <MakingPriceRowHeader />
      <NormalPriceRow label='新管' type='New' />
      <NormalPriceRow label='旧版' type='Old' />
      <NormalPriceRow label='支給' type='Supply' />
      <NormalPriceRow label='銅上' type='Copper' />

      <MakingOptionPriceRowHeader />
      <NormalOptionPriceRow label='カラー製版' rowPropertyName='colorCylMaking' />
      <NormalOptionPriceRow label='半調子' rowPropertyName='hanchoushi' />
      <NormalOptionPriceRow label='調子' rowPropertyName='choushi' />
      <NormalOptionPriceRow label='連続' rowPropertyName='renzoku' />
      <NormalOptionPriceRow
        label='追加腐食'
        rowPropertyName='tuikafusyoku'
        minimumPriceKey='additionalCorrosionMinimum9'
      />
    </>
  );
};

export default React.memo(NormalPrice);
