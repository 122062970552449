import { PageState, OrderEditTabIndex } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/types';

import { OtherOrderEditState } from './types';

/** その他受注入力のストアモデルの初期値 */
export const InitialOtherOrderEditState: OtherOrderEditState = {
  pageState: PageState.Edit,
  tabIndex: OrderEditTabIndex.Basis,
  data: null,
};
