import * as React from 'react';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { WorkPlanCountInfoRes } from 'api/types/portalTop/portal';

import { Typography, Heading } from 'components/parts/atoms';

type Props = {
  workPlanCountInfo: WorkPlanCountInfoRes | null;
};

const Container = styled(Box)(() => ({}));

const countText = (text: string, count: number | undefined, unit: string) => {
  let numText = '';
  if (count) {
    if (unit === '%') {
      // 小数点第3位を四捨五入、style: 'percent'を使用すると * 100されてしまうので100で割る。
      const roundedCount = Number((count).toFixed(2))  / 100;
      numText = roundedCount.toLocaleString('ja', { style: 'percent', minimumFractionDigits: 2 });
      numText = numText.replace(unit, '');
    } else {
      numText = count?.toString();
    }
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='body1' noWrap>{`${text}：`}</Typography>
      <Typography variant='body1' noWrap>{`${numText} ${unit}`}</Typography>
    </Box>
  );
};

const TodayMakingPlan: React.VFC<Props> = ({ workPlanCountInfo }) => {
  if (!workPlanCountInfo) return <></>;
  return (
    <>
      <Container>
        <Heading>{'本日の製版予定本数'}</Heading>
        <Box sx={{ display: 'flex' }}>
          <Grid container spacing={2} sx={{ width: 'auto' }}>
            <Grid item xs={5}>
              {countText('入荷予定本数', workPlanCountInfo.arrivalPlanCount, '本')}
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs={5}>
              {countText('製版予定本数', workPlanCountInfo.cylMakingPlanCount, '本')}
            </Grid>
            <Grid item xs={5}>
              {countText('出荷予定本数', workPlanCountInfo.shipmentPlanCount, '本')}
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs={5}>
              {countText('画像出稿予定点数', workPlanCountInfo.imageSyukkouPlanCount, '点')}
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          {countText('再損版率 (通年)', workPlanCountInfo.yearlyLossandRemakeCylRate, '%')}
          <Box mx={2}></Box>
          {countText('(今月)', workPlanCountInfo.monthlyLossandRemakeCylRate, '%')}
        </Box>
      </Container>
    </>
  );
};

export default TodayMakingPlan;
