import { ja } from 'date-fns/locale';
import * as React from 'react';

import {
  DateRangePicker as MuiDateRangePicker,
  DateRangePickerProps as MuiDateRangePickerProps,
  LocalizationProvider,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box } from '@mui/material';

import { TextField } from '..';

export type DateRangePickerProps<TDate> = Pick<
  MuiDateRangePickerProps<TDate>,
  'value' | 'onChange'
> & {
  inputWidth?: string | number;
};

const DateRangePicker: React.VFC<DateRangePickerProps<Date>> = ({ inputWidth, ...props }) => {
  const defaultProps: Pick<
    MuiDateRangePickerProps<Date>,
    'renderInput' | 'startText' | 'endText' | 'inputFormat' | 'mask'
  > = {
    renderInput: (startProps, endProps) => (
      <React.Fragment>
        <TextField {...startProps} sx={{ width: inputWidth }} />
        <Box sx={{ mx: 2 }}> ～ </Box>
        <TextField {...endProps} sx={{ width: inputWidth }} />
      </React.Fragment>
    ),
    startText: null,
    endText: null,
    inputFormat: 'yyyy年MM月dd日',
    mask: '____年__月__日',
  };
  const mergedProps = { ...defaultProps, ...props };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
      <MuiDateRangePicker {...mergedProps} />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
