import * as React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/parts/atoms';

const Container = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Portal: React.VFC = () => (
  <>
    <Container>
      <Typography variant='h1'>404</Typography>
      <Typography variant='h3'>Not Found</Typography>
    </Container>
  </>
);

export default Portal;
