import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FlexoOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';

/** 製版区分価変更時にフォームをリセットするHooks */
export const usePlateMakingWatcher = (
  propertyName: 'flexoMakingsMain' | 'flexoMakingsReverse',
  index: number,
  enabled: boolean,
): void => {
  // フォーム
  const { setValue, getValues } = useFormContext<FlexoOrderEditForm>();
  const flexoMaking = getValues(`making.${propertyName}.${index}`);

  const { defaultFlxUnitPrice } = usePriceCalculateMatter();
  const defaultToyoPriceRate = defaultFlxUnitPrice?.toyoPriceRate ?? null;

  useEffect(() => {
    if (!enabled) return;

    if (!flexoMaking.plateMakingId) {
      setValue(`making.${propertyName}.${index}.completeSetCalcUnitPrice`, 0);
      setValue(`making.${propertyName}.${index}.completeSetCalcToyoPriceRate`, null);
    } else {
      if (flexoMaking.completeSetCalcToyoPriceRate === null) {
        setValue(
          `making.${propertyName}.${index}.completeSetCalcToyoPriceRate`,
          defaultToyoPriceRate,
        );
      }
    }
  }, [flexoMaking.plateMakingId]);
};
