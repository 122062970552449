import { useFormContext, useWatch } from 'react-hook-form';

import { CylinderOrderEditForm, UnitPriceKyushuMakingRowValues } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** 工程あたり単価 - 製版代(九州) 行情報 Hooks */
export const useUnitPriceKyushuMakingRow = (): UnitPriceKyushuMakingRowValues => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const row = useWatch({ control, name: 'price.unitPrice.kyushuMaking' });
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcGrossInvoice } = usePriceCalculater();

  /** 売価 */
  const price = row.price;
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate);

  return {
    price,
    toyoPriceRate,
    grossInvoice,
  };
};
