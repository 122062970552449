import React from 'react';

import PrintSide from './PrintSide';

/** 作業予定 印刷面(リバース) */
const PrintSideReverse: React.VFC = () => {
  return <PrintSide propertyName='flexoMakingsReverse' />;
};

export default React.memo(PrintSideReverse);
