import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import { useEffect, useState } from 'react';

import { defaultCustomerEdit, defaultOriginalIds, defaultSelectBoxModel } from '../defaults';
import { CustomerEdit, CustomerIdCondition } from '../types';

/** 得意先編集のhooks */
export const useFetchCustomerEdit = (
  customerId: number,
): {
  loading: boolean;
  customerEdit: CustomerEdit;
} => {
  // APIクライアント
  const messageApi = useSharedMessage();
  const { apiClient, loading } = useApiClient(messageApi.pushMessages);

  // 得意先編集ステート
  const [customerEdit, setCustomerEdit] = useState<CustomerEdit>(defaultCustomerEdit);

  // 得意先編集(初期表示)
  useEffect(() => {
    (async () => {
      try {
        // 新規登録以外データ取得
        const condition: CustomerIdCondition = {
          customerId: customerId,
        };

        const [allresults] = await Promise.all([
          apiClient.get<CustomerEdit>(`/api/v1/CustomerEdit/GetCustomerEdit`, {
            params: condition,
          }),
        ]);
        if (allresults.data.result) {
          if (customerId === -1) {
            setCustomerEdit((state) => ({
              ...state,
              selectBoxData: allresults.data.result?.selectBoxData ?? defaultSelectBoxModel,
              originalIds: allresults.data.result?.originalIds ?? defaultOriginalIds,
            }));
          } else {
            setCustomerEdit(allresults.data.result);
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return {
    loading,
    customerEdit,
  };
};

export default useFetchCustomerEdit;
