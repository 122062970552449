import { useOtherCylinderPricePerAreaRow } from '../priceRow/useOtherCylinderPricePerAreaRow';
import { useOtherCylinderPriceRow } from '../priceRow/useOtherCylinderPriceRow';

/** その他（シリンダ） 集計情報 Hooks */
export const useOtherCylinderPriceTotal = (): { price: number; grossInvoice: number } => {
  const herio = useOtherCylinderPriceRow('herio');
  const gaikeisessaku = useOtherCylinderPricePerAreaRow('gaikeisessaku');
  const fare = useOtherCylinderPriceRow('fare');

  const rows = [herio, gaikeisessaku, fare];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
