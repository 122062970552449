import { SearchForm, MessageTitleListRequest } from './types';

/** メッセージ項目一覧 検索条件初期値 **/
export const defaultMessageTitleListRequest: MessageTitleListRequest = {
  displayCode: 0,
  divisionId: null,
};

/** メッセージ項目一覧 検索条件初期値 **/
export const defaultMessageTitleList: SearchForm = {
  displayCode: 0,
  divisionId: null,
};
