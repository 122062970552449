import { PresentSyobunrui } from 'constants/define';

import * as apiType from 'api/types/topImage/imageWorkEditPresentAll';

import { SelectOption } from 'components/parts/atoms/Select/types';

/** responseモデル */
export type ImageWorkEditPresentResponse = apiType.ImageWorkEditPresentResponse;

/** response Paramモデル */
export type JobBasicInfoModel = apiType.JobBasicInfoModel;
export type PresentAllData = apiType.PresentAllDataModel;
export type EigReferenceJobModel = apiType.EigReferenceJobModel;
export type EigReferenceTemplateModel = apiType.EigReferenceTemplateModel;
export type ImageReferenceJobModel = apiType.ImageReferenceJobModel;
export type ImageReferenceTemplateModel = apiType.ImageReferenceTemplateModel;
export type PresentInfoModel = apiType.PresentInfoModel;
export type ReferenceJobResponse = apiType.ReferenceJobResponse;
export type ReferenceTemplateResponse = apiType.ReferenceTemplateResponse;
export type PresentWorkFlag = apiType.PresentWorkFlag;
export type OriginalIdsModel = apiType.OriginalIdsModel;

/* 画像提出物全体 フォーム **/
export type ImageWorkEditPresentForm = {
  jobBasicInfo: JobBasicInfoModel;
  presentAllData: PresentAllData;
  imageReferenceJob: ImageReferenceJobModel[];
  imageReferenceTemplate: ImageReferenceTemplateModel[];
  imageReferenceJobInput: ImageReferenceJob;
  imageReferenceTemplateInput: ImageReferenceTemplate;
  originalIds: OriginalIdsModel;
};

/** 画像提出物作業記録入力確認の保存ボタン処理時のPostData */
export type ImageWorkEditRegistData = {
  imageReferenceJob: ImageReferenceJobModel[];
  imageReferenceTemplate: ImageReferenceTemplateModel[];
  jobBasicInfo: JobBasicInfoModel;
  presentAllData: PresentAllData;
  originalIds: OriginalIdsModel;
};

/** 入力・確認ページ遷移用 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** 提出物全体 状態ステータス */
export const PresentWorkStatus = {
  notStart: 0,
  onWork: 1,
  Finish: 2,
} as const;
export type PresentWorkStatus = typeof PresentWorkStatus[keyof typeof PresentWorkStatus];

/** 提出物分類 */
export type PresentBunrui = 'aoyaki' | 'ohuko' | 'irokou' | 'fairuhenkan' | 'pozisyuturyoku';

/** 入力画面 */
/* JOBIDでの検索条件（初期表示） **/
export type JobIdCondition = {
  jobId: number;
};

/** ページProps */
// 入力画面Props
export type ImageWorkEditpresentProps = {
  jobId: number;
  onBack: () => void;
  onConfirm: () => void;
  eigReferenceJob: EigReferenceJobModel[];
  eigReferenceTemplate: EigReferenceTemplateModel[];
  jobBasicInfo: JobBasicInfoModel;
  presentAllData: PresentAllData;
  workProcessMaster: SelectOption[];
  irokoCurveMaster: SelectOption[];
  startWork: (form: ImageWorkReportPostData) => Promise<boolean>;
  removeWork: (form: ImageWorkReportPostData) => Promise<boolean>;
  reStartWork: (form: ImageWorkReportPostData) => Promise<boolean>;
};

// 画像参照画面Props
export type ImageReferenceComponentProps = {
  eigReferenceJob: EigReferenceJobModel[];
  eigReferenceTemplate: EigReferenceTemplateModel[];
  workProcessMaster: SelectOption[];
  startFlag: boolean;
  jobId: number;
};

// 提出物画面Props
export type ImagePresentComponentProps = {
  jobBasicInfo: JobBasicInfoModel;
  presentAllData: PresentAllData;
  irokoCurveMaster: SelectOption[];
  startWork: (form: ImageWorkReportPostData) => Promise<boolean>;
  removeWork: (form: ImageWorkReportPostData) => Promise<boolean>;
  reStartWork: (form: ImageWorkReportPostData) => Promise<boolean>;
};

export type ImagePresentCheckComponentProps = {
  jobBasicInfo: JobBasicInfoModel;
  presentAllData: PresentAllData;
  irokoCurveMaster: SelectOption[];
  isReference: boolean;
};

// 入力確認画面
export type ImageWorkEditCheckPresentProps = {
  jobId: number;
  onBack: () => void;
  onSave: () => void;
  eigReferenceJob: EigReferenceJobModel[];
  eigReferenceTemplate: EigReferenceTemplateModel[];
  jobBasicInfo: JobBasicInfoModel;
  presentAllData: PresentAllData;
  workProcessMaster: SelectOption[];
  irokoCurveMaster: SelectOption[];
  isReference: boolean;
};

/* 画像参照JOB **/
export type ImageReferenceJob = {
  gaImageReferenceJobId: number;
  referenceJobNo: string;
  referenceJobId: number;
  workProcessCdId: string;
  note: string;
  linkFlg: number;
};

/* 画像参照テンプレート **/
export type ImageReferenceTemplate = {
  gaReferenceTemplateId: number;
  referenceTemplateNo: string;
  note: string;
  linkFlg: number;
};

/** ボタン押下時ポストデータ */
export type ImageWorkReportPostData = {
  present: ImageWorkEditPresentForm;
  bunrui: PresentSyobunrui;
};

/** 確認画面 */

/* 入力チェック用 **/
/** JOBNOによる検索 */
export type ReferenceJobInputCheck = {
  jobNo: string;
  workProcessCdId: string;
  note: string;
};
export type ReferenceTemplateInputCheck = {
  jobNo: string;
  note: string;
};
