import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialRackEditState } from 'store/manuscript/rackEdit/defaults';
import { RackEditState } from 'store/manuscript/rackEdit/types';

const slice = createSlice({
  name: 'rackEdit',
  initialState: InitialRackEditState,
  reducers: {
    save: (state, action: PayloadAction<RackEditState>) => action.payload,
    clear: () => ({ ...InitialRackEditState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
