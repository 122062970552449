import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { ProductionEditModel } from 'pages/productionManage/Production/types';

import {
  BackButton,
  SaveButton,
  RowGrid,
  HeadGrid,
  BodyGrid,
  BodyLabel,
  HeadLabel,
} from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules';

type Props = {
  onBackClick: () => void;
  onSaveClick: () => void;
};

const ProductionEditCheck: React.VFC<Props> = (props) => {
  // フォーム情報
  const formContext = useFormContext<ProductionEditModel>();

  return (
    <Box>
      <Box>
        <RowGrid>
          <HeadGrid xs={3}>
            <HeadLabel>PRODUCTION No</HeadLabel>
          </HeadGrid>
          <BodyGrid xs={9}>
            <Controller
              name='productionNo'
              control={formContext.control}
              render={({ field: { value } }) => <BodyLabel>{value}</BodyLabel>}
            />
          </BodyGrid>
        </RowGrid>
        <RowGrid>
          <HeadGrid xs={3}>
            <HeadLabel>PRODUCTION名</HeadLabel>
          </HeadGrid>
          <BodyGrid xs={9}>
            <Controller
              name='productionName'
              control={formContext.control}
              render={({ field: { value } }) => <BodyLabel>{value}</BodyLabel>}
            />
          </BodyGrid>
        </RowGrid>
        <RowGrid>
          <HeadGrid xs={3}>
            <HeadLabel>PRODUCTION半角カナ</HeadLabel>
          </HeadGrid>
          <BodyGrid xs={9}>
            <Controller
              name='productionNameHankakukana'
              control={formContext.control}
              render={({ field: { value } }) => <BodyLabel>{value}</BodyLabel>}
            />
          </BodyGrid>
        </RowGrid>
      </Box>

      <Box marginTop={12}>
        <FooterButtonsContainer>
          <BackButton onClick={props.onBackClick} />
          <SaveButton onClick={props.onSaveClick} />
        </FooterButtonsContainer>
      </Box>
    </Box>
  );
};

export default ProductionEditCheck;
