import useMessage from 'hooks/useMessage';
import React, { useState, useEffect, createContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { defaultProductionEditModel } from 'pages/productionManage/Production/defaults';
import useFetchProductionEdit from 'pages/productionManage/Production/hooks/useFetchProductionEdit';
import useRegistProductionEditCheck from 'pages/productionManage/Production/hooks/useRegistProductionEditCheck';
import {
  InputCheck,
  PageState,
  ProductionEditModel,
  RegistData,
} from 'pages/productionManage/Production/types';

import { Circular } from 'components/parts/atoms';
import { Dialog } from 'components/parts/molecules';

import ProductionEdit from './ProductionEdit';
import ProductionEditCheck from './ProductionEditCheck';

// 画面マスタ用Context
export const productionContents = createContext<ProductionEditModel>(defaultProductionEditModel);

type Props = {
  productionId: number;
  open: boolean;
  onClose: () => void;
  onSaved: (production: ProductionEditModel) => void;
};

const ProductionDialog: React.VFC<Props> = (props) => {
  // hooks
  const messageApi = useMessage();
  const { loading, production, isInputCheckAsync, revalidate } = useFetchProductionEdit(
    { productionId: props.productionId },
    messageApi,
  );
  const { registProductionAsync } = useRegistProductionEditCheck();

  // ページステート
  const [pageState, setPageState] = useState<PageState>(PageState.Edit);

  // フォーム
  const formMethod = useForm<ProductionEditModel>({ defaultValues: production });

  /** プロダクション編集関連 */
  // 初期表示
  useEffect(() => {
    formMethod.reset(production);
  }, [production]);

  // ダイアログを閉じる
  const close = () => {
    messageApi.clear();
    formMethod.reset();
    setPageState(PageState.Edit);
    props.onClose();
  };

  // プロダクション編集確認画面に遷移
  const onConfirm = formMethod.handleSubmit(async (data: ProductionEditModel) => {
    messageApi.clear();
    const inputCondition: InputCheck = {
      productionName: data.productionName,
      productionNameHankakukana: data.productionNameHankakukana,
    };
    const passed: boolean = await isInputCheckAsync(inputCondition);

    if (passed) {
      setPageState(PageState.Check);
    }
  });

  /** プロダクション編集確認 */
  // プロダクション編集に戻る
  const onBackEdit = () => {
    messageApi.clear();
    setPageState(PageState.Edit);
  };

  // プロダクション登録
  const onSave = formMethod.handleSubmit(async (data: ProductionEditModel) => {
    messageApi.clear();

    const postData: RegistData = {
      productionId: data.productionId,
      productionName: data.productionName,
      productionNameHankakukana: data.productionNameHankakukana,
    };
    const succeed: boolean = await registProductionAsync(postData);

    if (succeed) {
      revalidate();
      props.onSaved(data);
      close();
    }
  });

  const title = pageState === PageState.Edit ? 'プロダクション編集' : 'プロダクション編集確認';

  return (
    <>
      <Dialog
        open={props.open}
        title={title}
        onClose={close}
        messageApi={messageApi}
        maxWidth={'sm'}
        height={'18rem'}
      >
        <FormProvider {...formMethod}>
          <form>
            <productionContents.Provider value={production}>
              {loading ? (
                <Circular />
              ) : (
                <>
                  {(() => {
                    switch (pageState) {
                      // プロサクション編集
                      case PageState.Edit:
                        return <ProductionEdit onCloseClick={close} onConfirmClick={onConfirm} />;
                      // プロダクション登録
                      case PageState.Check:
                        return (
                          <ProductionEditCheck onBackClick={onBackEdit} onSaveClick={onSave} />
                        );
                      default:
                        throw new Error();
                    }
                  })()}
                </>
              )}
            </productionContents.Provider>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default ProductionDialog;
