import { DeficitSurplusFlg, CylMakingCalcTypeForMainOffice } from 'constants/define';

import { MinimumPriceKey } from '../../../types';
import { usePageContext } from '../../usePageContext';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';

/** 最低価格 Hooks */
export const useMinimumPrice = (
  minimumPriceKey?: MinimumPriceKey,
): { applyMinimumPrice: (price: number) => number } => {
  // コンテキスト
  const { cylUnitPrice } = usePriceCalculateMatter();
  const { orderMetaData } = usePageContext();

  const applyMinimumPrice = (price: number): number => {
    if (
      !minimumPriceKey ||
      !cylUnitPrice ||
      cylUnitPrice.cylMakingCalcType === null ||
      !orderMetaData
    ) {
      return price;
    }

    // 選択されているシリンダ単価が九州専用 且つ、価格が入力されていれば最低価格を適用
    const minimumPrice = cylUnitPrice[minimumPriceKey] ?? 0;
    if (cylUnitPrice.cylMakingCalcType !== CylMakingCalcTypeForMainOffice && price !== 0) {
      if (orderMetaData.deficitSurplusFlg === DeficitSurplusFlg.Deficit) {
        // 赤処理であれば符号を反転して適用する
        return Math.min(price, -minimumPrice);
      } else {
        return Math.max(price, minimumPrice);
      }
    } else {
      return price;
    }
  };

  return {
    applyMinimumPrice,
  };
};
