import * as React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)(() => ({
  height: '100%',
}));
const StyledImg = styled('img')(() => ({
  height: '100%',
}));

const Logo: React.VFC = () => {
  return (
    <Wrapper>
      <Link to='/Portal'>
        <StyledImg src='/images/CyclonistLogo1_M.png' />
      </Link>
    </Wrapper>
  );
};

export default Logo;
