import * as apiType from 'api/types/topImage/imageWorkEditInspection';

export type ImageWorkEditInspectionMasterResponse = apiType.ImageWorkEditInspectionMasterResponse;
export type ImageWorkEditInspectionDataResponse = apiType.ImageWorkEditInspectionDataResponse;
export type ImageWorkEditInspectionWorkUpdateRequest =
  apiType.ImageWorkEditInspectionWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

/** ダウンロード入力フォーム */
export type DownloadForm = {
  design: boolean;
  photoRetouch: boolean;
  colorSeparation: boolean;
  layout: boolean;
  final: boolean;
  send: boolean;
};

/** 検版状態 */
export const InspectionStatus = {
  None: '0',
  Ok: '1',
  Ng: '2',
} as const;
export type InspectionStatus = typeof InspectionStatus[keyof typeof InspectionStatus];

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
