import { useCylinderMakingsAggregation } from '../../useCylinderMakingsAggregation';
import { usePriceCalculater } from '../usePriceCalculater';

/** 一式計算 集計情報 Hooks */
export const useSetPriceTotal = (): { price: number; grossInvoice: number } => {
  const { calcGrossInvoice } = usePriceCalculater();
  const { cylinderMakings } = useCylinderMakingsAggregation();

  let price = 0;
  let grossInvoice = 0;

  for (const row of cylinderMakings) {
    price += row.completeSetCalcUnitPrice ?? 0;
    grossInvoice += calcGrossInvoice(
      row.completeSetCalcUnitPrice,
      row.completeSetCalcToyoPriceRate ?? 1,
    );
  }

  return {
    price,
    grossInvoice,
  };
};
