import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialImageWorkEditState } from 'store/topImage/ImageWorkEditHistoryPresent/defaults';
import { ImageWorkEditState } from 'store/topImage/ImageWorkEditHistoryPresent/types';

const slice = createSlice({
  name: 'ImageWorkEditHistoryPresent',
  initialState: InitialImageWorkEditState,
  reducers: {
    save: (state, action: PayloadAction<ImageWorkEditState>) => action.payload,
    clear: () => ({ ...InitialImageWorkEditState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
