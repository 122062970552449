import { useAuth } from 'hooks/useAuth';
import React, { useState, useEffect, useContext } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { Dialog } from 'components/parts/molecules';

import { CustomerEditContext } from '../CustomerEdit';
import { defaultUnitPriceCylinderModel } from '../CustomerEdit/defaults';
import { CustomerEdit } from '../CustomerEdit/types';
import UnitPriceEditCylinder from './UnitPriceEdit';
import UnitPriceEditCheckCylinder from './UnitPriceEditCheck';
import { useCheckUnitPrice } from './hooks/useCheckUnitPrice';
import { useFetchUnitPrice } from './hooks/useFetchUnitPrice';
import { PageState, CylinderMainProps, InputCheck, CylinderForm } from './types';

// 単価入力・確認のメインページ
const UnitPriceEditCylinderMain: React.VFC<CylinderMainProps> = (props) => {
  // ページステート
  const [pageState, setPageState] = useState<PageState>(PageState.Edit);

  // ログインユーザ
  const { session } = useAuth();
  if (!session) {
    throw new Error();
  }

  // hooks
  const { toyoRate, setToyoRate, searchData, getUnitPriceEditSearchData, getToyoRate } =
    useFetchUnitPrice();
  const { messageApi, IsInputCheckAsync } = useCheckUnitPrice();

  // シリンダ単価登録専用フォーム
  const unitPriceCylinderFormMethod = useForm<CylinderForm>({
    defaultValues: defaultUnitPriceCylinderModel,
  });

  // 売価率判定用情報取得
  const formContext = useFormContext<CustomerEdit>();
  const customerSectionCd = formContext.getValues('customerEditSingleInfo.customerSectionCd');
  const cdid = formContext.getValues('customerEditSingleInfo.sectionPeculiarInfo');
  const data = useContext(CustomerEditContext);
  const salesGroup = data.selectBoxData.sections.find((p) => p.value === cdid);

  /** 画面表示時 */
  // 初期表示
  useEffect(() => {
    unitPriceCylinderFormMethod.reset(props.state);
    unitPriceCylinderFormMethod.setValue('familyName', session.familyName);
    unitPriceCylinderFormMethod.setValue('registDate', new Date());
    unitPriceCylinderFormMethod.setValue('fullName', session.fullName);
    // 画面表示時かつ新規の場合
    if (props.state.cylUnitPriceId === -1 && props.open) {
      if (props.state.toyoPriceRate) {
        setToyoRate(props.state.toyoPriceRate ?? 0);
      } else {
        getToyoRate(customerSectionCd, salesGroup?.decisionCode ?? '');
      }
      // 東洋売価率セット
      unitPriceCylinderFormMethod.setValue('toyoPriceRate', toyoRate);
    }
    if (props.checkFinishFlag) {
      setPageState(PageState.Check);
    }
  }, [props.open]);

  // 東洋売価率セット
  useEffect(() => {
    unitPriceCylinderFormMethod.setValue('toyoPriceRate', toyoRate);
  }, [toyoRate]);

  /** 単価入力 */
  // 検索＆自動入力
  const onSearch = unitPriceCylinderFormMethod.handleSubmit((data: CylinderForm) => {
    getUnitPriceEditSearchData(data.shaftCdId);
  });
  useEffect(() => {
    unitPriceCylinderFormMethod.setValue(
      'additionalCorrosion',
      searchData.searchData.additionalCorrosion,
    );
    unitPriceCylinderFormMethod.setValue('colorCylMaking', searchData.searchData.colorCylMaking);
    unitPriceCylinderFormMethod.setValue('crPlate', searchData.searchData.crPlate);
    unitPriceCylinderFormMethod.setValue('cuPlate', searchData.searchData.cuPlate);
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate1',
      searchData.searchData.cylCircumferencePremiumRate1,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate2',
      searchData.searchData.cylCircumferencePremiumRate2,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate3',
      searchData.searchData.cylCircumferencePremiumRate3,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate4',
      searchData.searchData.cylCircumferencePremiumRate4,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate5',
      searchData.searchData.cylCircumferencePremiumRate5,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate6',
      searchData.searchData.cylCircumferencePremiumRate6,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate7',
      searchData.searchData.cylCircumferencePremiumRate7,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate8',
      searchData.searchData.cylCircumferencePremiumRate8,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate9',
      searchData.searchData.cylCircumferencePremiumRate9,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate10',
      searchData.searchData.cylCircumferencePremiumRate10,
    );
    unitPriceCylinderFormMethod.setValue(
      'cylCircumferencePremiumRate11',
      searchData.searchData.cylCircumferencePremiumRate11,
    );
    unitPriceCylinderFormMethod.setValue('cylMaking', searchData.searchData.cylMaking);
    unitPriceCylinderFormMethod.setValue('endless', searchData.searchData.endless);
    unitPriceCylinderFormMethod.setValue('fare', searchData.searchData.fare);
    unitPriceCylinderFormMethod.setValue('finishPolish', searchData.searchData.finishPolish);
    unitPriceCylinderFormMethod.setValue('gradetion', searchData.searchData.gradetion);
    unitPriceCylinderFormMethod.setValue('herio', searchData.searchData.herio);
    unitPriceCylinderFormMethod.setValue('materialSprice1', searchData.searchData.materialSprice1);
    unitPriceCylinderFormMethod.setValue('materialSprice2', searchData.searchData.materialSprice2);
    unitPriceCylinderFormMethod.setValue('materialSprice3', searchData.searchData.materialSprice3);
    unitPriceCylinderFormMethod.setValue('materialSprice4', searchData.searchData.materialSprice4);
    unitPriceCylinderFormMethod.setValue('middleTone', searchData.searchData.middleTone);
    unitPriceCylinderFormMethod.setValue(
      'outSideTypeCutting',
      searchData.searchData.outsideTypeCutting,
    );
    unitPriceCylinderFormMethod.setValue(
      'rakuhanEditionPolish',
      searchData.searchData.rakuhanEditionpolish,
    );
    unitPriceCylinderFormMethod.setValue('repeatCr', searchData.searchData.repeatCr);
  }, [searchData]);
  // （単価入力確認に）進む
  const onGoCheck = unitPriceCylinderFormMethod.handleSubmit(async (data: CylinderForm) => {
    messageApi.clear();
    // 入力チェック
    const inputCondition: InputCheck = {
      shaftCdId: data.shaftCdId,
      unitPriceName: data.unitPriceName,
    };
    const inputCheckFlag: boolean = await IsInputCheckAsync(inputCondition);
    if (inputCheckFlag) {
      setPageState(PageState.Check);
    }
  });
  // 戻る（ダイアログを閉じる）
  const onBackCustomerEdit = () => {
    messageApi.clear();
    setPageState(PageState.Edit);
    unitPriceCylinderFormMethod.reset(defaultUnitPriceCylinderModel);
    props.onClose(undefined);
  };

  /** 単価入力確認ボタンイベント */
  // 得意先入力のフォームに登録
  const onAddFormArray = unitPriceCylinderFormMethod.handleSubmit((data: CylinderForm) => {
    setPageState(PageState.Edit);
    props.onClose(data);
    unitPriceCylinderFormMethod.reset(defaultUnitPriceCylinderModel);
  });
  // （編集に）戻る
  const onBackEdit = () => {
    if (props.checkFinishFlag) {
      props.onClose(undefined);
    }
    setPageState(PageState.Edit);
  };

  /** ダイアログを閉じる際の処理 */
  const onCloseDialog = () => {
    messageApi.clear();
    setPageState(PageState.Edit);
    unitPriceCylinderFormMethod.reset(defaultUnitPriceCylinderModel);
    props.onClose(undefined);
  };

  return (
    <Dialog
      open={props.open}
      title={pageState === PageState.Edit ? '単価入力' : '単価入力確認'}
      onClose={() => onCloseDialog()}
      messageApi={messageApi}
    >
      <FormProvider {...unitPriceCylinderFormMethod}>
        {(() => {
          switch (pageState) {
            // 単価入力
            case PageState.Edit:
              return (
                <UnitPriceEditCylinder
                  onBack={onBackCustomerEdit}
                  onGoCheck={onGoCheck}
                  onSearch={onSearch}
                  newCreateFlag={props.index === -1 ? true : false}
                />
              );
            // 単価入力確認
            case PageState.Check:
              return (
                <UnitPriceEditCheckCylinder
                  onBack={onBackEdit}
                  onAddForm={onAddFormArray}
                  checkFinishFlag={props.checkFinishFlag}
                />
              );
            default:
              throw new Error();
          }
        })()}
      </FormProvider>
    </Dialog>
  );
};

export default UnitPriceEditCylinderMain;
