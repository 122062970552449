import React, { useCallback } from 'react';
import { Control, Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import { AddCircle } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';

import {
  CylinderOrderEditForm,
  Accessory,
  PageState,
} from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import {
  RowGrid,
  HeadGrid,
  BodyGrid as BodyGridOrigin,
  HeadLabel,
  BodyLabel,
  BodyGridProps,
} from 'components/parts/atoms';

import { hasValue } from '../../utils/utils';

import { useCylinderOrderEditMaster } from '../../hooks/useCylinderOrderEditMaster';
import { useId } from '../../hooks/useId';
import { usePageContext } from '../../hooks/usePageContext';
import { EditIconButton, EditKanaField, EditNumericField, EditSelect } from '../Fields';
import { Heading } from '../Heading';

const BodyGrid: React.FC<BodyGridProps> = ({ children, sx, ...props }) => {
  const { pageState } = usePageContext();
  const theme = useTheme();
  const padding = pageState === PageState.Edit ? theme.spacing(1) : undefined;

  return (
    <BodyGridOrigin
      {...props}
      sx={{
        justifyContent: 'center',
        paddingLeft: padding,
        paddingRight: padding,
        ...sx,
      }}
    >
      {children}
    </BodyGridOrigin>
  );
};

const PrintColor: React.VFC<{ control: Control<CylinderOrderEditForm>; index: number }> = ({
  control,
  index,
}) => {
  // ウォッチ
  const [cylinderMakingsMain, cylinderMakingsReverse, printSequence, printSequence2] = useWatch({
    control,
    name: [
      'making.cylinderMakingsMain',
      'making.cylinderMakingsReverse',
      `making.accessories.${index}.printSequence`,
      `making.accessories.${index}.printSequence2`,
    ],
  });

  let printColor = '';

  if (printSequence.length > 0) {
    if (printSequence.startsWith('R')) {
      // R で始まる場合は裏面
      const printSequenceNumber = Number(printSequence.substring(1));
      if (!Number.isNaN(printSequenceNumber)) {
        if (printSequence2 !== null && !Number.isNaN(printSequence2)) {
          printColor =
            cylinderMakingsReverse.find(
              (e) => e.printSequence === printSequenceNumber && e.printSequence2 === printSequence2,
            )?.printColor ?? '';
        } else {
          printColor =
            cylinderMakingsReverse.find((e) => e.printSequence === printSequenceNumber)
              ?.printColor ?? '';
        }
      }
    } else {
      // 数値のみの場合は表面
      const printSequenceNumber = Number(printSequence);
      if (!Number.isNaN(printSequenceNumber)) {
        if (printSequence2 !== null && !Number.isNaN(printSequence2)) {
          printColor =
            cylinderMakingsMain.find(
              (e) => e.printSequence === printSequenceNumber && e.printSequence2 === printSequence2,
            )?.printColor ?? '';
        } else {
          printColor =
            cylinderMakingsMain.find((e) => e.printSequence === printSequenceNumber)?.printColor ??
            '';
        }
      }
    }
  }

  return <BodyLabel>{printColor}</BodyLabel>;
};

/**
 * 行に入力があるか
 */
function isValidItem(item: Accessory): boolean {
  return (
    hasValue(item.positionCdId) ||
    hasValue(item.printSequence) ||
    hasValue(item.indication) ||
    hasValue(item.finishingOut) ||
    hasValue(item.height) ||
    hasValue(item.width) ||
    hasValue(item.pitch)
  );
}

/** 作業予定 アクサセリーコンポーネント */
const Accessorie: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const { master } = useCylinderOrderEditMaster();
  const { pageState } = usePageContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'making.accessories',
  });

  // hook
  const { generateId } = useId();

  /** アクセサリーの行追加 */
  const onAdd = useCallback(() => {
    const addRow: Accessory = {
      accessoriesId: generateId(),
      accessoriesMasterId: '',
      printSequence: '',
      printSequence2: null,
      indication: '',
      positionCdId: '',
      finishingOut: null,
      width: null,
      height: null,
      pitch: null,
      displayorder: null,
    };
    append(addRow);
  }, [append, generateId]);

  /** アクセサリーの行削除 */
  const onDelete = useCallback((index: number) => {
    remove(index);
  }, []);

  return (
    <>
      <Heading>アクセサリ</Heading>
      <RowGrid columns={100}>
        <HeadGrid vertical xs={14}>
          <HeadLabel>{'アクセサリ'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={9}>
          <HeadLabel>{'刷順'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={4}>
          <HeadLabel>{'刷色'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={35}>
          <HeadLabel>{'指定事項　*半角40文字、全角20文字'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={8}>
          <HeadLabel>{'位置'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={6.5}>
          <HeadLabel>{'仕上外'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={6.5}>
          <HeadLabel>{'巾'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={6.5}>
          <HeadLabel>{'高さ'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={6.5}>
          <HeadLabel>{'ピッチ'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={4}>
          <EditIconButton onClick={() => onAdd()}>
            <AddCircle />
          </EditIconButton>
        </HeadGrid>
      </RowGrid>
      {fields.map((item, index) => {
        // 確認画面では入力されていない項目を表示しない
        if (pageState === PageState.Check && !isValidItem(item)) {
          return null;
        }
        return (
          <RowGrid key={item.id} columns={100}>
            <BodyGrid xs={14}>
              <Controller
                name={`making.accessories.${index}.accessoriesMasterId`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    addEmptyRow
                    options={master.accessoriesMasterList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={9}>
              <Box sx={{ display: 'flex' }}>
                <Controller
                  name={`making.accessories.${index}.printSequence`}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <EditKanaField
                      maxLength={3}
                      onChange={onChange}
                      value={value}
                      name={name}
                      sx={{ width: '60%' }}
                    />
                  )}
                />
                <Box mx={1} />
                <Controller
                  name={`making.accessories.${index}.printSequence2`}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <EditNumericField
                      max={9}
                      min={0}
                      decimalDigits={0}
                      onChange={onChange}
                      value={value}
                      name={name}
                      sx={{ width: '40%' }}
                    />
                  )}
                />
              </Box>
            </BodyGrid>
            <BodyGrid xs={4}>
              <PrintColor control={control} index={index} />
            </BodyGrid>
            <BodyGrid xs={35} sx={{ justifyContent: 'left' }}>
              <Controller
                name={`making.accessories.${index}.indication`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditKanaField
                    maxLength={40}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={8}>
              <Controller
                name={`making.accessories.${index}.positionCdId`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    addEmptyRow
                    options={master.positoonList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={6.5}>
              <Controller
                name={`making.accessories.${index}.finishingOut`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditNumericField
                    max={999.9}
                    min={0}
                    decimalDigits={1}
                    onChange={onChange}
                    value={value}
                    name={name}
                    labelWidth={'grow'}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={6.5}>
              <Controller
                name={`making.accessories.${index}.width`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditNumericField
                    max={999.9}
                    min={0}
                    decimalDigits={1}
                    onChange={onChange}
                    value={value}
                    name={name}
                    labelWidth={'grow'}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={6.5}>
              <Controller
                name={`making.accessories.${index}.height`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditNumericField
                    max={999.9}
                    min={0}
                    decimalDigits={1}
                    onChange={onChange}
                    value={value}
                    name={name}
                    labelWidth={'grow'}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={6.5}>
              <Controller
                name={`making.accessories.${index}.pitch`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditNumericField
                    max={999.9}
                    min={0}
                    decimalDigits={1}
                    onChange={onChange}
                    value={value}
                    name={name}
                    labelWidth={'grow'}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={4}>
              <EditIconButton onClick={() => onDelete(index)}>
                <Delete />
              </EditIconButton>
            </BodyGrid>
          </RowGrid>
        );
      })}
    </>
  );
};

export default React.memo(Accessorie);
