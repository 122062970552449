import React from 'react';

import { Heading } from '../../Heading';
import MakingPriceRowHeader from '../Common/MakingPriceRowHeader';
import MakingPriceReverseRow from './ReverseMakingPriceRow';

/** 印刷面(リバース)計算 コンポーネント*/
const MakingPriceReverse: React.VFC = () => {
  return (
    <>
      <Heading>印刷面(リバース)計算</Heading>
      <MakingPriceRowHeader />
      <MakingPriceReverseRow label='ベタ版' rowPropertyName='betaHan' />
      <MakingPriceReverseRow label='網版' rowPropertyName='halfTone' />
      <MakingPriceReverseRow label='バリヤー処理代' rowPropertyName='barrier' />
      <MakingPriceReverseRow label='ネガフィルム代' rowPropertyName='negaFilm' />
      <MakingPriceReverseRow label='ポジ返し代' rowPropertyName='posi' />
      <MakingPriceReverseRow label='単面ネガ代' rowPropertyName='singleFaceNega' />
      <MakingPriceReverseRow label='殖版代' rowPropertyName='composer' />
      <MakingPriceReverseRow label='全面マットネガ代' rowPropertyName='matNega' />
      <MakingPriceReverseRow label='刷版代' rowPropertyName='plate' />
    </>
  );
};

export default React.memo(MakingPriceReverse);
