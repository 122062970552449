import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CylinderOrderEditForm } from '../../../types';
import {
  useHanberiPriceRow,
  useNormalPriceRow,
  useReChroniumPriceRow,
} from '../priceRow/useMakingPriceRow';

/** 製版価格変更時に製版情報のシリンダ単価をRHFにセットするHooks */
export const useMakingPriceWatcher = (): void => {
  const { setValue } = useFormContext<CylinderOrderEditForm>();

  const normalNew = useNormalPriceRow('New');
  const normalOld = useNormalPriceRow('Old');
  const normalSupply = useNormalPriceRow('Supply');
  const normalCopper = useNormalPriceRow('Copper');

  const hanberiNew = useHanberiPriceRow('New');
  const hanberiOld = useHanberiPriceRow('Old');
  const hanberiSupply = useHanberiPriceRow('Supply');
  const hanberiCopper = useHanberiPriceRow('Copper');

  const rechroniumRecr = useReChroniumPriceRow('ReCr');

  useEffect(() => {
    setValue('price.makingCylUnitPrice', {
      normal: {
        new: normalNew,
        old: normalOld,
        supply: normalSupply,
        copper: normalCopper,
      },
      hanberi: {
        new: hanberiNew,
        old: hanberiOld,
        supply: hanberiSupply,
        copper: hanberiCopper,
      },
      reChronium: {
        reCr: rechroniumRecr,
      },
    });
  }, [
    normalNew.price,
    normalNew.grossInvoice,
    normalOld.price,
    normalOld.grossInvoice,
    normalSupply.price,
    normalSupply.grossInvoice,
    normalCopper.price,
    normalCopper.grossInvoice,
    hanberiNew.price,
    hanberiNew.grossInvoice,
    hanberiOld.price,
    hanberiOld.grossInvoice,
    hanberiSupply.price,
    hanberiSupply.grossInvoice,
    hanberiCopper.price,
    hanberiCopper.grossInvoice,
    rechroniumRecr.price,
    rechroniumRecr.grossInvoice,
  ]);
};
