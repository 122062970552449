import * as React from 'react';

import { Delete } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type DeleteButtonProps = CustomButtonProps;

const DeleteButton: React.VFC<DeleteButtonProps> = (props) => {
  return (
    <CustomButton icon={<Delete />} {...props}>
      {'削除'}
    </CustomButton>
  );
};

export default DeleteButton;
