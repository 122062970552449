import { UnitPriceEditSearchData, UnitPriceResponse } from './types';

/** 単価入力自動入力用初期値 */
export const defaultContent: UnitPriceEditSearchData = {
  materialSprice1: 0,
  materialSprice2: 0,
  materialSprice3: 0,
  materialSprice4: 0,
  repeatCr: 0,
  cuPlate: 0,
  crPlate: 0,
  finishPolish: 0,
  rakuhanEditionpolish: 0,
  cylMaking: 0,
  colorCylMaking: 0,
  middleTone: 0,
  gradetion: 0,
  endless: 0,
  additionalCorrosion: 0,
  outsideTypeCutting: 0,
  herio: 0,
  fare: 0,
  cylCircumferencePremiumRate1: 0,
  cylCircumferencePremiumRate2: 0,
  cylCircumferencePremiumRate3: 0,
  cylCircumferencePremiumRate4: 0,
  cylCircumferencePremiumRate5: 0,
  cylCircumferencePremiumRate6: 0,
  cylCircumferencePremiumRate7: 0,
  cylCircumferencePremiumRate8: 0,
  cylCircumferencePremiumRate9: 0,
  cylCircumferencePremiumRate10: 0,
  cylCircumferencePremiumRate11: 0,
};
export const defaultSearchData: UnitPriceResponse = {
  searchData: defaultContent,
};
