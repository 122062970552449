import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import { CustomerEditContext } from 'pages/customer/CustomerEdit';

import { HeadLabel, BodyLabel, HeadGrid, BodyGrid, RowGrid } from 'components/parts/atoms';

import { getSelectText } from 'utils/selectUtils';

import { CylinderForm } from '../../types';

const CylinderBasicInfos: React.VFC = () => {
  // フォームデータ
  const master = useContext(CustomerEditContext).selectBoxData;
  const unitPriceCylinderFormContext = useFormContext<CylinderForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            基本情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>単価種別</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='shaftCdId'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>
                シリンダー製版：シャフト{getSelectText(field.value, master.shafts)}
              </BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>単価名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='unitPriceName'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>説明</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='unitPriceExplanation'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>東洋売価率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='toyoPriceRate'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => <BodyLabel>{field.value?.toFixed(3)}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>製版代計算区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='cylMakingCalcType'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default CylinderBasicInfos;
