import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SearchReq, SearchRes } from 'api/types/portalTop/jobNoSearchResult';

import { Button, LoadingButton } from 'components/parts/atoms';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const Category = styled(Box)(() => ({
  display: 'flex',
}));

const Links: React.VFC = () => {
  const { pushError, pushWarning, pushInfo, pushMessages, clear } = useSharedMessage();
  const { apiClient, loading } = useApiClient(pushMessages);

  const errorDemo = () => {
    pushError(`エラーメッセージ：${Math.random()}`);
  };
  const warningDemo = () => {
    pushWarning(`ワーニングメッセージ：${Math.random()}`);
  };
  const infoDemo = () => {
    pushInfo(`インフォメッセージ：${Math.random()}`);
  };
  const apiErrorDemo = async () => {
    try {
      const request: SearchReq = { jobNo: 'T' };
      await Promise.all([apiClient.post<SearchRes>(`/api/v1/JobNoSearchResult/Search`, request)]);
    } catch (e) {
      console.log(e);
    }
  };
  const clearMessage = () => {
    clear();
  };

  return (
    <Container>
      <Category sx={{ '& > *': { mx: 2 } }}>
        <Button onClick={errorDemo}>エラー</Button>
        <Button onClick={warningDemo}>ワーニング</Button>
        <Button onClick={infoDemo}>インフォ</Button>
        <LoadingButton loading={loading} onClick={apiErrorDemo}>
          APIエラー
        </LoadingButton>
        <Button onClick={clearMessage}>クリア</Button>
      </Category>
    </Container>
  );
};

export default Links;
