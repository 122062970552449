import { ProductionCategoryCode } from 'constants/define';

import { FlexoUnitPrice } from '../CustomerEdit/types';

/** ページ区分 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** フレキソ単価入力専用フォーム */
export type FlexoForm = FlexoUnitPrice;

/** フレキソ単価入力・確認メインのprops */
export type FlexoMainProps = {
  index: number;
  state: FlexoUnitPrice;
  open: boolean;
  onClose: (value?: FlexoUnitPrice) => void;
  checkFinishFlag: boolean;
};

/** フレキソ単価入力props */
export type FlexoEditProps = {
  onBack: () => void;
  onGoCheck: () => void;
};

/** フレキソ単価入力確認props */
export type FlexoEditCheckProps = {
  onBack: () => void;
  onAddForm: () => void;
  checkFinishFlag: boolean;
};

/** 単価入力東洋売価率検索条件 */
export type CustomerSectionCdCondition = {
  customerSectionCd: string;
  sectionPeculiarInfo: string;
};

/** 単価入力入力チェック */
export type InputCheck = {
  unitPriceName: string;
};
