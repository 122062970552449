import useApiClient from 'hooks/useApiClient';
import { useState, useEffect } from 'react';

import {
  GetImageWorkReportHeaderReq,
  GetImageWorkReportHeaderRes,
} from 'api/types/common/imageWorkReportHeader';

const useFetchImageWorkReportHeader = (
  jobId: number,
  history: boolean,
): { headerInfo: GetImageWorkReportHeaderRes | null; loading: boolean } => {
  const { apiClient } = useApiClient();
  const [loading, setLoading] = useState(false);

  const [headerInfo, setData] = useState<GetImageWorkReportHeaderRes | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const condition: GetImageWorkReportHeaderReq = { jobId: jobId, isHistory: history };
        const [imageWorkReportHeaderRes] = await Promise.all([
          apiClient.get<GetImageWorkReportHeaderRes>(
            `/api/v1/ImageWorkReportHeader/GetImageWorkReportHeader`,
            {
              params: condition,
            },
          ),
        ]);

        if (imageWorkReportHeaderRes.data.result) {
          setData((imageWorkReportHeaderRes.data.result as GetImageWorkReportHeaderRes) ?? null);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(true);
      }
    })();
  }, []);

  return { headerInfo, loading };
};

export default useFetchImageWorkReportHeader;
