import React, { useState, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Business } from '@mui/icons-material';
import { Box } from '@mui/material';

import PostSearchResultTable from 'pages/common/PostSearchResult';
import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel, BodyLabel, RowGrid, HeadGrid } from 'components/parts/atoms';

import { useCylinderOrderEditMaster } from '../../hooks/useCylinderOrderEditMaster';
import { useFormChildrenContext } from '../../hooks/useFormChildrenContext';
import { EditButton, EditCheckbox, EditIconButton, EditKanaField, EditSelect } from '../Fields';
import { RowOne } from './Rows';

/** 受注 基本情報(引取先)コンポーネント */
const Received: React.VFC = () => {
  // Context
  const { control, setValue } = useFormContext<CylinderOrderEditForm>();
  const { master } = useCylinderOrderEditMaster();

  // 詳細表示フラグ
  const {
    openReceivedState: [open, setOpen],
  } = useFormChildrenContext();

  /** 納入先選択表示有無（選択肢が0件の場合は非表示にする） */
  const placeSelectVisible = master.receivedPlaceList.length > 0;

  // 郵便番号検索表示
  const [openPostSearch, setOpenPostSearch] = useState(false);

  // ウォッチ
  const [zipCd] = useWatch({ control, name: ['basis.receivedPlace.zipCd'] });

  /** 入力モード切替 */
  const switchInputMode = (open: boolean) => {
    // 表示切替
    setOpen(open);

    // 入力値をクリア
    if (open) {
      setValue('basis.receivedPlace.deliveryPlaceId', '');
    } else {
      setValue('basis.receivedPlace.name', '');
      setValue('basis.receivedPlace.zipCd', '');
      setValue('basis.receivedPlace.prefectureId', '');
      setValue('basis.receivedPlace.address1', '');
      setValue('basis.receivedPlace.address2', '');
      setValue('basis.receivedPlace.builName', '');
      setValue('basis.receivedPlace.tel', '');
    }
  };

  /** 郵便番号検索ダイアログ */
  const renderDialogs = useMemo((): JSX.Element => {
    return (
      <>
        <PostSearchResultTable
          open={openPostSearch}
          onClose={(value) => {
            if (value) {
              setValue('basis.receivedPlace.zipCd', value.zipCd);
              setValue('basis.receivedPlace.prefectureId', value.prefecturesid.toString());
              setValue(
                'basis.receivedPlace.address1',
                `${value.citykanjiName}${value.chouikikanjiName}`,
              );
            }

            setOpenPostSearch(false);
          }}
          zipcd={zipCd}
        />
      </>
    );
  }, [zipCd, openPostSearch, setValue]);

  return (
    <>
      {!open && (
        <>
          <RowOne>
            <HeadLabel>引取先</HeadLabel>
            <Box sx={{ display: 'flex' }}>
              <Controller
                name='basis.receivedPlace.deliveryPlaceId'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    options={master.receivedPlaceList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ minWidth: '16em' }}
                    addEmptyRow
                  />
                )}
              />

              {placeSelectVisible && (
                <>
                  <Box mx={2} />
                  <EditIconButton onClick={() => switchInputMode(true)}>
                    <Business />
                  </EditIconButton>
                </>
              )}
              <Box mx={2} />
              <Box alignItems='center' sx={{ display: 'flex' }}>
                <BodyLabel>納入先住所と同じ</BodyLabel>
                <Controller
                  name={'basis.deliveryPlaceRefFlg'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <EditCheckbox onChange={onChange} checked={value} name={name} />
                  )}
                />
                <Box mx={2} />
                <BodyLabel>得意先住所と同じ</BodyLabel>
                <Controller
                  name={'basis.receivedCustomerAddressRefFlg'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <EditCheckbox onChange={onChange} checked={value} name={name} />
                  )}
                />
              </Box>
            </Box>
          </RowOne>
        </>
      )}
      {open && (
        <Box my={4}>
          <RowGrid>
            <HeadGrid sx={{ width: '100%', justifyContent: 'left' }}>
              <HeadLabel>引取先</HeadLabel>
            </HeadGrid>
          </RowGrid>
          <RowOne>
            <HeadLabel>引取先名</HeadLabel>
            <Box sx={{ display: 'flex' }}>
              <Controller
                name='basis.receivedPlace.name'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditKanaField
                    maxLength={40}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '40em' }}
                  />
                )}
              />
              {placeSelectVisible && (
                <>
                  <Box mx={1} />
                  <EditIconButton onClick={() => switchInputMode(false)}>
                    <Business />
                  </EditIconButton>
                </>
              )}
              <Box mx={2} />
              <Box alignItems='center' sx={{ display: 'flex' }}>
                <BodyLabel>納入先住所と同じ</BodyLabel>
                <Controller
                  name={'basis.deliveryPlaceRefFlg'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <EditCheckbox onChange={onChange} checked={value} name={name} />
                  )}
                />
                <Box mx={2} />
                <BodyLabel>得意先住所と同じ</BodyLabel>
                <Controller
                  name={'basis.receivedCustomerAddressRefFlg'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <EditCheckbox onChange={onChange} checked={value} name={name} />
                  )}
                />
              </Box>
            </Box>
          </RowOne>
          <RowOne>
            <HeadLabel>郵便番号</HeadLabel>
            <Box sx={{ display: 'flex' }}>
              <Controller
                name='basis.receivedPlace.zipCd'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditKanaField
                    maxLength={8}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '9em' }}
                  />
                )}
              />
              <Box mx={1} />
              <EditButton onClick={() => setOpenPostSearch(true)}>郵便番号</EditButton>
            </Box>
          </RowOne>
          <RowOne>
            <HeadLabel>都道府県</HeadLabel>
            <Controller
              name='basis.receivedPlace.prefectureId'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditSelect
                  addEmptyRow
                  options={master.prefecturesList}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '9em' }}
                />
              )}
            />
          </RowOne>
          <RowOne>
            <HeadLabel>住所1</HeadLabel>
            <Controller
              name='basis.receivedPlace.address1'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  maxLength={80}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '55em' }}
                />
              )}
            />
          </RowOne>
          <RowOne>
            <HeadLabel>住所2</HeadLabel>
            <Controller
              name='basis.receivedPlace.address2'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  maxLength={80}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '55em' }}
                />
              )}
            />
          </RowOne>
          <RowOne>
            <HeadLabel>ビル名</HeadLabel>
            <Controller
              name='basis.receivedPlace.builName'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  maxLength={80}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '55em' }}
                />
              )}
            />
          </RowOne>
          <RowOne>
            <HeadLabel>電話番号</HeadLabel>
            <Controller
              name='basis.receivedPlace.tel'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  maxLength={12}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '13em' }}
                />
              )}
            />
          </RowOne>
          {renderDialogs}
        </Box>
      )}
    </>
  );
};

export default React.memo(Received);
