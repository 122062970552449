import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, useTheme } from '@mui/material';

import {
  FlexoOrderEditForm,
  FlexoMaking,
} from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { HeadLabel, BodyLabel, Circular } from 'components/parts/atoms';

import { isValidFlexoMaking } from '../../utils/utils';
import { dateText } from 'utils/date';
import { getSelectText } from 'utils/selectUtils';

import { useFlexoOrderEditMaster } from '../../hooks/useFlexoOrderEditMaster';
import { usePageContext } from '../../hooks/usePageContext';
import { RowOne, RowTwo, RowFour, RowFourFinal } from './Rows';

/** 受注ヘッダーコンポーネント */
const FlexoOrderHeader: React.VFC = () => {
  const formContext = useFormContext<FlexoOrderEditForm>();
  const { orderMetaData } = usePageContext();
  const { master } = useFlexoOrderEditMaster();
  const theme = useTheme();
  const header = formContext.getValues('header');

  // ウォッチ
  const [
    orderDate,
    presentationDate,
    proofDate,
    deliveryDate,
    customerChargeId,
    eigyoItemCdId,
    productionName,
    jobName,
    printCdId,
    flexoMakingsMain,
    flexoMakingsReverse,
  ] = useWatch({
    control: formContext.control,
    name: [
      'schedule.scheduleDate.orderDate',
      'schedule.scheduleDate.presentationDate',
      'schedule.scheduleDate.proofDate',
      'schedule.scheduleDate.deliveryDate',
      'basis.customerChargeId',
      'basis.eigyoItemCdId',
      'basis.productionName',
      'basis.jobName',
      'making.makingBasis.printCdId',
      'making.flexoMakingsMain',
      'making.flexoMakingsReverse',
    ],
  });

  const getColorCount = (makings: FlexoMaking[]): number => {
    const printSequences = makings
      .filter((e) => isValidFlexoMaking(e))
      .filter((e) => e.printSequence !== null)
      .map((e) => e.printSequence);
    const count = Array.from(new Set(printSequences)).length;
    return count;
  };

  const getPlateCount = (makings: FlexoMaking[]): number => {
    const count = makings
      .filter((e) => isValidFlexoMaking(e))
      .filter((x) => x.plateMakingId !== '').length;

    return count;
  };

  // 印刷面数
  const mainColorCount: number = getColorCount(flexoMakingsMain);
  const reverseColorCount: number = getColorCount(flexoMakingsReverse);
  const mainPlateCount: number = getPlateCount(flexoMakingsMain);
  const reversePlateCount: number = getPlateCount(flexoMakingsReverse);

  if (!orderMetaData) {
    return <Circular />;
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <RowFour>
          <HeadLabel>登録日</HeadLabel>
          <BodyLabel>{dateText(header.tjobRecordRegistDay)}</BodyLabel>
          <HeadLabel>最終更新日</HeadLabel>
          <BodyLabel>{dateText(header.tjobRecordEditDay)}</BodyLabel>
          <HeadLabel>発行日</HeadLabel>
          <BodyLabel>{dateText(header.issueDate)}</BodyLabel>
          <HeadLabel>作成者</HeadLabel>
          <BodyLabel>{header.registEmployeeName}</BodyLabel>
        </RowFour>
        <RowFour>
          <HeadLabel>受注日</HeadLabel>
          <BodyLabel>{dateText(orderDate)}</BodyLabel>
          <HeadLabel>提出日</HeadLabel>
          <BodyLabel>{dateText(presentationDate.day)}</BodyLabel>
          <HeadLabel>立会校正日</HeadLabel>
          <BodyLabel>{dateText(proofDate.day)}</BodyLabel>
          <HeadLabel>納品日</HeadLabel>
          <BodyLabel>{dateText(deliveryDate.day)}</BodyLabel>
        </RowFour>
        <RowFour>
          <HeadLabel>JOB No.</HeadLabel>
          <BodyLabel>{header.jobNo}</BodyLabel>
          <HeadLabel>参照JOB No.</HeadLabel>
          <BodyLabel>{header.referenceJobNo}</BodyLabel>
          <HeadLabel>シリーズマスター</HeadLabel>
          <BodyLabel>{header.seriesMaster}</BodyLabel>
          <HeadLabel>最終更新者</HeadLabel>
          <BodyLabel>{header.editEmployeeName}</BodyLabel>
        </RowFour>
        <RowTwo>
          <HeadLabel>部門/得意先コード</HeadLabel>
          <BodyLabel>
            {header.customerSectionCd}-{header.customerCd}
          </BodyLabel>
          <HeadLabel>得意先名</HeadLabel>
          <BodyLabel>{`${header.customerName} ${getSelectText(
            customerChargeId,
            master.customerChargeList,
          )}`}</BodyLabel>
        </RowTwo>
        <RowOne>
          <HeadLabel>品目</HeadLabel>
          <BodyLabel>{getSelectText(eigyoItemCdId, master.eigyoItemList)}</BodyLabel>
        </RowOne>
        <RowOne>
          <HeadLabel>品名</HeadLabel>
          <BodyLabel>{`${productionName} ${jobName}`}</BodyLabel>
        </RowOne>
        <RowFourFinal>
          <HeadLabel>製版</HeadLabel>
          <BodyLabel>{getSelectText(printCdId, master.printList)}</BodyLabel>
          <HeadLabel>印刷面(メイン)</HeadLabel>
          <BodyLabel>{`${mainColorCount}色中${mainPlateCount}本`}</BodyLabel>
          <HeadLabel>印刷面(リバース)</HeadLabel>
          <BodyLabel>{`${reverseColorCount}色中${reversePlateCount}本`}</BodyLabel>
          <Box display={'flex'}>
            {orderMetaData.isEstimate && (
              <BodyLabel sx={{ color: theme.palette.cyclonistRed.main, mr: 2 }}>見積り</BodyLabel>
            )}
            {orderMetaData.isRepeatOrder && (
              <BodyLabel sx={{ color: theme.palette.cyclonistRed.main, fontWeight: 'bold' }}>
                リピートオーダー
              </BodyLabel>
            )}
            {!orderMetaData.isRepeatOrder && (
              <BodyLabel
                sx={{ color: theme.palette.cyclonistDefaultBlue.main, fontWeight: 'bold' }}
              >
                新規受注
              </BodyLabel>
            )}
          </Box>
        </RowFourFinal>
      </Box>
    </>
  );
};

export default FlexoOrderHeader;
