import { ProductionCategoryCode } from 'constants/define';
import { cloneDeep } from 'lodash';
import { useMakingSpecificationStore } from 'store/common/makingSpecification/hooks';
import { useMakingSpecificationHistoryStore } from 'store/common/makingSpecificationHistory/hooks';

import {
  CylinderOrderEditForm,
  CylinderOrderMetaData,
} from 'api/types/topSales/order/cylinderOrderEdit';
import { FlexoOrderEditForm, FlexoOrderMetaData } from 'api/types/topSales/order/flexoOrderEdit';

/** 製版指示書Hooks */
export const useShowMakingSpecification = (): {
  showMakingSpecification: (category: string, jobId: number) => void;
  showMakingSpecificationByForm: (
    category: string,
    orderMetaData: CylinderOrderMetaData | FlexoOrderMetaData,
    form: CylinderOrderEditForm | FlexoOrderEditForm,
    jobId?: number | null,
  ) => void;
  showMakingSpecificationHistory: (category: string, jobHistoryId: number) => void;
} => {
  // 画面サイズ
  const windowsize = 'width=900,height=700,toolbar=yes,menubar=yes,scrollbars=yes';

  const { save } = useMakingSpecificationStore();
  const { save: saveHistory } = useMakingSpecificationHistoryStore();

  /* 製版指示書表示 **/
  const showMakingSpecification = (category: string, jobId: number) => {
    switch (category) {
      case ProductionCategoryCode.Cylinder:
        save({ jobId, orderMetaData: null, form: null });
        window.open('/MakingSpecification/Cylinder', '', windowsize);
        break;
      case ProductionCategoryCode.Flexo:
        save({ jobId, orderMetaData: null, form: null });
        window.open('/MakingSpecification/Flexo', '', windowsize);
        break;
    }
  };

  /* 製版指示書表示（受注入力の入力内容から） **/
  const showMakingSpecificationByForm = (
    category: string,
    orderMetaData: CylinderOrderMetaData | FlexoOrderMetaData,
    form: CylinderOrderEditForm | FlexoOrderEditForm,
    jobId: number | null = null,
  ) => {
    // react-hook-formで持つオブジェクトのままだとエラーになるためディープコピーしてsave
    switch (category) {
      case ProductionCategoryCode.Cylinder:
        save({ jobId, orderMetaData: cloneDeep(orderMetaData), form: cloneDeep(form) });
        window.open('/MakingSpecification/Cylinder', '', windowsize);
        break;
      case ProductionCategoryCode.Flexo:
        save({ jobId, orderMetaData: cloneDeep(orderMetaData), form: cloneDeep(form) });
        window.open('/MakingSpecification/Flexo', '', windowsize);
        break;
    }
  };

  /* 製版指示書（履歴）表示 **/
  const showMakingSpecificationHistory = (category: string, jobHistoryId: number) => {
    switch (category) {
      case ProductionCategoryCode.Cylinder:
        saveHistory({ jobHistoryId });
        window.open('/MakingSpecificationHistory/Cylinder', '', windowsize);
        break;
      case ProductionCategoryCode.Flexo:
        saveHistory({ jobHistoryId });
        window.open('/MakingSpecificationHistory/Flexo', '', windowsize);
        break;
    }
  };

  return { showMakingSpecification, showMakingSpecificationByForm, showMakingSpecificationHistory };
};

export default useShowMakingSpecification;
