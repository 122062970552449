import * as React from 'react';

import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Circular: React.VFC = () => (
  <>
    <Container>
      <CircularProgress />
    </Container>
  </>
);

export default Circular;
