import React from 'react';

import { Heading } from '../../Heading';
import MakingOptionPriceRowHeader from '../Common/MakingOptionPriceRowHeader';
import MakingPriceRowHeader from '../Common/MakingPriceRowHeader';
import HanberiOptionPriceRow from './HanberiOptionPriceRow';
import HanberiPriceRow from './HanberiPriceRow';

/** 行コンポーネント */

/** 価格情報 版べり再版 コンポーネント */
const HanberiPrice: React.VFC = () => {
  return (
    <>
      <Heading>版べり再版</Heading>

      <MakingPriceRowHeader />
      <HanberiPriceRow label='新管' type='New' />
      <HanberiPriceRow label='旧版' type='Old' />
      <HanberiPriceRow label='支給' type='Supply' />
      <HanberiPriceRow label='銅上' type='Copper' />

      <MakingOptionPriceRowHeader />
      <HanberiOptionPriceRow label='カラー製版' rowPropertyName='colorCylMaking' />
      <HanberiOptionPriceRow label='半調子' rowPropertyName='hanchoushi' />
      <HanberiOptionPriceRow label='調子' rowPropertyName='choushi' />
      <HanberiOptionPriceRow label='連続' rowPropertyName='renzoku' />
      <HanberiOptionPriceRow label='追加腐食' rowPropertyName='tuikafusyoku' />
    </>
  );
};

export default React.memo(HanberiPrice);
