import useShowImageDataDisplay from 'hooks/useShowImageDataDisplay';
import * as React from 'react';
import { useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PrintTarget } from 'api/types/print/deliveryPrint';

import DeliveryPrint from 'pages/print/DeliveryPrint';

import {
  Typography,
  BackButton,
  BackTopButton,
  ClearButton,
  ConfirmButton,
  EditButton,
  PrintButton,
  SaveButton,
  SearchButton,
  KanaField,
  NumericField,
  AlphaNumericField,
  CustomButton,
} from 'components/parts/atoms';
import ImageBackdrop from 'components/parts/organisms/ImageServer/ImageBackdrop';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const Category = styled(Box)(() => ({
  display: 'flex',
}));
const Attention = styled(Typography)(({ theme }) => ({
  color: theme.palette.red.main,
}));

const WithCaption: React.FC<{ caption: string; children: React.ReactNode }> = ({
  caption,
  children,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant='caption'>{caption}</Typography>
      {children}
    </Box>
  );
};

const ComponentSamples: React.VFC = () => {
  const wait = (sec: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, sec * 1000);
    });
  };

  const loadDemo = async () => {
    setIsLoading(true);
    await wait(2);
    setIsLoading(false);
  };

  const onDownloadBackdrop = async () => {
    setBackDrop(true);
    await wait(5);
    setBackDrop(false);
  };

  const { show } = useShowImageDataDisplay();
  const onImageDataDisplayClick = async () => {
    show(497700);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [backDrop, setBackDrop] = useState<boolean>(false);

  const [openDeliveryPrint, setOpenDeliveryPrint] = React.useState(false);
  const [printTarget, setPrintTarget] = React.useState<PrintTarget>();
  const onPrintClick = () => {
    /** 納品書印刷用情報をセット */
    /*const target: PrintTarget = {
      DeliveryJobIdList: ['123','1234'],
      ReceiptJobIdList: ['999'],
      DeliveryDetailInkJobIdList:['2'],
      DeliveryInkJobIdList:['1'],
      IsDeliveryDetailInkPrint:false,
      IsDeliveryInkPrint:false,
      IsDeliveryPrint: false,
      IsReceiptPrint: false,
     };*/
    setPrintTarget({
      deliveryJobIdList: [123, 1234],
      receiptJobIdList: [999],
      deliveryDetailInkJobIdList: [2],
      deliveryInkJobIdList: [1],
      isDeliveryDetailInkPrint: false,
      isDeliveryInkPrint: false,
      isDeliveryPrint: false,
      isReceiptPrint: false,
    });
    setOpenDeliveryPrint(true);

    return;
  };

  return (
    <>
      <Container>
        <Category sx={{ '& > *': { mx: 2 } }}>
          <SearchButton loading={isLoading} onClick={() => loadDemo()} />
          <ConfirmButton loading={isLoading} onClick={() => loadDemo()} />
          <SaveButton loading={isLoading} onClick={() => loadDemo()} />
          <EditButton loading={isLoading} onClick={() => loadDemo()} />
          <PrintButton loading={isLoading} onClick={() => onPrintClick()} />
          <ClearButton loading={isLoading} onClick={() => loadDemo()} />
          <BackButton loading={isLoading} onClick={() => loadDemo()} />
          <BackTopButton loading={isLoading} onClick={() => loadDemo()} />
          <CustomButton icon={<DownloadIcon />} onClick={() => onDownloadBackdrop()}>
            ダウンロード
          </CustomButton>
          <CustomButton onClick={() => onImageDataDisplayClick()}>画像確認</CustomButton>
        </Category>
        <Box my={5} />
        <Category sx={{ '& > *': { mx: 2 } }}>
          <WithCaption caption='KanaField'>
            <KanaField maxLength={20} />
          </WithCaption>
          <WithCaption caption='NumericField'>
            <NumericField decimalDigits={3} min={0} max={999999} />
          </WithCaption>
          <WithCaption caption='AlphaNumericField'>
            <AlphaNumericField maxLength={10} />
          </WithCaption>
          <WithCaption caption='　'>
            <Attention>{'※実装中'}</Attention>
          </WithCaption>
        </Category>
      </Container>
      <ImageBackdrop displayText='ダウンロード' displayFlg={backDrop} />
      <DeliveryPrint
        open={openDeliveryPrint}
        onClose={() => setOpenDeliveryPrint(false)}
        data={printTarget}
      />
    </>
  );
};

export default ComponentSamples;
