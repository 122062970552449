import { Affiliation, CalculateMethodCd } from 'constants/define';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ToggleButtonProps } from '@mui/material';

import {
  CheckboxProps,
  IconButtonProps,
  KanaFieldProps,
  SelectProps,
} from 'components/parts/atoms';

import { FlexoOrderEditForm } from '../../types';
import {
  EditAlphaNumericField,
  EditCheckbox,
  EditIconButton,
  EditKanaField,
  EditNumericField,
  EditNumericFieldProps,
  EditSelect,
  EditToggleButton,
} from '../Fields';

/** 価格情報で使用する数字フィールド */
export const PriceNumericField: React.VFC<EditNumericFieldProps> = (props) => {
  const { session } = useAuth();
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || session?.affiliation !== Affiliation.Normal || approvalFlg;
  return <EditNumericField {...props} disabled={disabled} />;
};

/** 価格情報で使用するテキストフィールド */
export const PriceKanaField: React.VFC<KanaFieldProps> = (props) => {
  const { session } = useAuth();
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || session?.affiliation !== Affiliation.Normal || approvalFlg;
  return <EditKanaField {...props} disabled={disabled} />;
};

/** 価格情報で使用するチェックボックス */
export const PriceCheckbox: React.VFC<CheckboxProps> = (props) => {
  const { session } = useAuth();
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || session?.affiliation !== Affiliation.Normal || approvalFlg;
  return <EditCheckbox {...props} disabled={disabled} />;
};

/** 価格情報で使用するアイコンボタン */
export const PriceIconButton: React.VFC<IconButtonProps> = (props) => {
  const { session } = useAuth();
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || session?.affiliation !== Affiliation.Normal || approvalFlg;
  return <EditIconButton {...props} disabled={disabled} />;
};

/** 受注入力で使用するトグルボタン */
export const PriceToggleButton: React.VFC<ToggleButtonProps> = (props) => {
  const { session } = useAuth();
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || session?.affiliation !== Affiliation.Normal || approvalFlg;
  return <EditToggleButton {...props} disabled={disabled} />;
};

/** 価格情報で使用するセレクト */
export const PriceSelect: React.VFC<SelectProps> = (props) => {
  const { session } = useAuth();
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || session?.affiliation !== Affiliation.Normal || approvalFlg;
  return <EditSelect {...props} disabled={disabled} />;
};

/** 単価計算コンポーネントで使用する数字フィールド */
export const UnitPriceField: React.VFC<EditNumericFieldProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [calculateMethodCd] = useWatch({ control, name: ['price.calculateMethodCd'] });
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || calculateMethodCd === CalculateMethodCd.Set || approvalFlg;
  return <PriceNumericField {...props} disabled={disabled} />;
};

/** 単価計算コンポーネントで手入力のみ入力可能な数字フィールド */
export const UnitPriceFixedField: React.VFC<EditNumericFieldProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [calculateMethodCd] = useWatch({ control, name: ['price.calculateMethodCd'] });
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || calculateMethodCd !== CalculateMethodCd.Unit || approvalFlg;
  return <PriceNumericField {...props} disabled={disabled} />;
};

/** 単価計算コンポーネントで使用するチェックボックス */
export const UnitCheckBox: React.VFC<CheckboxProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [calculateMethodCd] = useWatch({ control, name: ['price.calculateMethodCd'] });
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || calculateMethodCd === CalculateMethodCd.Set || approvalFlg;
  return <PriceCheckbox {...props} disabled={disabled} />;
};

/** 一式計算コンポーネントで使用する数字フィールド */
export const SetPriceField: React.VFC<EditNumericFieldProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [calculateMethodCd] = useWatch({ control, name: ['price.calculateMethodCd'] });
  const [approvalFlg] = useWatch({ control, name: ['price.approvalFlg'] });
  const disabled = props.disabled || calculateMethodCd !== CalculateMethodCd.Set || approvalFlg;
  return <PriceNumericField {...props} disabled={disabled} />;
};

/** 価格情報のURLで使用する半角英数字フィールド */
export const PriceURLNumField: React.VFC<KanaFieldProps> = (props) => {
  const disabled = props.disabled;
  return <EditAlphaNumericField {...props} disabled={disabled} />;
};

/** 価格情報のURL名で使用するテキストフィールド */
export const PriceURLKanaField: React.VFC<KanaFieldProps> = (props) => {
  const disabled = props.disabled;
  return <EditKanaField {...props} disabled={disabled} />;
};
