import { useHanberiPriceRow } from '../priceRow/useMakingPriceRow';

/** 版べり再版 集計情報 Hooks */
export const useHanberiPriceTotal = (): { price: number; grossInvoice: number } => {
  const newCylinder = useHanberiPriceRow('New');
  const old = useHanberiPriceRow('Old');
  const supply = useHanberiPriceRow('Supply');
  const copper = useHanberiPriceRow('Copper');

  const rows = [newCylinder, old, supply, copper];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
