import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialBarcodeReadLaserState } from 'store/topLaser/barcodeReadLaser/defaults';
import { BarcodeReadLaserState } from 'store/topLaser/barcodeReadLaser/types';

const slice = createSlice({
  name: 'barcodeReadLaser',
  initialState: InitialBarcodeReadLaserState,
  reducers: {
    save: (state, action: PayloadAction<BarcodeReadLaserState>) => action.payload,
    clear: () => ({ ...InitialBarcodeReadLaserState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
