import React, { ReactNodeArray } from 'react';

import { Download } from '@mui/icons-material';
import { Box, Grid, styled } from '@mui/material';

import {
  Heading,
  HeadGrid,
  BodyGrid,
  RowGrid,
  HeadLabel,
  BodyLabel,
  IconButton,
} from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

import * as date from 'utils/date';

import { ImageJobInfoData } from './types';

const StyledImg = styled('img')(() => ({
  height: '100%',
}));

const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [head1, body1, head2, body2],
}) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={2} bxs={6}>
          {head1}
          {body1}
        </ContentsGrid>
        <ContentsGrid hxs={2} bxs={2}>
          {head2}
          {body2}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

export type ImageJobInfoProps = {
  imageJobInfoData: ImageJobInfoData | null;
  downloadable: boolean;
  onDownloadlClicked?: (jobId: number) => void;
};

/**
 * ジョブ画像情報コンポーネント
 */
const ImageJobInfo: React.VFC<ImageJobInfoProps> = (props) => {
  return (
    <Box>
      <Heading>{`JOB INFO.：${props.imageJobInfoData?.jobNo ?? ''}`}</Heading>
      <Box my={2} />
      <Grid container>
        <HeadGrid
          xs={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyledImg src='/images/CyclonistImg/folder_M.png' />
        </HeadGrid>
        <Grid item container xs={10}>
          <Row>
            <HeadLabel>品名</HeadLabel>
            <BodyLabel>{props.imageJobInfoData?.productionName ?? ''}</BodyLabel>
            <HeadLabel>登録日</HeadLabel>
            <BodyLabel>{date.dateText(props.imageJobInfoData?.registDate)}</BodyLabel>
          </Row>
          <Row>
            <HeadLabel>容量</HeadLabel>
            <BodyLabel>{props.imageJobInfoData?.fileSize?.toFixed(2) ?? 0}MB</BodyLabel>
            <HeadLabel>修正日</HeadLabel>
            <BodyLabel>{date.dateText(props.imageJobInfoData?.editDate)}</BodyLabel>
          </Row>
          {props.downloadable && (
            <Grid item container xs={12}>
              <BodyGrid xs={12}>
                <IconButton
                  onClick={() => {
                    if (props.imageJobInfoData) {
                      props.onDownloadlClicked?.(props.imageJobInfoData.jobid);
                    }
                  }}
                >
                  <Download />
                </IconButton>
              </BodyGrid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageJobInfo;
