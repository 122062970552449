import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState } from 'react';

import { CylinderOrderEditFormRes } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

interface PriceApprovalEditApiResponse {
  response: CylinderOrderEditFormRes | null;
  updatePriceApproval: () => Promise<void>;
}

const usePriceApprovalEdit = (
  jobId: number | null,
  approvalFlg: boolean | undefined,
  secondApprovalFlg: boolean | undefined,
  showStatus: string,
  messageApi: MessageApi,
): PriceApprovalEditApiResponse => {
  // Hooks
  const { apiClient } = useApiClient(messageApi.pushMessages);
  // State
  const [response, setResponse] = useState<CylinderOrderEditFormRes | null>(null);

  // シリンダー受注詳細データの更新処理
  const updatePriceApproval: () => Promise<void> = async () => {
    try {
      // API リクエストを行うエンドポイントを適切に設定
      const endpoint = '/api/v1/PriceApprovalEdit/UpdatePriceApproval';

      // API リクエスト本体
      const apiResponse = await apiClient.get<CylinderOrderEditFormRes>(endpoint, {
        params: {
          jobId: jobId,
          approvalFlg: approvalFlg,
          secondApprovalFlg: secondApprovalFlg,
          showStatus: showStatus,
        },
      });
      // レスポンスデータを state にセット
      setResponse(apiResponse.data.result);
    } catch (error) {
      console.error('Error updating price approval:', error);
      // エラー時の処理を追加
    }
  };

  return {
    response,
    updatePriceApproval,
  };
};

export default usePriceApprovalEdit;
