import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialBarcodeReadPmState } from 'store/topPlating/barcodeReadPM/defaults';
import { BarcodeReadPmState } from 'store/topPlating/barcodeReadPM/types';

const slice = createSlice({
  name: 'barcodeReadPM',
  initialState: InitialBarcodeReadPmState,
  reducers: {
    save: (state, action: PayloadAction<BarcodeReadPmState>) => action.payload,
    clear: () => ({ ...InitialBarcodeReadPmState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
