import { OldSalesSearchConditionModel } from './types';

/** 九州旧販売管理／検索条件初期値 */
export const initialOldSalesSearchCondition: OldSalesSearchConditionModel = {
  juchuNo: '',
  juchuHidukeFrom: null,
  juchuHidukeTo: null,
  tokuisakiMei: '',
  bumonNo: '',
  tokuisakiNo: '',
  hinmei: '',
};
