import { CylinderMaking } from '../types';

export function hasValue(value: string | number | boolean | null): boolean {
  if (typeof value === 'string') {
    return !!value;
  } else if (typeof value === 'number') {
    return true;
  } else if (typeof value === 'boolean') {
    return value;
  } else {
    return !!value;
  }
}

/**
 * 行に入力があるか
 */
export function isValidCylinderMaking(item: CylinderMaking): boolean {
  return (
    hasValue(item.plateMakingId) ||
    hasValue(item.summaryCdId) ||
    hasValue(item.printColorNo) ||
    hasValue(item.printColor) ||
    hasValue(item.colorTip) ||
    hasValue(item.cylMakingType1CdId) ||
    hasValue(item.cylMakingType2CdId) ||
    hasValue(item.pmpolishFlg) ||
    hasValue(item.awaseBanNo) ||
    hasValue(item.lineCount) ||
    hasValue(item.swidth) ||
    hasValue(item.stylus) ||
    hasValue(item.depth) ||
    hasValue(item.angle) ||
    hasValue(item.diameterDifference)
  );
}
