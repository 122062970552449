import React, { useState } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import theme from 'themes/theme';

import UnitPriceEditOtherMain from 'pages/customer/UnitPriceEditOther';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
} from 'components/parts/atoms';

import { dateText } from 'utils/date';

import { defaultUnitPriceOtherModel } from '../../defaults';
import { CustomerEdit, OtherUnitPrice } from '../../types';

// 単価入力画面
const UnitPriceOther: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();

  // その他配列
  const otherArray = useFieldArray({
    control: formContext.control,
    name: 'otherUnitPrice',
  });

  // 追加・編集ステート
  const [indexState, setIndexState] = useState<number>(-1);
  const [otherUnitPrice] = useState<OtherUnitPrice>(defaultUnitPriceOtherModel);
  

  // 単価入力編集ダイアログ
  const [openUnitPriceEdit, setOpenUnitPriceEdit] = React.useState(false);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <UnitPriceEditOtherMain
          open={openUnitPriceEdit}
          onClose={(value) => {
            setOpenUnitPriceEdit(false);
            if (value) {
              // 編集
              if (indexState !== -1) {
                otherArray.update(indexState, value);
              }
              // 新規
              else {
                otherArray.append(value);
              }
            }
            setIndexState(-1);
          }}
          index={indexState}
          state={otherUnitPrice}
          checkFinishFlag={true}
        />
      </>
    );
  };

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            その他
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          <HeadLabel>単価名</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          <HeadLabel>説明</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          <HeadLabel>登録者</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
          <HeadLabel>登録日</HeadLabel>
        </HeadGrid>
      </RowGrid>

      {otherArray.fields.map((item, index) => {
        return (
          <div key={item.id}>
            <RowGrid columns={10}>
              <BodyGrid xs={3}>
                <Controller
                  name={`otherUnitPrice.${index}.unitPriceName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={3}>
                <Controller
                  name={`otherUnitPrice.${index}.unitPriceExplanation`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={3}>
                <Controller
                  name={`otherUnitPrice.${index}.fullName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={1}>
                <Controller
                  name={`otherUnitPrice.${index}.registDate`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{dateText(field.value)}</BodyLabel>}
                />
              </BodyGrid>
            </RowGrid>
          </div>
        );
      })}

      {renderDialogs()}
    </>
  );
};

export default UnitPriceOther;
