import * as React from 'react';
import theme from 'themes/theme';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/parts/atoms';
import { RowGrid, BodyGrid, HeadGrid } from 'components/parts/atoms/Grid';

import { NoteProps } from './types';

/**
 * ラッパースタイル
 */
const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
}));

/**
 * 汎用のBOXコンポーネント
 */
const NormalBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(2),
  width: '100%',
}));

/**
 * ヘッダースタイル
 */
const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  width: '100%',
}));

/**
 * 注意事項コンポーネント
 * @returns 注意事項コンポーネントJSX
 */
const Note: React.VFC<NoteProps> = ({ note, cylMakingDirectionOnState }): JSX.Element => {
  return (
    <Wrapper>
      {/* 注意事項・製版指示書特記事項ヘッダー */}
      <RowGrid columns={2} sx={{ mt: theme.spacing(2), boxSizing: 'border-box' }}>
        <HeadGrid xs={1} sx={{ p: 0 }}>
          <Header>
            <Typography>注意事項</Typography>
          </Header>
        </HeadGrid>
        <HeadGrid xs={1} sx={{ p: 0 }}>
          <Header>
            <Typography>製版指示書特記事項 ※全ての部署で参照できます。</Typography>
          </Header>
        </HeadGrid>
      </RowGrid>

      {/* 注意事項ボディ */}
      <RowGrid columns={2}>
        <BodyGrid xs={1}>
          <NormalBox>
            <Typography>{note ?? ''}</Typography>
          </NormalBox>
        </BodyGrid>
        {/* 製版指示書特記事項 */}
        <BodyGrid xs={1}>
          <NormalBox>
            <Typography>{cylMakingDirectionOnState ?? ''}</Typography>
          </NormalBox>
        </BodyGrid>
      </RowGrid>
    </Wrapper>
  );
};

export default Note;
