export * from 'api/types/topSales/order/cylinderOrderEdit';

/**受注入力の画面表示定義 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
  Saved: 'Saved',
  Confirm: 'Confirm',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** 受注入力タブのタブインデックス */
export const OrderEditTabIndex = {
  Basis: 'Basis',
  Schedule: 'Schedule',
  Making: 'Making',
  Price: 'Price',
};
export type OrderEditTabIndex = typeof OrderEditTabIndex[keyof typeof OrderEditTabIndex];

/** 営業参照 */
export type EigImageReferenceJobForm = {
  /** 営業参照Job ID */
  eigImageReferenceJobId: number;

  /** 参照Job ID */
  referenceJobId: number | null;

  /** 参照Job No */
  referenceJobNo: string;

  /** 工程 */
  workProcesscdId: string;

  /** コメント */
  note: string;
};

/** 営業テンプレート参照 */
export type EigReferenceTemplateForm = {
  /** 営業テンプレートID  */
  eigReferenceTemplateId: number | null;

  /** 営業テンプレートNo (検索が無いので手入力オンリーになる)*/
  referenceTemplateNo: string;

  /** コメント */
  note: string;
};

// #region 価格情報
/** 工程あたり単価 行 */
export type UnitPriceRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 表面積 */
  surfaceArea: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

/** 工程あたり単価 - 製版代 行 */
export type UnitPriceCylMakingRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 円周割増率 */
  circleRate: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
  /** 四捨五入していない売価 */
  unRoundedPrice: number;
};

/** 工程あたり単価 - 製版代（版べり） 行 */
export type UnitPriceHanberiMakingRowValues = {
  /** 製版代 */
  cylMakingPrice: number;
  /** 版べり割率 */
  hanberiRate: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

/** 工程あたり単価 - 製版代（九州） 行 */
export type UnitPriceKyushuMakingRowValues = {
  /** 売価 */
  price: number | null;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

/** 製版価格 行 */
export type MakingPriceRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 版数 */
  amount: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

/** 製版オプション価格 行 */
export type MakingOptionPriceRowValues = {
  /** 製版代 */
  cylMakingPrice: number;
  /** 加算倍率 */
  scale: number;
  /** 版数 */
  amount: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

/** その他（シリンダ） 行 */
export type OtherCylinderPriceRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 表面積 */
  surfaceArea: number;
  /** 版数 */
  amount: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};
/** その他（シリンダ） 行 */
export type OtherCylinderPricePerAreaRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 表面積 */
  surfaceArea: number;
  /** 版数 */
  amount: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

/** その他（画像） 行 */
export type OtherImagePriceRowValues = {
  /** 名称 */
  name: string;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 東洋売価率フラグ */
  toyoPriceRateFlg: boolean;
  /** 仕切 */
  grossInvoice: number;
};

/** 一式計算 行 */
export type SetPriceRowValues = {
  /** 刷順 */
  printSequence: string;
  /** 単価 */
  unitPrice: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

export type SalesPrice = {
  /** 売価 */
  price: number;
  /** 仕切 */
  grossInvoice: number;
};

export type TotalPriceValues = {
  /** フルコース、 改版、増版 */
  normal: SalesPrice;
  /** 版べり再版 */
  hanberi: SalesPrice;
  /** 再クロム */
  reChronium: SalesPrice;
  /** その他（シリンダ） */
  otherCylinder: SalesPrice;
  /** その他（画像） */
  otherImage: SalesPrice;
  /** 一式計算 */
  set: SalesPrice;
  /** 値引き率 */
  discountRate: SalesPrice;
  /** 値引き */
  discountPrice: SalesPrice;
  /** 総合計 */
  total: SalesPrice;
};

/** シリンダー単価に設定された最低価格のプロパティ名 */
export type MinimumPriceKey =
  | 'materialMinimumPrice9'
  | 'cuPlateMinimum9'
  | 'crPlateMinimum9'
  | 'rakuhanMinimum9'
  | 'reCrPlateMinimum9'
  | 'cylMakingMinimum9'
  | 'additionalCorrosionMinimum9';
// #endregion
