import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CylinderOrderEditForm, CylUnitPrice } from '../../types';
import { useCylinderOrderEditMaster } from '../useCylinderOrderEditMaster';
import { usePriceCalculater } from './usePriceCalculater';

/** 価格の計算に必要な要素を返すHooks */
export const usePriceCalculateMatter = (): {
  cylSizeWidth: number;
  cylSizeEnsyuu: number;
  gentanWidth: number;
  surfaceArea: number;
  toyoPriceRate: number;
  circleRate: number;
  cylUnitPrice: CylUnitPrice | null;
  defaultCylUnitPrice: CylUnitPrice | null;
  rawCylSizeWidth: number | null;
  rawCylSizeEnsyuu: number | null;
} => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  // マスタ
  const { master } = useCylinderOrderEditMaster();

  const [cylSizeWidth, cylSizeEnsyuu, gentanWidth, toyoPriceRate, circleRate, cylUnitPriceId] =
    useWatch({
      control,
      name: [
        'making.makingBasis.cylSizeWidth',
        'making.makingBasis.cylSizeEnsyuu',
        'making.makingBasis.gentanWidth',
        'price.toyoPriceRate',
        'price.circleRate',
        'price.cylUnitPriceId',
      ],
    });

  const { calcSurfaceArea } = usePriceCalculater();

  // シリンダ単価
  const cylUnitPrice: CylUnitPrice | null = useMemo(
    () => master.cylUnitPriceList.find((e) => e.cylUnitPriceId === cylUnitPriceId) ?? null,
    [master.cylUnitPriceList, cylUnitPriceId],
  );

  const defaultCylUnitPrice: CylUnitPrice | null = useMemo(
    () => master.cylUnitPriceList.find((e) => e.cylUnitPriceId === '') ?? null,
    [master.cylUnitPriceList],
  );

  /** 表面積 */
  const surfaceArea = calcSurfaceArea(cylSizeWidth, cylSizeEnsyuu);

  return {
    cylSizeWidth: cylSizeWidth ?? 0,
    cylSizeEnsyuu: cylSizeEnsyuu ?? 0,
    gentanWidth: gentanWidth ?? 0,
    surfaceArea,
    toyoPriceRate: toyoPriceRate ?? 1,
    circleRate: circleRate ?? 1,
    cylUnitPrice,
    defaultCylUnitPrice,
    rawCylSizeWidth: cylSizeWidth,
    rawCylSizeEnsyuu: cylSizeEnsyuu,
  };
};
