import * as React from 'react';

import { AddCircle } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type AddButtonProps = CustomButtonProps;

const AddButton: React.VFC<AddButtonProps> = (props) => {
  return (
    <CustomButton icon={<AddCircle />} {...props}>
      {'追加'}
    </CustomButton>
  );
};

export default AddButton;
