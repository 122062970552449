import * as React from 'react';

import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type HeadGridProps = {
  xs?: GridProps['xs'];
  sx?: GridProps['sx'];
  vertical?: boolean;
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  outline: '1px solid',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  outlineColor: theme.palette.cyclonistBorderColor.main,
  backgroundColor: theme.palette.cyclonistHeadGridColor.main,
  color: theme.palette.cyclonistHeadGridColor.contrastText,
}));

const HeadGrid: React.FC<HeadGridProps> = ({ children, sx, vertical, ...props }) => {
  return (
    <>
      <StyledGrid item sx={{ justifyContent: vertical ? 'center' : undefined, ...sx }} {...props}>
        {children}
      </StyledGrid>
    </>
  );
};

export default HeadGrid;
