import * as React from 'react';

import { Grid, GridProps } from '@mui/material';

export type RowGridProps = {
  columns?: number;
  direction?: GridProps['direction'];
  sx?: GridProps['sx'];
};

const RowGrid: React.FC<RowGridProps> = ({ children, columns = 12, ...props }) => {
  return (
    <>
      <Grid container columns={columns} {...props}>
        {children}
      </Grid>
    </>
  );
};

export default RowGrid;
