import { UnappropriateListState } from './types';

/** 未計上一覧ストアモデルの初期値 */
export const InitialUnappropriateListState: UnappropriateListState = {
  allUnappropriateOrder: true,
  selectedAppropriateValue: '',
  cylinderCustomerId: '',
  flexoCustomerId: '',
  otherCustomerId: '',
};
