import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { useOtherCylinderPricePerAreaRow } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/hooks/price/priceRow/useOtherCylinderPricePerAreaRow';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { OnlyTypeKey } from 'utils/typeUtils';

import {
  CylinderOrderEditForm,
  OtherCylinderPrice,
  OtherCylinderPriceRow as OtherCylinderPriceRowType,
} from '../../../types';
import { UnitCheckBox, UnitPriceField, UnitPriceFixedField } from '../Fields';
import { Row } from './Row';

type Props = {
  label: string;
  propertyName: OnlyTypeKey<OtherCylinderPrice, OtherCylinderPriceRowType>;
};

/** その他（シリンダ） 行コンポーネント（面積当たりの単価が入力される） */
const OtherCylinderPricePerAreaRow: React.VFC<Props> = ({ label, propertyName }) => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { surfaceArea, price, toyoPriceRate, grossInvoice } =
    useOtherCylinderPricePerAreaRow(propertyName);

  return (
    <>
      <Row>
        <HeadLabel>{label}</HeadLabel>
        <Controller
          name={`price.otherCylinderPrice.${propertyName}.unitPrice`}
          control={control}
          render={({ field }) => (
            <UnitPriceFixedField
              min={0}
              max={99.99}
              decimalDigits={2}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
            />
          )}
        />
        <BodyLabel>{surfaceArea}</BodyLabel>
        <Controller
          name={`price.otherCylinderPrice.${propertyName}.amount`}
          control={control}
          render={({ field }) => (
            <UnitPriceField
              min={0}
              max={99}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
            />
          )}
        />
        <BodyLabel>{price.toFixed()}</BodyLabel>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Controller
            name={`price.otherCylinderPrice.${propertyName}.toyoPriceRateFlg`}
            control={control}
            render={({ field }) => (
              <UnitCheckBox onChange={field.onChange} checked={field.value} name={field.name} />
            )}
          />
          <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
        </Box>
        <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
      </Row>
    </>
  );
};

export default React.memo(OtherCylinderPricePerAreaRow);
