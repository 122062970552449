import { CalculateMethodCd } from 'constants/define';

import {
  CylinderOrderEditForm,
  CylinderOrderEditMaster,
  SplittedDateTime,
  EigImageReferenceJobForm,
  EigReferenceTemplateForm,
  UrlModel,
} from './types';

const defaultSplittedDateTime: SplittedDateTime = {
  day: null,
  timeDiv: '',
  hour: '',
  minute: '',
};

const defaultUrl: UrlModel = {
  urlId: null,
  url: '',
  urlName: '',
};

/** 受注入力モデルの初期データ */
export const initialCylinderOrderEdit: CylinderOrderEditForm = {
  header: {
    tjobRecordRegistDay: null,
    tjobRecordEditDay: null,
    issueDate: null,
    registEmployeeId: 0,
    registEmployeeName: '',
    editEmployeeId: null,
    editEmployeeName: '',
    jobNo: '',
    referenceJobNo: '',
    seriesMasterId: null,
    seriesMaster: '',
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
  },
  basis: {
    jobNo: '',
    chargedEmployeeId: '',
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
    companyCd: '',
    customerChargeId: '',
    customerOrderNo: '',
    customerOrderNo2: '',
    myJobNo: '',
    oldJobNo: '',
    eigyoItemCdId: '',
    productionId: null,
    productionName: '',
    jobName: '',
    jobKanaName: '',
    demandCustomerSectionCd: '',
    demandCustomerCd: '',
    demandCustomerName: '',
    deliveryPlace: {
      deliveryPlaceId: '',
      name: '',
      zipCd: '',
      prefectureId: '',
      address1: '',
      address2: '',
      builName: '',
      tel: '',
    },
    customerAddressRefFlg: false,
    receivedPlace: {
      deliveryPlaceId: '',
      name: '',
      zipCd: '',
      prefectureId: '',
      address1: '',
      address2: '',
      builName: '',
      tel: '',
    },
    deliveryPlaceRefFlg: false,
    receivedCustomerAddressRefFlg: false,
    tobiiriJobFlg: false,
  },
  schedule: {
    scheduleDate: {
      orderDate: null,
      appropriateDate: null,
      imageWorkFlg: false,
      designFlg: false,
      phtoRetouchFlg: false,
      colorSeparationFlg: false,
      approvedFlg: false,
      imageJobPlanDate: defaultSplittedDateTime,
      syukkouPlanDate: defaultSplittedDateTime,
      presentationDate: defaultSplittedDateTime,
      cylShipmentFlg: false,
      imageProcessonlyFlg: false,
      shanaitestFlg: false,
      cylArrivalPlanDate: defaultSplittedDateTime,
      cylMakingPlanDate: defaultSplittedDateTime,
      proofDate: defaultSplittedDateTime,
      proofFlg: false,
      shipmentPlanDate: defaultSplittedDateTime,
      deliveryDate: defaultSplittedDateTime,
      customerPlanDate: defaultSplittedDateTime,
    },
    outsourcing: {
      imageOutsourcingFlg: false,
      cylMakingOutsourcingFlg: false,
      offproofOutsourcingFlg: false,
    },
    draftReturns: [],
    presents: [],
    canEditDraftReturns: true,
    canEditPresents: true,
  },
  making: {
    makingBasis: {
      printCdId: '',
      orientationCdId: '',
      cylSizeWidth: null,
      cylSizeEnsyuu: null,
      shaftCdId: '',
      materialCdId: '',
      frontPageSizeWidth: null,
      frontPageSizeGrain: null,
      impositioningWidth: null,
      impositioningGrain: null,
      grooveWidth: null,
      grooveWidthAmount: null,
      grooveGrain: null,
      grooveGrainAmount: null,
      overAllSizeWidth: null,
      overAllSizeGrain: null,
      beforeCylMakingDate: null,
      paperName: '',
      gentanWidth: null,
      surfaceDiameterBenchmark: '',
      backDiameterBenchmark: '',
    },
    cylinderMakingsMain: [],
    cylinderMakingsReverse: [],
    accessories: [],
    barcode: {
      barcodeNo: '',
      scale: null,
      bwr: null,
    },
    eigImageReferenceJobs: [],
    eigReferenceTemplates: [],
    gaImageReferenceJobs: [],
    gaReferenceTemplates: [],
    instructions: {
      imageDirectionOnSchedule: '',
      imageDirectionOnState: '',
      cylMakingDirectionOnSchedule: '',
      cylMakingDirectionOnState: '',
      shipmentDirectionOnSchedule: '',
      shipmentDirectionOnState: '',
      chousaDirection: '',
    },
    approvalFlg: false,
  },
  price: {
    calculateMethodCd: CalculateMethodCd.Unit,
    cylUnitPriceId: '',
    toyoPriceRate: null,
    circleRate: null,
    unitPrice: {
      materials: {
        unitPrice: null,
      },
      cuPlate: {
        unitPrice: null,
      },
      crPlate: {
        unitPrice: null,
      },
      finishingPolish: {
        unitPrice: null,
      },
      rakuhanPolish: {
        unitPrice: null,
      },
      reCr: {
        unitPrice: null,
      },
      cylMaking: {
        unitPrice: null,
      },
      hanberiMaking: {
        hanberiRate: null,
      },
      kyushuMaking: {
        price: null,
      },
    },
    normalPrice: {
      colorCylMaking: {
        scale: null,
        amount: null,
      },
      hanchoushi: {
        scale: null,
        amount: null,
      },
      choushi: {
        scale: null,
        amount: null,
      },
      renzoku: {
        scale: null,
        amount: null,
      },
      tuikafusyoku: {
        scale: null,
        amount: null,
      },
    },
    otherCylinderPrice: {
      herio: {
        unitPrice: null,
        amount: null,
        toyoPriceRateFlg: false,
      },
      gaikeisessaku: {
        unitPrice: null,
        amount: null,
        toyoPriceRateFlg: false,
      },
      fare: {
        unitPrice: null,
        amount: null,
        toyoPriceRateFlg: false,
      },
    },
    otherImagePrice: {
      kakakuJouhouSonotas: [],
    },
    hanberiPrice: {
      colorCylMaking: {
        scale: null,
        amount: null,
      },
      hanchoushi: {
        scale: null,
        amount: null,
      },
      choushi: {
        scale: null,
        amount: null,
      },
      renzoku: {
        scale: null,
        amount: null,
      },
      tuikafusyoku: {
        scale: null,
        amount: null,
      },
    },
    discount: {
      discountRate: null,
      discountPrice: null,
    },
    totalPrice: {
      price: 0,
      grossInvoice: 0,
    },
    estimateNote: '',
    statementOfDeliveryNote: '',
    makingCylUnitPrice: {
      normal: {
        supply: {
          price: 0,
          grossInvoice: 0,
        },
        new: {
          price: 0,
          grossInvoice: 0,
        },
        old: {
          price: 0,
          grossInvoice: 0,
        },
        copper: {
          price: 0,
          grossInvoice: 0,
        },
      },
      hanberi: {
        supply: {
          price: 0,
          grossInvoice: 0,
        },
        new: {
          price: 0,
          grossInvoice: 0,
        },
        old: {
          price: 0,
          grossInvoice: 0,
        },
        copper: {
          price: 0,
          grossInvoice: 0,
        },
      },
      reChronium: {
        reCr: {
          price: 0,
          grossInvoice: 0,
        },
      },
    },
    approvalFlg: false,
    approvalName: null,
    secondApprovalName: null,
    approvalDate: null,
    secondApprovalDate: null,
    url: defaultUrl,
    priceNote: '',

    secondApprovalFlg: false,
    customerUserFlg: false,
  },
};

/** 受注入力マスタモデルの初期データ */
export const initialCylinderMaster: CylinderOrderEditMaster = {
  chargedEmployeeList: [],
  customerChargeList: [],
  eigyoItemList: [],
  deliveryPlaceList: [],
  receivedPlaceList: [],
  prefecturesList: [],
  draftReturnList: [],
  returnTimeList: [],
  returnList: [],
  presentList: [],
  presentMethodList: [],
  printList: [],
  orientationList: [],
  shaftList: [],
  materialList: [],
  plateColorList: [],
  plateMakingList: [],
  summaryList: [],
  arrangeOrderList: [],
  awaseChousaList: [],
  cylMakingType1List: [],
  cylMakingType2List: [],
  accessoriesMasterList: [],
  positoonList: [],
  workProcessList: [],
  cylUnitPriceList: [],
  cylUnitPriceSelectList: [],
};

/** 営業 参照のフォーム初期値 */
export const defaultEigImageReferenceJobForm: EigImageReferenceJobForm = {
  eigImageReferenceJobId: -1,
  referenceJobId: null,
  referenceJobNo: '',
  workProcesscdId: '',
  note: '',
};

/** 営業 テンプレート参照のフォーム初期値 */
export const defaultEigReferenceTemplateForm: EigReferenceTemplateForm = {
  eigReferenceTemplateId: -1,
  referenceTemplateNo: '',
  note: '',
};
