import { ArrivalScheduleListState } from './types';

/** 受注一覧のストアモデルの初期値 */
export const InitialArrivalScheduleListState: ArrivalScheduleListState = {
  jobNo: '',
  customersectioncd: '',
  customercd: '',
  arrivalplandate: null,
  customerName: '',
  displayclassification: '',
  searchFlg: false,
};
