import { ProductionCategoryCode } from 'constants/define';

import { OtherUnitPrice } from '../CustomerEdit/types';

/** ページ区分 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** その他単価入力専用フォーム */
export type OtherForm = OtherUnitPrice;

/** その他単価入力・確認メインのprops */
export type OtherMainProps = {
  index: number;
  state: OtherUnitPrice;
  open: boolean;
  onClose: (value?: OtherUnitPrice) => void;
  checkFinishFlag: boolean;
};

/** その他単価入力props */
export type OtherEditProps = {
  onBack: () => void;
  onGoCheck: () => void;
};

/** その他単価入力確認props */
export type OtherEditCheckProps = {
  onBack: () => void;
  onAddForm: () => void;
  checkFinishFlag: boolean;
};

/** 単価入力東洋売価率検索条件 */
export type CustomerSectionCdCondition = {
  customerSectionCd: string;
  sectionPeculiarInfo: string;
};

/** 入力チェック */
export type InputCheck = {
  unitPriceName: string;
};
