import * as React from 'react';

import { Paper, Box, ButtonGroup } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Typography, TypographyProps, Button } from 'components/parts/atoms';

export type HeadingProps = {
  children: string;
  leftButtonClick: () => void;
  rightButtonClick: () => void;
  leftButtonText: string;
  rightButtonText: string;
  variant?: TypographyProps['variant'];
  backgroundColor?: string;
  textColor?: string;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const ButtonGroupHeading: React.FC<HeadingProps> = ({ variant: typoVariant = 'h3', ...props }) => {
  const theme = useTheme();
  const backgroundColor = props.backgroundColor ?? theme.palette.primary.main;
  const textColor = props.textColor ?? theme.palette.primary.contrastText;

  return (
    <StyledPaper color={'primary'} sx={{ backgroundColor: backgroundColor, flexGrow: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography p={theme.spacing(1)} variant={typoVariant} sx={{ color: textColor }}>
          {props.children}
        </Typography>
        <ButtonGroup variant='text' aria-label='text button group'>
          <Button color={'reversal'} onClick={() => props.leftButtonClick()}>
            {props.leftButtonText}
          </Button>
          <Button color={'reversal'} onClick={() => props.rightButtonClick()}>
            {props.rightButtonText}
          </Button>
        </ButtonGroup>
      </Box>
    </StyledPaper>
  );
};

export default ButtonGroupHeading;
