import { CylinderShipmentCancelState, DeliveryInfoModel } from './types';

export const defaultDeliveryInfoModel: DeliveryInfoModel = {
  issuanceStatus: '',
  invoiceNumber: '',
  transporterCd: '',
  transporterId: '',
};

/** 出荷戻し画面のストアモデルの初期値 */
export const InitialCylinderShipmentCancelState: CylinderShipmentCancelState = {
  cylMakingList: [],
  deliveryInfo: defaultDeliveryInfoModel,
  customerSectionCd: '',
  rePrintFlg: false,
};
