import { Region } from 'constants/define';
import useApiClient from 'hooks/useApiClient';
import { useAppContextStore } from 'store/appContext/hooks';
import { useAuthStore } from 'store/auth/hooks';
import { Session } from 'store/auth/types';

import { LoginInfoRes } from 'api/types/login/login';

import { LoginForm } from 'pages/login/Login/types';

import { isErrorMessageExist } from 'utils/apiResponse';

import { MessageApi } from './useMessage';

type Auth = {
  login: (info: LoginForm, region: Region) => Promise<boolean>;
  logout: () => Promise<boolean>;
  isLoggedIn: boolean;
  session: Session | null;
};

/** ログイン・ログアウト用Hooks */
export const useAuth = (messageHandler?: MessageApi['pushMessages']): Auth => {
  const { apiClient } = useApiClient(messageHandler ?? (() => undefined));

  // 認証情報のストア
  const authStore = useAuthStore();
  const { save: saveAppContext } = useAppContextStore();

  /** ログイン処理 */
  const login = async (info: LoginForm, region: Region): Promise<boolean> => {
    const userParams = {
      LoginUserId: info.userId,
      Password: info.userPassword,
      Region: region,
    };

    const [loginInfo] = await Promise.all([
      apiClient.post<LoginInfoRes>('api/v1/SystemLogin', userParams),
    ]);
    if (loginInfo.data.status == 200) {
      if (isErrorMessageExist(loginInfo.data.messages ?? [])) {
        return false;
      } else {
        // ログインが成功してる場合はストアにセッション情報をセット
        if (loginInfo.data.result) {
          const session = loginInfo.data.result.session;
          authStore.save({ session });
          saveAppContext({ loggedInRegion: session.region });
        }

        return true;
      }
    } else {
      return false;
    }
  };

  /** ログアウト処理 */
  const logout = async (): Promise<boolean> => {
    const [logoutInfo] = await Promise.all([apiClient.get<null>('api/v1/SystemLogin/Logout')]);

    if (logoutInfo.data.status === 200) {
      authStore.save({ session: null });
      return true;
    } else {
      return false;
    }
  };

  const isLoggedIn = !!authStore.state.session;

  return { login, logout, isLoggedIn, session: authStore.state.session };
};
