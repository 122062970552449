import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultProductionSearchStore } from './defaults';
import { productionSearchStore } from './types';

const slice = createSlice({
  name: 'productionSearch',
  initialState: defaultProductionSearchStore,
  reducers: {
    save: (state, action: PayloadAction<productionSearchStore>) => action.payload,
    clear: () => ({ ...defaultProductionSearchStore }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
