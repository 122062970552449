import React from 'react';

import { useUnitPriceCylMakingRow } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/hooks/price/priceRow/useUnitPriceCylMakingRow';

import {
  MakingPriceRowTarget,
  useMakingPriceRowProperties,
} from '../../../hooks/price/priceRow/useMakingPriceRowProperties';
import MakingPriceRow, { MakingPriceRowProps } from '../Common/MakingPriceRow';

type Props = {
  label: MakingPriceRowProps['label'];
  type: Extract<MakingPriceRowTarget, { category: 'Normal' }>['type'];
};

/** フルコース、 改版、増版 行コンポーネント */
const NormalPriceRow: React.VFC<Props> = ({ label, type }) => {
  const { plateMakingCodes, summaryCodes, unitPriceKeys } = useMakingPriceRowProperties({
    category: 'Normal',
    type,
  });
  const { price } = useUnitPriceCylMakingRow();

  return (
    <MakingPriceRow
      label={label}
      plateMakingCodes={plateMakingCodes}
      summaryCodes={summaryCodes}
      unitPricePropertyNames={unitPriceKeys}
      cylMakingPrice={price}
    />
  );
};

export default React.memo(NormalPriceRow);
