import * as React from 'react';

import { ContentCopy } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type CopyButtonProps = CustomButtonProps;

const CopyButton: React.VFC<CopyButtonProps> = (props) => {
  return (
    <CustomButton icon={<ContentCopy />} {...props}>
      {'コピー'}
    </CustomButton>
  );
};

export default CopyButton;
