import * as apiType from 'api/types/topImage/imageWorkEditPresent';

export type ImageWorkEditPresentMasterResponse = apiType.ImageWorkEditPresentMasterResponse;
export type ImageWorkEditPresentDataResponse = apiType.ImageWorkEditPresentDataResponse;
export type ImageWorkEditPresentWorkUpdateRequest = apiType.ImageWorkEditPresentWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

/** ダウンロード入力フォーム */
export type DownloadForm = {
  design: boolean;
  photoRetouch: boolean;
  colorSeparation: boolean;
  layout: boolean;
  final: boolean;
  send: boolean;
};

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
