import useApiClient from 'hooks/useApiClient';
import { useState } from 'react';

import { ResponseMessage } from 'api/types/common/apiResponse';
import { MyMemoRes } from 'api/types/portalTop/myMemoEdit';

import { MyMemo, MyMemoOriginal } from '../types';

/** MyMemoデータ取得Hooksの型定義 */
type FetchMyMemo = {
  myMemos: MyMemo[];
  originals: MyMemoOriginal[];
  fetch: () => void;
  clear: () => void;
  loading: boolean;
};

/** MyMemoデータ取得Hooks */
export const useFetchMyMemo = (
  messageHandler: (messages: ResponseMessage[]) => void,
): FetchMyMemo => {
  const { apiClient, loading } = useApiClient(messageHandler);

  const [myMemos, setMyMemos] = useState<MyMemo[]>([]);
  const [originals, setOriginals] = useState<MyMemoOriginal[]>([]);

  /** MyMemoデータ取得 */
  const fetch = () => {
    (async () => {
      const response = await apiClient.get<MyMemoRes>(`/api/v1/MyMemoEdit/GetMyMemo`);
      const result = response.data.result;
      if (result) {
        console.log(result.myMemos);
        console.log(result.myMemoOriginals);
        setMyMemos(result.myMemos);
        setOriginals(result.myMemoOriginals);
      }
    })();
  };

  /** MyMemo内部データクリア */
  const clear = () => {
    setMyMemos([]);
    setOriginals([]);
  };

  return { myMemos, originals, fetch, clear, loading };
};
