import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  CylinderOrderEditForm,
  MinimumPriceKey,
  UnitPrice,
  UnitPriceRow as UnitPriceRowType,
} from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { OnlyTypeKey } from 'utils/typeUtils';

import { useUnitPriceRow } from '../../../hooks/price/priceRow/useUnitPriceRow';
import { UnitPriceFixedField } from '../Fields';
import { Row } from './Row';

type Props = {
  label: string;
  propertyName: OnlyTypeKey<UnitPrice, UnitPriceRowType>;
  minimumPricePropertyName?: MinimumPriceKey;
};

/** 工程あたり単価 行コンポーネント */
const UnitPriceRow: React.VFC<Props> = ({ label, propertyName, minimumPricePropertyName }) => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { surfaceArea, price, toyoPriceRate, grossInvoice } = useUnitPriceRow(
    propertyName,
    minimumPricePropertyName,
  );

  return (
    <Row>
      <HeadLabel>{label}</HeadLabel>

      <Controller
        name={`price.unitPrice.${propertyName}.unitPrice`}
        control={control}
        render={({ field }) => (
          <UnitPriceFixedField
            min={0}
            max={999.99}
            decimalDigits={2}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
          />
        )}
      />
      <BodyLabel>{surfaceArea.toFixed()}</BodyLabel>
      <BodyLabel>{price.toFixed()}</BodyLabel>
      <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
      <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
    </Row>
  );
};

export default React.memo(UnitPriceRow);
