import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ToggleButtonProps } from '@mui/material';

import {
  CheckboxProps,
  DatePickerProps,
  IconButtonProps,
  KanaFieldProps,
  SelectProps,
} from 'components/parts/atoms';

import { FlexoOrderEditForm } from '../../types';
import {
  EditCheckbox,
  EditDatePicker,
  EditIconButton,
  EditKanaField,
  EditNumericField,
  EditNumericFieldProps,
  EditSelect,
  EditToggleButton,
} from '../Fields';

// Import the React module

/** 製版情報で使用するセレクト */
export const MakingSelect: React.VFC<SelectProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['making.approvalFlg'] });
  const disabled = props.disabled || approvalFlg;
  return <EditSelect {...props} disabled={disabled} />;
};

/** 製版情報で使用する数字フィールド */
export const MakingNumericField: React.VFC<EditNumericFieldProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['making.approvalFlg'] });
  const disabled = props.disabled || approvalFlg;
  return <EditNumericField {...props} disabled={disabled} />;
};

/** 製版情報で使用するテキストフィールド */
export const MakingKanaField: React.VFC<KanaFieldProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['making.approvalFlg'] });
  const disabled = props.disabled || approvalFlg;
  return <EditKanaField {...props} disabled={disabled} />;
};

/** 製版情報で使用するチェックボックス */
export const MakingCheckbox: React.VFC<CheckboxProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['making.approvalFlg'] });
  const disabled = props.disabled || approvalFlg;
  return <EditCheckbox {...props} disabled={disabled} />;
};

/** 製版情報で使用するアイコンボタン */
export const MakingIconButton: React.VFC<IconButtonProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['making.approvalFlg'] });
  const disabled = props.disabled || approvalFlg;
  return <EditIconButton {...props} disabled={disabled} />;
};

/** 製版情報で使用するトグルボタン */
export const MakingToggleButton: React.VFC<ToggleButtonProps> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['making.approvalFlg'] });
  const disabled = props.disabled || approvalFlg;
  return <EditToggleButton {...props} disabled={disabled} />;
};

/** 製版情報で使用するトグルボタン */
export const MakingDatePicker: React.VFC<DatePickerProps<Date>> = (props) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [approvalFlg] = useWatch({ control, name: ['making.approvalFlg'] });
  const disabled = props.disabled || approvalFlg;
  return <EditDatePicker {...props} disabled={disabled} />;
};
