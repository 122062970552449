import React, { useContext } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import theme from 'themes/theme';

import { Box, Grid } from '@mui/material';

import { HeadLabel, BodyLabel, HeadGrid, BodyGrid, RowGrid } from 'components/parts/atoms';

import { getSelectText } from 'utils/selectUtils';

import { CustomerEditContext } from '../..';
import { CustomerEdit } from '../../types';

const CustomerEditPayment: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();
  const data = useContext(CustomerEditContext);
  const master = data.selectBoxData;

  /** 締日のデータ変更 */
  // ステート
  const closeDateArray = useFieldArray({
    control: formContext.control,
    name: 'closeDates',
  });

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel>締日</BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>締日</HeadLabel>
        </HeadGrid>
        <Grid item xs={8}>
          {closeDateArray.fields.map((item, index) => {
            return (
              <div key={item.id}>
                <RowGrid columns={10}>
                  <BodyGrid xs={10}>
                    <Controller
                      name={`closeDates.${index}.closeDateExplanation`}
                      control={formContext.control}
                      render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                    />
                  </BodyGrid>
                </RowGrid>
              </div>
            );
          })}
        </Grid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書用締日</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={8}>
          <Controller
            name={`customerEditSingleInfo.stateOfDeliveryCloseDate`}
            control={formContext.control}
            render={({ field }) => (
              <>
                <BodyLabel>{field.value === 0 ? '' : field.value}日締</BodyLabel>
              </>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            入金情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>入金条件</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.paymentConditionCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.paymentAndLimitOvers)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>相殺区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.offsetDivision`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === true ? '相殺あり' : '相殺なし'}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>現金限度額</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.cashLimit`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}円</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>現金限度額を超えた時</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.cashOverPaymentConditionCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.paymentAndLimitOvers)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>与信限度額</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.creditLimit`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}円</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>手形サイト</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.billSight`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}日</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>入金予定日</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.paymentPlanMonthId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.paymentDates)}</BodyLabel>
            )}
          />
          <Controller
            name={`customerEditSingleInfo.paymentPlanDate`}
            control={formContext.control}
            render={({ field }) => <BodyLabel> {field.value}日</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <HeadLabel> </HeadLabel>
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            請求先情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>請求先コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.demandSectionCd`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
          -
          <Controller
            name={`customerEditSingleInfo.demandCd`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求先名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.demandName`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>請求条件</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.demandCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.invoiceTerms)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求書送付条件</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.billSendConditionCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.invoiceSendTerms)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            取引情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={5}>
          <HeadLabel>銀行コード - 支店コード - 口座番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name={`customerEditSingleInfo.tradeBankCd`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value} - </BodyLabel>}
          />

          <Controller
            name={`customerEditSingleInfo.tradeBankBranchCd`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value} - </BodyLabel>}
          />

          <Controller
            name={`customerEditSingleInfo.tradeBankAccountCd`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>取引口座名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.tradeAccountName`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>取引振込方法</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.tradeTransFerCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.transactionTransfers)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            消費税情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>計算区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.taxCalcCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.calAndInvoiceOutputs)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>金額端数処理</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.taxAmountFractionProcessCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.amountFractions)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書出力方法</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.taxDeliveryOutputCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.deliveryShips)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求書出力方法</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.taxBillOutputCdId`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.calAndInvoiceOutputs)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            得意先指定伝票情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.customerAppointSlipDeliveryFlg`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === true ? 'あり' : 'なし'}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.customerAppointSlipBillFlg`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === true ? 'あり' : 'なし'}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            東洋インキ向け専用情報（東洋インキのみ入力してください）
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>仕入伝票</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.toyoInkStockingSlipFlg`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === true ? 'あり' : 'なし'}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>製版納品明細書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.toyoInkDeliveryDetailsFlg`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === true ? 'あり' : 'なし'}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.toyoInkDeliveryFlg`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === true ? 'あり' : 'なし'}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>製版計算区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.toyoInkCylmakingStateFlg`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value === true ? 'あり' : 'なし'}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>最終得意先名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.toyoInkFinalCustomerName`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>仕入先コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`customerEditSingleInfo.supplierSectionCd`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
          -
          <Controller
            name={`customerEditSingleInfo.supplierCd`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={2}>
          <HeadLabel>東洋売価率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={8}>
          <Controller
            name={`customerEditSingleInfo.toyoPriceRate`}
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(3)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default CustomerEditPayment;
