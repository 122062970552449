import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialCylinderWorksReadState } from 'store/topPlating/cylinderWorks/defaults';
import { CylinderWorksReadState } from 'store/topPlating/cylinderWorks/types';

const slice = createSlice({
  name: 'CylinderWorks',
  initialState: InitialCylinderWorksReadState,
  reducers: {
    save: (state, action: PayloadAction<CylinderWorksReadState>) => action.payload,
    clear: () => ({ ...InitialCylinderWorksReadState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
