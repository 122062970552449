import useApiClient from 'hooks/useApiClient';
import useMessage, { MessageApi } from 'hooks/useMessage';
import { useState, useEffect } from 'react';

import { DocumentsData } from '../types';

/** 書類棚検索のHooks */
export const useFetchOrderList = (
  jobno: string,
): {
  allLoading: boolean;
  documentsData: DocumentsData[];
  messageApi: MessageApi;
} => {
  const messageApi = useMessage();
  const { apiClient } = useApiClient(messageApi.pushMessages);
  const [allLoading, setAllLoading] = useState(false);
  // 書類棚のState
  const [documentsData, setDocumentsData] = useState<DocumentsData[]>([]);

  // 書類棚検索用のJobNo
  const ducumentSearchParam = {
    jobNo: jobno,
  };

  /** 書類棚検索結果の初期表示 */
  useEffect(() => {
    setAllLoading(true);

    (async () => {
      try {
        const [allDocuments] = await Promise.all([
          apiClient.get<DocumentsData[]>(`/api/v1/DocumentSearchList/GetDocuments`, {
            params: ducumentSearchParam,
          }),
        ]);
        console.log('API CALL');
        console.log(allDocuments.data.result);

        setDocumentsData(allDocuments.data.result ?? []);

        setAllLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setAllLoading(false);
      }
    })();
  }, [jobno]);

  return {
    allLoading,
    documentsData,
    messageApi,
  };
};

export default useFetchOrderList;
