import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FlexoOrderEditForm } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { HeadLabel, BodyLabel, AttentionLabel } from 'components/parts/atoms';

import { useFlexoOrderEditMaster } from '../../hooks/useFlexoOrderEditMaster';
import {
  EditCheckbox,
  EditDatePicker,
  EditKanaField,
  EditNumericField,
  EditSelect,
  ShowOnEdit,
} from '../Fields';
import {
  MakingCheckbox,
  MakingDatePicker,
  MakingKanaField,
  MakingNumericField,
  MakingSelect,
} from './Field';
import { RowThree, RowTwo } from './Rows';

/**
 * Boxを中央揃えに
 */
const Box = styled(MuiBox)(() => ({
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'row',
  justifyContent: 'cneter',
  alignItems: 'center',
}));

/** 製版情報 */
const MakingBasis: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { master } = useFlexoOrderEditMaster();

  return (
    <>
      <RowTwo>
        <Box sx={{ display: 'flex' }}>
          <ShowOnEdit>
            <AttentionLabel>【必須】</AttentionLabel>
          </ShowOnEdit>
          <HeadLabel>刷り方</HeadLabel>
        </Box>
        <Controller
          name={'making.makingBasis.printCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow
              options={master.printList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
            />
          )}
        />
        <HeadLabel>使用紙</HeadLabel>
        <Controller
          name={'making.makingBasis.paperName'}
          control={control}
          render={({ field }) => (
            <MakingKanaField
              maxLength={20}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '16em' }}
            />
          )}
        />
      </RowTwo>
      <RowThree>
        <HeadLabel>版材</HeadLabel>
        <Controller
          name={'making.makingBasis.materialCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow={true}
              options={master.materialList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
            />
          )}
        />
        <HeadLabel>版厚</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <Controller
            name={'making.makingBasis.thickness'}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm</BodyLabel>
        </Box>
        <HeadLabel>原反巾</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>巾</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name={'making.makingBasis.gentanWidth'}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm</BodyLabel>
        </Box>
      </RowThree>
      <RowThree>
        <HeadLabel>版材メーカー</HeadLabel>
        <Controller
          name={'making.makingBasis.materialMakerCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow={true}
              options={master.materialMakerList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '12em' }}
            />
          )}
        />
        <HeadLabel>コーティング</HeadLabel>
        <Controller
          name={'making.makingBasis.coatingCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow={true}
              options={master.coatingList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '12em' }}
            />
          )}
        />
        <HeadLabel>セッター</HeadLabel>
        <Controller
          name={'making.makingBasis.setterCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow={true}
              options={master.setterList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
            />
          )}
        />
      </RowThree>
      <RowThree>
        <HeadLabel>解像度</HeadLabel>
        <Controller
          name={'making.makingBasis.resolutionCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow={true}
              options={master.resolutionList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
            />
          )}
        />
        <HeadLabel>出力OP</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>HD</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name={'making.makingBasis.outputOption'}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={999}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '4em' }}
              />
            )}
          />
        </Box>
        <HeadLabel>Micro Cell</HeadLabel>
        <Controller
          name={'making.makingBasis.microCellCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow={true}
              options={master.microCellList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
            />
          )}
        />
      </RowThree>
      <RowTwo>
        <HeadLabel>InLine UV</HeadLabel>
        <Controller
          name={'making.makingBasis.inlineUvFlg'}
          control={control}
          render={({ field }) => (
            <MakingCheckbox onChange={field.onChange} checked={field.value} name={field.name} />
          )}
        />
        <HeadLabel>前製版日</HeadLabel>
        <Controller
          name={'making.makingBasis.beforeCylMakingDate'}
          control={control}
          render={({ field }) => <MakingDatePicker value={field.value} onChange={field.onChange} />}
        />
      </RowTwo>
    </>
  );
};

export default React.memo(MakingBasis);
