import React from 'react';

import { Customer } from 'api/types/common/customerSearch';

import CustomerSearchBase, { CustomerSearchProps } from 'components/parts/organisms/CustomerSearch';

type Props = Omit<CustomerSearchProps, 'onClose'> & { onClose: (value?: Customer) => void, isWindow?: boolean };

const CustomerSearch: React.VFC<Props> = (props) => {
  const onClose = (value?: Customer[]) => {
    let customer = undefined;
    if (value && value.length > 0) {
      customer = value[0];
    }
    props.onClose(customer);
  };

  return <CustomerSearchBase {...props} onClose={onClose} multipleSelection={false} />;
};

export default CustomerSearch;
