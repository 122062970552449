import { BigNumber } from 'bignumber.js';
import { useCallback } from 'react';

/** 価格の計算処理Hooks */
export const usePriceCalculater = (): {
  calcGrossInvoice: (
    price: number | null,
    toyoPriceRate: number | null,
    toyoPriceRateFlg?: boolean,
  ) => number;
  calcPriceSum: (price: number | null, count: number | null) => number;
  calcGrossInvoiceSum: (
    price: number | null,
    count: number | null,
    toyoPriceRate: number,
    toyoPriceRateFlg?: boolean,
  ) => number;
} => {
  /** 四捨五入 */
  const round = useCallback((price: number, decimalPlaces = 0) => {
    const priceBN = new BigNumber(price);
    const decimalPlacesBN = new BigNumber(decimalPlaces);

    if (decimalPlaces >= 0) {
      return priceBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_HALF_UP).toNumber();
    } else {
      const factor = new BigNumber(10).pow(decimalPlacesBN.abs());
      return priceBN
        .dividedBy(factor)
        .decimalPlaces(0, BigNumber.ROUND_HALF_UP)
        .times(factor)
        .toNumber();
    }
  }, []);

  /** 仕切価格の計算 */
  const calcGrossInvoice = useCallback(
    (price: number | null, toyoPriceRate: number | null, toyoPriceRateFlg = true): number => {
      if (toyoPriceRateFlg) {
        return round(new BigNumber(price ?? 0).times(toyoPriceRate ?? 1).toNumber());
      } else {
        return price ?? 0;
      }
    },
    [],
  );

  /** 売価計の計算 */
  const calcPriceSum = useCallback((price: number | null, count: number | null): number => {
    return new BigNumber(price ?? 0).times(count ?? 0).toNumber();
  }, []);

  /** 仕切計の計算 */
  const calcGrossInvoiceSum = useCallback(
    (
      price: number | null,
      count: number | null,
      toyoPriceRate: number | null,
      toyoPriceRateFlg = true,
    ): number => {
      const grossInvoice = calcGrossInvoice(price, toyoPriceRate, toyoPriceRateFlg);
      return new BigNumber(grossInvoice).times(count ?? 0).toNumber();
    },
    [],
  );

  return {
    calcPriceSum,
    calcGrossInvoiceSum,
    calcGrossInvoice,
  };
};
