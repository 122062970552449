import { EditAnyTimeModel } from './types';

export const initialEditAnyTimeModel: EditAnyTimeModel = {
  arrivaldatetime: null,
  arrivaldate: '',
  arrivaltime: '',
  customerSectionCd: '',
  customerid: null,
  customerCd: '',
  customerName: '',
  temporaryProductname: '',
  cylsizewidth: null,
  cylsizeensyuu: null,
  shaftCdid: '10070',
  addCount: null,
  status: null,
  stockRegistFlg: '',
};
