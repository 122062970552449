import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OtherOrderMetaData } from 'api/types/topSales/order/otherOrderEdit';

import { InitialOtherOrderManagerState } from './defaults';
import { OtherOrderManagerState } from './types';

const slice = createSlice({
  name: 'otherOrderManager',
  initialState: InitialOtherOrderManagerState,
  reducers: {
    save: (state, action: PayloadAction<OtherOrderManagerState>) => action.payload,
    saveMetaData: (state, action: PayloadAction<OtherOrderMetaData>) => {
      if (state.data) {
        state.data.orderMetaData = action.payload;
      }
    },
    clear: () => ({ ...InitialOtherOrderManagerState }),
  },
});

export const { save, saveMetaData, clear } = slice.actions;

export default slice.reducer;
