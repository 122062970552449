import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState, useEffect } from 'react';

import {
  CylinderOrderEditForm,
  CylinderOrderEditFormRes,
  CylinderOrderEditMaster,
  CylinderOrderMetaData,
  GetCylinderOrderMetaDataRes,
} from 'api/types/topSales/order/cylinderOrderEdit';

/** シリンダー受注詳細ダイアログのHooks */
export const useCylinderOrderEdit = (
  jobId: number | null,
  messageApi: MessageApi,
): {
  form: CylinderOrderEditForm;
  master: CylinderOrderEditMaster;
  oderMetaData: CylinderOrderMetaData;
} | null => {
  // Hooks
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // State
  const [orderData, setOrderData] = useState<{
    form: CylinderOrderEditForm;
    master: CylinderOrderEditMaster;
    oderMetaData: CylinderOrderMetaData;
  } | null>(null);

  /** シリンダー受注データ取得処理 */
  const fetch = async (jobId: number) => {
    const [formResponse, metaDataResponse] = await Promise.all([
      apiClient.get<CylinderOrderEditFormRes>(
        '/api/v1/CylinderOrderEdit/GetCylinderOrderEditForm',
        {
          params: { jobId },
        },
      ),
      apiClient.get<GetCylinderOrderMetaDataRes>(`/api/v1/CylinderOrder/GetOrderMetaData`, {
        params: { jobId },
      }),
    ]);

    if (formResponse.data.result && metaDataResponse.data.result) {
      setOrderData({
        form: formResponse.data.result.cylinderOrderEditForm,
        master: formResponse.data.result.master,
        oderMetaData: metaDataResponse.data.result.orderMetaData,
      });
    }
  };

  useEffect(() => {
    if (jobId !== null) {
      fetch(jobId);
    } else {
      setOrderData(null);
    }
  }, [jobId]);

  return orderData;
};
