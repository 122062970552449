/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';

import { ApiResponse } from '../types/common/apiResponse';
import { endpoint } from './config';

const isoDateFormat = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === 'object') handleDates(value);
  }
}

export class ApiClient {
  axios;

  constructor() {
    this.axios = axios.create({
      baseURL: endpoint,
    });
    this.axios.interceptors.response.use((response: any) => {
      handleDates(response.data);
      return response;
    });
  }

  get<T>(url: string, data?: any): Promise<AxiosResponse<ApiResponse<T>>> {
    return this.axios.get<ApiResponse<T>>(url, data);
  }
  post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ApiResponse<T>>> {
    return this.axios.post<ApiResponse<T>>(url, data, config);
  }
}

const apiClient = new ApiClient();

export default apiClient;
