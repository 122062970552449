import * as React from 'react';

import { styled } from '@mui/material/styles';

import { Typography } from 'components/parts/atoms/Typography';

type ErrorMessageProps = {
  children: string;
};
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  return <StyledTypography variant='subtitle1'>{props.children}</StyledTypography>;
};

export default ErrorMessage;
