import { CalculateMethodCd, OrderPriceUpdateMessage } from 'constants/define';
import useSharedMessage from 'hooks/useSharedMessage';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Cached } from '@mui/icons-material';
import { Box, ToggleButtonGroup, Tooltip } from '@mui/material';

import { useFlexoOrderEditMaster } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/hooks/useFlexoOrderEditMaster';
import {
  FlexoOrderEditForm,
  FlxUnitPrice,
} from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { BodyLabel, HeadLabel } from 'components/parts/atoms';

import { usePriceCalculateMatter } from '../../../hooks/price/usePriceCalculateMatter';
import { useResetPrice } from '../../../hooks/price/useResetPrice';
import { usePageContext } from '../../../hooks/usePageContext';
import { PageState } from '../../../types';
import { PriceIconButton, PriceSelect, PriceToggleButton } from '../Fields';
import { Row } from './Row';

/** 価格情報 計算方法 ～ 個別単価 */
const PriceHeader: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { master } = useFlexoOrderEditMaster();

  // 計算方法
  const [calculateMethodCd] = useWatch({ control, name: ['price.calculateMethodCd'] });

  const { flxUnitPrice } = usePriceCalculateMatter();
  const { resetPrices } = useResetPrice();

  const { pageState } = usePageContext();
  const edit = pageState === PageState.Edit;

  // 単価更新押下時に単価を再設定、メッセージを表示
  const messageApi = useSharedMessage();
  const pushResetPrices = (flxUnitPrice: FlxUnitPrice | null) => {
    resetPrices(flxUnitPrice);
    messageApi.clear();
    messageApi.pushInfo(OrderPriceUpdateMessage);
  };

  return (
    <>
      <Row>
        <HeadLabel>計算方法</HeadLabel>
        <Controller
          name='price.calculateMethodCd'
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              value={field.value}
              onChange={field.onChange}
              exclusive
              color='primary'
            >
              <PriceToggleButton
                style={{
                  whiteSpace: 'normal',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                value={CalculateMethodCd.Unit}
              >
                単価計算
                <br /> (手入力)
              </PriceToggleButton>
              <PriceToggleButton value={CalculateMethodCd.FixedUnit}>単価計算</PriceToggleButton>
              <PriceToggleButton value={CalculateMethodCd.Set}>一式計算</PriceToggleButton>
            </ToggleButtonGroup>
          )}
        />
        <HeadLabel>{'フレキソ単価'}</HeadLabel>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            name='price.flxUnitPriceId'
            control={control}
            render={({ field }) => (
              <PriceSelect
                addEmptyRow={true}
                options={master.flxUnitPriceSelectList}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                disabled={calculateMethodCd === CalculateMethodCd.Set}
                sx={{ minWidth: '12em' }}
              />
            )}
          />

          <Box mx={1} />
          <Box
            mx={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <PriceIconButton
              onClick={() => pushResetPrices(flxUnitPrice)}
              disabled={calculateMethodCd === CalculateMethodCd.Set}
              sx={{ padding: '0px' }}
            >
              <Tooltip title='単価を再設定する'>
                <Cached sx={{ fontSize: '2.5rem' }} />
              </Tooltip>
            </PriceIconButton>
            {edit ? <BodyLabel>更新</BodyLabel> : null}
          </Box>
        </Box>
      </Row>
    </>
  );
};

export default React.memo(PriceHeader);
