import { ImageSearchParameter } from './types';

/** 画像検索の検索条件初期値 */
export const initialImageSearchParameter: ImageSearchParameter = {
  jobNo: '',
  oldJobNo: '',
  customerName: '',
  productNameForDisplay: '',
  employeeId: '',
  fileName: '',
  fileComment: '',
  draftDateFrom: null,
  draftDateTo: null,
  imageWorkSchemeDateFrom: null,
  imageWorkSchemeDateTo: null,
  plateMakingWorkSchemeDateFrom: null,
  plateMakingWorkSchemeDateTo: null,
};
