import { EigyoItemCode } from 'constants/define';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import Approval from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/components/Price/Approval';

import { useOtherOrderEditMaster } from '../../hooks/useOtherOrderEditMaster';
import { OtherOrderEditForm } from '../../types';
import OtherProductInfo from './OtherProductInfo';
import Remark from './Remark';
import ScheduleDate from './ScheduleDate';
import UnitPriceSelect from './UnitPriceSelect';
import Url from './Url';

/** シリンダー受注 製版価格ンポーネント */
const Price: React.VFC = () => {
  const { getValues } = useFormContext<OtherOrderEditForm>();
  const { master } = useOtherOrderEditMaster();

  // 特定の品目の場合は項目を固定にする。
  const [eigyoItemCdId] = getValues(['basis.eigyoItemCdId']);
  const eigyoItem = master.eigyoItemList.find((x) => x.value === eigyoItemCdId);
  const isFixedItems =
    eigyoItem?.decisionCode === EigyoItemCode.PlateLiquid ||
    eigyoItem?.decisionCode === EigyoItemCode.Lyocell ||
    eigyoItem?.decisionCode === EigyoItemCode.Usheet;

  return (
    <Box>
      <ScheduleDate />
      <Box my={6} />
      {isFixedItems && (
        <>
          <UnitPriceSelect />
          <Box my={6} />
        </>
      )}
      <OtherProductInfo isFixedItems={isFixedItems} />
      <Box my={6} />
      <Remark />
      <Box my={2} />
      <Url />
      <Box my={2} />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
        <Box sx={{ width: '50%' }}>
          <Approval />
        </Box>
      </Box>
    </Box>
  );
};

export default Price;
