import React, { useState } from 'react';
import theme from 'themes/theme';

import { Box, ButtonBase } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid-pro';

import { Heading, BodyLabel, DataGrid, DataGridProps } from 'components/parts/atoms';

import { ImageSearchDisplayModel } from './types';

type PropsType = {
  data: ImageSearchDisplayModel[];
  pageSize: number;
  loading: boolean;
  onThumbnailClick?: (imageId: number) => void;
  disableSelectionOnClick?: boolean;
  onImageRowSelect?: (image: ImageSearchDisplayModel | null) => void;
  apiRef?: DataGridProps['apiRef'];
};

/** ダイアログ用画像検索一覧コンポーネント */
const ImageList: React.VFC<PropsType> = (props) => {
  // 行選択時の選択データId
  const [selectRow, setSelectRow] = useState<GridSelectionModel>([]);

  const onSelectionModelChange = (model: GridSelectionModel) => {
    setSelectRow(model);

    let result: ImageSearchDisplayModel | null = null;
    if (model.length > 0) {
      result = props.data.find((e) => e.imageDataId === model[0]) ?? null;
    }
    props.onImageRowSelect?.(result);
  };

  // カラムの定義
  const columns: GridColDef[] = [
    {
      field: 'thumnailFileNameS',
      headerName: '　',
      sortable: false,
      width: 35,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ButtonBase onClick={() => props.onThumbnailClick?.(Number(params.id))}>
            <img
              src={params.row.thumnailFileNameS}
              alt='Thumnail Image'
              width={35}
              height={'auto'}
            />
          </ButtonBase>
        );
      },
    },
    {
      field: 'jobNo',
      headerName: 'JOB No.',
      sortable: false,
      width: 140,
    },
    {
      field: 'fileName',
      headerName: 'ファイル名',
      sortable: false,
      resizable: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ whiteSpace: 'pre-wrap', width: '100%', wordWrap: 'break-word' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'folder',
      headerName: 'フォルダ',
      sortable: false,
      resizable: true,
      flex: 3,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ whiteSpace: 'pre-wrap', width: '100%', wordWrap: 'break-word' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'fileComment',
      headerName: 'ファイルコメント',
      sortable: false,
      width: 80,
    },
    {
      field: 'fileSize',
      headerName: 'サイズ(MB)',
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <>
            <BodyLabel>{Number(params.row.fileSize).toFixed(2)}</BodyLabel>
          </>
        );
      },
    },
    {
      field: 'fileType',
      headerName: 'タイプ',
      sortable: false,
      width: 50,
    },
    {
      field: 'draftDate',
      headerName: '入稿日',
      sortable: false,
      width: 90,
      renderCell: (params) => {
        const date = params.row.draftDate;
        let formattedDate = '';

        if (date) {
          const fullYear = date.getFullYear();
          const twoDigitYear = fullYear.toString().slice(2);
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          formattedDate = `${twoDigitYear}/${month}/${day}`;
        }
        return (
          <>
            <BodyLabel>{formattedDate}</BodyLabel>
          </>
        );
      },
    },
    {
      field: 'imageWorkSchemeDate',
      headerName: '画像作業予定日',
      sortable: false,
      width: 90,
      renderCell: (params) => {
        const date = params.row.imageWorkSchemeDate;
        let formattedDate = '';

        if (date) {
          const fullYear = date.getFullYear();
          const twoDigitYear = fullYear.toString().slice(2);
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          formattedDate = `${twoDigitYear}/${month}/${day}`;
        }
        return (
          <>
            <BodyLabel>{formattedDate}</BodyLabel>
          </>
        );
      },
    },
    {
      field: 'plateMakingWorkSchemeDate',
      headerName: '製版作業予定日',
      sortable: false,
      width: 90,
      renderCell: (params) => {
        const date = params.row.plateMakingWorkSchemeDate;
        let formattedDate = '';

        if (date) {
          const fullYear = date.getFullYear();
          const twoDigitYear = fullYear.toString().slice(2);
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          formattedDate = `${twoDigitYear}/${month}/${day}`;
        }
        return (
          <>
            <BodyLabel>{formattedDate}</BodyLabel>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Heading>FOLDERS AND FILES</Heading>
      <Box>
        <DataGrid
          rows={props.data}
          getRowId={(row) => row.imageDataId}
          columns={columns}
          pagination={true}
          pageSize={props.pageSize}
          autoHeight
          rowHeight={75}
          loading={props.loading}
          disableSelectionOnClick={props.disableSelectionOnClick ?? false}
          selectionModel={selectRow}
          onSelectionModelChange={onSelectionModelChange}
          disableMultipleSelection
          apiRef={props.apiRef}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
            '& .MuiDataGrid-columnHeaders': {
              maxHeight: '168px !important',
              backgroundColor: theme.palette.cylinderJob.main,
            },
          }}
        />
      </Box>
    </>
  );
};

export default React.memo(ImageList);
