import { SharedMessageListener } from 'contexts/MessageContext';
import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
}));

const EmptyLayout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '90%' }}>
          <SharedMessageListener />
        </Box>
      </Box>
      {children}
    </Wrapper>
  );
};

export default EmptyLayout;
