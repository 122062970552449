import useSharedMessage from 'hooks/useSharedMessage';
import React, { useEffect, createContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Container, Box } from '@mui/material';

import MessageListener from 'components/Message/MessageListener';
import { CloseButton, Heading } from 'components/parts/atoms';
import { NewWindow } from 'components/parts/atoms/NewWindow';
import { Dialog } from 'components/parts/molecules';
import { FooterButtonsContainer } from 'components/parts/molecules/FooterButtonsContainer';

import CustomerDetailPage from './CustomerDetail';
import { defaultCustomerEdit } from './defaults';
import useFetchCustomerDetail from './hooks/useFetchCustomerDetail';
import { CustomerEdit } from './types';

// 画面マスタ用Context
export const CustomerEditContext = createContext<CustomerEdit>(defaultCustomerEdit);

export type Props = {
  open: boolean;
  onClose: () => void;
  customerId: number;
  isWindow?: boolean;
};

/** 得意先詳細 */
const CustomerDialog: React.VFC<Props> = (props) => {
  const customerId = props.customerId;

  // フックス
  const { customerEdit, fetchCustomer } = useFetchCustomerDetail();

  // isOpenWindowがtrueの場合は別Windowで表示する(左記以外はDialog)
  const [isOpenWindow, setOpenWindow] = useState(false);

  // フォーム
  const formMethod = useForm<CustomerEdit>({ defaultValues: defaultCustomerEdit });
  const messageApi = useSharedMessage();

  // 初期表示
  useEffect(() => {
    (async () => {
      formMethod.reset(await fetchCustomer(customerId));
    })();
  }, [customerId]);

  useEffect(() => {
    setOpenWindow(props.isWindow ?? false);
  }, [props.isWindow]);

  /* ページを閉じる **/
  const onClose = () => {
    formMethod.reset();
    props.onClose();
  };
  // ダイアログ(Window)のBoby部
  const dialogBody = (): JSX.Element => {
    return (
      <>
        <FormProvider {...formMethod}>
          <Container maxWidth='md'>
            <CustomerEditContext.Provider value={customerEdit}>
              <CustomerDetailPage />
            </CustomerEditContext.Provider>
          </Container>
        </FormProvider>
        <Box m={2} />
        <FooterButtonsContainer>
          <CloseButton onClick={onClose} />
        </FooterButtonsContainer>
      </>
    );
  };

  return (
    <>
      {!isOpenWindow && (
        <Dialog
          open={props.open}
          title={'得意先詳細'}
          onClose={onClose}
          messageApi={messageApi}
          height='900px'
        >
          {dialogBody()}
        </Dialog>
      )}

      {isOpenWindow && props.open && (
        <NewWindow features={{ height: 900, width: 1280 }} onUnload={props.onClose}>
          <Heading>得意先詳細</Heading>
          <Box sx={{ m: 1 }}>
            <MessageListener messageApi={messageApi} />
          </Box>
          <Box m={4} />
          {dialogBody()}
        </NewWindow>
      )}
    </>
  );
};

export default CustomerDialog;
