import useSharedMessage from 'hooks/useSharedMessage';
import React from 'react';

import { MessageContainer } from 'components/Message';

const SharedMessageListener: React.VFC = () => {
  const { messages, remove } = useSharedMessage();
  const handleRemove = (id: string) => remove(id);

  return <MessageContainer messages={messages} onRemove={handleRemove} />;
};

export default SharedMessageListener;
