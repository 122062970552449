import { PlateMakingCode, SummaryCode } from 'constants/define';

import { OnlyTypeKey } from 'utils/typeUtils';

import { MakingPriceRowValues, UnitPrice, UnitPriceRow } from '../../../types';
import { useCylinderMakingsAggregation } from '../../useCylinderMakingsAggregation';
import { usePlateMakingId } from '../../usePlateMakingId';
import { useSummaryCdId } from '../../useSummaryCdId';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';
import { MakingPriceRowTarget, useMakingPriceRowProperties } from './useMakingPriceRowProperties';
import { useUnitPriceCylMakingRow } from './useUnitPriceCylMakingRow';
import { useUnitPriceHanberiMakingRow } from './useUnitPriceHanberiMakingRow';
import { useUnitPriceRow } from './useUnitPriceRow';

const useUnitPrices = () => {
  const { price: materials } = useUnitPriceRow('materials', 'materialMinimumPrice9');
  const { price: cuPlate } = useUnitPriceRow('cuPlate', 'cuPlateMinimum9');
  const { price: crPlate } = useUnitPriceRow('crPlate', 'crPlateMinimum9');
  const { price: finishingPolish } = useUnitPriceRow('finishingPolish');
  const { price: rakuhanPolish } = useUnitPriceRow('rakuhanPolish', 'rakuhanMinimum9');
  const { price: reCr } = useUnitPriceRow('reCr', 'reCrPlateMinimum9');

  return {
    materials,
    cuPlate,
    crPlate,
    finishingPolish,
    rakuhanPolish,
    reCr,
  };
};

/** 製版価格 行情報 Hooks */
export const useMakingPriceRow = (
  plateMakingCodes: PlateMakingCode[] | null,
  summaryCodes: SummaryCode[] | null,
  unitPriceKeys: Exclude<OnlyTypeKey<UnitPrice, UnitPriceRow>, 'cylMaking'>[],
  cylMakingPrice: number,
): MakingPriceRowValues => {
  // hook
  const { findByCode: findPlateMakingIdByCode } = usePlateMakingId();
  const { findByCode: findSummaryCdIdByCode } = useSummaryCdId();
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcMakingUnitPrice, calcMakingPrice, calcMakingGrossInvoice } = usePriceCalculater();

  // ウォッチ
  const unitPrices = useUnitPrices();

  const { cylinderMakings } = useCylinderMakingsAggregation();

  let filteredCylinderMakings = [...cylinderMakings];

  // 対象の製版区分で抽出
  if (plateMakingCodes) {
    const plateMakingIds = plateMakingCodes.map((e) => findPlateMakingIdByCode(e));
    filteredCylinderMakings = cylinderMakings.filter((e) =>
      plateMakingIds.includes(e.plateMakingId),
    );
  }

  // 対象の摘要で抽出
  if (summaryCodes) {
    const summaryCdIds = summaryCodes.map((e) => findSummaryCdIdByCode(e));
    filteredCylinderMakings = filteredCylinderMakings.filter((e) =>
      summaryCdIds.includes(e.summaryCdId),
    );
  }

  // 対象となる工程の売価の合計金額を集計
  const prices = unitPriceKeys.map((e) => unitPrices[e]);

  /** 単価 */
  const unitPrice = calcMakingUnitPrice(prices, cylMakingPrice);

  /** 版数 */
  const amount = filteredCylinderMakings.length;

  /** 売価 */
  const price = calcMakingPrice(prices, amount, cylMakingPrice);

  /** 仕切 */
  const grossInvoice = calcMakingGrossInvoice(prices, amount, toyoPriceRate, cylMakingPrice);

  return {
    unitPrice,
    amount,
    price,
    toyoPriceRate,
    grossInvoice,
  };
};

/** 製版価格 行情報 Hooks */
export const useNormalPriceRow = (
  type: Extract<MakingPriceRowTarget, { category: 'Normal' }>['type'],
): MakingPriceRowValues => {
  const { price } = useUnitPriceCylMakingRow();
  const { plateMakingCodes, summaryCodes, unitPriceKeys } = useMakingPriceRowProperties({
    category: 'Normal',
    type,
  });

  const values = useMakingPriceRow(plateMakingCodes, summaryCodes, unitPriceKeys, price);
  return values;
};

/** 製版価格 行情報 Hooks */
export const useHanberiPriceRow = (
  type: Extract<MakingPriceRowTarget, { category: 'Hanberi' }>['type'],
): MakingPriceRowValues => {
  const { price } = useUnitPriceHanberiMakingRow();
  const { plateMakingCodes, summaryCodes, unitPriceKeys } = useMakingPriceRowProperties({
    category: 'Hanberi',
    type,
  });

  const values = useMakingPriceRow(plateMakingCodes, summaryCodes, unitPriceKeys, price);
  return values;
};

/** 製版価格 行情報 Hooks */
export const useReChroniumPriceRow = (
  type: Extract<MakingPriceRowTarget, { category: 'ReChronium' }>['type'],
): MakingPriceRowValues => {
  const { plateMakingCodes, summaryCodes, unitPriceKeys } = useMakingPriceRowProperties({
    category: 'ReChronium',
    type,
  });

  const values = useMakingPriceRow(plateMakingCodes, summaryCodes, unitPriceKeys, 0);
  return values;
};
