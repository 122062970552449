import * as React from 'react';

import { Search } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type SearchButtonProps = CustomButtonProps;

const SearchButton: React.VFC<SearchButtonProps> = (props) => {
  return (
    <CustomButton icon={<Search />} {...props}>
      {'検索'}
    </CustomButton>
  );
};

export default SearchButton;
