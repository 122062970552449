import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialHolidayCalenderListCondition } from './defaults';
import { HolidayCalenderListConditionState } from './types';

const slice = createSlice({
  name: 'holidayCalenderListCondition',
  initialState: initialHolidayCalenderListCondition,
  reducers: {
    save: (state, action: PayloadAction<HolidayCalenderListConditionState>) => action.payload,
    clear: () => ({ ...initialHolidayCalenderListCondition }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
