import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './defaults';
import { ImageDataUpLoadState } from './types';

const slice = createSlice({
  name: 'imageDataUpLoad',
  initialState,
  reducers: {
    setProcessTarget(state, action: PayloadAction<ImageDataUpLoadState['processTarget']>) {
      state.processTarget = action.payload;
    },
    setFromPage(state, action: PayloadAction<ImageDataUpLoadState['fromPage']>) {
      state.fromPage = action.payload;
    },
    setIsAddMode(state, action: PayloadAction<ImageDataUpLoadState['page']['isAddMode']>) {
      state.page.isAddMode = action.payload;
    },
    setInitialProcessState(
      state,
      action: PayloadAction<ImageDataUpLoadState['page']['initialProcessState']>,
    ) {
      state.page.initialProcessState = action.payload;
    },
    setInitialProcessResult(
      state,
      action: PayloadAction<Pick<ImageDataUpLoadState['page'], 'destinations' | 'isAddable'>>,
    ) {
      state.page.destinations = action.payload.destinations;
      state.page.isAddable = action.payload.isAddable;
    },
    clear: () => ({
      ...initialState,
    }),
  },
});

export const {
  setProcessTarget,
  setFromPage,
  setIsAddMode,
  setInitialProcessState,
  setInitialProcessResult,
  clear,
} = slice.actions;

export default slice.reducer;
