import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, useTheme } from '@mui/material';

import { OtherOrderEditForm } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/types';

import { HeadLabel, BodyLabel, Circular } from 'components/parts/atoms';

import { dateText } from 'utils/date';
import { getSelectText } from 'utils/selectUtils';

import { useOtherOrderEditMaster } from '../../hooks/useOtherOrderEditMaster';
import { usePageContext } from '../../hooks/usePageContext';
import { RowOne, RowTwo, RowThree, RowThreeFinal } from './Rows';

/** 受注ヘッダーコンポーネント */
const OtherOrderHeader: React.VFC = () => {
  // Context
  const formContext = useFormContext<OtherOrderEditForm>();
  const { orderMetaData } = usePageContext();
  const { master } = useOtherOrderEditMaster();
  const theme = useTheme();

  const header = formContext.getValues('header');

  // ウォッチ
  const [orderDate, deliveryDate, customerChargeId, eigyoItemCdId, productionName, jobName] =
    useWatch({
      control: formContext.control,
      name: [
        'price.scheduleDate.orderDate',
        'price.scheduleDate.deliveryDate',
        'basis.customerChargeId',
        'basis.eigyoItemCdId',
        'basis.productionName',
        'basis.jobName',
      ],
    });

  if (!orderMetaData) {
    return <Circular />;
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <RowThree>
          <HeadLabel>登録日</HeadLabel>
          <BodyLabel>{dateText(header.tjobRecordRegistDay)}</BodyLabel>
          <HeadLabel>最終更新日</HeadLabel>
          <BodyLabel>{dateText(header.tjobRecordEditDay)}</BodyLabel>
          <HeadLabel>発行日</HeadLabel>
          <BodyLabel>{dateText(header.issueDate)}</BodyLabel>
        </RowThree>
        <RowThree>
          <HeadLabel>受注日</HeadLabel>
          <BodyLabel>{dateText(orderDate)}</BodyLabel>
          <HeadLabel>納品日</HeadLabel>
          <BodyLabel>{dateText(deliveryDate.day)}</BodyLabel>
          <HeadLabel>作成者</HeadLabel>
          <BodyLabel>{header.registEmployeeName}</BodyLabel>
        </RowThree>
        <RowThree>
          <HeadLabel>JOB No.</HeadLabel>
          <BodyLabel>{header.jobNo}</BodyLabel>
          <HeadLabel>参照JOB No.</HeadLabel>
          <BodyLabel>{header.referenceJobNo}</BodyLabel>
          <HeadLabel>シリーズマスター</HeadLabel>
          <BodyLabel>{header.seriesMaster}</BodyLabel>
        </RowThree>
        <RowTwo>
          <HeadLabel>部門/得意先コード</HeadLabel>
          <BodyLabel>
            {header.customerSectionCd}-{header.customerCd}
          </BodyLabel>
          <HeadLabel>得意先名</HeadLabel>
          <BodyLabel>{`${header.customerName} ${getSelectText(
            customerChargeId,
            master.customerChargeList,
          )}`}</BodyLabel>
        </RowTwo>
        <RowOne>
          <HeadLabel>品目</HeadLabel>
          <BodyLabel>{getSelectText(eigyoItemCdId, master.eigyoItemList)}</BodyLabel>
        </RowOne>
        <RowThreeFinal>
          <HeadLabel>品名</HeadLabel>
          <BodyLabel>{`${productionName} ${jobName}`}</BodyLabel>
          <HeadLabel>最終更新者</HeadLabel>
          <BodyLabel>{header.editEmployeeName}</BodyLabel>
          <Box display={'flex'}>
            {orderMetaData.isRepeatOrder && (
              <BodyLabel sx={{ color: theme.palette.cyclonistRed.main, fontWeight: 'bold' }}>
                リピートオーダー
              </BodyLabel>
            )}
            {!orderMetaData.isRepeatOrder && (
              <BodyLabel
                sx={{ color: theme.palette.cyclonistDefaultBlue.main, fontWeight: 'bold' }}
              >
                新規受注
              </BodyLabel>
            )}
          </Box>
        </RowThreeFinal>
      </Box>
    </>
  );
};

export default OtherOrderHeader;
