import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { HeadLabel } from 'components/parts/atoms';

import { CylinderOrderEditForm } from '../../../types';
import { Heading } from '../../Heading';
import { HeadRow } from './Row';
import SetPriceRow from './SetPriceRow';

/** 一式計算コンポーネント */
const SetPrice: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const [cylinderMakingsMain, cylinderMakingsReverse] = useWatch({
    control,
    name: ['making.cylinderMakingsMain', 'making.cylinderMakingsReverse'],
  });

  return (
    <>
      <Heading>一式計算</Heading>
      <HeadRow>
        <HeadLabel>刷順</HeadLabel>
        <HeadLabel>単価</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>
      {cylinderMakingsMain.map((item, index) => {
        if (item.plateMakingId !== '') {
          return (
            <SetPriceRow
              key={item.cylMakingJobId}
              propertyName='cylinderMakingsMain'
              index={index}
            />
          );
        } else {
          return null;
        }
      })}
      {cylinderMakingsReverse.map((item, index) => {
        if (item.plateMakingId !== '') {
          return (
            <SetPriceRow
              key={item.cylMakingJobId}
              propertyName='cylinderMakingsReverse'
              index={index}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default React.memo(SetPrice);
