import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { persistor, RootState, store } from 'store';

import { save, clear } from '.';
import { MakingSpecificationState } from './types';

/** 製版指示書StoreのHooks */
export const useMakingSpecificationStore = (): {
  state: MakingSpecificationState;
  save: (state: MakingSpecificationState) => void;
  clear: () => void;
  getLatestState: () => MakingSpecificationState;
} => {
  /** State */
  const state = useSelector((state: RootState) => state.makingSpecification);

  const dispatch = useDispatch();

  /** Stateに登録 */
  const dispatchSave = useCallback(
    (state: MakingSpecificationState) => {
      dispatch(save(state));

      // ストレージに即書き込みする
      // 製版指示書ウィンドウ表示時に、ストレージ書き込み前の値が参照されないようにするため。
      persistor.flush();
    },
    [dispatch],
  );

  /** Stateを初期化 */
  const dispatchClear = useCallback(() => {
    dispatch(clear());
    persistor.flush();
  }, []);

  /** 最新の状態を取得 */
  const getLatestState = useCallback(() => store.getState().makingSpecification, []);

  return {
    state,
    save: dispatchSave,
    clear: dispatchClear,
    getLatestState,
  };
};
