import { useAuth } from 'hooks/useAuth';
import React, { useState, useEffect, useContext } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { Dialog } from 'components/parts/molecules';

import { CustomerEditContext } from '../CustomerEdit';
import { defaultUnitPriceFlexoModel } from '../CustomerEdit/defaults';
import { CustomerEdit } from '../CustomerEdit/types';
import UnitPriceEditFlexo from './UnitPriceEdit';
import UnitPriceEditCheckFlexo from './UnitPriceEditCheck';
import { useCheckUnitPriceFlexo } from './hooks/useCheckUnitPrice';
import { useFetchUnitPriceFlexo } from './hooks/useFetchUnitPrice';
import { PageState, InputCheck, FlexoMainProps, FlexoForm } from './types';

// 単価入力・確認のメインページ
// props(bool値,closeイベント,行特定index(新規の場合は-1),単価入力・確認用オブジェクト)
const UnitPriceEditFlexoMain: React.VFC<FlexoMainProps> = (props) => {
  // ページステート
  const [pageState, setPageState] = useState<PageState>(PageState.Edit);

  // ログインユーザ
  const { session } = useAuth();
  if (!session) {
    throw new Error();
  }

  // hooks
  const { toyoRate, setToyoRate, getToyoRate } = useFetchUnitPriceFlexo();
  const { messageApi, IsInputCheckAsync } = useCheckUnitPriceFlexo();

  // フレキソ単価登録専用フォーム
  const unitPriceFlexoFormMethod = useForm<FlexoForm>({
    defaultValues: defaultUnitPriceFlexoModel,
  });

  // 売価率判定用情報取得
  const formContext = useFormContext<CustomerEdit>();
  const customerSectionCd = formContext.getValues('customerEditSingleInfo.customerSectionCd');
  const cdid = formContext.getValues('customerEditSingleInfo.sectionPeculiarInfo');
  const data = useContext(CustomerEditContext);
  const salesGroup = data.selectBoxData.sections.find((p) => p.value === cdid);

  /** 画面初期表示 */
  // 初期表示
  useEffect(() => {
    unitPriceFlexoFormMethod.reset(props.state);
    unitPriceFlexoFormMethod.setValue('familyName', session.familyName);
    unitPriceFlexoFormMethod.setValue('registDate', new Date());
    unitPriceFlexoFormMethod.setValue('fullName', session.fullName);
    // 画面表示時かつ新規の場合
    if (props.state.flxUnitPriceId === -1 && props.open) {
      if (props.state.toyoPriceRate) {
        setToyoRate(props.state.toyoPriceRate ?? 0);
      } else {
        getToyoRate(customerSectionCd, salesGroup?.decisionCode ?? '');
      }
      // 東洋売価率セット
      unitPriceFlexoFormMethod.setValue('toyoPriceRate', toyoRate);
    }
    if (props.checkFinishFlag) {
      setPageState(PageState.Check);
    }
  }, [props.open]);

  // 東洋売価率セット
  useEffect(() => {
    unitPriceFlexoFormMethod.setValue('toyoPriceRate', toyoRate);
  }, [toyoRate]);

  /** 単価入力 */
  // （単価入力確認に）進む
  const onGoCheck = unitPriceFlexoFormMethod.handleSubmit(async (data: FlexoForm) => {
    messageApi.clear();
    // 入力チェック
    const inputCondition: InputCheck = {
      unitPriceName: data.unitPriceName,
    };
    const inputCheckFlag: boolean = await IsInputCheckAsync(inputCondition);
    if (inputCheckFlag) {
      setPageState(PageState.Check);
    }
  });
  // 戻る（ダイアログを閉じる）
  const onBackCustomerEdit = () => {
    messageApi.clear();
    setPageState(PageState.Edit);
    unitPriceFlexoFormMethod.reset(defaultUnitPriceFlexoModel);
    props.onClose(undefined);
  };

  /** 単価入力確認ボタンイベント */
  // 得意先入力のフォームに登録
  const onAddFormArray = unitPriceFlexoFormMethod.handleSubmit((data: FlexoForm) => {
    setPageState(PageState.Edit);
    props.onClose(data);
    unitPriceFlexoFormMethod.reset(defaultUnitPriceFlexoModel);
  });
  // （編集に）戻る
  const onBackEdit = () => {
    if (props.checkFinishFlag) {
      props.onClose(undefined);
    }
    setPageState(PageState.Edit);
  };

  /** ダイアログを閉じる際の処理 */
  const onCloseDialog = () => {
    messageApi.clear();
    setPageState(PageState.Edit);
    unitPriceFlexoFormMethod.reset(defaultUnitPriceFlexoModel);
    props.onClose(undefined);
  };

  return (
    <Dialog
      open={props.open}
      title={pageState === PageState.Edit ? '単価入力' : '単価入力確認'}
      onClose={() => onCloseDialog()}
      messageApi={messageApi}
    >
      <FormProvider {...unitPriceFlexoFormMethod}>
        {(() => {
          switch (pageState) {
            // 単価入力
            case PageState.Edit:
              return <UnitPriceEditFlexo onBack={onBackCustomerEdit} onGoCheck={onGoCheck} />;
            // 単価入力確認
            case PageState.Check:
              return (
                <UnitPriceEditCheckFlexo
                  onBack={onBackEdit}
                  onAddForm={onAddFormArray}
                  checkFinishFlag={props.checkFinishFlag}
                />
              );
            default:
              throw new Error();
          }
        })()}
      </FormProvider>
    </Dialog>
  );
};

export default UnitPriceEditFlexoMain;
