// MenuRes
export type MenusRes = {
  menuCategories: MenuCategory[];
};

export type MenuCategory = {
  menuDiv: MenuDiv;
  menuName: string;
  menuItems: MenuItem[];
};

export type MenuItem = {
  menuId: string;
  menuName: string;
  actionString?: string;
  linkFlg: MenuLinkType;
};

export const menuDiv = {
  WORKS: 1,
  SCHEDULE: 2,
  NIPPO: 3,
  ETC: 4,
} as const;
export type MenuDiv = typeof menuDiv[keyof typeof menuDiv];

export const menuLinkType = {
  LABEL: 0,
  LINK: 1,
} as const;
export type MenuLinkType = typeof menuLinkType[keyof typeof menuLinkType];

// EmployeeInfoRes
export type EmployeeInfoRes = {
  employeeId: string;
  employeeNo: string;
  fullName: string;
  divisionNames: string[];
};

// LateUseJobsRes
export type LateUseJob = {
  jobId: string;
  jobNo: string;
  productionCategory: string;
};

export type LateUseJobsRes = {
  jobs: LateUseJob[];
};

// MyMemosRes
export type MyMemo = {
  myMemoId: string;
  memo: string;
};

export type MyMemosRes = {
  myMemos: MyMemo[];
};

// WorkPlanCountInfoRes
export type WorkPlanCountInfoRes = {
  arrivalPlanCount?: number;
  cylMakingPlanCount?: number;
  shipmentPlanCount?: number;
  imageSyukkouPlanCount?: number;
  yearlyLossandRemakeCylRate?: number;
  monthlyLossandRemakeCylRate?: number;
};

// InformationsRes
export type InformationsRes = {
  informationCategories: InformationCategory[];
};
export type InformationCategory = {
  informationCategoryId: string;
  informationTitle: string;
  foregroundColor: string;
  backgroundColor: string;
  iconFileName: string;
  informations: Information[];
};

export type Information = {
  informationId: string;
  informationTitle: string;
  informationContents: string;
  registdate: Date;
};
