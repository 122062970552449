import React, { useState } from 'react';

import { useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { Typography } from 'components/parts/atoms/Typography';

export type ImageBackdropProps = {
  displayText: string;
  displayFlg: boolean;
};

const ImageBackdrop: React.VFC<ImageBackdropProps> = (props) => {
  const theme = useTheme();

  return (
    <Backdrop
      open={props.displayFlg}
      sx={{ color: theme.palette.primary.contrastText, zIndex: theme.zIndex.drawer + 1 }}
    >
      <Box sx={{ width: '80%' }}>
        <Typography sx={{ textAlign: 'center' }}>{props.displayText}</Typography>
        <Typography sx={{ textAlign: 'center' }}>しばらくお待ち下さい。</Typography>
        <Box m={2} />
        <LinearProgress />
      </Box>
    </Backdrop>
  );
};

export default ImageBackdrop;
