import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialImageSearchParameter } from './defaults';
import { ImageSearchParameterState } from './types';

const slice = createSlice({
  name: 'imageDataSearch',
  initialState: initialImageSearchParameter,
  reducers: {
    save: (state, action: PayloadAction<ImageSearchParameterState>) => action.payload,
    clear: () => ({ ...initialImageSearchParameter }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
