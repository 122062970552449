import { BarcodeReadLaserState } from './types';

// /** バーコード読み取り画面のストアモデルの初期値 */
// export const InitialBarcodeReadLaserState: BarcodeReadLaserState = {
//   cylMakingList: [],
//   pageState: false,
//   cylinderWorksFlg: false,
// };

/** バーコード読み取り画面のストアモデルの初期値 */
export const InitialBarcodeReadLaserState: BarcodeReadLaserState = {
  storeData: null,
};
