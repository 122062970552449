import { useFormContext, useWatch } from 'react-hook-form';

import { CylinderMaking, CylinderOrderEditForm } from '../types';
import { usePlateMakingId } from './usePlateMakingId';

/** 製版情報集計Hooks */
export const useCylinderMakingsAggregation = (): {
  cylinderMakings: CylinderMaking[];
  costMakings: CylinderMaking[];
  hasFreeCylinderWithoutAwaseChousa: boolean;
} => {
  const { control } = useFormContext<CylinderOrderEditForm>();
  const [cylinderMakingsMain, cylinderMakingsReverse] = useWatch({
    control,
    name: ['making.cylinderMakingsMain', 'making.cylinderMakingsReverse'],
  });
  const cylinderMakings = [...cylinderMakingsMain, ...cylinderMakingsReverse];

  const { fullId, kaihanId, zouhanId, hanberiId, reChromeId, awaseId, chousaId } =
    usePlateMakingId();

  // 無償のシリンダーであるか
  const isFree = (cm: CylinderMaking) => {
    switch (cm.plateMakingId) {
      case fullId:
      case kaihanId:
      case zouhanId:
      case hanberiId:
      case reChromeId:
        return false;
      default:
        return true;
    }
  };

  // 合せor調査版であるか
  const isAwaseChousa = (cm: CylinderMaking) => {
    switch (cm.plateMakingId) {
      case awaseId:
      case chousaId:
        return true;
      default:
        return false;
    }
  };

  // 合せ版、調査版を除き無償のシリンダーを１本でも持っているか
  const hasFreeCylinderWithoutAwaseChousa: boolean = (() => {
    return cylinderMakings.some((e) => e.plateMakingId && isFree(e) && !isAwaseChousa(e));
  })();

  const costMakings = cylinderMakings.filter(
    (e) => e.plateMakingId && (!isFree(e) || isAwaseChousa(e)),
  );

  return {
    cylinderMakings,
    costMakings,
    hasFreeCylinderWithoutAwaseChousa,
  };
};
