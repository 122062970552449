import * as apiType from 'api/types/topImage/imageWorkEditHistoryPresent';

export type ImageWorkEditPresentMasterResponse = apiType.ImageWorkEditPresentMasterResponse;
export type ImageWorkEditPresentDataResponse = apiType.ImageWorkEditPresentDataResponse;
export type ImageWorkEditPresentWorkUpdateRequest = apiType.ImageWorkEditPresentWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
