import { BigNumber } from 'bignumber.js';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel, BodyLabel, AttentionLabel } from 'components/parts/atoms';

import { useCylinderOrderEditMaster } from '../../hooks/useCylinderOrderEditMaster';
import { useFormSetState } from '../../hooks/useFormSetState';
import { EditDatePicker, EditKanaField, EditNumericField, EditSelect, ShowOnEdit } from '../Fields';
import { MakingDatePicker, MakingKanaField, MakingNumericField, MakingSelect } from './Field';
import { RowOne, RowThree, RowTwo } from './Rows';

/**
 * Boxを中央揃えに
 */
const Box = styled(MuiBox)(() => ({
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'row',
  justifyContent: 'cneter',
  alignItems: 'center',
}));

/** 製版情報 */
const MakingBasis: React.VFC = () => {
  // コンテキスト
  const { control, setValue } = useFormContext<CylinderOrderEditForm>();
  const { master } = useCylinderOrderEditMaster();
  const { afterFormSet } = useFormSetState();

  const [
    frontPageSizeWidth,
    frontPageSizeGrain,
    impositioningWidth,
    impositioningGrain,
    grooveWidth,
    grooveWidthAmount,
    grooveGrain,
    grooveGrainAmount,
  ] = useWatch({
    control,
    name: [
      'making.makingBasis.frontPageSizeWidth',
      'making.makingBasis.frontPageSizeGrain',
      'making.makingBasis.impositioningWidth',
      'making.makingBasis.impositioningGrain',
      'making.makingBasis.grooveWidth',
      'making.makingBasis.grooveWidthAmount',
      'making.makingBasis.grooveGrain',
      'making.makingBasis.grooveGrainAmount',
    ],
  });

  function roundToThreeDecimalPlaces(num: number): number {
    const numTimes = new BigNumber(num ?? 0).times(1000).toNumber();
    const roundNum = Math.round(numTimes);
    if (roundNum == 0) {
      return 0;
    }
    return new BigNumber(roundNum).div(1000).toNumber();
  }

  useEffect(() => {
    // 初期表示時は値セットを行わない
    if (!afterFormSet) return;

    // 全面寸法 巾を自動計算
    if (
      frontPageSizeWidth !== null ||
      impositioningWidth !== null ||
      grooveWidth !== null ||
      grooveWidthAmount !== null
    ) {
      const one = new BigNumber(frontPageSizeWidth ?? 0).times(impositioningWidth ?? 0).toNumber();
      const two = new BigNumber(grooveWidth ?? 0).times(grooveWidthAmount ?? 0).toNumber();
      const width = new BigNumber(one ?? 0).plus(two ?? 0).toNumber();
      const roundWidth = roundToThreeDecimalPlaces(width);
      setValue('making.makingBasis.overAllSizeWidth', roundWidth);
    }
  }, [frontPageSizeWidth, impositioningWidth, grooveWidth, grooveWidthAmount]);

  useEffect(() => {
    // 初期表示時は値セットを行わない
    if (!afterFormSet) return;

    // 全面寸法 流を自動計算
    if (
      frontPageSizeGrain !== null ||
      impositioningGrain !== null ||
      grooveGrain !== null ||
      grooveGrainAmount !== null
    ) {
      const one = new BigNumber(frontPageSizeGrain ?? 0).times(impositioningGrain ?? 0).toNumber();
      const two = new BigNumber(grooveGrain ?? 0).times(grooveGrainAmount ?? 0).toNumber();
      const grain = new BigNumber(one ?? 0).plus(two ?? 0).toNumber();
      const roundGrain = roundToThreeDecimalPlaces(grain);
      setValue('making.makingBasis.overAllSizeGrain', roundGrain);
    }
  }, [frontPageSizeGrain, impositioningGrain, grooveGrain, grooveGrainAmount]);

  return (
    <>
      <RowTwo>
        <Box sx={{ display: 'flex' }}>
          <ShowOnEdit>
            <AttentionLabel>【必須※】</AttentionLabel>
          </ShowOnEdit>
          <HeadLabel>刷り方</HeadLabel>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Controller
            name={'making.makingBasis.printCdId'}
            control={control}
            render={({ field }) => (
              <MakingSelect
                addEmptyRow
                options={master.printList}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <ShowOnEdit>
            <AttentionLabel>※『シリンダー出荷あり』の時は必須です</AttentionLabel>
          </ShowOnEdit>
        </Box>
        <HeadLabel>印刷方向</HeadLabel>
        <Controller
          name={'making.makingBasis.orientationCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              addEmptyRow
              options={master.orientationList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
            />
          )}
        />
      </RowTwo>
      <RowThree>
        <Box sx={{ display: 'flex' }}>
          <ShowOnEdit>
            <AttentionLabel>【必須※】</AttentionLabel>
          </ShowOnEdit>
          <HeadLabel>シリンダーサイズ</HeadLabel>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>巾</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name={'making.makingBasis.cylSizeWidth'}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={9999}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '4.3em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm × 円周</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name={'making.makingBasis.cylSizeEnsyuu'}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={9999.99}
                min={0}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm</BodyLabel>
        </Box>
        <HeadLabel>シャフト</HeadLabel>
        <Controller
          name={'making.makingBasis.shaftCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              options={master.shaftList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
            />
          )}
        />
        <HeadLabel>素材</HeadLabel>
        <Controller
          name={'making.makingBasis.materialCdId'}
          control={control}
          render={({ field }) => (
            <MakingSelect
              options={master.materialList}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
            />
          )}
        />
      </RowThree>

      <Box my={4} />

      <RowTwo>
        <HeadLabel>1面寸法</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>巾</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name={'making.makingBasis.frontPageSizeWidth'}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm × 流</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name={'making.makingBasis.frontPageSizeGrain'}
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm</BodyLabel>
        </Box>
        <HeadLabel>前製版日</HeadLabel>
        <Controller
          name={'making.makingBasis.beforeCylMakingDate'}
          control={control}
          render={({ field }) => <MakingDatePicker value={field.value} onChange={field.onChange} />}
        />
      </RowTwo>
      <RowOne>
        <HeadLabel>面付</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>{'巾'}</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.impositioningWidth'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '3em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>面 × 流</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.impositioningGrain'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '3em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>面</BodyLabel>
        </Box>
      </RowOne>
      <RowTwo>
        <HeadLabel>ドブ</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>{'巾'}</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.grooveWidth'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={9999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm ×</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.grooveWidthAmount'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '3em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>/ 流</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.grooveGrain'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={9999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>{'mm　×　'}</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.grooveGrainAmount'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99}
                min={0}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '3em' }}
              />
            )}
          />
        </Box>
        <HeadLabel>使用紙</HeadLabel>
        <Controller
          name='making.makingBasis.paperName'
          control={control}
          render={({ field }) => (
            <MakingKanaField
              maxLength={20}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '16em' }}
            />
          )}
        />
      </RowTwo>
      <RowTwo>
        <HeadLabel>全面寸法</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>巾</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.overAllSizeWidth'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm × 流</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.overAllSizeGrain'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm</BodyLabel>
        </Box>
        <HeadLabel>原反巾</HeadLabel>
        <Box sx={{ display: 'flex' }}>
          <BodyLabel>{'巾'}</BodyLabel>
          <Box mx={0.5} />
          <Controller
            name='making.makingBasis.gentanWidth'
            control={control}
            render={({ field }) => (
              <MakingNumericField
                max={99999.999}
                min={0}
                decimalDigits={3}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '7em' }}
              />
            )}
          />
          <Box mx={0.5} />
          <BodyLabel>mm</BodyLabel>
        </Box>
      </RowTwo>
    </>
  );
};

export default React.memo(MakingBasis);
