import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CylinderOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';

/** 製版区分価変更時にフォームをリセットするHooks */
export const usePlateMakingWatcher = (
  propertyName: 'cylinderMakingsMain' | 'cylinderMakingsReverse',
  index: number,
  enabled: boolean,
): void => {
  // フォーム
  const { setValue, getValues } = useFormContext<CylinderOrderEditForm>();
  const cylinderMaking = getValues(`making.${propertyName}.${index}`);

  const { defaultCylUnitPrice } = usePriceCalculateMatter();
  const defaultToyoPriceRate = defaultCylUnitPrice?.toyoPriceRate ?? null;

  useEffect(() => {
    if (!enabled) return;

    if (!cylinderMaking.plateMakingId) {
      setValue(`making.${propertyName}.${index}.completeSetCalcUnitPrice`, 0);
      setValue(`making.${propertyName}.${index}.completeSetCalcToyoPriceRate`, null);
    } else {
      if (cylinderMaking.completeSetCalcToyoPriceRate === null) {
        setValue(
          `making.${propertyName}.${index}.completeSetCalcToyoPriceRate`,
          defaultToyoPriceRate,
        );
      }
    }
  }, [cylinderMaking.plateMakingId]);
};
