import React, { useCallback } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useWorkNoticeEditStore } from 'store/topSales/order/workNoticeEdit/hooks';

import { AddCircle } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material';

import {
  FlexoOrderEditForm,
  DraftReturn,
} from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { RowGrid, HeadGrid, BodyGrid as BodyGridOrigin, HeadLabel } from 'components/parts/atoms';

import { useFlexoOrderEditMaster } from '../../hooks/useFlexoOrderEditMaster';
import { useId } from '../../hooks/useId';
import {
  EditCheckbox,
  EditIconButton,
  EditKanaField,
  EditNumericField,
  EditSelect,
} from '../Fields';
import { Heading } from '../Heading';

const BodyGrid = styled(BodyGridOrigin)(() => ({
  justifyContent: 'center',
}));

/** 作業予定 入稿／返却 */
const DraftReturns: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { master } = useFlexoOrderEditMaster();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'schedule.draftReturns',
  });

  // 編集可否
  const {
    state: { data: workData },
  } = useWorkNoticeEditStore();
  const discardImageScheme: boolean = workData?.form.discardImageScheme ?? false;
  const [canEditDraftReturns] = useWatch({
    control,
    name: ['schedule.canEditDraftReturns'],
  });
  const editable = canEditDraftReturns || discardImageScheme;

  // hook
  const { generateId } = useId();

  /** 入稿／返却の行追加 */
  const onAdd = useCallback(() => {
    const addRow: DraftReturn = {
      draftReturnId: generateId(),
      nameCdId: '',
      draftFlg: false,
      directionFlg: false,
      returnTimecdId: '',
      returnCdId: '',
      count: 0,
      returnFlg: false,
      note: '',
    };
    append(addRow);
  }, [append, generateId]);

  /**
   * 入稿／返却の行削除
   * @param index インデックス
   */
  const onDelete = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <>
      <Heading>入稿／返却</Heading>
      <RowGrid>
        <HeadGrid vertical xs={1.5}>
          <HeadLabel>{'名称'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={0.5}>
          <HeadLabel>{'入稿'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={0.5}>
          <HeadLabel>{'指示'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={1.5}>
          <HeadLabel>{'返却時期'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={1.5}>
          <HeadLabel>{'返却先'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={0.75}>
          <HeadLabel>{'数量'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={0.75}>
          <HeadLabel>{'返却済'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={4.5}>
          <HeadLabel>{'備考'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={0.5}>
          <EditIconButton onClick={() => onAdd()} disabled={!editable}>
            <AddCircle />
          </EditIconButton>
        </HeadGrid>
      </RowGrid>
      {fields.map((item, index) => {
        return (
          <RowGrid key={item.id}>
            <BodyGrid xs={1.5}>
              <Controller
                name={`schedule.draftReturns.${index}.nameCdId`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    addEmptyRow
                    options={master.draftReturnList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={0.5}>
              <Controller
                name={`schedule.draftReturns.${index}.draftFlg`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditCheckbox
                    onChange={onChange}
                    checked={value}
                    name={name}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={0.5}>
              <Controller
                name={`schedule.draftReturns.${index}.directionFlg`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditCheckbox
                    onChange={onChange}
                    checked={value}
                    name={name}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={1.5}>
              <Controller
                name={`schedule.draftReturns.${index}.returnTimecdId`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    addEmptyRow
                    options={master.returnTimeList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={1.5}>
              <Controller
                name={`schedule.draftReturns.${index}.returnCdId`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    addEmptyRow
                    options={master.returnList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={0.75}>
              <Controller
                name={`schedule.draftReturns.${index}.count`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditNumericField
                    max={999}
                    min={0}
                    decimalDigits={0}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={0.75}>
              <Controller
                name={`schedule.draftReturns.${index}.returnFlg`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditCheckbox
                    onChange={onChange}
                    checked={value}
                    name={name}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={4.5} sx={{ justifyContent: 'left' }}>
              <Controller
                name={`schedule.draftReturns.${index}.note`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditKanaField
                    maxLength={60}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '100%' }}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={0.5}>
              <EditIconButton onClick={() => onDelete(index)} disabled={!editable}>
                <Delete />
              </EditIconButton>
            </BodyGrid>
          </RowGrid>
        );
      })}
    </>
  );
};

export default React.memo(DraftReturns);
