import useMessage from 'hooks/useMessage';
import React, { useState, useEffect } from 'react';

import { Box, FormControlLabel, RadioGroup, Radio, useTheme } from '@mui/material';
import { GridCellParams, GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';

import { Customer, SearchReq } from 'api/types/common/customerSearch';

import MessageListener from 'components/Message/MessageListener';
import {
  DataGrid,
  AlphaNumericField,
  KanaField,
  ConfirmButton,
  HeadLabel,
  ClearButton,
  RowGrid,
  HeadGrid,
  BodyGrid,
  BodyLabel,
  BodyLink,
  Heading,
} from 'components/parts/atoms';
import CustomerSearchButton from 'components/parts/atoms/Button/CustomerSearchButton';
import { NewWindow } from 'components/parts/atoms/NewWindow';
import {
  Dialog,
  DialogProps,
  FooterButtonsContainer,
  TableCount,
} from 'components/parts/molecules';
import CustomerDetailDialog from 'components/parts/organisms/CustomerDetailDialog';

import { IconExplanation } from '../IconExplanation';
import useCustomers from './hooks/useCustomers';
import { SearchTarget } from './types';

export type CustomerSearchProps = {
  open: boolean;
  onClose: (value?: Customer[]) => void;
  showDeletedCustomer?: boolean;
  showDemandCustomer?: boolean;
  initValues?: {
    searchTarget?: SearchTarget;
    customerSectionCd?: string;
    customerCd?: string;
    customerName?: string;
  };
  searchOnOpen?: boolean;
  searchOnOpenCondition?: {
    searchTarget?: SearchTarget;
    customerSectionCd?: string;
    customerCd?: string;
    customerName?: string;
  };
  multipleSelection?: boolean;
  isWindow?: boolean;
  disablePortal?: DialogProps['disablePortal'];
};

const CustomerSearch: React.VFC<CustomerSearchProps> = ({
  showDeletedCustomer = true,
  showDemandCustomer = true,
  ...props
}) => {
  const theme = useTheme();
  const messageApi = useMessage();
  const { customers, search, clear, loading } = useCustomers(messageApi.pushMessages);

  const openCustomerDialog = (pCustomerId: number) => {
    setOpenDialog(true);
    setCustomerId(pCustomerId);
  };

  const columns: GridColDef[] = [
    {
      field: 'customerSectionCd',
      headerName: '部門コード',
      width: 120,
      sortable: false,
    },
    {
      field: 'customerCd',
      headerName: '得意先コード',
      width: 120,
      sortable: false,
    },
    {
      field: 'customerName',
      headerName: '得意先名',
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <BodyLink onClick={() => openCustomerDialog(Number(params.id))}>{params.value}</BodyLink>
      ),
    },
  ];

  const [searchTarget, setSearchTarget] = useState<SearchTarget>(SearchTarget.ALL);
  const [customerSectionCd, setCustomerSectionCd] = useState('');
  const [customerCd, setCustomerCd] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(-1);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // isOpenWindowがtrueの場合は別Windowで表示する(左記以外はDialog)
  const [isOpenWindow, setOpenWindow] = useState(false);

  const handleSearch = () => {
    messageApi.clear();
    const condition: SearchReq = {
      showDeletedCustomer: showDeletedCustomer,
      showDemandCustomer: showDemandCustomer,
      searchTarget: searchTarget,
      customerSectionCd: customerSectionCd,
      customerCd: customerCd,
      customerName: customerName,
    };
    search(condition);
  };

  const handleClear = () => {
    messageApi.clear();
    setSearchTarget(SearchTarget.ALL);
    setCustomerSectionCd('');
    setCustomerCd('');
    setCustomerName('');
  };

  const reset = () => {
    messageApi.clear();
    setSearchTarget(SearchTarget.ALL);
    setCustomerSectionCd('');
    setCustomerCd('');
    setCustomerName('');
    setSelectionModel([]);
    clear();
  };

  const handleClose = () => {
    props.onClose(undefined);
    reset();
  };

  const handleConfirm = () => {
    messageApi.clear();
    if (selectionModel.length === 0) {
      messageApi.pushError('結果一覧を選択してください');
    } else {
      const result = customers.filter((e) => selectionModel.includes(e.customerId));
      props.onClose(result);
      reset();
    }
  };

  const searchBody = (): JSX.Element => {
    return (
      <>
        <RowGrid columns={12}>
          <HeadGrid xs={9} sx={{ p: 0 }}>
            <RowGrid columns={9}>
              <HeadGrid xs={3}>
                <HeadLabel>検索対象</HeadLabel>
              </HeadGrid>
              <BodyGrid xs={6}>
                <RadioGroup
                  row
                  value={searchTarget}
                  onChange={(e) => {
                    setSearchTarget(Number(e.target.value) as SearchTarget);
                  }}
                >
                  <FormControlLabel value={SearchTarget.OWN} control={<Radio />} label='自分担当' />
                  <FormControlLabel value={SearchTarget.ALL} control={<Radio />} label='全件' />
                </RadioGroup>
              </BodyGrid>

              <HeadGrid xs={3}>
                <HeadLabel>部門／得意先コード</HeadLabel>
              </HeadGrid>
              <BodyGrid xs={6}>
                <AlphaNumericField
                  value={customerSectionCd}
                  onChange={(e) => setCustomerSectionCd(e.target.value)}
                  maxLength={4}
                />
                <BodyLabel sx={{ mx: 2 }}> − </BodyLabel>
                <AlphaNumericField
                  value={customerCd}
                  onChange={(e) => setCustomerCd(e.target.value)}
                  maxLength={6}
                />
              </BodyGrid>

              <HeadGrid xs={3}>
                <HeadLabel>得意先名</HeadLabel>
              </HeadGrid>
              <BodyGrid xs={6}>
                <KanaField
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  maxLength={20}
                  sx={{ minWidth: '25rem' }}
                />
              </BodyGrid>
            </RowGrid>
          </HeadGrid>
          <BodyGrid xs={3} sx={{ justifyContent: 'center', p: 0 }}>
            <ClearButton onClick={handleClear}></ClearButton>
            <Box m={1} />
            <CustomerSearchButton onClick={handleSearch}></CustomerSearchButton>
          </BodyGrid>
        </RowGrid>

        <Box m={2} />

        {/* 一覧件数表示*/}
        <TableCount count={customers.length ?? 0}></TableCount>
        <Box m={2} />
        <DataGrid
          columns={columns}
          rows={customers}
          getRowId={(row) => row.customerId}
          loading={loading}
          pagination={true}
          autoHeight
          pageSize={20}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          checkboxSelection={props.multipleSelection}
          disableMultipleSelection={!props.multipleSelection}
          getRowClassName={(params) => (params.row.deleteFlg ? 'deleted-customer' : '')}
          sx={{
            '& .deleted-customer': {
              color: theme.palette.makingSpecificationGray.main,
            },
            '& .deleted-customer .MuiTypography-root': {
              color: theme.palette.makingSpecificationGray.main,
            },
          }}
        />
        {showDeletedCustomer && (
          <>
            <Box m={2} />
            <IconExplanation
              textColors={[
                {
                  color: theme.palette.makingSpecificationGray.main,
                  colorName: 'グレー',
                  description: '削除フラグが立っている得意先',
                },
              ]}
            />
          </>
        )}
        <Box m={2} />

        {customers.length > 0 && (
          <FooterButtonsContainer>
            <ConfirmButton onClick={handleConfirm} />
          </FooterButtonsContainer>
        )}
        <Box m={2} />
        <CustomerDetailDialog
          open={openDialog}
          customerId={customerId}
          onClose={() => setOpenDialog(false)}
          isWindow={isOpenWindow}
        />
      </>
    );
  };

  useEffect(() => {
    if (props.open) {
      if (props.initValues) {
        setSearchTarget(props.initValues.searchTarget ?? SearchTarget.ALL);
        setCustomerSectionCd(props.initValues.customerSectionCd ?? '');
        setCustomerCd(props.initValues.customerCd ?? '');
        setCustomerName(props.initValues.customerName ?? '');
      }
      if (props.searchOnOpen) {
        const condition: SearchReq = {
          showDeletedCustomer: showDeletedCustomer,
          showDemandCustomer: showDemandCustomer,
          searchTarget:
            props.searchOnOpenCondition?.searchTarget ||
            props.initValues?.searchTarget ||
            SearchTarget.ALL,
          customerSectionCd:
            props.searchOnOpenCondition?.customerSectionCd ||
            props.initValues?.customerSectionCd ||
            '',
          customerCd: props.searchOnOpenCondition?.customerCd || props.initValues?.customerCd || '',
          customerName:
            props.searchOnOpenCondition?.customerName || props.initValues?.customerName || '',
        };
        search(condition);
      }
    }
  }, [props.open]);

  useEffect(() => {
    setOpenWindow(props.isWindow ?? false);
  }, [props.isWindow]);

  return (
    <>
      {props.open && isOpenWindow && (
        <>
          <NewWindow features={{ height: 720, width: 1280 }} onUnload={props.onClose}>
            <Heading>得意先検索</Heading>
            <Box sx={{ m: 1 }}>
              <MessageListener messageApi={messageApi} />
            </Box>
            <Box m={4} />
            {searchBody()}
          </NewWindow>
        </>
      )}
      {!isOpenWindow && (
        <>
          <Dialog
            open={props.open}
            title={'得意先検索'}
            onClose={handleClose}
            messageApi={messageApi}
          >
            {searchBody()}
          </Dialog>
        </>
      )}
    </>
  );
};

export default CustomerSearch;
