import { printColorMap } from 'constants/define';
import * as React from 'react';

import { Box, Grid, useTheme } from '@mui/material';

import { BodyGrid, BodyLabel, HeadGrid, HeadLabel } from 'components/parts/atoms';
import { NewWindow } from 'components/parts/atoms/NewWindow';

import { Heading } from '../Heading';

const PrintColorBox: React.VFC<{ printColorNo: number; printColor: string }> = ({
  printColorNo,
  printColor,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <HeadGrid
        sx={{
          justifyContent: 'center',
          backgroundColor: theme.palette.cyclonistLightOrange.main,
          minHeight: '30px',
        }}
      >
        <HeadLabel>{String(printColorNo).padStart(2, '0')}</HeadLabel>
      </HeadGrid>
      <BodyGrid
        sx={{
          justifyContent: 'center',
          minHeight: '30px',
        }}
      >
        <BodyLabel>{printColor}</BodyLabel>
      </BodyGrid>
    </Box>
  );
};

const PrintColorMap: React.VFC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <NewWindow features={{ height: 600, width: 600 }} onUnload={onClose}>
      <Box m={2}>
        <Heading>刷色コード表</Heading>
        <Box my={4} />
        <Grid container columns={10}>
          {Object.keys(printColorMap)
            .map(Number)
            .map((key) => {
              const printColor = printColorMap[key];
              return (
                <Grid key={key} item xs={1}>
                  <PrintColorBox printColorNo={key} printColor={printColor} />
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </NewWindow>
  );
};

export default PrintColorMap;
