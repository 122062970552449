import { useFormContext, useWatch } from 'react-hook-form';

import { FlexoOrderEditForm, OtherImagePriceRowValues } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** その他（画像）行情報 Hooks */
export const useOtherImagePriceRow = (index: number): OtherImagePriceRowValues => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const row = useWatch({ control, name: `price.otherImagePrice.kakakuJouhouSonotas.${index}` });
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcGrossInvoice } = usePriceCalculater();

  /** 売価 */
  const price = row.price ?? 0;
  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate, row.toyoPriceRateFlg);

  return {
    name: row.name,
    price,
    toyoPriceRate,
    toyoPriceRateFlg: row.toyoPriceRateFlg,
    grossInvoice,
  };
};
