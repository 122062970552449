import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultProductionCustomerSearchStore } from 'store/productionManage/productionCustomerSearch/defaults';
import { productionCustomerSearchStore } from 'store/productionManage/productionCustomerSearch/types';

const slice = createSlice({
  name: 'productionCustomerSearch',
  initialState: defaultProductionCustomerSearchStore,
  reducers: {
    save: (state, action: PayloadAction<productionCustomerSearchStore>) => action.payload,
    clear: () => ({ ...defaultProductionCustomerSearchStore }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
