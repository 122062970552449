import { ja } from 'date-fns/locale';
import * as React from 'react';

import {
  DesktopDatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { TextField } from '..';

export type DatePickerProps<TDate> = Pick<
  MuiDatePickerProps<TDate>,
  'value' | 'onChange' | 'disabled' | 'minDate'
> & { truncateTime?: boolean };

const DatePicker: React.VFC<DatePickerProps<Date>> = ({ truncateTime = true, minDate, ...props }) => {
  const defaultProps: Pick<
    MuiDatePickerProps<Date>,
    'renderInput' | 'inputFormat' | 'mask' | 'views'
  > = {
    renderInput: (params) => <TextField {...params} sx={{ width: '14em' }} />,
    inputFormat: 'yyyy年MM月dd日',
    mask: '____年__月__日',
    views: ['year', 'month', 'day'],
  };
  const mergedProps = { ...defaultProps, ...props, minDate};

  const onChange: DatePickerProps<Date>['onChange'] = (date: Date | null) => {

    if (truncateTime && date) {
      // 時間を切り捨て
      const truncatedDate: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      props.onChange(truncatedDate);
    } else {
      props.onChange(date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
      <MuiDatePicker {...mergedProps} onChange={onChange} />
    </LocalizationProvider>
  );
};

export default DatePicker;
