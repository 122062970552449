import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  NumericField,
} from 'components/parts/atoms';

import { CylinderForm } from '../../types';

const CylinderProcessPrice: React.VFC = () => {
  // フォームデータ
  const unitPriceCylinderFormContext = useFormContext<CylinderForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            加算倍率情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>カラー製版</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='colorCylMaking'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={999.9}
                decimalDigits={1}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>半調子</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='middleTone'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={999.9}
                decimalDigits={1}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>調子</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='gradetion'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={999.9}
                decimalDigits={1}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>連続</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='endless'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={999.9}
                decimalDigits={1}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>追加腐食</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='additionalCorrosion'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={999.9}
                decimalDigits={1}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <BodyLabel> </BodyLabel>
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default CylinderProcessPrice;
