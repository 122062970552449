import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { OnlyTypeKey } from 'utils/typeUtils';

import { useUnitPriceWithRateFlgRow } from '../../../hooks/price/priceRow/useUnitPriceWithRateFlgRow';
import {
  FlexoOrderEditForm,
  UnitPrice,
  UnitPriceWithRateFlgRow as UnitPriceWithRateFlgRowType,
} from '../../../types';
import { UnitCheckBox, UnitPriceField, UnitPriceFixedField } from '../Fields';
import { Row } from './Row';

type Props = {
  label: string;
  propertyName: OnlyTypeKey<UnitPrice, UnitPriceWithRateFlgRowType>;
};

/** 単価計算 行コンポーネント */
const UnitPriceWithRateFlgRow: React.VFC<Props> = ({ label, propertyName }) => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();

  const { price, toyoPriceRate, grossInvoice } = useUnitPriceWithRateFlgRow(propertyName);

  return (
    <>
      <Row>
        <HeadLabel>{label}</HeadLabel>
        <Controller
          name={`price.unitPrice.${propertyName}.unitPrice`}
          control={control}
          render={({ field }) => (
            <UnitPriceFixedField
              min={0}
              max={9999999}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '6em' }}
              labelWidth={'grow'}
            />
          )}
        />
        <Controller
          name={`price.unitPrice.${propertyName}.count`}
          control={control}
          render={({ field }) => (
            <UnitPriceField
              min={0}
              max={99}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '100%' }}
              labelWidth={'grow'}
            />
          )}
        />
        <BodyLabel>{price.toFixed()}</BodyLabel>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Controller
            name={`price.unitPrice.${propertyName}.toyoPriceRateFlg`}
            control={control}
            render={({ field }) => (
              <UnitCheckBox onChange={field.onChange} checked={field.value} name={field.name} />
            )}
          />
          <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
        </Box>
        <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
      </Row>
    </>
  );
};

export default React.memo(UnitPriceWithRateFlgRow);
