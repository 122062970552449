import React, { useState } from 'react';
import theme from 'themes/theme';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';

import { HeadLabel, BodyLabel, Button, ColorLabel } from 'components/parts/atoms';
import { CloseHeading } from 'components/parts/molecules/CloseHeading';

import { useTodayPlan } from './hooks';

const contentsCouPercentntText = (text: string, count: number | null, unit: string) => {
  return (
    <>
      <HeadLabel>{`${text}：`}</HeadLabel>
      <BodyLabel>{`${count ?? '0'}${unit}`}</BodyLabel>
      <Box m={2} />
    </>
  );
};

const contentsPercentText = (text: string, count: number | null, unit: string) => {
  return (
    <>
      <ColorLabel color={theme.palette.cyclonistDarkGrey.main}>{`${text}:`}</ColorLabel>
      <ColorLabel color={theme.palette.cyclonistDarkGrey.main}>{`${
        count ?? '0'
      }${unit}`}</ColorLabel>
    </>
  );
};

const TodayPlan: React.VFC = () => {
  const todayPlan = useTodayPlan();
  const [displayFlg, setDisplayFlg] = useState<boolean>(false);

  const onDisplayChange = () => {
    if (displayFlg) {
      setDisplayFlg(false);
    } else {
      setDisplayFlg(true);
    }
  };

  return (
    <>
      <Box m={2}>
        {displayFlg && (
          <>
            <CloseHeading onClose={onDisplayChange}>本日の製版予定</CloseHeading>

            <Box m={2} />
            <Box
              sx={{
                display: 'frex',
                justifyContent: 'flex-start',
                borderBottom: '1px dotted',
                borderColor: `${theme.palette.cyclonistDarkGrey.main}`,
              }}
            >
              {contentsCouPercentntText(
                '入荷予定本数',
                todayPlan.todayPlanData.arrivalPlanCount,
                '本',
              )}
              {contentsCouPercentntText(
                '製版予定本数',
                todayPlan.todayPlanData.cylMakingPlanCount,
                '本',
              )}
              {contentsCouPercentntText(
                '出荷予定本数',
                todayPlan.todayPlanData.shipmentPlanCount,
                '本',
              )}
              {contentsCouPercentntText(
                '画像出稿予定本数',
                todayPlan.todayPlanData.imageSyukkouPlanCount,
                '本',
              )}
            </Box>
            <Box m={2} />
            <Box
              borderBottom={1}
              sx={{
                display: 'frex',
                justifyContent: 'flex-end',
                borderBottom: '1px dotted',
                borderColor: `${theme.palette.cyclonistDarkGrey.main}`,
              }}
            >
              {contentsPercentText('再損版率', todayPlan.todayPlanData.monthlyLossCylTotal, '%')}
            </Box>
          </>
        )}
        {!displayFlg && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button startIcon={<OpenInNewIcon />} onClick={() => onDisplayChange()}>
                Open
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default TodayPlan;
