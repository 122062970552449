import React, { ReactNodeArray } from 'react';

import { GridProps } from '@mui/material';

import { HeadGrid, BodyGrid } from 'components/parts/atoms/Grid';

export type ContentsGridProps = {
  children: ReactNodeArray;
  hxs: GridProps['xs'];
  bxs: GridProps['xs'];
  hsx?: GridProps['sx'];
  bsx?: GridProps['sx'];
};

const ContentsGrid: React.VFC<ContentsGridProps> = ({
  children: [hContents, bContents],
  ...props
}) => {
  return (
    <>
      <HeadGrid xs={props.hxs} sx={props.hsx}>
        {hContents}
      </HeadGrid>
      <BodyGrid xs={props.bxs} sx={props.bsx}>
        {bContents}
      </BodyGrid>
    </>
  );
};

export default ContentsGrid;
