import { useFormContext, useWatch } from 'react-hook-form';

import { OnlyTypeKey } from 'utils/typeUtils';

import {
  CylinderOrderEditForm,
  OtherCylinderPrice,
  OtherCylinderPricePerAreaRow,
  OtherCylinderPriceRowValues,
} from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** その他（シリンダ）行情報（面積当たりの単価が入力される） Hooks */
export const useOtherCylinderPricePerAreaRow = (
  key: OnlyTypeKey<OtherCylinderPrice, OtherCylinderPricePerAreaRow>,
): OtherCylinderPriceRowValues => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const row = useWatch({ control, name: `price.otherCylinderPrice.${key}` });
  const { surfaceArea, toyoPriceRate } = usePriceCalculateMatter();
  const { calcOtherCylinderPricePerArea, calcGrossInvoice } = usePriceCalculater();

  /** 売価 */
  const price = calcOtherCylinderPricePerArea(row.unitPrice, row.amount, surfaceArea);
  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate, row.toyoPriceRateFlg);

  return {
    unitPrice: row.unitPrice ?? 0,
    surfaceArea,
    amount: row.amount ?? 0,
    price,
    toyoPriceRate,
    grossInvoice,
  };
};
