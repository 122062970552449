import { CalculateMethodCd, OrderPriceUpdateMessage, ShaftCode } from 'constants/define';
import useSharedMessage from 'hooks/useSharedMessage';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Cached } from '@mui/icons-material';
import { Box, ToggleButtonGroup, Tooltip } from '@mui/material';

import { messageStatus, ResponseMessage } from 'api/types/common/apiResponse';

import { useCylinderOrderEditMaster } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/hooks/useCylinderOrderEditMaster';
import {
  CylinderOrderEditForm,
  CylUnitPrice,
} from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { BodyLabel, HeadLabel } from 'components/parts/atoms';

import { usePriceCalculateMatter } from '../../../hooks/price/usePriceCalculateMatter';
import { useResetPrice } from '../../../hooks/price/useResetPrice';
import { usePageContext } from '../../../hooks/usePageContext';
import { PageState } from '../../../types';
import { PriceIconButton, PriceSelect, PriceToggleButton } from '../Fields';
import { Row } from './Row';

/** 価格情報 計算方法 ～ 個別単価 */
const PriceHeader: React.VFC = () => {
  const { control } = useFormContext<CylinderOrderEditForm>();
  const { master } = useCylinderOrderEditMaster();

  const { pushError, remove } = useSharedMessage();

  // 計算方法
  const [calculateMethodCd, shaftCdId] = useWatch({
    control,
    name: ['price.calculateMethodCd', 'making.makingBasis.shaftCdId'],
  });
  const shaft = master.shaftList.find((e) => e.value === shaftCdId) ?? null;

  const { cylUnitPrice, rawCylSizeEnsyuu } = usePriceCalculateMatter();
  const { resetPrices } = useResetPrice();

  const { pageState } = usePageContext();
  const edit = pageState === PageState.Edit;

  // 単価更新押下時に単価を再設定、メッセージを表示
  const messageApi = useSharedMessage();
  const pushResetPrices = (cylUnitPrice: CylUnitPrice | null, rawCylSizeEnsyuu: number | null) => {
    resetPrices(cylUnitPrice, rawCylSizeEnsyuu);
    messageApi.clear();
    messageApi.pushInfo(OrderPriceUpdateMessage);
  };

  const validateShaft = (cylUnitPrice: CylUnitPrice | null): boolean => {
    const mustWithmessage = 'シャフトありの計算方法を選択してください';
    const mustWithoutmessage = 'シャフト無しの計算方法を選択してください';

    remove(mustWithmessage);
    remove(mustWithoutmessage);

    // 製版情報タブで選択されたシャフト有無と一致していなければエラーを表示
    if (shaft && cylUnitPrice?.shaftCdId && cylUnitPrice.shaftCdId !== Number(shaftCdId)) {
      switch (shaft.decisionCode) {
        case ShaftCode.With:
          pushError(mustWithmessage);
          break;
        case ShaftCode.Without:
          pushError(mustWithoutmessage);
          break;
      }
      return false;
    }
    return true;
  };

  return (
    <>
      <Row>
        <HeadLabel>計算方法</HeadLabel>
        <Controller
          name='price.calculateMethodCd'
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              value={field.value}
              onChange={field.onChange}
              exclusive
              color='primary'
            >
              <PriceToggleButton
                style={{
                  whiteSpace: 'normal',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                value={CalculateMethodCd.Unit}
              >
                単価計算
                <br /> (手入力)
              </PriceToggleButton>
              <PriceToggleButton value={CalculateMethodCd.FixedUnit}>単価計算</PriceToggleButton>
              <PriceToggleButton value={CalculateMethodCd.Set}>一式計算</PriceToggleButton>
            </ToggleButtonGroup>
          )}
        />
        <HeadLabel>{'シリンダー単価'}</HeadLabel>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            name='price.cylUnitPriceId'
            control={control}
            render={({ field }) => (
              <PriceSelect
                addEmptyRow={true}
                options={master.cylUnitPriceSelectList}
                onChange={(e) => {
                  const selectedCylUnitPrice =
                    master.cylUnitPriceList.find(
                      (price) => price.cylUnitPriceId === (e.target.value as string | null),
                    ) ?? null;
                  if (validateShaft(selectedCylUnitPrice)) {
                    field.onChange(e);
                  }
                }}
                value={field.value}
                name={field.name}
                disabled={calculateMethodCd === CalculateMethodCd.Set}
                sx={{ minWidth: '12em' }}
              />
            )}
          />
          <Box mx={1} />
          <Box
            mx={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <PriceIconButton
              onClick={() => pushResetPrices(cylUnitPrice, rawCylSizeEnsyuu)}
              disabled={calculateMethodCd === CalculateMethodCd.Set}
              sx={{ padding: '0px' }}
            >
              <Tooltip title='単価を再設定する'>
                <Cached sx={{ fontSize: '2.5rem' }} />
              </Tooltip>
            </PriceIconButton>
            {edit ? <BodyLabel>更新</BodyLabel> : null}
          </Box>

          {cylUnitPrice && cylUnitPrice.cylMakingCalcType !== null && (
            <>
              <Box ml={2} />
              <BodyLabel>{`製版代計算区分[ ${cylUnitPrice.cylMakingCalcType} ]`}</BodyLabel>
            </>
          )}
        </Box>
      </Row>
    </>
  );
};

export default React.memo(PriceHeader);
