import React from 'react';
import { TransitionGroup } from 'react-transition-group';

import { Box, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';

import Message from './Message';
import { MessageContainerProps } from './types';

const ZINDEX = 1000;

const Container = styled(Box)(() => ({ width: '100%' }));

const MessageContainer: React.FC<MessageContainerProps> = ({ messages, onRemove }) => {
  return (
    <Container sx={{ zIndex: 10000 }}>
      <TransitionGroup>
        {messages.map((message, index) => {
          const zIndex = ZINDEX - index;

          return (
            <Collapse key={message.id}>
              <Message
                message={message}
                onRemove={onRemove}
                sx={{ marginBottom: 1, zIndex: zIndex }}
              />
            </Collapse>
          );
        })}
      </TransitionGroup>
    </Container>
  );
};

export default MessageContainer;
