import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

import { save, clear } from '.';
import { PriceApprovalListSearchConditionState } from './types';

/** 承認画面一覧StoreのHooks */
export const usePriceApprovalListSearchCondition = (): {
    state: PriceApprovalListSearchConditionState;
    save: (state: PriceApprovalListSearchConditionState) => void;
    clear: () => void;
} => {
    /** 価格承認一覧のState */
    const state = useSelector((state: RootState) => state.priceApprovalListSearchConditionState);

    const dispatch = useDispatch();

    /** 価格承認一覧Stateに登録 */
    const dispatchSave = useCallback(
        (state: PriceApprovalListSearchConditionState) => {
            dispatch(save(state));
        },
        [dispatch],
    );

    /** 価格承認一覧Stateを初期化 */
    const dispatchClear = useCallback(() => {
        dispatch(clear());
    }, []);

    return { state, save: dispatchSave, clear: dispatchClear };
};
