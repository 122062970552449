import * as React from 'react';

import { Paper } from '@mui/material';
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles';

import { Typography, TypographyProps } from 'components/parts/atoms';

export type HeadingProps = {
  children: string;
  variant?: TypographyProps['variant'];
  backgroundColor?: string;
  textColor?: string;
  sx?: SxProps<Theme>;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const Heading: React.FC<HeadingProps> = ({ variant: typoVariant = 'h3', ...props }) => {
  const theme = useTheme();
  const backgroundColor = props.backgroundColor ?? theme.palette.primary.main;
  const textColor = props.textColor ?? theme.palette.primary.contrastText;
  return (
    <StyledPaper
      color={'primary'}
      sx={{ backgroundColor: backgroundColor, flexGrow: 1, ...props.sx }}
    >
      <Typography variant={typoVariant} sx={{ color: textColor }}>
        {props.children}
      </Typography>
    </StyledPaper>
  );
};

export default Heading;
