import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { OnlyTypeKey } from 'utils/typeUtils';

import { useMakingPriceRow } from '../../../hooks/price/priceRow/useMakingPriceRow';
import {
  FlexoOrderEditForm,
  Making,
  FlexoMaking,
  Price,
  MakingPrice,
  MakingPriceRow as MakingPriceRowType,
} from '../../../types';
import { UnitPriceField, UnitPriceFixedField } from '../Fields';
import { Row } from './Row';

export type MakingPriceRowProps = {
  label: string;
  pricePropertyName: OnlyTypeKey<Price, MakingPrice>;
  makingsPropertyName: OnlyTypeKey<Making, FlexoMaking[]>;
  rowPropertyName: OnlyTypeKey<MakingPrice, MakingPriceRowType>;
  surfaceArea: number;
};

/** 製版価格 行コンポーネント */
const MakingPriceRow: React.VFC<MakingPriceRowProps> = ({
  label,
  pricePropertyName,
  makingsPropertyName,
  rowPropertyName,
}) => {
  const { control } = useFormContext<FlexoOrderEditForm>();

  const [makings] = useWatch({
    control,
    name: [`making.${makingsPropertyName}`],
  });

  const { surfaceArea, price, toyoPriceRate, grossInvoice } = useMakingPriceRow(
    pricePropertyName,
    rowPropertyName,
  );

  return (
    <Row>
      <HeadLabel>{label}</HeadLabel>
      <Controller
        name={`price.${pricePropertyName}.${rowPropertyName}.unitPrice`}
        control={control}
        render={({ field }) => (
          <UnitPriceFixedField
            min={0}
            max={99999999.9999}
            decimalDigits={4}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
          />
        )}
      />
      <BodyLabel>{surfaceArea.toFixed()}</BodyLabel>
      <Controller
        name={`price.${pricePropertyName}.${rowPropertyName}.count`}
        control={control}
        render={({ field }) => (
          <UnitPriceField
            min={0}
            max={99}
            decimalDigits={0}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
            disabled={makings.length === 0}
          />
        )}
      />
      <BodyLabel>{price.toFixed()}</BodyLabel>
      <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
      <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
    </Row>
  );
};

export default React.memo(MakingPriceRow);
