import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { OtherOrderEditForm } from '../../../types';
import { useTotalPrice } from '../priceTotal/useTotalPrice';

/** 合計価格変更時にRHFに値をセットするHooks */
export const useTotalPriceWatcher = (): void => {
  // フォーム
  const { setValue } = useFormContext<OtherOrderEditForm>();

  // ウォッチ
  const prices = useTotalPrice();

  // 合計金額が再計算されたらフォームにセットする。
  useEffect(() => {
    setValue('price.totalPrice', {
      price: prices.total.price,
      grossInvoice: prices.total.grossInvoice,
    });
  }, [prices.total.price, prices.total.grossInvoice]);
};
