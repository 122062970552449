import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { AppBar, Box, Toolbar } from '@mui/material';

import apiClient from 'api/apiClient';
import { ApiResponse } from 'api/types/common/apiResponse';
import { MenusRes, menuDiv } from 'api/types/portalTop/portal';

import { IconButton, Typography } from 'components/parts/atoms';

import AppsButton from './AppsButton';
import Logo from './Logo';
import MenuButton from './MenuButton';
import SearchBox from './SearchBox';

type Props = {
  height: number;
  showSideBar: boolean;
  toggleSideBar: () => void;
};

const NavBar: React.VFC<Props> = (props) => {
  const [menusResponse, setMenusResponse] = useState<ApiResponse<MenusRes>>();

  const [worksMenu, scheduleMenu, etcMenu] = useMemo(() => {
    const categories = menusResponse?.result?.menuCategories;
    return [
      categories?.find((e) => e.menuDiv === menuDiv.WORKS),
      categories?.find((e) => e.menuDiv === menuDiv.SCHEDULE),
      categories?.find((e) => e.menuDiv === menuDiv.ETC),
    ];
  }, [menusResponse]);

  useEffect(() => {
    (async () => {
      try {
        const [menusRes] = await Promise.all([apiClient.get<MenusRes>(`/api/v1/Portal/Menus`)]);
        setMenusResponse(menusRes.data);
      } catch (e) {
        console.log(e);
        return;
      }
    })();
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          color='white'
          position='fixed'
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: props.height }}
        >
          <Toolbar variant='dense' sx={{ minHeight: 0 }}>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={props.toggleSideBar}
              sx={{ width: '70px' }}
            >
              {(() => {
                if (props.showSideBar) {
                  return (
                    <>
                      <ArrowBackIos />
                      <Typography>CLOSE</Typography>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Typography>OPEN</Typography>
                      <ArrowForwardIos />
                    </>
                  );
                }
              })()}
            </IconButton>
            <Box mx={2}></Box>
            <Logo />

            <Box mx={2}></Box>
            <MenuButton title={'WORKS'} menuCategory={worksMenu} />
            <Box mx={0.5}></Box>
            <MenuButton title={'予定／進捗'} menuCategory={scheduleMenu} />
            <Box mx={0.5}></Box>
            <MenuButton title={'etc'} menuCategory={etcMenu} />
            <Box mx={0.5}></Box>
            <Box mx={4}></Box>

            <SearchBox />

            <Box sx={{ flexGrow: 1 }} />
            <Box mx={2}></Box>
            <AppsButton />
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default NavBar;
