import { useFormContext, useWatch } from 'react-hook-form';

import { OnlyTypeKey } from 'utils/typeUtils';

import {
  FlexoOrderEditForm,
  MakingPrice,
  MakingPriceRow,
  MakingPriceRowValues,
} from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** 印刷面計算 行情報 Hooks */
export const useMakingPriceRow = (
  key: 'makingPriceMain' | 'makingPriceReverse',
  rowKey: OnlyTypeKey<MakingPrice, MakingPriceRow>,
): MakingPriceRowValues => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const row = useWatch({ control, name: `price.${key}.${rowKey}` });
  const { toyoPriceRate, mainSurfaceArea, reverseSurfaceArea } = usePriceCalculateMatter();
  const { calcMakingPrice, calcGrossInvoice } = usePriceCalculater();

  let surfaceArea = 0;
  switch (key) {
    case 'makingPriceMain':
      surfaceArea = mainSurfaceArea;
      break;
    case 'makingPriceReverse':
      surfaceArea = reverseSurfaceArea;
      break;
  }

  /** 売価 */
  const price = calcMakingPrice(row.unitPrice, surfaceArea, row.count);
  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate);

  return {
    unitPrice: row.unitPrice ?? 0,
    surfaceArea,
    count: row.count ?? 0,
    price,
    toyoPriceRate,
    grossInvoice,
  };
};

/** 印刷面(メイン)計算 行情報 Hooks */
export const useMainMakingPriceRow = (
  rowKey: OnlyTypeKey<MakingPrice, MakingPriceRow>,
): MakingPriceRowValues => {
  const values = useMakingPriceRow('makingPriceMain', rowKey);
  return values;
};

/** 印刷面(リバース)計算 行情報 Hooks */
export const useReverseMakingPriceRow = (
  rowKey: OnlyTypeKey<MakingPrice, MakingPriceRow>,
): MakingPriceRowValues => {
  const values = useMakingPriceRow('makingPriceReverse', rowKey);
  return values;
};
