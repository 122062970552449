import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialStoreData } from 'store/common/progressList/defaults';
import { SearchConditionState } from 'store/common/progressList/types';

const slice = createSlice({
  name: 'imageWorkAssignList',
  initialState: InitialStoreData,
  reducers: {
    save: (state, action: PayloadAction<SearchConditionState>) => action.payload,
    clear: () => ({ ...InitialStoreData }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
