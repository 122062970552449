import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { styled } from '@mui/material';

import { FlexoOrderEditForm } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import {
  RowGrid,
  HeadGrid,
  BodyGrid as BodyGridOrigin,
  HeadLabel,
  BodyLabel,
  BodyLink,
} from 'components/parts/atoms';

import { usePageContext } from '../../hooks/usePageContext';
import { Heading } from '../Heading';

const BodyGrid = styled(BodyGridOrigin)(() => ({
  justifyContent: 'center',
}));

/** 画像 画像参照JOBコンポーネント */
const GaImageReferenceJobs: React.VFC = () => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { leavePage } = usePageContext();

  const { fields } = useFieldArray({
    control: control,
    name: 'making.gaImageReferenceJobs',
  });

  /**
   * JobNoリンククリック時
   */
  const onClickJobNoLink = useCallback((referenceJobId: number) => {
    leavePage('/ImageDataDetail/' + referenceJobId);
  }, []);

  return (
    <>
      <Heading>画像 参照／テンプレート</Heading>
      <RowGrid columns={12}>
        <HeadGrid vertical xs={4}>
          <HeadLabel>画像参照JOB No.</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={2}>
          <HeadLabel>工程</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={6}>
          <HeadLabel>コメント</HeadLabel>
        </HeadGrid>
      </RowGrid>
      {fields.map((item) => {
        return (
          <RowGrid columns={12} key={item.id}>
            <BodyGrid xs={4}>
              {item.linkFlg ? (
                <BodyLink onClick={() => onClickJobNoLink(item.referenceJobId)}>
                  {item.referenceJobNo}
                </BodyLink>
              ) : (
                <BodyLabel>{item.referenceJobNo}</BodyLabel>
              )}
            </BodyGrid>
            <BodyGrid xs={2}>
              <BodyLabel>{item.workProcesscdName}</BodyLabel>
            </BodyGrid>
            <BodyGrid xs={6}>
              <BodyLabel sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {item.note}
              </BodyLabel>
            </BodyGrid>
          </RowGrid>
        );
      })}
    </>
  );
};

export default React.memo(GaImageReferenceJobs);
