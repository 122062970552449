import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CylinderOrderMetaData } from 'api/types/topSales/order/cylinderOrderEdit';

import { InitialCylinderOrderManagerState } from './defaults';
import { CylinderOrderManagerState } from './types';

const slice = createSlice({
  name: 'cylinderOrderManager',
  initialState: InitialCylinderOrderManagerState,
  reducers: {
    save: (state, action: PayloadAction<CylinderOrderManagerState>) => action.payload,
    saveMetaData: (state, action: PayloadAction<CylinderOrderMetaData>) => {
      if (state.data) {
        state.data.orderMetaData = action.payload;
      }
    },
    clear: () => ({ ...InitialCylinderOrderManagerState }),
  },
});

export const { save, saveMetaData, clear } = slice.actions;

export default slice.reducer;
