import * as React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/parts/atoms';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(1),
}));

const ColorBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ theme, color }) => ({
  backgroundColor: color ?? color,
  padding: theme.spacing(1),
  height: '20px',
  width: '20px',
}));

const TextColorWrapper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ theme, color }) => ({
  color: color ?? color,
  padding: theme.spacing(1),
}));

export const IconExplanation: React.VFC = () => {
  return (
    <>
      <Box>アイコン説明</Box>
      <Wrapper>
        <ItemWrapper>
          <ColorBox color='#000000' />
          <Typography>作業なし</Typography>
        </ItemWrapper>
        <ItemWrapper>
          <ColorBox color='#D3D3D3' />
          <Typography>未着手</Typography>
        </ItemWrapper>
        <ItemWrapper>
          <ColorBox color='#4069E1' />
          <Typography>見なし終了</Typography>
        </ItemWrapper>
        <ItemWrapper>
          <ColorBox color='#FFFF00' />
          <Typography>着手（一部着手、一部完了）</Typography>
        </ItemWrapper>
        <ItemWrapper>
          <ColorBox color='#FF3300' />
          <Typography>完了</Typography>
        </ItemWrapper>
        <ItemWrapper>
          <ColorBox color='#FF8C00' />
          <Typography>外注</Typography>
        </ItemWrapper>
      </Wrapper>
      <Typography>
        文字色
        <TextColorWrapper color='#ff0000'>赤</TextColorWrapper>: トビ入り
      </Typography>
      <Typography>
        背景色
        <TextColorWrapper color='#FF8C00'>オレンジ</TextColorWrapper>: 外注
      </Typography>
    </>
  );
};

export default IconExplanation;
