import * as React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import { RowGrid, BodyGrid, HeadGrid } from 'components/parts/atoms/Grid';

// types
import { PrintContentsProps } from './types';

/** RowGridのマージンとパディングを0にする */
const CustomRowGrid = styled(RowGrid)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}));

/** BodyGridのマージンとパディングを0にする */
const CustomBodyGrid = styled(BodyGrid)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}));

/** HeadGridのマージンとパディングを0にする */
const CustomHeadGrid = styled(HeadGrid)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}));

/** 汎用ボックススタイル */
const NormalBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(2),
  flexWrap: 'wrap',
  width: '100%',
  height: '100%',
  wordBreak: 'break-word',
}));

/** 汎用ボックススタイル(背景色: 白) */
const WhiteBox = styled(NormalBox)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
}));

/** 汎用ボックススタイル(背景色: ピンク) */
const PinkBox = styled(NormalBox)(({ theme }) => ({
  backgroundColor: theme.palette.cyclonistLightPink.main,
}));

/**
 * 出力物コンポーネント
 * @param props
 * @returns
 */
const PrintContents: React.VFC<PrintContentsProps> = (props) => {
  return (
    <Box>
      {/* ヘッダー部 */}
      <CustomRowGrid columns={12}>
        <CustomHeadGrid xs={8}>
          <PinkBox>
            <Typography>出力物</Typography>
          </PinkBox>
        </CustomHeadGrid>
        <CustomBodyGrid xs={4}>
          <PinkBox>
            <Typography>件数</Typography>
          </PinkBox>
        </CustomBodyGrid>
      </CustomRowGrid>

      {/* データ部 */}
      {props.contents.map((content, index) => {
        return (
          <CustomRowGrid key={index}>
            <CustomHeadGrid xs={8}>
              <WhiteBox>
                <Typography>{content.name}</Typography>
              </WhiteBox>
            </CustomHeadGrid>
            <CustomBodyGrid xs={4}>
              <WhiteBox>
                <Typography>{content.count}件</Typography>
              </WhiteBox>
            </CustomBodyGrid>
          </CustomRowGrid>
        );
      })}
    </Box>
  );
};

export default PrintContents;
