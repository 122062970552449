import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OldSalesSearchConditionState } from './types';
import { initialOldSalesSearchCondition } from './defaults';

const slice = createSlice({
    name: 'oldSalesSearchCondition',
    initialState: initialOldSalesSearchCondition,
  reducers: {
      save: (state, action: PayloadAction<OldSalesSearchConditionState>) => action.payload,
      clear: () => ({ ...initialOldSalesSearchCondition }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
