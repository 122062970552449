import { BigNumber } from 'bignumber.js';
import { DeficitSurplusFlg } from 'constants/define';
import { useCallback } from 'react';

import { usePageContext } from '../usePageContext';

export type Step = [threshold: number, price: number];

export type PriceTable = {
  steps: Step[];
  priceWhenOver: number;
  overedStep?: Step;
};

/** 価格の計算処理Hooks */
export const usePriceCalculater = (): {
  calcSurfaceArea: (cylSizeWidth: number | null, cylSizeEnsyuu: number | null) => number;
  calcUnitPrice: (unitPrice: number | null, surfaceArea: number) => number;
  calcUnitPriceCylMakingUnRounded: (
    unitPrice: number | null,
    cylSizeWidth: number | null,
    circleRate?: number,
  ) => number;
  calcUnitPriceCylMaking: (
    unitPrice: number | null,
    cylSizeWidth: number | null,
    circleRate?: number,
  ) => number;
  calcUnitPriceCylMakingByWidth: (
    unitPrice: number | null,
    width: number | null,
    cylSizeEnsyuu: number | null,
    priceTable: PriceTable,
  ) => number;
  calcUnitPriceCylMakingBySurfaceArea: (surfaceArea: number, priceTable: PriceTable) => number;
  calcUnitPriceHanberiMaking: (cylMakingPrice: number, hanberiRate: number | null) => number;
  calcMakingUnitPrice: (unitPrices: number[], cylMakingPrice: number) => number;
  calcMakingPrice: (unitPrices: number[], amount: number, cylMakingPrice: number) => number;
  calcMakingGrossInvoice: (
    unitPrices: number[],
    amount: number,
    toyoPriceRate: number,
    cylMakingPrice: number,
  ) => number;
  calcMakingOptionPrice: (
    cylMakingPrice: number,
    scale: number | null,
    amount: number | null,
  ) => number;
  calcOtherCylinderPrice: (unitPrice: number | null, amount: number | null) => number;
  calcOtherCylinderPricePerArea: (
    unitPrice: number | null,
    amount: number | null,
    surfaceArea: number,
  ) => number;
  calcDiscountRate: (price: number, discountRate: number | null) => number;
  calcGrossInvoice: (
    price: number | null,
    toyoPriceRate: number | null,
    toyoPriceRateFlg?: boolean,
  ) => number;
} => {
  // 赤処理であるか
  const { orderMetaData } = usePageContext();
  const isDeficit: boolean = orderMetaData?.deficitSurplusFlg === DeficitSurplusFlg.Deficit;

  /** 四捨五入 */
  const round = useCallback((price: number, decimalPlaces = 0) => {
    const priceBN = new BigNumber(price);
    const decimalPlacesBN = new BigNumber(decimalPlaces);

    if (decimalPlaces >= 0) {
      return priceBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_HALF_UP).toNumber();
    } else {
      const factor = new BigNumber(10).pow(decimalPlacesBN.abs());
      return priceBN
        .dividedBy(factor)
        .decimalPlaces(0, BigNumber.ROUND_HALF_UP)
        .times(factor)
        .toNumber();
    }
  }, []);

  /** 表面積の計算 */
  const calcSurfaceArea = useCallback(
    (cylSizeWidth: number | null, cylSizeEnsyuu: number | null): number => {
      return new BigNumber(cylSizeWidth ?? 0)
        .times(cylSizeEnsyuu ?? 0)
        .dividedBy(100)
        .integerValue(BigNumber.ROUND_CEIL)
        .toNumber();
    },
    [],
  );

  /** 単価売価の計算 */
  const calcUnitPrice = useCallback((unitPrice: number | null, surfaceArea: number): number => {
    return round(new BigNumber(unitPrice ?? 0).times(surfaceArea).toNumber());
  }, []);

  /** 単価売価（製版代）の計算 */
  const calcUnitPriceCylMakingUnRounded = useCallback(
    (unitPrice: number | null, cylSizeWidth: number | null, circleRate?: number): number => {
      return new BigNumber(unitPrice ?? 0)
        .times(cylSizeWidth ?? 0)
        .times(circleRate ?? 1)
        .toNumber();
    },
    [],
  );

  /** 単価売価（製版代）の計算 */
  const calcUnitPriceCylMaking = useCallback(
    (unitPrice: number | null, cylSizeWidth: number | null, circleRate?: number): number => {
      return round(calcUnitPriceCylMakingUnRounded(unitPrice, cylSizeWidth, circleRate));
    },
    [],
  );

  /** 単価売価（製版代）の計算 （巾から）（九州専用計算）*/
  const calcUnitPriceCylMakingByWidth = useCallback(
    (
      unitPrice: number | null,
      width: number | null,
      cylSizeEnsyuu: number | null,
      priceTable: PriceTable,
    ): number => {
      const { steps, priceWhenOver } = priceTable;
      const basePrice = calcUnitPriceCylMaking(unitPrice, width);
      const sign = isDeficit ? -1 : 1;

      for (const step of steps) {
        const [threshold, price] = step;
        // 円周が閾値未満であればその価格を使用する。
        if ((cylSizeEnsyuu ?? 0) < threshold) {
          const signedPrice = new BigNumber(price).times(sign).toNumber();
          return new BigNumber(basePrice).plus(signedPrice).toNumber();
        }
      }

      // 10の位まで四捨五入
      const signedPriceWhenOver = new BigNumber(priceWhenOver).times(sign).toNumber();
      const decimalPrice = new BigNumber(basePrice).plus(signedPriceWhenOver).toNumber();
      return round(decimalPrice, -1);
    },
    [isDeficit],
  );

  /** 単価売価（製版代）の計算 （表面積から）（九州専用計算）*/
  const calcUnitPriceCylMakingBySurfaceArea = useCallback(
    (surfaceArea: number, priceTable: PriceTable): number => {
      if (surfaceArea === 0) return 0;

      const { steps, priceWhenOver, overedStep } = priceTable;
      const sign = isDeficit ? -1 : 1;

      for (const step of steps) {
        const [threshold, price] = step;
        // 表面積が閾値以下であればその価格を使用する。
        if (surfaceArea <= threshold) {
          return new BigNumber(price).times(sign).toNumber();
        }
      }

      // 超過サイズ分の金額を計算
      const overPrice = (() => {
        // 超過金額が指定されていなければ0
        if (!overedStep) return 0;

        const lastStep = steps.at(-1);
        const [lastStepThreshold] = lastStep ?? [0, 0];
        const [overStepThreshold, overStepPrice] = overedStep;

        const threshold = new BigNumber(lastStepThreshold).plus(overStepThreshold).toNumber();
        const overSize = new BigNumber(surfaceArea).minus(threshold).toNumber();
        // サイズ超過がなければ0
        if (overSize <= 0) return 0;

        const overCount = new BigNumber(overSize)
          .dividedBy(overStepThreshold)
          .integerValue(BigNumber.ROUND_CEIL)
          .toNumber();
        return new BigNumber(overStepPrice).multipliedBy(overCount).toNumber();
      })();

      const price = new BigNumber(priceWhenOver).plus(overPrice).toNumber();
      return round(new BigNumber(price).times(sign).toNumber(), -1);
    },
    [isDeficit],
  );

  /** 単価売価（版べり製版代）の計算 */
  const calcUnitPriceHanberiMaking = useCallback(
    (cylMakingPrice: number, hanberiRate: number | null): number => {
      return round(new BigNumber(cylMakingPrice).times(hanberiRate ?? 0).toNumber());
    },
    [],
  );

  /** 製版単価の計算 */
  const calcMakingUnitPrice = useCallback((prices: number[], cylMakingPrice: number): number => {
    return BigNumber.sum(...prices, cylMakingPrice).toNumber();
  }, []);

  /** 製版売価の計算 */
  const calcMakingPrice = useCallback(
    (prices: number[], amount: number, cylMakingPrice: number): number => {
      const unitPrice = calcMakingUnitPrice(prices, cylMakingPrice);
      return new BigNumber(unitPrice).times(amount).toNumber();
    },
    [],
  );

  /** 製版仕切の計算 */
  const calcMakingGrossInvoice = useCallback(
    (prices: number[], amount: number, toyoPriceRate: number, cylMakingPrice: number): number => {
      const grossInvoices = prices.map((e) => calcGrossInvoice(e, toyoPriceRate));
      const cylMakingGrossInvoice = calcGrossInvoice(cylMakingPrice, toyoPriceRate);
      const unitGrossInvoice = BigNumber.sum(...grossInvoices, cylMakingGrossInvoice).toNumber();
      return new BigNumber(unitGrossInvoice).times(amount).toNumber();
    },
    [],
  );

  /** 製版オプション売価の計算 */
  const calcMakingOptionPrice = useCallback(
    (cylMakingPrice: number, scale: number | null, amount: number | null): number => {
      return round(
        new BigNumber(cylMakingPrice)
          .times(scale ?? 0)
          .times(amount ?? 0)
          .toNumber(),
      );
    },
    [],
  );

  /** その他（シリンダ）売価の計算 */
  const calcOtherCylinderPrice = useCallback(
    (unitPrice: number | null, amount: number | null): number => {
      return new BigNumber(unitPrice ?? 0).times(amount ?? 0).toNumber();
    },
    [],
  );

  /** その他（シリンダ）（面積当たりの単価）売価の計算 */
  const calcOtherCylinderPricePerArea = useCallback(
    (unitPrice: number | null, amount: number | null, surfaceArea: number): number => {
      return new BigNumber(round(new BigNumber(unitPrice ?? 0).times(surfaceArea).toNumber()))
        .times(amount ?? 0)
        .toNumber();
    },
    [],
  );

  /** 値引き率の計算 */
  const calcDiscountRate = useCallback((price: number, discountRate: number | null): number => {
    return new BigNumber(price)
      .minus(
        new BigNumber(price)
          .times(discountRate ?? 0)
          .dividedBy(100)
          .integerValue(BigNumber.ROUND_UP),
      )
      .toNumber();
  }, []);

  /** 仕切価格の計算 */
  const calcGrossInvoice = useCallback(
    (price: number | null, toyoPriceRate: number | null, toyoPriceRateFlg = true): number => {
      if (toyoPriceRateFlg) {
        return round(new BigNumber(price ?? 0).times(toyoPriceRate ?? 1).toNumber());
      } else {
        return price ?? 0;
      }
    },
    [],
  );

  return {
    calcSurfaceArea,
    calcUnitPrice,
    calcUnitPriceCylMakingUnRounded,
    calcUnitPriceCylMaking,
    calcUnitPriceCylMakingByWidth,
    calcUnitPriceCylMakingBySurfaceArea,
    calcUnitPriceHanberiMaking,
    calcMakingUnitPrice,
    calcMakingPrice,
    calcMakingGrossInvoice,
    calcMakingOptionPrice,
    calcOtherCylinderPrice,
    calcOtherCylinderPricePerArea,
    calcDiscountRate,
    calcGrossInvoice,
  };
};
