import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialBarcodeReadEngravingState } from 'store/topEngraving/barcodeReadEngraving/defaults';
import { BarcodeReadEngravingState } from 'store/topEngraving/barcodeReadEngraving/types';

const slice = createSlice({
  name: 'barcodeReadEngraving',
  initialState: InitialBarcodeReadEngravingState,
  reducers: {
    save: (state, action: PayloadAction<BarcodeReadEngravingState>) => action.payload,
    clear: () => ({ ...InitialBarcodeReadEngravingState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
