import * as React from 'react';
import { useState, useEffect } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

export type NumericFieldProps = {
  min: number;
  max: number;
  decimalDigits: number;
  name?: TextFieldProps['name'];
  value?: number | null;
  onChange?: (value: number | null) => void;
  onBlur?: TextFieldProps['onBlur'];
  disabled?: TextFieldProps['disabled'];
  placeholder?: TextFieldProps['placeholder'];
  sx?: TextFieldProps['sx'];
};

function parseToNumber(value: string): number | null {
  if (value.length === 0) return null;

  const parsed = Number(value);
  return Number.isNaN(parsed) ? null : parsed;
}

function parseToText(value: number | null | undefined, decimalDigits?: number): string {
  if (value == null || value == undefined) {
    return '';
  }
  return value.toFixed(decimalDigits);
}

/** 小数点以下の桁数を取得 */
function getDecimalDigitsLength(value: number) {
  const splitted = String(value).split('.');
  return splitted[1] ? splitted[1].length : 0;
}

const NumericField: React.VFC<NumericFieldProps> = (props) => {
  const [textValue, setTextValue] = useState('');

  // valueの変更時にテキストを更新する
  useEffect(() => {
    setTextValue(parseToText(props.value, props.decimalDigits));
  }, [props.value]);

  // 数値の妥当性を検証する
  function validate(value: number | null): boolean {
    if (value === null) return true;
    if (value < props.min) return false;
    if (props.max < value) return false;
    if (getDecimalDigitsLength(value) > props.decimalDigits) return false;
    return true;
  }

  const onChange: TextFieldProps['onChange'] = (event) => {
    const inputValue: string = event.target.value;
    setTextValue(inputValue);
  };

  const onBlur: TextFieldProps['onBlur'] = (event) => {
    const inputValue: string = event.target.value;
    const numberValue: number | null = parseToNumber(inputValue);
    const isValid = validate(numberValue);
    const validatedValue = isValid ? numberValue : null;

    const text = validatedValue !== null ? parseToText(validatedValue, props.decimalDigits) : '';
    setTextValue(text);

    props.onChange?.(validatedValue);
    props.onBlur?.(event);
  };

  // 最大桁数
  const maxLength: number = (() => {
    const integerLength = Math.max(
      String(Math.floor(props.min)).length,
      String(Math.floor(props.max)).length,
    );
    // 整数部桁数（符号含む） + 小数部桁数 + 小数点
    return integerLength + props.decimalDigits + (props.decimalDigits > 0 ? 1 : 0);
  })();

  return (
    <TextField
      name={props.name}
      disabled={props.disabled}
      placeholder={props.placeholder}
      sx={props.sx}
      value={textValue}
      onChange={onChange}
      onBlur={onBlur}
      inputProps={{
        maxLength,
      }}
    />
  );
};

export default NumericField;
