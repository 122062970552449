import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialBarcodeReadBafuState } from 'store/topPlating/barcodeReadBafu/defaults';
import { BarcodeReadBafuState } from 'store/topPlating/barcodeReadBafu/types';

const slice = createSlice({
  name: 'barcodeReadBafu',
  initialState: InitialBarcodeReadBafuState,
  reducers: {
    save: (state, action: PayloadAction<BarcodeReadBafuState>) => action.payload,
    clear: () => ({ ...InitialBarcodeReadBafuState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
