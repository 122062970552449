import { CustomerEditTabIndex } from 'constants/define';
import { usePrompt } from 'hooks/usePrompt';
import useSharedMessage from 'hooks/useSharedMessage';
import React, { useState, useEffect, createContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCustomerListStore } from 'store/customer/customerList/hooks';

import { Container } from '@mui/material';

import CustomerEditPage from './CustomerEdit';
import CustomerEditCheckPage from './CustomerEditCheck';
import { defaultCustomerEdit } from './defaults';
import useCheckCustomerEdit from './hooks/useCheckCustomerEdit';
import useFetchCustomerEdit from './hooks/useFetchCustomerEdit';
import useRegistCustomerEdit from './hooks/useRegistCustomerEdit';
import { PageState, CustomerEdit } from './types';

// 画面マスタ用Context
export const CustomerEditContext = createContext<CustomerEdit>(defaultCustomerEdit);

/** 得意先編集・確認画面共通部 */
const CustomerEditMainPage: React.VFC = () => {
  // 遷移時受け渡しパラメータ
  const params = useParams<{ customerId: string }>();
  const customerId = Number(params.customerId);
  const location = useLocation<{ copy: boolean; reference: boolean }>();

  // フックス
  const { customerEdit } = useFetchCustomerEdit(customerId);
  const { IsInputCheckAsync, duplicateDel } = useCheckCustomerEdit();
  const { RegistCustomerEditAsync } = useRegistCustomerEdit();

  // 得意先一覧のストア
  const customerListCtore = useCustomerListStore();

  // フォーム
  const formMethod = useForm<CustomerEdit>({ defaultValues: defaultCustomerEdit });

  const [pageState, setPageState] = useState<PageState>(PageState.Edit);

  const history = useHistory();
  const { unblockPush, unblockGoBack } = usePrompt(!location.state.reference);
  const messageApi = useSharedMessage();
  // const { confirm, tempRegister, register, loading } = useRegister(messageApi.pushMessages);
  const [isReference, setIsReference] = React.useState(false);

  // 初期表示
  useEffect(() => {
    formMethod.reset(customerEdit);
    // 参照コピーの場合
    if (location.state.copy) {
      formMethod.setValue('customerEditSingleInfo.customerId', -1);
      formMethod.setValue('customerEditSingleInfo.customerSectionCd', '');
      formMethod.setValue('customerEditSingleInfo.customerCd', '');
      formMethod.setValue('customerEditSingleInfo.jobNoCd', '');
    }
    // 参照画面の場合
    if (location.state.reference) {
      // 得意先情報を取得したタイミングで得意先詳細確認画面を表示
      if (customerEdit.customerEditSingleInfo.customerId != -1) {
        setIsReference(true);
        setPageState(PageState.Check);
      }
    }
  }, [customerEdit]);

  /** 得意先入力ボタンアクション */
  // 戻る（得意先一覧に）
  const onBackCutomerList = () => {
    history.goBack();
  };

  // 入力チェック＋得意先編集確認に進む
  const onGoCheck = formMethod.handleSubmit(async (data: CustomerEdit) => {
    messageApi.clear();
    // 入力チェック
    const inputCheckFlag = await IsInputCheckAsync(data);
    if (inputCheckFlag) {
      setPageState(PageState.Check);
    }
  });

  /** 得意先入力確認ボタンアクション */
  const onBackEdit = () => {
    if (location.state.reference) {
      // 戻る（得意先一覧に）
      unblockGoBack();
    } else {
      // 戻る（得意先入力に）
      setPageState(PageState.Edit);
    }
  };
  // 保存
  const onSave = formMethod.handleSubmit(async (data: CustomerEdit) => {
    const registFinishFlag = await RegistCustomerEditAsync(data, duplicateDel);
    if (registFinishFlag) {
      // 得意先一覧のストアクリア
      customerListCtore.clear();
      unblockPush('/CustomerList');
    }
  });

  return (
    <>
      <FormProvider {...formMethod}>
        <Container maxWidth='md'>
          <CustomerEditContext.Provider value={customerEdit}>
            {(() => {
              switch (pageState) {
                // 得意先入力・編集
                case PageState.Edit:
                  return (
                    <CustomerEditPage
                      selectIndex={CustomerEditTabIndex.Basic}
                      onBackClick={onBackCutomerList}
                      onConfirmClick={onGoCheck}
                    />
                  );
                // 得意先確認
                case PageState.Check:
                  return (
                    <CustomerEditCheckPage
                      onBackEdit={onBackEdit}
                      onSave={onSave}
                      isReferenceOnly={isReference}
                    />
                  );
                default:
                  throw new Error();
              }
            })()}
          </CustomerEditContext.Provider>
        </Container>
      </FormProvider>
    </>
  );
};

export default CustomerEditMainPage;
