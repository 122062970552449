import * as React from 'react';

import Typography, { TypographyProps } from './Typography';

export type ColorLabelProps = {
  sx?: TypographyProps['sx'];
  color?: string;
  lineThrough?: boolean;
  variant?: TypographyProps['variant'];
};

const ColorLabel: React.FC<ColorLabelProps> = ({ children, sx, lineThrough, ...props }) => {
  return (
    <Typography {...props} sx={{ ...sx, ...(lineThrough && { textDecoration: 'line-through' }),
    '@media print': { color: 'black' }, 
    }}>
      {children}
    </Typography>
  );
};

export default ColorLabel;
