import React from 'react';

import PriceApprovalList from 'pages/priceApproval/PriceApprovalList';
import DevPortal from 'pages/develop/DevPortal';
import LoginHokkaido from 'pages/login/LoginHokkaido';
import LoginKyushu from 'pages/login/LoginKyushu';
import Login from 'pages/login/LoginNormal';
// ログイン、ポータル画面は先に読み込む
import Portal from 'pages/portalTop/Portal';

// その他の画面は遅延読み込み

// #region --- admin Menu ---
const AdminMenu = React.lazy(() => import('pages/adminMenu/AdminMenu'));
const AuditLogList = React.lazy(() => import('pages/adminMenu/AuditLogList'));
const AuditLogDetail = React.lazy(() => import('pages/adminMenu/AuditLogDetail'));
const MessageList = React.lazy(() => import('pages/adminMenu/MessageList'));
const MessageEdit = React.lazy(() => import('pages/adminMenu/Message'));
const CodeList = React.lazy(() => import('pages/adminMenu/CodeList'));
const CodeEdit = React.lazy(() => import('pages/adminMenu/Code'));
const SystemPreferencesList = React.lazy(() => import('pages/adminMenu/SystemPreferencesList'));
const SystemPreferencesEdit = React.lazy(() => import('pages/adminMenu/SystemPreferences'));
const PageInfoList = React.lazy(() => import('pages/adminMenu/PageInfoList'));
const MenuList = React.lazy(() => import('pages/adminMenu/MenuList'));
const AuthorityList = React.lazy(() => import('pages/adminMenu/AuthorityList'));
const AuthorityEmployeeEdit = React.lazy(() => import('pages/adminMenu/AuthorityEmployeeEdit'));
const DivisionList = React.lazy(() => import('pages/adminMenu/DivisionList'));
const CategoryList = React.lazy(() => import('pages/adminMenu/CategoryList'));
const EmployeeList = React.lazy(() => import('pages/adminMenu/EmployeeList'));
const EmployeeEdit = React.lazy(() => import('pages/adminMenu/Employee'));
// #endregion

// #region --- arrivalScheduleList ---
const ArrivalScheduleSearchList = React.lazy(
  () => import('pages/arrivalScheduleList/ArrivalScheduleSearchList'),
);
// #endregion

// #region --- common ---
const MakingSpecification = React.lazy(() => import('pages/common/MakingSpecification/Cylinder'));
const OrderSearch = React.lazy(() => import('pages/common/OrderSearchWindow'));
const MakingSpecificationHistory = React.lazy(
  () => import('pages/common/MakingSpecificationHistory/Cylinder'),
);
const MakingSpecificationFlexo = React.lazy(() => import('pages/common/MakingSpecification/Flexo'));
const MakingSpecificationHistoryFlexo = React.lazy(
  () => import('pages/common/MakingSpecificationHistory/Flexo'),
);
const ProgressList = React.lazy(() => import('pages/common/ProgressList/ProgressListSearch'));
const ProgressDetail = React.lazy(() => import('pages/common/ProgressList/ProgressDetail'));
// #endregion

// #region --- customer ---
const CustomerListMainPage = React.lazy(() => import('pages/customer/CustomerList'));
const CustomerEditMainPage = React.lazy(() => import('pages/customer/CustomerEdit'));
// #endregion

// #region --- erroe ---
// #endregion

// #region --- Finishing ---
const FinishingList = React.lazy(() => import('pages/finishing/FinishingList'));
// #endregion

// #region --- image Server ---
const ImageDataSearch = React.lazy(() => import('pages/imageServer/ImageDataSearch'));
const ImageDataDetail = React.lazy(() => import('pages/imageServer/ImageDataDetail'));
const ImageDataDisplay = React.lazy(() => import('pages/imageServer/ImageDataDisplay'));
const ImageDataUpLoadFolder = React.lazy(() => import('pages/imageServer/ImageDataUpLoadFolder'));
const ImageDataUpLoadFolderEdit = React.lazy(
  () => import('pages/imageServer/ImageDataUpLoadFolderEdit'),
);
const ImageDataDownLoadFolder = React.lazy(
  () => import('pages/imageServer/ImageDataDownLoadFolder'),
);
const ImageDataDownLoadFile = React.lazy(() => import('pages/imageServer/ImageDataDownLoadFile'));
// #endregion

// #region --- imageWorkSchedule ---
const ImageWorkSchedule = React.lazy(() => import('pages/imageWorkSchedule/ImageWorkSchedule'));
// #endregion

// #region --- login ---
const PasswordReissue = React.lazy(() => import('pages/login/PasswordReissue'));
const PasswordReissueCompleted = React.lazy(() => import('pages/login/PasswordReissueCompleted'));
// #endregion

// #region --- makingSchedule ---
const MakingSchedule = React.lazy(() => import('pages/makingSchedule/MakingSchedule'));
// #endregion

// #region --- imageWorkAssign ---
const ImageWorkPresent = React.lazy(() => import('pages/imageWorkAssign/ImageWorkPresent'));
const ImageWorkAssignList = React.lazy(() => import('pages/imageWorkAssign/imageWorkAssign'));
const ImageWorkDraft = React.lazy(() => import('pages/imageWorkAssign/ImageWorkDraft'));
// #endregion

// #region --- portal Top ---
const DocumentSearchList = React.lazy(() => import('pages/portalTop/DocumentSearchList'));
// #endregion

// #region --- present ---
const PresentList = React.lazy(() => import('pages/present/PresentList'));

// #endregion
// #region --- production Manage ---
const ProductionSearch = React.lazy(() => import('pages/productionManage/ProductionSearch'));
const Production = React.lazy(() => import('pages/productionManage/Production'));
const ProductionCustomerSearch = React.lazy(
  () => import('pages/productionManage/ProductionCustomerSearch'),
);
// #endregion

// #region --- top Arrival ---
const CylinderArrivalEditAnyTime = React.lazy(
  () => import('pages/topArrival/CylinderArrivalEditAnyTime'),
);
const CylinderArrival = React.lazy(() => import('pages/topArrival/CylinderArrival'));
const CylinderArrivalEditAdd = React.lazy(() => import('pages/topArrival/CylinderArrivalEditAdd'));
const ArrivalScheduleList = React.lazy(() => import('pages/topArrival/ArrivalScheduleList'));
const CylinderEdit = React.lazy(() => import('pages/topArrival/CylinderEdit'));
// #endregion

// #region --- top Engraving ---
const EngravingWorkList = React.lazy(() => import('pages/topEngraving/EngravingWorkList'));
const BarcodeReadEngraving = React.lazy(() => import('pages/topEngraving/BarcodeEngraving'));
const EngravingDataEdit = React.lazy(() => import('pages/topEngraving/EngravingData'));
const EngravingDataHistory = React.lazy(() => import('pages/topEngraving/EngravingDataHistory'));
const WorkReportEngraving = React.lazy(() => import('pages/topEngraving/WorkReportEngraving'));

// #endregion

// #region --- top Inspection ---
const InspectionWorkList = React.lazy(() => import('pages/topInspection/InspectionWorkList'));
const WorkReportInspection = React.lazy(() => import('pages/topInspection/WorkReportInspection'));
// #endregion

// #region --- top Image ---
const ImageWorkList = React.lazy(() => import('pages/topImage/ImageWorkList'));
const ImageWorkEditPresentAll = React.lazy(() => import('pages/topImage/ImageWorkEditPresentAll'));
const ImageJobWorkReportList = React.lazy(() => import('pages/topImage/ImageJobWorkReportList'));
const ImageWorkEditDesign = React.lazy(() => import('pages/topImage/ImageWorkDesign'));
const ImageWorkEditHistoryDesign = React.lazy(
  () => import('pages/topImage/ImageWorkHistoryDesign'),
);
const ImageWorkReportDetailDesign = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailDesign'),
);
const ImageWorkReportDetailHistoryDesign = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailHistoryDesign'),
);
const ImageWorkEditPhotoRetouch = React.lazy(() => import('pages/topImage/ImageWorkPhotoRetouch'));
const ImageWorkEditHistoryPhotoRetouch = React.lazy(
  () => import('pages/topImage/ImageWorkHistoryPhotoRetouch'),
);
const ImageWorkReportDetailPhotoRetouch = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailPhotoRetouch'),
);
const ImageWorkReportDetailHistoryPhotoRetouch = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailHistoryPhotoRetouch'),
);
const ImageWorkEditColorSeparation = React.lazy(
  () => import('pages/topImage/ImageWorkColorSeparation'),
);
const ImageWorkEditHistoryColorSeparation = React.lazy(
  () => import('pages/topImage/ImageWorkHistoryColorSeparation'),
);
const ImageWorkReportDetailColorSeparation = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailColorSeparation'),
);
const ImageWorkReportDetailHistoryColorSeparation = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailHistoryColorSeparation'),
);
const ImageWorkEditPresent = React.lazy(() => import('pages/topImage/ImageWorkPresent'));
const ImageWorkEditHistoryPresent = React.lazy(
  () => import('pages/topImage/ImageWorkHistoryPresent'),
);
const ImageWorkReportDetailPresent = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailPresent'),
);
const ImageWorkReportDetailHistoryPresent = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailHistoryPresent'),
);
const ImageWorkEditInspection = React.lazy(() => import('pages/topImage/ImageWorkInspection'));
const ImageWorkEditHistoryInspection = React.lazy(
  () => import('pages/topImage/ImageWorkHistoryInspection'),
);
const ImageWorkReportDetailInspection = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailInspection'),
);
const ImageWorkReportDetailHistoryInspection = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailHistoryInspection'),
);
const ImageWorkEditLayout = React.lazy(() => import('pages/topImage/ImageWorkLayout'));
const ImageWorkEditHistoryLayout = React.lazy(
  () => import('pages/topImage/ImageWorkHistoryLayout'),
);
const ImageWorkReportDetailLayout = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailLayout'),
);
const ImageWorkReportDetailHistoryLayout = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailHistoryLayout'),
);
const ImageWorkReportDetailPresentAll = React.lazy(
  () => import('pages/topImage/ImageWorkReportDetailPresentAll'),
);
// #endregion

// #region --- top Laser ---
const LaserWorkList = React.lazy(() => import('pages/topLaser/LaserWorkList'));
const BarcodeReadLaser = React.lazy(() => import('pages/topLaser/BarcodeReadLaser'));
const WorkReportLaser = React.lazy(() => import('pages/topLaser/WorkReportLaser'));
// #endregion

// #region --- top Laser ---
const LaserData = React.lazy(() => import('pages/topLaser/LaserData'));
const LaserDataHistory = React.lazy(() => import('pages/topLaser/LaserData/LaserDataEditHistory'));
// #endregion

// #region --- top Plating ---
const PlatingWorkList = React.lazy(() => import('pages/topPlating/PlatingWorkList'));
const BarcodeReadCuPlating = React.lazy(() => import('pages/topPlating/BarcodeReadCuPlating'));
const WorkReportCuPlating = React.lazy(() => import('pages/topPlating/WorkReportCuPlating'));
const BarcodeReadPM = React.lazy(() => import('pages/topPlating/BarcodePM'));
const WorkReportPM = React.lazy(() => import('pages/topPlating/WorkReportPM'));
const BarcodeReadFinishing = React.lazy(() => import('pages/topPlating/BarcodeFinishing'));
const WorkReportFinishing = React.lazy(() => import('pages/topPlating/WorkReportFinishing'));
const BarcodeReadBafu = React.lazy(() => import('pages/topPlating/BarcodeReadBafu'));
const WorkReportBafu = React.lazy(() => import('pages/topPlating/WorkReportBafu'));
const BarcodeReadCrPlating = React.lazy(() => import('pages/topPlating/BarcodeReadCrPlating'));
const WorkReportCrPlating = React.lazy(() => import('pages/topPlating/WorkReportCrPlating'));
const CylinderWorksCylJobListEditCheck = React.lazy(
  () => import('pages/topPlating/CylinderWorksCylJobListEditCheck'),
);
// #endregion

// #region --- work Report Search ---
const ImageWorkReportSearch = React.lazy(
  () => import('pages/workReportSearch/ImageWorkReportSearch'),
);
const ImageWorkReportInfoList = React.lazy(
  () => import('pages/workReportSearch/ImageWorkReportInfoList'),
);
// #endregion

// #region --- top Sales ---
const OrderList = React.lazy(() => import('pages/topSales/OrderList'));
const ProductionSelect = React.lazy(() => import('pages/topSales/order/ProductionSelect'));
const WorkNoticeEdit = React.lazy(() => import('pages/topSales/order/WorkNoticeEdit'));
const WorkNoticeEditCheck = React.lazy(() => import('pages/topSales/order/WorkNoticeEditCheck'));
const CylinderOrder = React.lazy(
  () => import('pages/topSales/order/CylinderOrder/CylinderOrderEdit'),
);
const FlexoOrder = React.lazy(() => import('pages/topSales/order/FlexoOrder/FlexoOrderEdit'));
const OtherOrder = React.lazy(() => import('pages/topSales/order/OtherOrder/OtherOrderEdit'));
const PostSearchResult = React.lazy(() => import('pages/common/PostSearchResult'));
// #endregion

// #region --- top Sales ---
const PasswordChange = React.lazy(() => import('pages//PasswordChange/PasswordChangeEdit'));
const PasswordChangeCompleted = React.lazy(
  () => import('pages//PasswordChange/PasswordChangeCompleted'),
);
// #endregion

// #region --- top Shipment ---
const ShipmentList = React.lazy(() => import('pages/topShipment/ShipmentList'));
const CylinderShipmentDelivery = React.lazy(
  () => import('pages//topShipment/CylinderShipmentDelivery'),
);
const CylinderShipmentCancel = React.lazy(
  () => import('pages//topShipment/CylinderShipmentCancel'),
);
// #endregion

// #region --- salesLedger ---
const SalesLedgerList = React.lazy(() => import('pages/salesLedger/SalesLedger'));
// #endregion

// #region --- salesList ---
const SalesList = React.lazy(() => import('pages/salesList/SalesList'));
// #endregion

// #region --- schedule ---
const ImageWorkScheduleList = React.lazy(() => import('pages/schedule/ImageWorkScheduleList'));
const SeihanScheduleList = React.lazy(() => import('pages/schedule/SeihanScheduleList'));
const ShipmentScheduleList = React.lazy(() => import('pages/schedule/ShipmentScheduleList'));
const DefermentList = React.lazy(() => import('pages/schedule/Defermentlist'));
const ShipmentPriorityList = React.lazy(() => import('pages/schedule/ShipmentPriorityList'));
// #endregion

// #region --- receivableList ---
const ReceivableList = React.lazy(() => import('pages/receivableList/ReceivableList'));
// #endregion

// #region --- manuscript ---
const ManuscriptMenu = React.lazy(() => import('pages/manuscript/ManuscriptMenu'));
const ManuscriptArrivalEdit = React.lazy(() => import('pages/manuscript/ManuscriptArrivalEdit'));
const ManuscriptArrivalEditCheck = React.lazy(
  () => import('pages/manuscript/ManuscriptArrivalEditCheck'),
);
const ManuscriptShipmentEdit = React.lazy(() => import('pages/manuscript/ManuscriptShipmentEdit'));
const ManuscriptShipmentEditCheck = React.lazy(
  () => import('pages/manuscript/ManuscriptShipmentEditCheck'),
);
const RackSearch = React.lazy(() => import('pages/manuscript/RackSearch'));
const RackEdit = React.lazy(() => import('pages/manuscript/RackEdit'));
const RackEditCheck = React.lazy(() => import('pages/manuscript/RackEditCheck'));
const ManuscriptMoveSearch = React.lazy(() => import('pages/manuscript/ManuscriptMoveSearch'));
const ManuscriptMoveEdit = React.lazy(() => import('pages/manuscript/ManuscriptMoveEdit'));
const ManuscriptMoveEditCheck = React.lazy(
  () => import('pages/manuscript/ManuscriptMoveEditCheck'),
);
const ManuscriptSearch = React.lazy(() => import('pages/manuscript/ManuscriptSearch'));
// #endregion

// #region --- OldSales ---
const OldSales = React.lazy(() => import('pages/oldSales/OldSalesSearch'));
const OldSalesList = React.lazy(() => import('pages/oldSales/OldSalesList'));
// #endregion

// #region --- cylinderWorks ---
const CylinderWorksRead = React.lazy(() => import('pages/cylinderWorks/CylinderWorksRead'));
const CylinderWorksUse = React.lazy(() => import('pages/cylinderWorks/CylinderWorksUse'));
const CylinderWorks2Read = React.lazy(() => import('pages/cylinderWorks2/CylinderWorks2Read'));
const CylinderWorks2WorkerEdit = React.lazy(
  () => import('pages/cylinderWorks2/CylinderWorks2WorkerEdit'),
);
// #endregion

// #region --- cylinderSearcher ---
const CylinderSearcher = React.lazy(() => import('pages/cylinderSearcher/CylinderSearcher'));
const CylinderSearcherBarcode = React.lazy(
  () => import('pages/cylinderSearcher/CylinderSearcherBarcode'),
);
// #endregion

// #region --- dailyProcess ---
const DailyProcessList = React.lazy(() => import('pages/dailyProcess/DailyProcessList'));
// #endregion

// #region --- deliveryList ---
const DeliveryList = React.lazy(() => import('pages/delivery/DeliveryList'));
const DeliveryListHokkaido = React.lazy(() => import('pages/delivery/DeliveryListHokkaido'));
// #endregion

// #region --- dailyProcess ---
const DemandProcessList = React.lazy(() => import('pages/demandProcess/DemandProcessList'));
// #endregion

// #region --- monthlyProcess ---
const MonthlyProcess = React.lazy(() => import('pages/monthlyProcess/MonthlyProcess'));
// #endregion

// #region --- unappropriateList ---
const UnappropriateList = React.lazy(() => import('pages/unappropriateList/UnappropriateList'));
// #endregion

// #region --- messageTitle ---
const MessageTitleList = React.lazy(() => import('pages/messageTitle/MessageTitleList'));
// #endregion

// #region --- paymentProcess ---
const PaymentProcess = React.lazy(() => import('pages/paymentProcess/PaymentProcess'));
// #endregion

// #region --- paymentHistory ---
const PaymentHistoryList = React.lazy(() => import('pages/paymentHistory/PaymentHistoryList'));
// #endregion

// #region --- messageItem ---
const MessageItemList = React.lazy(() => import('pages/messageItem/MessageItemList'));
const MessageItem = React.lazy(() => import('pages/messageItem/MessageItem'));
// #endregion

// #region --- imageWorkReportList ---
const ImageWorkReportList = React.lazy(
  () => import('pages/imageWorkReportList/ImageWorkReportList'),
);
// #endregion

// #region --- salesConditionList ---
const SalesConditionList = React.lazy(() => import('pages/salesConditionList/SalesConditionList'));
// #endregion

// #region --- holidayCalender ---
const HolidayCalender = React.lazy(() => import('pages/holidayCalender/HolidayCalender'));
const HolidayCalenderList = React.lazy(() => import('pages/holidayCalender/HolidayCalenderList'));
// #endregion

// #region --- templateImageDetail ---
const TemplateImageDetail = React.lazy(
  () => import('pages/templateImageDetail/TemplateImageDetail'),
);
// #endregion

// #region --- cylinderMakingLimit ---
const CylinderMakingLimit = React.lazy(
  () => import('pages/cylinderMakingLimit/CylinderMakingLimit'),
);
// #endregion

export const entryRoutes = [
  // #region --- login ---
  {
    path: '/Cyclonist2GBRN',
    exact: true,
    name: 'ログイン',
    component: Login,
  },
  {
    path: '/Cyclonist2GBRNHK',
    exact: true,
    name: 'ログイン',
    component: LoginHokkaido,
  },
  {
    path: '/Cyclonist2GBRN9',
    exact: true,
    name: 'ログイン',
    component: LoginKyushu,
  },
  {
    path: '/',
    exact: true,
    name: 'ログイン',
    component: Login,
  },
  // #endregion
];
export const publicRoutes = [
  // #region --- login ---
  { path: '/PasswordReissue', exact: true, name: 'PasswordReissue', component: PasswordReissue },
  {
    path: '/PasswordReissueCompleted',
    exact: true,
    name: 'ユーザーID・パスワード再発行完了',
    component: PasswordReissueCompleted,
  },
  // #endregion
];

export const privateRoutes = [
  // #region --- admin Menu ---
  {
    path: '/AdminMenu',
    exact: true,
    name: '管理メニュー',
    component: AdminMenu,
  },
  {
    path: '/AuditLogList',
    exact: true,
    name: '監査証跡一覧',
    component: AuditLogList,
  },
  {
    path: '/AuditLogDetail/:accessLogId',
    exact: true,
    name: '監査証跡詳細',
    component: AuditLogDetail,
  },
  {
    path: '/MessageList',
    exact: true,
    name: 'メッセージ一覧',
    component: MessageList,
  },
  {
    path: '/MessageEdit',
    exact: true,
    name: 'メッセージ新規登録',
    component: MessageEdit,
  },
  {
    path: '/MessageEdit/:originalMessageId',
    exact: true,
    name: 'メッセージ編集',
    component: MessageEdit,
  },
  {
    path: '/CodeList',
    exact: true,
    name: 'コードメンテナンスマスター一覧',
    component: CodeList,
  },
  {
    path: '/CodeEdit',
    exact: true,
    name: 'コードメンテナンスマスター新規登録',
    component: CodeEdit,
  },
  {
    path: '/CodeEdit/:cdId',
    exact: true,
    name: 'コードメンテナンスマスター編集',
    component: CodeEdit,
  },
  {
    path: '/SystemPreferencesList',
    exact: true,
    name: 'システムプレファレンス一覧',
    component: SystemPreferencesList,
  },
  {
    path: '/SystemPreferencesEdit',
    exact: true,
    name: 'システムプレファレンス新規登録',
    component: SystemPreferencesEdit,
  },
  {
    path: '/SystemPreferencesEdit/:originalPreferenceKey',
    exact: true,
    name: 'システムプレファレンス編集',
    component: SystemPreferencesEdit,
  },
  {
    path: '/PageInfoList',
    exact: true,
    name: 'ページ情報一覧',
    component: PageInfoList,
  },
  {
    path: '/MenuList',
    exact: true,
    name: 'メニュー一覧',
    component: MenuList,
  },
  {
    path: '/AuthorityList',
    exact: true,
    name: '社員権限一覧',
    component: AuthorityList,
  },
  {
    path: '/AuthorityEmployeeEdit/:originalAuthorityId',
    exact: true,
    name: '権限の社員付与登録',
    component: AuthorityEmployeeEdit,
  },
  {
    path: '/DivisionList',
    exact: true,
    name: '部署一覧',
    component: DivisionList,
  },
  {
    path: '/CategoryList',
    exact: true,
    name: '大分類一覧',
    component: CategoryList,
  },
  {
    path: '/EmployeeList',
    exact: true,
    name: '社員マスター一覧',
    component: EmployeeList,
  },
  {
    path: '/EmployeeEdit/:employeeId',
    exact: true,
    name: '社員マスタ編集',
    component: EmployeeEdit,
  },
  // #endregion

  // #region --- arrivalScheduleList ---
  {
    path: '/ArrivalScheduleSearchList',
    exact: true,
    name: '入荷予定一覧',
    component: ArrivalScheduleSearchList,
  },
  // #endregion

  // #region --- common ---
  {
    path: '/ProgressList',
    exact: true,
    name: '進捗 対象JOB',
    component: ProgressList,
  },
  {
    path: '/ProgressDetail/:jobId',
    exact: true,
    name: '進捗詳細 対象JOB',
    component: ProgressDetail,
  },
  // #endregion

  // #region --- customer ---
  {
    path: '/CustomerList',
    exact: true,
    name: '得意先一覧',
    component: CustomerListMainPage,
  },
  {
    path: '/CustomerEdit/:customerId',
    exact: true,
    name: '得意先入力',
    component: CustomerEditMainPage,
  },

  // #endregion

  // #region --- erroe ---
  // #endregion

  // #region --- image Server ---
  {
    path: '/ImageDataSearch',
    exact: true,
    name: '画像検索',
    component: ImageDataSearch,
  },
  {
    path: '/ImageDataDetail/:jobId',
    exact: true,
    name: '画像詳細',
    component: ImageDataDetail,
  },
  {
    path: '/ImageDataUpLoadFolder',
    exact: true,
    name: '画像アップロード',
    component: ImageDataUpLoadFolder,
  },
  {
    path: '/ImageDataUpLoadFolderEdit',
    exact: true,
    name: '画像アップロード ファイルコメント入力',
    component: ImageDataUpLoadFolderEdit,
  },
  {
    path: '/ImageDataDownLoadFolder',
    exact: true,
    name: '画像ダウンロード(フォルダ)',
    component: ImageDataDownLoadFolder,
  },
  {
    path: '/ImageDataDownLoadFile',
    exact: true,
    name: '画像ダウンロード(ファイル)',
    component: ImageDataDownLoadFile,
  },

  // #endregion

  // #region --- imageWorkSchedule ---
  {
    path: '/ImageWorkSchedule',
    exact: true,
    name: '画像作業予定作成一覧',
    component: ImageWorkSchedule,
  },
  { path: '/makingSchedule', exact: true, name: '製版予定作成一覧', component: MakingSchedule },
  // #endregion

  // #region --- imageWorkAssign ---
  {
    path: '/ImageWorkDraft/:jobId',
    exact: true,
    name: '画像作業振分け 入稿編集',
    component: ImageWorkDraft,
  },
  {
    path: '/ImageWorkAssignList',
    exact: true,
    name: '画像作業振分け一覧',
    component: ImageWorkAssignList,
  },
  {
    path: '/ImageWorkPresent/:jobId',
    exact: true,
    name: '画像作業振分け 提出物編集',
    component: ImageWorkPresent,
  },
  // #endregion

  // #region --- portal Top ---
  { path: '/DevPortal', exact: true, name: '開発用ポータル', component: DevPortal },
  { path: '/Portal', exact: true, name: 'ポータルTOP', component: Portal },
  {
    path: '/DocumentSearchList',
    exact: true,
    name: '書類棚検索',
    component: DocumentSearchList,
  },
  // #endregion

  // #region --- presen ---
  { path: '/PresentList', exact: true, name: '提出物作業一覧', component: PresentList },
  // #endregion

  // #region --- production Manage ---
  {
    path: '/ProductionCustomerSearch',
    exact: true,
    name: 'JOB/得意先検索',
    component: ProductionCustomerSearch,
  },
  {
    path: '/ProductionSearch',
    exact: true,
    name: 'プロダクション検索',
    component: ProductionSearch,
  },
  {
    path: '/Production/:productionId',
    exact: true,
    name: 'プロダクション編集',
    component: Production,
  },
  // #endregion

  // #region --- top Arrival ---
  {
    path: '/CylinderArrivalEditAnyTime',
    exact: true,
    name: 'シリンダー随時入荷入力',
    component: CylinderArrivalEditAnyTime,
  },
  {
    path: '/ArrivalScheduleList',
    exact: true,
    name: '入荷一覧',
    component: ArrivalScheduleList,
  },
  {
    path: '/CylinderArrival/:jobId',
    exact: true,
    name: 'シリンダー入荷入力',
    component: CylinderArrival,
  },
  {
    path: '/CylinderArrival',
    exact: true,
    name: 'シリンダー随時入荷入力',
    component: CylinderArrival,
  },
  {
    path: '/CylinderArrivalEditAdd',
    exact: true,
    name: 'シリンダー追加',
    component: CylinderArrivalEditAdd,
  },
  {
    path: '/CylinderEdit/:cylId',
    exact: true,
    name: 'シリンダー編集',
    component: CylinderEdit,
  },
  // #endregion

  // #region --- top Engraving ---
  {
    path: '/EngravingWorkList',
    exact: true,
    name: '彫刻作業一覧',
    component: EngravingWorkList,
  },
  {
    path: '/BarcodeReadEngraving/:jobId',
    exact: true,
    name: '彫刻製版 バーコード読込',
    component: BarcodeReadEngraving,
  },
  {
    path: '/EngravingDataEdit/:jobId',
    exact: true,
    name: '彫刻データ 設定値入力',
    component: EngravingDataEdit,
  },
  {
    path: '/WorkReportEngraving/:jobId',
    exact: true,
    name: '彫刻/彫刻製版設定値入力',
    component: WorkReportEngraving,
  },
  // #endregion

  // #region --- top Inspection ---
  {
    path: '/InspectionWorkList',
    exact: true,
    name: '検版作業一覧',
    component: InspectionWorkList,
  },
  {
    path: '/WorkReportInspection/:jobId',
    exact: true,
    name: '検版作業記録入力',
    component: WorkReportInspection,
  },
  // #endregion

  // #region --- top Image ---
  {
    path: '/ImageWorkList',
    exact: true,
    name: '画像作業一覧',
    component: ImageWorkList,
  },
  {
    path: '/ImageJobWorkReportList/:jobId',
    exact: true,
    name: 'JOB別作業一覧',
    component: ImageJobWorkReportList,
  },
  {
    path: '/ImageWorkEditPresentAll/:jobId',
    exact: true,
    name: '提出物 画像作業記録全体入力',
    component: ImageWorkEditPresentAll,
  },
  {
    path: '/ImageWorkEditDesign/:jobId',
    exact: true,
    name: 'デザイン 画像作業記録入力',
    component: ImageWorkEditDesign,
  },
  {
    path: '/ImageWorkEditHistoryDesign/:jobId',
    exact: true,
    name: 'デザイン 画像作業記録入力（履歴）',
    component: ImageWorkEditHistoryDesign,
  },
  {
    path: '/ImageWorkReportDetailDesign/:jobId',
    exact: true,
    name: 'デザイン 画像作業記録参照',
    component: ImageWorkReportDetailDesign,
  },
  {
    path: '/ImageWorkReportDetailHistoryDesign/:jobId',
    exact: true,
    name: 'デザイン 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryDesign,
  },
  {
    path: '/ImageWorkEditPhotoRetouch/:jobId',
    exact: true,
    name: '分解 画像作業記録入力',
    component: ImageWorkEditPhotoRetouch,
  },
  {
    path: '/ImageWorkEditHistoryPhotoRetouch/:jobId',
    exact: true,
    name: '分解 画像作業記録入力（履歴）',
    component: ImageWorkEditHistoryPhotoRetouch,
  },
  {
    path: '/ImageWorkReportDetailPhotoRetouch/:jobId',
    exact: true,
    name: '分解 画像作業記録参照',
    component: ImageWorkReportDetailPhotoRetouch,
  },
  {
    path: '/ImageWorkReportDetailHistoryPhotoRetouch/:jobId',
    exact: true,
    name: '分解 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryPhotoRetouch,
  },
  {
    path: '/ImageWorkEditColorSeparation/:jobId',
    exact: true,
    name: '色分け 画像作業記録入力',
    component: ImageWorkEditColorSeparation,
  },
  {
    path: '/ImageWorkEditHistoryColorSeparation/:jobId',
    exact: true,
    name: '色分け 画像作業記録入力（履歴）',
    component: ImageWorkEditHistoryColorSeparation,
  },
  {
    path: '/ImageWorkReportDetailColorSeparation/:jobId',
    exact: true,
    name: '色分け 画像作業記録参照',
    component: ImageWorkReportDetailColorSeparation,
  },
  {
    path: '/ImageWorkReportDetailHistoryColorSeparation/:jobId',
    exact: true,
    name: '色分け 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryColorSeparation,
  },
  {
    path: '/ImageWorkEditPresent/:jobId/:workReportId',
    exact: true,
    name: '提出物 画像作業記録入力',
    component: ImageWorkEditPresent,
  },
  {
    path: '/ImageWorkEditHistoryPresent/:jobId/:workReportId',
    exact: true,
    name: '提出物 画像作業記録入力（履歴）',
    component: ImageWorkEditHistoryPresent,
  },
  {
    path: '/ImageWorkReportDetailPresent/:jobId/:workReportId',
    exact: true,
    name: '提出物 画像作業記録参照',
    component: ImageWorkReportDetailPresent,
  },
  {
    path: '/ImageWorkReportDetailHistoryPresent/:jobId/:workReportId',
    exact: true,
    name: '提出物 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryPresent,
  },
  {
    path: '/ImageWorkEditInspection/:jobId',
    exact: true,
    name: '検版 画像作業記録入力',
    component: ImageWorkEditInspection,
  },
  {
    path: '/ImageWorkEditHistoryInspection/:jobId',
    exact: true,
    name: '検版 画像作業記録入力（履歴）',
    component: ImageWorkEditHistoryInspection,
  },
  {
    path: '/ImageWorkReportDetailInspection/:jobId',
    exact: true,
    name: '検版 画像作業記録参照',
    component: ImageWorkReportDetailInspection,
  },
  {
    path: '/ImageWorkReportDetailHistoryInspection/:jobId',
    exact: true,
    name: '検版 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryInspection,
  },
  {
    path: '/ImageWorkEditLayout/:jobId',
    exact: true,
    name: '下版 画像作業記録入力',
    component: ImageWorkEditLayout,
  },
  {
    path: '/ImageWorkEditHistoryLayout/:jobId',
    exact: true,
    name: '下版 画像作業記録入力（履歴）',
    component: ImageWorkEditHistoryLayout,
  },
  {
    path: '/ImageWorkReportDetailLayout/:jobId',
    exact: true,
    name: '下版 画像作業記録参照',
    component: ImageWorkReportDetailLayout,
  },
  {
    path: '/ImageWorkReportDetailHistoryLayout/:jobId',
    exact: true,
    name: '下版 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryLayout,
  },
  // #endregion

  // #region --- top Laser ---
  {
    path: '/LaserWorkList',
    exact: true,
    name: 'レーザー作業一覧',
    component: LaserWorkList,
  },

  {
    path: '/BarcodeReadLaser/:jobId',
    exact: true,
    name: 'レーザー製版 バーコード読込',
    component: BarcodeReadLaser,
  },
  {
    path: '/WorkReportLaser/:jobId',
    exact: true,
    name: 'レーザー製版 作業記録入力',
    component: WorkReportLaser,
  },
  // #endregion

  // #region --- top Laser ---
  {
    path: '/LaserData/:jobId',
    exact: true,
    name: 'レーザーデータ 設定値入力',
    component: LaserData,
  },
  // #endregion

  // #region --- top Plating ---
  {
    path: '/PlatingWorkList',
    exact: true,
    name: 'メッキ作業一覧',
    component: PlatingWorkList,
  },
  {
    path: '/BarcodeReadCuPlating/:jobId',
    exact: true,
    name: '銅メッキ バーコード読込',
    component: BarcodeReadCuPlating,
  },
  {
    path: '/WorkReportCuPlating/:jobId',
    exact: true,
    name: '銅メッキ 作業記録入力確認',
    component: WorkReportCuPlating,
  },
  {
    path: '/BarcodeReadPM/:jobId',
    exact: true,
    name: 'PM バーコード読込',
    component: BarcodeReadPM,
  },
  {
    path: '/WorkReportPM/:jobId',
    exact: true,
    name: 'PM 作業記録入力',
    component: WorkReportPM,
  },
  {
    path: '/BarcodeReadFinishing/:jobId',
    exact: true,
    name: '仕上げ バーコード読込',
    component: BarcodeReadFinishing,
  },
  {
    path: '/WorkReportFinishing/:jobId',
    exact: true,
    name: '仕上げ 作業記録入力',
    component: WorkReportFinishing,
  },
  {
    path: '/BarcodeReadBafu/:jobId',
    exact: true,
    name: 'ピンホール検査 バーコード読込',
    component: BarcodeReadBafu,
  },
  {
    path: '/WorkReportBafu/:jobId',
    exact: true,
    name: 'ピンホール検査 作業記録入力',
    component: WorkReportBafu,
  },
  {
    path: '/BarcodeReadCrPlating/:jobId',
    exact: true,
    name: 'クロムメッキ バーコード読込',
    component: BarcodeReadCrPlating,
  },
  {
    path: '/WorkReportCrPlating/:jobId',
    exact: true,
    name: 'クロムメッキ 作業記録入力',
    component: WorkReportCrPlating,
  },
  {
    path: '/CylinderWorksCylJobListEditCheck/:jobId',
    exact: true,
    name: 'CW シリンダーJOBリスト編集 確認',
    component: CylinderWorksCylJobListEditCheck,
  },
  // #endregion

  // #region --- top Sales ---
  {
    path: '/OrderList',
    exact: true,
    name: '受注一覧',
    component: OrderList,
  },
  {
    path: '/ProductionSelect',
    exact: true,
    name: '得意先・PRODUCTION選択',
    component: ProductionSelect,
  },
  {
    path: '/CylinderOrder',
    exact: true,
    name: 'シリンダー受注入力',
    component: CylinderOrder,
  },
  {
    path: '/FlexoOrder',
    exact: true,
    name: 'フレキソ受注入力',
    component: FlexoOrder,
  },
  {
    path: '/OtherOrder',
    exact: true,
    name: 'その他受注入力',
    component: OtherOrder,
  },
  {
    path: '/WorkNoticeEdit',
    exact: false,
    name: 'WORK/連絡票登録',
    component: WorkNoticeEdit,
  },
  {
    path: '/WorkNoticeEditCheck',
    exact: false,
    name: 'WORK/連絡票入力確認',
    component: WorkNoticeEditCheck,
  },
  {
    path: '/PostSearchResult',
    exact: true,
    name: '郵便番号検索結果一覧',
    component: PostSearchResult,
  },
  {
    path: '/PasswordChange',
    exact: true,
    name: 'パスワード変更',
    component: PasswordChange,
  },
  {
    path: '/PasswordChangeCompleted',
    exact: true,
    name: 'パスワード完了',
    component: PasswordChangeCompleted,
  },
  // #endregion

  // #region --- top Shipment ---
  {
    path: '/ShipmentList',
    exact: true,
    name: '出荷一覧',
    component: ShipmentList,
  },
  {
    path: '/CylinderShipmentDelivery/:jobId',
    exact: true,
    name: 'シリンダー出荷 仮納品書発行指示入力',
    component: CylinderShipmentDelivery,
  },
  {
    path: '/CylinderShipmentCancel/:jobId',
    exact: true,
    name: 'シリンダー出荷 出荷戻し',
    component: CylinderShipmentCancel,
  },
  // #endregion

  // #region --- work Report Search ---
  {
    path: '/ImageWorkReportSearch',
    exact: true,
    name: '作業記録検索',
    component: ImageWorkReportSearch,
  },
  {
    path: '/ImageWorkReportInfoList/:productionId',
    exact: true,
    name: '作業記録検索',
    component: ImageWorkReportInfoList,
  },
  // #endregion

  // #region --- Finishing ---
  {
    path: '/FinishingList',
    exact: true,
    name: '仕上り一覧',
    component: FinishingList,
  },
  // #endregion

  // #region --- salesLedger ---
  {
    path: '/SalesLedgerList',
    exact: true,
    name: '売上元帳検索',
    component: SalesLedgerList,
  },
  // #endregion

  // #region --- salesList ---
  {
    path: '/SalesList',
    exact: true,
    name: '売掛金管理表（自社締）検索',
    component: SalesList,
  },
  // #endregion

  // #region --- schedule ---
  {
    path: '/ImageWorkScheduleList',
    exact: true,
    name: '画像作業予定一覧',
    component: ImageWorkScheduleList,
  },
  {
    path: '/SeihanScheduleList',
    exact: true,
    name: '製版予定一覧',
    component: SeihanScheduleList,
  },
  {
    path: '/ShipmentScheduleList',
    exact: true,
    name: '出荷予定一覧',
    component: ShipmentScheduleList,
  },
  {
    path: '/DefermentList',
    exact: true,
    name: '保留・STOP一覧',
    component: DefermentList,
  },
  {
    path: '/ShipmentPriorityList',
    exact: true,
    name: '出荷優先一覧',
    component: ShipmentPriorityList,
  },
  // #endregion

  // #region --- receivableList ---
  {
    path: '/ReceivableList',
    exact: true,
    name: '未収金一覧',
    component: ReceivableList,
  },
  // #endregion

  // #region --- imageWorkReportList ---
  {
    path: '/ImageWorkReportList',
    exact: true,
    name: '画像作業記録一覧',
    component: ImageWorkReportList,
  },

  // #region --- manuscript ---
  {
    path: '/ManuscriptMenu',
    exact: true,
    name: '書類棚管理メニュー',
    component: ManuscriptMenu,
  },
  {
    path: '/ManuscriptArrivalEdit',
    exact: true,
    name: '原稿袋入庫入力',
    component: ManuscriptArrivalEdit,
  },
  {
    path: '/ManuscriptArrivalEditCheck',
    exact: true,
    name: '原稿袋入庫入力確認',
    component: ManuscriptArrivalEditCheck,
  },
  {
    path: '/ManuscriptShipmentEdit',
    exact: true,
    name: '原稿袋出庫入力',
    component: ManuscriptShipmentEdit,
  },
  {
    path: '/ManuscriptShipmentEditCheck',
    exact: true,
    name: '原稿袋出庫入力確認',
    component: ManuscriptShipmentEditCheck,
  },
  {
    path: '/RackSearch',
    exact: true,
    name: '棚番号検索',
    component: RackSearch,
  },
  {
    path: '/RackEdit',
    exact: true,
    name: '棚作成入力',
    component: RackEdit,
  },
  {
    path: '/RackEditCheck',
    exact: true,
    name: '棚作成入力確認',
    component: RackEditCheck,
  },
  {
    path: '/ManuscriptMoveSearch',
    exact: true,
    name: '棚移動検索',
    component: ManuscriptMoveSearch,
  },
  {
    path: '/ManuscriptMoveEdit',
    exact: true,
    name: '棚移動入力',
    component: ManuscriptMoveEdit,
  },
  {
    path: '/ManuscriptMoveEditCheck',
    exact: true,
    name: '棚移動入力確認',
    component: ManuscriptMoveEditCheck,
  },
  {
    path: '/ManuscriptSearch',
    exact: true,
    name: 'JOB No.／棚番号検索',
    component: ManuscriptSearch,
  },
  // #endregion

  // #region --- top OldSalesSearch ---
  {
    path: '/OldSalesSearch',
    exact: true,
    name: '旧販管検索',
    component: OldSales,
  },
  {
    path: '/OldSalesList/:id',
    exact: true,
    name: '旧販管受注',
    component: OldSalesList,
  },
  // #region --- cylinderWorks ---
  {
    path: '/CylinderWorksRead',
    exact: true,
    name: 'cylinderWorks',
    component: CylinderWorksRead,
  },
  {
    path: '/CylinderWorksUse',
    exact: true,
    name: 'CylinderWorksUse',
    component: CylinderWorksUse,
  },
  {
    path: '/CylinderWorks2Read',
    exact: true,
    name: 'cylinderWorks2',
    component: CylinderWorks2Read,
  },
  {
    path: '/CylinderWorks2WorkerEdit',
    exact: true,
    name: 'CylinderWorks2WorkerEdit',
    component: CylinderWorks2WorkerEdit,
  },
  // #endregion

  // #region --- cylinderSearcher ---
  {
    path: '/CylinderSearcher',
    exact: true,
    name: 'CylinderSearcher',
    component: CylinderSearcher,
  },
  {
    path: '/CylinderSearcherBarcode/:cylId',
    exact: true,
    name: 'CylinderSearcherBarcode',
    component: CylinderSearcherBarcode,
  },
  // #endregion

  // #region --- dailyProcess ---
  {
    path: '/DailyProcess',
    exact: true,
    name: '日次処理',
    component: DailyProcessList,
  },
  // #endregion

  // #region --- deliveryList ---
  {
    path: '/DeliveryList',
    exact: true,
    name: '納品書発行',
    component: DeliveryList,
  },
  {
    path: '/DeliveryListHokkaido',
    exact: true,
    name: '納品書発行(北海道)',
    component: DeliveryListHokkaido,
  },
  // #endregion

  // region --- approvalList ---
  {
    path: '/PriceApprovalList',
    exact: true,
    name: '価格承認',
    component: PriceApprovalList,
  },
  // #endregion

  // #region --- demandProcess ---
  {
    path: '/DemandProcess',
    exact: true,
    name: '請求処理',
    component: DemandProcessList,
  },
  // #endregion

  // #region --- monthlyProcess ---
  {
    path: '/MonthlyProcess',
    exact: true,
    name: '月次処理',
    component: MonthlyProcess,
  },
  // #endregion

  // #region --- unappropriateList ---
  {
    path: '/UnappropriateList',
    exact: true,
    name: '未計上一覧',
    component: UnappropriateList,
  },
  // #endregion
  // #region --- messageTitle ---
  {
    path: '/MessageTitleList',
    exact: true,
    name: 'メッセージ項目管理',
    component: MessageTitleList,
  },
  // #region --- messageItem ---
  {
    path: '/MessageItemList',
    exact: true,
    name: 'メッセージ項目一覧',
    component: MessageItemList,
  },
  {
    path: '/MessageItem',
    exact: true,
    name: 'メッセージ項目',
    component: MessageItem,
  },
  // #endregion
  // #endregion

  // #region --- pemandProcess ---
  {
    path: '/PaymentProcess',
    exact: true,
    name: '入金処理',
    component: PaymentProcess,
  },
  // #endregion

  // #region --- paymentHistory ---
  {
    path: '/PaymentHistory',
    exact: true,
    name: '入金履歴・照会・修正',
    component: PaymentHistoryList,
  },
  // #endregion

  // #region --- imageWorkReportList ---
  {
    path: '/ImageWorkReportList',
    exact: true,
    name: '画像作業記録一覧',
    component: ImageWorkReportList,
  },
  // #endregion

  // #region --- salesConditionList ---
  {
    path: '/SalesConditionList',
    exact: true,
    name: '売上条件別一覧',
    component: SalesConditionList,
  },
  // #endregion

  // #region --- holidayCalender ---
  {
    path: '/HolidayCalenderList',
    exact: true,
    name: '休日管理一覧',
    component: HolidayCalenderList,
  },
  {
    path: '/HolidayCalender',
    exact: true,
    name: '休日管理入力',
    component: HolidayCalender,
  },
  // #endregion

  // #region --- templateImageDetail ---
  {
    path: '/TemplateImageDetail/:materialNo',
    exact: true,
    name: 'テンプレート素材詳細',
    component: TemplateImageDetail,
  },
  // #endregion

  // #region --- CylinderMakingLimit ---
  {
    path: '/CylinderMakingLimit',
    exact: true,
    name: '製版リミット',
    component: CylinderMakingLimit,
  },
  // #endregion
];

export const privateEmptyRoutes = [
  // #region --- common ---
  {
    path: '/MakingSpecification/Cylinder',
    exact: true,
    name: '製版指示書',
    component: MakingSpecification,
  },
  {
    path: '/MakingSpecification/Flexo',
    exact: true,
    name: 'フレキソ製版指示書',
    component: MakingSpecificationFlexo,
  },
  {
    path: '/MakingSpecificationHistory/Cylinder',
    exact: true,
    name: '製版指示書(履歴)',
    component: MakingSpecificationHistory,
  },
  {
    path: '/MakingSpecificationHistory/Flexo',
    exact: true,
    name: 'フレキソ製版指示書(履歴)',
    component: MakingSpecificationHistoryFlexo,
  },
  {
    path: '/ImageDataDisplay/:jobId',
    exact: true,
    name: '画像確認',
    component: ImageDataDisplay,
  },
  {
    path: '/OrderSearch',
    exact: true,
    name: '詳細検索',
    component: OrderSearch,
  },
  // #endregion

  // #region --- top Engraving ---
  {
    path: '/EngravingDataHistory/:jobId',
    exact: true,
    name: '彫刻データ設定値入力(履歴)',
    component: EngravingDataHistory,
  },
  // #endregion

  // #region --- top Image ---
  {
    path: '/ImageWorkReportDetailDesignSub/:jobId',
    exact: true,
    name: 'デザイン 画像作業記録参照',
    component: ImageWorkReportDetailDesign,
  },
  {
    path: '/ImageWorkReportDetailHistoryDesignSub/:jobId',
    exact: true,
    name: 'デザイン 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryDesign,
  },
  {
    path: '/ImageWorkReportDetailPhotoRetouchSub/:jobId',
    exact: true,
    name: '分解 画像作業記録参照',
    component: ImageWorkReportDetailPhotoRetouch,
  },
  {
    path: '/ImageWorkReportDetailHistoryPhotoRetouchSub/:jobId',
    exact: true,
    name: '分解 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryPhotoRetouch,
  },
  {
    path: '/ImageWorkReportDetailColorSeparationSub/:jobId',
    exact: true,
    name: '色分け 画像作業記録参照',
    component: ImageWorkReportDetailColorSeparation,
  },
  {
    path: '/ImageWorkReportDetailHistoryColorSeparationSub/:jobId',
    exact: true,
    name: '色分け 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryColorSeparation,
  },
  {
    path: '/ImageWorkReportDetailPresentSub/:jobId/:workReportId',
    exact: true,
    name: '提出物 画像作業記録参照',
    component: ImageWorkReportDetailPresent,
  },
  {
    path: '/ImageWorkReportDetailHistoryPresentSub/:jobId/:workReportId',
    exact: true,
    name: '提出物 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryPresent,
  },
  {
    path: '/ImageWorkReportDetailInspectionSub/:jobId',
    exact: true,
    name: '検版 画像作業記録参照',
    component: ImageWorkReportDetailInspection,
  },
  {
    path: '/ImageWorkReportDetailHistoryInspectionSub/:jobId',
    exact: true,
    name: '検版 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryInspection,
  },
  {
    path: '/ImageWorkReportDetailLayoutSub/:jobId',
    exact: true,
    name: '下版 画像作業記録参照',
    component: ImageWorkReportDetailLayout,
  },
  {
    path: '/ImageWorkReportDetailHistoryLayoutSub/:jobId',
    exact: true,
    name: '下版 画像作業記録参照（履歴）',
    component: ImageWorkReportDetailHistoryLayout,
  },
  {
    path: '/ImageWorkReportDetailPresentSub/:jobId',
    exact: true,
    name: '提出物 画像作業記録全体参照',
    component: ImageWorkReportDetailPresentAll,
  },
  {
    path: '/LaserDataHistory/:jobId',
    exact: true,
    name: 'レーザーデータ 設定値参照(履歴)',
    component: LaserDataHistory,
  },
  // #endregion
];
