import React, { ReactNodeArray } from 'react';

import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

/** フレキソ受注 作業予定 1列コンポーネント */
export const RowOne: React.FC<{ children: ReactNodeArray }> = ({ children: [label, body] }) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={2} bxs={10}>
          {label}
          {body}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

/** フレキソ受注 作業予定 2列コンポーネント */
export const RowTwo: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid xs={3}>{labelOne}</HeadGrid>
        <BodyGrid xs={3}>{bodyOne}</BodyGrid>
        <HeadGrid xs={3}>{labelTwo}</HeadGrid>
        <BodyGrid xs={3}>{bodyTwo}</BodyGrid>
      </RowGrid>
    </>
  );
};

/** フレキソ受注 作業予定 3列コンポーネント */
export const RowThree: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo, labelThree, bodyThree],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid xs={2}>{labelOne}</HeadGrid>
        <BodyGrid xs={2}>{bodyOne}</BodyGrid>
        <HeadGrid xs={2}>{labelTwo}</HeadGrid>
        <BodyGrid xs={2}>{bodyTwo}</BodyGrid>
        <HeadGrid xs={2}>{labelThree}</HeadGrid>
        <BodyGrid xs={2}>{bodyThree}</BodyGrid>
      </RowGrid>
    </>
  );
};
