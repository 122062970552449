import useMessage from 'hooks/useMessage';
import React, { useState } from 'react';
import { useEffect } from 'react';

import { Box, Container } from '@mui/material';

import IconExplanationDetail from '../ProgressDetail/components/IconExplanation';
import ImageProgress from '../ProgressDetail/components/ImageProgress';
import MakingProgress from '../ProgressDetail/components/MakingProgress';
import IconExplanation from './components/IconExplanation';
import ProgressListData from './components/ProgressList';
import MessageListener from 'components/Message/MessageListener';
import { Circular, CloseButton, BackButton, Heading } from 'components/parts/atoms';
import { NewWindow } from 'components/parts/atoms/NewWindow';
import { DialogProps, FooterButtonsContainer } from 'components/parts/molecules';
import { Dialog } from 'components/parts/molecules';
import { JobInfo } from 'components/parts/organisms';

import useFetchProgressDetail from '../ProgressDetail/hooks/useFetchProgressDetail';
import useFetchProgressList from './hooks/useFetchProgressList';

// jobNo設定時、Navibarからの呼び出し
type Props = {
  jobId?: number;
  jobNo?: string;
  open: boolean;
  onClose: () => void;
  disablePortal?: DialogProps['disablePortal'];
  isWindow?: boolean;
};

const PageState = {
  List: 'List',
  Detail: 'Detail',
} as const;
type PageState = typeof PageState[keyof typeof PageState];

const ProgressListDialog: React.VFC<Props> = (props) => {
  const messageApi = useMessage();
  const [pageState, setPageState] = useState<PageState>(PageState.List);
  const { progressList, fetch, clearData, loading } = useFetchProgressList(
    props.jobId ?? 0,
    props.jobNo ?? '',
    messageApi.pushMessages,
  );

  const { progressDetail, detailLoading, getProgressDetail } = useFetchProgressDetail();

  const [jobId, setJobId] = useState<number>(0);

  // isOpenWindowがtrueの場合は別Windowで表示する(左記以外はDialog)
  const [isOpenWindow, setOpenWindow] = useState(false);

  useEffect(() => {
    if (props.open) {
      fetch();
    } else {
      messageApi.clear();
      clearData();
    }
  }, [props.open]);

  useEffect(() => {
    setOpenWindow(props.isWindow ?? false);
  }, [props.isWindow]);

  const handleClose = () => {
    props.onClose();
    setPageState(PageState.List);
  };

  const handleTransition = (jobId: number) => {
    setJobId(jobId);
    getProgressDetail(jobId);
    setPageState(PageState.Detail);
  };

  const handleBackPage = () => {
    setPageState(PageState.List);
  };
  handleBackPage;

  const listBody = (): JSX.Element => {
    return (
      <>
        {(() => {
          // ローディング画面
          if (loading) {
            return <Circular />;
          } else {
            return (
              <Container maxWidth='lg'>
                <ProgressListData
                  progressList={progressList}
                  onClickTransition={handleTransition}
                />
                <Box m={2} />
                <IconExplanation />
              </Container>
            );
          }
        })()}
        <Box m={5} />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CloseButton onClick={handleClose} />
        </Box>
      </>
    );
  };

  const detailBody = (): JSX.Element => {
    return (
      <>
        {(() => {
          // ローディング画面
          if (detailLoading) {
            return <Circular />;
          } else {
            return (
              <>
                <Box m={2} />
                <Box>
                  <JobInfo jobId={jobId ?? 0} />
                </Box>
                <Box m={2} />
                <Box>
                  <ImageProgress imageStatusModel={progressDetail.imageStatusList} />
                </Box>
                <Box m={2} />
                <Box
                  sx={{
                    '& .MuiGrid-container': {
                      flexWrap: 'nowrap',
                    },
                  }}
                >
                  <MakingProgress makingStatusModel={progressDetail.makingStatusList} />
                </Box>
              </>
            );
          }
        })()}
        <Box m={2} />
        <IconExplanationDetail />
        <FooterButtonsContainer>
          <BackButton onClick={() => handleBackPage()} />
        </FooterButtonsContainer>
        <Box m={2} />
      </>
    );
  };

  return (
    <>
      {!isOpenWindow &&
        (() => {
          switch (pageState) {
            // メッセージ編集・登録
            case PageState.List:
              return (
                <Dialog
                  open={props.open}
                  title={`進捗 対象JOB`}
                  onClose={handleClose}
                  messageApi={messageApi}
                  disablePortal={props.disablePortal}
                >
                  {listBody()}
                </Dialog>
              );
            // メッセージ入力確認
            case PageState.Detail:
              return (
                <Dialog
                  open={props.open}
                  title={`進捗一覧 詳細`}
                  onClose={handleClose}
                  messageApi={messageApi}
                  disablePortal={props.disablePortal}
                >
                  {detailBody()}
                </Dialog>
              );
            default:
              throw new Error();
          }
        })()}
      {props.open &&
        isOpenWindow &&
        (() => {
          switch (pageState) {
            // メッセージ編集・登録
            case PageState.List:
              return (
                <NewWindow features={{ height: 720, width: 1280 }} onUnload={props.onClose}>
                  <Heading>進捗 対象JOB</Heading>
                  {listBody()}
                </NewWindow>
              );
            // メッセージ入力確認
            case PageState.Detail:
              return (
                <NewWindow features={{ height: 720, width: 1280 }} onUnload={props.onClose}>
                  <Heading>進捗一覧 詳細</Heading>
                  <Box sx={{ m: 1 }}>
                    <MessageListener messageApi={messageApi} />
                  </Box>
                  {detailBody()}
                </NewWindow>
              );
            default:
              throw new Error();
          }
        })()}
    </>
  );
};
export default ProgressListDialog;
