import * as React from 'react';

import TextField, { TextFieldProps } from './TextField';

export type KanaFieldProps = {
  maxLength: number;
  id?: TextFieldProps['id'];
  name?: TextFieldProps['name'];
  value?: TextFieldProps['value'];
  onChange?: TextFieldProps['onChange'];
  onBlur?: TextFieldProps['onBlur'];
  disabled?: TextFieldProps['disabled'];
  placeholder?: TextFieldProps['placeholder'];
  sx?: TextFieldProps['sx'];
  multiline?: TextFieldProps['multiline'];
  rows?: TextFieldProps['rows'];
};

const KanaField: React.VFC<KanaFieldProps> = (props) => {
  return (
    <TextField
      id={props.id}
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      inputProps={{ maxLength: props.maxLength }}
      sx={props.sx}
      multiline={props.multiline}
      rows={props.rows}
    />
  );
};

export default KanaField;
