import { useFormContext, useWatch } from 'react-hook-form';

import { FlexoOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** その他（画像等） 集計情報 Hooks */
export const useOtherImagePriceTotal = (): { price: number; grossInvoice: number } => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcGrossInvoice } = usePriceCalculater();

  const otherImagePrice = useWatch({
    control,
    name: 'price.otherImagePrice',
  });

  let price = 0;
  let grossInvoice = 0;

  for (const row of otherImagePrice.kakakuJouhouSonotas) {
    price += row.price ?? 0;
    grossInvoice += calcGrossInvoice(row.price, toyoPriceRate, row.toyoPriceRateFlg);
  }

  return {
    price,
    grossInvoice,
  };
};
