import { productionCustomerSearchStore } from './types';

/* JOB/得意先検索のStore初期値 **/
export const defaultProductionCustomerSearchStore: productionCustomerSearchStore = {
  form: {
    jobNo: '',
    oldJobNo: '',
    customer: null,
  },
};
