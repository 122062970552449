import React from 'react';

import { Box } from '@mui/material';

import FlexoBasicInfos from './components/flexoBasicInfos';
import FlexoUnitPriceInfos from './components/flexoUnitPriceInfos';
import { BackButton, ConfirmButton } from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules/FooterButtonsContainer';

import { FlexoEditProps } from '../types';

const UnitPriceEditFlexo: React.VFC<FlexoEditProps> = (props) => {
  return (
    <>
      <FlexoBasicInfos />

      <Box m={2} />

      <FlexoUnitPriceInfos />

      <FooterButtonsContainer>
        <BackButton onClick={props.onBack} />
        <ConfirmButton onClick={props.onGoCheck} />
      </FooterButtonsContainer>
    </>
  );
};

export default UnitPriceEditFlexo;
