export type Customer = {
  customerId: number;
  customerSectionCd: string;
  customerCd: string;
  customerName: string;
  demandId: number;
  demandCustomerSectionCd: string;
  demandCustomerCd: string;
  demandCustomerName: string;
  deleteFlg: boolean;
};

export type SearchRes = {
  customers: Customer[];
};

export const SearchTarget = {
  OWN: 1,
  ALL: 2,
} as const;
export type SearchTarget = typeof SearchTarget[keyof typeof SearchTarget];

export type SearchReq = {
  showDeletedCustomer: boolean;
  showDemandCustomer: boolean;
  searchTarget: SearchTarget;
  customerSectionCd: string;
  customerCd: string;
  customerName: string;
};
