import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';

import { CustomerEditContext } from 'pages/customer/CustomerEdit';
import { DeliveryPlaceEdit } from 'pages/customer/CustomerEdit/types';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  BackButton,
  ConfirmButton,
} from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules';

import { getSelectText } from 'utils/selectUtils';

import { EditCheckProps } from '../types';

// 納入先入力
const DeliveryAddressEdit: React.VFC<EditCheckProps> = (props) => {
  // フォームデータ
  const master = useContext(CustomerEditContext).selectBoxData;
  const deliveryPlaceFormContext = useFormContext<DeliveryPlaceEdit>();

  return (
    <>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>納入先名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='deliveryPlaceName'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>郵便番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='zipCd'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>運送会社</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='transporterId'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.transporters)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>都道府県</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='prefucturesId'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.prefectures)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>市区町村</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='address1'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>丁目・番地</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='address2'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>ビル名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='buildingName'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>電話番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='tel'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <FooterButtonsContainer>
        <BackButton onClick={props.onBack} />
        {props.checkFinishFlag === false && <ConfirmButton onClick={props.onAddForm} />}
      </FooterButtonsContainer>
    </>
  );
};

export default DeliveryAddressEdit;
