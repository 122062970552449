import React from 'react';

import { Box } from '@mui/material';

import { Typography } from 'components/parts/atoms';

export type TableCountProps = {
  count: number | null;
};

const TableCount: React.FC<TableCountProps> = (props) => {
  return (
    <Box sx={{ display: 'frex', justifyContent: 'flex-end' }}>
      <Typography variant='body1' noWrap>{`検索結果`}</Typography>
      <Typography variant='body1' noWrap>{`${props.count == null ? 0 : props.count}件`}</Typography>
    </Box>
  );
};

export default TableCount;
