import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Box, Divider, Typography } from '@mui/material';

import {
  FlexoOrderEditForm,
  PageState,
  ScheduleDate as ScheduleDateType,
  SplittedDateTime,
} from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { HeadLabel, BodyLabel, AttentionLabel } from 'components/parts/atoms';
import { SelectOption } from 'components/parts/atoms/Select/types';

import { OnlyTypeKey } from 'utils/typeUtils';

import { usePageContext } from '../../hooks/usePageContext';
import { EditCheckbox, EditDatePicker, EditSelect, ShowOnEdit } from '../Fields';
import { Heading } from '../Heading';
import { RowOne } from './Rows';

/** 受注 時刻区分リスト */
export const TimeDivList: SelectOption[] = [
  { value: '0', text: '午前' },
  { value: '1', text: '午後' },
];

/** 受注 時間リスト */
export const HourList: SelectOption[] = [
  { value: '00', text: '00' },
  { value: '01', text: '01' },
  { value: '02', text: '02' },
  { value: '03', text: '03' },
  { value: '04', text: '04' },
  { value: '05', text: '05' },
  { value: '06', text: '06' },
  { value: '07', text: '07' },
  { value: '08', text: '08' },
  { value: '09', text: '09' },
  { value: '10', text: '10' },
  { value: '11', text: '11' },
  { value: '12', text: '12' },
  { value: '13', text: '13' },
  { value: '14', text: '14' },
  { value: '15', text: '15' },
  { value: '16', text: '16' },
  { value: '17', text: '17' },
  { value: '18', text: '18' },
  { value: '19', text: '19' },
  { value: '20', text: '20' },
  { value: '21', text: '21' },
  { value: '22', text: '22' },
  { value: '23', text: '23' },
];

/** 受注 分リスト */
export const MinutesList: SelectOption[] = [
  { value: '00', text: '00' },
  { value: '30', text: '30' },
];

/** 日時選択コンポーネント */
const SplittedDateTimePicker: React.VFC<{
  propertyName: OnlyTypeKey<ScheduleDateType, SplittedDateTime>;
}> = ({ propertyName }) => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [timeDiv, hour, min] = useWatch({
    control,
    name: [
      `schedule.scheduleDate.${propertyName}.timeDiv`,
      `schedule.scheduleDate.${propertyName}.hour`,
      `schedule.scheduleDate.${propertyName}.minute`,
    ],
  });
  const { pageState } = usePageContext();

  const hasTimeDivInput = !!timeDiv;
  const hasTimeInput = !!hour || !!min;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Controller
        name={`schedule.scheduleDate.${propertyName}.day`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <EditDatePicker onChange={onChange} value={value} />
        )}
      />
      <Box mx={3} />

      <Controller
        name={`schedule.scheduleDate.${propertyName}.timeDiv`}
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <EditSelect
            addEmptyRow
            options={TimeDivList}
            onChange={onChange}
            value={value}
            name={name}
            sx={{ width: '6em' }}
            disabled={hasTimeInput}
          />
        )}
      />

      {pageState === PageState.Edit && <Box mx={2} />}

      <Controller
        name={`schedule.scheduleDate.${propertyName}.hour`}
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <EditSelect
            addEmptyRow
            options={HourList}
            onChange={onChange}
            value={value}
            name={name}
            sx={{ width: '5em' }}
            disabled={hasTimeDivInput}
          />
        )}
      />

      {(pageState === PageState.Edit || hasTimeInput) && (
        <>
          <Box mx={0.5}>
            <BodyLabel>{'：'}</BodyLabel>
          </Box>
        </>
      )}

      <Controller
        name={`schedule.scheduleDate.${propertyName}.minute`}
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <EditSelect
            addEmptyRow
            options={MinutesList}
            onChange={onChange}
            value={value}
            name={name}
            sx={{ width: '5em' }}
            disabled={hasTimeDivInput}
          />
        )}
      />
    </Box>
  );
};

/** 作業予定 予定日コンポーネント */
const ScheduleDate: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();

  return (
    <>
      <Heading>予定日</Heading>
      <RowOne>
        <Box sx={{ display: 'flex' }}>
          <ShowOnEdit>
            <AttentionLabel>【必須】</AttentionLabel>
          </ShowOnEdit>
          <HeadLabel>受注日</HeadLabel>
        </Box>
        <Controller
          name='schedule.scheduleDate.orderDate'
          control={control}
          render={({ field: { onChange, value } }) => (
            <EditDatePicker onChange={onChange} value={value} />
          )}
        />
      </RowOne>
      <RowOne>
        <HeadLabel>計上予定日</HeadLabel>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '175px' }}>
            <Controller
              name='schedule.scheduleDate.appropriateDate'
              control={control}
              render={({ field: { onChange, value } }) => (
                <EditDatePicker onChange={onChange} value={value} />
              )}
            />
          </Box>
          <BodyLabel>【締日以降を指定→翌月請求】</BodyLabel>
        </Box>
      </RowOne>
      <RowOne>
        <HeadLabel>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>画像作業</Typography>
            <Box mx={2} />
            <Typography>あり</Typography>
            <Controller
              name='schedule.scheduleDate.imageWorkFlg'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditCheckbox onChange={onChange} checked={value} name={name} />
              )}
            />
          </Box>
        </HeadLabel>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <BodyLabel>デザイン あり</BodyLabel>
          <Controller
            name='schedule.scheduleDate.designFlg'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <EditCheckbox onChange={onChange} checked={value} name={name} />
            )}
          />
          <Divider orientation='vertical' flexItem sx={{ marginX: 4 }} />
          <BodyLabel>分解 あり</BodyLabel>
          <Controller
            name='schedule.scheduleDate.phtoRetouchFlg'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <EditCheckbox onChange={onChange} checked={value} name={name} />
            )}
          />
          <Divider orientation='vertical' flexItem sx={{ marginX: 4 }} />
          <BodyLabel>色分け あり</BodyLabel>
          <Controller
            name='schedule.scheduleDate.colorSeparationFlg'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <EditCheckbox onChange={onChange} checked={value} name={name} />
            )}
          />
          <Divider orientation='vertical' flexItem sx={{ marginX: 4 }} />
          <BodyLabel>校了 あり</BodyLabel>
          <Controller
            name='schedule.scheduleDate.approvedFlg'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <EditCheckbox onChange={onChange} checked={value} name={name} />
            )}
          />
        </Box>
      </RowOne>
      <RowOne>
        <HeadLabel>入稿予定日</HeadLabel>
        <SplittedDateTimePicker propertyName='imageJobPlanDate' />
      </RowOne>
      <RowOne>
        <HeadLabel>出稿予定日</HeadLabel>
        <SplittedDateTimePicker propertyName='syukkouPlanDate' />
      </RowOne>
      <RowOne>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AttentionLabel>【必須】</AttentionLabel>
          <HeadLabel>{'提出日'}</HeadLabel>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SplittedDateTimePicker propertyName='presentationDate' />
          <Box mx={1} />
          <AttentionLabel>※『画像作業あり』の時は必須です</AttentionLabel>
        </Box>
      </RowOne>
      <RowOne>
        <HeadLabel>立会い校正印刷日</HeadLabel>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SplittedDateTimePicker propertyName='proofDate' />
          <Box mx={2} />
          <BodyLabel>{'【'}</BodyLabel>
          <BodyLabel>{'立会い有'}</BodyLabel>
          <Controller
            name='schedule.scheduleDate.proofFlg'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <EditCheckbox
                onChange={(e) => {
                  onChange(!e.target.checked);
                }}
                checked={!value}
                name={name}
              />
            )}
          />
          <BodyLabel>{'】'}</BodyLabel>
        </Box>
      </RowOne>
      <RowOne>
        <HeadLabel>出荷予定日</HeadLabel>
        <SplittedDateTimePicker propertyName='shipmentPlanDate' />
      </RowOne>
      <RowOne>
        <Box sx={{ display: 'flex' }}>
          <AttentionLabel>【必須】</AttentionLabel>
          <HeadLabel>{'納品日'}</HeadLabel>
        </Box>
        <SplittedDateTimePicker propertyName='deliveryDate' />
      </RowOne>
      <RowOne>
        <HeadLabel>得意先印刷予定日</HeadLabel>
        <SplittedDateTimePicker propertyName='customerPlanDate' />
      </RowOne>
    </>
  );
};

export default React.memo(ScheduleDate);
