import * as React from 'react';

import { Link as MUiLink, LinkProps as MuiLinkProps } from '@mui/material';

export type LinkProps = MuiLinkProps;

const Link: React.FC<MuiLinkProps> = ({ children, ...props }) => {
  return <MUiLink {...props}>{children}</MUiLink>;
};

export default Link;
