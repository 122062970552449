import * as React from 'react';

import { RotateLeft } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type ClearButtonProps = CustomButtonProps;

const ClearButton: React.VFC<ClearButtonProps> = (props) => {
  return (
    <CustomButton icon={<RotateLeft />} {...props}>
      {'クリア'}
    </CustomButton>
  );
};

export default ClearButton;
