import * as React from 'react';

import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';

export type IconButtonProps = MuiIconButtonProps;

const IconButton: React.FC<IconButtonProps> = ({ children, ...props }) => {
  return <MuiIconButton {...props}>{children}</MuiIconButton>;
};

export default IconButton;
