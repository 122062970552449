import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { HeadLabel } from 'components/parts/atoms';

import { FlexoOrderEditForm } from '../../../types';
import { Heading } from '../../Heading';
import { HeadRow } from './Row';
import SetPriceRow from './SetPriceRow';

/** 一式計算コンポーネント */
const SetPrice: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();

  const [flexoMakingsMain, flexoMakingsReverse] = useWatch({
    control,
    name: ['making.flexoMakingsMain', 'making.flexoMakingsReverse'],
  });

  return (
    <>
      <Heading>一式計算</Heading>
      <HeadRow>
        <HeadLabel>刷順</HeadLabel>
        <HeadLabel>単価</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>
      {flexoMakingsMain.map((item, index) => {
        if (item.plateMakingId !== '') {
          return (
            <SetPriceRow key={item.flxMakingId} propertyName='flexoMakingsMain' index={index} />
          );
        } else {
          return null;
        }
      })}
      {flexoMakingsReverse.map((item, index) => {
        if (item.plateMakingId !== '') {
          return (
            <SetPriceRow key={item.flxMakingId} propertyName='flexoMakingsReverse' index={index} />
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default React.memo(SetPrice);
