import * as React from 'react';

import { HomeWork } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type BackTopButtonProps = CustomButtonProps;

const BackTopButton: React.VFC<BackTopButtonProps> = (props) => {
  return (
    <CustomButton icon={<HomeWork />} color={'minor'} {...props}>
      {'TOPへ戻る'}
    </CustomButton>
  );
};

export default BackTopButton;
