import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { useUnitPriceHanberiMakingRow } from '../../../hooks/price/priceRow/useUnitPriceHanberiMakingRow';
import { UnitPriceFixedField } from '../Fields';
import { Row } from './Row';

type Props = {
  label: string;
};

/** 工程あたり単価 製版代（版べり）行コンポーネント */
const UnitPriceHanberiMakingRow: React.VFC<Props> = ({ label }) => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { cylMakingPrice, price, toyoPriceRate, grossInvoice } = useUnitPriceHanberiMakingRow();

  return (
    <Row>
      <HeadLabel>{label}</HeadLabel>

      <BodyLabel>{cylMakingPrice.toFixed()}</BodyLabel>
      <Controller
        name={'price.unitPrice.hanberiMaking.hanberiRate'}
        control={control}
        render={({ field }) => (
          <UnitPriceFixedField
            min={0}
            max={9.99}
            decimalDigits={2}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
          />
        )}
      />
      <BodyLabel>{price.toFixed()}</BodyLabel>
      <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
      <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
    </Row>
  );
};

export default React.memo(UnitPriceHanberiMakingRow);
