import { HistoryStatus, CylindersWorkCode } from 'constants/define';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// CW store
import { useCylinderWorksCylJobListEditCheck } from 'store/topPlating/cylinderWorksCylJobListEditCheck/hooks';
import { CylinderWorksBarcodeReadInfo } from 'store/topPlating/cylinderWorksCylJobListEditCheck/types';

import { ExitToApp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PostSearchResultModel } from 'api/types/common/postSearchResult';

import CylinderDetail from 'pages/common/CylinderDetail';
import PostSearchResultTable from 'pages/common/PostSearchResult';

import { Button } from 'components/parts/atoms';

import { devLinks } from '../config';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledButton = styled(Button)(() => ({}));

const Links: React.VFC = () => {
  // CW Store
  const CWState = useCylinderWorksCylJobListEditCheck();
  // ダイアログ
  const [Open2, setOpen2] = React.useState(false);
  const renderDialogs2 = (): JSX.Element => {
    return (
      <>
        <CylinderDetail
          open={Open2}
          onClose={() => {
            setOpen2(false);
          }}
          // テスト用の値 1
          jobId={498168}
          // テスト用の値 2
          // jobId={382515}
        />
      </>
    );
  };

  // ダイアログ
  const [openPostSearchResultTable, setOpenPostSearchResultTable] = React.useState(false);
  const [PostSearchResultModel, setPostSearchResult] = useState<PostSearchResultModel | null>(null);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <PostSearchResultTable
          open={openPostSearchResultTable}
          onClose={(value) => {
            if (value) {
              setPostSearchResult(value);
            }
            setOpenPostSearchResultTable(false);
          }}
          // テスト用の値
          zipcd={'788'}
        />
      </>
    );
  };

  // ダイアログから返ってきた郵便番号を表示する
  if (PostSearchResultModel != undefined) {
    console.log(PostSearchResultModel.zipCd);
  }

  // シリンダー随時入荷入力遷移の処理
  const history = useHistory();

  return (
    <>
      <Container>
        <StyledButton
          variant='contained'
          sx={{
            minWidth: '150px',
            mx: 1,
            backgroundColor: '#4cbd9b',
            '&:hover': {
              backgroundColor: '#33a080',
            },
          }}
          target='_blank'
          href='https://nxtg-ysc.backlog.com/wiki/TOYO_FPP_CYCLONIST_2/%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AE%E3%81%A4%E3%81%8F%E3%82%8A%E6%96%B9'
          endIcon={<ExitToApp />}
        >
          {'画面のつくりかた'}
        </StyledButton>
        <Box my={5}></Box>
        {devLinks.map((link) => (
          <StyledButton
            key={link.to}
            variant='contained'
            component={Link}
            to={link.to}
            sx={{
              minWidth: '150px',
              mx: 1,
              my: 1,
            }}
          >
            {link.name}
          </StyledButton>
        ))}
        {/* シリンダー随時入荷入力 */}
        <StyledButton
          variant='contained'
          sx={{
            minWidth: '150px',
            minHeight: '30px',
            mx: 1,
            my: 1,
          }}
          onClick={() => {
            history.push({
              pathname: '/CylinderArrivalEditAnyTime',
              state: { initialDisplay: HistoryStatus.InitialDisplay },
            });
          }}
        >
          {`シリンダー随時入荷入力`}
        </StyledButton>
        {/* 郵便番号検索結果一覧ダイアログ表示ボタン */}
        <StyledButton
          variant='contained'
          sx={{
            minWidth: '150px',
            minHeight: '30px',
            mx: 1,
            my: 1,
          }}
          onClick={() => {
            setOpenPostSearchResultTable(true);
          }}
        >
          {`郵便番号検索結果一覧`}
        </StyledButton>
        {/* JOB詳細 */}
        <StyledButton
          variant='contained'
          sx={{
            minWidth: '150px',
            minHeight: '30px',
            mx: 1,
            my: 1,
          }}
          onClick={() => {
            setOpen2(true);
          }}
        >
          {`JOB詳細`}
        </StyledButton>
      </Container>
      {renderDialogs()}
      {/* CW シリンダーJOBリスト編集 確認 */}
      <StyledButton
        variant='contained'
        sx={{
          minWidth: '150px',
          minHeight: '30px',
          mx: 1,
          my: 1,
        }}
        onClick={() => {
          const cylList: CylinderWorksBarcodeReadInfo = {
            cylindersWorkCode: CylindersWorkCode.Cw1,
            cylinderList: [
              { cylId: 834615, ictagNo: '2058D0129A', message: 'BCしますか？' },
              { cylId: 834616, ictagNo: '2098F041A0', message: 'BCしますか？' },
            ],
          };
          CWState.save(cylList);
          history.push({
            pathname: '/CylinderWorksCylJobListEditCheck/495850',
          });
        }}
      >
        {`CW シリンダーJOBリスト編集 確認`}
      </StyledButton>
      {renderDialogs2()}
    </>
  );
};

export default Links;
