import * as React from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Paper, Box } from '@mui/material';
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles';

import { Typography, TypographyProps, Button } from 'components/parts/atoms';

export type OpenCloseHeadingProps = {
  children: string;
  onClose: () => void;
  variant?: TypographyProps['variant'];
  backgroundColor?: string;
  textColor?: string;
  buttonText?: string;
  displayFlg: boolean;
  sx?: SxProps<Theme>;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const OpenCloseHeading: React.FC<OpenCloseHeadingProps> = ({
  variant: typoVariant = 'h3',
  ...props
}) => {
  const theme = useTheme();
  const backgroundColor = props.backgroundColor ?? theme.palette.primary.main;
  const textColor = props.textColor ?? theme.palette.primary.contrastText;
  const buttonText = props.buttonText ?? 'Close';

  return (
    <StyledPaper
      color={'primary'}
      sx={{ backgroundColor: backgroundColor, flexGrow: 1, ...props.sx }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography p={theme.spacing(1)} variant={typoVariant} sx={{ color: textColor }}>
          {props.children}
        </Typography>
        {props.displayFlg && (
          <Button
            variant='text'
            sx={{ color: textColor }}
            startIcon={<ExpandLessIcon />}
            onClick={() => props.onClose()}
          >
            {buttonText}
          </Button>
        )}
        {!props.displayFlg && (
          <Button
            variant='text'
            sx={{ color: textColor }}
            startIcon={<ExpandMoreIcon />}
            onClick={() => props.onClose()}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </StyledPaper>
  );
};

export default OpenCloseHeading;
