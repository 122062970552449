import { OrderPriceUpdateMessage } from 'constants/define';
import useSharedMessage from 'hooks/useSharedMessage';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Cached } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

import { useOtherOrderEditMaster } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/hooks/useOtherOrderEditMaster';
import {
  OthUnitPrice,
  OtherOrderEditForm,
} from 'pages/topSales/order/OtherOrder/OtherOrderEdit/types';

import { HeadLabel } from 'components/parts/atoms';

import { usePriceCalculateMatter } from '../../../hooks/price/usePriceCalculateMatter';
import { useResetPrice } from '../../../hooks/price/useResetPrice';
import { PriceIconButton, PriceSelect } from '../Fields';
import { Row } from './Row';

/** 価格情報 単価 */
const UnitPriceSelect: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<OtherOrderEditForm>();
  const { master } = useOtherOrderEditMaster();

  const { othUnitPrice } = usePriceCalculateMatter();
  const { resetPrices } = useResetPrice();

  // 単価更新押下時に単価を再設定、メッセージを表示
  const messageApi = useSharedMessage();
  const pushResetPrices = (othUnitPrice: OthUnitPrice | null) => {
    resetPrices(othUnitPrice);
    messageApi.clear();
    messageApi.pushInfo(OrderPriceUpdateMessage);
  };

  return (
    <>
      <Row>
        <HeadLabel>単価</HeadLabel>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            name='price.othUnitPriceId'
            control={control}
            render={({ field }) => (
              <PriceSelect
                addEmptyRow={true}
                options={master.othUnitPriceSelectList}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ minWidth: '12em' }}
              />
            )}
          />
          <Box mx={2}>
            <PriceIconButton onClick={() => pushResetPrices(othUnitPrice)}>
              <Tooltip title='単価を再設定する'>
                <Cached />
              </Tooltip>
            </PriceIconButton>
          </Box>
        </Box>
      </Row>
    </>
  );
};

export default React.memo(UnitPriceSelect);
