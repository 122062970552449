import { useContext } from 'react';

import { CylinderOrderEditMasterContext } from '../contexts/CylinderOrderEditMasterContext';
import { CylinderOrderEditMaster } from '../types';

/** セレクト選択肢Hooks */
export const useCylinderOrderEditMaster = (): {
  master: CylinderOrderEditMaster;
} => {
  const master = useContext(CylinderOrderEditMasterContext);

  return {
    master,
  };
};
