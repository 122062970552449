import { PageState, OrderEditTabIndex } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { CylinderOrderEditState } from './types';

/** シリンダー受注入力のストアモデルの初期値 */
export const InitialCylinderOrderEditState: CylinderOrderEditState = {
  pageState: PageState.Edit,
  tabIndex: OrderEditTabIndex.Basis,
  data: null,
};
