import * as React from 'react';

import {
  TextareaAutosize as MuiTextareaAutosize,
  TextareaAutosizeProps as MuiTextareaAutosizeProps,
} from '@mui/material';

export type TextareaAutosizeProps = MuiTextareaAutosizeProps;

const Textarea: React.FC<TextareaAutosizeProps> = ({ children, ...props }) => {
  return <MuiTextareaAutosize {...props}>{children}</MuiTextareaAutosize>;
};

export default Textarea;
