import useApiClient from 'hooks/useApiClient';
import useMessage, { MessageApi } from 'hooks/useMessage';

import { InputCheck } from '../types';

/** Hooks */
export const useCheckDeliveryAddressEdit = (): {
  IsInputCheckAsync: (inputInfo: InputCheck) => Promise<boolean>;
  messageApi: MessageApi;
} => {
  // メッセージ
  const messageApi = useMessage();
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // 入力値チェック
  const IsInputCheckAsync = async (inputInfo: InputCheck): Promise<boolean> => {
    try {
      const [allresults] = await Promise.all([
        apiClient.post(`/api/v1/DeliveryAddressEdit/IsCheckDeliveryAddress`, inputInfo),
      ]);
      if (allresults.data.result) {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    return false;
  };

  return {
    messageApi,
    IsInputCheckAsync,
  };
};

export default useCheckDeliveryAddressEdit;
