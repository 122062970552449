import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './defaults';
import { ImageWrokReportListStore } from './types';

const slice = createSlice({
  name: 'SearchCondition',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<ImageWrokReportListStore>) => action.payload,
    clear: () => ({ ...initialState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
