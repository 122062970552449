import useApiClient from 'hooks/useApiClient';
import useMessage, { MessageApi } from 'hooks/useMessage';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState } from 'react';

import { defaultProgressDetail } from '../defaults';
import { ProgressDetailResponse } from '../types';

/** 進捗詳細 対象JOB一覧のフェッチHooks */
export const useFetchProgressDetail = (): {
  progressDetail: ProgressDetailResponse;
  messageApi: MessageApi;
  detailLoading: boolean;
  getProgressDetail: (jobId: number) => void;
} => {
  const messageApi = useMessage();
  const [progressDetail, setProgressDetail] =
    useState<ProgressDetailResponse>(defaultProgressDetail);
  const [detailLoading, setLoading] = useState(false);
  const { pushMessages, clear } = useSharedMessage();
  const { apiClient } = useApiClient(pushMessages);

  /** 進捗詳細 対象JOBの初期表示 */
  const getProgressDetail = (jobId: number) => {
    clear();
    setLoading(true);

    (async () => {
      try {
        const searchCondition = {
          jobId: jobId,
        };
        const [response] = await Promise.all([
          apiClient.get<ProgressDetailResponse>(`/api/v1/ProgressDetail/GetProgressDetail`, {
            params: searchCondition,
          }),
        ]);
        setProgressDetail(response.data.result ?? defaultProgressDetail);
        setLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  };

  return {
    progressDetail,
    messageApi,
    detailLoading,
    getProgressDetail,
  };
};

export default useFetchProgressDetail;
