import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { OtherOrderEditForm, OthUnitPrice } from '../../types';
import { useOtherOrderEditMaster } from '../useOtherOrderEditMaster';

/** 価格の計算に必要な要素を返すHooks */
export const usePriceCalculateMatter = (): {
  toyoPriceRate: number;
  othUnitPrice: OthUnitPrice | null;
} => {
  // コンテキスト
  const { control } = useFormContext<OtherOrderEditForm>();
  // マスタ
  const { master } = useOtherOrderEditMaster();

  const [toyoPriceRate, othUnitPriceId] = useWatch({
    control,
    name: ['price.toyoPriceRate', 'price.othUnitPriceId'],
  });

  // その他単価
  const othUnitPrice: OthUnitPrice | null = useMemo(
    () => master.othUnitPriceList.find((e) => e.othOrderUnitPriceId === othUnitPriceId) ?? null,
    [othUnitPriceId],
  );

  return {
    toyoPriceRate: toyoPriceRate ?? 1,
    othUnitPrice,
  };
};
