import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, store } from 'store';

import { save, clear } from '.';
import { WorkNoticeEditCheckState } from './types';

export const useWorkNoticeEditCheckStore = (): {
  state: WorkNoticeEditCheckState;
  save: (state: WorkNoticeEditCheckState) => void;
  clear: () => void;
  getLatestState: () => WorkNoticeEditCheckState;
} => {
  const state = useSelector((state: RootState) => state.workNoticeEditCheck);

  const dispatch = useDispatch();

  const dispatchSave = useCallback(
    (state: WorkNoticeEditCheckState) => {
      dispatch(save(state));
    },
    [dispatch],
  );

  const dispatchClear = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  /** 最新の状態を取得 */
  const getLatestState = useCallback(() => store.getState().workNoticeEditCheck, []);

  return { state, save: dispatchSave, clear: dispatchClear, getLatestState };
};
