import React, { ReactNodeArray } from 'react';

import { RowGrid, BodyGrid, HeadGrid } from 'components/parts/atoms';

/** 一式計算 行コンポーネント */
export const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth],
}) => {
  return (
    <>
      <RowGrid>
        <BodyGrid xs={2.5} sx={{ justifyContent: 'center' }}>
          {first}
        </BodyGrid>
        <BodyGrid xs={4.5} sx={{ justifyContent: 'center' }}>
          {second}
        </BodyGrid>
        <BodyGrid xs={1.6} sx={{ justifyContent: 'end' }}>
          {third}
        </BodyGrid>
        <BodyGrid xs={1.8} sx={{ justifyContent: 'center' }}>
          {fourth}
        </BodyGrid>
        <BodyGrid xs={1.6} sx={{ justifyContent: 'end' }}>
          {fifth}
        </BodyGrid>
      </RowGrid>
    </>
  );
};

/** 一式計算 ヘッダー行コンポーネント */
export const HeadRow: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid vertical xs={2.5}>
          {first}
        </HeadGrid>
        <HeadGrid vertical xs={4.5}>
          {second}
        </HeadGrid>
        <HeadGrid vertical xs={1.6}>
          {third}
        </HeadGrid>
        <HeadGrid vertical xs={1.8}>
          {fourth}
        </HeadGrid>
        <HeadGrid vertical xs={1.6}>
          {fifth}
        </HeadGrid>
      </RowGrid>
    </>
  );
};
