import React from 'react';

import { Heading } from '../../Heading';
import MakingPriceRowHeader from '../Common/MakingPriceRowHeader';
import ReChromiumPriceRow from './ReChromiumPriceRow';

/** 再クロム */
const ReChromiumPrice: React.VFC = () => {
  return (
    <>
      <Heading>再クロム</Heading>

      <MakingPriceRowHeader />
      <ReChromiumPriceRow label='再クロム' type='ReCr' />
    </>
  );
};

export default React.memo(ReChromiumPrice);
