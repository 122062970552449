import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';

import { ApprovalContentCheckRequest } from 'api/types/approval/approvalList';
import { RegisterResponse } from 'api/types/common/apiResponse';

/** 承認の内容確認チェック更新Hooksの型定義 */
type Register = {
  checkUpdate: (postData: ApprovalContentCheckRequest) => Promise<boolean>;
};

/** 承認の内容確認チェック更新Hooks */
export const usePriceApprovalCheck = (messageApi: MessageApi): Register => {
  const { apiClient } = useApiClient(messageApi.pushMessages);

  const checkUpdate = async (postData: ApprovalContentCheckRequest): Promise<boolean> => {
    try {
      const response = apiClient.post<RegisterResponse>(
        `/api/v1/PriceApprovalList/UpdateContentCheck`,
        postData,
      );

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return { checkUpdate };
};

export default usePriceApprovalCheck;
