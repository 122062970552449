import useApiClient from 'hooks/useApiClient';
import { useState } from 'react';

import { RegisterResponse } from 'api/types/common/apiResponse';
import { ResponseMessage } from 'api/types/common/apiResponse';

import { isErrorMessageExist } from 'utils/apiResponse';

import { MyMemoForm } from '../types';

/** MyMemoデータ登録Hooksの型定義 */
type Register = {
  complete: boolean;
  myMemoRegister: (data: MyMemoForm) => void;
  resetComplete: () => void;
};

/** MyMemoデータ登録Hooks */
export const useRegister = (messageHandler: (messages: ResponseMessage[]) => void): Register => {
  // State
  const [complete, setComplete] = useState<boolean>(false);
  const { apiClient } = useApiClient(messageHandler);

  /** MyMemoデータ登録 */
  const myMemoRegister = (data: MyMemoForm) => {
    console.log('MyMemo登録');

    (async () => {
      const registerParams = { myMemos: data.myMemos, myMemoOriginals: data.myMemoOriginals };
      const [response] = await Promise.all([
        apiClient.post<RegisterResponse>(`/api/v1/MyMemoEdit/RegisterMyMemo`, registerParams),
      ]);

      if (response.data.messages && isErrorMessageExist(response.data.messages)) {
        console.log(response.data.messages);
      } else {
        setComplete(true);
      }
    })();
  };

  /** 登録完了フラグのクリア */
  const resetComplete = () => {
    setComplete(false);
  };

  return { complete, myMemoRegister, resetComplete };
};
