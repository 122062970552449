import React, { useState, useContext, useEffect } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';

import { Box, Grid, SelectChangeEvent } from '@mui/material';

import PostSearchResultTable from 'pages/common/PostSearchResult';
import { PostSearch } from 'pages/customer/DeliveryAddressEdit/types';

import {
  Typography,
  Select,
  Checkbox,
  HeadLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  NumericField,
  KanaField,
  SearchButton,
  AddButton,
  DeleteButton,
  BodyLabel,
} from 'components/parts/atoms';

import { getSelectText } from 'utils/selectUtils';

import { CustomerEditContext } from '../..';
import { CustomerEdit } from '../../types';

// 基本情報
const CustomerEditBasic: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();
  const data = useContext(CustomerEditContext);
  const master = data.selectBoxData;

  /** 担当者(当社)のデータ変更 */
  // ステート
  const chargesOurArray = useFieldArray({
    control: formContext.control,
    name: 'chargesOur',
  });
  const [ourEmployeeId, setOurEmployeeId] = useState<string>('');
  // セレクトボックスで選択
  const onOurChargeSelect = (event: SelectChangeEvent<unknown>) => {
    setOurEmployeeId(String(event.target.value));
  };
  // 追加ボタンによる追加処理
  const onAddOurCharge = () => {
    if (
      chargesOurArray.fields.some((x) => x.employeeId === Number(ourEmployeeId)) === false &&
      ourEmployeeId !== ''
    ) {
      chargesOurArray.append({
        employeeId: Number(ourEmployeeId),
        fullName: getSelectText(ourEmployeeId.toString(), master.ourCharges),
      });
      setOurEmployeeId('');
    }
  };
  // 削除ボタンによる削除処理
  const onDeleteOurCharge = (index: number) => {
    chargesOurArray.remove(index);
  };

  /** 担当者(お客様)のデータ変更 */
  // ステート
  const chargesYourArray = useFieldArray({
    control: formContext.control,
    name: 'chargesYour',
  });
  // 追加ボタンによる追加処理
  const onAddYourCharge = () => {
    chargesYourArray.append({
      customerChargeId: -1,
      seikyuusakiFlg: false,
      customerChargeName: '',
      belongPost: '',
      emailAddress: '',
    });
  };
  // 削除ボタンによる削除処理
  const onDeleteYourCharge = (index: number) => {
    chargesYourArray.remove(index);
  };

  /** 郵便番号検索 */
  // 郵便番号検索
  const onSearch = () => {
    setOpenPostSearch(true);
  };
  // 郵便番号ダイアログ
  const [openPostSearch, setOpenPostSearch] = useState(false);
  const [postSearch, setPostSearch] = useState<PostSearch | null>(null);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <PostSearchResultTable
          open={openPostSearch}
          onClose={(value) => {
            if (value) {
              setPostSearch(value);
            }
            setOpenPostSearch(false);
          }}
          zipcd={formContext.getValues('customerEditSingleInfo.zipCd')}
        />
      </>
    );
  };
  useEffect(() => {
    if (postSearch !== undefined && postSearch !== null) {
      // セレクトボックスに入れる為IDを取得
      const prefecture = master.prefectures.find(
        (x) => x.text == postSearch.prefecturesName,
      )?.value;
      formContext.setValue('customerEditSingleInfo.zipCd', postSearch.zipCd ?? '');
      formContext.setValue(
        'customerEditSingleInfo.prefecturesId',
        prefecture !== undefined ? prefecture : '',
      );
      formContext.setValue('customerEditSingleInfo.address1', postSearch.citykanjiName);
      formContext.setValue('customerEditSingleInfo.address2', postSearch.chouikikanjiName);
    }
  }, [postSearch]);

  return (
    <>
      <RowGrid columns={18}>
        <HeadGrid xs={3}>
          <HeadLabel>部門コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='customerEditSingleInfo.customerSectionCd'
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={4}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>得意先コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='customerEditSingleInfo.customerCd'
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={6}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>
            JOB No.用 <br />
            コード
          </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='customerEditSingleInfo.jobNoCd'
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={3}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>削除</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <Controller
            name='customerEditSingleInfo.deleteFlg'
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <Checkbox onChange={onChange} value={value} name={name} checked={value} />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>グループ</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <Controller
            name='customerEditSingleInfo.customerGroup'
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={1}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={18}>
        <HeadGrid xs={3}>
          <HeadLabel>得意先正式名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={10} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <Controller
            name='customerEditSingleInfo.customerFormalName'
            control={formContext.control}
            render={({ field }) => (
              <>
                <KanaField
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  maxLength={80}
                  sx={{ minWidth: '28rem' }}
                />
                <Typography>※全角20字で折り返されます。</Typography>
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>支社支店区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <Controller
            name='customerEditSingleInfo.branchFlg'
            control={formContext.control}
            render={({ field }) => (
              <Checkbox
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                checked={field.value}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>得意先表示名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={6}>
          <Controller
            name='customerEditSingleInfo.customerName'
            control={formContext.control}
            render={({ field }) => (
              <>
                <KanaField
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  maxLength={40}
                  sx={{ minWidth: '15rem' }}
                />
                <Box m={1} />
                <Typography>※画面で表示される項目です。</Typography>
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={4} sx={{ justifyContent: 'center' }}>
          <HeadLabel>売上部門グループ</HeadLabel>
        </HeadGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>得意先カナ名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={6}>
          <Controller
            name='customerEditSingleInfo.customerKanaName'
            control={formContext.control}
            render={({ field }) => (
              <>
                <KanaField
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  maxLength={40}
                  sx={{ minWidth: '15rem' }}
                />
                <Box m={1} />
                <Typography>※半角カナで入力して下さい。</Typography>
              </>
            )}
          />
        </BodyGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.sectionPeculiarInfo'
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.sections}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '17rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>担当者(当社)</HeadLabel>
        </HeadGrid>
        <Grid item xs={10}>
          <RowGrid columns={7}>
            <BodyGrid xs={6}>
              <Select
                options={master.ourCharges}
                onChange={onOurChargeSelect}
                value={ourEmployeeId}
                addEmptyRow={true}
                sx={{ minWidth: '10rem' }}
              />
            </BodyGrid>
            <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
              <AddButton onClick={() => onAddOurCharge()}></AddButton>
            </BodyGrid>
          </RowGrid>
          {chargesOurArray.fields.map((item, index) => {
            return (
              <div key={item.id}>
                <RowGrid columns={7}>
                  <BodyGrid xs={6}>
                    <Controller
                      name={`chargesOur.${index}.fullName`}
                      control={formContext.control}
                      render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                    />
                  </BodyGrid>
                  <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
                    <DeleteButton onClick={() => onDeleteOurCharge(index)}></DeleteButton>
                  </BodyGrid>
                </RowGrid>
              </div>
            );
          })}
        </Grid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>郵便番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.zipCd'
            control={formContext.control}
            render={({ field }) => (
              <>
                <KanaField
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  maxLength={7}
                />
                <Box m={1} />
                <SearchButton onClick={onSearch}></SearchButton>
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>運送会社</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.transporterId'
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.transporters}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '15rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={1}>
          <HeadLabel>
            住所
            <br />
            (請求先)
          </HeadLabel>
        </HeadGrid>
        <Grid item xs={11}>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>都道府県</HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.prefecturesId'
                control={formContext.control}
                render={({ field }) => (
                  <Select
                    options={master.prefectures}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    addEmptyRow={true}
                    sx={{ minWidth: '10rem' }}
                  />
                )}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>市区町村</HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.address1'
                control={formContext.control}
                render={({ field }) => (
                  <KanaField
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    maxLength={18}
                    sx={{ minWidth: '28rem' }}
                  />
                )}
              />
              <Box m={1} />
              <Typography> ※全角18文字以内で入力してください。</Typography>
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>丁目･番地</HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.address2'
                control={formContext.control}
                render={({ field }) => (
                  <KanaField
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    maxLength={22}
                    sx={{ minWidth: '28rem' }}
                  />
                )}
              />
              <Box m={1} />
              <Typography> ※全角22文字以内で入力してください。</Typography>
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>ビル名</HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.buildingName'
                control={formContext.control}
                render={({ field }) => (
                  <KanaField
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    maxLength={22}
                    sx={{ minWidth: '28rem' }}
                  />
                )}
              />
              <Box m={1} />
              <Typography> ※全角22文字以内で入力してください。</Typography>
            </BodyGrid>
          </RowGrid>
        </Grid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>電話番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.tel'
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={12}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>FAX番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.fax'
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={12}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>代表者名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={10}>
          <Controller
            name='customerEditSingleInfo.representativeName'
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={40}
                sx={{ minWidth: '28rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>担当者(お客様)</HeadLabel>
        </HeadGrid>
        <Grid item xs={10}>
          <RowGrid columns={14}>
            <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
              <HeadLabel>請求先</HeadLabel>
            </HeadGrid>
            <HeadGrid xs={4} sx={{ justifyContent: 'center' }}>
              <HeadLabel>担当者名</HeadLabel>
            </HeadGrid>
            <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
              <HeadLabel>所属部署</HeadLabel>
            </HeadGrid>
            <HeadGrid xs={4} sx={{ justifyContent: 'center' }}>
              <HeadLabel>MailAddress</HeadLabel>
            </HeadGrid>
            <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
              <AddButton onClick={() => onAddYourCharge()}></AddButton>
            </HeadGrid>
          </RowGrid>
          {chargesYourArray.fields.map((item, index) => {
            return (
              <div key={item.id}>
                <RowGrid columns={14}>
                  <BodyGrid xs={1}>
                    <Controller
                      name={`chargesYour.${index}.seikyuusakiFlg`}
                      control={formContext.control}
                      render={({ field: { onChange, value, name } }) => (
                        <Checkbox onChange={onChange} value={value} name={name} checked={value} />
                      )}
                    />
                  </BodyGrid>
                  <BodyGrid xs={4}>
                    <Controller
                      name={`chargesYour.${index}.customerChargeName`}
                      control={formContext.control}
                      render={({ field }) => (
                        <KanaField
                          onChange={field.onChange}
                          value={field.value}
                          name={field.name}
                          maxLength={20}
                        />
                      )}
                    />
                  </BodyGrid>
                  <BodyGrid xs={3}>
                    <Controller
                      name={`chargesYour.${index}.belongPost`}
                      control={formContext.control}
                      render={({ field }) => (
                        <KanaField
                          onChange={field.onChange}
                          value={field.value}
                          name={field.name}
                          maxLength={20}
                        />
                      )}
                    />
                  </BodyGrid>
                  <BodyGrid xs={4}>
                    <Controller
                      name={`chargesYour.${index}.emailAddress`}
                      control={formContext.control}
                      render={({ field }) => (
                        <KanaField
                          onChange={field.onChange}
                          value={field.value}
                          name={field.name}
                          maxLength={100}
                        />
                      )}
                    />
                  </BodyGrid>
                  <BodyGrid xs={2} sx={{ justifyContent: 'center' }}>
                    <DeleteButton onClick={() => onDeleteYourCharge(index)}></DeleteButton>
                  </BodyGrid>
                </RowGrid>
              </div>
            );
          })}
        </Grid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>東洋インキG情報</HeadLabel>
        </HeadGrid>
        <Grid item xs={10}>
          <RowGrid columns={10}>
            <HeadGrid xs={2}>
              <HeadLabel> Σコード</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkCustomerCd'
                control={formContext.control}
                render={({ field }) => (
                  <NumericField
                    onChange={field.onChange}
                    value={field.value === 0 ? null : field.value}
                    name={field.name}
                    min={0}
                    max={99999999999}
                    decimalDigits={0}
                  />
                )}
              />
            </BodyGrid>
            <HeadGrid xs={2}>
              <HeadLabel>倉庫コード</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkStoreHouseCd'
                control={formContext.control}
                render={({ field }) => (
                  <KanaField
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    maxLength={4}
                  />
                )}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={10}>
            <HeadGrid xs={2}>
              <HeadLabel>インキ用仕入先コード</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkSupplierCd'
                control={formContext.control}
                render={({ field }) => (
                  <KanaField
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    maxLength={6}
                  />
                )}
              />
            </BodyGrid>
            <HeadGrid xs={2}>
              <HeadLabel>インキフラグ</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkFlg'
                control={formContext.control}
                render={({ field }) => (
                  <NumericField
                    onChange={field.onChange}
                    value={field.value === 0 ? null : field.value}
                    name={field.name}
                    min={0}
                    max={1}
                    decimalDigits={0}
                  />
                )}
              />
            </BodyGrid>
          </RowGrid>
        </Grid>
      </RowGrid>
      <Box m={2} />
      <Box sx={{ display: 'frex', width: '100%' }}>
        <Typography>
          ** インキ用倉庫コード(出倉庫)は,本社 BD00,九州 BD09,北海道 BD0H
          <br />
          インキ用仕入先コード(仕入先)は,本社 045601,九州 045603,北海道 045602 <br />
          但し、インキ用仕入先コード(仕入先)の中四国向け”井原扱”に関しては本社 045605,九州 045604
          <br />
          インキフラグは得意先が東洋インキの部門である場合に1として下さい{' '}
        </Typography>
      </Box>
      {renderDialogs()}
    </>
  );
};

export default CustomerEditBasic;
