import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { BodyLabel } from 'components/parts/atoms';

import { EditCheckbox } from '../Fields';
import { Heading } from '../Heading';
import { RowThree } from './Rows';

/** 作業予定 外注区分 */
const Outsourcing: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  return (
    <>
      <Heading>外注区分</Heading>
      <RowThree>
        <BodyLabel>{'画像外注　あり'}</BodyLabel>
        <Controller
          name='schedule.outsourcing.imageOutsourcingFlg'
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <EditCheckbox onChange={onChange} checked={value} name={name} />
          )}
        />

        <BodyLabel>{'製版外注　あり'}</BodyLabel>
        <Controller
          name='schedule.outsourcing.cylMakingOutsourcingFlg'
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <EditCheckbox onChange={onChange} checked={value} name={name} />
          )}
        />

        <BodyLabel>{'オフ校外注　あり'}</BodyLabel>
        <Controller
          name='schedule.outsourcing.offproofOutsourcingFlg'
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <EditCheckbox onChange={onChange} checked={value} name={name} />
          )}
        />
      </RowThree>
    </>
  );
};

export default React.memo(Outsourcing);
