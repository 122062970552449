import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InitialCylinderOrderEditState } from './defaults';
import { CylinderOrderEditState } from './types';

const slice = createSlice({
  name: 'cylinderOrderEdit',
  initialState: InitialCylinderOrderEditState,
  reducers: {
    save: (state, action: PayloadAction<CylinderOrderEditState>) => action.payload,
    saveForm(state, action: PayloadAction<NonNullable<CylinderOrderEditState['data']>['form']>) {
      if (state.data) {
        state.data.form = action.payload;
      }
    },
    clear: () => ({ ...InitialCylinderOrderEditState }),
    setData(state, action: PayloadAction<CylinderOrderEditState['data']>) {
      state.data = action.payload;
    },
    setPageState(state, action: PayloadAction<CylinderOrderEditState['pageState']>) {
      state.pageState = action.payload;
    },
    setTabIndex(state, action: PayloadAction<CylinderOrderEditState['tabIndex']>) {
      state.tabIndex = action.payload;
    },
  },
});

export const { save, saveForm, clear, setData, setPageState, setTabIndex } = slice.actions;

export default slice.reducer;
