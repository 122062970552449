import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultCustomerListStore } from './defaults';
import { CustomerListState } from './types';

const slice = createSlice({
  name: 'customerList',
  initialState: defaultCustomerListStore,
  reducers: {
    save: (state, action: PayloadAction<CustomerListState>) => action.payload,
    clear: () => ({ ...defaultCustomerListStore }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
