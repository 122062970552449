import useApiClient from 'hooks/useApiClient';
import { ResponseMessage } from 'api/types/common/apiResponse';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState } from 'react';

import { ProgressListResponse, ProgressListModel } from '../types';

/** 進捗 対象JOB一覧のフェッチHooks */
export const useFetchProgressList = (
  jobId: number,
  jobNo: string,
  messageHandler: (messages: ResponseMessage[]) => void,
): {
  progressList: ProgressListModel;
  fetch: () => void;
  clearData: () => void;
  loading: boolean;
} => {
  // const messageApi = useMessage();
  const [progressList, setProgressList] = useState<ProgressListModel>([]);
  const [loading, setLoading] = useState(false);
  const { clear } = useSharedMessage();
  const { apiClient } = useApiClient(messageHandler);
  
  // 画面上部の進捗から検索時はjobId未設定、jobNo設定となる。
  // datetypeはDIALOG固定(NaviBar(画面上部)の進捗から検索時のみ使用)
  const searchCondition = {
    jobId: jobId,
    jobNo: jobNo,
    datetype: "DIALOG"
  };

  /** 進捗 対象JOB一覧の検索処理 */
  const fetch = () => {
    clear();
    setLoading(true);
    (async () => {
      try {
        if(searchCondition.jobId > 0){
          const [response] = await Promise.all([
            apiClient.get<ProgressListResponse>(`/api/v1/ProgressList/GetProgressJobData`, {
              params: searchCondition,
            }),
          ]);
          setProgressList(response.data.result?.progressList ?? []);
        } else 
        {
          // NaviBar(画面上部)の進捗から検索時(jobNoで検索する為、GetProgressListを呼び出す)
          const [response] = await Promise.all([
            apiClient.get<ProgressListResponse>(`/api/v1/ProgressList/GetProgressList`, {
              params: searchCondition,
            }),
          ]);
          setProgressList(response.data.result?.progressList ?? []);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  };
  const clearData = () => {
    setProgressList([]);
  };

  return {
    progressList,
    fetch,
    clearData,
    loading,
  };
};

export default useFetchProgressList;
