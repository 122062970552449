import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { LocalStorageKey } from 'constants/define';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
// #region --- adminMenu ---
import auditLogListReducer from 'store/adminMenu/AuditLogList';
import codeListReducer from 'store/adminMenu/CodeList';
import employeeListReducer from 'store/adminMenu/EmployeeList';
import menuListReducer from 'store/adminMenu/MenuList';
import appContextReducer from 'store/appContext';
// #endregion
// #region --- arrivalScheduleList ---
import arrivalScheduleSearchListReducer from 'store/arrivalScheduleList/arrivalScheduleSearchList';
import authReducer from 'store/auth';
// #endregion
// #region --- common ---
import imageWorkReducer from 'store/common/imageWork';
import makingSpecification from 'store/common/makingSpecification';
import makingSpecificationHistory from 'store/common/makingSpecificationHistory';
import progressListReducer from 'store/common/progressList';
import customerListReducer from 'store/customer/customerList';
import cylinderSearcherReducer from 'store/cylinderSearcher/CylinderSearcher';
import holidayCalenderListReducer from 'store/holidayCalender/holidayCalenderList';
import imageDataDownloadReducer from 'store/imageServer/imageDataDownload';
import imageDataSearchReducer from 'store/imageServer/imageDataSearch';
import imageDataUploadReducer from 'store/imageServer/imageDataUpLoad';
import imageWorkAssignReducer from 'store/imageWorkAssign/imageWorkAssign';
import imageWorkReportListReducer from 'store/imageWorkReportList';
import imageWorkReportSearchReducer from 'store/imageWorkReportSearch';
// #endregion
import makingScheduleReducer from 'store/makingSchedule/MakingSchedule';
// #region --- manuscript ---
import manuscriptArrivalEditReducer from 'store/manuscript/manuscriptArrivalEdit';
import manuscriptMoveEditReducer from 'store/manuscript/manuscriptMoveEdit';
import manuscriptMoveSearchReducer from 'store/manuscript/manuscriptMoveSearch';
import manuscriptShipmentEditReducer from 'store/manuscript/manuscriptShipmentEdit';
import rackEditReducer from 'store/manuscript/rackEdit';
import rackEditCheckReducer from 'store/manuscript/rackEditCheck';
import rackSearchReducer from 'store/manuscript/rackSearch';
import messageTitleListReducer from 'store/messageTitle/MessageTitleList';
// #endregion
// #region --- top OldSales ---
import oldSalesSearchReducer from 'store/oldSales/oldSalesSearch';
// #endregion
import presentListReducer from 'store/present/PresentList';
import productionCustomerSearchReducer from 'store/productionManage/productionCustomerSearch';
import productionSearchReducer from 'store/productionManage/productionSearch';
import salesLedgerListReducer from 'store/salesLedger/SalesLedgerList';
import salesListReducer from 'store/salesList/SalesList';
import ArrivalScheduleListReducer from 'store/topArrival/ArrivalScheduleList';
import cylinderArrivalEditAnyTimeReducer from 'store/topArrival/CylinderArrivalEditAnyTime';
import engravingJobListReducer from 'store/topEngraving/EngravingWorkList';
import barcodeReadEngravingReducer from 'store/topEngraving/barcodeReadEngraving';
import imageWorkEditColorSeparationReducer from 'store/topImage/ImageWorkEditColorSeparation';
import imageWorkEditDesignReducer from 'store/topImage/ImageWorkEditDesign';
import imageWorkEditHistoryColorSeparationReducer from 'store/topImage/ImageWorkEditHistoryColorSeparation';
import imageWorkEditHistoryDesignReducer from 'store/topImage/ImageWorkEditHistoryDesign';
import imageWorkEditHistoryInspectionReducer from 'store/topImage/ImageWorkEditHistoryInspection';
import imageWorkEditHistoryLayoutReducer from 'store/topImage/ImageWorkEditHistoryLayout';
import imageWorkEditHistoryPhotoRetouchReducer from 'store/topImage/ImageWorkEditHistoryPhotoRetouch';
import imageWorkEditHistoryPresentReducer from 'store/topImage/ImageWorkEditHistoryPresent';
import imageWorkEditInspectionReducer from 'store/topImage/ImageWorkEditInspection';
import imageWorkEditLayoutReducer from 'store/topImage/ImageWorkEditLayout';
import imageWorkEditPhotoRetouchReducer from 'store/topImage/ImageWorkEditPhotoRetouch';
import imageWorkEditPresentReducer from 'store/topImage/ImageWorkEditPresent';
// #region --- topImage ---
import imageWorkEditPresentAllReducer from 'store/topImage/ImageWorkEditPresentAll';
import inspectionWorkListReducer from 'store/topInspection/InspectionWorkList';
import barcodeReadLaserReducer from 'store/topLaser/barcodeReadLaser';
import laserJobListReducer from 'store/topLaser/laserWorkList';
// #endregion
import platingJobListReducer from 'store/topPlating/PlatingWorkList';
import barcodeReadBafuReducer from 'store/topPlating/barcodeReadBafu';
import barcodeReadCrPlatingReducer from 'store/topPlating/barcodeReadCrPlating';
import barcodeReadCuPlatingReducer from 'store/topPlating/barcodeReadCuPlating';
import barcodeReadFinishingReducer from 'store/topPlating/barcodeReadFinishing';
import barcodeReadPMReducer from 'store/topPlating/barcodeReadPM';
import cylinderWorksReducer from 'store/topPlating/cylinderWorks';
import cylinderWorksCylJobListEditCheckReducer from 'store/topPlating/cylinderWorksCylJobListEditCheck';
// #endregion
// #region --- topSales ---
import cylinderOrderEditReducer from 'store/topSales/order/cylinderOrderEdit';
import cylinderOrderManagerReducer from 'store/topSales/order/cylinderOrderManager';
import flexoOrderEditReducer from 'store/topSales/order/flexoOrderEdit';
import flexoOrderManagerReducer from 'store/topSales/order/flexoOrderManager';
import otherOrderEditReducer from 'store/topSales/order/otherOrderEdit';
import otherOrderManagerReducer from 'store/topSales/order/otherOrderManager';
import productionSelectReducer from 'store/topSales/order/productionSelect';
import workNoticeEditReducer from 'store/topSales/order/workNoticeEdit';
import workNoticeEditCheckReducer from 'store/topSales/order/workNoticeEditCheck';
import orderListReducer from 'store/topSales/orderList';
import shipmentListReducer from 'store/topShipment/ShipmentList';
import cylinderShipmentCancelReducer from 'store/topShipment/cylinderShipmentCancel';
import unappropriateListReducer from 'store/unappropriateList/unappropriateList';
import priceApprovalListSearchReducer from 'store/priceApproval/index';

// #endregion

// Connected React Routerで共通のインスタンスを使用する
// 必要があるためエクスポートしておく
export const history = createBrowserHistory();

const reducer = combineReducers({
  router: connectRouter(history),
  appContext: appContextReducer,
  auth: authReducer,
  productionSelect: productionSelectReducer,
  workNoticeEdit: workNoticeEditReducer,
  workNoticeEditCheck: workNoticeEditCheckReducer,
  orderList: orderListReducer,
  cylinderOrderManager: cylinderOrderManagerReducer,
  cylinderOrderEdit: cylinderOrderEditReducer,
  flexoOrderManager: flexoOrderManagerReducer,
  flexoOrderEdit: flexoOrderEditReducer,
  otherOrderManager: otherOrderManagerReducer,
  otherOrderEdit: otherOrderEditReducer,
  progressList: progressListReducer,
  makingSpecification: makingSpecification,
  makingSpecificationHistory: makingSpecificationHistory,
  salesLedgerList: salesLedgerListReducer,
  engravingJobList: engravingJobListReducer,
  laserJobList: laserJobListReducer,
  presentList: presentListReducer,
  manuscriptArrivalEdit: manuscriptArrivalEditReducer,
  manuscriptShipmentEdit: manuscriptShipmentEditReducer,
  rackEdit: rackEditReducer,
  rackEditCheck: rackEditCheckReducer,
  rackSearch: rackSearchReducer,
  manuscriptMoveEdit: manuscriptMoveEditReducer,
  manuscriptMoveSearch: manuscriptMoveSearchReducer,
  platingJobList: platingJobListReducer,
  barcodeReadPM: barcodeReadPMReducer,
  barcodeReadBafu: barcodeReadBafuReducer,
  barcodeReadEngraving: barcodeReadEngravingReducer,
  barcodeReadCrPlating: barcodeReadCrPlatingReducer,
  barcodeReadCuPlating: barcodeReadCuPlatingReducer,
  cylinderWorksCylJobListEditCheck: cylinderWorksCylJobListEditCheckReducer,
  barcodeReadLaser: barcodeReadLaserReducer,
  productionCustomerSearch: productionCustomerSearchReducer,
  productionSearch: productionSearchReducer,
  customerListReducer: customerListReducer,
  imageWorkReportSearchReducer: imageWorkReportSearchReducer,
  audistLogList: auditLogListReducer,
  imageWorkAssign: imageWorkAssignReducer,
  cylinderArrivalEditAnyTime: cylinderArrivalEditAnyTimeReducer,
  ArrivalScheduleList: ArrivalScheduleListReducer,
  codeList: codeListReducer,
  imageDataUpload: imageDataUploadReducer,
  imageDataDownload: imageDataDownloadReducer,
  imageDataSearch: imageDataSearchReducer,
  barcodeReadFinishing: barcodeReadFinishingReducer,
  inspectionWorkList: inspectionWorkListReducer,
  imageWorkEditDesign: imageWorkEditDesignReducer,
  imageWorkEditPhotoRetouch: imageWorkEditPhotoRetouchReducer,
  imageWorkEditColorSeparation: imageWorkEditColorSeparationReducer,
  imageWorkEditPresent: imageWorkEditPresentReducer,
  imageWorkEditInspection: imageWorkEditInspectionReducer,
  imageWorkEditLayout: imageWorkEditLayoutReducer,
  imageWorkReportList: imageWorkReportListReducer,
  makingSchedule: makingScheduleReducer,
  oldSalesSearch: oldSalesSearchReducer,
  menuList: menuListReducer,
  cylinderShipmentCancel: cylinderShipmentCancelReducer,
  shipmentList: shipmentListReducer,
  arrivalScheduleSearchList: arrivalScheduleSearchListReducer,
  salesList: salesListReducer,
  cylinderSearcher: cylinderSearcherReducer,
  unappropriateList: unappropriateListReducer,
  cylinderWorks: cylinderWorksReducer,
  messageTitleList: messageTitleListReducer,
  holidayCalenderList: holidayCalenderListReducer,
  employeeList: employeeListReducer,
  imageWorkEditHistoryDesign: imageWorkEditHistoryDesignReducer,
  imageWorkEditHistoryPhotoRetouch: imageWorkEditHistoryPhotoRetouchReducer,
  imageWorkEditHistoryColorSeparation: imageWorkEditHistoryColorSeparationReducer,
  imageWorkEditHistoryPresent: imageWorkEditHistoryPresentReducer,
  imageWorkEditHistoryInspection: imageWorkEditHistoryInspectionReducer,
  imageWorkEditHistoryLayout: imageWorkEditHistoryLayoutReducer,
  imageWork: imageWorkReducer,
  imageWorkEditPresentAll: imageWorkEditPresentAllReducer,
  priceApprovalListSearchConditionState: priceApprovalListSearchReducer,
});

const persistConfig = {
  key: LocalStorageKey.KeyName,
  storage: storageSession,
};
const persistedReducer = persistReducer(persistConfig, reducer);
// useSelectorでの型推論に必要なため、reducerの戻り値型をエクスポート
export type RootState = ReturnType<typeof reducer>;

export const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(routerMiddleware(history));
  },
});

export const persistor = persistStore(store);
