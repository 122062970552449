import React, { ReactNodeArray } from 'react';

import { RowGrid } from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

/** 受注基本情報 1列コンポーネント */
export const RowOne: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne],
}) => {
  return (
    <>
      <RowGrid columns={24}>
        <ContentsGrid hxs={4} bxs={20}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

/** 受注基本情報 2列コンポーネント */
export const RowTwo: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo],
}) => {
  return (
    <>
      <RowGrid columns={24}>
        <ContentsGrid hxs={4} bxs={12}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
        <ContentsGrid hxs={4} bxs={4}>
          {labelTwo}
          {bodyTwo}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};
