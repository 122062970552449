import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';

import {
  ReferenceJobCheckResponse,
  ReferenceJobInputRequest,
  TemplateCheckResponse,
  TemplateInputRequest,
} from 'api/types/topSales/order/flexoOrderEdit';

export const useReferenceInputCheck = (
  messageApi: MessageApi,
): {
  checkReferenceJob: (
    data: ReferenceJobInputRequest,
    onSucseed?: (jobid: number, linkFlg: boolean) => void,
    onFailure?: () => void,
  ) => void;
  checkTemplate: (
    data: TemplateInputRequest,
    onSucseed?: (linkFlg: boolean) => void,
    onFailure?: () => void,
  ) => void;
  loading: boolean;
} => {
  const { apiClient, loading } = useApiClient(messageApi.pushMessages);

  const checkReferenceJob = (
    data: ReferenceJobInputRequest,
    onSucseed?: (jobid: number, linkFlg: boolean) => void,
    onFailure?: () => void,
  ) => {
    (async () => {
      try {
        const response = await apiClient.post<ReferenceJobCheckResponse>(
          `/api/v1/FlexoOrderEdit/CheckReferenceJob`,
          data,
        );
        if (response.data.result?.succeed) {
          onSucseed?.(response.data.result?.jobId, response.data.result?.linkFlg);
        } else {
          onFailure?.();
        }
      } catch (e) {
        console.log(e);
        return;
      }
    })();
  };

  const checkTemplate = (
    data: TemplateInputRequest,
    onSucseed?: (linkFlg: boolean) => void,
    onFailure?: () => void,
  ) => {
    (async () => {
      try {
        const response = await apiClient.post<TemplateCheckResponse>(
          `/api/v1/FlexoOrderEdit/CheckTemplate`,
          data,
        );
        if (response.data.result?.succeed) {
          onSucseed?.(response.data.result?.linkFlg);
        } else {
          onFailure?.();
        }
      } catch (e) {
        console.log(e);
        return;
      }
    })();
  };

  return { checkReferenceJob, checkTemplate, loading };
};
