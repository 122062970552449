import { ProductionCategoryCode } from 'constants/define';
import useSharedMessage from 'hooks/useSharedMessage';
import * as React from 'react';
import { useState } from 'react';
import theme from 'themes/theme';

import { Box, Typography } from '@mui/material';
import { GridColDef, GridCellParams, useGridApiRef } from '@mui/x-data-grid-pro';

import { ApprovalContentCheckRequest } from 'api/types/approval/approvalList';

import PriceApprovalCylinderEditDialog from 'pages/common/PriceApproval/PriceApprovalCylinderEditDialog';
import PriceApprovalFlexoEditDialog from 'pages/common/PriceApproval/PriceApprovalFlexoEditDialog';
import PriceApprovalOtherEditDialog from 'pages/common/PriceApproval/PriceApprovalOtherEditDialog';

import { DataGrid, BodyLink, ColorLabel, Checkbox } from 'components/parts/atoms';
import { TableCount } from 'components/parts/molecules';

import usePriceApprovalCheck from '../hooks/usePriceApprovalCheck';
import usePriceApprovalListFetch from '../hooks/usePriceApprovalListFetch';
import { ApprovalListModel, ApprovalListResponse, Job } from '../types';

type Props = {
  setSelectedJob?: (job: Job | null) => void;
  displayData: ApprovalListResponse;
  priceApprovalList?: ApprovalListModel[];
  approvalPermissionFlg: boolean;
  listCount: number;
  loading: boolean | undefined;
  onDialogClose: () => void;
  onApproved: (jobId: number | null) => void;
  searchLoading: boolean;
  loginUserFamilyName: string;
  loginUserEmployeeId: number;
};

/** 承認一覧のテーブルコンポーネント */
const ApprovalList: React.VFC<Props> = (props) => {
  const { loading, onDialogClose } = props;
  const apiRef = useGridApiRef();
  const messageApi = useSharedMessage();
  const [openPriceApprovalEditDialog, setOpenPriceApprovalEditDialog] = useState(false);
  const [displayedJob, setDisplayedJob] = useState<Job | null>(null);
  usePriceApprovalListFetch(messageApi.pushMessages);

  const showPriceApprovalEdit = (job: Job, showStatus: string) => {
    job.showStatus = showStatus;
    setDisplayedJob(job);
    setOpenPriceApprovalEditDialog(true);
  };

  const { checkUpdate } = usePriceApprovalCheck(messageApi);

  const handleCheck = (jobId: number, checked: boolean) => {
    const postData: ApprovalContentCheckRequest = {
      jobId: jobId,
      contentCheckFlg: checked,
    };
    checkUpdate(postData);
  };

  const columns: GridColDef[] = [
    {
      field: 'contentCheckFlg',
      cellClassName: '',
      headerClassName: 'header',
      headerName: '✓',
      headerAlign: 'center',
      width: 50,
      sortable: false,
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={params.row.contentCheckFlg}
          onChange={(event) => {
            params.row.contentCheckFlg = event.target.checked;
            handleCheck(params.row.jobId, event.target.checked);
          }}
          sx={{ marginRight: 0, marginLeft: 0 }}
        />
      ),
    },
    {
      field: 'approvalFlg',
      headerName: '第一承認',
      headerAlign: 'center',
      renderHeader: () => (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>第一</Typography>
          <Typography>承認</Typography>
        </Box>
      ),
      width: 65,
      align: 'center',
      sortable: true,
      renderCell: (params) => {
        const {
          firstApprovalEmployeeId,
          firstApprovalEmployeeName,
          approvalFlg,
          secondApprovalFlg,
          chargedEmployeeId,
          tjobRegistEmployeeId,
          isApprovalCancellationAllowed,
          approvalCancelBeforeLastFlg,
        } = params.row;
        const { loginUserEmployeeId, approvalPermissionFlg } = props;

        // 担当者とログインユーザーが同一かどうか
        const isChargedUserSame = chargedEmployeeId === loginUserEmployeeId;
        // 作成者とログインユーザーが同一かどうか
        const isTjobRegistUserSame = tjobRegistEmployeeId === loginUserEmployeeId;
        // 第一承認者とログインユーザーが同一かどうか
        const isFirstApproverSame = firstApprovalEmployeeId === loginUserEmployeeId;

        // 承認フラグがtrueの場合は承認者の表示名を表示する
        const displayText =
          approvalFlg && firstApprovalEmployeeName ? firstApprovalEmployeeName : '未承認';

        let isLinkNeeded = false;
        let actionType = 'Cancel';

        if (!approvalFlg && !secondApprovalFlg) {
          isLinkNeeded = true;
          actionType = 'Approval';
        } else if (
          approvalFlg &&
          !secondApprovalFlg &&
          !isChargedUserSame &&
          !isTjobRegistUserSame
        ) {
          isLinkNeeded = true;
        } else if (approvalFlg && !secondApprovalFlg && !isFirstApproverSame) {
          isLinkNeeded = true;
        } else if (secondApprovalFlg && isApprovalCancellationAllowed) {
          isLinkNeeded = true;
        }

        if (approvalFlg && (secondApprovalFlg || isChargedUserSame || isTjobRegistUserSame)) {
          isLinkNeeded = false;
        }

        if (secondApprovalFlg || isChargedUserSame || isTjobRegistUserSame) {
          isLinkNeeded = false;
        }

        if (!approvalPermissionFlg) {
          isLinkNeeded = false;
        }

        // 第一承認がログインユーザーではない、第一承認が承認済み、第二承認が未承認、担当者がログインユーザーと同一 or 作成者がログインユーザーと同一の場合はリンクを表示
        if (approvalFlg && !isFirstApproverSame && !secondApprovalFlg && approvalPermissionFlg) {
          isLinkNeeded = true;
          actionType = 'Cancel';
        }

        if (approvalCancelBeforeLastFlg) {
          return isLinkNeeded ? (
            <BodyLink
              onClick={() => {
                showPriceApprovalEdit(params.row, actionType);
              }}
              sx={{ color: theme.palette.cyclonistRed.main }}
            >
              {displayText}
            </BodyLink>
          ) : (
            <Typography sx={{ color: theme.palette.cyclonistRed.main }}>{displayText}</Typography>
          );
        } else {
          return isLinkNeeded ? (
            <BodyLink
              onClick={() => {
                showPriceApprovalEdit(params.row, actionType);
              }}
            >
              {displayText}
            </BodyLink>
          ) : (
            <Typography>{displayText}</Typography>
          );
        }
      },
    },
    {
      field: 'secondApprovalFlg',
      headerName: '第二承認',
      renderHeader: () => (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>第二</Typography>
          <Typography>承認</Typography>
        </Box>
      ),
      headerAlign: 'center',
      width: 65,
      align: 'center',
      sortable: true,
      renderCell: (params) => {
        const {
          approvalFlg,
          secondApprovalFlg,
          chargedEmployeeId,
          tjobRegistEmployeeId,
          firstApprovalEmployeeId,
          secondApprovalEmployeeName,
          approvalCancelBeforeLastFlg,
        } = params.row;
        const { loginUserEmployeeId, approvalPermissionFlg } = props;

        // 担当者とログインユーザーが同一かどうか
        const isChargedUserSame = chargedEmployeeId === loginUserEmployeeId;
        // 作成者とログインユーザーが同一かどうか
        const isTjobRegistUserSame = tjobRegistEmployeeId === loginUserEmployeeId;
        // 第一承認者とログインユーザーが同一かどうか
        const isFirstApproverSame = firstApprovalEmployeeId === loginUserEmployeeId;

        // 承認フラグがtrueの場合は承認者の表示名を表示する
        const displayText =
          secondApprovalFlg && secondApprovalEmployeeName ? secondApprovalEmployeeName : '未承認';

        let isLinkNeeded = false;
        let actionType = 'Cancel';

        // 第一承認済み、第二承認未済、担当者がログインユーザーと同一じゃない or 作成者がログインユーザーと同一じゃない場合 リンク有 承認
        if (
          approvalFlg &&
          !secondApprovalFlg &&
          !isChargedUserSame &&
          !isTjobRegistUserSame &&
          !isFirstApproverSame &&
          approvalPermissionFlg
        ) {
          isLinkNeeded = true;
          actionType = 'Approval';
        }

        // 第一承認済み、第二承認済み の場合 リンク有 cancel
        if (approvalFlg && secondApprovalFlg) {
          isLinkNeeded = true;
        }
        if (!approvalPermissionFlg) {
          isLinkNeeded = false;
        }

        if (approvalCancelBeforeLastFlg) {
          return isLinkNeeded ? (
            <BodyLink
              onClick={() => {
                showPriceApprovalEdit(params.row, actionType);
              }}
              sx={{ color: theme.palette.cyclonistRed.main }}
            >
              {displayText}
            </BodyLink>
          ) : (
            <Typography sx={{ color: theme.palette.cyclonistRed.main }}>{displayText}</Typography>
          );
        } else {
          return isLinkNeeded ? (
            <BodyLink
              onClick={() => {
                showPriceApprovalEdit(params.row, actionType);
              }}
            >
              {displayText}
            </BodyLink>
          ) : (
            <Typography>{displayText}</Typography>
          );
        }
      },
    },
    {
      field: 'jobNo',
      headerName: 'JOB No.',
      width: 135,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <BodyLink
            onClick={() => {
              showPriceApprovalEdit(params.row, 'Show');
            }}
          >
            {params.row.jobNo}
          </BodyLink>
        );
      },
    },
    {
      field: 'sectionCd_customerCd',
      headerName: '部門 / 得意先コード',
      renderHeader: () => (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>部門</Typography>
          <Typography>得意先コード</Typography>
        </Box>
      ),
      headerAlign: 'center',
      align: 'center',
      width: 90,
      sortable: false,
      renderCell: (params: GridCellParams): JSX.Element | null => {
        return (
          <Box sx={{ textAlign: 'center' }}>
            <Typography>{params.row.customerSectionCd}</Typography>
            <Typography>{params.row.customerCd}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'customerName',
      headerName: '得意先',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <ColorLabel
            sx={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {params.row.customerName}
          </ColorLabel>
        );
      },
    },
    {
      field: 'productNameForDisplay',
      headerName: '品名',
      headerAlign: 'center',
      flex: 0.75,
      sortable: false,
      renderCell: (params) => {
        return (
          <ColorLabel
            sx={{
              whiteSpace: 'pre-wrap',
            }}
          >
            <Typography variant='body1' style={{ fontSize: '11px' }}>
              {params.row.productNameForDisplay}
            </Typography>
          </ColorLabel>
        );
      },
    },
    {
      field: 'cylMakingPlanDate',
      headerName: '製版予定日',
      headerAlign: 'center',
      renderHeader: () => (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>製版</Typography>
          <Typography>予定日</Typography>
        </Box>
      ),
      align: 'center',
      width: 80,
      minWidth: 80,
      sortable: true,
    },
    {
      field: 'shipmentPlanDate',
      headerName: '出荷予定日',
      headerAlign: 'center',
      renderHeader: () => (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>出荷</Typography>
          <Typography>予定日</Typography>
        </Box>
      ),
      align: 'center',
      width: 80,
      minWidth: 80,
      sortable: true,
    },
    {
      field: 'appropriatePlanDate',
      headerName: '計上予定日',
      headerAlign: 'center',
      renderHeader: () => (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>計上</Typography>
          <Typography>予定日</Typography>
        </Box>
      ),
      align: 'center',
      width: 80,
      minWidth: 80,
      sortable: true,
    },
    {
      field: 'chargedEmployeeName',
      headerName: '担当者',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            {params.row.chargedEmployeeName}
          </Typography>
        );
      },
    },
    {
      field: 'tjobRegistEmployeeName',
      headerName: '作成者',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            {params.row.tjobRegistEmployeeName}
          </Typography>
        );
      },
    },
    {
      field: 'jobStatus',
      headerName: '全体ステータス',
      renderHeader: () => (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>全体</Typography>
          <Typography>ステータス</Typography>
        </Box>
      ),
      headerAlign: 'center',
      align: 'center',
      flex: 0.65,
      sortable: false,
      renderCell: (params) => {
        if (typeof params.row.jobStatus === 'string' && params.row.jobStatus.length > 5) {
          return (
            <Typography
              sx={{
                textAlign: 'center',
              }}
            >
              {params.row.jobStatus}
            </Typography>
          );
        } else {
          return (
            <Typography
              sx={{
                textAlign: 'center',
              }}
            >
              {params.row.jobStatus}
            </Typography>
          );
        }
      },
    },
  ];

  return (
    <>
      <Box>
        <Typography sx={{ color: theme.palette.cyclonistRed.main }}>
          赤...承認取消を行ったデータです。
        </Typography>
      </Box>
      <TableCount count={props.priceApprovalList?.length ?? 0}></TableCount>
      <Box
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            minHeight: 40,
          },
          '& .appropriatePlanDate': {
            backgroundColor: theme.palette.white?.main,
            color: theme.palette.cyclonistLightOrange.main,
            minHeight: 40,
          },
        }}
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
            '& .MuiTypography-root': {
              height: 'auto',
            },
          }}
          rows={props.priceApprovalList ?? []}
          getRowId={(row) => row.jobId}
          columns={columns}
          rowHeight={65}
          autoHeight
          loading={loading}
          apiRef={apiRef}
          pagination={true}
          pageSize={props.listCount}
        />
        {(() => {
          switch (displayedJob?.productioncategory) {
            case ProductionCategoryCode.Cylinder:
              return (
                <PriceApprovalCylinderEditDialog
                  open={openPriceApprovalEditDialog}
                  onClose={() => {
                    setOpenPriceApprovalEditDialog(false);
                    setDisplayedJob(null);
                    onDialogClose();
                  }}
                  showStatus={displayedJob?.showStatus}
                  jobId={displayedJob?.jobId ?? null}
                  onUpdated={props.onApproved}
                />
              );
            case ProductionCategoryCode.Flexo:
              return (
                <PriceApprovalFlexoEditDialog
                  open={openPriceApprovalEditDialog}
                  onClose={() => {
                    setOpenPriceApprovalEditDialog(false);
                    setDisplayedJob(null);
                  }}
                  showStatus={displayedJob?.showStatus}
                  jobId={displayedJob?.jobId ?? null}
                  onUpdated={props.onApproved}
                />
              );
            case ProductionCategoryCode.Other:
              return (
                <PriceApprovalOtherEditDialog
                  open={openPriceApprovalEditDialog}
                  onClose={() => {
                    setOpenPriceApprovalEditDialog(false);
                    setDisplayedJob(null);
                  }}
                  showStatus={displayedJob?.showStatus}
                  jobId={displayedJob?.jobId ?? null}
                  onUpdated={props.onApproved}
                />
              );
          }
        })()}
      </Box>
    </>
  );
};

export default ApprovalList;
