import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';

import { CustomerEdit, PostData } from '../types';

/** 得意先編集のhooks */
export const useRegistCustomerEdit = (): {
  RegistCustomerEditAsync: (inputInfo: CustomerEdit, duplicateDel: boolean) => Promise<boolean>;
} => {
  // APIクライアント
  const messageApi = useSharedMessage();
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // 登録・更新
  const RegistCustomerEditAsync = async (
    inputInfo: CustomerEdit,
    duplicateDel: boolean,
  ): Promise<boolean> => {
    try {
      // ポストデータ
      const postData: PostData = {
        inputParams: inputInfo,
        duplicateDelFlag: duplicateDel,
      };
      console.log('登録・更新送信データ');
      console.log(postData);
      const [allresults] = await Promise.all([
        apiClient.post(`/api/v1/CustomerEdit/RegistCustomerEdit`, postData),
      ]);
      if (allresults.data.result) {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    return false;
  };

  return {
    RegistCustomerEditAsync,
  };
};

export default useRegistCustomerEdit;
