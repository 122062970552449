import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';

import { PostSearchResultModel } from 'api/types/common/postSearchResult';

import { DataGrid } from 'components/parts/atoms';
import { ConfirmButton } from 'components/parts/atoms';
import { Dialog, FooterButtonsContainer } from 'components/parts/molecules';
import { TableCount } from 'components/parts/molecules';

import useFetchPostSearchResult from './hooks/useFetchPostSearchResult';

type Props = {
  zipcd: string;
  open: boolean;
  onClose: (value?: PostSearchResultModel) => void;
};

const PostSearchResultTable: React.VFC<Props> = (props) => {
  const { postSearchResults, messageApi, fetch, clear, listCount } = useFetchPostSearchResult(
    props.zipcd,
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const reset = () => {
    setSelectionModel([]);
  };

  useEffect(() => {
    if (props.open) {
      fetch();
    } else {
      messageApi.clear();
      clear();
    }
  }, [props.open]);

  const columns: GridColDef[] = [
    { field: 'zipCd', headerName: '郵便番号', flex: 1 },
    { field: 'prefecturesName', headerName: '都道府県', flex: 1 },
    { field: 'citykanjiName', headerName: '市町村', flex: 1 },
    { field: 'chouikikanjiName', headerName: '町名', flex: 1 },
  ];

  const handleClose = () => {
    props.onClose(undefined);
    reset();
  };

  const handleConfirm = () => {
    let result = undefined;
    console.log(selectionModel.length);
    if (selectionModel.length > 0) {
      result = postSearchResults.find((e) => e.zipCodeId === selectionModel[0]);
    }
    props.onClose(result);
    reset();
    console.log(result);
  };

  return (
    <Dialog
      open={props.open}
      title={`郵便番号検索結果一覧`}
      onClose={handleClose}
      messageApi={messageApi}
    >
      {/* 一覧件数表示*/}
      <TableCount count={postSearchResults.length} />
      <Box m={2} />
      <DataGrid
        rows={postSearchResults ?? []}
        getRowId={(row) => row.zipCodeId}
        columns={columns}
        pageSize={listCount}
        pagination={true}
        autoHeight
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
      <Box m={2} />

      <FooterButtonsContainer>
        <ConfirmButton onClick={handleConfirm} />
      </FooterButtonsContainer>
    </Dialog>
  );
};

export default PostSearchResultTable;
