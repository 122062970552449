import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useEffect, useState } from 'react';

import { ProductionEditResponse } from 'api/types/productionManage/productionEdit';

import { defaultProductionEditModel } from '../defaults';
import { ProductionEditModel, ProductionEditCodition, InputCheck } from '../types';

/** Hooks */
export const useFetchProductionEdit = (
  inputInfo: ProductionEditCodition,
  messageApi: MessageApi,
): {
  loading: boolean;
  production: ProductionEditModel;
  isInputCheckAsync: (condition: InputCheck) => Promise<boolean>;
  revalidate: () => void;
} => {
  const { apiClient, loading } = useApiClient(messageApi.pushMessages);

  // State
  const [production, setProduction] = useState<ProductionEditModel>(defaultProductionEditModel);

  const fetchProduction = () => {
    (async () => {
      try {
        const [allresults] = await Promise.all([
          apiClient.get<ProductionEditResponse>(`/api/v1/ProductionEdit/GetInitialProductionEdit`, {
            params: inputInfo,
          }),
        ]);
        if (allresults.data.result) {
          setProduction(allresults.data.result.productionContent);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  // プロダクション取得(初期表示)
  useEffect(() => {
    fetchProduction();
  }, []);

  // 入力値チェック
  const isInputCheckAsync = async (inputInfo: InputCheck): Promise<boolean> => {
    try {
      const [allresults] = await Promise.all([
        apiClient.get<ProductionEditResponse>(`/api/v1/ProductionEdit/IsProductionEditCheck`, {
          params: inputInfo,
        }),
      ]);
      if (allresults.data.result) {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    return false;
  };

  // プロダクションの再取得
  const revalidate = () => {
    fetchProduction();
  };

  return {
    loading,
    production,
    isInputCheckAsync,
    revalidate,
  };
};

export default useFetchProductionEdit;
