import { ProductionCategoryCode } from 'constants/define';
import useMessage from 'hooks/useMessage';
import * as React from 'react';
import theme from 'themes/theme';

import { Box, Container } from '@mui/material';

import JobTable from './components/JobTable';
import MessageListener from 'components/Message/MessageListener';
import { ColorLabel, Heading } from 'components/parts/atoms';
import { NewWindow } from 'components/parts/atoms/NewWindow';

import useFetchJobNoSearchResult from './hooks/useFetchJobNoSearchResult';

type Props = {
  jobNo: string;
  onClose: () => void;
};

const JobNoSearchResult: React.VFC<Props> = (props) => {
  const messageApi = useMessage();
  const { loading, searchSucceed, cylinderJobs, flexoJobs, otherJobs } = useFetchJobNoSearchResult(
    props.jobNo,
    messageApi.pushMessages,
  );

  return (
    <NewWindow features={{ height: 720, width: 1280 }} onUnload={props.onClose}>
      <Container maxWidth='lg'>
        <Box m={2} />
        <MessageListener messageApi={messageApi} />

        <Box m={2} />
        {searchSucceed && (
          <>
            <ColorLabel color={theme.palette.cyclonistRed.main}>
              検索結果は、シリンダー：{cylinderJobs.length}件、フレキソ：
              {flexoJobs.length}件、その他{otherJobs.length}件です
            </ColorLabel>
          </>
        )}

        <Box m={2} />
        <Heading>JOB No.検索結果一覧</Heading>
        <Box m={2} />

        <JobTable
          category={ProductionCategoryCode.Cylinder}
          title={'シリンダー'}
          jobs={cylinderJobs}
          loading={loading}
        />
        <Box m={10} />
        <JobTable
          category={ProductionCategoryCode.Flexo}
          title={'フレキソ'}
          jobs={flexoJobs}
          loading={loading}
        />
        <Box m={10} />
        <JobTable
          category={ProductionCategoryCode.Other}
          title={'その他'}
          jobs={otherJobs}
          loading={loading}
        />
      </Container>
    </NewWindow>
  );
};

export default JobNoSearchResult;
