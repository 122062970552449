import * as React from 'react';

import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type LayoutBodyGridProps = {
  xs?: GridProps['xs'];
  sx?: GridProps['sx'];
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  outline: '1px solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // paddingTop: theme.spacing(1),
  // paddingBottom: theme.spacing(1),
  //paddingLeft: theme.spacing(1),
  //paddingRight: theme.spacing(1),
  overflow: 'visible',            
  outlineColor: theme.palette.makingSpecificationGray.main,
  backgroundColor: theme.palette.cyclonistBodyGridColor.main,
}));

const LayoutBodyGrid: React.FC<LayoutBodyGridProps> = ({ children, ...props }) => {
  return (
    <>
      <StyledGrid item {...props}>
        {children}
      </StyledGrid>
    </>
  );
};

export default LayoutBodyGrid;
