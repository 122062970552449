import React from 'react';

import { useUnitPriceHanberiMakingRow } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/hooks/price/priceRow/useUnitPriceHanberiMakingRow';

import {
  MakingPriceRowTarget,
  useMakingPriceRowProperties,
} from '../../../hooks/price/priceRow/useMakingPriceRowProperties';
import MakingPriceRow, { MakingPriceRowProps } from '../Common/MakingPriceRow';

type Props = {
  label: MakingPriceRowProps['label'];
  type: Extract<MakingPriceRowTarget, { category: 'Hanberi' }>['type'];
};

/** 版べり再版 単価行コンポーネント */
const HanberiPriceRow: React.VFC<Props> = ({ label, type }) => {
  const { plateMakingCodes, summaryCodes, unitPriceKeys } = useMakingPriceRowProperties({
    category: 'Hanberi',
    type,
  });
  const { price } = useUnitPriceHanberiMakingRow();

  return (
    <MakingPriceRow
      label={label}
      plateMakingCodes={plateMakingCodes}
      summaryCodes={summaryCodes}
      unitPricePropertyNames={unitPriceKeys}
      cylMakingPrice={price}
    />
  );
};

export default React.memo(HanberiPriceRow);
