import React, { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import theme from 'themes/theme';

import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';

import { ProductionEditModel } from 'pages/productionManage/Production/types';
import ProductionDialog from 'pages/productionManage/ProductionDialog';
import { FlexoOrderEditForm } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { HeadLabel, BodyLabel, AttentionLabel } from 'components/parts/atoms';

import { useFlexoOrderEditMaster } from '../../hooks/useFlexoOrderEditMaster';
import { usePageContext } from '../../hooks/usePageContext';
import { EditIconButton, EditKanaField, EditSelect, ShowOnEdit } from '../Fields';
import Delivery from './Delivery';
import { RowOne, RowTwo } from './Rows';

/** フレキソ受注 基本情報コンポーネント */
const Basis: React.VFC = () => {
  // プロダクション編集ダイアログ
  const [openProductionEdit, setOpenProductionEdit] = useState(false);

  // Context
  const { control, getValues, setValue } = useFormContext<FlexoOrderEditForm>();
  const { master } = useFlexoOrderEditMaster();
  const { orderMetaData } = usePageContext();

  // ウォッチ
  const [productionId] = useWatch({
    control,
    name: ['basis.productionId'],
  });
  const hasProduction = productionId !== null;

  const onProductionEditClick = () => {
    setOpenProductionEdit(true);
  };

  const onProductionDialogClose = () => {
    setOpenProductionEdit(false);
  };

  const onProductionSaved = (production: ProductionEditModel) => {
    // 更新後のプロダクション名を画面に反映
    setValue('basis.productionName', production.productionName);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <>
          <RowTwo>
            <HeadLabel>JOB No.</HeadLabel>
            <BodyLabel>{getValues('basis.jobNo')}</BodyLabel>
            <Box sx={{ display: 'flex' }}>
              <ShowOnEdit>
                <AttentionLabel>【必須】</AttentionLabel>
              </ShowOnEdit>
              <HeadLabel>担当者（当社）</HeadLabel>
            </Box>
            <Controller
              name='basis.chargedEmployeeId'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditSelect
                  options={master.chargedEmployeeList}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '12em' }}
                />
              )}
            />
          </RowTwo>
          <RowTwo>
            <HeadLabel>部門／得意先コード</HeadLabel>
            <BodyLabel>
              {getValues('basis.customerSectionCd')}-{getValues('basis.customerCd')}
            </BodyLabel>
            <HeadLabel>会社コード</HeadLabel>
            <Controller
              name='basis.companyCd'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  onChange={onChange}
                  value={value}
                  name={name}
                  maxLength={1}
                  sx={{ width: '3em' }}
                />
              )}
            />
          </RowTwo>
          <RowTwo>
            <HeadLabel>得意先名</HeadLabel>
            <BodyLabel>{getValues('basis.customerName')}</BodyLabel>
            <HeadLabel>担当者（お客様）</HeadLabel>
            <Controller
              name='basis.customerChargeId'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditSelect
                  addEmptyRow={true}
                  options={master.customerChargeList}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '12em' }}
                />
              )}
            />
          </RowTwo>
          <RowTwo>
            <HeadLabel>得意先発注No.</HeadLabel>
            <Controller
              name='basis.customerOrderNo'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  onChange={onChange}
                  value={value}
                  name={name}
                  maxLength={40}
                  sx={{ width: '29em' }}
                />
              )}
            />
            <HeadLabel>MY JOB No.</HeadLabel>
            <Controller
              name='basis.myJobNo'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  onChange={onChange}
                  value={value}
                  name={name}
                  maxLength={14}
                  sx={{ width: '12em' }}
                />
              )}
            />
          </RowTwo>
          <RowOne>
            <HeadLabel>旧JOB No.</HeadLabel>
            <BodyLabel>{getValues('basis.oldJobNo')}</BodyLabel>
          </RowOne>
          <RowOne>
            <Box sx={{ display: 'flex' }}>
              <ShowOnEdit>
                <AttentionLabel>【必須】</AttentionLabel>
              </ShowOnEdit>
              <HeadLabel sx={{ color: theme.palette.CyclonistEigyoItem.main, fontWeight: 'bold' }}>
                品目
              </HeadLabel>
            </Box>
            <Controller
              name='basis.eigyoItemCdId'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditSelect
                  options={master.eigyoItemList}
                  addEmptyRow
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '16em' }}
                />
              )}
            />
          </RowOne>
          <RowOne>
            <Box sx={{ display: 'flex' }}>
              <Box>
                <HeadLabel sx={{ textAlign: 'right' }}>品名</HeadLabel>
                <HeadLabel sx={{ textAlign: 'right' }}>(PRODUCTION／JOB)</HeadLabel>
              </Box>
              {hasProduction && (
                <EditIconButton onClick={onProductionEditClick}>
                  <EditIcon />
                </EditIconButton>
              )}
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Controller
                name='basis.productionName'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditKanaField
                    onChange={onChange}
                    value={value}
                    name={name}
                    maxLength={40}
                    sx={{ width: '25em' }}
                    disabled={orderMetaData?.isRepeatOrder || orderMetaData?.isSaved}
                  />
                )}
              />
              <Box mx={1} />

              <Controller
                name='basis.jobName'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditKanaField
                    onChange={onChange}
                    value={value}
                    name={name}
                    maxLength={40}
                    sx={{ width: '25em' }}
                  />
                )}
              />
            </Box>
          </RowOne>
          <RowOne>
            <Box sx={{ display: 'flex' }}>
              <ShowOnEdit>
                <AttentionLabel>【必須】</AttentionLabel>
              </ShowOnEdit>
              <HeadLabel>品名半角カナ</HeadLabel>
            </Box>
            <Controller
              name='basis.jobKanaName'
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  maxLength={40}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '25em' }}
                />
              )}
            />
          </RowOne>
          <RowOne>
            <HeadLabel>請求先コード</HeadLabel>
            <BodyLabel>
              {getValues('basis.demandCustomerSectionCd')}-{getValues('basis.demandCustomerCd')}
            </BodyLabel>
          </RowOne>
          <RowOne>
            <HeadLabel>{'請求先名'}</HeadLabel>
            <BodyLabel>{getValues('basis.demandCustomerName')}</BodyLabel>
          </RowOne>
        </>

        <Delivery />
      </Box>

      {hasProduction && (
        <ProductionDialog
          productionId={productionId}
          open={openProductionEdit}
          onClose={onProductionDialogClose}
          onSaved={onProductionSaved}
        />
      )}
    </>
  );
};

export default React.memo(Basis);
