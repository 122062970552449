/** セッションストレージキー */
export const LocalStorageKey = { KeyName: 'cyclonist2' } as const;

/** React Route Domの設定State */
export const HistoryStatus = { InitialDisplay: '0' } as const;

/** 赤黒フラグ */
export const DeficitSurplusFlg = {
  Surplus: 0,
  Deficit: 1,
  DeficitSurplus: -1,
  HasDeficit: 2,
} as const;
export type DeficitSurplusFlg = typeof DeficitSurplusFlg[keyof typeof DeficitSurplusFlg];

/** 受注赤黒タイプ */
export const AkaKuroType = {
  KURO: 'Kuro',
  AKA: 'Aka',
  AKA_KURO: 'AkaKuro',
} as const;
export type AkaKuroType = typeof AkaKuroType[keyof typeof AkaKuroType];

/** シリンダー、フレキソ、その他のステータスコード */
export const ProductionCategoryCode = {
  Cylinder: 'C',
  Flexo: 'F',
  Other: 'O',
} as const;
export type ProductionCategoryCode =
  typeof ProductionCategoryCode[keyof typeof ProductionCategoryCode];

/** 全体ステータスコード */
export const TotalStatusCode = {
  /** 仮見積コード */
  TsTemporaryEstimateCode: 'TE',
  /** 仮受注コード */
  TsTemporaryOrderCode: 'TO',
  /** 見積りコード */
  TsEstimateCode: 'ES',
  /** 保留コード */
  TsReserveCode: 'RS',
} as const;
export type TotalStatusCode = typeof TotalStatusCode[keyof typeof TotalStatusCode];

/** 新規受注・リピートオーダーのステータス */
export const OrderStatus = {
  NewOrder: 0,
  RepeatOrder: 1,
};
export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];

/** 新規受注・リピートオーダーの表示文字 */
export const OrderStatusName = {
  NewOrder: '新規受注',
  RepeatOrder: 'リピートオーダー',
  EstimateNewOrder: '見積り 新規受注',
  EstimateRepeatOrder: '見積り リピートオーダー',
} as const;
export type OrderStatusName = typeof OrderStatusName[keyof typeof OrderStatusName];

/** ワーク連絡票の選択ラジオボタンのステータス */
export const WorkDirectionKind = {
  // 再見積もり
  reEstimate: '1',
  // 受注
  order: '2',
  // 受注不可
  orderNotAllowed: '3',
  // 予定変更(ノビ・トビ入り)
  editSchedule: '4',
  // 指示変更
  editDirection: '5',
  // 指示変更(落版あり)
  rakuhan: '6',
  // 価格変更
  editPrice: '7',
  // 再校
  saikou: '8',
  // 念校
  nenkou: '9',
  // 責了
  sekiryou: '10',
  // 校了
  kouryou: '11',
  // 提出
  present: '12',
  // 画像NG
  imageNg: '13',
  // 保留
  pending: '14',
  // STOP
  stop: '15',
  // 納品
  delivery: '16',
  // 完了
  complete: '17',
  // 前回指示取消
  cancelDirection: '18',
};
export type WorkDirectionKind = typeof WorkDirectionKind[keyof typeof WorkDirectionKind];

/** WORKの指示内容名称マップ */
export const workDirectionKindNameMap: {
  [key: WorkDirectionKind]: string;
} = {
  [WorkDirectionKind.reEstimate]: '再見積もり',
  [WorkDirectionKind.order]: '受注',
  [WorkDirectionKind.orderNotAllowed]: '受注不可',
  [WorkDirectionKind.editSchedule]: '予定変更(ノビ・トビ入り)',
  [WorkDirectionKind.editDirection]: '指示変更',
  [WorkDirectionKind.rakuhan]: '指示変更(落版あり)',
  [WorkDirectionKind.editPrice]: '価格変更',
  [WorkDirectionKind.saikou]: '再校',
  [WorkDirectionKind.nenkou]: '念校',
  [WorkDirectionKind.sekiryou]: '責了',
  [WorkDirectionKind.kouryou]: '校了',
  [WorkDirectionKind.present]: '提出',
  [WorkDirectionKind.imageNg]: '画像NG',
  [WorkDirectionKind.pending]: '保留',
  [WorkDirectionKind.stop]: 'STOP',
  [WorkDirectionKind.delivery]: '納品',
  [WorkDirectionKind.complete]: '完了',
  [WorkDirectionKind.cancelDirection]: '前回指示取消',
};

/** 作業終了状態 */
export const WorkEndStatusKind = {
  save: { value: '1', text: '仮保存する' },
  complete: { value: '2', text: '作業完了にする' },
  cancel: { value: '3', text: '作業取消にする' },
  none: { value: '4', text: '画像データ作成はありません（作業取消）' },
};
export type WorkEndStatusKind = typeof WorkEndStatusKind[keyof typeof WorkEndStatusKind];

/** 印刷面表・裏のコード */
export const FaceDiv = {
  Main: 0,
  Reverse: 1,
} as const;
export type FaceDiv = typeof FaceDiv[keyof typeof FaceDiv];

/** 受注 計算方法コード */
export const CalculateMethodCd = {
  Unit: '1',
  Set: '2',
  FixedUnit: '3',
} as const;
export type CalculateMethodCd = typeof CalculateMethodCd[keyof typeof CalculateMethodCd];

/** 受注 計算方法名 */
export const PaymentMethodName = {
  UnitPriceName: '単価計算(手入力)',
  SetPriceName: '一式計算',
  FixedUnitPriceName: '単価計算',
} as const;
export type PaymentMethodName = typeof PaymentMethodName[keyof typeof PaymentMethodName];

/** 製版摘要コード */
export const SummaryCode = {
  /** 支給 */
  Supply: '1',
  /** 新管 */
  New: '2',
  /** 旧管 */
  Old: '3',
  /** 銅上 */
  Copper: '4',
} as const;
export type SummaryCode = typeof SummaryCode[keyof typeof SummaryCode];

/** 製版情報 シリンダー手配 */
export const OrderCode = {
  Hikitori: '10',
  Hattyu: '11',
  Zaiko: '12',
  Souhu: '13',
} as const;
export type OrderCode = typeof OrderCode[keyof typeof OrderCode];

/** 製版区分コード */
export const PlateMakingCode = {
  FullDiv: 'FC',
  KaihanDiv: 'KH',
  ZouhanDiv: 'ZH',
  YobihanDiv: 'YH',
  ReChromeDiv: 'RC',
  HanberiDiv: 'HS',
  AwaseDiv: 'AH',
  ChousaDiv: 'CH',
} as const;
export type PlateMakingCode = typeof PlateMakingCode[keyof typeof PlateMakingCode];

/** 母材計算用の基準値 */
export const MaterialUnitPriceThreshold = {
  WidthSize1: 600,
  WidthSize2: 680,
  WidthSize3: 760,
} as const;
export type MaterialUnitPriceThreshold =
  typeof MaterialUnitPriceThreshold[keyof typeof MaterialUnitPriceThreshold];

/** 円周割増率計算用の基準値 */
export const CircleRateThreshold = {
  WidthSize1: 500,
  WidthSize2: 600,
  WidthSize3: 700,
  WidthSize4: 800,
  WidthSize5: 900,
  WidthSize6: 1000,
  WidthSize7: 1100,
  WidthSize8: 1200,
  WidthSize9: 1300,
  WidthSize10: 1400,
};
export type CircleRateThreshold = typeof CircleRateThreshold[keyof typeof CircleRateThreshold];

/** リージョン
 * (１：通常、２：北海道、３：九州) */
export const Region = {
  Normal: 1,
  Hokkaido: 2,
  Kyushu: 3,
} as const;
export type Region = typeof Region[keyof typeof Region];

/** 所属
 * (１：通常、２：KSM、３：SNW、４：STNK */
export const Affiliation = {
  Normal: 1,
  Ksm: 2,
  Snw: 3,
  Stnk: 4,
} as const;
export type Affiliation = typeof Affiliation[keyof typeof Affiliation];

/** 刷色NOと刷色の対応表 */
export const printColorMap: {
  [key: number]: string;
} = {
  0: '',
  1: '墨',
  2: '藍',
  3: '赤',
  4: '紅',
  5: '黄',
  6: '白',
  7: '特草',
  8: '草',
  10: '淡草',
  11: '濃草',
  13: '特藍',
  14: '薄藍',
  15: '淡藍',
  16: '濃藍',
  17: '特紺',
  18: '紺',
  19: '紺藍',
  20: '浅葱',
  21: '特紫',
  22: '紫',
  23: '薄紫',
  24: '淡紫',
  25: '濃紫',
  26: '緑',
  27: '薄緑',
  28: '淡緑',
  29: '濃緑',
  30: '特朱',
  31: '朱',
  32: '特橙',
  33: '橙',
  34: '特赤',
  35: '金赤',
  36: '牡丹',
  37: '特黄',
  38: '透明黄',
  39: '黄土',
  40: '茶',
  41: '特茶',
  42: '薄茶',
  43: '淡茶',
  44: '濃茶',
  45: '特色',
  50: '金',
  51: '銀色',
  52: '青金',
  53: '赤金',
  54: '透明金',
  55: 'ＡＬ地',
  61: '青',
  70: 'ﾋﾟﾝｸ',
  71: 'ｳｽﾋﾟﾝｸ',
  72: 'ｱﾜﾋﾟﾝｸ',
  73: 'ｺｲﾋﾟﾝｸ',
  74: 'ｵﾚﾝｼﾞ',
  75: 'ｸﾘｰﾑ',
  76: 'ﾍﾞｰｼﾞｭ',
  77: 'ｸﾞﾚｰ',
  78: 'ｺｲｸﾞﾚｰ',
  79: 'ｳｽｸﾞﾚｰ',
  80: 'ｱﾜｸﾞﾚｰ',
  81: 'ｼﾙﾊﾞｰ',
  82: 'ｺﾞｰﾙﾄﾞ',
  83: 'ｸﾞﾘｰﾝ',
  84: 'ﾌﾞﾙｰ',
  85: 'ｴﾝｼﾞ',
  86: 'ﾄｸｴﾝｼﾞ',
  87: '',
  88: 'ﾆｽ',
  89: 'ﾌﾟﾗｲﾏｰ',
  90: 'ＯＰ',
  91: 'ＡＣ',
  92: 'ＰＣ',
  93: 'ＯＣ',
  94: 'ＵＶ',
  95: 'ＶＣ',
  96: '',
};

/** ミラー画像パス */
export const directionOfMirrorImagePath = {
  mirror: '/images/R_0.png',
  mirror1: '/images/R_1.png',
  mirror2: '/images/R_2.png',
  mirror5: '/images/R_5.png',
};

/** デザイン */
/** シリンダー入荷入力タブのタブインデックス
 * (１：基本情報、２：外注/在庫、３：刻印・木箱、４：計測値、５：TK、６：TP/備考) */
export const ArrivalEditTabIndex = {
  Basic: '1',
  OutsourcingStock: '2',
  Engraved: '3',
  Measure: '4',
  Tk: '5',
  Tp: '6',
};
export type ArrivalEditTabIndex = typeof ArrivalEditTabIndex[keyof typeof ArrivalEditTabIndex];

/** 得意先入力タブのタブインデックス */
/** (1:基本情報、2:入金情報、3:単価登録、4:納入先)  */
export const CustomerEditTabIndex = {
  Basic: '1',
  Payment: '2',
  UnitPrice: '3',
  Delivery: '4',
};
export type CustomerEditTabIndex = typeof CustomerEditTabIndex[keyof typeof CustomerEditTabIndex];

/** 画像作業工程コード
 * (１：デザイン、２：分解、３：色分け、４：提出物、６：検版、８：下版) */
export const ImageWorkProcessTypes = {
  /** デザイン */
  design: 1,
  /** 分解 */
  photoRetouch: 2,
  /** 色分け */
  colorSeparation: 3,
  /** 提出物（色校カーブあり） */
  presentItemWithProof: 4,
  /** 提出物（色校カーブなし） */
  presentItemNoProof: 5,
  /** 検版 */
  inspection: 6,
  /** 提出 */
  present: 7,
  /** 下版 */
  layout: 8,
  /** 提出物 */
  PresentItem: 9,
  /** 校了 */
  Approved: 10,
} as const;
export type ImageWorkProcessTypes =
  typeof ImageWorkProcessTypes[keyof typeof ImageWorkProcessTypes];

/** 画像提出物ステータス */
export const PresentSyobunrui = {
  aoyakiPresent: 1,
  ohukoPresent: 2,
  irokoPresent: 3,
  fairuhenkanPresent: 4,
  pozisyuturyokuPresent: 5,
} as const;
export type PresentSyobunrui = typeof PresentSyobunrui[keyof typeof PresentSyobunrui];

/** データタイプNo*/
export const DataTypeNo = {
  original: '00',
  design: '01',
  photoRetouch: '02',
  colorSeparation: '03',
  layout: '04',
  final: '05',
  send: '06',
  present: '10',
  inspection: '20',
  template: '31',
} as const;
export type DataTypeNo = typeof DataTypeNo[keyof typeof DataTypeNo];

/** シリンダーWORKコード */
export const CylindersWorkCode = {
  /** 入荷 */
  Arrival: 1,
  /** 仕上 */
  Shiage: 2,
  /** PM */
  Pm: 3,
  /** 彫刻製版 */
  Sculpture: 4,
  /** レーザー製版 */
  Laser: 5,
  /** CW1 */
  Cw1: 6,
  /** CW2 */
  Cw2: 7,
  /** 出荷 */
  Shipment: 8,
  /** 彫刻デザイン */
  SculptureDesign: 9,
  /** レーザーデータ */
  LaserData: 10,
  /** 製版用画像データ作成 */
  GazoInfo: 11,
  /** 営業 */
  Eigyou: 12,
  /** 在庫管理 */
  StockControl: 13,
  /** 校正 */
  Kousei: 14,
  /** 検版 */
  Kenpan: 15,
};
export type CylindersWorkCode = typeof CylindersWorkCode[keyof typeof CylindersWorkCode];

/** 画像作業詳細の画面表示ステータス */
export const ImageListDisplayType = {
  /** サムネイル表示のステータス */
  Thumbnail: 0,
  /** リスト表示のステータス */
  List: 1,
} as const;
export type ImageListDisplayType = typeof ImageListDisplayType[keyof typeof ImageListDisplayType];

/** 入稿ステータス */
export const ImageJobType = {
  /** 作業なし */
  noWork: '00',
  /** 作業あり */
  work: '10',
  /** 作業取得 */
  getWork: '11',
  /** 作業中 */
  onWork: '12',
  /** STOP */
  stop: '98',
  /** 作業完了 */
  endWork: '99',
} as const;
export type ImageJobType = typeof ImageJobType[keyof typeof ImageJobType];

/** 入稿ステータス */
export const ImageShceduleStatus = {
  /** 保留 */
  onHold: '保留',
  /** STOP */
  stop: 'STOP',
} as const;

export type ImageShceduleStatus = typeof ImageShceduleStatus[keyof typeof ImageShceduleStatus];
export const ScheduleStatus = {
  /** Stop */
  stop: 'STOP',
  /** 保留 */
  onhold: '保留',
} as const;
export type ScheduleStatus = typeof ImageJobType[keyof typeof ImageJobType];

/** ICタグチェック モード */
export const IcTagCheckMode = {
  engraving: 1,
} as const;
export type IcTagCheckMode = typeof IcTagCheckMode[keyof typeof IcTagCheckMode];

/** 旧販売管理 依頼先 */
interface IraisakiName {
  [key: number]: string;
}
export const iraisakiName: IraisakiName = {};
iraisakiName[1] = '吉永デザイン';
iraisakiName[2] = '深見デザイン';
iraisakiName[3] = 'その他';

/** CylinderWorks 選択作業 */
export const TargetWork = {
  USUAL: '1',
  SPECIFIC: '2',
} as const;
export type TargetWork = typeof TargetWork[keyof typeof TargetWork];

/** 作業工程コード */
export const WorkingProcessCode = {
  // 入荷
  arrival: '1',
  // 剥離
  hakuri: '2',
  // 落版
  rakuhan: '3',
  // 銅メッキ（ＣＵ）
  cu: '4',
  // 仕上
  shiage: '6',
  // バフ
  bafu: '7',
  // ピンホール検査（ピ検）
  pinhole: '8',
  // レーザーデザイン（ＬＤ）
  ld: '9',
  // レーザー製版（Ｌ製）
  laser: '10',
  // 彫刻デザイン（ＳＤ）
  sd: '11',
  // 彫刻製版（Ｓ製）
  sculpture: '12',
  // クロームメッキ（ＣＲ）
  cr: '13',
  // 校正
  proofreading: '14',
  // 検表
  kenhyou: '15',
  // 検版
  inspection: '16',
  // 出荷
  shipment: '17',
  // 調査
  investigation: '18',
  // pM
  pm: '19',
  // 営業
  eigyou: '51',
  // 画像_デザイン
  imageDesign: '61',
  // 画像_分解
  imagePr: '62',
  // 画像_色分け
  imageCs: '63',
  // 画像_検版
  imageInspection: '64',
  // 画像_下版
  imageLayout: '65',
  // 業務企画
  gyoumuKikaku: '71',
  // 品質管理
  qc: '72',
  // 在庫管理
  stockControl: '73',
};
export type WorkingProcessCode = typeof WorkingProcessCode[keyof typeof WorkingProcessCode];

/** 原稿袋 JOB No.／棚番号検索表示条件 */
export const ManuscriptDisplayUnit = {
  jobUnit: '0',
  rackUnit: '1',
};
export type ManuscriptDisplayUnit =
  typeof ManuscriptDisplayUnit[keyof typeof ManuscriptDisplayUnit];

/** シリンダー用途 */
export const CylinderUse = {
  rakuhan: '1',
  zaiko: '2',
  henkyaku: '3',
  haiki: '4',
  kanryo: '5',
  horyu: '6',
  torikeshi: '7',
} as const;
export type CylinderUse = typeof CylinderUse[keyof typeof CylinderUse];

/** シリンダーステータス */
export const CylinderStatus = {
  // 入荷
  arrived: '10',
  // 落版
  back: '12',
  // 再手配
  reorder: '30',
  // 返却
  return: '40',
  // 廃棄
  destroy: '50',
  // 別のJOB(BJ)
  bj: '60',
  // 出荷なし
  noshipment: '70',
  // 在庫
  stock: '80',
  // 別のシリンダー（BC）
  bc: '81',
  // STOP
  stop: '82',
  // 在庫（仮納品書出力指示）
  absstock: '83',
  // 刷色削除
  abort: '84',
  // 損版在庫
  backstock: '85',
  // 保留
  reservation: '86',
  // JOB削除
  jobDelete: '87',
  // 再手配保留
  reorderReserve: '88',
  // 在庫（完了）
  cwStock: '89',
  // 出荷
  shipped: '90',
} as const;
export type CylinderStatus = typeof CylinderStatus[keyof typeof CylinderStatus];

/** シリンダー出荷 ラジオボタン */
export const CylinderShipmentStatusRadioFlg = {
  stock: '1',
  shipped: '2',
  unShipped: '3',
};
export type CylinderShipmentStatusRadioFlg =
  typeof CylinderShipmentStatusRadioFlg[keyof typeof CylinderShipmentStatusRadioFlg];

/** 計上済／未計上 */
export const UnappropriateKbn = {
  appropriated: '1',
  unappropriated: '2',
} as const;
export type UnappropriateKbn = typeof UnappropriateKbn[keyof typeof UnappropriateKbn];

/** メッセージ項目入力 表示区分 */
export const DisplayCodeMessageTitle = {
  // ポータル
  PortalCd: 0,
  // アプリケーショントップ
  ApplicateonCd: 1,
} as const;
export type DisplayCodeMessageTitle =
  typeof DisplayCodeMessageTitle[keyof typeof DisplayCodeMessageTitle];

/** 検索日付（CylinderWorks） */
export const SelectedDateKbnForCW = {
  arrival: '1',
  shippped: '2',
  makingPlan: '3',
} as const;
export type SelectedDateKbnForCW = typeof SelectedDateKbnForCW[keyof typeof SelectedDateKbnForCW];

/** 得意先部門コードデフォルト */
export const CustomerSectionCode = {
  Direct: 'ZB70',
  Tonai: '0002',
} as const;
export type CustomerSectionCode = typeof CustomerSectionCode[keyof typeof CustomerSectionCode];

/** ソート条件（CylinderSearcher） */
export const SortItemForCS = {
  customer: '1',
  cylsizewidth: '2',
  cylsizeensyuu: '3',
  area: '4',
} as const;
export type SortItemForCS = typeof SortItemForCS[keyof typeof SortItemForCS];

/** 指示条件（CylinderSearcher） */
export const DirectionForCW = {
  return: '1',
  destroy: '2',
  delete: '3',
} as const;
export type DirectionForCW = typeof DirectionForCW[keyof typeof DirectionForCW];

/** JOBステータス */
export const JobStatus = {
  // 見積／仮
  temporaryEstimate: '01',
  // 見積
  estimate: '02',
  // 受注不可
  disableOrder: '03',
  // 受注／仮
  temporaryOrder: '04',
  // 受注
  order: '05',
  // 提出
  present: '06',
  // 校了
  kouryou: '07',
  // stop
  stop: '08',
  // 画stop
  imageStop: '09',
  // シstop
  cylStop: '10',
  // シ一部stop
  cylPartStop: '11',
  // 完了
  end: '12',
  // 再開
  restart: '13',
  // 作業予定済み(画像・製版)
  plan: '14',
  // 作業中
  running: '15',
  // 出荷済み
  delivered: '16',
  // 計上済み
  appropriated: '17',
  // 保留
  reserve: '18',
  // トビ入
  tobiiri: '19',
  // ノビ
  nobi: '20',
  // 再手配
  reorder: '21',
} as const;
export type JobStatus = typeof JobStatus[keyof typeof JobStatus];

/** 休日区分 */
export const HolydayDiv = {
  Holyday: '1',
  ConsideredWeekday: '2',
} as const;
export type HolydayDiv = typeof HolydayDiv[keyof typeof HolydayDiv];

/** 表示先区分 */
export const DisplayCode = {
  Portal: 0,
  AppTop: 1,
} as const;
export type DisplayCode = typeof DisplayCode[keyof typeof DisplayCode];

/** DataGridの行の高さ */
export const DataGridRowHeight = {
  Default: 36,
  OneLine: 18,
} as const;

/** 営業品目区分コード */
export const EigyoItemCode = {
  PlateLiquid: '02',
  Lyocell: '03',
  Usheet: '14',
} as const;

/** その他受注入力 価格情報の初期表示行項目名 */
export const OtherProductItemName = {
  DegreasingLiquid: '脱脂液',
  ActivationLiquid: '活性液',
  NickelLiquid: 'プリニッケル液',
  CRLiquid: '硼弗化銅液',
  Tansobo: '炭素棒',
  PartialPlatingEquipUnit: '部分メッキ装置単体',
  PartialPlatingEquipSet: '部分メッキ装置一式',
  TansoboHolder: '炭素棒ホルダー',
  LeadRed: 'リード線(赤)',
  LeadBlack: 'リード線(黒)',
  PlasticTape: '電気絶縁用テープ',
  SandpaperAttachRubber: '紙ヤスリ用工具',
  Sandpaper: '耐水ペーパー800#',
  Lyocell10mmTape: '10mmテープ',
  Lyocell15mmTape: '15mmテープ',
  Lyocell30mmTape: '30mmテープ',
  LyocellOpaque: 'オペーク',
  LyocellOpaqueDiluent: 'オペーク希釈',
  LyocellOpaqueRemove: 'オペーク剥離',
  LyocellAdhesive: '接着剤',
  LyocellAdhesiveRemove: '接着剤剥離',
  UsheetUsheet: 'Uシート',
  UsheetPolynet600: 'ポリネット#600',
  UsheetPolynet800: 'ポリネット#800',
  UsheetPolynet1000: 'ポリネット#1000',
  UsheetHardnester800850: 'ﾊ-ﾄﾞﾈｽﾀ-800HV/850HV',
  UsheetHardnester900950: 'ﾊ-ﾄﾞﾈｽﾀ-900HV/950HV',
  UsheetHardnesterkaeshin: 'ﾊ-ﾄﾞﾈｽﾀ-替芯',
  Dansheet900: 'ダンシート900 x 50m',
  Dansheet1000: 'ダンシート1000 x 50m',
  Dansheet1100: 'ダンシート1100 x 50m',
  Dansheet1200: 'ダンシート1200 x 50m',
  Dansheet1250: 'ダンシート1250 x 50m',
  Dansheet1300: 'ダンシート1300 x 50m',
  BlackLacquerHGJ001EU: 'HGJ-001 EU',
  Fare: '運賃',
  Others1: 'その他１',
  Others2: 'その他２',
} as const;

/** 製版代計算区分 本社用 */
export const CylMakingCalcTypeForMainOffice = 0;

/** シャフトコード */
export const ShaftCode = {
  /** 無し */
  Without: '10',
  /** あり */
  With: '11',
};

/** メッセージ */
export const Message = {
  Regist: '登録しました。',
};
export type ShaftCode = typeof ShaftCode[keyof typeof ShaftCode];

/** 見積ダミー得意先部門コード */
export const DummyCustomerSectionCode = 'MZMR';

/** 単価計算 メッセージ */
export const OrderPriceUpdateMessage = '単価を更新しました。';

export const invoiceStartDate = new Date('2023-10-01');

/** 価格承認検索 日付条件プルダウンリスト */
export const SELECT_DATE_TYPE_OPTIONS = [
  { value: 'cylMakingPlanDate', text: '製版予定日', decisionCode: '' },
  { value: 'shipmentPlanDate', text: '出荷予定日', decisionCode: '' },
  { value: 'deliveryPlanDate', text: '納品予定日', decisionCode: '' },
  { value: 'imageApprovedFinishDate', text: '校了日', decisionCode: '' },
  { value: 'appropriatePlanDate', text: '計上予定日', decisionCode: '' },
  { value: 'approvalDate', text: '承認日', decisionCode: '' },
];

/** 価格承認検索 全体ステータス条件プルダウンリスト */
export const SELECT_TOTAL_JOB_STATUS_OPTIONS = [
  { value: '05', text: '受注' },
  { value: '07', text: '校了' },
  { value: '14', text: '作業予定済み(画像・製版)' },
  { value: '15', text: '作業中' },
  { value: '16', text: '出荷済み' },
  { value: '18', text: '保留' },
  { value: '19', text: 'トビ入' },
  { value: '20', text: 'ノビ' },
  { value: '21', text: '再手配' },
];
