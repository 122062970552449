import React, { ReactNodeArray } from 'react';

import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms';

/** 単価計算 行コンポーネント */
export const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth, sixth],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid xs={2.6}>{first}</HeadGrid>
        <BodyGrid xs={3.2} sx={{ justifyContent: 'center' }}>
          {second}
        </BodyGrid>
        <BodyGrid xs={1.2} sx={{ justifyContent: 'end' }}>
          {third}
        </BodyGrid>
        <BodyGrid xs={1.6} sx={{ justifyContent: 'end' }}>
          {fourth}
        </BodyGrid>
        <BodyGrid xs={1.8} sx={{ justifyContent: 'end' }}>
          {fifth}
        </BodyGrid>
        <BodyGrid xs={1.6} sx={{ justifyContent: 'end' }}>
          {sixth}
        </BodyGrid>
      </RowGrid>
    </>
  );
};

/** 単価計算 ヘッダー行コンポーネント */
export const HeadRow: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth, sixth],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid vertical xs={2.6}>
          {first}
        </HeadGrid>
        <HeadGrid vertical xs={3.2}>
          {second}
        </HeadGrid>
        <HeadGrid vertical xs={1.2}>
          {third}
        </HeadGrid>
        <HeadGrid vertical xs={1.6}>
          {fourth}
        </HeadGrid>
        <HeadGrid vertical xs={1.8}>
          {fifth}
        </HeadGrid>
        <HeadGrid vertical xs={1.6}>
          {sixth}
        </HeadGrid>
      </RowGrid>
    </>
  );
};
