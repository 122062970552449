import { useContext } from 'react';

import { FlexoOrderEditMasterContext } from '../contexts/FlexoOrderEditMasterContext';
import { FlexoOrderEditMaster } from '../types';

/** セレクト選択肢Hooks */
export const useFlexoOrderEditMaster = (): {
  master: FlexoOrderEditMaster;
} => {
  const master = useContext(FlexoOrderEditMasterContext);

  return {
    master,
  };
};
