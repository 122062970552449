import * as React from 'react';
import theme from 'themes/theme';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { RowGrid, BodyGrid, HeadGrid } from 'components/parts/atoms/Grid';

// types
import { LastUpdateProps } from './types';

// styled
const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
}));

/**
 * ヘッダースタイル
 */
const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  width: '100%',
}));

/**
 * 汎用ボックススタイル
 */
const NormalBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(2),
  width: '100%',
}));

/**
 * 最終更新者・最終更新日時コンポーネント
 * @param param0
 * @returns
 */
const LastUpdate: React.VFC<LastUpdateProps> = ({ workProcess, lastEmployee, lastUpdateDate }) => {
  return (
    <Wrapper>
      <RowGrid columns={1} sx={{ mt: theme.spacing(4), boxSizing: 'border-box' }}>
        <HeadGrid xs={1} sx={{ p: 0 }}>
          <Header>
            <Typography>{workProcess}作業記録</Typography>
          </Header>
        </HeadGrid>
      </RowGrid>
      <RowGrid columns={4} sx={{ boxSizing: 'border-box' }}>
        <HeadGrid xs={1}>
          <NormalBox>
            <Typography>最終更新者</Typography>
          </NormalBox>
        </HeadGrid>
        <BodyGrid xs={1}>
          <NormalBox>
            <Typography>{lastEmployee ?? ''}</Typography>
          </NormalBox>
        </BodyGrid>
        <HeadGrid xs={1}>
          <NormalBox>
            <Typography>最終更新日時</Typography>
          </NormalBox>
        </HeadGrid>
        <BodyGrid xs={1}>
          <NormalBox>
            <Typography>{lastUpdateDate ?? ''}</Typography>
          </NormalBox>
        </BodyGrid>
      </RowGrid>
    </Wrapper>
  );
};

export default LastUpdate;
