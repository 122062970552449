import * as React from 'react';

import { Box, CircularProgress, useTheme } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {
  DataGridPro as MuiDataGrid,
  DataGridProProps as MuiDataGridProps,
  GridOverlay,
  useGridApiContext,
  useGridState,
} from '@mui/x-data-grid-pro';

export type DataGridProps = MuiDataGridProps & {
  stripe?: boolean;
  stripeColor?: string;
  disableWhenLoading?: boolean;
};

const RoundedPagination = () => {
  const apiRef = useGridApiContext();
  const [state] = useGridState(apiRef);
  return (
    <Pagination
      color='primary'
      count={Math.ceil(state.pagination.rowCount / state.pagination.pageSize)}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      shape='rounded'
    />
  );
};

const DisabledOverlay = () => {
  return (
    <GridOverlay>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff88',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <CircularProgress />
      </Box>
    </GridOverlay>
  );
};

const DataGrid: React.FC<DataGridProps> = ({
  stripe,
  stripeColor,
  disableWhenLoading,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box>
      <MuiDataGrid
        density={'compact'}
        disableColumnFilter
        disableColumnMenu
        disableColumnReorder
        {...props}
        components={{
          Pagination: RoundedPagination,
          LoadingOverlay: disableWhenLoading ? DisabledOverlay : undefined,
          ...props.components,
        }}
        sx={{
          '& .MuiDataGrid-row:nth-child(even)': {
            backgroundColor: stripe ? stripeColor ?? theme.palette.defaultStripe.main : undefined,
          },
          ...props.sx,
        }}
        localeText={{
          footerRowSelected: (count) => `${count}件選択しました`,
        }}
      ></MuiDataGrid>
    </Box>
  );
};

export default DataGrid;
