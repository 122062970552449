import { ja } from 'date-fns/locale';
import * as React from 'react';

import {
  DesktopDatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { TextField } from '..';

export type YearMonthPickerProps<TDate> = Pick<
  MuiDatePickerProps<TDate>,
  'value' | 'onChange' | 'minDate' | 'maxDate'
>;

const YearMonthPicker: React.VFC<YearMonthPickerProps<Date>> = ({ ...props }) => {
  const defaultProps: Pick<
    MuiDatePickerProps<Date>,
    'renderInput' | 'inputFormat' | 'mask' | 'views'
  > = {
    renderInput: (params) => <TextField {...params} sx={{ width: '11em' }} />,
    inputFormat: 'yyyy年MM月',
    mask: '____年__月',
    views: ['year', 'month'],
  };
  const mergedProps = { ...defaultProps, ...props };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
      <MuiDatePicker {...mergedProps} />
    </LocalizationProvider>
  );
};

export default YearMonthPicker;
