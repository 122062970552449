import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  setProcessTarget,
  setInitialProcessState,
  setDestinations,
  clear,
  setFromPage,
} from 'store/imageServer/imageDataDownload';

import { ImageDataDownloadState } from './types';

/** 画像ダウンロードストアのHooks */
export const useImageDataDownload = (): {
  processTarget: ImageDataDownloadState['processTarget'];
  fromPage: ImageDataDownloadState['fromPage'];
  pageState: ImageDataDownloadState['page'];
  setProcessTarget: (state: ImageDataDownloadState['processTarget']) => void;
  setFromPage: (state: ImageDataDownloadState['fromPage']) => void;
  setInitialProcessState: (state: ImageDataDownloadState['page']['initialProcessState']) => void;
  setDestinations: (state: ImageDataDownloadState['page']['destinations']) => void;
  clear: () => void;
} => {
  const state = useSelector((state: RootState) => state.imageDataDownload);

  const targetState = state['processTarget'];
  const fromPageState = state['fromPage'];
  const pageState = state['page'];

  const dispatch = useDispatch();
  const dispatchSetProcessTarget = (state: ImageDataDownloadState['processTarget']) => {
    dispatch(setProcessTarget(state));
  };

  const dispatchSetFromPage = (state: ImageDataDownloadState['fromPage']) => {
    dispatch(setFromPage(state));
  };

  const dispatchSetInitialProcessState = (
    state: ImageDataDownloadState['page']['initialProcessState'],
  ) => {
    dispatch(setInitialProcessState(state));
  };

  const dispatchSetDestinations = useCallback(
    (destinations: ImageDataDownloadState['page']['destinations']) => {
      dispatch(setDestinations(destinations));
    },
    [dispatch],
  );
  const dispatchClear = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  return {
    processTarget: targetState,
    fromPage: fromPageState,
    pageState,
    setProcessTarget: dispatchSetProcessTarget,
    setFromPage: dispatchSetFromPage,
    setInitialProcessState: dispatchSetInitialProcessState,
    setDestinations: dispatchSetDestinations,
    clear: dispatchClear,
  };
};
