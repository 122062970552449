import { SharedMessageListener } from 'contexts/MessageContext';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import NavBar from './components/NavBar';
import SideBar from './components/SideBar';

import { SIDEBAR_WIDTH_FULL, NAVBAR_HEIGHT } from './config';
import { useScroll } from 'hooks/useScroll';

/** 画面サイズの設定(指定の横サイズが1280だがスクロールが出た時点で19px使用されるので1261pxとしている) */
const Wrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  minWidth: 1261,
}));

const BodyWrapper = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  paddingTop: NAVBAR_HEIGHT,
}));

const Inner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showSideBar',
})<{ showSideBar: boolean }>(({ theme, showSideBar }) => ({
  padding: theme.spacing(4),
  height: '100%',
  width: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${SIDEBAR_WIDTH_FULL}px`,
  ...(showSideBar && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Page = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const [showSideBar, setShowSideBar] = useState(true);
  const { scrollTop } = useScroll();

  useEffect(() => {
    setShowSideBar(location.pathname.includes('Portal'));
    scrollTop('auto');
  }, [location.pathname]);

  return (
    <Wrapper>
      <NavBar
        height={NAVBAR_HEIGHT}
        showSideBar={showSideBar}
        toggleSideBar={() => setShowSideBar((prevState: boolean) => !prevState)}
      />
      <BodyWrapper>
        <SideBar showSideBar={showSideBar} />
        <Inner
          sx={{
            paddingLeft: '2px',
            paddingRight: '2px',
          }}
          showSideBar={showSideBar}
        >
          <Page>
            <SharedMessageListener />
            {children}
            <Box sx={{ py: 8 }} />
          </Page>
        </Inner>
      </BodyWrapper>
    </Wrapper>
  );
};

export default DefaultLayout;
