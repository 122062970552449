import React from 'react';

import { useTheme } from '@mui/material';

import { OpenCloseHeading, OpenCloseHeadingProps } from 'components/parts/molecules';

type Props = Pick<OpenCloseHeadingProps, 'children' | 'onClose' | 'displayFlg' | 'buttonText'>;

const StyledOpenCloseHeading: React.VFC<Props> = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <OpenCloseHeading
      {...props}
      backgroundColor={theme.palette.cyclonistDarkOrange.main}
      textColor={theme.palette.cyclonistDarkOrange.contrastText}
      sx={{ zIndex: 1, position: 'relative', marginBottom: '2px' }}
    >
      {children}
    </OpenCloseHeading>
  );
};
export { StyledOpenCloseHeading as OpenCloseHeading };
