import React, { ReactNodeArray } from 'react';

import { RowGrid } from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

/** 合計 行コンポーネント */
export const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo],
}) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={1.5} bxs={4.5}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
        <ContentsGrid hxs={1.5} bxs={4.5}>
          {labelTwo}
          {bodyTwo}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};
