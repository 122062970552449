import React from 'react';

import { useTotalPriceWatcher } from '../hooks/price/priceWatcher/useTotalPriceWatcher';
import { useUnitPriceWatcher } from '../hooks/price/priceWatcher/useUnitPriceWatcher';
import { useFormSetState } from '../hooks/useFormSetState';

/** 価格情報フォーム監視コンポーネント */
export const PriceWatcher: React.VFC = () => {
  const { afterFormSet } = useFormSetState();

  // シリンダー単価変更時に値をセットするhook
  useUnitPriceWatcher(afterFormSet);
  useTotalPriceWatcher();
  return <></>;
};
