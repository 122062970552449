import { PlateMakingCode, FaceDiv } from 'constants/define';
import useShowMakingSpecification from 'hooks/useShowMakingSpecification';
import React, { useState, useEffect } from 'react';
import theme from 'themes/theme';

import { Box } from '@mui/material';

import { BodyLabel, HeadLabel, BodyLink } from 'components/parts/atoms';
import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms/Grid';
import { JobStatus } from 'components/parts/molecules';
import { ContentsGrid } from 'components/parts/molecules/ContentsGrid';
import { OpenCloseHeading } from 'components/parts/molecules/OpenCloseHeading';

import * as date from 'utils/date';
import { dateText } from 'utils/date';

import useFetchJobInfo from './hooks';
import { JobInfoDetail } from './types';

type Props = {
  jobId: number;
  mainMakings?: JobInfoDetail[];
  reload?: boolean;
};

const JobInfo: React.VFC<Props> = (props) => {
  // 詳細画面表示State
  const [displayFlg, setDisplayFlg] = useState<boolean>(false);

  // Job情報Hooks
  const { jobInfo, fetch, updateDetail } = useFetchJobInfo(props.jobId);

  const { showMakingSpecification } = useShowMakingSpecification();

  const reload = props.reload ?? false;

  // 行の背景色取得
  const getLineBgColor = (index: number) => {
    if (index % 2 === 0) {
      return theme.palette.background.default;
    } else {
      return theme.palette.cyclonistLightBlue.main;
    }
  };

  useEffect(() => {
    // 最新のJOB情報に更新する
    if (reload === true) {
      fetch();
    }
  }, [reload]);

  useEffect(() => {
    // 外部から渡された内容を元に製版情報を更新する
    // シリンダー入荷入力画面では、画面内で変更された内容が製版情報に反映されるため
    if (props.mainMakings && props.mainMakings.length !== 0) {
      if (jobInfo) {
        const updateData = props.mainMakings.map((item) => {
          const mainMaking = jobInfo.mainMakings?.find(
            (x) =>
              item.faceDiv === x.faceDiv &&
              item.printSequence === x.printSequence &&
              item.printSequence2 === x.printSequence2,
          );

          if (mainMaking) {
            mainMaking.stampNo1 = item.stampNo1;
            mainMaking.stampNo2 = item.stampNo2;
            mainMaking.tkPunch = item.tkPunch;
            mainMaking.stampNo3 = item.stampNo3;
            mainMaking.tpAbcName = item.tpAbcName;
            mainMaking.tpStampNo = item.tpStampNo;
            return mainMaking;
          } else {
            return item;
          }
        });

        updateDetail(updateData);
      }
    }
  }, [props.mainMakings]);

  // 詳細表示イベント
  const onDisplayChange = () => {
    if (displayFlg) {
      setDisplayFlg(false);
    } else {
      setDisplayFlg(true);
    }
  };

  // 製版指示書の表示
  const onShowMakingSpecification = (
    jobId: number | undefined,
    productionCategory: string | undefined,
  ) => {
    if (jobId && productionCategory) {
      showMakingSpecification(productionCategory, jobId);
    }
  };

  const renderBasicInfo = (): JSX.Element => {
    return (
      <>
        <Box m={2} />
        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>JOB No.</HeadLabel>
            <BodyLink
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  color: theme.palette.cyclonistRed.main,
                },
              }}
              onClick={() =>
                onShowMakingSpecification(
                  jobInfo?.header?.jobId,
                  jobInfo?.header?.productionCategory,
                )
              }
            >
              {jobInfo?.header?.jobNo}
            </BodyLink>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={5} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>品名</HeadLabel>
            <BodyLabel>{`${jobInfo?.header?.productionName ?? ''}　${
              jobInfo?.header?.jobName ?? ''
            }`}</BodyLabel>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>得意先名</HeadLabel>
            <BodyLabel>{jobInfo?.header?.customerName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>使用紙</HeadLabel>
            <BodyLabel>{jobInfo?.header?.paperName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>刷り方</HeadLabel>
            <BodyLabel>{jobInfo?.header?.printName}</BodyLabel>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>サイズ</HeadLabel>
            <BodyLabel>{`${jobInfo?.header?.cylSizeWidth ?? ''} × ${
              jobInfo?.header?.cylSizeEnsyuu?.toFixed(2) ?? ''
            }`}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>色数/版数</HeadLabel>
            <BodyLabel>
              {' '}
              {`印刷面（メイン）${jobInfo?.header?.mainTotalCount ?? 0}色${
                jobInfo?.header?.mainMakeCount ?? 0
              }本`}
              <br />
              {`印刷面（リバース）${jobInfo?.header?.reverseTotalCount ?? 0}色${
                jobInfo?.header?.reverseMakeCount ?? 0
              }本`}
            </BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>前製版日</HeadLabel>
            <BodyLabel>{date.dateText(jobInfo?.header?.beforeCylMakingDate, 'yy/MM/dd')}</BodyLabel>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>製版予定日</HeadLabel>
            <BodyLabel> {dateText(jobInfo?.header?.cylMakingPlanDate, 'yy/MM/dd')} </BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>出荷予定日</HeadLabel>
            <BodyLabel> {dateText(jobInfo?.header?.shipmentPlanDate, 'yy/MM/dd')} </BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>納品予定日</HeadLabel>
            <BodyLabel> {dateText(jobInfo?.header?.deliveryDate, 'yy/MM/dd')} </BodyLabel>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={9} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>JOB指示事項</HeadLabel>
            <BodyLabel> {jobInfo?.header?.noteGenerally}</BodyLabel>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={9} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>シ 指示事項</HeadLabel>
            <BodyLabel>{jobInfo?.header?.cylDirection}</BodyLabel>
          </ContentsGrid>
        </RowGrid>
        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={2} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>第二承認</HeadLabel>
            <BodyLabel>{jobInfo?.header?.secondApprovalEmployeeName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>第二承認日</HeadLabel>
            <BodyLabel>{dateText(jobInfo?.header?.secondApprovalDate, 'yy/MM/dd')}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={2} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>第一承認</HeadLabel>
            <BodyLabel>{jobInfo?.header?.approvalEmployeeName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>第一承認日</HeadLabel>
            <BodyLabel>{dateText(jobInfo?.header?.approvalDate, 'yy/MM/dd')}</BodyLabel>
          </ContentsGrid>
        </RowGrid>
      </>
    );
  };

  const renderBasicExtendInfo = (): JSX.Element => {
    return (
      <>
        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>参照JOB No.</HeadLabel>
            <BodyLabel>{jobInfo?.header?.referenceJobNo}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={3} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel sx={{ textAlign: 'left' }}>
              得意先
              <br />
              発注No.
            </HeadLabel>
            <BodyLabel>{jobInfo?.header?.customerOrderNo}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel sx={{ textAlign: 'left' }}>
              シリーズ
              <br />
              マスタ
            </HeadLabel>
            <BodyLabel>{jobInfo?.header?.productionNo}</BodyLabel>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>営業担当者</HeadLabel>
            <BodyLabel>{jobInfo?.header?.salesFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>デザイン</HeadLabel>
            <BodyLabel>{jobInfo?.header?.designFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>分解</HeadLabel>
            <BodyLabel>{jobInfo?.header?.photoRetouchFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>色分け</HeadLabel>
            <BodyLabel>{jobInfo?.header?.colorSeparationFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>下版</HeadLabel>
            <BodyLabel>{jobInfo?.header?.layoutFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>彫刻D</HeadLabel>
            <BodyLabel>{jobInfo?.header?.engravingFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>レーザーD</HeadLabel>
            <BodyLabel>{jobInfo?.header?.laserFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel>校正</HeadLabel>
            <BodyLabel>{jobInfo?.header?.proofReadingFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1} hsx={{ justifyContent: 'flex-start' }}>
            <HeadLabel> 検版</HeadLabel>
            <BodyLabel>{jobInfo?.header?.inspectionFamilyName}</BodyLabel>
          </ContentsGrid>
          <ContentsGrid hxs={1} bxs={1}>
            <></>
            <></>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={9} hsx={{ justifyContent: 'flex-start' }} bsx={{ p: 0 }}>
            <HeadLabel>製版指示事項</HeadLabel>
            <RowGrid columns={9}>
              <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                <HeadLabel>予定表</HeadLabel>
                <BodyLabel>{jobInfo?.header?.cylMakingDirectionOnSchedule}</BodyLabel>
              </ContentsGrid>
              <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                <HeadLabel>指示書</HeadLabel>

                <BodyLabel>{jobInfo?.header?.cylMakingDirectionOnState}</BodyLabel>
              </ContentsGrid>
            </RowGrid>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={9} hsx={{ justifyContent: 'flex-start' }} bsx={{ p: 0 }}>
            <HeadLabel>出荷指示事項</HeadLabel>
            <RowGrid columns={9}>
              <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                <HeadLabel>予定表</HeadLabel>
                <BodyLabel>{jobInfo?.header?.shipmentDirectionOnSchedule}</BodyLabel>
              </ContentsGrid>
              <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                <HeadLabel>指示書</HeadLabel>
                <BodyLabel>{jobInfo?.header?.shipmentDirectionOnState}</BodyLabel>
              </ContentsGrid>
            </RowGrid>
          </ContentsGrid>
        </RowGrid>

        <RowGrid columns={10}>
          <ContentsGrid hxs={1} bxs={9} hsx={{ justifyContent: 'flex-start' }} bsx={{ p: 0 }}>
            <HeadLabel sx={{ color: theme.palette.cyclonistRed.main }}>
              製版指示書
              <br />
              特記事項
            </HeadLabel>
            <>
              <RowGrid columns={9}>
                <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                  <HeadLabel>画像</HeadLabel>
                  <BodyLabel>{jobInfo?.header?.noteImage}</BodyLabel>
                </ContentsGrid>
                <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                  <HeadLabel>入荷</HeadLabel>
                  <BodyLabel>{jobInfo?.header?.cylArrivalNote}</BodyLabel>
                </ContentsGrid>
                <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                  <HeadLabel>彫刻</HeadLabel>
                  <BodyLabel>{jobInfo?.header?.noteEngraving}</BodyLabel>
                </ContentsGrid>
                <ContentsGrid hxs={1} bxs={8} hsx={{ justifyContent: 'flex-start' }}>
                  <HeadLabel>レーザー</HeadLabel>
                  <BodyLabel>{jobInfo?.header?.noteLaser}</BodyLabel>
                </ContentsGrid>
              </RowGrid>
            </>
          </ContentsGrid>
        </RowGrid>
      </>
    );
  };

  const renderHeader = (): JSX.Element => {
    if (!jobInfo || (jobInfo.mainMakings.length == 0 && jobInfo.reverseMakings.length == 0)) {
      return <></>;
    }
    return (
      <RowGrid columns={14}>
        <HeadGrid
          xs={2}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          <HeadLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            刷順
          </HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          <HeadLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            刷色
          </HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          <HeadLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            区分
          </HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          <HeadLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            合・調
          </HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={9}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: 'center',
          }}
        >
          <HeadLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            営業：シリンダー情報・合版情報
          </HeadLabel>
        </HeadGrid>
      </RowGrid>
    );
  };

  const renderDetails = (details: JobInfoDetail[]): JSX.Element => {
    if (details.length == 0) return <></>;
    return (
      <>
        {details.map((item, index) => {
          return (
            <RowGrid columns={14} key={item.cylMakingJobId}>
              <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
                <BodyLabel>
                  {item.faceDiv === FaceDiv.Reverse
                    ? '裏' + item.printSequence
                    : item.printSequence}
                </BodyLabel>
              </HeadGrid>
              <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
                <BodyLabel>{item.printSequence2}</BodyLabel>
              </HeadGrid>
              <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
                <BodyLabel>{item.printColor}</BodyLabel>
              </HeadGrid>
              <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
                <BodyLabel>{item.plateMakingShortName}</BodyLabel>
              </HeadGrid>
              <BodyGrid
                xs={1}
                sx={{
                  bgcolor:
                    item.plateMakingCd === PlateMakingCode.AwaseDiv
                      ? theme.palette.cyclonistYellow.main
                      : item.plateMakingCd === PlateMakingCode.ChousaDiv
                      ? theme.palette.cyclonistChousahanPink.main
                      : theme.palette.background.default,
                  justifyContent: 'center',
                }}
              >
                <BodyLabel>{item.awaseChousaName}</BodyLabel>
              </BodyGrid>
              <BodyGrid xs={9} sx={{ backgroundColor: getLineBgColor(index) }}>
                <RowGrid columns={4} direction='column'>
                  <BodyGrid xs={1} sx={{ backgroundColor: getLineBgColor(index) }}>
                    <BodyLabel>{item.awasebanNo}</BodyLabel>
                  </BodyGrid>
                  <BodyGrid xs={1} sx={{ backgroundColor: getLineBgColor(index) }}>
                    <BodyLabel>{`ｼﾘﾝﾀﾞｰNO: ${item.icTagNo ?? ''}`}</BodyLabel>
                  </BodyGrid>
                  <BodyGrid xs={1} sx={{ backgroundColor: getLineBgColor(index) }}>
                    <BodyLabel>
                      {`刻印:
                  ${item.stampNo1 ?? ''}
                  ${item.stampNo2 ?? ''}
                  ${item.tkPunch ?? ''}
                  ${item.stampNo3 ?? ''}
                  ${item.tpAbcName ?? ''}
                  ${item.tpStampNo ?? ''}`}
                    </BodyLabel>
                  </BodyGrid>
                  <BodyGrid xs={1} sx={{ backgroundColor: getLineBgColor(index) }}>
                    <BodyLabel>
                      {`
                  ${item.diameter1?.toFixed(3) ?? ''}/
                  ${item.diameter2?.toFixed(3) ?? ''}/
                  ${item.diameter3?.toFixed(3) ?? ''}/
                  ${item.diameter4?.toFixed(3) ?? ''}/
                  ${item.diameter5?.toFixed(3) ?? ''}/
                  ${item.diameter6?.toFixed(3) ?? ''}`}
                    </BodyLabel>
                  </BodyGrid>
                </RowGrid>
              </BodyGrid>
            </RowGrid>
          );
        })}
      </>
    );
  };

  return (
    <>
      <JobStatus
        status={{
          totalStatus: jobInfo?.header?.displayedTotalStatus ?? '',
          imageStatus: jobInfo?.header?.displayedImageStatus ?? '',
          cylindersStatus: jobInfo?.header?.displayedCylinderStatus ?? '',
        }}
      />
      <Box m={2} />
      <OpenCloseHeading onClose={onDisplayChange} buttonText='詳細' displayFlg={displayFlg}>
        JOB 基本情報
      </OpenCloseHeading>
      <Box m={2} />
      {renderBasicInfo()}
      {displayFlg && (
        <>
          {renderBasicExtendInfo()}
          <Box m={2} />
          {renderHeader()}
          {renderDetails(jobInfo?.mainMakings ?? [])}
          {renderDetails(jobInfo?.reverseMakings ?? [])}
        </>
      )}
    </>
  );
};

export default JobInfo;
