import { Region } from 'constants/define';
import useApiClient from 'hooks/useApiClient';
import { useAuth } from 'hooks/useAuth';
import useSharedMessage from 'hooks/useSharedMessage';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import LoginIcon from '@mui/icons-material/Login';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BodyLink, Button, KanaField, PasswordField, Typography } from 'components/parts/atoms';

import { LoginForm } from './types';

const Container = styled(Box)(() => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledPaper = styled(Paper)(() => ({
  height: '250px',
  width: '400px',
  marginTop: '-10%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Login: React.VFC<{ region: Region }> = ({ region }) => {
  // React Hook Form
  const formMethod = useForm<LoginForm>();

  const history = useHistory();
  const messageApi = useSharedMessage();
  const { login } = useAuth(messageApi.pushMessages);
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // Event
  const onTryLogin = async (data: LoginForm) => {
    messageApi.clear();
    const isLogin = await login(data, region);
    if (isLogin) {
      history.push('/Portal');
    } else {
      console.log('login failure');
      messageApi.pushError('ログインに失敗しました。');
    }
  };

  // Event
  const onTryHealth = async () => {
    try {
      const response = await apiClient.get(`/api/v1/SystemLogin/Health`);

      if (response.data.status === 200) {
        // 処理なし
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onPasswordReissue = async () => {
    history.push('/PasswordReissue');
  };

  const regionTitle = (() => {
    switch (region) {
      case Region.Kyushu:
        return '（九州専用）';
      case Region.Hokkaido:
        return '（北海道専用）';
      default:
        return '';
    }
  })();

  const editionText = (() => {
    switch (region) {
      case Region.Kyushu:
        return ' : Kyushu Edition';
      case Region.Hokkaido:
        return ' : Hokkaido Edition';
      default:
        return '';
    }
  })();

  return (
    <>
      <Helmet>
        <title>{'Cyclonist2'}</title>
      </Helmet>
      <Box
        onLoad={() => {
          onTryHealth();
        }}
      >
        <Container>
          <StyledPaper>
            <form onSubmit={formMethod.handleSubmit(onTryLogin)}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography>{`ログイン画面${regionTitle}`}</Typography>
              </Box>
              <Box m={2}></Box>
              <Box alignItems='center' justifyContent='center' sx={{ display: 'flex' }}>
                <Typography>ログインID</Typography>
                <Box mx={1}></Box>
                <Controller
                  name='userId'
                  control={formMethod.control}
                  render={({ field: { onChange, value, name } }) => (
                    <KanaField maxLength={20} onChange={onChange} value={value} name={name} />
                  )}
                />
              </Box>
              <Box m={2}></Box>
              <Box alignItems='center' justifyContent='center' sx={{ display: 'flex' }}>
                <Typography>パスワード</Typography>
                <Box mx={1}></Box>
                <Controller
                  name='userPassword'
                  control={formMethod.control}
                  render={({ field: { onChange, value, name } }) => (
                    <PasswordField maxLength={20} onChange={onChange} value={value} name={name} />
                  )}
                />
              </Box>
              <Box m={2}></Box>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  startIcon={<LoginIcon />}
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{ width: '100px' }}
                >
                  ログイン
                </Button>
              </Box>
            </form>
            <Box m={3}></Box>
            <BodyLink
              variant='body1'
              onClick={() => {
                onPasswordReissue();
              }}
            >
              ユーザーID・パスワードを忘れてしまった方はこちらへ
            </BodyLink>
            <Box my={2} />
            <Typography variant='body1' sx={{ textAlign: 'center' }}>
              {`Cyclonist2 version 1.0${editionText}`} <br />
              Copyright 2004 - 2023 TOYO FPP Co.,Ltd. All rights reserved
            </Typography>
          </StyledPaper>
        </Container>
      </Box>
    </>
  );
};

export default Login;
