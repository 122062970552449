import React, { useCallback } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useWorkNoticeEditStore } from 'store/topSales/order/workNoticeEdit/hooks';

import { AddCircle } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material';

import {
  FlexoOrderEditForm,
  PageState,
  Present,
} from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { HeadLabel, RowGrid, HeadGrid, BodyGrid as BodyGridOrigin } from 'components/parts/atoms';

import { hasValue } from '../../utils/utils';

import { useFlexoOrderEditMaster } from '../../hooks/useFlexoOrderEditMaster';
import { useId } from '../../hooks/useId';
import { usePageContext } from '../../hooks/usePageContext';
import { EditIconButton, EditNumericField, EditSelect } from '../Fields';
import { Heading } from '../Heading';

/**
 * 行に入力があるか
 */
function isValidItem(item: Present): boolean {
  return hasValue(item.nameCdId) && hasValue(item.amount) && item.amount !== 0;
}

const BodyGrid = styled(BodyGridOrigin)(() => ({
  justifyContent: 'center',
}));

/** 作業予定 提出物コンポーネント */
const Presents: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { master } = useFlexoOrderEditMaster();
  const { pageState } = usePageContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'schedule.presents',
  });

  // 編集可否
  const {
    state: { data: workData },
  } = useWorkNoticeEditStore();
  const discardImageScheme: boolean = workData?.form.discardImageScheme ?? false;
  const [canEditPresents] = useWatch({
    control,
    name: ['schedule.canEditPresents'],
  });
  const editable = canEditPresents || discardImageScheme;

  // hook
  const { generateId } = useId();

  /**提出物の行追加 */
  const onAdd = useCallback(() => {
    const addRow: Present = {
      presentId: generateId(),
      nameCdId: '',
      methodCdId: '',
      amount: 0,
    };
    append(addRow);
  }, [append, generateId]);

  /**
   * 提出物の行削除
   * @param index インデックス
   */
  const onDelete = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <>
      <Heading>提出物</Heading>
      <RowGrid>
        <HeadGrid vertical xs={4.5}>
          <HeadLabel>名称</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={4}>
          <HeadLabel>方法</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={3}>
          <HeadLabel>{'枚数(ファイル数)'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={0.5}>
          <EditIconButton onClick={() => onAdd()} disabled={!editable}>
            <AddCircle />
          </EditIconButton>
        </HeadGrid>
      </RowGrid>
      {fields.map((item, index) => {
        // 確認画面では入力されていない項目を表示しない
        if (pageState === PageState.Check && !isValidItem(item)) {
          return null;
        }
        return (
          <RowGrid key={item.id}>
            <BodyGrid xs={4.5} sx={{ justifyContent: 'center' }}>
              <Controller
                name={`schedule.presents.${index}.nameCdId`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    addEmptyRow
                    options={master.presentList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '16em' }}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={4} sx={{ justifyContent: 'center' }}>
              <Controller
                name={`schedule.presents.${index}.methodCdId`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditSelect
                    addEmptyRow
                    options={master.presentMethodList}
                    onChange={onChange}
                    value={value}
                    name={name}
                    sx={{ width: '10em' }}
                    disabled={!editable}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={3} sx={{ justifyContent: 'center' }}>
              <Controller
                name={`schedule.presents.${index}.amount`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <EditNumericField
                    max={999}
                    min={0}
                    decimalDigits={0}
                    onChange={onChange}
                    value={value}
                    name={name}
                    disabled={!editable}
                    sx={{ width: '4em' }}
                  />
                )}
              />
            </BodyGrid>
            <BodyGrid xs={0.5}>
              <EditIconButton onClick={() => onDelete(index)} disabled={!editable}>
                <Delete />
              </EditIconButton>
            </BodyGrid>
          </RowGrid>
        );
      })}
    </>
  );
};

export default React.memo(Presents);
