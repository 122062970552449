import React from 'react';

import { useTheme } from '@mui/material/styles';

import { BodyGrid, BodyLabel, HeadGrid, RowGrid } from 'components/parts/atoms';

export type JobStatusProps = {
  status: {
    totalStatus: string;
    imageStatus: string;
    cylindersStatus: string;
  } | null;
};

const JobStatus: React.VFC<JobStatusProps> = (props) => {
  const theme = useTheme();
  return (
    <>
      <RowGrid columns={10}>
        <HeadGrid
          xs={1}
          sx={{
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          ステータス
        </HeadGrid>
        <HeadGrid
          xs={1}
          sx={{
            justifyContent: 'center',
            backgroundColor: theme.palette.cyclonistBlue.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          全
        </HeadGrid>
        <BodyGrid
          xs={2}
          sx={{
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.status?.totalStatus ?? ''}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{
            justifyContent: 'center',
            backgroundColor: theme.palette.cyclonistOrange.main,
          }}
        >
          画
        </BodyGrid>
        <BodyGrid
          xs={2}
          sx={{
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.status?.imageStatus ?? ''}</BodyLabel>
        </BodyGrid>
        <HeadGrid
          xs={1}
          sx={{
            justifyContent: 'center',
            backgroundColor: theme.palette.cyclonistGreen.main,
          }}
        >
          シ
        </HeadGrid>
        <BodyGrid
          xs={2}
          sx={{
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.status?.cylindersStatus ?? ''}</BodyLabel>
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default JobStatus;
