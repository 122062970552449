import { ProductionCategoryCode } from 'constants/define';

import * as apiTypeCutomerEdit from 'api/types/customer/customerEdit';
import * as apiTypeUnitPriceEdit from 'api/types/customer/unitPriceEdit';

import { CylinderUnitPrice } from '../CustomerEdit/types';

/** ページ区分 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** シリンダ単価入力専用フォーム */
export type CylinderForm = CylinderUnitPrice;

/** シリンダ単価入力・確認メインのprops */
export type CylinderMainProps = {
  index: number;
  state: CylinderUnitPrice;
  open: boolean;
  onClose: (value?: CylinderUnitPrice) => void;
  checkFinishFlag: boolean;
};

/** シリンダ単価入力props */
export type CylinderEditProps = {
  onBack: () => void;
  onGoCheck: () => void;
  onSearch: () => void;
  newCreateFlag: boolean;
};

/** シリンダ単価入力基本情報props */
export type CylinderEditBasicInfosProps = {
  onSearch: () => void;
  newCreateFlag: boolean;
};

/** シリンダ単価入力確認props */
export type CylinderEditCheckProps = {
  onBack: () => void;
  onAddForm: () => void;
  checkFinishFlag: boolean;
};

/** 単価入力東洋売価率未設定時検索条件 */
export type CustomerSectionCdCondition = {
  customerSectionCd: string;
  sectionPeculiarInfo: string;
};

/** シャフトCdId検索条件 */
export type ShaftCdIdCondtion = {
  shaftCdId: string;
};

/** 検索データ */
export type UnitPriceEditSearchData = apiTypeUnitPriceEdit.UnitPriceEditModel;
export type UnitPriceResponse = apiTypeUnitPriceEdit.UnitPriceResponse;

/** 入力チェック */
export type InputCheck = {
  shaftCdId: string;
  unitPriceName: string;
};
