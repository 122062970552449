import * as React from 'react';

import { styled } from '@mui/material/styles';

import { Button, Typography } from 'components/parts/atoms';

const Wrapper = styled('div')(() => ({
  padding: '10px',
  outline: 'solid',
}));

const Sample: React.VFC = () => {
  return (
    <Wrapper>
      <Typography>SampleMolcule</Typography>
      <Button variant='contained' color='secondary'>
        Push me
      </Button>
    </Wrapper>
  );
};

export default Sample;
