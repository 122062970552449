import React, { ReactNodeArray } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/material';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';
import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms';

import { useUnitPriceKyushuMakingRow } from '../../../hooks/price/priceRow/useUnitPriceKyushuMakingRow';
import { UnitPriceFixedField } from '../Fields';

export const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth],
}) => {
  const theme = useTheme();
  return (
    <RowGrid>
      <HeadGrid
        xs={6.6}
        sx={{ backgroundColor: theme.palette.cyclonistDarkOrange.main, justifyContent: 'center' }}
      >
        {first}
      </HeadGrid>
      <BodyGrid xs={1.8}>{second}</BodyGrid>
      <BodyGrid xs={1.8} sx={{ justifyContent: 'end' }}>
        {third}
      </BodyGrid>
      <BodyGrid xs={1.8} sx={{ justifyContent: 'end' }}>
        {fourth}
      </BodyGrid>
    </RowGrid>
  );
};

/** 工程あたり単価 製版代（九州）行コンポーネント */
const UnitPriceKyushuMakingRow: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { toyoPriceRate, grossInvoice } = useUnitPriceKyushuMakingRow();

  return (
    <Row>
      <HeadLabel>製版代(九州)</HeadLabel>

      <Controller
        name={'price.unitPrice.kyushuMaking.price'}
        control={control}
        render={({ field }) => (
          <UnitPriceFixedField
            min={0}
            max={999999}
            decimalDigits={0}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
          />
        )}
      />
      <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
      <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
    </Row>
  );
};

export default React.memo(UnitPriceKyushuMakingRow);
