import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialOrderListState } from 'store/topSales/orderList/defaults';
import { OrderListState } from 'store/topSales/orderList/types';

const slice = createSlice({
  name: 'orderList',
  initialState: InitialOrderListState,
  reducers: {
    save: (state, action: PayloadAction<OrderListState>) => action.payload,
    clear: () => ({ ...InitialOrderListState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
