import { CustomerEditTabIndex } from 'constants/define';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import CustomerEditBasic from './components/tabCustomerEditCheckBasic';
import DeliveryAddressEdit from './components/tabCustomerEditCheckDeliveryAddressEdit';
import CustomerEditPayment from './components/tabCustomerEditCheckPayment';
import UnitPriceMain from './components/tabCustomerEditCheckUnitPrice';
import {
  HeadLabel,
  BodyLabel,
  BackButton,
  BodyGrid,
  HeadGrid,
  Heading,
  RowGrid,
  SaveButton,
} from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules/FooterButtonsContainer';

import { defaultHeader } from '../defaults';
import { CustomerEdit, CustomerEditHeader } from '../types';

type Props = {
  onBackEdit: () => void;
  onSave: () => void;
  // 参照専用画面かどうか(専用の場合は保存ボタンを表示しない)
  isReferenceOnly: boolean;
};

const CustomerEditCheckPage: React.VFC<Props> = (props) => {
  // フォーム情報
  const formContext = useFormContext<CustomerEdit>();

  // タブ
  const [tabValue, setTabValue] = useState<CustomerEditTabIndex>('1');
  const tabChange = (event: React.SyntheticEvent, newValue: CustomerEditTabIndex) => {
    setTabValue(newValue);
  };

  // 初期表示時のヘッダー部分表示
  const [headerParams, setHeaderParams] = useState<CustomerEditHeader>(defaultHeader);
  useEffect(() => {
    const setData: CustomerEditHeader = {
      customerCd: formContext.getValues('customerEditSingleInfo.customerCd'),
      customerName: formContext.getValues('customerEditSingleInfo.customerName'),
      customerSectionCd: formContext.getValues('customerEditSingleInfo.customerSectionCd'),
    };
    setHeaderParams(setData);
  }, []);

  return (
    <>
      <Heading>得意先詳細</Heading>

      <Box m={2} />

      <RowGrid columns={4}>
        <HeadGrid xs={1}>
          <HeadLabel>部門コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <BodyLabel>{headerParams.customerSectionCd}</BodyLabel>
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>得意先コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <BodyLabel>{headerParams.customerCd}</BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={4}>
        <HeadGrid xs={1}>
          <HeadLabel>得意先表示名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <BodyLabel>{headerParams.customerName}</BodyLabel>
        </BodyGrid>
      </RowGrid>

      <Box m={2} />

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1 }}>
          <TabList onChange={tabChange}>
            <Tab label='基本情報' value={CustomerEditTabIndex.Basic}></Tab>
            <Tab label='入金情報' value={CustomerEditTabIndex.Payment}></Tab>
            <Tab label='単価登録' value={CustomerEditTabIndex.UnitPrice}></Tab>
            <Tab label='納入先' value={CustomerEditTabIndex.Delivery}></Tab>
          </TabList>
        </Box>
        <TabPanel value={CustomerEditTabIndex.Basic}>
          <CustomerEditBasic />
        </TabPanel>
        <TabPanel value={CustomerEditTabIndex.Payment}>
          <CustomerEditPayment />
        </TabPanel>
        <TabPanel value={CustomerEditTabIndex.UnitPrice}>
          <UnitPriceMain />
        </TabPanel>
        <TabPanel value={CustomerEditTabIndex.Delivery}>
          <DeliveryAddressEdit />
        </TabPanel>
      </TabContext>

      <Box m={2} />

      <FooterButtonsContainer>
        <BackButton onClick={props.onBackEdit} />
        {props.isReferenceOnly === false && <SaveButton onClick={props.onSave} />}
      </FooterButtonsContainer>
    </>
  );
};

export default CustomerEditCheckPage;
