import * as apiType from 'api/types/customer/customerEdit';

/** 取得モデル */
export type CustomerEdit = apiType.CustomerEditResponse;
export type OriginalIds = apiType.CustomerEditIdsModel;

export type CylinderUnitPrice = apiType.UnitPriceCylinderModel;
export type FlexoUnitPrice = apiType.UnitPriceFlexoModel;
export type OtherUnitPrice = apiType.UnitPriceOtherModel;
export type DeliveryPlaceEdit = apiType.DeliveryPlaceModel;
export type CustomerEditSingleInfosModel = apiType.CustomerEditSingleInfoModel;
export type CustomerEditSelectBoxModel = apiType.CustomerEditSelectBoxModel;

/** ページステータス */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** 得意先IDによる検索条件 */
export type CustomerIdCondition = {
  customerId: number;
};

/** 得意先ヘッダー */
export type CustomerEditHeader = {
  customerSectionCd: string;
  customerCd: string;
  customerName: string;
};

/** 登録・更新送信データ */
export type PostData = {
  inputParams: CustomerEdit;
  duplicateDelFlag: boolean;
};
