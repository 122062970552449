import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialEditAnyTimeModel } from './defaults';
import { EditAnyTimeModel } from './types';

const slice = createSlice({
  name: 'cylinderArrivalEditAnyTime',
  initialState: initialEditAnyTimeModel,
  reducers: {
    save: (state, action: PayloadAction<EditAnyTimeModel>) => action.payload,
    clear: () => ({ ...initialEditAnyTimeModel }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
