import React from 'react';

import { Box } from '@mui/material';

import Approval from './Approval';
import MakingPriceMain from './MakingPriceMain';
import MakingPriceReverse from './MakingPriceReverse';
import OtherImagePrice from './OtherImagePrice';
import PriceHeader from './PriceHeader';
import Remark from './Remark';
import SetPrice from './SetPrice';
import TotalPrice from './TotalPrice';
import UnitPrice from './UnitPrice';
import Url from './Url';

/** フレキソ受注 製版価格ンポーネント */
const Price: React.VFC = () => {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <PriceHeader />
      </Box>

      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box my={6} sx={{ width: '50%' }}>
          <MakingPriceMain />
          <Box my={6} />
          <UnitPrice />
          <Box my={6} />
          <OtherImagePrice />
        </Box>
        <Box mx={2} />
        <Box
          my={6}
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <MakingPriceReverse />
            <Box my={6} />
            <SetPrice />
            <Box my={6} />
          </Box>
          <Box>
            <TotalPrice />
          </Box>
          <Box my={2} />
          <Box>
            <Approval />
          </Box>
        </Box>
      </Box>
      <Box my={2} />
      <Box sx={{ width: '50%' }}>
        <Remark />
      </Box>
      <Box my={2} />
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ width: '50%' }}>
          <Url />
        </Box>
      </Box>
    </>
  );
};

export default Price;
