import React, { ReactNodeArray } from 'react';

import { RowGrid, BodyGrid } from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

/** 受注ヘッダー情報 1列コンポーネント */
export const RowOne: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne],
}) => {
  return (
    <>
      <RowGrid columns={24}>
        <ContentsGrid hxs={3} bxs={21}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

/** 受注ヘッダー情報 2列コンポーネント */
export const RowTwo: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo],
}) => {
  return (
    <>
      <RowGrid columns={24}>
        <ContentsGrid hxs={3} bxs={9}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
        <ContentsGrid hxs={2.5} bxs={9.5}>
          {labelTwo}
          {bodyTwo}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

/** 受注ヘッダー情報 4列コンポーネント */
export const RowFour: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo, labelThree, bodyThree, labelFour, bodyFour],
}) => {
  return (
    <>
      <RowGrid columns={24}>
        <ContentsGrid hxs={3} bxs={3}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
        <ContentsGrid hxs={2.5} bxs={3.5}>
          {labelTwo}
          {bodyTwo}
        </ContentsGrid>
        <ContentsGrid hxs={2.5} bxs={3.5}>
          {labelThree}
          {bodyThree}
        </ContentsGrid>
        <ContentsGrid hxs={2.5} bxs={3.5}>
          {labelFour}
          {bodyFour}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

/** 受注ヘッダーの4列コンポーネント(最終行用) */
export const RowFourFinal: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo, labelThree, bodyThree, bodyFour],
}) => {
  return (
    <>
      <RowGrid columns={24}>
        <ContentsGrid hxs={3} bxs={3}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
        <ContentsGrid hxs={2.5} bxs={3.5}>
          {labelTwo}
          {bodyTwo}
        </ContentsGrid>
        <ContentsGrid hxs={2.5} bxs={3.5}>
          {labelThree}
          {bodyThree}
        </ContentsGrid>
        <BodyGrid xs={6}>{bodyFour}</BodyGrid>
      </RowGrid>
    </>
  );
};
