import { useState, useEffect } from 'react';

import { usePageContext } from './usePageContext';

/** フォームデータセット状態を返すHooks */
export const useFormSetState = (): { hasFormSet: boolean; afterFormSet: boolean } => {
  // フォームデータセット後にレンダリングが走ったらtrueがセットされる
  const [afterFormSet, setAfterFormSet] = useState(false);

  const { hasFormSet } = usePageContext();

  useEffect(() => {
    if (hasFormSet) {
      setAfterFormSet(true);
    }
  }, [hasFormSet]);

  return { hasFormSet, afterFormSet };
};
