import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialBarcodeReadFinishingState } from 'store/topPlating/barcodeReadFinishing/defaults';
import { BarcodeReadFinishingState } from 'store/topPlating/barcodeReadFinishing/types';

const slice = createSlice({
  name: 'barcodeReadFinishing',
  initialState: InitialBarcodeReadFinishingState,
  reducers: {
    save: (state, action: PayloadAction<BarcodeReadFinishingState>) => action.payload,
    clear: () => ({ ...InitialBarcodeReadFinishingState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
