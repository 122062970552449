import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState, useEffect } from 'react';

import {
  FlexoOrderEditForm,
  FlexoOrderEditFormRes,
  FlexoOrderEditMaster,
  FlexoOrderMetaData,
  GetFlexoOrderMetaDataRes,
} from 'api/types/topSales/order/flexoOrderEdit';

/** フレキソ受注詳細ダイアログのHooks */
export const useFlexoOrderEdit = (
  jobId: number | null,
  messageApi: MessageApi,
): {
  form: FlexoOrderEditForm;
  master: FlexoOrderEditMaster;
  oderMetaData: FlexoOrderMetaData;
} | null => {
  // Hooks
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // State
  const [orderData, setOrderData] = useState<{
    form: FlexoOrderEditForm;
    master: FlexoOrderEditMaster;
    oderMetaData: FlexoOrderMetaData;
  } | null>(null);

  /** フレキソ受注データ取得処理 */
  const fetch = async (jobId: number) => {
    const [formResponse, metaDataResponse] = await Promise.all([
      apiClient.get<FlexoOrderEditFormRes>('/api/v1/FlexoOrderEdit/GetFlexoOrderEditForm', {
        params: { jobId },
      }),
      apiClient.get<GetFlexoOrderMetaDataRes>(`/api/v1/FlexoOrder/GetOrderMetaData`, {
        params: { jobId },
      }),
    ]);

    if (formResponse.data.result && metaDataResponse.data.result) {
      setOrderData({
        form: formResponse.data.result.flexoOrderEditForm,
        master: formResponse.data.result.master,
        oderMetaData: metaDataResponse.data.result.orderMetaData,
      });
    }
  };

  useEffect(() => {
    if (jobId !== null) {
      fetch(jobId);
    } else {
      setOrderData(null);
    }
  }, [jobId]);

  return orderData;
};
