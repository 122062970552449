import * as React from 'react';

import { styled } from '@mui/material/styles';

import Typography, { TypographyProps } from './Typography';

export type AttentionLabelProps = {
  sx?: TypographyProps['sx'];
  px?: number;
  py?: number;
  lineThrough?: boolean;
  variant?: TypographyProps['variant'];
};

const AttentionLabel: React.FC<AttentionLabelProps> = ({ children, sx, lineThrough, ...props }) => {
  return (
    <Typography
      {...props}
      variant={'subtitle1'}
      sx={{
        ...sx,
        ...(lineThrough && { textDecoration: 'line-through' }),
      }}
    >
      {children}
    </Typography>
  );
};

const StyledAttentionLabel = styled(AttentionLabel)(({ theme }) => ({
  color: theme.palette.red.main,
}));

export default StyledAttentionLabel;
