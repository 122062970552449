import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FlexoOrderEditForm, FlxUnitPrice } from '../../types';
import { useFlexoOrderEditMaster } from '../useFlexoOrderEditMaster';

/** 価格の計算に必要な要素を返すHooks */
export const usePriceCalculateMatter = (): {
  mainSurfaceArea: number;
  reverseSurfaceArea: number;
  toyoPriceRate: number;
  flxUnitPrice: FlxUnitPrice | null;
  defaultFlxUnitPrice: FlxUnitPrice | null;
} => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  // マスタ
  const { master } = useFlexoOrderEditMaster();

  const [mainSurfaceArea, reverseSurfaceArea, toyoPriceRate, flxUnitPriceId] = useWatch({
    control,
    name: [
      'making.makingSizeMain.plateArea',
      'making.makingSizeReverse.plateArea',
      'price.toyoPriceRate',
      'price.flxUnitPriceId',
    ],
  });

  // フレキソ単価
  const flxUnitPrice: FlxUnitPrice | null = useMemo(
    () => master.flxUnitPriceList.find((e) => e.flxUnitPriceId === flxUnitPriceId) ?? null,
    [flxUnitPriceId],
  );

  const defaultFlxUnitPrice: FlxUnitPrice | null = useMemo(
    () => master.flxUnitPriceList.find((e) => e.flxUnitPriceId === '') ?? null,
    [master.flxUnitPriceList],
  );

  return {
    mainSurfaceArea: mainSurfaceArea ?? 0,
    reverseSurfaceArea: reverseSurfaceArea ?? 0,
    toyoPriceRate: toyoPriceRate ?? 1,
    flxUnitPrice,
    defaultFlxUnitPrice,
  };
};
