import React, { ReactNodeArray } from 'react';

import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms';

/** 合計 行コンポーネント */
export const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third],
}) => {
  return (
    <>
      <RowGrid columns={4}>
        <HeadGrid xs={2}>{first}</HeadGrid>
        <BodyGrid xs={1} sx={{ justifyContent: 'end' }}>
          {second}
        </BodyGrid>
        <BodyGrid xs={1} sx={{ justifyContent: 'end' }}>
          {third}
        </BodyGrid>
      </RowGrid>
    </>
  );
};

/** 合計 ヘッダー行コンポーネント */
export const HeadRow: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third],
}) => {
  return (
    <>
      <RowGrid columns={4}>
        <HeadGrid vertical xs={2}>
          {first}
        </HeadGrid>
        <HeadGrid vertical xs={1}>
          {second}
        </HeadGrid>
        <HeadGrid vertical xs={1}>
          {third}
        </HeadGrid>
      </RowGrid>
    </>
  );
};
