import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import { Box } from '@mui/material';

import { CustomerEditContext } from 'pages/customer/CustomerEdit';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  NumericField,
  KanaField,
  Select,
  SearchButton,
} from 'components/parts/atoms';

import { CylinderEditBasicInfosProps, CylinderForm } from '../../types';

const CylinderBasicInfos: React.VFC<CylinderEditBasicInfosProps> = (props) => {
  // フォームデータ
  const master = useContext(CustomerEditContext).selectBoxData;
  const unitPriceCylinderFormContext = useFormContext<CylinderForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            基本情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>単価種別</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='shaftCdId'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <>
                <BodyLabel>シャフト</BodyLabel>
                <Box m={1} />
                <Select
                  options={master.shafts}
                  onChange={field.onChange}
                  value={field.value}
                  sx={{ minWidth: '5rem' }}
                />
                {props.newCreateFlag === true && (
                  <>
                    <Box m={1} />
                    <SearchButton onClick={props.onSearch}></SearchButton>
                  </>
                )}
              </>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>単価名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='unitPriceName'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={25}
                sx={{ minWidth: '35rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>説明</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='unitPriceExplanation'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={180}
                multiline
                rows={5}
                sx={{ minWidth: '40rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>東洋売価率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='toyoPriceRate'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={9.999}
                decimalDigits={3}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>**製版代計算区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='cylMakingCalcType'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default CylinderBasicInfos;
