import * as React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Paper, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Typography, TypographyProps, Button } from 'components/parts/atoms';

export type HeadingProps = {
  children: string;
  onClose: () => void;
  variant?: TypographyProps['variant'];
  backgroundColor?: string;
  textColor?: string;
  buttonText?: string;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const CloseHeading: React.FC<HeadingProps> = ({ variant: typoVariant = 'h3', ...props }) => {
  const theme = useTheme();
  const backgroundColor = props.backgroundColor ?? theme.palette.primary.main;
  const textColor = props.textColor ?? theme.palette.primary.contrastText;
  const buttonText = props.buttonText ?? 'Close';

  return (
    <StyledPaper color={'primary'} sx={{ backgroundColor: backgroundColor, flexGrow: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography p={theme.spacing(1)} variant={typoVariant} sx={{ color: textColor }}>
          {props.children}
        </Typography>
        <Button
          variant='text'
          color={'reversal'}
          startIcon={<CancelIcon />}
          onClick={() => props.onClose()}
        >
          {buttonText}
        </Button>
      </Box>
    </StyledPaper>
  );
};

export default CloseHeading;
