import useApiClient from 'hooks/useApiClient';
import useMessage, { MessageApi } from 'hooks/useMessage';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState } from 'react';

import { ToyoRateResponse, UnitPriceResponse } from 'api/types/customer/unitPriceEdit';

import { defaultSearchData } from '../defaults';
import { CustomerSectionCdCondition, ShaftCdIdCondtion } from '../types';

/** Hooks */
export const useFetchUnitPrice = (): {
  messageApi: MessageApi;
  loading: boolean;
  toyoRate: number;
  setToyoRate: (toyoRate: number) => void;
  searchData: UnitPriceResponse;
  getToyoRate: (customerSectionCd: string, sectionPeculiarInfo: string) => void;
  getUnitPriceEditSearchData: (shaftCdId: string) => void;
} => {
  // メッセージ
  const messageApi = useMessage(); //useSharedMessage();
  const { apiClient, loading } = useApiClient(messageApi.pushMessages);

  //ステート
  const [toyoRate, setToyoRate] = useState<number>(0);
  const [searchData, setSearchData] = useState<UnitPriceResponse>(defaultSearchData);

  // 東洋売価率取得
  const getToyoRate = (customerSectionCd: string, sectionPeculiarInfo: string) => {
    (async () => {
      try {
        setToyoRate(0);
        const condition: CustomerSectionCdCondition = {
          customerSectionCd: customerSectionCd,
          sectionPeculiarInfo: sectionPeculiarInfo,
        };
        const [allresults] = await Promise.all([
          apiClient.get<ToyoRateResponse>(`/api/v1/UnitPriceEdit/GetToyoRate`, {
            params: condition,
          }),
        ]);
        if (allresults.data.result?.toyoRate) {
          setToyoRate(allresults.data.result.toyoRate);
        }
      } catch (e) {
        console.log(e);
      }
    })();
    return false;
  };

  // シャフトコードによる検索
  const getUnitPriceEditSearchData = (shaftCdId: string): void => {
    (async () => {
      try {
        const condition: ShaftCdIdCondtion = {
          shaftCdId: shaftCdId,
        };
        const [allresults] = await Promise.all([
          apiClient.get<UnitPriceResponse>(`/api/v1/UnitPriceEdit/GetUnitPriceEdit`, {
            params: condition,
          }),
        ]);

        if (allresults.data.result) {
          setSearchData(allresults.data.result);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  return {
    messageApi,
    loading,
    toyoRate,
    setToyoRate,
    searchData,
    getToyoRate,
    getUnitPriceEditSearchData,
  };
};

export default useFetchUnitPrice;
