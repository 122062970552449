import { ConnectedRouter } from 'connected-react-router';
import { MessagesProvider } from 'contexts/MessageContext';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material';

import { store, persistor, history } from './store';
import theme from './themes/theme';
import {HelmetProvider } from 'react-helmet-async';

const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MessagesProvider>
                <HelmetProvider>{children}</HelmetProvider>
              </MessagesProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default Providers;
