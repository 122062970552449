import * as apiType from 'api/types/topImage/imageWorkEditPhotoRetouch';

export type ImageWorkEditPhotoRetouchMasterResponse =
  apiType.ImageWorkEditPhotoRetouchMasterResponse;
export type ImageWorkEditPhotoRetouchDataResponse = apiType.ImageWorkEditPhotoRetouchDataResponse;
export type ImageWorkEditPhotoRetouchWorkUpdateRequest =
  apiType.ImageWorkEditPhotoRetouchWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

/** ダウンロード入力フォーム */
export type DownloadForm = {
  design: boolean;
  photoRetouch: boolean;
  colorSeparation: boolean;
  layout: boolean;
  final: boolean;
  send: boolean;
};

/** アップロード入力フォーム */
export type UploadForm = {
  final: boolean;
  send: boolean;
};

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
