import React, { ReactNodeArray } from 'react';

import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms';

/** 工程あたり単価 行コンポーネント */
export const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth, sixth],
}) => {
  return (
    <RowGrid>
      <HeadGrid xs={2.5}>{first}</HeadGrid>
      <BodyGrid xs={2.3} sx={{ justifyContent: 'end' }}>
        {second}
      </BodyGrid>
      <BodyGrid xs={1.8} sx={{ justifyContent: 'end' }}>
        {third}
      </BodyGrid>
      <BodyGrid xs={1.8} sx={{ justifyContent: 'end' }}>
        {fourth}
      </BodyGrid>
      <BodyGrid xs={1.8} sx={{ justifyContent: 'end' }}>
        {fifth}
      </BodyGrid>
      <BodyGrid xs={1.8} sx={{ justifyContent: 'end' }}>
        {sixth}
      </BodyGrid>
    </RowGrid>
  );
};

/** 工程あたり単価 ヘッダー行コンポーネント */
export const HeadRow: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth, sixth],
}) => {
  return (
    <RowGrid>
      <HeadGrid vertical xs={2.5}>
        {first}
      </HeadGrid>
      <HeadGrid vertical xs={2.3}>
        {second}
      </HeadGrid>
      <HeadGrid vertical xs={1.8}>
        {third}
      </HeadGrid>
      <HeadGrid vertical xs={1.8}>
        {fourth}
      </HeadGrid>
      <HeadGrid vertical xs={1.8}>
        {fifth}
      </HeadGrid>
      <HeadGrid vertical xs={1.8}>
        {sixth}
      </HeadGrid>
    </RowGrid>
  );
};
