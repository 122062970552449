import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { styled } from '@mui/material';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import {
  RowGrid,
  HeadGrid,
  BodyGrid as BodyGridOrigin,
  HeadLabel,
  BodyLabel,
  BodyLink,
} from 'components/parts/atoms';

import { usePageContext } from '../../hooks/usePageContext';

const BodyGrid = styled(BodyGridOrigin)(() => ({
  justifyContent: 'center',
}));

/** 画像 参照テンプレートコンポーネント */
const GaReferenceTemplates: React.VFC = () => {
  const { control } = useFormContext<CylinderOrderEditForm>();
  const { leavePage } = usePageContext();

  const { fields } = useFieldArray({
    control: control,
    name: 'making.gaReferenceTemplates',
  });

  /**
   * テンプレートNoリンククリック時
   */
  const onClickTemplateNoLink = useCallback((referenceTemplateNo: string) => {
    // テンプレート/素材 詳細 画面へ遷移
    leavePage('/TemplateImageDetail/' + referenceTemplateNo);
  }, []);

  return (
    <>
      <RowGrid columns={12}>
        <HeadGrid vertical xs={4}>
          <HeadLabel>{'参照テンプレートNo.'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={8}>
          <HeadLabel>{'コメント'}</HeadLabel>
        </HeadGrid>
      </RowGrid>
      {fields.map((item) => {
        return (
          <RowGrid columns={12} key={item.id}>
            <BodyGrid xs={4}>
              {item.linkFlg ? (
                <BodyLink onClick={() => onClickTemplateNoLink(item.referenceTemplateNo)}>
                  {item.referenceTemplateNo}
                </BodyLink>
              ) : (
                <BodyLabel>{item.referenceTemplateNo}</BodyLabel>
              )}
            </BodyGrid>
            <BodyGrid xs={8}>
              <BodyLabel sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {item.note}
              </BodyLabel>
            </BodyGrid>
          </RowGrid>
        );
      })}
    </>
  );
};

export default React.memo(GaReferenceTemplates);
