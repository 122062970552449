import React, { useState } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';

import DeliveryAddressEditMainPage from 'pages/customer/DeliveryAddressEdit';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
} from 'components/parts/atoms';

import { defaultDeliveryPlaceEdit } from '../../defaults';
import { CustomerEdit, DeliveryPlaceEdit } from '../../types';

// 単価入力画面
const DeliveryAddressEdit: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();

  // 画面ステート
  const [indexState, setIndexState] = useState<number>(-1);
  const [deliveryPlaceEditState] =
    useState<DeliveryPlaceEdit>(defaultDeliveryPlaceEdit);

  // 納入先情報配列
  const deliveryPlaceArray = useFieldArray({
    control: formContext.control,
    name: 'deliveryPlace',
  });

  /** 納入先ダイアログ */
  const [openDeliveryPlace, setOpenDeliveryPlace] = React.useState(false);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <DeliveryAddressEditMainPage
          open={openDeliveryPlace}
          onClose={(value) => {
            setOpenDeliveryPlace(false);
            if (value) {
              // 編集の為フィールドArrayを更新する
              if (indexState !== -1) {
                deliveryPlaceArray.update(indexState, value);
              }
              // 新規登録の為Arrayに追加
              else {
                deliveryPlaceArray.append(value);
              }
            }
            setIndexState(-1);
          }}
          index={indexState}
          state={deliveryPlaceEditState}
          checkFinishFlag={true}
        />
      </>
    );
  };

  return (
    <>
      <RowGrid columns={10}>
        <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
          <HeadLabel>帳票送付先</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>納入先名</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
          <HeadLabel>郵便番号</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
          <HeadLabel>都道府県</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
          <HeadLabel>市区町村</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
          <HeadLabel>丁目・番地</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>ビル名</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
          <HeadLabel>電話番号</HeadLabel>
        </HeadGrid>
      </RowGrid>

      {deliveryPlaceArray.fields.map((item, index) => {
        return (
          <div key={item.id}>
            <RowGrid columns={10}>
              <BodyGrid xs={1}>
                <Controller
                  name={`deliveryPlace.${index}.souhusakiFlg`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value === true ? '●' : ''}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={2}>
                <Controller
                  name={`deliveryPlace.${index}.deliveryPlaceName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={1}>
                <Controller
                  name={`deliveryPlace.${index}.zipCd`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={1}>
                <Controller
                  name={`deliveryPlace.${index}.prefecturesName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={1}>
                <Controller
                  name={`deliveryPlace.${index}.address1`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={1}>
                <Controller
                  name={`deliveryPlace.${index}.address2`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={2}>
                <Controller
                  name={`deliveryPlace.${index}.buildingName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={1}>
                <Controller
                  name={`deliveryPlace.${index}.tel`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
            </RowGrid>
          </div>
        );
      })}
      {renderDialogs()}
    </>
  );
};

export default DeliveryAddressEdit;
