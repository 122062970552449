import useMessage from 'hooks/useMessage';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import usePriceApprovalEdit from 'pages/priceApproval/PriceApprovalList/hooks/usePriceApprovalEdit';
import CylinderOrderEditForm from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/components/CylinderOrderEditForm';
import { CylinderOrderEditMasterContext } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/contexts/CylinderOrderEditMasterContext';
import { PageContext } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/contexts/PageContext';
import { initialCylinderOrderEdit } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/defaults';
import {
  CylinderOrderEditForm as CylinderOrderEditFormType,
  OrderEditTabIndex,
  PageState,
} from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';
import { useCylinderOrderEdit } from 'pages/topSales/order/CylinderOrder/CylinderOrderEditDialog/hooks/useCylinderOrderEdit';

import MessageListener from 'components/Message/MessageListener';
import { Button, Circular, CloseButton, Heading, Typography } from 'components/parts/atoms';
import { NewWindow } from 'components/parts/atoms/NewWindow';
import { Dialog, FooterButtonsContainer } from 'components/parts/molecules';

type Props = {
  open: boolean;
  isWindow?: boolean;
  showStatus: string;
  onClose: () => void;
  onUpdated: (jobId: number | null) => void;
  jobId: number | null;
};

/** シリンダー受注詳細ダイアログ */
const CylinderOrderEditDialog: React.VFC<Props> = (props) => {
  const messageApi = useMessage();
  const history = useHistory();

  /** RHFオブジェクト */
  const formMethod = useForm<CylinderOrderEditFormType>({
    defaultValues: initialCylinderOrderEdit,
  });

  // フォームデータセット済みフラグ
  const [hasFormSet, setHasFormSet] = useState(false);
  /** シリンダー受注データ */
  const orderData = useCylinderOrderEdit(props.jobId, messageApi);

  // isOpenWindowがtrueの場合は別Windowで表示する(左記以外はDialog)
  const [isOpenWindow, setOpenWindow] = useState(false);

  // フォームデータをセット
  useEffect(() => {
    console.log(orderData);
    if (orderData?.form) {
      formMethod.reset(orderData.form);
      setHasFormSet(true);
    } else {
      formMethod.reset(initialCylinderOrderEdit);
      setHasFormSet(false);
    }
  }, [orderData?.form]);

  useEffect(() => {
    console.log('showStatus: ' + props.showStatus);
    setOpenWindow(props.isWindow ?? false);
  }, [props.isWindow]);

  const [tabIndex, setTabIndex] = useState<OrderEditTabIndex>(OrderEditTabIndex.Price);
  /** 承認フラグ更新アクション */
  const { updatePriceApproval } = usePriceApprovalEdit(
    props.jobId,
    orderData?.form.price.approvalFlg,
    orderData?.form.price.secondApprovalFlg,
    props.showStatus,
    messageApi,
  );

  const onClose = () => {
    formMethod.reset(initialCylinderOrderEdit);
    setHasFormSet(false);
    props.onClose();
  };

  /**
   * 承認ボタン押下時
   * 承認フラグ更新用アクション
   */
  const approvalAction = async () => {
    await updatePriceApproval();
    await props.onUpdated(props.jobId);
    props.onClose();
  };

  /** 別画面に遷移する（参照JOBNoクリック時など） */
  const leavePage = (location: string) => {
    history.push(location);
  };
  // #endregion

  if (!orderData) {
    return <Circular />;
  }

  // ダイアログ(Window)のBoby部
  const cylinderBody = (): JSX.Element => {
    return (
      <>
        <PageContext.Provider
          value={{
            pageState: PageState.Check,
            tabIndex,
            setTabIndex,
            hasFormSet,
            leavePage,
            orderMetaData: orderData.oderMetaData,
          }}
        >
          <CylinderOrderEditMasterContext.Provider value={orderData.master}>
            <FormProvider {...formMethod}>
              <Container maxWidth={'md'}>
                <Box sx={{ width: '100%' }}>
                  {/* フォーム */}
                  <CylinderOrderEditForm />

                  {/* フッターボタン */}
                  {props.showStatus !== 'Show' && (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {props.showStatus === 'Cancel'
                          ? '承認を取り消しますか？'
                          : '承認を行いますか？'}
                      </Typography>
                      <FooterButtonsContainer>
                        <CloseButton onClick={props.onClose} />
                        <Button onClick={() => approvalAction()}>OK</Button>
                      </FooterButtonsContainer>
                    </Box>
                  )}
                </Box>
              </Container>
            </FormProvider>
          </CylinderOrderEditMasterContext.Provider>
        </PageContext.Provider>
      </>
    );
  };

  /** 受注/見積の文字を受注のタイプに合わせて取得 */
  const orderTypeName = orderData?.oderMetaData.isEstimate ? '見積' : '受注';
  /** 画面タイトル */
  const title = `シリンダー${orderTypeName}詳細`;

  return (
    <>
      {!isOpenWindow && (
        <>
          <Dialog
            open={props.open}
            title={title}
            onClose={onClose}
            messageApi={messageApi}
            maxWidth={'lg'}
          >
            {cylinderBody()}
          </Dialog>
        </>
      )}

      {props.open && isOpenWindow && (
        <>
          <NewWindow features={{ height: 720, width: 1280 }} onUnload={props.onClose}>
            <Heading>{title}</Heading>
            <Box sx={{ m: 1 }}>
              <MessageListener messageApi={messageApi} />
            </Box>
            <Box m={4} />
            {cylinderBody()}
          </NewWindow>
        </>
      )}
    </>
  );
};

export default CylinderOrderEditDialog;
