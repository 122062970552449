import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

const FooterButtonsContainer: React.VFC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box
      p={5}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '50%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FooterButtonsContainer;
