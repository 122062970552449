import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BodyLabel, HeadLabel } from 'components/parts/atoms';

import { useSetPriceRow } from '../../../hooks/price/priceRow/useSetPriceRow';
import { CylinderOrderEditForm } from '../../../types';
import { SetPriceField } from '../Fields';
import { Row } from './Row';

type Props = {
  propertyName: 'cylinderMakingsMain' | 'cylinderMakingsReverse';
  index: number;
};

/** 一式計算 行コンポーネント */
const SetPriceRow: React.VFC<Props> = ({ propertyName, index }) => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { printSequence, price, grossInvoice } = useSetPriceRow(propertyName, index);

  return (
    <>
      <Row>
        <HeadLabel>{printSequence}</HeadLabel>
        <Controller
          name={`making.${propertyName}.${index}.completeSetCalcUnitPrice`}
          control={control}
          render={({ field }) => (
            <SetPriceField
              min={0}
              max={9999999}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '6em' }}
            />
          )}
        />
        <BodyLabel>{price.toFixed()}</BodyLabel>
        <Controller
          name={`making.${propertyName}.${index}.completeSetCalcToyoPriceRate`}
          control={control}
          render={({ field }) => (
            <SetPriceField
              min={0}
              max={99.999}
              decimalDigits={3}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '5em' }}
            />
          )}
        />
        <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
      </Row>
    </>
  );
};

export default React.memo(SetPriceRow);
