import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useState, useMemo } from 'react';

import { ApiClient } from 'api/apiClient';
import { ResponseMessage, ApiResponse } from 'api/types/common/apiResponse';

const useApiClient = (
  messageHandler: (messages: ResponseMessage[]) => void = () => undefined,
): { apiClient: ApiClient; loading: boolean } => {
  const [apiClient] = useState<ApiClient>(new ApiClient());
  const [counter, setCounter] = useState(0);

  const interceptors = useMemo(() => {
    const inc = () => setCounter((counter) => counter + 1);
    const dec = () => setCounter((counter) => counter - 1);

    const handleMessages = (messages: ResponseMessage[] | null) => {
      messageHandler(messages ?? []);
    };

    return {
      request: (config: AxiosRequestConfig) => {
        inc();
        return config;
      },
      response: (response: AxiosResponse<ApiResponse<unknown>>) => {
        dec();
        handleMessages(response?.data?.messages);
        return response;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any) => {
        dec();
        if (Axios.isAxiosError(error)) {
          // TODO:ステータスコードを定数に
          console.log(error.response);
          handleMessages(error.response?.data?.messages);
          console.log(error.response?.data?.messages);
          switch (error.response?.status) {
            case 400:
              // 業務エラー
              break;
            case 403:
              // セキュリティエラー
              break;
            default:
              break;
          }
        }
        return Promise.reject(error);
      },
    };
  }, []);

  useEffect(() => {
    const reqInterceptor = apiClient.axios.interceptors.request.use(
      interceptors.request,
      interceptors.error,
    );
    const resInterceptor = apiClient.axios.interceptors.response.use(
      interceptors.response,
      interceptors.error,
    );
    return () => {
      // UnMount時にinterceptorsを外す
      apiClient.axios.interceptors.request.eject(reqInterceptor);
      apiClient.axios.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  const loading = counter > 0;
  return { apiClient, loading };
};

export default useApiClient;
