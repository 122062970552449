import { useFormContext, useWatch } from 'react-hook-form';

import { CylinderOrderEditForm, UnitPriceCylMakingRowValues } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { PriceTable, usePriceCalculater } from '../usePriceCalculater';
import { useMinimumPrice } from './useMinimumPrice';
import { useUnitPriceKyushuMakingRow } from './useUnitPriceKyushuMakingRow';

/** 工程あたり単価 - 製版代 行情報 Hooks */
export const useUnitPriceCylMakingRow = (): UnitPriceCylMakingRowValues => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const row = useWatch({ control, name: 'price.unitPrice.cylMaking' });
  const kyushuRow = useUnitPriceKyushuMakingRow();

  const {
    cylSizeWidth,
    cylSizeEnsyuu,
    gentanWidth,
    surfaceArea,
    toyoPriceRate,
    circleRate,
    cylUnitPrice,
  } = usePriceCalculateMatter();

  const {
    calcUnitPriceCylMaking,
    calcUnitPriceCylMakingUnRounded,
    calcUnitPriceCylMakingByWidth,
    calcUnitPriceCylMakingBySurfaceArea,
    calcGrossInvoice,
  } = usePriceCalculater();

  // 九州専用計算hook
  const { applyMinimumPrice } = useMinimumPrice('cylMakingMinimum9');

  /** 売価 */
  let price = 0;
  let unRoundedPrice = 0;
  if (kyushuRow.price) {
    // 製版代（九州）に値が入力されていればそれを使用する
    price = kyushuRow.price;
    unRoundedPrice = price;
  } else {
    // 製版代計算区分別で計算を行う
    // 通常は'0'
    // それ以外は九州専用計算
    switch (cylUnitPrice?.cylMakingCalcType) {
      case 0:
        {
          price = calcUnitPriceCylMaking(row.unitPrice, cylSizeWidth, circleRate);
          unRoundedPrice = calcUnitPriceCylMakingUnRounded(row.unitPrice, cylSizeWidth, circleRate);
        }
        break;
      case 90:
        {
          // シート
          const priceTable: PriceTable = {
            steps: [[600, 500]],
            priceWhenOver: 1000,
          };
          price = calcUnitPriceCylMakingByWidth(
            row.unitPrice,
            cylSizeWidth,
            cylSizeEnsyuu,
            priceTable,
          );

          // 最低価格を適用
          price = applyMinimumPrice(price);
          unRoundedPrice = price;
        }
        break;

      case 91:
        // 製版チューブ区分:1
        {
          const priceTable: PriceTable = {
            steps: [
              [1000, 6000],
              [1250, 6500],
              [1500, 7000],
            ],
            priceWhenOver: 8000,
            overedStep: [500, 1000],
          };
          price = calcUnitPriceCylMakingBySurfaceArea(surfaceArea, priceTable);
          unRoundedPrice = price;
        }
        break;

      case 94:
        {
          // 製版チューブ区分:4
          const priceTable: PriceTable = {
            steps: [[600, 500]],
            priceWhenOver: 1000,
          };
          price = calcUnitPriceCylMakingByWidth(
            row.unitPrice,
            gentanWidth,
            cylSizeEnsyuu,
            priceTable,
          );

          // 最低価格を適用
          price = applyMinimumPrice(price);
          unRoundedPrice = price;
        }
        break;

      case 95:
        {
          // 製版チューブ区分:0（95）
          const priceTable: PriceTable = {
            steps: [],
            priceWhenOver: 0,
          };
          price = calcUnitPriceCylMakingByWidth(
            row.unitPrice,
            cylSizeWidth,
            cylSizeEnsyuu,
            priceTable,
          );

          // 最低価格を適用
          price = applyMinimumPrice(price);
          unRoundedPrice = price;
        }
        break;

      case 11:
        // 製版チューブ区分:11
        {
          const priceTable: PriceTable = {
            steps: [
              [1000, 7000],
              [1250, 7500],
              [1500, 8000],
            ],
            priceWhenOver: 9000,
            overedStep: [500, 1000],
          };
          price = calcUnitPriceCylMakingBySurfaceArea(surfaceArea, priceTable);
          unRoundedPrice = price;
        }
        break;

      case 12:
        // 製版チューブ区分:12
        {
          const priceTable: PriceTable = {
            steps: [
              [1000, 7200],
              [1250, 7700],
              [1500, 8200],
            ],
            priceWhenOver: 9200,
            overedStep: [500, 1000],
          };
          price = calcUnitPriceCylMakingBySurfaceArea(surfaceArea, priceTable);
          unRoundedPrice = price;
        }
        break;

      case 13:
        // 製版チューブ区分:13
        {
          const priceTable: PriceTable = {
            steps: [
              [1000, 7500],
              [1250, 8000],
              [1500, 8500],
            ],
            priceWhenOver: 9500,
            overedStep: [500, 1000],
          };
          price = calcUnitPriceCylMakingBySurfaceArea(surfaceArea, priceTable);
          unRoundedPrice = price;
        }
        break;

      default:
        {
          price = calcUnitPriceCylMaking(row.unitPrice, cylSizeWidth, circleRate);
          unRoundedPrice = calcUnitPriceCylMakingUnRounded(row.unitPrice, cylSizeWidth, circleRate);
        }
        break;
    }
  }

  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate);

  return {
    unitPrice: row.unitPrice ?? 0,
    circleRate,
    price,
    toyoPriceRate,
    grossInvoice,
    unRoundedPrice,
  };
};
