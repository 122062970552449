import * as apiType from 'api/types/productionManage/productionEdit';

/* プロダクション編集・確認モデル **/
export type ProductionEditModel = apiType.ProductionEditModel;
export type ProductionEditResponse = apiType.ProductionEditResponse;

/* プロダクションIDでの初期表示用検索条件 **/
export type ProductionEditCodition = {
  productionId: number;
};

/* 入力チェック用 **/
export type InputCheck = {
  productionName: string;
  productionNameHankakukana: string;
};

/* 入力チェック用 **/
export type RegistData = {
  productionId: number;
  productionName: string;
  productionNameHankakukana: string;
};

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
