import * as React from 'react';
import theme from 'themes/theme';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/parts/atoms';
import { RowGrid, BodyGrid, HeadGrid } from 'components/parts/atoms/Grid';

import { WorkEndStatusCheckProps } from './types';

/**
 * ラッパースタイル
 */
const Wrapper = styled(Box)(({ theme }) => ({
  pt: theme.spacing(2),
}));

/**
 * ヘッダースタイル
 */
const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.cyclonistGreen.main,
  color: theme.palette.primary.contrastText,
  width: '100%',
}));

/**
 * 作業終了状態チェックコンポーネント
 * @returns
 */
const WorkEndStatusCheck: React.VFC<WorkEndStatusCheckProps> = ({
  workEndStatusText,
}): JSX.Element => {
  return (
    <Wrapper>
      {/* 作業終了状態ヘッダー */}
      <RowGrid columns={1} sx={{ mt: theme.spacing(2), boxSizing: 'border-box' }}>
        <HeadGrid xs={1} sx={{ p: 0 }}>
          <Header>
            <Typography>作業終了状態</Typography>
          </Header>
        </HeadGrid>
      </RowGrid>

      {/* 作業終了状態ボディ */}
      <RowGrid columns={1}>
        <BodyGrid xs={2}>{workEndStatusText ?? ''}</BodyGrid>
      </RowGrid>
    </Wrapper>
  );
};

export default WorkEndStatusCheck;
