import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialUnappropriateListState } from 'store/unappropriateList/unappropriateList/defaults';
import { UnappropriateListState } from 'store/unappropriateList/unappropriateList/types';

const slice = createSlice({
  name: 'unappropriateList',
  initialState: InitialUnappropriateListState,
  reducers: {
    save: (state, action: PayloadAction<UnappropriateListState>) => action.payload,
    clear: () => ({ ...InitialUnappropriateListState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
