import { useEffect } from 'react';

import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { useResetPrice } from '../useResetPrice';

/** シリンダ単価変更時にRHFに値をセットするHooks */
export const usePriceRateWatcher = (enabled: boolean): void => {
  const { flxUnitPrice } = usePriceCalculateMatter();
  const { resetPriceRate } = useResetPrice();

  // シリンダ単価が変更されたらフォームにセットする。
  useEffect(() => {
    if (!enabled) return;
    resetPriceRate(flxUnitPrice);
  }, [flxUnitPrice]);
};
