import { EmployeeModel, MenuListResponse, SearchForm, FormModel } from './types';

export const defaultEmployeeModel: EmployeeModel = {
  employeeId: null,
  employeeNo: '',
  fullName: '',
  loginUserId: '',
  userHomeDirPath: null,
};

/** 検索条件値格納 初期値 */
export const defaultMenuListResponse: MenuListResponse = {
  employee: defaultEmployeeModel,
  menuList: [],
};

/** 検索条件値格納 初期値 */
export const defaultSearchForm: SearchForm = {
  employeeNo: '',
};

/** 得意先編集初期値 */
export const defaultFormModel: FormModel = {
  SearchForm: defaultSearchForm,
  MenuListResponse: defaultMenuListResponse,
};
