import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

import { save, clear } from '.';
import { CustomerListState } from './types';

/** 得意先一覧StoreのHooks */
export const useCustomerListStore = (): {
  state: CustomerListState;
  save: (state: CustomerListState) => void;
  clear: () => void;
} => {
  /** 得意先一覧のState */
  const state = useSelector((state: RootState) => state.customerListReducer);

  const dispatch = useDispatch();

  /** 得意先一覧Stateに登録 */
  const dispatchSave = useCallback(
    (state: CustomerListState) => {
      dispatch(save(state));
    },
    [dispatch],
  );

  /** 得意先一覧Stateを初期化 */
  const dispatchClear = useCallback(() => {
    dispatch(clear());
  }, []);

  return { state, save: dispatchSave, clear: dispatchClear };
};
