import { CalculateMethodCd } from 'constants/define';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FlexoOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { useResetPrice } from '../useResetPrice';

/** 計算方法変更時にフォームをリセットするHooks */
export const useCalculateMethodWatcher = (enabled: boolean): void => {
  // フォーム
  const { control, getValues, setValue } = useFormContext<FlexoOrderEditForm>();

  const [calculateMethodCd] = useWatch({ control, name: ['price.calculateMethodCd'] });
  const { defaultFlxUnitPrice } = usePriceCalculateMatter();
  const defaultToyoPriceRate = defaultFlxUnitPrice?.toyoPriceRate ?? null;

  const { resetPrices } = useResetPrice();

  useEffect(() => {
    if (!enabled) return;

    switch (calculateMethodCd) {
      // 単価計算(手入力)
      case CalculateMethodCd.Unit:
        {
          const [flexoMakingsMain, flexoMakingsReverse] = getValues([
            'making.flexoMakingsMain',
            'making.flexoMakingsReverse',
          ]);
          flexoMakingsMain.forEach((_, index) => {
            setValue(`making.flexoMakingsMain.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.flexoMakingsMain.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
          flexoMakingsReverse.forEach((_, index) => {
            setValue(`making.flexoMakingsReverse.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.flexoMakingsReverse.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
        }
        break;

      // 単価計算
      case CalculateMethodCd.FixedUnit:
        {
          const [flexoMakingsMain, flexoMakingsReverse] = getValues([
            'making.flexoMakingsMain',
            'making.flexoMakingsReverse',
          ]);
          flexoMakingsMain.forEach((_, index) => {
            setValue(`making.flexoMakingsMain.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.flexoMakingsMain.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
          flexoMakingsReverse.forEach((_, index) => {
            setValue(`making.flexoMakingsReverse.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.flexoMakingsReverse.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
          resetPrices(defaultFlxUnitPrice);
        }
        break;

      // 一式計算
      case CalculateMethodCd.Set:
        {
          // 印刷面(メイン)計算
          setValue('price.makingPriceMain.betaHan.unitPrice', 0);
          setValue('price.makingPriceMain.halfTone.unitPrice', 0);
          setValue('price.makingPriceMain.barrier.unitPrice', 0);
          setValue('price.makingPriceMain.negaFilm.unitPrice', 0);
          setValue('price.makingPriceMain.posi.unitPrice', 0);
          setValue('price.makingPriceMain.singleFaceNega.unitPrice', 0);
          setValue('price.makingPriceMain.composer.unitPrice', 0);
          setValue('price.makingPriceMain.matNega.unitPrice', 0);
          setValue('price.makingPriceMain.plate.unitPrice', 0);

          // 印刷面(リバース)計算
          setValue('price.makingPriceReverse.betaHan.unitPrice', 0);
          setValue('price.makingPriceReverse.halfTone.unitPrice', 0);
          setValue('price.makingPriceReverse.barrier.unitPrice', 0);
          setValue('price.makingPriceReverse.negaFilm.unitPrice', 0);
          setValue('price.makingPriceReverse.posi.unitPrice', 0);
          setValue('price.makingPriceReverse.singleFaceNega.unitPrice', 0);
          setValue('price.makingPriceReverse.composer.unitPrice', 0);
          setValue('price.makingPriceReverse.matNega.unitPrice', 0);
          setValue('price.makingPriceReverse.plate.unitPrice', 0);

          // 単価計算
          setValue('price.unitPrice.dataMake.unitPrice', 0);
          setValue('price.unitPrice.dataEdit.unitPrice', 0);
          setValue('price.unitPrice.layoutData.unitPrice', 0);
          setValue('price.unitPrice.colorProcess.unitPrice', 0);
          setValue('price.unitPrice.photoRetouch.unitPrice', 0);
          setValue('price.unitPrice.blockCopy.unitPrice', 0);
          setValue('price.unitPrice.fare.unitPrice', 0);

          // シリンダ単価を未選択にセット
          setValue('price.flxUnitPriceId', '');

          // 一式計算の東洋売価率セット
          const [flexoMakingsMain, flexoMakingsReverse] = getValues([
            'making.flexoMakingsMain',
            'making.flexoMakingsReverse',
          ]);
          flexoMakingsMain.forEach((_, index) => {
            setValue(`making.flexoMakingsMain.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.flexoMakingsMain.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
          flexoMakingsReverse.forEach((_, index) => {
            setValue(`making.flexoMakingsReverse.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.flexoMakingsReverse.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
        }
        break;
    }
  }, [calculateMethodCd]);
};
