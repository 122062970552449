import { CustomerListState } from './types';

/* 得意先一覧のstore初期値 **/
export const defaultCustomerListStore: CustomerListState = {
  searchRange: 1,
  customerSectionCd: '',
  customerCd: '',
  customerName: '',
  deleteFlag: false,
};
