import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { HeadLabel } from 'components/parts/atoms';

import { CylinderOrderEditForm } from '../../../types';
import { EditKanaField } from '../../Fields';
import { Row } from './Row';

/** 価格情報の備考コンポーネント */
const Remark: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const formContext = useFormContext<CylinderOrderEditForm>();
  const displayFlg = !formContext.getValues('price.customerUserFlg');

  return (
    <>
      <Row>
        <HeadLabel>見積書備考</HeadLabel>
        <Controller
          name='price.estimateNote'
          control={control}
          render={({ field }) => (
            <EditKanaField
              maxLength={80}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '100%' }}
            />
          )}
        />
      </Row>
      <Row>
        <HeadLabel>納品書備考</HeadLabel>
        <Controller
          name='price.statementOfDeliveryNote'
          control={control}
          render={({ field }) => (
            <EditKanaField
              maxLength={80}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '100%' }}
            />
          )}
        />
      </Row>
      {displayFlg ? (
        <Row>
          <HeadLabel>価格備考</HeadLabel>
          <Controller
            name='price.priceNote'
            control={control}
            render={({ field }) => (
              <EditKanaField
                multiline
                maxLength={2000}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                rows={5}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Row>
      ) : null}
    </>
  );
};

export default React.memo(Remark);
