import * as React from 'react';

import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';

import { MenuItem } from '..';
import { SelectOption } from './types';

export type SelectProps = Omit<MuiSelectProps, 'children'> & {
  options: SelectOption[];
  value: string | null;
  addEmptyRow?: boolean;
};

const Select: React.VFC<SelectProps> = ({
  options,
  value,
  addEmptyRow = false,
  ...props
}) => {
  return (
    <MuiSelect value={value ?? ''} {...props}>
      {addEmptyRow && <MenuItem value={''}>{'　'}</MenuItem>}
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;
