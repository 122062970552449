import * as apiType from 'api/types/topImage/imageWorkEditHistoryPhotoRetouch';

export type ImageWorkEditHistoryPhotoRetouchMasterResponse =
  apiType.ImageWorkEditHistoryPhotoRetouchMasterResponse;
export type ImageWorkEditHistoryPhotoRetouchDataResponse =
  apiType.ImageWorkEditHistoryPhotoRetouchDataResponse;
export type ImageWorkEditHistoryPhotoRetouchWorkUpdateRequest =
  apiType.ImageWorkEditHistoryPhotoRetouchWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

export type DownloadForm = {
  design: boolean;
  photoRetouch: boolean;
  colorSeparation: boolean;
  layout: boolean;
  final: boolean;
  send: boolean;
};

export type UploadForm = {
  final: boolean;
  send: boolean;
};

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
