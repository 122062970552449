import { FaceDiv } from 'constants/define';
import * as React from 'react';
import theme from 'themes/theme';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Heading, HeadLabel, BodyLabel, ColorLabel } from 'components/parts/atoms';
import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms/Grid';
import { TableCount } from 'components/parts/molecules';

import { MakingStatusModel } from '../types';

type MakingProgress = {
  makingStatusModel?: MakingStatusModel[];
};

const Header = styled(HeadGrid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.makingSpecificationGray?.main,
  color: theme.palette.white.main,
}));

const BodyMain = styled(BodyGrid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.makingSpecificationGray?.main,
  color: theme.palette.white.main,
}));

const BodyReverse = styled(BodyGrid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.makingSpecificationGray?.light,
}));

// 取消線、トビ入り
const ReserveRow = (item: string, tobiflg: boolean) => {
  return (
    <ColorLabel
      sx={{
        color: tobiflg ? theme.palette.red.main : theme.palette.red.contrastText,
      }}
    >
      {item}
    </ColorLabel>
  );
};
const MakingProgress: React.VFC<MakingProgress> = (props) => {
  return (
    <>
      <Box m={2} />
      <Heading>製版進捗詳細</Heading>
      <Box m={2} />
      <TableCount count={props.makingStatusModel?.length ?? 0}></TableCount>
      <RowGrid columns={57}>
        <Header xs={4}>
          <HeadLabel>刷順</HeadLabel>
        </Header>
        <Header xs={4}>
          <HeadLabel>刷色</HeadLabel>
        </Header>
        <Header xs={4}>
          <HeadLabel>区分</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>合・調</HeadLabel>
        </Header>
        <Header xs={4}>
          <HeadLabel>版式</HeadLabel>
        </Header>
        <Header xs={1} />
        <Header xs={2}>
          <HeadLabel>入荷</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>剥離</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>落版</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>Cu</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>PM</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>仕上</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>バフ</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>P検</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>LD</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>L製</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>彫D</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>彫刻</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>Cr</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>校正</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>検表</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>検版</HeadLabel>
        </Header>
        <Header xs={2}>
          <HeadLabel>出荷</HeadLabel>
        </Header>
        <Header xs={4}>
          <HeadLabel>所在</HeadLabel>
        </Header>
      </RowGrid>
      {props.makingStatusModel?.map((item) => {
        return (
          <>
            <RowGrid columns={57}>
              {(() => {
                if (item.facediv == FaceDiv.Main) {
                  return (
                    <>
                      <BodyMain xs={2}>{item.printsequence}</BodyMain>
                      <BodyMain xs={2}>{item.printsequence2}</BodyMain>
                    </>
                  );
                } else if (item.facediv == FaceDiv.Reverse) {
                  return (
                    <>
                      <BodyMain xs={2}>{item.printsequence}</BodyMain>
                      <BodyMain xs={2}>{item.printsequence2}</BodyMain>
                    </>
                  );
                } else {
                  return (
                    <>
                      <BodyMain xs={2} />
                      <BodyMain xs={2} />
                    </>
                  );
                }
              })()}

              <BodyReverse xs={4}>{ReserveRow(item.printcolor, item.tobiirijobflg)}</BodyReverse>
              <BodyReverse xs={4}>{ReserveRow(item.kubun, item.tobiirijobflg)}</BodyReverse>
              <BodyGrid xs={2} sx={{ backgroundColor: item.awasechousacolor }}>
                {ReserveRow(item.awasechousavalue, item.tobiirijobflg)}
              </BodyGrid>
              <BodyReverse xs={4}>
                {item.cylmakingtype1cd}
                {item.cylmakingtype2cd}
              </BodyReverse>
              <BodyMain xs={1} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.arrival }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.hakuri }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.rakuhan }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.cu }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.pm }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.shiage }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.bafu }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.pinhole }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.ld }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.laser }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.sd }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.sculpture }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.cr }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.proofreading }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.inspection }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.kenhyou }} />
              <BodyGrid xs={2} sx={{ backgroundColor: item.shipment }} />
              <BodyGrid xs={4}>
                <BodyLabel>{ReserveRow(item.inventoryareaname, item.tobiirijobflg)}</BodyLabel>
              </BodyGrid>
            </RowGrid>
          </>
        );
      })}
    </>
  );
};
export default MakingProgress;
