import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultFormStore } from './defaults';
import { ImageWorkReportConditionState } from './types';

const slice = createSlice({
  name: 'imageWorkReportCondition',
  initialState: defaultFormStore,
  reducers: {
    save: (state, action: PayloadAction<ImageWorkReportConditionState>) => action.payload,
    clear: () => ({ ...defaultFormStore }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
