import * as React from 'react';

import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@mui/material';

export type MenuItemProps = MuiMenuItemProps;

const MenuItem: React.FC<MenuItemProps> = ({ children, ...props }) => {
  return <MuiMenuItem {...props}>{children}</MuiMenuItem>;
};

export default MenuItem;
