import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import { useId } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/hooks/useId';

import { HeadLabel } from 'components/parts/atoms';

import { hasValue } from '../../../utils/utils';

import { usePageContext } from '../../../hooks/usePageContext';
import { FlexoOrderEditForm, KakakuJouhouSonota, PageState } from '../../../types';
import { Heading } from '../../Heading';
import { PriceIconButton } from '../Fields';
import OtherImagePriceRow from './OtherImagePriceRow';
import { HeadRow } from './Row';

/**
 * 行に入力があるか
 */
function isValidItem(item: KakakuJouhouSonota): boolean {
  return hasValue(item.price) && item.price !== 0;
}

/** その他（画像等）コンポーネント */
const OtherImagePrice: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { pageState } = usePageContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'price.otherImagePrice.kakakuJouhouSonotas',
  });

  const { generateId } = useId();

  /** 行追加 */
  const onAddRow = useCallback(() => {
    const addRow: KakakuJouhouSonota = {
      kakakuJouhouSonotaId: generateId(),
      name: '',
      price: 0,
      toyoPriceRateFlg: true,
    };
    append(addRow);
  }, [append, generateId]);

  /**
   * 行削除
   * @param index インデックス
   */
  const onDeleteRow = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <>
      <Heading>{'その他'}</Heading>
      <HeadRow>
        <HeadLabel>名称</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
        <PriceIconButton color='primary' onClick={() => onAddRow()}>
          <LibraryAddIcon />
        </PriceIconButton>
      </HeadRow>
      {fields.map((item, index) => {
        // 確認画面では入力されていない項目を表示しない
        if (pageState === PageState.Check && !isValidItem(item)) {
          return null;
        }
        return (
          <OtherImagePriceRow
            key={item.id}
            index={index}
            onDeleteClicked={() => onDeleteRow(index)}
          />
        );
      })}
    </>
  );
};

export default React.memo(OtherImagePrice);
