import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import { Grid } from '@mui/material';

import { HeadLabel, BodyLabel, HeadGrid, BodyGrid, RowGrid } from 'components/parts/atoms';

import { CylinderForm } from '../../types';

const CylinderPremiumRate: React.VFC = () => {
  // フォームデータ
  const unitPriceCylinderFormContext = useFormContext<CylinderForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            シリンダー円周割増率情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>500mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate1'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>1000mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate6'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>600mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate2'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>1100mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate7'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>700mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate3'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>1200mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate8'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>800mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate4'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>1300mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>900mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate5'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>1400mm未満</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate10'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <BodyLabel> </BodyLabel>
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>1400mm以上</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylCircumferencePremiumRate11'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default CylinderPremiumRate;
