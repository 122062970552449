import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialRackEditCheckState } from 'store/manuscript/rackEditCheck/defaults';
import { RackEditCheckState } from 'store/manuscript/rackEditCheck/types';

const slice = createSlice({
  name: 'rackEditCheck',
  initialState: InitialRackEditCheckState,
  reducers: {
    save: (state, action: PayloadAction<RackEditCheckState>) => action.payload,
    clear: () => ({ ...InitialRackEditCheckState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
