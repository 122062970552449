import React from 'react';

import {
  MakingPriceRowTarget,
  useMakingPriceRowProperties,
} from '../../../hooks/price/priceRow/useMakingPriceRowProperties';
import MakingPriceRow, { MakingPriceRowProps } from '../Common/MakingPriceRow';

type Props = {
  label: MakingPriceRowProps['label'];
  type: Extract<MakingPriceRowTarget, { category: 'ReChronium' }>['type'];
};

/** 再クロム 行コンポーネント */
const ReChromiumPriceRow: React.VFC<Props> = ({ label, type }) => {
  const { plateMakingCodes, summaryCodes, unitPriceKeys } = useMakingPriceRowProperties({
    category: 'ReChronium',
    type,
  });

  return (
    <MakingPriceRow
      label={label}
      plateMakingCodes={plateMakingCodes}
      summaryCodes={summaryCodes}
      unitPricePropertyNames={unitPriceKeys}
      cylMakingPrice={0}
    />
  );
};

export default React.memo(ReChromiumPriceRow);
