import React from 'react';

import PrintSide from './PrintSide';

/** 作業予定 印刷面(メイン) */
const PrintSideMain: React.VFC = () => {
  return <PrintSide propertyName='flexoMakingsMain' />;
};

export default React.memo(PrintSideMain);
