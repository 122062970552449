export * from 'api/types/topSales/order/flexoOrderEdit';

/**受注入力の画面表示定義 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
  Saved: 'Saved',
  Confirm: 'Confirm',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** 受注入力のタブインデックス */
export const OrderEditTabIndex = {
  Basis: 'Basis',
  Schedule: 'Schedule',
  Making: 'Making',
  Price: 'Price',
};
export type OrderEditTabIndex = typeof OrderEditTabIndex[keyof typeof OrderEditTabIndex];

/** 営業参照 */
export type EigImageReferenceJobForm = {
  /** 営業参照Job ID */
  eigImageReferenceJobId: number;

  /** 参照Job ID */
  referenceJobId: number | null;

  /** 参照Job No */
  referenceJobNo: string;

  /** 工程 */
  workProcesscdId: string;

  /** コメント */
  note: string;
};

/** 営業テンプレート参照 */
export type EigReferenceTemplateForm = {
  /** 営業テンプレートID  */
  eigReferenceTemplateId: number | null;

  /** 営業テンプレートNo (検索が無いので手入力オンリーになる)*/
  referenceTemplateNo: string;

  /** コメント */
  note: string;
};

// #region 価格情報

/** 印刷面計算 行 */
export type MakingPriceRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 表面積 */
  surfaceArea: number;
  /** 版数 */
  count: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

/** 単価計算 行 */
export type UnitPriceRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 版数 */
  count: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};
export type UnitPriceWithRateFlgRowValues = {
  /** 単価 */
  unitPrice: number;
  /** 版数 */
  count: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
  /** 東洋売価率フラグ */
  toyoPriceRateFlg: boolean;
};

/** その他（画像） 行 */
export type OtherImagePriceRowValues = {
  /** 名称 */
  name: string;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 東洋売価率フラグ */
  toyoPriceRateFlg: boolean;
  /** 仕切 */
  grossInvoice: number;
};

/** 一式計算 行 */
export type SetPriceRowValues = {
  /** 刷順 */
  printSequence: string;
  /** 単価 */
  unitPrice: number;
  /** 売価 */
  price: number;
  /** 東洋売価率 */
  toyoPriceRate: number;
  /** 仕切 */
  grossInvoice: number;
};

export type SalesPrice = {
  /** 売価 */
  price: number;
  /** 仕切 */
  grossInvoice: number;
};

export type TotalPriceValues = {
  /** 印刷面(メイン)計算 計 */
  mainMaking: SalesPrice;
  /** 印刷面(リバース)計算 計 */
  reverseMaking: SalesPrice;
  /** 単価計算 計 */
  unit: SalesPrice;
  /** その他（画像） */
  otherImage: SalesPrice;
  /** 一式計算 */
  set: SalesPrice;
  /** 値引き率 */
  discountRate: SalesPrice;
  /** 値引き */
  discountPrice: SalesPrice;
  /** 総合計 */
  total: SalesPrice;
};
// #endregion
