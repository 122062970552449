import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import useShowMakingSpecification from 'hooks/useShowMakingSpecification';
import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';

import { Box, Drawer, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  EmployeeInfoRes as LoginUserInfoRes,
  LateUseJobsRes,
  MyMemosRes,
  LateUseJob,
} from 'api/types/portalTop/portal';

import MyMemoEdit from 'pages/portalTop/MyMemoEdit';

import { EditButton, Heading, Typography, Circular, BodyLink } from 'components/parts/atoms';

import * as date from 'utils/date';

import { SIDEBAR_WIDTH_FULL, NAVBAR_HEIGHT, SIDEBAR_PADDING_TOP } from '../../config';

type Props = {
  showSideBar: boolean;
};

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1),
}));

const StyledHeading = styled(Heading)(() => ({}));
StyledHeading.defaultProps = { variant: 'subtitle1' };

const LoginUserInfo: React.VFC<{ data: LoginUserInfoRes | null }> = ({ data }) => {
  const Head = styled(Typography)(() => ({}));
  const Body = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(4),
  }));

  return (
    <Container>
      <StyledHeading sx={{ fontSize: '0.75rem' }}>
        {date.todayText('yyyy年MM月dd日(E)')}
      </StyledHeading>
      {data && (
        <>
          <Head variant='subtitle1'> 社員No.</Head>
          <Body variant='body1'>{data.employeeNo}</Body>
          <Head variant='subtitle1'> 所属</Head>
          {data.divisionNames.map((division) => (
            <Body key={division} variant='body1'>
              {division}
            </Body>
          ))}
          <Head variant='subtitle1'> 名前</Head>
          <Body variant='body1'>{data.fullName}</Body>
        </>
      )}
    </Container>
  );
};

const LateUsedJobs: React.VFC<{ data: LateUseJobsRes | null }> = ({ data }) => {
  const countPerPage = 10;
  const StyledTypo = styled(Typography)(() => ({}));

  const [page, setPage] = useState<number>(1);

  const showJobs: LateUseJob[] = useMemo(() => {
    const start = (page - 1) * countPerPage;
    const end = start + countPerPage;
    return data?.jobs.slice(start, end) ?? [];
  }, [data, page]);

  // 製販指示書 hooks
  const { showMakingSpecification } = useShowMakingSpecification();

  //JOBNOリンク_製版指示書の表示
  const onShowMakingSpecification = (productionCategory: string, jobId: number) => {
    showMakingSpecification(productionCategory, jobId);
  };

  return (
    <Container>
      <StyledHeading>{'最近使ったJOB'}</StyledHeading>
      {(() => {
        if (!data || data.jobs.length == 0) {
          return <Typography>{'１件もありません。'}</Typography>;
        } else {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {showJobs.map((job) => (
                  <StyledTypo key={job.jobId} variant='body1'>
                    <BodyLink
                      onClick={() => {
                        onShowMakingSpecification(job.productionCategory, Number(job.jobId));
                      }}
                    >
                      {job.jobNo}
                    </BodyLink>
                  </StyledTypo>
                ))}
              </Box>
              <Pagination
                size='small'
                count={Math.ceil(data.jobs.length / countPerPage)}
                onChange={(e, page) => {
                  page && setPage(page);
                }}
                page={page}
                siblingCount={0}
                boundaryCount={0}
              ></Pagination>
            </>
          );
        }
      })()}
    </Container>
  );
};

const MyMemos: React.VFC<{ data: MyMemosRes | null; onEditButtonClick: () => void }> = ({
  data,
  onEditButtonClick,
}) => {
  const StyledTypo = styled(Typography)(() => ({}));

  return (
    <Container>
      <StyledHeading>{'MY MEMO'}</StyledHeading>
      {data && (
        <>
          {data.myMemos.map((myMemo) => (
            <StyledTypo key={myMemo.memo} variant='body1'>
              {myMemo.memo}
            </StyledTypo>
          ))}
        </>
      )}
      <EditButton
        onClick={() => {
          onEditButtonClick();
        }}
      />
    </Container>
  );
};

const SideBar: React.VFC<Props> = (props) => {
  const [loginUserInfo, setLoginUserInfo] = useState<LoginUserInfoRes | null>(null);
  const [lateUseJobs, setLateUseJobs] = useState<LateUseJobsRes | null>(null);
  const [myMemos, setMyMemos] = useState<MyMemosRes | null>(null);
  const [openMyMemoEdit, setOpenMyMemoEdit] = React.useState(false);
  const [reviewMyMemo, setReviewMyMemo] = React.useState(false);

  const { pushMessages } = useSharedMessage();
  const { apiClient, loading } = useApiClient(pushMessages);

  useEffect(() => {
    (async () => {
      try {
        const [employeeInfoRes, lateUseJobsRes, myMemosRes] = await Promise.all([
          apiClient.get<LoginUserInfoRes>(`/api/v1/Portal/LoginUserInfo`),
          apiClient.get<LateUseJobsRes>(`/api/v1/Portal/LateUseJobs`),
          apiClient.get<MyMemosRes>(`/api/v1/Portal/MyMemos`),
        ]);

        setLoginUserInfo(employeeInfoRes.data.result);
        setLateUseJobs(lateUseJobsRes.data.result);
        setMyMemos(myMemosRes.data.result);
      } catch (e) {
        console.log(e);
        return;
      }
    })();
  }, [reviewMyMemo]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: SIDEBAR_WIDTH_FULL,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: SIDEBAR_WIDTH_FULL,
              boxSizing: 'border-box',
              display: 'block',
              paddingTop: SIDEBAR_PADDING_TOP,
            },
          }}
          variant='persistent'
          anchor='left'
          open={props.showSideBar}
        >
          <Box sx={{ height: NAVBAR_HEIGHT }} />
          <Box my={1} />
          {loading ? (
            <Circular />
          ) : (
            <>
              <LoginUserInfo data={loginUserInfo} />
              <LateUsedJobs data={lateUseJobs} />
              <MyMemos data={myMemos} onEditButtonClick={() => setOpenMyMemoEdit(true)} />
            </>
          )}
        </Drawer>
      </Box>
      <MyMemoEdit
        open={openMyMemoEdit}
        onClose={() => setOpenMyMemoEdit(false)}
        onSaveClose={() => {
          setOpenMyMemoEdit(false);
          setReviewMyMemo(!reviewMyMemo);
        }}
      />
    </>
  );
};

export default SideBar;
