import { CircleRateThreshold, MaterialUnitPriceThreshold } from 'constants/define';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { isValidCylinderMaking } from '../../utils/utils';

import { CylUnitPrice, CylinderMaking, CylinderOrderEditForm } from '../../types';
import { useCylinderMakingsAggregation } from '../useCylinderMakingsAggregation';
import { usePlateMakingId } from '../usePlateMakingId';

/** シリンダ単価変更時にフォームをリセットするHooks */
export const useResetPrice = (): {
  resetUnitPrice: (cylUnitPrice: CylUnitPrice | null, cylSizeEnsyuu: number) => void;
  resetPriceRate: (cylUnitPrice: CylUnitPrice | null, cylSizeEnsyuu: number | null) => void;
  resetPrices: (cylUnitPrice: CylUnitPrice | null, cylSizeEnsyuu: number | null) => void;
} => {
  // フォーム
  const { setValue, getValues } = useFormContext<CylinderOrderEditForm>();

  const { costMakings } = useCylinderMakingsAggregation();

  //
  const { awaseId } = usePlateMakingId();
  const getPlateCount = (makings: CylinderMaking[]): number => {
    const count = makings
      .filter((e) => isValidCylinderMaking(e))
      .filter((x) => x.plateMakingId !== '' && x.plateMakingId !== awaseId).length;

    return count;
  };
  const cylinderMakingsMain = getValues('making.cylinderMakingsMain');
  const cylinderMakingsReverse = getValues('making.cylinderMakingsReverse');
  const mainPlateCount: number = getPlateCount(cylinderMakingsMain) ?? 0;
  const reversePlateCount: number = getPlateCount(cylinderMakingsReverse) ?? 0;

  const resetUnitPrice = useCallback(
    (cylUnitPrice: CylUnitPrice | null, cylSizeEnsyuu: number) => {
      if (cylUnitPrice) {
        // 工程あたり単価
        if (cylSizeEnsyuu <= MaterialUnitPriceThreshold.WidthSize1) {
          setValue('price.unitPrice.materials.unitPrice', cylUnitPrice.materialsPrice1);
        } else if (cylSizeEnsyuu <= MaterialUnitPriceThreshold.WidthSize2) {
          setValue('price.unitPrice.materials.unitPrice', cylUnitPrice.materialsPrice2);
        } else if (cylSizeEnsyuu <= MaterialUnitPriceThreshold.WidthSize3) {
          setValue('price.unitPrice.materials.unitPrice', cylUnitPrice.materialsPrice3);
        } else {
          setValue('price.unitPrice.materials.unitPrice', cylUnitPrice.materialsPrice4);
        }
        setValue('price.unitPrice.cuPlate.unitPrice', cylUnitPrice.cuPlate);
        setValue('price.unitPrice.crPlate.unitPrice', cylUnitPrice.crPlate);
        setValue('price.unitPrice.finishingPolish.unitPrice', cylUnitPrice.finishPolish);
        setValue('price.unitPrice.rakuhanPolish.unitPrice', cylUnitPrice.rakuhanEditionPolish);
        setValue('price.unitPrice.reCr.unitPrice', cylUnitPrice.repeatCr);
        setValue('price.unitPrice.cylMaking.unitPrice', cylUnitPrice.cylMaking);

        // フルコース、 改版、増版
        setValue('price.normalPrice.colorCylMaking.scale', cylUnitPrice.colorCylMaking);
        setValue('price.normalPrice.hanchoushi.scale', cylUnitPrice.middleTone);
        setValue('price.normalPrice.choushi.scale', cylUnitPrice.gradetion);
        setValue('price.normalPrice.renzoku.scale', cylUnitPrice.endless);
        setValue('price.normalPrice.tuikafusyoku.scale', cylUnitPrice.additionalCorrosion);

        // 版べり再版
        setValue('price.hanberiPrice.colorCylMaking.scale', cylUnitPrice.colorCylMaking);
        setValue('price.hanberiPrice.hanchoushi.scale', cylUnitPrice.middleTone);
        setValue('price.hanberiPrice.choushi.scale', cylUnitPrice.gradetion);
        setValue('price.hanberiPrice.renzoku.scale', cylUnitPrice.endless);
        setValue('price.hanberiPrice.tuikafusyoku.scale', cylUnitPrice.additionalCorrosion);

        // その他（シリンダ）
        setValue('price.otherCylinderPrice.herio.unitPrice', cylUnitPrice.herio);
        setValue(
          'price.otherCylinderPrice.gaikeisessaku.unitPrice',
          cylUnitPrice.outsideTypeCutting,
        );
        setValue('price.otherCylinderPrice.fare.unitPrice', cylUnitPrice.fare);

        // 東洋売価率にチェックを入れる
        setValue('price.otherCylinderPrice.herio.toyoPriceRateFlg', true);
        setValue('price.otherCylinderPrice.gaikeisessaku.toyoPriceRateFlg', true);
        setValue('price.otherCylinderPrice.fare.toyoPriceRateFlg', true);

        const kakakuList = getValues('price.otherImagePrice.kakakuJouhouSonotas');
        if (kakakuList != null && kakakuList?.length > 0) {
          const updateKakakuList = kakakuList.map((item) => ({ ...item, toyoPriceRateFlg: true }));
          setValue('price.otherImagePrice.kakakuJouhouSonotas', updateKakakuList);
        }
      }

      setValue('price.otherCylinderPrice.fare.amount', mainPlateCount + reversePlateCount);
    },
    [setValue, costMakings.length],
  );

  const resetPriceRate = useCallback(
    (cylUnitPrice: CylUnitPrice | null, cylSizeEnsyuu: number | null) => {
      /** 円周割増率 */
      let circleRate: number | null = null;
      if (cylUnitPrice) {
        if (cylSizeEnsyuu == null) {
          circleRate = cylUnitPrice.cylCircumferencePremiumRate11;
        } else {
          if (cylSizeEnsyuu < CircleRateThreshold.WidthSize1) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate1;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize2) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate2;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize3) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate3;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize4) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate4;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize5) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate5;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize6) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate6;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize7) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate7;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize8) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate8;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize9) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate9;
          } else if (cylSizeEnsyuu < CircleRateThreshold.WidthSize10) {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate10;
          } else {
            circleRate = cylUnitPrice.cylCircumferencePremiumRate11;
          }
        }
        setValue('price.toyoPriceRate', cylUnitPrice.toyoPriceRate);
        setValue('price.circleRate', circleRate);
      }
    },
    [setValue],
  );

  const resetPrices = (cylUnitPrice: CylUnitPrice | null, cylSizeEnsyuu: number | null) => {
    resetUnitPrice(cylUnitPrice, cylSizeEnsyuu ?? 0);
    resetPriceRate(cylUnitPrice, cylSizeEnsyuu);
  };

  return {
    resetUnitPrice,
    resetPriceRate,
    resetPrices,
  };
};
