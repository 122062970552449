import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialCylinderWorksBarcodeReadInfo } from 'store/topPlating/cylinderWorksCylJobListEditCheck/defaults';
import { CylinderWorksBarcodeReadInfo } from 'store/topPlating/cylinderWorksCylJobListEditCheck/types';

const slice = createSlice({
  name: 'barcodeReadCuPlating',
  initialState: InitialCylinderWorksBarcodeReadInfo,
  reducers: {
    save: (state, action: PayloadAction<CylinderWorksBarcodeReadInfo>) => action.payload,
    clear: () => ({ ...InitialCylinderWorksBarcodeReadInfo }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
