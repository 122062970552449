import * as React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography, ColorLabel } from 'components/parts/atoms';

import { IconExplainationProps } from './types';

const ColorBox: React.VFC<{ color: string }> = ({ color }) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: color,
    margin: theme.spacing(1),
    height: '20px',
    width: '20px',
  }));
  return <StyledBox />;
};

export const IconExplanation: React.VFC<IconExplainationProps> = (props) => {
  const { icons, textColors, bgColors } = { ...props };
  return (
    <>
      {icons && (
        <>
          <Box sx={{ display: 'frex', width: '100%' }}>
            <Typography style={{ fontWeight: 600 }}>アイコン説明</Typography>
          </Box>
          <Box sx={{ display: 'frex', width: '100%' }}>
            {icons.map((item, index) => {
              return (
                <Box key={index} sx={{ display: 'frex', alignItems: 'center' }}>
                  <ColorBox color={item.color} />
                  <Typography>{item.description}</Typography>
                  <Box m={2} />
                </Box>
              );
            })}
          </Box>
        </>
      )}
      <Box m={1} />
      {textColors && (
        <>
          <Box sx={{ display: 'frex', width: '100%' }}>
            <Typography style={{ fontWeight: 600 }}>文字色</Typography>
            <Box m={2} />
            {textColors.map((item, index) => {
              return (
                <Box key={index} sx={{ display: 'frex' }}>
                  <ColorLabel color={item.color}>{item.colorName}</ColorLabel>
                  <Typography>
                    {'： '} {item.description}
                  </Typography>
                  <Box m={2} />
                </Box>
              );
            })}
          </Box>
        </>
      )}
      <Box m={1} />
      {bgColors && (
        <>
          <Box sx={{ display: 'frex', width: '100%' }}>
            <Typography style={{ fontWeight: 600 }}>背景色</Typography>
            <Box m={2} />
            {bgColors.map((item, index) => {
              return (
                <Box key={index} sx={{ display: 'frex' }}>
                  <ColorLabel color={item.color}>{item.colorName}</ColorLabel>
                  <Typography>
                    {'： '} {item.description}
                  </Typography>
                  <Box m={2} />
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};

export default IconExplanation;
