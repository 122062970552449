import { ImageDataDownloadState } from './types';

/** 画像ダウンロードストアの初期値 */
export const initialState: ImageDataDownloadState = {
  processTarget: null,
  fromPage: null,
  page: {
    initialProcessState: 'unProcessed',
    destinations: [],
  },
};
