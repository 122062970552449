import { TodayPlan } from './types';

/** 本日の予定の初期データ */
export const initialTodayPlan: TodayPlan = {
  arrivalPlanCount: 0,
  cylMakingPlanCount: 0,
  shipmentPlanCount: 0,
  imageSyukkouPlanCount: 0,
  monthlyLossCylTotal: 0,
};
