import { SearchCondition } from './types';

export const defaultSearchCondition: SearchCondition = {
  // JOBNO
  jobNo: '',
  // 未承認フラグ
  unApproved: false,
  // 第一承認済みフラグ
  firstApprovalDone: false,
  // 第二承認済みフラグ
  secondApprovalDone: false,
  // 開始日
  dateStart: null,
  // 終了日
  dateEnd: null,
  // 得意先
  customerInfo: {
    customerId: 0,
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
    demandId: 0,
    demandCustomerSectionCd: '',
    demandCustomerCd: '',
    demandCustomerName: '',
    deleteFlg: true,
  },
  // 承認者名
  approvalEmployeeName: '',
  // 検索条件日付
  selectedDateType: '',
  // 第一承認
  firstApproval: false,
  // 第二承認
  secondApproval: false,
  // 全体ステータス
  totalJobStatus: '',
  // 日時処理対象JOB表示フラグ
  isDailyProcessTargetJobDisplay: false,
};
