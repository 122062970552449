import { useContext } from 'react';

import { OtherOrderEditMasterContext } from '../contexts/OtherOrderEditMasterContext';
import { OtherOrderEditMaster } from '../types';

/** セレクト選択肢Hooks */
export const useOtherOrderEditMaster = (): {
  master: OtherOrderEditMaster;
} => {
  const master = useContext(OtherOrderEditMasterContext);

  return {
    master,
  };
};
