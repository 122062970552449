import useMessage from 'hooks/useMessage';
import React, { useEffect } from 'react';
import { useForm, Controller} from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { PrintTarget } from './types';
import { defaultPrintTarget } from './default';
import { usePrint } from './hooks/usePrint';

import {
  RowGrid,
  HeadGrid,
  HeadLabel,
  BodyGrid,
  Checkbox,
  CloseButton,
  PrintButton,
  Circular,
} from 'components/parts/atoms';
import { Dialog } from 'components/parts/molecules';

export type Props = {
  open: boolean;
  onClose: () => void;
  data: PrintTarget | undefined;
};

const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  margin: theme.spacing(2),
  minWidth: 500,
}));

const DeliveryPrint: React.VFC<Props> = (props) => {
  // hocks
  const messageApi = useMessage();
  const { printProcess, loading } = usePrint(messageApi.pushMessages);
  
  const printForm = useForm<PrintTarget>({
    defaultValues: defaultPrintTarget
  });

  // 初期化
  useEffect(() => {
    if(props.data) {
      printForm.reset(props.data);
      printForm.setValue('isDeliveryPrint', props.data.deliveryJobIdList.length > 0);
      printForm.setValue('isReceiptPrint', props.data.receiptJobIdList.length > 0);
      printForm.setValue('isDeliveryInkPrint', props.data.deliveryInkJobIdList.length > 0);
      printForm.setValue('isDeliveryDetailInkPrint', props.data.deliveryDetailInkJobIdList.length > 0);
    }
  }, [props.data]);

  /* 印刷ボタン押下 **/
  const onSubmit = printForm.handleSubmit((data: PrintTarget) => {
     messageApi.clear();
     printProcess(data);
  });

  /* ページを閉じる **/
  const onClose = () => {
    printForm.reset();
    props.onClose();
  };

 return (
       <Dialog open={props.open} title={'印刷指示'} onClose={onClose} messageApi={messageApi} height='400px'>
           <Box sx={{ width: '100%' }}>
            
            {loading && <Circular/>}
            
            { (props.data) && (!loading) &&  
             (<Container>
              <Box m={5} />
                <RowGrid>
                  <HeadGrid xs={12} sx={{ justifyContent: 'center' }}>
                    <HeadLabel>帳票</HeadLabel>
                  </HeadGrid>
                </RowGrid>
                {
                  (props.data.deliveryJobIdList.length > 0) && (<RowGrid>
                  <BodyGrid xs={12}>
                  <Controller
                    name={"isDeliveryPrint"}
                    control={printForm.control}
                    render={({field}) => (
                      <Box>
                        <Checkbox
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}/>
                          納品書(TOPC)
                      </Box>
                    )}  
                  />
                  </BodyGrid>
                </RowGrid>)
                }
                {
                  (props.data.receiptJobIdList.length > 0) && (
                  <RowGrid>
                    <BodyGrid xs={12}>
                    <Controller
                      name={"isReceiptPrint"}
                      control={printForm.control}
                      render={({field}) => (
                        <Box>
                          <Checkbox
                            onChange={(e) => field.onChange(e.target.checked)}
                            checked={field.value}/>
                            受領書
                        </Box>
                      )}  
                    />
                    </BodyGrid>
                  </RowGrid>
                  )
                }
                  {
                  (props.data.deliveryInkJobIdList.length > 0) && (
                  <RowGrid>
                    <BodyGrid xs={12}>
                    <Controller
                      name={"isDeliveryInkPrint"}
                      control={printForm.control}
                      render={({field}) => (
                        <Box>
                          <Checkbox
                            onChange={(e) => field.onChange(e.target.checked)}
                            checked={field.value}/>
                            納品書(インキ)
                        </Box>
                      )}  
                    />
                    </BodyGrid>
                  </RowGrid>
                  )
                }
                {
                  (props.data.deliveryDetailInkJobIdList.length > 0) && (
                  <RowGrid>
                    <BodyGrid xs={12}>
                    <Controller
                      name={"isDeliveryDetailInkPrint"}
                      control={printForm.control}
                      render={({field}) => (
                        <Box>
                          <Checkbox
                            onChange={(e) => field.onChange(e.target.checked)}
                            checked={field.value}/>
                            納品書明細(インキ向)
                        </Box>
                      )}  
                    />
                    </BodyGrid>
                  </RowGrid>
                  )
                }
                <Box m={5} />
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <PrintButton onClick={onSubmit} />
                          <Box m={1} />
                          <CloseButton onClick={onClose} />
                        </Box>
              </Container>)
              }
          </Box>
      </Dialog>
  );
};
export default DeliveryPrint;