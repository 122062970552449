import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Paper, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Typography, TypographyProps, IconButton } from 'components/parts/atoms';

export type HeadingProps = {
  children: string;
  onClose: () => void;
  variant?: TypographyProps['variant'];
  backgroundColor?: string;
  textColor?: string;
  buttonText?: string;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const DialogCloseHeading: React.FC<HeadingProps> = ({ variant: typoVariant = 'h3', ...props }) => {
  const theme = useTheme();
  const backgroundColor = props.backgroundColor ?? theme.palette.primary.main;
  const textColor = props.textColor ?? theme.palette.primary.contrastText;

  return (
    <StyledPaper color={'primary'} sx={{ backgroundColor: backgroundColor, flexGrow: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', color: textColor }}>
        <Typography p={theme.spacing(1)} variant={typoVariant} sx={{ color: textColor }}>
          {props.children}
        </Typography>

        <IconButton color='inherit' onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledPaper>
  );
};

export default DialogCloseHeading;
