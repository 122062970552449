import { useFormContext, useWatch } from 'react-hook-form';

import { OtherOrderEditForm, OtherProductInfoRowValues } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** その他（画像）行情報 Hooks */
export const useOtherProductInfoRow = (index: number): OtherProductInfoRowValues => {
  // コンテキスト
  const { control } = useFormContext<OtherOrderEditForm>();
  const row = useWatch({ control, name: `price.otherProductInfos.${index}` });
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcGrossInvoice, calcPriceSum, calcGrossInvoiceSum } = usePriceCalculater();

  /** 仕切 */
  const grossInvoice = calcGrossInvoice(row.price, toyoPriceRate, row.toyoPriceRateFlg);
  /** 売価計 */
  const priceSum = calcPriceSum(row.price, row.count);
  /** 仕切計 */
  const grossInvoiceSum = calcGrossInvoiceSum(
    row.price,
    row.count,
    toyoPriceRate,
    row.toyoPriceRateFlg,
  );

  return {
    itemName: row.itemName,
    price: row.price,
    toyoPriceRateFlg: row.toyoPriceRateFlg,
    grossInvoice,
    count: row.count,
    priceSum,
    grossInvoiceSum,
  };
};
