import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import { Grid } from '@mui/material';

import { HeadLabel, BodyLabel, HeadGrid, BodyGrid, RowGrid } from 'components/parts/atoms';

import { CylinderForm } from '../../types';

const CylinderProsessPrice: React.VFC = () => {
  // フォームデータ
  const unitPriceCylinderFormContext = useFormContext<CylinderForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            工程単価情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={24}>
        <HeadGrid xs={1}>
          <HeadLabel>母材代</HeadLabel>
        </HeadGrid>
        <Grid item xs={23}>
          <RowGrid columns={23}>
            <HeadGrid xs={3}>
              <HeadLabel>600mm以下</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='materialSprice1'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
            <HeadGrid xs={4}>
              <HeadLabel>銅メッキ</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='cuPlate'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={23}>
            <HeadGrid xs={3}>
              <HeadLabel>680mm以下</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='materialSprice2'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
            <HeadGrid xs={4}>
              <HeadLabel>クロムメッキ</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='crPlate'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={23}>
            <HeadGrid xs={3}>
              <HeadLabel>760mm以下</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='materialSprice3'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
            <HeadGrid xs={4}>
              <HeadLabel>仕上研磨</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='finishPolish'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={23}>
            <HeadGrid xs={3}>
              <HeadLabel>761mm以上</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='materialSprice4'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
            <HeadGrid xs={4}>
              <HeadLabel>落版研磨</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={8}>
              <Controller
                name='rakuhanEditionPolish'
                control={unitPriceCylinderFormContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
                )}
              />
            </BodyGrid>
          </RowGrid>
        </Grid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>再クロム</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='repeatCr'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>製版</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='cylMaking'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === 0 ? '' : field.value?.toFixed(2)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default CylinderProsessPrice;
