import { useFormContext, useWatch } from 'react-hook-form';

import { OnlyTypeKey } from 'utils/typeUtils';

import {
  CylinderOrderEditForm,
  Price,
  MakingOptionPrice,
  MakingOptionPriceRow,
  MakingOptionPriceRowValues,
  MinimumPriceKey,
} from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';
import { useMinimumPrice } from './useMinimumPrice';
import { useUnitPriceCylMakingRow } from './useUnitPriceCylMakingRow';
import { useUnitPriceHanberiMakingRow } from './useUnitPriceHanberiMakingRow';

/** 製版価格 行情報 Hooks */
export const useMakingOptionPriceRow = (
  priceKey: OnlyTypeKey<Price, MakingOptionPrice>,
  rowKey: OnlyTypeKey<MakingOptionPrice, MakingOptionPriceRow>,
  cylMakingPrice: number,
  minimumPriceKey?: MinimumPriceKey,
): MakingOptionPriceRowValues => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const row = useWatch({ control, name: `price.${priceKey}.${rowKey}` });
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcMakingOptionPrice, calcGrossInvoice } = usePriceCalculater();

  // 九州専用計算hook
  const { applyMinimumPrice } = useMinimumPrice(minimumPriceKey);

  /** 売価 */
  let price = calcMakingOptionPrice(cylMakingPrice, row.scale, row.amount);

  // 最低価格を適用（九州専用）
  price = applyMinimumPrice(price);

  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate);

  return {
    cylMakingPrice,
    scale: row.scale ?? 0,
    amount: row.amount ?? 0,
    price,
    toyoPriceRate,
    grossInvoice,
  };
};

/** 製版価格 行情報 Hooks */
export const useNormalOptionPriceRow = (
  rowKey: OnlyTypeKey<MakingOptionPrice, MakingOptionPriceRow>,
): MakingOptionPriceRowValues => {
  const { price } = useUnitPriceCylMakingRow();
  const values = useMakingOptionPriceRow('normalPrice', rowKey, price);
  return values;
};

/** 製版価格 行情報 Hooks */
export const useHanberiOptionPriceRow = (
  rowKey: OnlyTypeKey<MakingOptionPrice, MakingOptionPriceRow>,
): MakingOptionPriceRowValues => {
  const { price } = useUnitPriceHanberiMakingRow();
  const values = useMakingOptionPriceRow('hanberiPrice', rowKey, price);
  return values;
};
