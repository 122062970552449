import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialCylinderSearcherCondition } from './defaults';
import { CylinderSearcherConditionState } from './types';

const slice = createSlice({
  name: 'CylinderSearcherCondition',
  initialState: initialCylinderSearcherCondition,
  reducers: {
    save: (state, action: PayloadAction<CylinderSearcherConditionState>) => action.payload,
    clear: () => ({ ...initialCylinderSearcherCondition }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
