import React, { useState } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';

import { Box } from '@mui/material';

import DeliveryAddressEditMainPage from 'pages/customer/DeliveryAddressEdit';

import {
  Typography,
  Checkbox,
  HeadGrid,
  RowGrid,
  EditButton,
  DeleteButton,
  AddButton,
  HeadLabel,
  BodyGrid,
  BodyLabel,
} from 'components/parts/atoms';

import { defaultDeliveryPlaceEdit } from '../../defaults';
import { CustomerEdit, DeliveryPlaceEdit } from '../../types';

// 単価入力画面
const DeliveryAddressEdit: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();

  // 納入先情報配列
  const deliveryPlaceArray = useFieldArray({
    control: formContext.control,
    name: 'deliveryPlace',
  });

  // 納入先削除
  const onDeleteDeliveryPlace = (index: number) => {
    deliveryPlaceArray.remove(index);
  };

  // 画面ステート
  const [indexState, setIndexState] = useState<number>(-1);
  const [deliveryPlaceEditState, setDeliveryPlaceEditState] =
    useState<DeliveryPlaceEdit>(defaultDeliveryPlaceEdit);
  // 新規作成単価ID-1から順にセット
  const [unitPriceId, setUnitPriceId] = useState<number>(-1);
  // 追加ボタンアクション
  const onCreateUnitPrice = () => {
    const toyoPriceRate = formContext.getValues('customerEditSingleInfo.toyoPriceRate');
    setDeliveryPlaceEditState(defaultDeliveryPlaceEdit);
    setDeliveryPlaceEditState((state) => ({
      ...state,
      toyoPriceRate: toyoPriceRate,
      deliveryPlaceId: unitPriceId,
    }));
    setUnitPriceId(unitPriceId - 1);
    setOpenDeliveryPlace(true);
  };
  // 編集ボタンアクション
  const onEditUnitPrice = (index: number) => {
    const target = formContext.getValues('deliveryPlace')[index];
    setIndexState(index);
    if (target !== undefined) {
      setDeliveryPlaceEditState(target);
    }
    setOpenDeliveryPlace(true);
  };

  /** 納入先ダイアログ */
  const [openDeliveryPlace, setOpenDeliveryPlace] = React.useState(false);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <DeliveryAddressEditMainPage
          open={openDeliveryPlace}
          onClose={(value) => {
            setOpenDeliveryPlace(false);
            if (value) {
              // 編集の為フィールドArrayを更新する
              if (indexState !== -1) {
                deliveryPlaceArray.update(indexState, value);
              }
              // 新規登録の為Arrayに追加
              else {
                deliveryPlaceArray.append(value);
              }
            }
            setIndexState(-1);
          }}
          index={indexState}
          state={deliveryPlaceEditState}
          checkFinishFlag={false}
        />
      </>
    );
  };

  return (
    <>
      <RowGrid columns={24}>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>
            帳票
            <br />
            送付先
          </HeadLabel>
        </HeadGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          <HeadLabel>納入先名</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>郵便番号</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>都道府県</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>市区町村</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>丁目･番地</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          <HeadLabel>ビル名</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          <HeadLabel>電話番号</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={5} sx={{ justifyContent: 'center' }}>
          <AddButton onClick={() => onCreateUnitPrice()}></AddButton>
        </HeadGrid>
      </RowGrid>

      {deliveryPlaceArray.fields.map((item, index) => {
        return (
          <div key={item.id}>
            <RowGrid columns={24}>
              <BodyGrid xs={2}>
                <Controller
                  name={`deliveryPlace.${index}.souhusakiFlg`}
                  control={formContext.control}
                  render={({ field: { onChange, value, name } }) => (
                    <Checkbox onChange={onChange} value={value} name={name} checked={value} />
                  )}
                />
              </BodyGrid>
              <BodyGrid xs={3}>
                <Controller
                  name={`deliveryPlace.${index}.deliveryPlaceName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={2}>
                <Controller
                  name={`deliveryPlace.${index}.zipCd`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={2}>
                <Controller
                  name={`deliveryPlace.${index}.prefecturesName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={2}>
                <Controller
                  name={`deliveryPlace.${index}.address1`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={2}>
                <Controller
                  name={`deliveryPlace.${index}.address2`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={3}>
                <Controller
                  name={`deliveryPlace.${index}.buildingName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={3}>
                <Controller
                  name={`deliveryPlace.${index}.tel`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={5} sx={{ justifyContent: 'center' }}>
                <EditButton onClick={() => onEditUnitPrice(index)}></EditButton>
                <Box m={1} />
                <DeleteButton onClick={() => onDeleteDeliveryPlace(index)}></DeleteButton>
              </BodyGrid>
            </RowGrid>
          </div>
        );
      })}
      {renderDialogs()}
    </>
  );
};

export default DeliveryAddressEdit;
