import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

export function today(): Date {
  return new Date();
}

export function dateText(date: Date | null | undefined, formatoption = 'yyyy/MM/dd'): string {
  if (!date || date === null) {
    return '';
  }
  if (isInvalidDate(date)) {
    return '';
  }
  return format(date, formatoption, { locale: ja });
}

export function datetimeText(
  date: Date | null | undefined,
  formatoption = 'yyyy/MM/dd HH:mm:ss',
): string {
  if (!date || date === null) {
    return '';
  }
  if (isInvalidDate(date)) {
    return '';
  }
  return format(date, formatoption, { locale: ja });
}

export function datetimeAmPmText(
  date: Date | null | undefined,
  timediv: number | null,
  formatoption = 'yyyy/MM/dd',
): string {
  if (date === null || timediv === null) {
    return dateText(date, formatoption);
  }
  let time = dateText(date, 'hh:mm');
  if (time === '11:59') {
    time = '';
  }
  return dateText(date, formatoption + ' ' + (timediv === 0 ? '午前' : '午後') + time);
}

export function todayText(formatoption = 'yyyy/MM/dd'): string {
  return dateText(today(), formatoption);
}

export function dayOfWeekDateText(date: Date = today()): string {
  return dateText(date, 'yyyy年MM月dd日（E）');
}

export function dayOfWeekShortDateText(date: Date = today()): string {
  return dateText(date, 'MM/dd（E）');
}

export function max(date1: Date | null, date2: Date | null): Date | null {
  if (!date1 && !date2) return null;
  if (date1 && date2) return date1 > date2 ? date1 : date2;
  return date1 || date2;
}

export function isInvalidDate(date: Date | null): boolean {
  return date != null ? Number.isNaN(date.getTime()) : false;
}
