import React from 'react';

import { Box } from '@mui/material';

import Accessorie from './Accessorie';
import Barcode from './Barcode';
import EigImageReferenceJobs from './EigImageReferenceJobs';
import EigReferenceTemplates from './EigReferenceTemplates';
import GaImageReferenceJobs from './GaImageReferenceJobs';
import GaReferenceTemplates from './GaReferenceTemplates';
import Instructions from './Instructions';
import MakingBasis from './MakingBasis';
import MakingMain from './MakingMain';
import MakingReverse from './MakingReverse';

/** フレキソ受注 製版情報コンポーネント */
const FlexoOrderMaking: React.VFC = () => {
  return (
    <>
      <MakingBasis />
      <Box my={6} />
      <MakingMain />
      <Box my={6} />
      <MakingReverse />
      <Box my={6} />
      <Accessorie />
      <Box my={6} />
      <Barcode />

      <Box my={6} sx={{ display: 'flex' }}>
        <Box sx={{ width: '55%' }}>
          <EigImageReferenceJobs />
          <Box my={6} />
          <EigReferenceTemplates />
        </Box>
        <Box mx={2} />
        <Box sx={{ width: '45%' }}>
          <GaImageReferenceJobs />
          <Box my={6} />
          <GaReferenceTemplates />
        </Box>
      </Box>
      <Instructions />
    </>
  );
};

export default FlexoOrderMaking;
