import { useHanberiOptionPriceRow } from '../priceRow/useMakingOptionPriceRow';

/** 版べり再版 集計情報 Hooks */
export const useHanberiOptionPriceTotal = (): { price: number; grossInvoice: number } => {
  const colorCylMaking = useHanberiOptionPriceRow('colorCylMaking');
  const hanchoushi = useHanberiOptionPriceRow('hanchoushi');
  const choushi = useHanberiOptionPriceRow('choushi');
  const renzoku = useHanberiOptionPriceRow('renzoku');
  const tuikafusyoku = useHanberiOptionPriceRow('tuikafusyoku');

  const rows = [colorCylMaking, hanchoushi, choushi, renzoku, tuikafusyoku];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
