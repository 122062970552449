import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState } from 'react';

import { defaultCustomerEdit, defaultOriginalIds, defaultSelectBoxModel } from '../defaults';
import { CustomerEdit, CustomerIdCondition } from '../types';

/** 得意先編集のhooks */
export const useFetchCustomerDetail = (): {
  fetchCustomer:(customerId: number) => Promise<CustomerEdit>;
  loading: boolean;
  customerEdit: CustomerEdit;
} => {
  // APIクライアント
  const messageApi = useSharedMessage();
  const { apiClient, loading } = useApiClient(messageApi.pushMessages);

  // 得意先詳細ステート
  const [customerEdit, setCustomerEdit] = useState<CustomerEdit>(defaultCustomerEdit);

  const fetchCustomer = async (customerId: number): Promise<CustomerEdit> => {
      // 得意先詳細表示用
      try {
        const condition: CustomerIdCondition = {
          customerId: customerId,
        };
        const [allresults] = await Promise.all([
          apiClient.get<CustomerEdit>(`/api/v1/CustomerDetailDialog/GetCustomerDetail`, {
            params: condition,
          }),
        ]);
        if (allresults.data.result) {
          if (customerId === -1) {
            setCustomerEdit((state) => ({
              ...state,
              selectBoxData: allresults.data.result?.selectBoxData ?? defaultSelectBoxModel,
              originalIds: allresults.data.result?.originalIds ?? defaultOriginalIds,
            }));
            return customerEdit;
          } else {
            setCustomerEdit(allresults.data.result);
            return allresults.data.result;
          }
        }
      } catch (e) {
        console.log(e);
      }
    return customerEdit;
  };

  return {
    loading,
    customerEdit,
    fetchCustomer,
  };
};

export default useFetchCustomerDetail;
