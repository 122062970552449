import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import {
  Typography,
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
} from 'components/parts/atoms';

import { FlexoForm } from '../../types';

const FlexoUnitPriceInfos: React.VFC = () => {
  // フォームデータ
  const unitPriceFlexoFormContext = useFormContext<FlexoForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            単価情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>ベタ版</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='betahan'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>データ作成代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dataMakePrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>網版</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='halfTone'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>データ修正代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dataEditPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>バリヤー処理代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='barrierPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>下版データ作成代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='layoutDataPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>ネガフィルム代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='negafilmPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>カラー処理代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='colorProcessPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>ポジ返し代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='posireturnPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>カラー分解代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='photoRetouchPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>単面ネガ代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='singleFacenegaPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>版下代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='blockCopyPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>殖版代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='composerPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>運賃</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='fare'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>全面マットネガ代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='matnegaPrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <BodyLabel> </BodyLabel>
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>刷版代</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='platePrice'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <BodyLabel> </BodyLabel>
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default FlexoUnitPriceInfos;
