import { SummaryCode } from 'constants/define';

import { useCylinderOrderEditMaster } from './useCylinderOrderEditMaster';

/** 摘要_コードID取得Hooks */
export const useSummaryCdId = (): {
  newCdId: string;
  oldCdId: string;
  supplyCdId: string;
  copperCdId: string;
  findByCode: (summaryCode: SummaryCode) => string;
} => {
  const {
    master: { summaryList },
  } = useCylinderOrderEditMaster();

  const findByCode = (summaryCode: SummaryCode) => {
    return summaryList.find((x) => x.decisionCode === summaryCode)?.value ?? '';
  };

  // 摘要IDを取得
  const newCdId = findByCode(SummaryCode.New);
  const oldCdId = findByCode(SummaryCode.Old);
  const supplyCdId = findByCode(SummaryCode.Supply);
  const copperCdId = findByCode(SummaryCode.Copper);

  return {
    newCdId,
    oldCdId,
    supplyCdId,
    copperCdId,
    findByCode,
  };
};
