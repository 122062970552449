import * as React from 'react';

import { styled } from '@mui/material/styles';

import Typography, { TypographyProps } from './Typography';

export type BodyLabelProps = {
  sx?: TypographyProps['sx'];
  px?: number;
  py?: number;
  lineThrough?: boolean;
  variant?: TypographyProps['variant'];
  fontSize?: TypographyProps['fontSize'];
};

const BodyLabel: React.FC<BodyLabelProps> = ({
  variant: typoVariant = 'body1',
  children,
  sx,
  lineThrough,
  ...props
}) => {
  return (
    <Typography
      {...props}
      variant={typoVariant}
      sx={{ ...sx, ...(lineThrough && { textDecoration: 'line-through' }) }}
    >
      {children}
    </Typography>
  );
};

const StyledBodyLabel = styled(BodyLabel)(() => undefined);

export default StyledBodyLabel;
