import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState } from 'react';

import { ResponseMessage } from 'api/types/common/apiResponse';

import { CustomerEdit } from '../types';

/** 得意先編集のhooks */
export const useCheckCustomerEdit = (): {
  IsInputCheckAsync: (inputInfo: CustomerEdit) => Promise<boolean>;
  duplicateDel: boolean;
} => {
  // APIクライアント
  const messageApi = useSharedMessage();
  const { apiClient } = useApiClient(messageApi.pushMessages);
  const [duplicateDel, setDuplicateDel] = useState<boolean>(false);

  // 得意先編集(初期表示)
  // 入力値チェック
  const IsInputCheckAsync = async (inputInfo: CustomerEdit): Promise<boolean> => {
    try {
      console.log('入力チェック');
      console.log(inputInfo);
      const [allresults] = await Promise.all([
        apiClient.post(`/api/v1/CustomerEdit/IsCheckCustomerEdit`, inputInfo),
      ]);
      if (allresults.data.result) {
        const messages: ResponseMessage[] = allresults.data.messages ?? [];
        if (messages.length > 0) {
          setDuplicateDel(true);
        }
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    return false;
  };

  return {
    IsInputCheckAsync,
    duplicateDel,
  };
};

export default useCheckCustomerEdit;
