import * as React from 'react';

import { ContentPasteSearch } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type ReferButtonProps = CustomButtonProps;

const ReferButton: React.VFC<ReferButtonProps> = (props) => {
  return (
    <CustomButton icon={<ContentPasteSearch />} {...props}>
      {'参照'}
    </CustomButton>
  );
};

export default ReferButton;
