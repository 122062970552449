import * as React from 'react';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { InformationsRes } from 'api/types/portalTop/portal';

import { Typography, Heading } from 'components/parts/atoms';

import * as date from 'utils/date';

type Props = {
  informations: InformationsRes | null;
};

const Container = styled(Box)(() => ({}));

const InformationCategory = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const Information = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(6),
  marginBottom: theme.spacing(2),
}));

const StyledImg = styled('img')(({ theme }) => ({
  margin: theme.spacing(2),
}));

const informationColor = '#0066cc';

const Informations: React.VFC<Props> = ({ informations }) => {
  if (!informations) return <></>;
  return (
    <Container>
      {informations.informationCategories.map((category, index) => (
        <>
          <div key={category.informationCategoryId}>
            <Heading
              backgroundColor={category.backgroundColor}
              textColor={category.foregroundColor}
            >
              {category.informationTitle}
            </Heading>
            <Grid key={index} container>
              {index % 2 == 0 && (
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <StyledImg src={`/images${category.iconFileName}`} />
                </Grid>
              )}
              <Grid item xs={10}>
                <InformationCategory key={category.informationCategoryId}>
                  {category.informations.map((information) => (
                    <Information key={information.informationId}>
                      <Box display={'flex'}>
                        <Typography variant='subtitle1' color={informationColor}>
                          {information.informationTitle}
                        </Typography>
                        <Box sx={{ mx: 4 }} />
                        <Typography variant='caption' color={informationColor}>
                          {`${date.dateText(information.registdate)} 公開`}
                        </Typography>
                      </Box>
                      <Typography variant='body1'><span dangerouslySetInnerHTML={{ __html: information.informationContents.replace(/<script/ig, '&lt;script') }} /></Typography>
                    </Information>
                  ))}
                </InformationCategory>
              </Grid>
              {index % 2 != 0 && (
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <StyledImg src={`/images${category.iconFileName}`} />
                </Grid>
              )}
            </Grid>
          </div>
        </>
      ))}
    </Container>
  );
};

export default Informations;
