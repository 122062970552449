import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel, RowGrid, BodyGrid, Textarea } from 'components/parts/atoms';

import { EditKanaField, EditKanaTextAreaField } from '../Fields';
import { Heading } from '../Heading';
import { RowOne } from './Rows';

/** 製版指示 指示事項コンポーネント  */
const Instructions: React.VFC = () => {
  // コンテキスト
  const formContext = useFormContext<CylinderOrderEditForm>();

  return (
    <>
      <Heading>画像指示事項</Heading>
      <RowOne>
        <HeadLabel>予定表</HeadLabel>
        <Controller
          name={'making.instructions.imageDirectionOnSchedule'}
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditKanaField
              maxLength={80}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
            />
          )}
        />
      </RowOne>
      <RowOne>
        <HeadLabel>指示書</HeadLabel>
        <Controller
          name={'making.instructions.imageDirectionOnState'}
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditKanaTextAreaField
              maxLength={400}
              onChange={onChange}
              value={value}
              name={name}
              style={{ width: '100%', height: '3rem' }}
            />
          )}
        />
      </RowOne>

      <Box my={6} />
      <Heading>製版指示事項</Heading>
      <RowOne>
        <HeadLabel>予定表</HeadLabel>
        <Controller
          name={'making.instructions.cylMakingDirectionOnSchedule'}
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditKanaField
              maxLength={80}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
            />
          )}
        />
      </RowOne>
      <RowOne>
        <HeadLabel>指示書</HeadLabel>
        <Controller
          name={'making.instructions.cylMakingDirectionOnState'}
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditKanaTextAreaField
              maxLength={400}
              onChange={onChange}
              value={value}
              name={name}
              style={{ width: '100%', height: '3rem' }}
            />
          )}
        />
      </RowOne>

      <Box my={6} />
      <Heading>出荷指示事項</Heading>
      <RowOne>
        <HeadLabel>予定表</HeadLabel>
        <Controller
          name={'making.instructions.shipmentDirectionOnSchedule'}
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditKanaField
              maxLength={80}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
            />
          )}
        />
      </RowOne>
      <RowOne>
        <HeadLabel>指示書</HeadLabel>
        <Controller
          name={'making.instructions.shipmentDirectionOnState'}
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditKanaTextAreaField
              maxLength={400}
              onChange={onChange}
              value={value}
              name={name}
              style={{ width: '100%', height: '3rem' }}
            />
          )}
        />
      </RowOne>

      <Box my={6} />
      <Heading>調査指示事項</Heading>
      <RowGrid>
        <BodyGrid xs={12}>
          <Controller
            name={'making.instructions.chousaDirection'}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <EditKanaField
                maxLength={2000}
                multiline
                rows={7}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default React.memo(Instructions);
