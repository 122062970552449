import { Region } from 'constants/define';
import { useAuth } from 'hooks/useAuth';
import React from 'react';

import { HeadLabel } from 'components/parts/atoms';

import { Heading } from '../../Heading';
import { HeadRow } from './Row';
import UnitPriceCylMakingRow from './UnitPriceCylMakingRow';
import UnitPriceHanberiMakingRow from './UnitPriceHanberiMakingRow';
import UnitPriceKyushuMakingRow from './UnitPriceKyushuMakingRow';
import UnitPriceRow from './UnitPriceRow';

/** 価格情報 工程あたり単価 */
const UnitPrice: React.VFC = () => {
  // セッション情報
  const { session } = useAuth();
  if (!session) {
    throw new Error();
  }

  return (
    <>
      <Heading>工程あたり単価</Heading>
      <HeadRow>
        <></>
        <HeadLabel>単価</HeadLabel>
        <HeadLabel>表面積</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>

      <UnitPriceRow
        label='母材'
        propertyName='materials'
        minimumPricePropertyName='materialMinimumPrice9'
      />
      <UnitPriceRow
        label='銅メッキ'
        propertyName='cuPlate'
        minimumPricePropertyName='cuPlateMinimum9'
      />
      <UnitPriceRow
        label='クロムメッキ'
        propertyName='crPlate'
        minimumPricePropertyName='crPlateMinimum9'
      />
      <UnitPriceRow label='仕上研磨' propertyName='finishingPolish' />
      <UnitPriceRow
        label='落版研磨'
        propertyName='rakuhanPolish'
        minimumPricePropertyName='rakuhanMinimum9'
      />
      <UnitPriceRow
        label='再クロム'
        propertyName='reCr'
        minimumPricePropertyName='reCrPlateMinimum9'
      />

      <HeadRow>
        <></>
        <HeadLabel>単価</HeadLabel>
        <HeadLabel>円周割増率</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>

      <UnitPriceCylMakingRow label='製版代' />

      {session.region === Region.Kyushu && <UnitPriceKyushuMakingRow />}

      <HeadRow>
        <></>
        <HeadLabel>製版代</HeadLabel>
        <HeadLabel>版べり割率</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>

      <UnitPriceHanberiMakingRow label='製版代 (版べり)' />
    </>
  );
};

export default React.memo(UnitPrice);
