import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState, useEffect } from 'react';

import { TodayPlanRes } from 'api/types/common/todayPlan';

import { initialTodayPlan } from './defaults';
import { TodayPlan as TodayPlanType } from './types';

type TodayPlan = {
  todayPlanData: TodayPlanType;
};
export const useTodayPlan = (): TodayPlan => {
  // Hooks
  const { pushMessages } = useSharedMessage();
  const { apiClient } = useApiClient(pushMessages);

  // 本日予定State
  const [todayPlanData, setTodayPlanData] = useState<TodayPlanType>(initialTodayPlan);

  /** 本日の予定初期表示時の */
  useEffect(() => {
    (async () => {
      const [todayPlanRes] = await Promise.all([
        apiClient.get<TodayPlanRes>('/api/v1/TodayPlan/GetTodayPlan'),
      ]);

      console.log(todayPlanRes);
      if (todayPlanRes.data.result) {
        setTodayPlanData(todayPlanRes.data.result.todayPlan);
      }
    })();
  }, []);

  return { todayPlanData };
};
