import React from 'react';

import { HeadLabel } from 'components/parts/atoms';

import { HeadRow } from './Row';

/** 印刷面計算 行ヘッダーコンポーネント */
const MakingPriceRowHeader: React.VFC = () => {
  return (
    <HeadRow>
      <HeadLabel>工程</HeadLabel>
      <HeadLabel>単価</HeadLabel>
      <HeadLabel>表面積</HeadLabel>
      <HeadLabel>版数</HeadLabel>
      <HeadLabel>売価</HeadLabel>
      <HeadLabel>東洋売価率</HeadLabel>
      <HeadLabel>仕切</HeadLabel>
    </HeadRow>
  );
};

export default MakingPriceRowHeader;
