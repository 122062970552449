import { ProgressDetailResponse, ImageStatusModel } from './types';

export const defaultImageStatusModel: ImageStatusModel = {
  designstatus: '#000000',
  photoretouchstatus: '#000000',
  colorseparationstatus: '#000000',
  presentallstatus: '#000000',
  inspectionstatus: '#000000',
  presentstatus: '#000000',
  approvedstatus: '#000000',
  layoutstatus: '#000000',
  designemployee: '',
  photoretouchemployee: '',
  colorseparationemployee: '',
  presentallemployee: '',
  inspectionemployee: '',
  presentemployee: '',
  approvedemployee: '',
  layoutemployee: '',
};
export const defaultProgressDetail: ProgressDetailResponse = {
  imageStatusList: defaultImageStatusModel,
  makingStatusList: [],
};
