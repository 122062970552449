import React, { useCallback, useRef } from 'react';

import { Box, Alert, AlertColor } from '@mui/material';
import { styled } from '@mui/material/styles';

import { MessageProps, types, Types } from './types';

const alertTypeMap: { [key: Types]: AlertColor } = {
  [types.ERROR]: 'error',
  [types.WARNING]: 'warning',
  [types.INFO]: 'info',
};

const StyledMessage = styled(Box)(() => ({ width: '100%' }));

const Message: React.FC<MessageProps> = ({ message, onRemove, sx, ...props }) => {
  const { id, text, type } = message;

  const removeHandler = useRef(onRemove);
  const handleRemove = useCallback(() => removeHandler.current(id), [id, removeHandler]);

  return (
    <StyledMessage sx={sx} {...props}>
      <Alert severity={alertTypeMap[type]} onClose={handleRemove}>
        {text}
      </Alert>
    </StyledMessage>
  );
};

export default Message;
