import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  NumericField,
} from 'components/parts/atoms';

import { CylinderForm } from '../../types';

const Cylinder9Infos: React.VFC = () => {
  // フォームデータ
  const unitPriceCylinderFormContext = useFormContext<CylinderForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            九州向け製版関連オプション
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>母材_最低</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='materialMinimumPrice9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>母材_特寸</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='materialSpecialRateRate9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={9.99}
                decimalDigits={2}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>銅メッキ_最低</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='cuPlateMinimum9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>クロム_最低</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='crPlateMinimum9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>落版_最低</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='rakuhanMinimum9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>製版シート_最低</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='cylMakingMinimum9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>追加腐食_最低</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='additionalCorrosionMinimum9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>再クロム_最低</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='reCrPlateMinimum9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>セレクト_補刻</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='selectrons9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>青焼</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='aoyaki9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>POS</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='pos9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={99999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>歩引率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='bubikiRate9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={9.99}
                decimalDigits={2}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>値引率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='discountRate9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={9.99}
                decimalDigits={2}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>割増率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='warimasiRate9'
            control={unitPriceCylinderFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={9.99}
                decimalDigits={2}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default Cylinder9Infos;
