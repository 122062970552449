import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { OnlyTypeKey } from 'utils/typeUtils';

import { useMakingOptionPriceRow } from '../../../hooks/price/priceRow/useMakingOptionPriceRow';
import {
  CylinderOrderEditForm,
  Price,
  MakingOptionPrice,
  MakingOptionPriceRow as MakingOptionPriceRowType,
  MinimumPriceKey,
} from '../../../types';
import { UnitPriceField, UnitPriceFixedField } from '../Fields';
import { Row } from './Row';

export type MakingOptionPriceRowProps = {
  label: string;
  pricePropertyName: OnlyTypeKey<Price, MakingOptionPrice>;
  rowPropertyName: OnlyTypeKey<MakingOptionPrice, MakingOptionPriceRowType>;
  cylMakingPrice: number;
  minimumPriceKey?: MinimumPriceKey;
};

/** 製版価格 行コンポーネント */
const MakingOptionPriceRow: React.VFC<MakingOptionPriceRowProps> = ({
  label,
  pricePropertyName,
  rowPropertyName,
  cylMakingPrice,
  minimumPriceKey,
}) => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { price, toyoPriceRate, grossInvoice } = useMakingOptionPriceRow(
    pricePropertyName,
    rowPropertyName,
    cylMakingPrice,
    minimumPriceKey,
  );

  return (
    <Row>
      <HeadLabel>{label}</HeadLabel>
      <BodyLabel>{cylMakingPrice.toFixed()}</BodyLabel>
      <Controller
        name={`price.${pricePropertyName}.${rowPropertyName}.scale`}
        control={control}
        render={({ field }) => (
          <UnitPriceFixedField
            min={0}
            max={999.9}
            decimalDigits={1}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
          />
        )}
      />
      <Controller
        name={`price.${pricePropertyName}.${rowPropertyName}.amount`}
        control={control}
        render={({ field }) => (
          <UnitPriceField
            min={0}
            max={99}
            decimalDigits={0}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
          />
        )}
      />
      <BodyLabel>{price.toFixed()}</BodyLabel>
      <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
      <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
    </Row>
  );
};

export default React.memo(MakingOptionPriceRow);
