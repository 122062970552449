import * as React from 'react';

import { FileDownload } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type CsvButtonProps = CustomButtonProps;

const CsvButton: React.VFC<CsvButtonProps> = (props) => {
  return (
    <CustomButton icon={<FileDownload />} {...props}>
      {'CSV'}
    </CustomButton>
  );
};

export default CsvButton;
