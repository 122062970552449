import React from 'react';

import { Box } from '@mui/material';

import DraftReturns from './DraftReturns';
import Outsourcing from './Outsourcing';
import Presents from './Presents';
import ScheduleDate from './ScheduleDate';

/** フレキソ受注 作業予定コンポーネント */
const Schedule: React.VFC = () => {
  return (
    <>
      <ScheduleDate />
      <Box my={6} />
      <Outsourcing />
      <Box my={6} />
      <DraftReturns />
      <Box my={6} />
      <Presents />
    </>
  );
};

export default Schedule;
