import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { ProductionEditModel } from 'pages/productionManage/Production/types';

import {
  KanaField,
  ConfirmButton,
  RowGrid,
  HeadGrid,
  BodyGrid,
  BodyLabel,
  HeadLabel,
  CloseButton,
} from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules';

type Props = {
  onCloseClick: () => void;
  onConfirmClick: () => void;
};

const ProductionEdit: React.VFC<Props> = (props) => {
  // フォーム情報
  const formContext = useFormContext<ProductionEditModel>();

  return (
    <Box>
      <Box>
        <RowGrid>
          <HeadGrid xs={3}>
            <HeadLabel>PRODUCTION No</HeadLabel>
          </HeadGrid>
          <BodyGrid xs={9}>
            <Controller
              name='productionNo'
              control={formContext.control}
              render={({ field: { value } }) => <BodyLabel>{value}</BodyLabel>}
            />
          </BodyGrid>
        </RowGrid>
        <RowGrid>
          <HeadGrid xs={3}>
            <HeadLabel>PRODUCTION名</HeadLabel>
          </HeadGrid>
          <BodyGrid xs={9}>
            <Controller
              name='productionName'
              control={formContext.control}
              render={({ field: { onChange, value, name } }) => (
                <KanaField
                  onChange={onChange}
                  value={value}
                  name={name}
                  maxLength={40}
                  sx={{ minWidth: '15rem' }}
                />
              )}
            />
          </BodyGrid>
        </RowGrid>
        <RowGrid>
          <HeadGrid xs={3}>
            <HeadLabel>PRODUCTION半角カナ</HeadLabel>
          </HeadGrid>
          <BodyGrid xs={9}>
            <Controller
              name='productionNameHankakukana'
              control={formContext.control}
              render={({ field: { onChange, value, name } }) => (
                <KanaField
                  onChange={onChange}
                  value={value}
                  name={name}
                  maxLength={40}
                  sx={{ minWidth: '15rem' }}
                />
              )}
            />
          </BodyGrid>
        </RowGrid>
      </Box>

      <Box marginTop={8}>
        <FooterButtonsContainer>
          <CloseButton onClick={props.onCloseClick} />
          <ConfirmButton onClick={props.onConfirmClick} />
        </FooterButtonsContainer>
      </Box>
    </Box>
  );
};

export default ProductionEdit;
