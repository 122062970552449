import useApiClient from 'hooks/useApiClient';
import useMessage, { MessageApi } from 'hooks/useMessage';
import { useState } from 'react';

import { ImageSearchResponse } from 'api/types/imageServer/common';

import { ImageSearchDisplayModel, ImageSearchParameter } from 'pages/common/ImageDataSearch/types';

import { SelectOption } from 'components/parts/atoms/Select/types';

type useFetchImageListType = {
  imageList: ImageSearchDisplayModel[];
  employeeList: SelectOption[];
  pageSize: number;
  loading: boolean;
  messageApi: MessageApi;
  getEmployeeList: () => Promise<void>;
  searchImageList: (params: ImageSearchParameter) => Promise<void>;
  clearImageList: () => void;
};

/** 画像検索(ダイアログ)のHooks */
export const useFetchImageList = (): useFetchImageListType => {
  const messageApi = useMessage();
  const { apiClient } = useApiClient(messageApi.pushMessages);

  /** 画像検索結果のリスト */
  const [imageList, setImageList] = useState<ImageSearchDisplayModel[]>([]);

  /** 営業担当者のリスト */
  const [employeeList, setEmployeeList] = useState<SelectOption[]>([]);

  /** 画像検索結果の表示件数 */
  const [pageSize, setPageSize] = useState<number>(100);

  /** 検索ローディング */
  const [loading, setLoading] = useState<boolean>(false);

  /** 営業担当者のリストを取得 */
  const getEmployeeList = async () => {
    try {
      const response = await apiClient.get<SelectOption[]>(`/api/v1/SalesEmpoyee/GetSalesEmpoyees`);
      setEmployeeList(response.data.result ?? []);
    } catch (e) {
      console.log(e);
    }
  };

  /** 画像検索 */
  const searchImageList = async (params: ImageSearchParameter) => {
    setImageList([]);
    setLoading(true);

    try {
      const response = await apiClient.get<ImageSearchResponse>(
        `/api/v1/ImageDataSearch/GetImageList`,
        {
          params,
        },
      );

      // Stateにセット
      setImageList(response.data.result?.imageList ?? []);
      setPageSize(response.data.result?.pageSize ?? 100);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  /** 検索結果のクリア */
  const clearImageList = () => {
    setImageList([]);
  };

  return {
    imageList,
    employeeList,
    pageSize,
    loading,
    messageApi,
    getEmployeeList,
    searchImageList,
    clearImageList,
  };
};
