// 作成する画面のリンクをこの配列に追加してください。
export const devLinks: DevLink[] = [
  { name: `サンプルページ`, to: '/counter' },
  { name: `ポータル TOP`, to: '/portal' },
  { name: `マイメモ`, to: '/MymemoEdit' },
  { name: `受注一覧`, to: '/OrderList' },
  { name: `得意先・PRODUCTION選択`, to: '/ProductionSelect' },
  { name: `WORK/連絡票登録`, to: '/WorkNoticeEdit/478896' },
  { name: `シリンダー受注入力`, to: '/CylinderOrderEdit' },
  { name: `書類棚検索`, to: '/DocumentSearchList' },
  { name: `シリンダー受注入力`, to: '/CylinderOrder' },
  { name: `製版指示書(履歴)`, to: '/MakingSpecificationHistory/Cylinder' },
  { name: `フレキソ製版指示書(履歴)`, to: '/MakingSpecificationHistory/Flexo' },
  { name: `メッセージ一覧`, to: '/MessageList' },
  { name: `入荷一覧`, to: '/ArrivalScheduleList' },
  { name: `JOB/得意先検索`, to: '/ProductionCustomerSearch' },
  { name: `JOB別作業一覧`, to: '/ImageJobWorkReportList/495517' },
  { name: `メッセージ編集`, to: '/MessageEdit/E-0001' },
  { name: `プロダクション編集`, to: '/Production/10000' },
  { name: `得意先一覧`, to: '/CustomerList' },
  { name: `作業記録検索`, to: '/ImageWorkReportSearch' },
  { name: `監査証跡一覧`, to: '/AuditLogList' },
  { name: `システムプレファレンス一覧`, to: '/SystemPreferencesList' },
  { name: `パスワード変更`, to: '/PasswordChange' },
  { name: `画像作業予定作成一覧`, to: '/ImageWorkSchedule' },
  { name: `画像作業振分け 提出物編集`, to: '/ImageWorkPresent/479906' },
  { name: `製版予定作成一覧`, to: '/MakingSchedule' },
  { name: `画像作業振分け 入稿編集`, to: '/ImageWorkDraft/476435' },
  { name: `画像提出物作業記録`, to: '/ImageWorkEditPresentAll/489198' }, // 489198 489818
  { name: `画像作業振分け一覧`, to: '/ImageWorkAssignList' },
  { name: `出荷予定一覧`, to: '/ShipmentScheduleList' },
  { name: `製版予定一覧`, to: '/SeihanScheduleList' },
  { name: `保留・STOP一覧`, to: '/DefermentList' },
  { name: `出荷優先一覧`, to: '/ShipmentPriorityList' },
  { name: `画像作業予定一覧`, to: '/ImageWorkScheduleList' },
  { name: `コードメンテナンスマスタ一覧`, to: '/CodeList' },
  { name: `仕上げ バーコード読込`, to: '/BarcodeReadFinishing/433004' },
  { name: `レーザー設定値入力`, to: '/LaserData/489515' },
  { name: `書類棚管理メニュー`, to: '/ManuscriptMenu' },
  { name: `帳票テストページ`, to: '/TestPrint' },
  { name: `納品書発行一覧(北海道)`, to: '/DeliveryListHokkaido' },
];

export type DevLink = {
  name: string;
  to: string;
};
