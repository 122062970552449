import { useState, useCallback } from 'react';

import { ResponseMessage } from 'api/types/common/apiResponse';

import { Message, messageTypes } from 'components/Message';

import { splitMessages } from 'utils/apiResponse';

type MessageSignature = (text: Message['text']) => void;
export interface MessageApi {
  messages: Message[];
  clear: () => void;
  remove: (id: string) => void;
  pushError: MessageSignature;
  pushWarning: MessageSignature;
  pushInfo: MessageSignature;
  pushMessages: (messages: ResponseMessage[]) => void;
}

const useMessage = (): MessageApi => {
  const [messages, setMessages] = useState<MessageApi['messages']>([]);

  const message = useCallback(
    ({ text, type }: Omit<Message, 'id'>) => {
      setMessages((prevMessages) => {
        const id = text;
        // Remove any existing messages with the same id
        const currentMessages = prevMessages.filter((prevMessage) => prevMessage.id !== id);

        return [
          ...currentMessages,
          {
            id,
            text,
            type,
          },
        ];
      });
    },
    [setMessages],
  );

  const pushError = (text: Message['text']) => {
    return message({ text, type: messageTypes.ERROR });
  };
  const pushWarning = (text: Message['text']) => {
    return message({ text, type: messageTypes.WARNING });
  };
  const pushInfo = (text: Message['text']) => {
    return message({ text, type: messageTypes.INFO });
  };

  const pushMessages = (messages: ResponseMessage[]) => {
    const [errors, warnings, infos] = splitMessages(messages);
    for (const error of errors) {
      pushError(error.message);
    }
    for (const warning of warnings) {
      pushWarning(warning.message);
    }
    for (const info of infos) {
      pushInfo(info.message);
    }
  };

  const clear = () => setMessages([]);
  const remove = (id: string) => {
    setMessages((prevMessages) => prevMessages.filter((prevMessage) => prevMessage.id !== id));
  };

  return { messages, clear, remove, pushError, pushWarning, pushInfo, pushMessages };
};

export default useMessage;
