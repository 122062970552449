import React, { ReactNodeArray } from 'react';

import { RowGrid } from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

/** シリンダー受注 受注製版情報 1列コンポーネント */
export const RowOne: React.FC<{ children: ReactNodeArray }> = ({ children: [label, body] }) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={2} bxs={10}>
          {label}
          {body}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

/** シリンダー受注 受注製版情報 2列コンポーネント */
export const RowTwo: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo],
}) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={2} bxs={5}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
        <ContentsGrid hxs={1} bxs={4}>
          {labelTwo}
          {bodyTwo}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};
/** シリンダー受注 受注製版情報 3列コンポーネント */
export const RowThree: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne, labelTwo, bodyTwo, labelThree, bodyThree],
}) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={2} bxs={5}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
        <ContentsGrid hxs={1} bxs={1.5}>
          {labelTwo}
          {bodyTwo}
        </ContentsGrid>
        <ContentsGrid hxs={1} bxs={1.5}>
          {labelThree}
          {bodyThree}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};
