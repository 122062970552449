import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  NumericField,
  KanaField,
} from 'components/parts/atoms';

import { FlexoForm } from '../../types';

const FlexoBasicInfos: React.VFC = () => {
  // フォームデータ
  const unitPriceFlexoFormContext = useFormContext<FlexoForm>();

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            基本情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>単価種別</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <BodyLabel>フレキソ製版</BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>単価名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='unitPriceName'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={25}
                sx={{ minWidth: '35rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>説明</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='unitPriceExplanation'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={180}
                multiline
                rows={5}
                sx={{ minWidth: '40rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={6}>
        <HeadGrid xs={1}>
          <HeadLabel>東洋売価率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={5}>
          <Controller
            name='toyoPriceRate'
            control={unitPriceFlexoFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0.0}
                max={9.999}
                decimalDigits={3}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
    </>
  );
};

export default FlexoBasicInfos;
