import { useEffect } from 'react';

import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { useResetPrice } from '../useResetPrice';

/** シリンダ単価変更時にフォームをリセットするHooks */
export const useUnitPriceWatcher = (enabled: boolean): void => {
  // フォーム
  const { cylUnitPrice, cylSizeEnsyuu } = usePriceCalculateMatter();
  const { resetUnitPrice } = useResetPrice();

  useEffect(() => {
    if (!enabled) return;

    resetUnitPrice(cylUnitPrice, cylSizeEnsyuu);
  }, [cylUnitPrice]);
};
