import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState, useEffect } from 'react';

import {
  OtherOrderEditForm,
  OtherOrderEditFormRes,
  OtherOrderEditMaster,
  OtherOrderMetaData,
  GetOtherOrderMetaDataRes,
} from 'api/types/topSales/order/otherOrderEdit';

/** その他受注詳細ダイアログのHooks */
export const useOtherOrderEdit = (
  jobId: number | null,
  messageApi: MessageApi,
): {
  form: OtherOrderEditForm;
  master: OtherOrderEditMaster;
  oderMetaData: OtherOrderMetaData;
} | null => {
  // Hooks
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // State
  const [orderData, setOrderData] = useState<{
    form: OtherOrderEditForm;
    master: OtherOrderEditMaster;
    oderMetaData: OtherOrderMetaData;
  } | null>(null);

  /** その他受注データ取得処理 */
  const fetch = async (jobId: number) => {
    const [formResponse, metaDataResponse] = await Promise.all([
      apiClient.get<OtherOrderEditFormRes>('/api/v1/OtherOrderEdit/GetOtherOrderEditForm', {
        params: { jobId },
      }),
      apiClient.get<GetOtherOrderMetaDataRes>(`/api/v1/OtherOrder/GetOrderMetaData`, {
        params: { jobId },
      }),
    ]);

    if (formResponse.data.result && metaDataResponse.data.result) {
      setOrderData({
        form: formResponse.data.result.otherOrderEditForm,
        master: formResponse.data.result.master,
        oderMetaData: metaDataResponse.data.result.orderMetaData,
      });
    }
  };

  useEffect(() => {
    if (jobId !== null) {
      fetch(jobId);
    } else {
      setOrderData(null);
    }
  }, [jobId]);

  return orderData;
};
