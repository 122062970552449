import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';

import { BodyLabel } from 'components/parts/atoms';

import { useOtherProductInfoRow } from '../../../hooks/price/priceRow/useOtherProductInfoRow';
import { OtherOrderEditForm } from '../../../types';
import { PriceCheckbox, PriceIconButton, PriceKanaField, PriceNumericField } from '../Fields';
import { Row } from './Row';

type Props = {
  index: number;
  isFixedItems: boolean;
  onDeleteClicked: () => void;
};

/** その他（画像等） 行コンポーネント */
const OtherProductInfoRow: React.VFC<Props> = ({ index, isFixedItems, onDeleteClicked }) => {
  // コンテキスト
  const { control } = useFormContext<OtherOrderEditForm>();

  const { grossInvoice, priceSum, grossInvoiceSum } = useOtherProductInfoRow(index);

  return (
    <>
      <Row>
        <Controller
          name={`price.otherProductInfos.${index}.itemName`}
          control={control}
          render={({ field }) => (
            <PriceKanaField
              maxLength={40}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              disabled={isFixedItems}
              sx={{ width: '100%' }}
            />
          )}
        />
        <Controller
          name={`price.otherProductInfos.${index}.price`}
          control={control}
          render={({ field }) => (
            <PriceNumericField
              min={-99999999}
              max={99999999}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '7em' }}
              labelWidth={'grow'}
            />
          )}
        />
        <Controller
          name={`price.otherProductInfos.${index}.toyoPriceRateFlg`}
          control={control}
          render={({ field }) => (
            <PriceCheckbox onChange={field.onChange} checked={field.value} name={field.name} />
          )}
        />
        <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
        <Controller
          name={`price.otherProductInfos.${index}.count`}
          control={control}
          render={({ field }) => (
            <PriceNumericField
              min={0}
              max={999}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '4em' }}
              labelWidth={'grow'}
            />
          )}
        />
        <BodyLabel>{priceSum.toFixed()}</BodyLabel>
        <BodyLabel>{grossInvoiceSum.toFixed()}</BodyLabel>

        {!isFixedItems && (
          <PriceIconButton onClick={onDeleteClicked}>
            <DeleteIcon />
          </PriceIconButton>
        )}
        {isFixedItems && <></>}
      </Row>
    </>
  );
};

export default React.memo(OtherProductInfoRow);
