import { MakingScheduleListCondition } from './types';

/** 製版予定作成一覧の検索条件のストアモデルの初期値 */
export const InitialMakingScheduleListCondition: MakingScheduleListCondition = {
  searchFlg: false,
  jobNo: '',
  customerName: '',
  productionName: '',
  factoryId: '',
  searchDate: null,
  createdFlg: false,
};
