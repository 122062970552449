import { PlateMakingCode, SummaryCode } from 'constants/define';
import React from 'react';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { OnlyTypeKey } from 'utils/typeUtils';

import { useMakingPriceRow } from '../../../hooks/price/priceRow/useMakingPriceRow';
import { UnitPrice, UnitPriceRow } from '../../../types';
import { Row } from './Row';

export type MakingPriceRowProps = {
  label: string;
  plateMakingCodes: PlateMakingCode[] | null;
  summaryCodes: SummaryCode[] | null;
  unitPricePropertyNames: Exclude<OnlyTypeKey<UnitPrice, UnitPriceRow>, 'cylMaking'>[];
  cylMakingPrice: number;
};

/** 製版区分、摘要で集計した単価 行コンポーネント */
const MakingPriceRow: React.VFC<MakingPriceRowProps> = ({
  label,
  plateMakingCodes,
  summaryCodes,
  unitPricePropertyNames,
  cylMakingPrice,
}) => {
  const { unitPrice, amount, price, toyoPriceRate, grossInvoice } = useMakingPriceRow(
    plateMakingCodes,
    summaryCodes,
    unitPricePropertyNames,
    cylMakingPrice,
  );

  return (
    <Row>
      <HeadLabel>{label}</HeadLabel>
      <BodyLabel>{unitPrice.toFixed()}</BodyLabel>
      <></>
      <BodyLabel>{amount.toFixed()}</BodyLabel>
      <BodyLabel>{price.toFixed()}</BodyLabel>
      <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
      <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
    </Row>
  );
};

export default React.memo(MakingPriceRow);
