import { HistoryStatus } from 'constants/define';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { KeyboardArrowDown } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { MenuCategory, menuLinkType } from 'api/types/portalTop/portal';

import { Button, Menu, MenuItem, Typography } from 'components/parts/atoms';

export type LinkItem = {
  id: string;
  text: string;
  to: string;
};

type Props = {
  title: string;
  menuCategory?: MenuCategory;
};
const Wrapper = styled('div')(() => ({
  height: '100%',
}));

const StyledButton = styled(Button)(() => ({
  width: '100px',
  height: '100%',
}));

const LinkItem = styled(MenuItem)(() => ({}));
const LabelItem = styled(Typography)(() => ({}));

const MenuButton: React.VFC<Props> = (props) => {
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const handleLink = (link?: string) => {
    // 表示中の画面と同じメニューが選択された時に初期表示に戻すため、ユニークな値をstateに渡す
    if (link) {
      history.push('/' + link, { initialDisplay: HistoryStatus.InitialDisplay, routeUniqueKey });
      setRouteUniqueKey(`${Math.random()}`);
    }
    closeMenu();
  };

  return (
    <>
      <Wrapper>
        <StyledButton
          aria-controls='simple-menu'
          aria-haspopup='true'
          variant='text'
          color='primary'
          size='small'
          endIcon={<KeyboardArrowDown />}
          onClick={handleClick}
        >
          {props.title}
        </StyledButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          {props.menuCategory?.menuItems?.map((menuItem) => {
            switch (menuItem.linkFlg) {
              case menuLinkType.LINK:
                return (
                  <LinkItem key={menuItem.menuId} onClick={() => handleLink(menuItem.actionString)}>
                    {menuItem.menuName}
                  </LinkItem>
                );

              case menuLinkType.LABEL:
                return (
                  <LabelItem key={menuItem.menuId} onClick={() => closeMenu()}>
                    {menuItem.menuName}
                  </LabelItem>
                );
            }
          })}
        </Menu>
      </Wrapper>
    </>
  );
};

export default MenuButton;
