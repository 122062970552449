import { CustomerEditTabIndex } from 'constants/define';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import CustomerEditBasic from './components/tabCustomerDetailBasic';
import DeliveryAddressEdit from './components/tabCustomerDetailDeliveryAddressEdit';
import CustomerEditPayment from './components/tabCustomerDetailPayment';
import UnitPriceMain from './components/tabCustomerDetailUnitPrice';
import {
  HeadLabel,
  BodyLabel,
  BodyGrid,
  HeadGrid,
  RowGrid,
} from 'components/parts/atoms';

import { defaultHeader } from '../defaults';
import { CustomerEdit, CustomerEditHeader } from '../types';

const CustomerDetailPage: React.VFC = () => {
  // フォーム情報
  const formContext = useFormContext<CustomerEdit>();

  // タブ
  const [tabValue, setTabValue] = useState<CustomerEditTabIndex>('1');

  // Key
  const [key, setKey] = useState<number>(1);

  const tabChange = (event: React.SyntheticEvent, newValue: CustomerEditTabIndex) => {
    setTabValue(newValue);
  };

  // 初期表示時のヘッダー部分表示
  const [headerParams, setHeaderParams] = useState<CustomerEditHeader>(defaultHeader);
  useEffect(() => {
    setKey(key + 1);
    const setData: CustomerEditHeader = {
      customerCd: formContext.getValues('customerEditSingleInfo.customerCd'),
      customerName: formContext.getValues('customerEditSingleInfo.customerName'),
      customerSectionCd: formContext.getValues('customerEditSingleInfo.customerSectionCd'),
    };
    setHeaderParams(setData);
  }, [formContext]);

  return (
    <>
      <Box m={2} />

      <RowGrid columns={4}>
        <HeadGrid xs={1}>
          <HeadLabel>部門コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <BodyLabel>{headerParams.customerSectionCd}</BodyLabel>
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>得意先コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <BodyLabel>{headerParams.customerCd}</BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={4}>
        <HeadGrid xs={1}>
          <HeadLabel>得意先表示名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={3}>
          <BodyLabel>{headerParams.customerName}</BodyLabel>
        </BodyGrid>
      </RowGrid>

      <Box m={2} />

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1 }}>
          <TabList onChange={tabChange}>
            <Tab label='基本情報' value={CustomerEditTabIndex.Basic}></Tab>
            <Tab label='入金情報' value={CustomerEditTabIndex.Payment}></Tab>
            <Tab label='単価登録' value={CustomerEditTabIndex.UnitPrice}></Tab>
            <Tab label='納入先' value={CustomerEditTabIndex.Delivery}></Tab>
          </TabList>
        </Box>
        <TabPanel value={CustomerEditTabIndex.Basic}>
          <CustomerEditBasic key={key}/>
        </TabPanel>
        <TabPanel value={CustomerEditTabIndex.Payment}>
          <CustomerEditPayment key={key}/>
        </TabPanel>
        <TabPanel value={CustomerEditTabIndex.UnitPrice}>
          <UnitPriceMain key={key}/>
        </TabPanel>
        <TabPanel value={CustomerEditTabIndex.Delivery}>
          <DeliveryAddressEdit key={key}/>
        </TabPanel>
      </TabContext>
      <Box m={2} />
    </>
  );
};

export default CustomerDetailPage;
