import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './defaults';
import { CodeListSearchForm } from './types';

const slice = createSlice({
  name: 'SearchForm',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<CodeListSearchForm>) => action.payload,
    clear: () => ({ ...initialState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
