import { UrlModel } from 'api/types/topSales/order/orderEditCommon';

import { OtherOrderEditForm, OtherOrderEditMaster, SplittedDateTime } from './types';

export * from 'api/types/topSales/order/otherOrderEdit';

const defaultSplittedDateTime: SplittedDateTime = {
  day: null,
  timeDiv: '',
  hour: '',
  minute: '',
};

const defaultUrl: UrlModel = {
  urlId: null,
  url: '',
  urlName: '',
};

/** 受注入力モデルの初期データ */
export const initialOtherOrderEdit: OtherOrderEditForm = {
  header: {
    tjobRecordRegistDay: null,
    tjobRecordEditDay: null,
    issueDate: null,
    registEmployeeName: '',
    editEmployeeName: '',
    jobNo: '',
    referenceJobNo: '',
    seriesMasterId: null,
    seriesMaster: '',
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
  },
  basis: {
    jobNo: '',
    chargedEmployeeId: '',
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
    companyCd: '',
    customerChargeId: '',
    customerOrderNo: '',
    myJobNo: '',
    oldJobNo: '',
    eigyoItemCdId: '',
    productionId: null,
    productionName: '',
    jobName: '',
    jobKanaName: '',
    demandCustomerSectionCd: '',
    demandCustomerCd: '',
    demandCustomerName: '',
    deliveryPlace: {
      deliveryPlaceId: '',
      name: '',
      zipCd: '',
      prefectureId: '',
      address1: '',
      address2: '',
      builName: '',
      tel: '',
    },
    stateFlg: false,
  },
  price: {
    scheduleDate: {
      orderDate: null,
      appropriateDate: null,
      deliveryDate: defaultSplittedDateTime,
    },
    othUnitPriceId: '',
    toyoPriceRate: null,
    otherProductInfos: [],
    statementOfDeliveryNote: '',
    totalPrice: {
      price: 0,
      grossInvoice: 0,
    },
    approvalFlg: false,
    approvalName: null,
    secondApprovalName: null,
    approvalDate: null,
    secondApprovalDate: null,
    priceNote: '',
    secondApprovalFlg: false,
    customerUserFlg: false,
    url: defaultUrl,
  },
};

/** 受注入力マスタモデルの初期データ */
export const initialOtherMaster: OtherOrderEditMaster = {
  chargedEmployeeList: [],
  customerChargeList: [],
  eigyoItemList: [],
  deliveryPlaceList: [],
  prefecturesList: [],
  othUnitPriceList: [],
  othUnitPriceSelectList: [],
};
