import { ImageDataUpLoadState } from './types';

/** 画像アップロードストアの初期値 */
export const initialState: ImageDataUpLoadState = {
  processTarget: null,
  fromPage: null,
  page: {
    isAddMode: false,
    initialProcessState: 'unProcessed',
    destinations: [],
    isAddable: false,
  },
};
