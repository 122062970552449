import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialSearchConditionState } from 'store/topEngraving/EngravingWorkList/defaults';

import { SearchConditionState } from './types';

const slice = createSlice({
  name: 'engravingJobList',
  initialState: InitialSearchConditionState,
  reducers: {
    save: (state, action: PayloadAction<SearchConditionState>) => action.payload,
    clear: () => ({ ...InitialSearchConditionState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
