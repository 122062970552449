import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialBarcodeReadCuPlatingState } from 'store/topPlating/barcodeReadCuPlating/defaults';
import { BarcodeReadCuPlatingState } from 'store/topPlating/barcodeReadCuPlating/types';

const slice = createSlice({
  name: 'barcodeReadCuPlating',
  initialState: InitialBarcodeReadCuPlatingState,
  reducers: {
    save: (state, action: PayloadAction<BarcodeReadCuPlatingState>) => action.payload,
    clear: () => ({ ...InitialBarcodeReadCuPlatingState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
