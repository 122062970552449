import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialMakingScheduleListCondition } from 'store/makingSchedule/MakingSchedule/defaults';
import { MakingScheduleListCondition } from 'store/makingSchedule/MakingSchedule/types';

const slice = createSlice({
  name: 'makingSchedule',
  initialState: InitialMakingScheduleListCondition,
  reducers: {
    save: (state, action: PayloadAction<MakingScheduleListCondition>) => action.payload,
    clear: () => ({ ...InitialMakingScheduleListCondition }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
