import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box } from '@mui/material';

import CustomerSearch from 'pages/common/CustomerSearch';

import { HeadLabel, BodyLabel, RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms';
import CustomerSearchButton from 'components/parts/atoms/Button/CustomerSearchButton';
import { ContentsGrid } from 'components/parts/molecules/ContentsGrid';

import { SearchCondition } from '../types';

const Customer: React.VFC = () => {
  // フォーム
  const { control, setValue } = useFormContext<SearchCondition>();
  const [customer] = useWatch({ control, name: ['customerInfo'] });

  // ダイアログ
  const [openCustomerSearch, setOpenCustomerSearch] = React.useState(false);

  const placeHolder = '＊選択されていません';

  return (
    <>
      <Box>
        <Box>
          <RowGrid columns={12}>
            <HeadGrid xs={10} sx={{ p: 0 }}>
              <RowGrid columns={10}>
                <ContentsGrid hxs={3} bxs={7}>
                  <HeadLabel>部門／得意先コード</HeadLabel>
                  <BodyLabel>
                    {customer.customerSectionCd != ""
                      ? `${customer.customerSectionCd}-${customer.customerCd}`
                      : placeHolder}
                  </BodyLabel>
                </ContentsGrid>
                <ContentsGrid hxs={3} bxs={7}>
                  <HeadLabel>得意先名</HeadLabel>
                  <BodyLabel>{customer?.customerName != "" ? customer.customerName : placeHolder}</BodyLabel>
                </ContentsGrid>
              </RowGrid>
            </HeadGrid>
            <BodyGrid xs={2} sx={{ justifyContent: 'center', p: 0 }}>
              <CustomerSearchButton
                onClick={() => {
                  setOpenCustomerSearch(true);
                }}
              />
            </BodyGrid>
          </RowGrid>
        </Box>
      </Box>
      {/* ダイアログ */}
      <CustomerSearch
        open={openCustomerSearch}
        onClose={(value) => {
          if (value) {
            setValue('customerInfo', value);
          }
          setOpenCustomerSearch(false);
        }}
        showDeletedCustomer={false}
        showDemandCustomer={false}
      />
    </>
  );
};

export default Customer;
