import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PriceApprovalListSearchConditionState } from './types';

// Define the initial state
const initialPriceApprovalSearchCondition: PriceApprovalListSearchConditionState = {
  jobNo: '',
  // 未承認フラグ
  unApproved: false,
  // 第一承認済みフラグ
  firstApprovalDone: false,
  // 第二承認済みフラグ
  secondApprovalDone: false,
  // 得意先名
  customerInfo: {
    customerId: 0,
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
    demandId: 0,
    demandCustomerSectionCd: '',
    demandCustomerCd: '',
    demandCustomerName: '',
    deleteFlg: false,
  },
  // 承認者名
  approvalEmployeeName: '',
  // 検索日時条件
  selectedDateType: '',
  // 開始日
  dateStart: new Date(),
  // 終了日
  dateEnd: null,
  // 第一承認チェック
  firstApproval: false,
  // 第二承認チェック
  secondApproval: false,
  // 全体ステータス
  totalJobStatus: '',
  // 日時処理対象JOB表示フラグ
  isDailyProcessTargetJobDisplay: false,
};

const slice = createSlice({
  name: 'priceApprovalSearchCondition',
  initialState: initialPriceApprovalSearchCondition,
  reducers: {
    save: (state, action: PayloadAction<PriceApprovalListSearchConditionState>) => {
      // Even though we don't use the `state` parameter in this case, it's part of the API
      return action.payload;
    },
    clear: () => ({ ...initialPriceApprovalSearchCondition }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
