import { ResponseMessage, messageStatus } from 'api/types/common/apiResponse';

/** メッセージをinfo、warning、errorに分割する */
export function splitMessages(
  messages: ResponseMessage[],
): [errors: ResponseMessage[], warnings: ResponseMessage[], infos: ResponseMessage[]] {
  const errors = messages.filter((e) => e.status === messageStatus.ERROR);
  const warnings = messages.filter((e) => e.status === messageStatus.WARNING);
  const infos = messages.filter((e) => e.status === messageStatus.INFO);
  return [errors, warnings, infos];
}

/** メッセージにエラーメッセージが含まれているかチェック */
export function isErrorMessageExist(messages: ResponseMessage[]): boolean {
  const errors = messages.filter((x) => x.status === messageStatus.ERROR);
  if (errors.length > 0) {
    return true;
  } else {
    return false;
  }
}
