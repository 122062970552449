import { PageState, OrderEditTabIndex } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { FlexoOrderEditState } from './types';

/** フレキソ受注入力のストアモデルの初期値 */
export const InitialFlexoOrderEditState: FlexoOrderEditState = {
  pageState: PageState.Edit,
  tabIndex: OrderEditTabIndex.Basis,
  data: null,
};
