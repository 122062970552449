import * as React from 'react';

import { Delete } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type PhysicalDeleteButtonProps = CustomButtonProps;

const PhysicalDeleteButton: React.VFC<PhysicalDeleteButtonProps> = (props) => {
  return (
    <CustomButton icon={<Delete />} color={'error'} {...props}>
      {'物理削除'}
    </CustomButton>
  );
};

export default PhysicalDeleteButton;
