import { useNormalOptionPriceRow } from '../priceRow/useMakingOptionPriceRow';

/** フルコース、 改版、増版 集計情報 Hooks */
export const useNormalOptionPriceTotal = (): { price: number; grossInvoice: number } => {
  const colorCylMaking = useNormalOptionPriceRow('colorCylMaking');
  const hanchoushi = useNormalOptionPriceRow('hanchoushi');
  const choushi = useNormalOptionPriceRow('choushi');
  const renzoku = useNormalOptionPriceRow('renzoku');
  const tuikafusyoku = useNormalOptionPriceRow('tuikafusyoku');

  const rows = [colorCylMaking, hanchoushi, choushi, renzoku, tuikafusyoku];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
