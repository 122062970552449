import * as React from 'react';

import { styled } from '@mui/material/styles';

import Typography, { TypographyProps } from './Typography';

export type HeadLabelProps = {
  sx?: TypographyProps['sx'];
  px?: number;
  py?: number;
  lineThrough?: boolean;
  variant?: TypographyProps['variant'];
  fontSize?: TypographyProps['fontSize'];
};

const HeadLabel: React.FC<HeadLabelProps> = ({ children, sx, lineThrough, ...props }) => {
  return (
    <Typography {...props} sx={{ ...sx, ...(lineThrough && { textDecoration: 'line-through' }) }}>
      {children}
    </Typography>
  );
};

const StyledHeadLabel = styled(HeadLabel)(() => ({ textAlign: 'center' }));

export default StyledHeadLabel;
