import * as React from 'react';
import { useState } from 'react';

import TextField, { TextFieldProps } from './TextField';

export type AlphaNumericFieldProps = {
  maxLength: number;
  name?: TextFieldProps['name'];
  value?: TextFieldProps['value'];
  onChange?: TextFieldProps['onChange'];
  onBlur?: TextFieldProps['onBlur'];
  disabled?: TextFieldProps['disabled'];
  placeholder?: TextFieldProps['placeholder'];
  sx?: TextFieldProps['sx'];
};

const AlphaNumericField: React.VFC<AlphaNumericFieldProps> = (props) => {
  function convertZenkakuToHankaku(str: string): string {
    return str.replace(
      /[ａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ０１２３４５６７８９．！＠＃＄％＾＆＊（）＿＋｜〜｀｛｝［］；：＂＇＜＞？／ー＝]/g,
      (match) => {
        const zenkaku =
          'ａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ０１２３４５６７８９．！＠＃＄％＾＆＊（）＿＋｜〜｀｛｝［］；：＂＇＜＞？／ー＝';
        const hankaku =
          'abcdefghijklmnopqrstuvwxyzQWERTYUIOPASDFGHJKLZXCVBNM0123456789.!@#$%^&*()_+|~`{}[];:"\'<>?/-=';
        const zenkakuIndex = zenkaku.indexOf(match);
        return hankaku.charAt(zenkakuIndex);
      },
    );
  }

  function convertHarfWord(e: React.FocusEvent<HTMLInputElement>) {
    /** 全角文字を半角文字に変換 */
    const convertedValue = convertZenkakuToHankaku(e.target.value);

    // 半角の英数字、記号以外の文字の確認
    const regex = /[^!-~\uFF01-\uFF5E\u3000-\u301C\-＝]/g;
    if (regex.test(convertedValue)) {
      return; // 入力を許可しない場合、そのままreturn
    }
    if (convertedValue.length > props.maxLength) {
      return;
    }
    if (props.onChange) {
      const event: React.ChangeEvent<HTMLInputElement> = {
        ...e,
        target: {
          ...e.target,
          value: convertedValue,
        },
      };
      props.onChange?.(event);
    }

    // props.onBlurを実行する
    if (props.onBlur) {
      props.onBlur(e);
    }
  }

  return (
    <TextField
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      onBlur={convertHarfWord}
      onChange={props.onChange}
      placeholder={props.placeholder}
      inputProps={{ maxLength: props.maxLength }}
      sx={props.sx}
    />
  );
};

export default AlphaNumericField;
