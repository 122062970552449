import React from 'react';

import { OrderEditTabIndex, OtherOrderMetaData, PageState } from '../types';

export type PageContextType = {
  pageState: PageState;
  tabIndex: OrderEditTabIndex;
  setTabIndex: (tabIndex: OrderEditTabIndex) => void;
  hasFormSet: boolean;
  leavePage: (location: string) => void;
  orderMetaData: OtherOrderMetaData | null;
};

/** 受注入力ページコンテキスト */
export const PageContext = React.createContext<PageContextType>({
  pageState: PageState.Edit,
  tabIndex: OrderEditTabIndex.Basis,
  setTabIndex: () => undefined,
  hasFormSet: false,
  leavePage: () => undefined,
  orderMetaData: null,
});
