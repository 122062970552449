import React, { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { usePageContext } from '../../hooks/usePageContext';
import { FlexoOrderEditForm } from '../../types';
import { OpenCloseHeading } from '../OpenCloseHeading';
import MakingSizeReverse from './MakingSizeReverse';
import PrintSideReverse from './PrintSideReverse';

/** フレキソ受注 印刷面(リバース) */
const MakingReverse: React.VFC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { getValues } = useFormContext<FlexoOrderEditForm>();
  const { hasFormSet } = usePageContext();

  // フォームデータセット時の処理
  useEffect(() => {
    if (hasFormSet) {
      const makings = getValues('making.flexoMakingsMain');
      setOpen(makings.length > 0);
    }
  }, [hasFormSet]);

  /** 表示非常時設定 */
  const toggleDisplay = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  return (
    <Box>
      <OpenCloseHeading
        onClose={toggleDisplay}
        buttonText={open ? 'CLOSE' : 'OPEN'}
        displayFlg={open}
      >
        {'印刷面(リバース)'}
      </OpenCloseHeading>
      {open && (
        <>
          <MakingSizeReverse />
          <PrintSideReverse />
        </>
      )}
    </Box>
  );
};

export default MakingReverse;
