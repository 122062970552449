import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

import { save, clear } from '.';
import { CylinderWorksBarcodeReadInfo } from './types';

/** CW シリンダーJOBリスト編集 確認画面StoreのHooks */
export const useCylinderWorksCylJobListEditCheck = (): {
  state: CylinderWorksBarcodeReadInfo;
  save: (state: CylinderWorksBarcodeReadInfo) => void;
  clear: () => void;
} => {
  /** CW シリンダーJOBリスト編集 確認画面のState */
  const state = useSelector((state: RootState) => state.cylinderWorksCylJobListEditCheck);

  const dispatch = useDispatch();

  /** CW シリンダーJOBリスト編集 確認画面Stateに登録 */
  const dispatchSave = useCallback(
    (state: CylinderWorksBarcodeReadInfo) => {
      dispatch(save(state));
    },
    [dispatch],
  );

  /** CW シリンダーJOBリスト編集 確認画面Stateを初期化 */
  const dispatchClear = useCallback(() => {
    dispatch(clear());
  }, []);

  return { state, save: dispatchSave, clear: dispatchClear };
};
