import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { persistor, RootState } from 'store';

import { save, clear } from '.';
import { MakingSpecificationHistoryState } from './types';

/** 製版指示書（履歴）StoreのHooks */
export const useMakingSpecificationHistoryStore = (): {
  state: MakingSpecificationHistoryState;
  save: (state: MakingSpecificationHistoryState) => void;
  clear: () => void;
} => {
  /** State */
  const state = useSelector((state: RootState) => state.makingSpecificationHistory);

  const dispatch = useDispatch();

  /** Stateに登録 */
  const dispatchSave = useCallback(
    (state: MakingSpecificationHistoryState) => {
      dispatch(save(state));

      // ストレージに即書き込みする
      // 製版指示書ウィンドウ表示時に、ストレージ書き込み前の値が参照されないようにするため。
      persistor.flush();
    },
    [dispatch],
  );

  /** Stateを初期化 */
  const dispatchClear = useCallback(() => {
    dispatch(clear());
    persistor.flush();
  }, []);

  return { state, save: dispatchSave, clear: dispatchClear };
};
