import React, { ReactNode } from 'react';

import { ToggleButton, ToggleButtonProps } from '@mui/material';

import {
  AlphaNumericField,
  AlphaNumericFieldProps,
  BodyLabel,
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  DatePicker,
  DatePickerProps,
  IconButton,
  IconButtonProps,
  KanaField,
  KanaFieldProps,
  NumericField,
  NumericFieldProps,
  Select,
  SelectProps,
} from 'components/parts/atoms';

import { dateText } from 'utils/date';
import { getSelectText } from 'utils/selectUtils';

import { usePageContext } from '../../hooks/usePageContext';
import { PageState } from '../../types';

/** 受注入力で使用するテキストフィールド */
export const EditKanaField: React.VFC<KanaFieldProps> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <KanaField {...props} />;
  } else {
    return (
      <BodyLabel sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {(props.value as string) ?? ''}
      </BodyLabel>
    );
  }
};

/** 受注入力で使用する数字フィールド */
export type EditNumericFieldProps = NumericFieldProps & { labelWidth?: 'grow' | 'shrink' | 'fix' };
export const EditNumericField: React.VFC<EditNumericFieldProps> = ({
  labelWidth = 'fix',
  ...props
}) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <NumericField {...props} />;
  } else {
    let sx = props.sx;
    switch (labelWidth) {
      case 'grow':
        sx = { ...props.sx, width: '100%' };
        break;
      case 'shrink':
        sx = { ...props.sx, width: undefined };
        break;
    }
    return (
      <BodyLabel sx={{ textAlign: 'right', ...sx }}>
        {props.value?.toFixed(props.decimalDigits) ?? ''}
      </BodyLabel>
    );
  }
};

/** 受注入力で使用する英数字フィールド */
export const EditAlphaNumericField: React.VFC<AlphaNumericFieldProps> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <AlphaNumericField {...props} />;
  } else {
    return <BodyLabel>{(props.value as string) ?? ''}</BodyLabel>;
  }
};

/** 受注入力で使用するセレクト */
export const EditSelect: React.VFC<SelectProps> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <Select {...props} />;
  } else {
    return <BodyLabel>{getSelectText(props.value, props.options)}</BodyLabel>;
  }
};

/** 受注入力で使用するチェックボックス */
export const EditCheckbox: React.VFC<CheckboxProps> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  return <Checkbox {...props} disabled={props.disabled || confirm} />;
};

/** 受注入力で使用するボタン */
export const EditButton: React.VFC<ButtonProps> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <Button {...props} />;
  } else {
    return <></>;
  }
};

/** 受注入力で使用するアイコンボタン */
export const EditIconButton: React.VFC<IconButtonProps> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <IconButton {...props} />;
  } else {
    return <></>;
  }
};

/** 受注入力で使用するトグルボタン */
export const EditToggleButton: React.VFC<ToggleButtonProps> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  return <ToggleButton {...props} disabled={props.disabled || confirm} />;
};

/** 受注入力で使用する日付ピッカー */
export const EditDatePicker: React.VFC<DatePickerProps<Date>> = (props) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <DatePicker {...props} />;
  } else {
    if (!props.value) {
      return <></>;
    } else {
      const date = new Date(props.value.toString());
      return <BodyLabel>{dateText(date, 'yyyy年MM月dd日')}</BodyLabel>;
    }
  }
};

/** 入力モードの時だけ表示するコンポーネント */
export const ShowOnEdit: React.VFC<{ children: ReactNode }> = ({ children }) => {
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;
  if (!confirm) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
