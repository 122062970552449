import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { OnlyTypeKey } from 'utils/typeUtils';

import { useUnitPriceRow } from '../../../hooks/price/priceRow/useUnitPriceRow';
import { FlexoOrderEditForm, UnitPrice, UnitPriceRow as UnitPriceRowType } from '../../../types';
import { UnitPriceField, UnitPriceFixedField } from '../Fields';
import { Row } from './Row';

type Props = {
  label: string;
  propertyName: OnlyTypeKey<UnitPrice, UnitPriceRowType>;
};

/** 単価計算 行コンポーネント */
const UnitPriceRow: React.VFC<Props> = ({ label, propertyName }) => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();

  const { price, toyoPriceRate, grossInvoice } = useUnitPriceRow(propertyName);

  return (
    <>
      <Row>
        <HeadLabel>{label}</HeadLabel>
        <Controller
          name={`price.unitPrice.${propertyName}.unitPrice`}
          control={control}
          render={({ field }) => (
            <UnitPriceFixedField
              min={0}
              max={9999999}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '6em' }}
              labelWidth={'grow'}
            />
          )}
        />
        <Controller
          name={`price.unitPrice.${propertyName}.count`}
          control={control}
          render={({ field }) => (
            <UnitPriceField
              min={0}
              max={99}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '100%' }}
              labelWidth={'grow'}
            />
          )}
        />
        <BodyLabel>{price.toFixed()}</BodyLabel>
        <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
        <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
      </Row>
    </>
  );
};

export default React.memo(UnitPriceRow);
