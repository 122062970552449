import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialRackSearchState } from 'store/manuscript/rackSearch/defaults';
import { RackSearchState } from 'store/manuscript/rackSearch/types';

const slice = createSlice({
  name: 'rackSearch',
  initialState: InitialRackSearchState,
  reducers: {
    save: (state, action: PayloadAction<RackSearchState>) => action.payload,
    clear: () => ({ ...InitialRackSearchState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
