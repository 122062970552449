import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/lab/themeAugmentation';

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs:0,
      sm:720,
      md:1080,
      lg:1200,
      xl:1505,
    }
  },
  palette: {
    primary: {
      main: '#245191',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#252666',
      contrastText: '#ffffff',
    },
    reversal:{
      main: '#ffffff',
      contrastText: '#245191',
    },
    background: {
      default: '#f5f7f7',
    },
    cylinderJob: {
      main: '#cccccc',
      contrastText: '#000000',
    },
    flexoJob: {
      main: '#ffc166',
      contrastText: '#000000',
    },
    otherJob: {
      main: '#c2dfc1',
      contrastText: '#000000',
    },
    attention: {
      main: '#ff2660',
      contrastText: '#ffffff',
    },
    careful: {
      main: '#7ccaee',
      contrastText: '#222222',
    },
    minor: {
      main: '#dddde8',
      contrastText: '#444444',
    },
    white: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    red: {
      main: '#ff0000',
      contrastText: '#000000',
    },
    yellow: {
      main: '#fff54E',
      contrastText: '#000000',
    },
    makingSpecificationGray:{
      main:'#808080',
      light:'#e7e7e7',
      dark:'#cccccc',
    },
    cyclonistBlue:{
      main:'#5d9ac7',
      contrastText: '#ffffff',
    },
    cyclonistLightOrange: {
      main: '#ffc166',
      contrastText: '#000000',
    },
    cyclonistOrange:{
      main:'#FF9900',
      contrastText: '#ffffff',
    },
    cyclonistDarkOrange:{
      main:'#FC8700',
      contrastText: '#ffffff',
    },
    cyclonistOrangeRed:{
      main:'#FF4500',
      contrastText: '#ffffff',
    },
    cyclonistGreen:{
      main:'#009900',
      contrastText: '#ffffff',
    },
    // グリッドの枠線カラー
    cyclonistBorderColor:{
      main:'#9aa2b0',
    },
    // ヘッダーグリッドのバックカラー
    cyclonistHeadGridColor:{
      main:' #e2eafa',
      contrastText:' #000000',
    },
    // ボディグリッドのバックカラー
    cyclonistBodyGridColor:{
      main:' #ffffff',
      contrastText:' #000000',
    },
    cyclonistDarkGrey:{
      main:'#666666',
      contrastText: '#ffffff',
    },
    cyclonistGrey:{
      main:'#CCCCCC',
      contrastText:' #000000',
    },
    cyclonistLightGrey:{
      main:'#e2e2e2',
      contrastText: '#333333',
    },
    outSourcing:{
      main:'#ff8c00',
    },
    cyclonistLightBlue:{
      main:'#F0F3FF',
      dark:'#6495ED',
    },
    cyclonistBlack:{
      main:'#090909',
    },
    cyclonistRed:{
      main:'#FF0000',
    },
    cyclonistRedHeader:{
      main:'#FF3300',
    },
    cyclonistYellow:{
      main:'#FFFF00',
    },
    defaultStripe:{
      main:'#ddfadc',
    },
    cyclonistPurple:{
      main:'#800080',
    },
    cyclonistLightGreen:{
      main:'#ddfadc',
    },
    imageWorkReportListRowGreen:{
      main:'#ddfadc',
    },
    cyclonistGrayBorderColor:{
      main:'#A6A6A6',
    },
    imageWorkReportDesign:{
      main:'#C8C488',
      light:'#F8F5EF',
      dark:'#E1D9BF',
    },
    imageWorkReportPhotoRetouch:{
      main:'#74B672',
      light:'#EFF8EF',
      dark:'#C2DFC1',
    },
    imageWorkReportColorSeparation:{
      main:'#FF9900',
      light:'#F2F2F2',
      dark:'#FFCC99',
    },
    imageWorkReportPresentColorProofing:{
      main:'#D286D2',
      light:'#F8EEF9',
      dark:'#E6BBE6',
    },
    imageWorkReportCheckInspection:{
      main:'#FFCC66',
      light:'#FCFCEB',
      dark:'#F4F1AC',
    },
    imageWorkReportLayout:{
      main:'#C27E7E',
      light:'#F8EFEF',
      dark:'#E1BFBF',
    },
    imageWorkReportPresentNormal:{
      main:'#AAE3FF',
      light:'#EDF9FA',
      dark:'#D9FFFF',
    },
    imageWorkReportPresentAll:{
      main:'#666666',
      light:'#FFFFFF',
      dark:'#DEDFDE',
    },
    imageWorkEditPresentAoyaki:{
      main:'#4A75B5',
    },
    imageWorkEditPresentOhuko:{
      main:'#31B2CE',
    },
    imageWorkEditPresentIroko:{
      main:'#9CDBE7',
    },
    imageWorkEditPresentfairuhenkan:{
      main:'#FFFF00',
    },
    imageWorkEditPresentpozisyuturyoku:{
      main:'#F7BE6B',
    },
    imageWorkEditPresentSyobunruiOrange:{
      main:'#FFDFBD',
    },
    imageWorkScheduleListRow1:{
      main:'#D9E2FD',
    },
    imageWorkScheduleListRow2:{
      main:'#F2F2F2',
    },
    imageWorkScheduleListOrange:{
      main:'#FF8C00',
    },
    darkorange:{
      main:'#FFCB97',
      light:'#FFFFFF',
      dark:'#ff8c00',
      contrastText:'#FFFFFF',
    },
    cyclonistSkyBlue:{
      main:'#D0DBFF',
      contrastText:'#FFFFFF',
    },
    cyclonistMediumSlateBlue:{
      main:'#8483FF',
      contrastText:'#FFFFFF',
    },
    cyclonistChousahanPink:{
      main:'#FF0066',
    },
    cyclonistLightPink:{
      main:'#FFEDF2',
    },
    cyclonistDefaultBlue:{
      main:'#0000FF',
    },
    CyclonistShinchokuLightGrey:{
      main:'#d3d3d3',
    },
    CyclonistShinchokuBlue:{
      main:'#4069E1',
    },
    CyclonistEigyoItem:{
      main:'#309865',
    },
    cyclonistProgressListHeader:{
      main:'#666666',
      contrastText:'#f9f9f9',
    },
    cyclonistImageWorkScheduleListHeader:{
      main:'#6699ff',
      contrastText:'#ffffff',
    },
    cyclonistScheduleSearcher:{
      main:'#cccccc',
    },
    cyclonistSeihanScheduleListHeader:{
      main:'#8487ff',
      contrastText: '#ffffff'
    },
    cyclonistSeihanScheduleListFooter:{
      main:'#999999',
      contrastText: '#ffffff'
    },
    cyclonistShipmentScheduleListSearcher:{
      main:'#ffffff',
    },
    cyclonistScheduleListHeader:{
      main:'#666666',
      contrastText:'#ffffff',
    },
    cyclonistScheduleArrivalListHeader:{
      main:'#808080',
      contrastText:'#ffffff',
    },
    cyclonistLinkColor:{
      main:'#d9e5ef'
    }
  },
  components: {
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          margin:"0"
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          justifyContent:"center"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding:"5px 10px",
          '&.Mui-disabled': {
            color: '#333333',
            '-webkit-text-fill-color': '#333333',
          },
        },
        root: {
          padding:"0px 0px",
          background:'#ffffff',
          '&.Mui-disabled': {
            background: '#e8e8e8',
          },
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight:"2em",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            background:'#ffffff',
            borderRadius:'3px'
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding:"2px 15px 2px 5px",
        },
        message: {
          padding:"2px 0px",
          display:'flex',
          alignItems:'center',
          fontSize:12
        },
        icon: {
          padding:"2px 0px"
        },
        action: {
          padding:"0px"
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root:{
          '&.Mui-selected': {
            backgroundColor: '#75aaff44',
            fontWeight:"bold",
          },
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&.Mui-selected": {
            backgroundColor: "#24519138",
            "&:hover": {
              backgroundColor: "#24519148"
            }
          }
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 0,
    },
  },
  typography: {
    fontSize: 11,
    fontFamily: 'Meiryo UI',
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize:"2rem",
      lineHeight:1.2,
    },
    h2: {
      fontSize:"1.5rem",
      lineHeight:1.4,
    },
    h3: {
      fontSize:"0.9rem",
      lineHeight:1.6,
    },
    subtitle1: {
      fontWeight:"550",
    },
    h4:undefined,
    h5:undefined,
    h6:undefined,
    subtitle2:undefined,
    body2:undefined,
    overline:undefined,
  },
  spacing: 4,
});

declare module '@mui/material/styles' {
  interface Palette {
    cylinderJob: Palette['primary'];
    flexoJob: Palette['primary'];
    otherJob: Palette['primary'];
    attention: Palette['primary'];
    careful: Palette['primary'];
    minor: Palette['primary'];
    white: Palette['primary'];
    red: Palette['primary'];
    yellow: Palette['primary'];
    required: Palette['primary'];
    makingSpecificationGray: Palette['primary'];
    cyclonistDarkGrey: Palette['primary'];
    cyclonistGrey: Palette['primary'];
    cyclonistLightGrey: Palette['primary'];
    reversal: Palette['primary'];
    cyclonistBlue: Palette['primary'];
    cyclonistLightOrange: Palette['primary'];
    cyclonistOrange: Palette['primary'];
    cyclonistDarkOrange: Palette['primary'];
    cyclonistOrangeRed: Palette['primary'];
    cyclonistGreen: Palette['primary'];
    cyclonistBorderColor: Palette['primary'];
    cyclonistHeadGridColor: Palette['primary'];
    cyclonistBodyGridColor: Palette['primary'];
    outSourcing: Palette['primary'];
    cyclonistLightBlue: Palette['primary'];
    cyclonistBlack: Palette['primary'];
    cyclonistRed: Palette['primary'];
    cyclonistRedHeader: Palette['primary'];
    cyclonistYellow: Palette['primary'];
    defaultStripe: Palette['primary'];
    cyclonistPurple: Palette['primary'];
    imageWorkReportListRowGreen: Palette['primary'];
    cyclonistLightGreen: Palette['primary'];
    cyclonistGrayBorderColor: Palette['primary'];
    imageWorkReportDesign: Palette['primary'];
    imageWorkReportPhotoRetouch: Palette['primary'];
    imageWorkReportColorSeparation: Palette['primary'];
    imageWorkReportPresentColorProofing: Palette['primary'];
    imageWorkReportCheckInspection: Palette['primary'];
    imageWorkReportLayout: Palette['primary'];
    imageWorkReportPresentNormal: Palette['primary'];
    imageWorkReportPresentAll: Palette['primary'];
    imageWorkEditPresentAoyaki:Palette['primary'];
    imageWorkEditPresentOhuko:Palette['primary'];
    imageWorkEditPresentIroko:Palette['primary'];
    imageWorkEditPresentfairuhenkan:Palette['primary'];
    imageWorkEditPresentpozisyuturyoku:Palette['primary'];
    imageWorkEditPresentSyobunruiOrange:Palette['primary'];
    imageWorkScheduleListRow1:Palette['primary'];
    imageWorkScheduleListRow2:Palette['primary'];
    imageWorkScheduleListOrange:Palette['primary'];
    darkorange: Palette['primary'];
    cyclonistSkyBlue: Palette['primary'];
    cyclonistMediumSlateBlue: Palette['primary'];
    cyclonistChousahanPink: Palette['primary'];
    cyclonistLightPink: Palette['primary'];
    cyclonistDefaultBlue: Palette['primary'];
    CyclonistShinchokuLightGrey: Palette['primary'];
    CyclonistShinchokuBlue: Palette['primary'];
    CyclonistEigyoItem: Palette['primary'];
    cyclonistProgressListHeader: Palette['primary'];
    cyclonistImageWorkScheduleListHeader: Palette['primary'];
    cyclonistScheduleSearcher: Palette['primary'];
    cyclonistSeihanScheduleListHeader: Palette['primary'];
    cyclonistSeihanScheduleListFooter: Palette['primary'];
    cyclonistShipmentScheduleListSearcher: Palette['primary'];
    cyclonistScheduleListHeader: Palette['primary'];
    cyclonistScheduleArrivalListHeader: Palette['primary'];
    cyclonistLinkColor: Palette['primary'];
}

  interface PaletteOptions {
    cylinderJob?: PaletteOptions['primary'];
    flexoJob?: PaletteOptions['primary'];
    otherJob?: PaletteOptions['primary'];
    attention?: PaletteOptions['primary'];
    careful?: PaletteOptions['primary'];
    minor?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
    yellow?: PaletteOptions['primary'];
    required?: Palette['primary'];
    makingSpecificationGray?: PaletteOptions['primary'];
    todayPlan?: PaletteOptions['primary'];
    reversal: PaletteOptions['primary'];
    cyclonistBlue?: PaletteOptions['primary'];
    cyclonistLightOrange?: PaletteOptions['primary'];
    cyclonistOrange?: PaletteOptions['primary'];
    cyclonistDarkOrange?: PaletteOptions['primary'];
    cyclonistOrangeRed?: PaletteOptions['primary'];
    cyclonistGreen?: PaletteOptions['primary'];
    cyclonistBorderColor?: PaletteOptions['primary'];
    cyclonistHeadGridColor?: PaletteOptions['primary'];
    cyclonistBodyGridColor?: PaletteOptions['primary'];
    cyclonistDarkGrey?: PaletteOptions['primary'];
    cyclonistGrey?: PaletteOptions['primary'];
    cyclonistLightGrey?: PaletteOptions['primary'];
    outSourcing?: PaletteOptions['primary'];
    cyclonistLightBlue?: PaletteOptions['primary'];
    cyclonistBlack?: PaletteOptions['primary'];
    cyclonistRed?: PaletteOptions['primary'];
    cyclonistRedHeader?: PaletteOptions['primary'];
    cyclonistYellow?: PaletteOptions['primary'];
    defaultStripe: PaletteOptions['primary'];
    cyclonistPurple?: PaletteOptions['primary'];
    imageWorkReportListRowGreen: PaletteOptions['primary'];
    cyclonistLightGreen: PaletteOptions['primary'];
    cyclonistGrayBorderColor?: PaletteOptions['primary'];
    imageWorkReportDesign?: PaletteOptions['primary'];
    imageWorkReportPhotoRetouch?: PaletteOptions['primary'];
    imageWorkReportColorSeparation?: PaletteOptions['primary'];
    imageWorkReportPresentColorProofing?: PaletteOptions['primary'];
    imageWorkReportCheckInspection?: PaletteOptions['primary'];
    imageWorkReportLayout?: PaletteOptions['primary'];
    imageWorkReportPresentNormal?: PaletteOptions['primary'];
    imageWorkReportPresentAll?: PaletteOptions['primary'];
    imageWorkEditPresentAoyaki:PaletteOptions['primary'];
    imageWorkEditPresentOhuko:PaletteOptions['primary'];
    imageWorkEditPresentIroko:PaletteOptions['primary'];
    imageWorkEditPresentfairuhenkan:PaletteOptions['primary'];
    imageWorkEditPresentpozisyuturyoku:PaletteOptions['primary'];
    imageWorkEditPresentSyobunruiOrange:PaletteOptions['primary'];
    imageWorkScheduleListRow1:PaletteOptions['primary'];
    imageWorkScheduleListRow2:PaletteOptions['primary'];
    imageWorkScheduleListOrange:PaletteOptions['primary'];
    darkorange?: PaletteOptions['primary'];
    cyclonistSkyBlue?: PaletteOptions['primary'];
    cyclonistMediumSlateBlue?: PaletteOptions['primary'];
    cyclonistChousahanPink?: PaletteOptions['primary'];
    cyclonistLightPink?: PaletteOptions['primary'];
    cyclonistDefaultBlue?: PaletteOptions['primary'];
    CyclonistShinchokuLightGrey?: PaletteOptions['primary'];
    CyclonistShinchokuBlue?: PaletteOptions['primary'];
    CyclonistEigyoItem?: PaletteOptions['primary'];
    cyclonistProgressListHeader? : PaletteOptions[&'primary'];
    cyclonistImageWorkScheduleListHeader? : PaletteOptions[&'primary'];
    cyclonistScheduleSearcher? : PaletteOptions[&'primary'];
    cyclonistSeihanScheduleListHeader? : PaletteOptions[&'primary'];
    cyclonistSeihanScheduleListFooter? : PaletteOptions[&'primary'];
    cyclonistShipmentScheduleListSearcher? : PaletteOptions[&'primary'];
    cyclonistScheduleListHeader? : PaletteOptions[&'primary'];
    cyclonistScheduleArrivalListHeader? : PaletteOptions[&'primary'];
    cyclonistLinkColor? : PaletteOptions[&'primary'];
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body2: false;
    h4: false;
    h5: false;
    h6: false;
    overline: false;
    subtitle2: false;
    button: false;
    inherit: false;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    attention: true;
    careful: true;
    minor: true;
    reversal:true;
  }
}
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    white: true;
  }
}

export default theme;
