import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialManuscriptMoveEditState } from 'store/manuscript/manuscriptMoveEdit/defaults';

import { ManuscriptMoveEditState } from './types';

const slice = createSlice({
  name: 'manuscriptArrivalEdit',
  initialState: InitialManuscriptMoveEditState,
  reducers: {
    save: (state, action: PayloadAction<ManuscriptMoveEditState>) => action.payload,
    clear: () => ({ ...InitialManuscriptMoveEditState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
