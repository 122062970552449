import * as React from 'react';

import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type BodyGridProps = {
  xs?: GridProps['xs'];
  sx?: GridProps['sx'];
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  outline: '1px solid',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  outlineColor: theme.palette.cyclonistBorderColor.main,
  backgroundColor: theme.palette.cyclonistBodyGridColor.main,
}));

const BodyGrid: React.FC<BodyGridProps> = ({ children, ...props }) => {
  return (
    <>
      <StyledGrid item {...props}>
        {children}
      </StyledGrid>
    </>
  );
};

export default BodyGrid;
