import * as apiType from 'api/types/topImage/imageWorkEditColorSeparation';

export type ImageWorkEditColorSeparationMasterResponse =
  apiType.ImageWorkEditColorSeparationMasterResponse;
export type ImageWorkEditColorSeparationDataResponse =
  apiType.ImageWorkEditColorSeparationDataResponse;
export type ImageWorkEditColorSeparationWorkUpdateRequest =
  apiType.ImageWorkEditColorSeparationWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

/** ダウンロード入力フォーム */
export type DownloadForm = {
  design: boolean;
  photoRetouch: boolean;
  colorSeparation: boolean;
  layout: boolean;
  final: boolean;
  send: boolean;
};

/** アップロード入力フォーム */
export type UploadForm = {
  final: boolean;
  send: boolean;
};

/** 画像 画像テンプレート入力フォーム */
export type ImageTemplateForm = {
  templateNo: string;
  templateComment: string;
};

/** 画像 画像参照JOB入力フォーム */
export type ImageReferenceJobForm = {
  referenceJobNo: string;
  selectedWorkProcess: string;
  referenceComment: string;
};

/** 作業内容入力フォーム */
export type WorkContentsForm = {
  selectedWorkContent: string;
};

/** テスト出力入力フォーム */
export type TestOutputForm = {
  testOutputCount: string;
  selectedTestOutput: string;
};

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
