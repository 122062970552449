import React from 'react';

export type FormChildrenContextType = {
  openDeliveryState: [boolean, (value: boolean) => void];
};

/** フォーム子コンポーネント用コンテキスト */
export const FormChildrenContext = React.createContext<FormChildrenContextType>({
  openDeliveryState: [false, () => undefined],
});
