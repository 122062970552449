import { ProductionCategoryCode } from 'constants/define';
import { useShowMakingSpecification } from 'hooks/useShowMakingSpecification';
import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import { GridCellParams, GridRowParams, GridColDef } from '@mui/x-data-grid-pro';

import ProgressListDialog from 'pages/common/ProgressList/ProgressListDialog';

import { DataGrid, Heading, BodyLink } from 'components/parts/atoms';

import { dateText } from 'utils/date';

import { Job } from '../types';

type Props = {
  category: ProductionCategoryCode;
  title: string;
  jobs?: Job[];
  loading: boolean;
};

function getHeadingColor(
  productionCategoryCode: ProductionCategoryCode,
  theme: Theme,
): [string, string] {
  switch (productionCategoryCode) {
    case ProductionCategoryCode.Cylinder:
      return [theme.palette.cylinderJob.main, theme.palette.cylinderJob.contrastText];
    case ProductionCategoryCode.Flexo:
      return [theme.palette.flexoJob.main, theme.palette.flexoJob.contrastText];
    case ProductionCategoryCode.Other:
      return [theme.palette.otherJob.main, theme.palette.otherJob.contrastText];
    default:
      return [theme.palette.primary.main, theme.palette.primary.contrastText];
  }
}

/** セルのクラスを取得 */
const getCellClass = (params: GridCellParams): string => {
  const classArray: string[] = [];
  if (params.row.isEstimate) {
    classArray.push('estimate');
  }
  if (params.row.isUndelivered) {
    classArray.push('undelivered');
  }
  if (params.row.isTemp) {
    classArray.push('temp');
  }
  return classArray.join(' ');
};

const getRowClass = (params: GridRowParams): string => {
  const classArray: string[] = [];
  if (params.row.isMakingOutsourcing) {
    classArray.push('makingOutsourcing');
  }
  if (params.row.isSomeoneUpdated) {
    classArray.push('someoneUpdated');
  }
  return classArray.join(' ');
};

const JobTable: React.VFC<Props> = (props) => {
  const { showMakingSpecification } = useShowMakingSpecification();
  const theme = useTheme();
  const [backgroundColor, textColor] = getHeadingColor(props.category, theme);

  // ダイアログ
  const [openProgressList, setOpenProgressList] = useState(false);
  const [selectJob, setSelectJob] = useState<Job | null>(null);

  /** 進捗ダイアログ表示 */
  const showProgressList = (job: Job) => {
    setSelectJob(job);
    setOpenProgressList(true);
  };

  const headerClassName = (() => {
    switch (props.category) {
      case ProductionCategoryCode.Cylinder:
        return 'cylinder';
      case ProductionCategoryCode.Flexo:
        return 'flexo';
      case ProductionCategoryCode.Other:
        return 'other';
      default:
        return '';
    }
  })();

  const columns = (() => {
    const jobNoLink: GridColDef = {
      field: 'jobNo',
      headerName: 'JOB No.',
      headerClassName,
      width: 140,
      renderCell: (params: GridCellParams) => (
        <BodyLink onClick={() => showMakingSpecification(props.category, params.row.jobId)}>
          {params.value}
        </BodyLink>
      ),
      cellClassName: (params) => getCellClass(params),
    };
    const jobNoLabel: GridColDef = {
      field: 'jobNo',
      headerName: 'JOB No.',
      headerClassName,
      width: 140,
      cellClassName: (params) => getCellClass(params),
    };
    const customerName: GridColDef = {
      field: 'customerName',
      headerName: '得意先名',
      headerClassName,
      width: 150,
      sortable: false,
      cellClassName: (params) => getCellClass(params),
    };
    const productNameForDisplay: GridColDef = {
      field: 'productNameForDisplay',
      headerName: '品名',
      headerClassName,
      width: 250,
      sortable: false,
      cellClassName: (params) => getCellClass(params),
    };
    const cylSize: GridColDef = {
      field: 'cylSize',
      headerName: 'シリンダーサイズ',
      headerClassName,
      width: 127.5,
      valueGetter: (params) => {
        if (!params.row.cylSizeWidth && !params.row.cylSizeEnsyuu) return '';
        const width: string = params.row.cylSizeWidth || '';
        const ensyuu: string = parseFloat(params.row.cylSizeEnsyuu).toFixed(2) || '';
        return `${width} × ${ensyuu}`;
      },
      align: 'center',
      sortable: false,
      cellClassName: (params) => getCellClass(params),
      renderHeader: () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>{'シリンダー'}</Typography>
          <Typography>{'サイズ'}</Typography>
        </Box>
      ),
    };
    const colorCount: GridColDef = {
      field: 'colorCount',
      headerName: '色数',
      headerClassName,
      type: 'number',
      width: 60,
      align: 'center',
      sortable: false,
      cellClassName: (params) => getCellClass(params),
    };
    const plateCount: GridColDef = {
      field: 'plateCount',
      headerName: '版数',
      headerClassName,
      type: 'number',
      width: 60,
      align: 'center',
      sortable: false,
      cellClassName: (params) => getCellClass(params),
    };
    const syukkouPlanDate: GridColDef = {
      field: 'syukkouPlanDate',
      headerName: '出稿日',
      headerClassName,
      type: 'date',
      width: 80,
      align: 'center',
      sortable: false,
      valueGetter: (params) => {
        return dateText(params.row.syukkouPlanDate, 'yy/MM/dd');
      },
      cellClassName: (params) => getCellClass(params),
    };
    const cylMakingPlanDate: GridColDef = {
      field: 'cylMakingPlanDate',
      headerName: '製版日',
      headerClassName,
      type: 'date',
      width: 80,
      align: 'center',
      sortable: false,
      valueGetter: (params) => {
        return dateText(params.row.cylMakingPlanDate, 'yy/MM/dd');
      },
      cellClassName: (params) => getCellClass(params),
    };
    const deliveryDate: GridColDef = {
      field: 'deliveryDate',
      headerName: '納品日',
      headerClassName,
      type: 'date',
      width: 120,
      align: 'center',
      sortable: false,
      valueGetter: (params) => {
        return dateText(params.row.deliveryDate, 'yy/MM/dd');
      },
      cellClassName: (params) => getCellClass(params),
    };
    const totalPriceTaxOmission: GridColDef = {
      field: 'totalPriceTaxOmission',
      headerName: '金額',
      headerClassName,
      type: 'number',
      width: 90,
      sortable: false,
      cellClassName: (params) => getCellClass(params),
    };
    const statusLink: GridColDef = {
      field: 'status',
      headerName: 'ステータス',
      headerClassName,
      width: 139,
      renderCell: (params: GridCellParams) => (
        <BodyLink onClick={() => showProgressList(params.row)}>{params.value}</BodyLink>
      ),
      sortable: false,
      cellClassName: (params) => getCellClass(params),
    };
    const statusLabel: GridColDef = {
      field: 'status',
      headerName: 'ステータス',
      headerClassName,
      width: 424,
      sortable: false,
      cellClassName: (params) => getCellClass(params),
    };

    switch (props.category) {
      case ProductionCategoryCode.Cylinder:
      case ProductionCategoryCode.Flexo:
        return [
          jobNoLink,
          customerName,
          productNameForDisplay,
          cylSize,
          colorCount,
          plateCount,
          syukkouPlanDate,
          cylMakingPlanDate,
          totalPriceTaxOmission,
          statusLink,
        ];
      case ProductionCategoryCode.Other:
        return [
          jobNoLabel,
          customerName,
          productNameForDisplay,
          deliveryDate,
          totalPriceTaxOmission,
          statusLabel,
        ];
    }
  })();

  return (
    <>
      <Heading backgroundColor={backgroundColor} textColor={textColor}>
        {props.title}
      </Heading>
      <Box m={2} />
      <Box
        sx={{
          '& .undelivered': {
            color: theme.palette.cyclonistRed.main,
          },
          '& .estimate': {
            color: theme.palette.cyclonistBlue.main,
          },
          '& .makingOutsourcing': {
            backgroundColor: theme.palette.outSourcing.main,
          },
          '& .temp': {
            backgroundColor: theme.palette.cyclonistLightBlue.main,
          },
          '& .someoneUpdated': {
            backgroundColor: theme.palette.cyclonistYellow.main,
          },
        }}
      >
        <DataGrid
          columns={columns}
          rows={props.jobs ?? []}
          getRowId={(row) => row.jobId}
          getRowClassName={(params) => getRowClass(params)}
          pagination={true}
          density='compact'
          autoHeight
          disableColumnFilter
          disableColumnMenu
          loading={props.loading}
          pageSize={20}
        />
      </Box>
      <ProgressListDialog
        open={openProgressList}
        onClose={() => {
          setOpenProgressList(false);
          setSelectJob(null);
        }}
        jobId={selectJob?.jobId}
        disablePortal={true}
      />
    </>
  );
};

export default React.memo(JobTable);
