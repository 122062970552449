import * as apiType from 'api/types/topImage/imageWorkEditLayout';

export type ImageWorkEditLayoutMasterResponse = apiType.ImageWorkEditLayoutMasterResponse;
export type ImageWorkEditLayoutDataResponse = apiType.ImageWorkEditLayoutDataResponse;
export type ImageWorkEditLayoutWorkUpdateRequest = apiType.ImageWorkEditLayoutWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

/** ダウンロード入力フォーム */
export type DownloadForm = {
  design: boolean;
  photoRetouch: boolean;
  colorSeparation: boolean;
  layout: boolean;
  final: boolean;
  send: boolean;
};

/** アップロード入力フォーム */
export type UploadForm = {
  final: boolean;
  send: boolean;
};

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
