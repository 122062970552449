import { ImageWorkReportConditionState } from './types';

/** 初期値 */
export const defaultFormStore: ImageWorkReportConditionState = {
  jobNo: '',
  oldJobNo: '',
  myJobNo: '',
  customerOrderNo: '',
  chargedEmployeeId: '',
  customerId: null,
  customerSectionCd: '',
  customerCd: '',
  customerName: '',
  productionName: '',
  jobName: '',
  stampNo1: '',
  stampNo2: '',
  selectConditionDate: '',
  selectStartDate: null,
  selectEndDate: null,
};
