import useSharedMessage from 'hooks/useSharedMessage';
import React, { useEffect } from 'react';

import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import ComponentSamples from './components/ComponentSamples';
import FormSample from './components/FormSample';
import Links from './components/Links';
import MessageSamples from './components/MessageSamples';
import TypographySamples from './components/TypographySamples';
import { Heading } from 'components/parts/atoms';

const Container = styled(Box)(() => ({}));

const DevPortal: React.VFC = () => {
  const { clear } = useSharedMessage();

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  return (
    <>
      <Container>
        <Typography variant='h1' color='initial'>
          {'開発用ポータル画面'}
        </Typography>
        <Box my={8}></Box>

        <Heading>リンク</Heading>
        <Links />
        <Box my={8}></Box>

        <Heading>メッセージサンプル</Heading>
        <MessageSamples />
        <Box my={8}></Box>

        <Heading>コンポーネントサンプル</Heading>
        <ComponentSamples />
        <Box my={8}></Box>

        <Heading>Typographyサンプル</Heading>
        <TypographySamples />
        <Box my={8}></Box>

        <Heading>画面サンプル</Heading>
        <FormSample />
        <Box my={8}></Box>
      </Container>
    </>
  );
};

export default DevPortal;
