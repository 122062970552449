import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Box } from '@mui/material';

import { ImageSearchParameter } from 'pages/imageServer/ImageDataSearch/types';

import {
  RowGrid,
  HeadLabel,
  KanaField,
  Select,
  DatePicker,
  CustomButton,
  ClearButton,
  SearchButton,
} from 'components/parts/atoms';
import { SelectOption } from 'components/parts/atoms/Select/types';
import { ContentsGrid } from 'components/parts/molecules/ContentsGrid';
import { OpenCloseHeading } from 'components/parts/molecules/OpenCloseHeading';

/**自身の尭定義 */
type OwnDefinition = {
  onDisplayChange: () => void;
  onSearch: () => void;
  onClear: () => void;
  employeeList: SelectOption[];
  displayFlg: boolean;
  buttonText: string;
  searching: boolean;
};

const allDisplay = '詳細検索表示';
const notAllDisplay = '詳細検索非表示';

/** 画像検索の検索条件コンポーネント */
const ImageSearchCondition: React.VFC<OwnDefinition> = (props) => {
  // 検索条件全表示State
  const [allConditionFlg, setAllConditionFlg] = useState<boolean>(true);
  const [allConditionText, setAllConditionText] = useState<string>(notAllDisplay);
  const [minDate] = useState<Date>(new Date(2011, 11, 1));

  // RHFのFormContext
  const formSet = useFormContext<ImageSearchParameter>();

  /** 検索条件折り畳みイベント */
  const onChangeAllCondition = () => {
    if (allConditionFlg) {
      setAllConditionFlg(false);
      setAllConditionText(allDisplay);
    } else {
      setAllConditionFlg(true);
      setAllConditionText(notAllDisplay);
    }
  };

  return (
    <>
      <OpenCloseHeading
        onClose={props.onDisplayChange}
        displayFlg={props.displayFlg}
        buttonText={props.buttonText}
      >
        画像検索
      </OpenCloseHeading>

      {props.displayFlg && (
        <>
          <RowGrid columns={4}>
            <ContentsGrid hxs={1} bxs={3}>
              <HeadLabel>JOB No.(未受注も含む)</HeadLabel>
              <Controller
                name='jobNo'
                control={formSet.control}
                render={({ field }) => (
                  <KanaField
                    maxLength={20}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    sx={{ width: '20rem' }}
                  />
                )}
              />
            </ContentsGrid>
          </RowGrid>

          {allConditionFlg && (
            <>
              <RowGrid columns={4}>
                <ContentsGrid hxs={1} bxs={3}>
                  <HeadLabel>旧JOB No.</HeadLabel>
                  <Controller
                    name='oldJobNo'
                    control={formSet.control}
                    render={({ field }) => (
                      <KanaField
                        maxLength={20}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        sx={{ width: '20rem' }}
                      />
                    )}
                  />
                </ContentsGrid>
              </RowGrid>
            </>
          )}

          <RowGrid columns={4}>
            <ContentsGrid hxs={1} bxs={3}>
              <HeadLabel>得意先名</HeadLabel>
              <Controller
                name='customerName'
                control={formSet.control}
                render={({ field }) => (
                  <KanaField
                    maxLength={20}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    sx={{ width: '20rem' }}
                  />
                )}
              />
            </ContentsGrid>
          </RowGrid>
          <RowGrid columns={4}>
            <ContentsGrid hxs={1} bxs={3}>
              <HeadLabel>品名</HeadLabel>
              <Controller
                name='productNameForDisplay'
                control={formSet.control}
                render={({ field }) => (
                  <KanaField
                    maxLength={20}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    sx={{ width: '20rem' }}
                  />
                )}
              />
            </ContentsGrid>
          </RowGrid>
          <RowGrid columns={4}>
            <ContentsGrid hxs={1} bxs={3}>
              <HeadLabel>担当者</HeadLabel>
              <Controller
                name='employeeId'
                control={formSet.control}
                render={({ field }) => (
                  <Select
                    options={props.employeeList}
                    addEmptyRow={true}
                    value={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    sx={{ minWidth: '7rem' }}
                  />
                )}
              />
            </ContentsGrid>
          </RowGrid>

          {allConditionFlg && (
            <>
              <RowGrid columns={4}>
                <ContentsGrid hxs={1} bxs={3}>
                  <HeadLabel>ファイル名</HeadLabel>
                  <Controller
                    name='fileName'
                    control={formSet.control}
                    render={({ field }) => (
                      <KanaField
                        maxLength={20}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        sx={{ width: '20rem' }}
                      />
                    )}
                  />
                </ContentsGrid>
              </RowGrid>
              <RowGrid columns={4}>
                <ContentsGrid hxs={1} bxs={3}>
                  <HeadLabel>ファイルコメント</HeadLabel>
                  <Controller
                    name='fileComment'
                    control={formSet.control}
                    render={({ field }) => (
                      <KanaField
                        maxLength={20}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        sx={{ width: '20rem' }}
                      />
                    )}
                  />
                </ContentsGrid>
              </RowGrid>
              <RowGrid columns={4}>
                <ContentsGrid hxs={1} bxs={3}>
                  <HeadLabel>入稿日</HeadLabel>
                  <Box sx={{ display: 'flex' }}>
                    <Controller
                      name='draftDateFrom'
                      control={formSet.control}
                      render={({ field }) => (
                        <DatePicker
                          onChange={field.onChange}
                          minDate={minDate}
                          value={field.value}
                        />
                      )}
                    />
                    {'　から　'}
                    <Controller
                      name='draftDateTo'
                      control={formSet.control}
                      render={({ field }) => (
                        <DatePicker
                          onChange={field.onChange}
                          minDate={minDate}
                          value={field.value}
                        />
                      )}
                    />
                  </Box>
                </ContentsGrid>
              </RowGrid>
              <RowGrid columns={4}>
                <ContentsGrid hxs={1} bxs={3}>
                  <HeadLabel>画像作業予定日</HeadLabel>
                  <Box sx={{ display: 'flex' }}>
                    <Controller
                      name='imageWorkSchemeDateFrom'
                      control={formSet.control}
                      render={({ field }) => (
                        <DatePicker
                          onChange={field.onChange}
                          minDate={minDate}
                          value={field.value}
                        />
                      )}
                    />
                    {'　から　'}
                    <Controller
                      name='imageWorkSchemeDateTo'
                      control={formSet.control}
                      render={({ field }) => (
                        <DatePicker
                          onChange={field.onChange}
                          minDate={minDate}
                          value={field.value}
                        />
                      )}
                    />
                  </Box>
                </ContentsGrid>
              </RowGrid>
              <RowGrid columns={4}>
                <ContentsGrid hxs={1} bxs={3}>
                  <HeadLabel>製版作業予定日</HeadLabel>
                  <Box sx={{ display: 'flex' }}>
                    <Controller
                      name='plateMakingWorkSchemeDateFrom'
                      control={formSet.control}
                      render={({ field }) => (
                        <DatePicker
                          onChange={field.onChange}
                          minDate={minDate}
                          value={field.value}
                        />
                      )}
                    />
                    {'　から　'}
                    <Controller
                      name='plateMakingWorkSchemeDateTo'
                      control={formSet.control}
                      render={({ field }) => (
                        <DatePicker
                          onChange={field.onChange}
                          minDate={minDate}
                          value={field.value}
                        />
                      )}
                    />
                  </Box>
                </ContentsGrid>
              </RowGrid>
            </>
          )}

          <Box sx={{ display: 'flex', width: '100%', mt: 2 }}>
            <CustomButton onClick={() => onChangeAllCondition()}>{allConditionText}</CustomButton>
            <Box sx={{ flexGrow: 1 }} />
            <ClearButton onClick={() => props.onClear()} sx={{ mr: 2 }} />
            <SearchButton onClick={() => props.onSearch()} loading={props.searching} />
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(ImageSearchCondition);
