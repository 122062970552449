import { useAuth } from 'hooks/useAuth';
import * as React from 'react';

import { Apps, Logout } from '@mui/icons-material';
import { Menu, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button, IconButton, Typography } from 'components/parts/atoms';

type ExternalLink = {
  name: string;
  link: string;
};

type ExternalLinkGroup = {
  category: string;
  links: ExternalLink[];
};

const externalLinkGroups: ExternalLinkGroup[] = [
  {
    category: 'リンク',
    links: [
      { name: '東洋FPP', link: 'https://toyofpp.co.jp/' },
    ],
  },
  {
    category: 'etc',
    links: [
      { name: 'Yahoo!JAPAN', link: 'http://www.yahoo.co.jp/' },
      { name: 'Google', link: 'http://www.google.co.jp/' },
    ],
  },
  {
    category: '印刷／DTP関連',
    links: [{ name: 'Jagat', link: 'http://www.jagat.jp/' }],
  },
];

const LogoutButton = styled(Button)(() => ({
  width: 'calc(100% - 20px)',
  height: '100%',
  marginLeft: '10px',
  marginRight: '10px',
}));

const Container = styled(Box)(() => ({
  padding: '10px',
}));

const StyledLink = styled(Link)(() => ({}));

const AppsButton: React.VFC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const { logout } = useAuth();

  const tryLogout = async (): Promise<void> => {
    await logout();
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <Container>
        <LogoutButton variant='contained' size='small' startIcon={<Logout />} onClick={tryLogout}>
          ログアウト
        </LogoutButton>

        <Box my={1}></Box>

        {externalLinkGroups.map((linkItem) => (
          <div key={linkItem.category}>
            <Typography key='' mt={4}>
              {linkItem.category}
            </Typography>

            {linkItem.links.map((link) => (
              <div key={link.link}>
                <StyledLink href={link.link} target='_blank'>
                  {link.name}
                </StyledLink>
              </div>
            ))}
          </div>
        ))}
      </Container>
    </Menu>
  );

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <IconButton
          size='large'
          edge='end'
          aria-label='account of current user'
          aria-controls={menuId}
          aria-haspopup='true'
          onClick={handleProfileMenuOpen}
          color='inherit'
        >
          <Apps />
        </IconButton>
      </Box>
      {renderMenu}
    </>
  );
};

export default AppsButton;
