import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState } from 'react';

import { CylinderDetailResponse, CylinderDetailResponseModel } from '../types';

/** 進捗詳細 対象JOB一覧のフェッチHooks */
export const useFetcCylinderDetail = (
  messageHandler: MessageApi['pushMessages'],
): {
  cylinderDetail: CylinderDetailResponseModel[];
  getCylinderDetail: (jobId: number) => void;
} => {
  const { apiClient } = useApiClient(messageHandler);
  const [cylinderDetail, setCylinderDetail] = useState<CylinderDetailResponseModel[]>([]);

  /** 進捗詳細 対象JOBの初期表示 */
  const getCylinderDetail = (jobId: number) => {
    (async () => {
      try {
        const searchCondition = {
          jobId: jobId,
        };
        const [response] = await Promise.all([
          apiClient.get<CylinderDetailResponse>(`/api/v1/CylinderDetail/GetCylinderDetail`, {
            params: searchCondition,
          }),
        ]);
        setCylinderDetail(response.data.result?.cylinderDetailList ?? []);
      } catch (e) {
        console.log(e);
      }
    })();
  };

  return {
    cylinderDetail,
    getCylinderDetail,
  };
};

export default useFetcCylinderDetail;
