import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FlexoOrderEditForm } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { InitialFlexoOrderEditState } from './defaults';
import { FlexoOrderEditState } from './types';

const slice = createSlice({
  name: 'flexoOrderEdit',
  initialState: InitialFlexoOrderEditState,
  reducers: {
    save: (state, action: PayloadAction<FlexoOrderEditState>) => action.payload,
    saveForm(state, action: PayloadAction<FlexoOrderEditForm>) {
      if (state.data) {
        state.data.form = action.payload;
      }
    },
    clear: () => ({ ...InitialFlexoOrderEditState }),
    setData(state, action: PayloadAction<FlexoOrderEditState['data']>) {
      state.data = action.payload;
    },
    setPageState(state, action: PayloadAction<FlexoOrderEditState['pageState']>) {
      state.pageState = action.payload;
    },
    setTabIndex(state, action: PayloadAction<FlexoOrderEditState['tabIndex']>) {
      state.tabIndex = action.payload;
    },
  },
});

export const { save, saveForm, clear, setData, setPageState, setTabIndex } = slice.actions;

export default slice.reducer;
