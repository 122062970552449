import React from 'react';

import { Box } from '@mui/material';

import UnitPriceCylinder from './unitPriceCylinder';
import UnitPriceFlexo from './unitPriceFlexo';
import UnitPriceOther from './unitPriceOther';

// 単価情報タブ
const UnitPriceMain: React.VFC = () => {
  return (
    <>
      <UnitPriceCylinder />

      <Box m={2} />

      <UnitPriceFlexo />

      <Box m={2} />

      <UnitPriceOther />
    </>
  );
};

export default UnitPriceMain;
