import useApiClient from 'hooks/useApiClient';
import useMessage, { MessageApi } from 'hooks/useMessage';
import { useState } from 'react';

import { PostSearchResultResponse, PostSearchResultModel } from 'api/types/common/postSearchResult';

/** PostSearchResultデータ取得Hooksの型定義 */
export const useFetchPostSearchResult = (
  zipcd: string,
): {
  postSearchResults: PostSearchResultModel[];
  messageApi: MessageApi;
  fetch: () => void;
  clear: () => void;
  listCount: number;
} => {
  const messageApi = useMessage();
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // 郵便番号検索結果一覧のState
  const [postSearchResults, setpostSearchResults] = useState<PostSearchResultModel[]>([]);
  const [listCount, setListCount] = useState(20);

  // 検索用の郵便番号
  const PostSearchParam = {
    zipcd: zipcd,
  };

  const fetch = () => {
    (async () => {
      const response = await apiClient.get<PostSearchResultResponse>(
        `/api/v1/PostSearchResult/GetPostSearchResult`,
        {
          params: PostSearchParam,
        },
      );
      setpostSearchResults(response.data.result?.postSearchResult ?? []);
      setListCount(response.data.result?.listCount ?? 20);
    })();
  };

  const clear = () => {
    setpostSearchResults([]);
  };

  return {
    postSearchResults,
    messageApi,
    fetch,
    clear,
    listCount,
  };
};

export default useFetchPostSearchResult;
