import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState } from 'react';

import { ToyoRateResponse } from 'api/types/customer/unitPriceEdit';

import { CustomerSectionCdCondition } from '../types';

/** Hooks */
export const useFetchUnitPriceFlexo = (): {
  messageApi: MessageApi;
  loading: boolean;
  toyoRate: number;
  setToyoRate: (toyoRate: number) => void;
  getToyoRate: (customerSectionCd: string, sectionPeculiarInfo: string) => void;
} => {
  // メッセージ
  const messageApi = useSharedMessage();
  const { apiClient, loading } = useApiClient(messageApi.pushMessages);

  //ステート
  const [toyoRate, setToyoRate] = useState<number>(0);

  // 東洋売価率取得
  const getToyoRate = (customerSectionCd: string, sectionPeculiarInfo: string) => {
    (async () => {
      try {
        setToyoRate(0);
        const condition: CustomerSectionCdCondition = {
          customerSectionCd: customerSectionCd,
          sectionPeculiarInfo: sectionPeculiarInfo,
        };
        const [allresults] = await Promise.all([
          apiClient.get<ToyoRateResponse>(`/api/v1/UnitPriceEdit/GetToyoRate`, {
            params: condition,
          }),
        ]);
        if (allresults.data.result?.toyoRate) {
          setToyoRate(allresults.data.result.toyoRate);
        }
      } catch (e) {
        console.log(e);
      }
    })();
    return false;
  };

  return {
    messageApi,
    loading,
    toyoRate,
    setToyoRate,
    getToyoRate,
  };
};

export default useFetchUnitPriceFlexo;
