import {
  CustomerEdit,
  DeliveryPlaceEdit,
  CylinderUnitPrice,
  FlexoUnitPrice,
  OtherUnitPrice,
  CustomerEditSingleInfosModel,
  CustomerEditHeader,
  OriginalIds,
} from './types';

/** 得意先編集単一データ初期値 */
const defaultCustomerEditSingleInfoModel: CustomerEditSingleInfosModel = {
  customerId: -1,
  customerSectionCd: '',
  customerCd: '',
  customerName: '',
  jobNoCd: '',
  jobCodeId: 0,
  deleteFlg: false,
  customerGroup: '',
  customerFormalName: '',
  branchFlg: false,
  customerKanaName: '',
  sectionPeculiarInfo: '',
  zipCd: '',
  transporterId: '',
  prefecturesId: '',
  address1: '',
  address2: '',
  buildingName: '',
  tel: '',
  fax: '',
  representativeName: '',
  inkCustomerCd: 0,
  inkStoreHouseCd: '',
  inkSupplierCd: '',
  inkFlg: 0,
  closeDateId: '',
  stateOfDeliveryCloseDate: 0,
  paymentConditionCdId: '',
  offsetDivision: false,
  cashLimit: 0,
  cashOverPaymentConditionCdId: '',
  creditLimit: 0,
  billSight: '',
  paymentPlanMonthId: '',
  paymentPlanDate: '',
  demandSectionCd: '',
  demandId: null,
  demandCd: '',
  demandName: '',
  demandCdId: '',
  billSendConditionCdId: '',
  tradeBankCd: '',
  tradeBankBranchCd: '',
  tradeBankAccountCd: '',
  tradeAccountName: '',
  tradeTransFerCdId: '',
  taxCalcCdId: '354',
  taxAmountFractionProcessCdId: '358',
  taxDeliveryOutputCdId: '10031',
  taxBillOutputCdId: '354',
  customerAppointSlipDeliveryFlg: false,
  customerAppointSlipBillFlg: false,
  toyoInkStockingSlipFlg: false,
  toyoInkDeliveryDetailsFlg: false,
  toyoInkDeliveryFlg: false,
  toyoInkCylmakingStateFlg: false,
  toyoInkFinalCustomerName: '',
  supplierSectionCd: '',
  supplierCd: '',
  toyoPriceRate: 0,
  toyoInkoyoInkSupplierId: null,
};

/** セレクトボックスの初期値データ */
export const defaultSelectBoxModel = {
  transporters: [],
  sections: [],
  ourCharges: [],
  prefectures: [],
  closeDates: [],
  paymentAndLimitOvers: [],
  paymentDates: [],
  invoiceTerms: [],
  invoiceSendTerms: [],
  transactionTransfers: [],
  calAndInvoiceOutputs: [],
  amountFractions: [],
  deliveryShips: [],
  shafts: [],
};

/** オリジナルIDのモデル */
export const defaultOriginalIds: OriginalIds = {
  customerCd: '',
  customerSectionCd: '',
  chargeOurIds: [],
  chargeYourIds: [],
  closeDateIds: [],
  unitCylinderIds: [],
  unitFlexoIds: [],
  unitOtherIds: [],
  deliveryPlaceIds: [],
};

/** 得意先編集初期値 */
export const defaultCustomerEdit: CustomerEdit = {
  customerEditSingleInfo: defaultCustomerEditSingleInfoModel,
  chargesOur: [],
  chargesYour: [],
  closeDates: [],
  cylinderUnitPrice: [],
  flexoUnitPrice: [],
  otherUnitPrice: [],
  deliveryPlace: [],
  selectBoxData: defaultSelectBoxModel,
  originalIds: defaultOriginalIds,
};

/** 得意先編集初期値 */
export const defaultHeader: CustomerEditHeader = {
  customerSectionCd: '',
  customerCd: '',
  customerName: '',
};

/** シリンダ単価入力初期値 */
export const defaultUnitPriceCylinderModel: CylinderUnitPrice = {
  cylUnitPriceId: -1,
  unitPriceName: '',
  unitPriceExplanation: '',
  familyName: '',
  fullName: '',
  registDate: null,
  shaftCdId: '',
  toyoPriceRate: 0,
  cylMakingCalcType: 0,
  materialSprice1: null,
  materialSprice2: null,
  materialSprice3: null,
  materialSprice4: null,
  repeatCr: null,
  cuPlate: null,
  crPlate: null,
  finishPolish: null,
  rakuhanEditionPolish: null,
  cylMaking: null,
  colorCylMaking: null,
  middleTone: null,
  gradetion: null,
  endless: null,
  additionalCorrosion: null,
  outSideTypeCutting: null,
  herio: null,
  fare: null,
  cylCircumferencePremiumRate1: null,
  cylCircumferencePremiumRate2: null,
  cylCircumferencePremiumRate3: null,
  cylCircumferencePremiumRate4: null,
  cylCircumferencePremiumRate5: null,
  cylCircumferencePremiumRate6: null,
  cylCircumferencePremiumRate7: null,
  cylCircumferencePremiumRate8: null,
  cylCircumferencePremiumRate9: null,
  cylCircumferencePremiumRate10: null,
  cylCircumferencePremiumRate11: null,
  materialMinimumPrice9: null,
  materialSpecialRateRate9: null,
  cuPlateMinimum9: null,
  crPlateMinimum9: null,
  rakuhanMinimum9: null,
  cylMakingMinimum9: null,
  additionalCorrosionMinimum9: null,
  reCrPlateMinimum9: null,
  selectrons9: null,
  aoyaki9: null,
  pos9: null,
  bubikiRate9: null,
  discountRate9: null,
  warimasiRate9: null,
};

/** フレキソ単価入力初期値 */
export const defaultUnitPriceFlexoModel: FlexoUnitPrice = {
  flxUnitPriceId: -1,
  unitPriceName: '',
  unitPriceExplanation: '',
  familyName: '',
  fullName: '',
  registDate: null,
  toyoPriceRate: 0,
  betahan: null,
  dataMakePrice: null,
  halfTone: null,
  dataEditPrice: null,
  barrierPrice: null,
  layoutDataPrice: null,
  negafilmPrice: null,
  colorProcessPrice: null,
  posireturnPrice: null,
  photoRetouchPrice: null,
  singleFacenegaPrice: null,
  blockCopyPrice: null,
  composerPrice: null,
  fare: null,
  matnegaPrice: null,
  platePrice: null,
};

/** その他単価入力初期値 */
export const defaultUnitPriceOtherModel: OtherUnitPrice = {
  othOrderUnitPriceId: -1,
  unitPriceName: '',
  unitPriceExplanation: '',
  familyName: '',
  fullName: '',
  registDate: null,
  toyoPriceRate: 0,
  platePartialPlatingEquipset: null,
  platePartialPlatingEquipunit: null,
  plateDegreasingLiquidPrice: null,
  plateActivationLiquidPrice: null,
  plateNickelLiquidPrice: null,
  plateCrLiquidPrice: null,
  plateTansoboPrice: null,
  plateTansoboholder: null,
  plateLeadRed: null,
  plateLeadBlack: null,
  platePlastictape: null,
  plateSandpaperAttachrubber: null,
  plateSandpaper: null,
  plateFarePrice: null,
  lyocell10mmTapePrice: null,
  lyocell15mmTapePrice: null,
  lyocell30mmTapePrice: null,
  lyocellOpaquePrice: null,
  lyocellOpaquEdiluentPrice: null,
  lyocellOpaquEremovePrice: null,
  lyocellAdhesivePrice: null,
  lyocellAdhesiveRemovePrice: null,
  lyocellFarePrice: null,
  usheetUsheet: 17000,
  usheetPolyneT600: null,
  usheetPolyneT800: null,
  usheetPolyneT1000: null,
  usheetHardnester800850: null,
  usheetHardnester900950: null,
  usheetHardnesterKaeshin: null,
  dansheet900: null,
  dansheet1000: null,
  dansheet1100: null,
  dansheet1200: null,
  dansheet1250: null,
  dansheet1300: null,
  blackLacquerhgj001Eu: null,
};

/** 納入先登録初期値 */
export const defaultDeliveryPlaceEdit: DeliveryPlaceEdit = {
  deliveryPlaceId: -1,
  souhusakiFlg: false,
  deliveryPlaceName: '',
  zipCd: '',
  transporterId: '',
  prefecturesName: '',
  prefucturesId: '',
  address1: '',
  address2: '',
  buildingName: '',
  tel: '',
};
