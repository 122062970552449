import { FlexoMaking } from '../types';

export function hasValue(value: string | number | boolean | null): boolean {
  if (typeof value === 'string') {
    return !!value;
  } else if (typeof value === 'number') {
    return true;
  } else if (typeof value === 'boolean') {
    return value;
  } else {
    return !!value;
  }
}

/**
 * 行に入力があるか
 */
export function isValidFlexoMaking(item: FlexoMaking): boolean {
  return (
    hasValue(item.plateMakingId) ||
    hasValue(item.printColorNo) ||
    hasValue(item.printColor) ||
    hasValue(item.colorTipCdId) ||
    hasValue(item.colorTip) ||
    hasValue(item.lineCount) ||
    hasValue(item.angle) ||
    hasValue(item.reliefDepth) ||
    hasValue(item.aniloxLineCount) ||
    hasValue(item.awaseBanNo)
  );
}
