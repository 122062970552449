import { MessageContext } from 'contexts/MessageContext';
import { MessageApi } from 'hooks/useMessage';
import { useContext, useEffect } from 'react';

import { usePrevious } from './usePrevious';
import { useScroll } from './useScroll';

const useSharedMessage = (): MessageApi => {
  const messageContext = useContext(MessageContext);

  const messagesLength = messageContext?.messages.length ?? 0;
  const prevMessagesLength = usePrevious(messagesLength);
  const { scrollTop } = useScroll();

  // メッセージがプッシュされたら画面トップにスクロール
  useEffect(() => {
    if (prevMessagesLength < messagesLength) {
      scrollTop();
    }
  }, [messagesLength]);

  if (messageContext === undefined) {
    throw new Error('Messages context undefined');
  }

  messageContext.messages;

  return messageContext;
};

export default useSharedMessage;
