import React, { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useFetchImageList } from 'pages/common/ImageDataSearch/hooks/useFetchImageList';

import { IconButton, ConfirmButton, CloseButton } from 'components/parts/atoms';
import { Dialog, FooterButtonsContainer } from 'components/parts/molecules';
import ImageDetail from 'components/parts/organisms/ImageServer/ImageDetail';
import ImageList from 'components/parts/organisms/ImageServer/ImageList';
import ImageSearchCondition from 'components/parts/organisms/ImageServer/ImageSearchCondition';

import { initialImageSearchParameter } from './defaults';
import { ImageSearchParameter, ImageSearchDisplayModel, SelectImageData } from './types';

/** 画像検索ダイアログのProps型定義 */
type ImageDataSearchProps = {
  open: boolean;
  onClose: (value: SelectImageData | null) => void;
};

/** 画像検索ダイアログ */
const ImageDataSearch: React.VFC<ImageDataSearchProps> = (props) => {
  const formSet = useForm<ImageSearchParameter>({ defaultValues: initialImageSearchParameter });

  // 検索条件開閉フラグのState
  const [searchConditionDisplayFlg, setSearchConditionDisplayFlg] = useState<boolean>(true);
  // 検索条件開閉の表示文言State
  const searchConditionDisplayText = searchConditionDisplayFlg ? 'CLOSE' : 'OPEN';

  const {
    imageList,
    employeeList,
    pageSize,
    loading,
    messageApi,
    getEmployeeList,
    searchImageList,
    clearImageList,
  } = useFetchImageList();

  // 選択されているイメージ詳細IndexのState
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const selectedImage: ImageSearchDisplayModel = imageList[selectedImageIndex] ?? null;

  const prevImage: ImageSearchDisplayModel | null = imageList[selectedImageIndex - 1] ?? null;
  const nextImage: ImageSearchDisplayModel | null = imageList[selectedImageIndex + 1] ?? null;

  const apiRef = useGridApiRef();

  useEffect(() => {
    getEmployeeList();
    formSet.reset(initialImageSearchParameter);
  }, []);

  // 検索結果が返ってきたら検索条件を非表示
  useEffect(() => {
    setSearchConditionDisplayFlg(imageList.length === 0);
    if (imageList.length > 0) {
      apiRef.current.setSelectionModel([imageList[0].imageDataId]);
    }
  }, [imageList]);

  const getImageIndex = (image: ImageSearchDisplayModel | null) => {
    return imageList.findIndex((x) => x.imageDataId === image?.imageDataId);
  };

  // イベント
  const onImageRowSelect = useCallback(
    (image: ImageSearchDisplayModel | null) => {
      const index = imageList.findIndex((x) => x.imageDataId === image?.imageDataId);
      setSelectedImageIndex(index);
    },
    [imageList],
  );

  /** 検索条件表示非表示イベント */
  const onDisplayChange = useCallback(() => {
    setSearchConditionDisplayFlg((prev) => !prev);
  }, [searchConditionDisplayFlg]);

  /** 検索イベント */
  const onSearch = useCallback(
    formSet.handleSubmit((data: ImageSearchParameter) => {
      messageApi.clear();
      searchImageList(data);
    }),
    [],
  );

  /** 条件クリアイベント */
  const onClear = useCallback(() => {
    messageApi.clear();
    formSet.reset(initialImageSearchParameter);
  }, [formSet]);

  /** 次へイベント */
  const onNextImage = () => {
    if (nextImage) {
      apiRef.current.setSelectionModel([nextImage.imageDataId]);
      const index = getImageIndex(nextImage);
      const page = Math.floor(index / pageSize);
      apiRef.current.setPage(page);
    }
  };

  /** 前へイベント */
  const onBackImage = () => {
    if (prevImage) {
      apiRef.current.setSelectionModel([prevImage.imageDataId]);
      const index = getImageIndex(prevImage);
      const page = Math.floor(index / pageSize);
      apiRef.current.setPage(page);
    }
  };

  /** 画像選択のイベント */
  const onConfirmClick = () => {
    props.onClose(selectedImage);
    displayReset();
  };

  /** 閉じるイベント */
  const onCloseClick = useCallback(() => {
    props.onClose(null);
    displayReset();
  }, []);

  // ロジック
  /** ディスプレイの初期化 */
  const displayReset = useCallback(() => {
    formSet.reset(initialImageSearchParameter);
    clearImageList();
    setSearchConditionDisplayFlg(true);
  }, []);

  return (
    <Dialog
      open={props.open}
      title={'検索'}
      onClose={onCloseClick}
      maxWidth={'lg'}
      messageApi={messageApi}
    >
      <FormProvider {...formSet}>
        <ImageSearchCondition
          onDisplayChange={onDisplayChange}
          onSearch={onSearch}
          onClear={onClear}
          employeeList={employeeList}
          displayFlg={searchConditionDisplayFlg}
          buttonText={searchConditionDisplayText}
          searching={loading}
        />
      </FormProvider>
      <Box my={2} />

      {imageList.length > 0 && (
        <>
          <ImageDetail image={selectedImage} downloadable={false} thumbnailClickable={false} />
          {imageList.length > 0 && (
            <Box sx={{ display: 'flex' }}>
              {prevImage && (
                <IconButton onClick={() => onBackImage()}>
                  <ArrowBackIosNewIcon />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }} />
              {nextImage && (
                <IconButton onClick={() => onNextImage()}>
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
            </Box>
          )}

          <ImageList
            data={imageList}
            pageSize={pageSize}
            loading={loading}
            onImageRowSelect={onImageRowSelect}
            apiRef={apiRef}
          />
        </>
      )}
      <FooterButtonsContainer>
        {imageList.length === 0 && <CloseButton onClick={() => onCloseClick()} />}
        {imageList.length > 0 && <ConfirmButton onClick={() => onConfirmClick()} />}
      </FooterButtonsContainer>
    </Dialog>
  );
};

export default ImageDataSearch;
