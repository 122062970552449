import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialBarcodeReadCrPlatingState } from 'store/topPlating/barcodeReadCrPlating/defaults';
import { BarcodeReadCrPlatingState } from 'store/topPlating/barcodeReadCrPlating/types';

const slice = createSlice({
  name: 'barcodeReadCrPlating',
  initialState: InitialBarcodeReadCrPlatingState,
  reducers: {
    save: (state, action: PayloadAction<BarcodeReadCrPlatingState>) => action.payload,
    clear: () => ({ ...InitialBarcodeReadCrPlatingState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
