import { OrderListState } from './types';

/** 受注一覧のストアモデルの初期値 */
export const InitialOrderListState: OrderListState = {
  selectEmpoyeeId: '',
  cylinderCustomerId: '',
  flexoCustomerId: '',
  otherCustomerId: '',
  jobNo: '',
};
