import { useFormContext, useWatch } from 'react-hook-form';

import { SalesPrice, TotalPriceValues } from '../../../types';
import { CylinderOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';
import { useHanberiOptionPriceTotal } from './useHanberiOptionPriceTotal';
import { useHanberiPriceTotal } from './useHanberiPriceTotal';
import { useNormalOptionPriceTotal } from './useNormalOptionPriceTotal';
import { useNormalPriceTotal } from './useNormalPriceTotal';
import { useOtherCylinderPriceTotal } from './useOtherCylinderPriceTotal';
import { useOtherImagePriceTotal } from './useOtherImagePriceTotal';
import { useReChroniumPriceTotal } from './useReChroniumPriceTotal';
import { useSetPriceTotal } from './useSetPriceTotal';

/** 合計情報 Hooks */
export const useTotalPrice = (): TotalPriceValues => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcDiscountRate, calcGrossInvoice } = usePriceCalculater();

  const [discountRateValue, discountPriceValue, cylShipmentFlg, imageProcessonlyFlg] = useWatch({
    control,
    name: [
      'price.discount.discountRate',
      'price.discount.discountPrice',
      'schedule.scheduleDate.cylShipmentFlg',
      'schedule.scheduleDate.imageProcessonlyFlg',
    ],
  });

  // 画像作業のみフラグ
  const isImageWorkOnly = !cylShipmentFlg && imageProcessonlyFlg;

  /** フルコース、 改版、増版 計*/
  let normal: SalesPrice = useNormalPriceTotal();
  let normalOption: SalesPrice = useNormalOptionPriceTotal();
  let normalTotal: SalesPrice = {
    price: normal.price + normalOption.price,
    grossInvoice: normal.grossInvoice + normalOption.grossInvoice,
  };
  /** 版べり再版 計*/
  let hanberi: SalesPrice = useHanberiPriceTotal();
  let hanberiOption: SalesPrice = useHanberiOptionPriceTotal();
  let hanberiTotal: SalesPrice = {
    price: hanberi.price + hanberiOption.price,
    grossInvoice: hanberi.grossInvoice + hanberiOption.grossInvoice,
  };
  /** 再クロム 計*/
  let reChronium: SalesPrice = useReChroniumPriceTotal();
  /** その他（シリンダ） 計*/
  let otherCylinder: SalesPrice = useOtherCylinderPriceTotal();
  /** その他（画像） 計*/
  const otherImage: SalesPrice = useOtherImagePriceTotal();
  /** 一式計算 計*/
  let set: SalesPrice = useSetPriceTotal();

  // 画像作業のみの場合は製版作業の価格を0にする
  if (isImageWorkOnly) {
    const emptyPrice: SalesPrice = {
      price: 0,
      grossInvoice: 0,
    };
    normal = emptyPrice;
    normalOption = emptyPrice;
    normalTotal = emptyPrice;
    hanberi = emptyPrice;
    hanberiOption = emptyPrice;
    hanberiTotal = emptyPrice;
    reChronium = emptyPrice;
    otherCylinder = emptyPrice;
    set = emptyPrice;
  }

  const prices = [normalTotal, hanberiTotal, reChronium, otherCylinder, otherImage, set];

  /** 合計*/
  let totalPrice = 0;
  let totalGrossInvoice = 0;
  for (const price of prices) {
    totalPrice += price.price;
    totalGrossInvoice += price.grossInvoice;
  }

  /** 値引き率 計*/
  const discountRate: SalesPrice = {
    price: calcDiscountRate(totalPrice, discountRateValue),
    grossInvoice: calcDiscountRate(totalGrossInvoice, discountRateValue),
  };

  /** 値引き 計*/
  const discountPrice: SalesPrice = {
    price: discountPriceValue ?? 0,
    grossInvoice: calcGrossInvoice(discountPriceValue, toyoPriceRate),
  };

  /** 総合計*/
  const total: SalesPrice = {
    price: discountRate.price - discountPrice.price,
    grossInvoice: discountRate.grossInvoice - discountPrice.grossInvoice,
  };

  return {
    normal: normalTotal,
    hanberi: hanberiTotal,
    reChronium,
    otherCylinder,
    otherImage,
    set,
    discountRate,
    discountPrice,
    total,
  };
};
