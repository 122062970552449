import { CylinderSearcherConditionModel, InitInfo } from './types';

/** CylinderSearcher／検索条件 */
export const initialCylinderSearcherCondition: CylinderSearcherConditionModel = {
  barcodeMode: true,
  barcode: '',
  jobNo: '',
  customerSectionCd: '',
  customerCd: '',
  customerList: [],
  customerName: '',
  productionName: '',
  ictagNo: '',
  cylsizeWidth: null,
  cylsizeEnsyuu: null,
  plateStatusId: '',
  areaId: '',
  inventoryAreaId: '',
  stampNo: '',
  jobStatusId: '',
  cylStatusId: '',
  selectedDateKind: '',
  incShipped: false,
  selectedDateFrom: null,
  selectedDateTo: null,
  selectedSortKey: '',
};

/** CylinderSearcher／初期情報 */
export const initialInitInfo: InitInfo = {
  plateStatusList: [],
  areaList: [],
  inventoryAreaList: [],
  jobStatusList: [],
  cylStatusList: [],
  listCount: 50,
};
