/** 画像データ確認表示Hooks */
const useShowImageDataDisplay = (): {
  show: (jobId: number) => void;
} => {
  /* 画像データ確認表示関数 **/
  const show = (jobId: number) => {
    // 画面サイズ
    const windowsize = 'width=1000,height=700,toolbar=yes,menubar=yes,scrollbars=yes';

    // シリンダー製販指示書表示
    const url = `/ImageDataDisplay/${jobId}`;
    window.open(url, '', windowsize);
  };

  return { show };
};

export default useShowImageDataDisplay;
