import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';
import Providers from './Providers';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root'),
);

registerServiceWorker();
