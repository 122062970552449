import React, { useState } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import theme from 'themes/theme';

import { Box } from '@mui/material';

import UnitPriceEditCylinderMain from 'pages/customer/UnitPriceEditCylinder';

import {
  Typography,
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  EditButton,
  DeleteButton,
  AddButton,
} from 'components/parts/atoms';

import { dateText } from 'utils/date';

import { defaultUnitPriceCylinderModel } from '../../defaults';
import { CustomerEdit, CylinderUnitPrice } from '../../types';

// 単価入力画面
const UnitPriceCylinder: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();

  // シリンダ配列
  const cylinderArray = useFieldArray({
    control: formContext.control,
    name: 'cylinderUnitPrice',
  });

  // 追加・編集ステート
  const [indexState, setIndexState] = useState<number>(-1);
  const [cylinderUnitPrice, setCylinderUnitPrice] = useState<CylinderUnitPrice>(
    defaultUnitPriceCylinderModel,
  );
  // 新規作成単価ID-1から順にセット
  const [unitPriceId, setUnitPriceId] = useState<number>(-1);
  // 追加ボタンアクション
  const onCreateUnitPrice = () => {
    const toyoPriceRate = formContext.getValues('customerEditSingleInfo.toyoPriceRate');
    setCylinderUnitPrice(defaultUnitPriceCylinderModel);
    setCylinderUnitPrice((origin) => ({
      ...origin,
      toyoPriceRate: toyoPriceRate,
      cylUnitPriceId: unitPriceId,
    }));
    setUnitPriceId(unitPriceId - 1);
    setOpenUnitPriceEdit(true);
  };
  // 編集ボタンアクション
  const onEditUnitPrice = (index: number) => {
    const target = formContext.getValues('cylinderUnitPrice')[index];
    setIndexState(index);
    if (target !== undefined) {
      setCylinderUnitPrice(target);
    }
    setOpenUnitPriceEdit(true);
  };

  // 削除ボタンアクション
  const onDeleteUnitPrice = (index: number) => {
    cylinderArray.remove(index);
  };

  // 単価入力編集ダイアログ
  const [openUnitPriceEdit, setOpenUnitPriceEdit] = React.useState(false);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <UnitPriceEditCylinderMain
          open={openUnitPriceEdit}
          onClose={(value) => {
            setOpenUnitPriceEdit(false);
            if (value) {
              // 編集
              if (indexState !== -1) {
                cylinderArray.update(indexState, value);
              }
              // 新規
              else {
                cylinderArray.append(value);
              }
            }
            setIndexState(-1);
          }}
          index={indexState}
          state={cylinderUnitPrice}
          checkFinishFlag={false}
        />
      </>
    );
  };

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            シリンダ
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={24}>
        <HeadGrid xs={7} sx={{ justifyContent: 'center' }}>
          <HeadLabel>単価名</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={7} sx={{ justifyContent: 'center' }}>
          <HeadLabel>説明</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          <HeadLabel>登録者</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>登録日</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={5} sx={{ justifyContent: 'center' }}>
          <AddButton onClick={() => onCreateUnitPrice()}></AddButton>
        </HeadGrid>
      </RowGrid>

      {cylinderArray.fields.map((item, index) => {
        return (
          <div key={item.id}>
            <RowGrid columns={24}>
              <BodyGrid xs={7}>
                <Controller
                  name={`cylinderUnitPrice.${index}.unitPriceName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={7}>
                <Controller
                  name={`cylinderUnitPrice.${index}.unitPriceExplanation`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={3}>
                <Controller
                  name={`cylinderUnitPrice.${index}.fullName`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={2}>
                <Controller
                  name={`cylinderUnitPrice.${index}.registDate`}
                  control={formContext.control}
                  render={({ field }) => <BodyLabel>{dateText(field.value)}</BodyLabel>}
                />
              </BodyGrid>
              <BodyGrid xs={5} sx={{ justifyContent: 'center' }}>
                <EditButton onClick={() => onEditUnitPrice(index)}></EditButton>
                <Box m={1} />
                <DeleteButton onClick={() => onDeleteUnitPrice(index)}></DeleteButton>
              </BodyGrid>
            </RowGrid>
          </div>
        );
      })}
      {renderDialogs()}
    </>
  );
};

export default UnitPriceCylinder;
