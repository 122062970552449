import * as React from 'react';

import LoadingButton, { LoadingButtonProps } from './LoadingButton';

type Props = {
  children: string;
  onClick?: () => void;
  disabled?: LoadingButtonProps['disabled'];
  loading?: LoadingButtonProps['loading'];
  icon?: LoadingButtonProps['startIcon'];
  iconOnly?: boolean;
  color?: LoadingButtonProps['color'];
  classes?: LoadingButtonProps['classes'];
  className?: LoadingButtonProps['className'];
  sx?: LoadingButtonProps['sx'];
  type?: LoadingButtonProps['type'];
};

export type CustomButtonProps = Omit<Props, 'children' | 'icon'>;

const CustomButton: React.VFC<Props> = ({ icon, iconOnly, children, ...props }) => {
  if (iconOnly) {
    return <LoadingButton loadingPosition='start' startIcon={icon} {...props}></LoadingButton>;
  } else {
    return (
      <LoadingButton loadingPosition='start' startIcon={icon} {...props}>
        {children}
      </LoadingButton>
    );
  }
};

export default CustomButton;
