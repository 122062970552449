import useMessage from 'hooks/useMessage';
import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { Box, Grid } from '@mui/material';

import {
  Typography,
  DeleteButton,
  TextField,
  Circular,
  AddButton,
  SaveButton,
  CloseButton,
} from 'components/parts/atoms';
import { Dialog } from 'components/parts/molecules';

import { InitialMyMemos } from './defaults';
import { useFetchMyMemo } from './hooks/useFetchMyMemo';
import { useRegister } from './hooks/useRegister';
import { MyMemo, MyMemoForm } from './types';

export type Props = {
  open: boolean;
  onClose: () => void;
  onSaveClose: () => void;
};

/** MyMemoコンポーネント */
const MyMemoEdit: React.VFC<Props> = (props) => {
  // hocks
  const messageApi = useMessage();
  const { myMemos, originals, fetch, clear, loading } = useFetchMyMemo(messageApi.pushMessages);
  const { complete, myMemoRegister, resetComplete } = useRegister(messageApi.pushMessages);

  // react hook form
  const { control, handleSubmit, reset } = useForm<MyMemoForm>({
    defaultValues: InitialMyMemos,
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'myMemos' });

  useEffect(() => {
    if (props.open) {
      console.log(props.open);
      fetch();
    } else {
      clear();
    }
  }, [props.open]);

  useEffect(() => {
    reset({ myMemos: myMemos });
  }, [myMemos]);

  useEffect(() => {
    if (complete) {
      onClose();
    }
  }, [complete]);

  /* ダイアログ非表示 **/
  const onClose = () => {
    clear();
    resetComplete();
    if (complete) {
      props.onSaveClose();
    }
    else {
      props.onClose();
    }
  };

  // Event
  /* マイメモ行追加 **/
  const onAddClick = () => {
    const newRow: MyMemo = { mymemoId: -1, memo: '' };
    append(newRow);
  };

  /* マイメモ行削除 **/
  const onDeleteCrick = (index: number) => {
    remove(index);
  };

  /* マイメモ登録 **/
  const onSubmit = handleSubmit((data: MyMemoForm) => {
    console.log('登録データ');
    console.log(data);
    console.log(originals);
    data.myMemoOriginals = originals;
    myMemoRegister(data);
  });

  return (
    <Dialog open={props.open} title={'MY MEMO'} onClose={onClose} messageApi={messageApi}>
      <Box sx={{ width: '100%' }}>
        {(() => {
          if (loading) {
            return <Circular />;
          } else {
            return (
              <form>
                <Grid alignItems='center' justifyContent='center' container spacing={2}>
                  <Grid item xs={10}>
                    <Typography>本文</Typography>
                  </Grid>
                  <Grid item xs={2} textAlign={'end'}>
                    <AddButton onClick={() => onAddClick()} />
                  </Grid>
                </Grid>
                <Box m={2} />
                <Grid container spacing={1}>
                  {fields.map((item, index) => {
                    return (
                      <Grid
                        alignItems='center'
                        justifyContent='center'
                        spacing={2}
                        container
                        key={item.id}
                        marginBottom={2}
                        columns={15}
                      >
                        <Grid item xs={13}>
                          <Controller
                            name={`myMemos.${index}.memo`}
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                              <TextField
                                onChange={onChange}
                                value={value}
                                name={name}
                                inputProps={{ maxLength: 80 }}
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2} textAlign={'end'}>
                          <DeleteButton onClick={() => onDeleteCrick(index)}></DeleteButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                <SaveButton onClick={onSubmit} />
                <Box m={1} />
                <CloseButton onClick={props.onClose} />
                </Box>
              </form>
            );
          }
        })()}
      </Box>
    </Dialog>
  );
};

export default MyMemoEdit;
