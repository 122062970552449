import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

import { save, clear } from '.';
import { AuthState } from './types';

export const useAuthStore = (): {
  state: AuthState;
  save: (state: AuthState) => void;
  clear: () => void;
} => {
  const state = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const dispatchSave = useCallback(
    (state: AuthState) => {
      dispatch(save(state));
    },
    [dispatch],
  );
  const dispatchClear = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  return { state, save: dispatchSave, clear: dispatchClear };
};
