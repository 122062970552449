import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, FormControlLabel } from '@mui/material';
import {
  HeadLabel,
  BodyLabel,
  ClearButton,
  SearchButton,
  KanaField,
  DatePicker,
  Heading,
  Select,
  Checkbox,
} from 'components/parts/atoms';
import { ContentsGrid, FooterButtonsContainer } from 'components/parts/molecules';

import { defaultSearchCondition } from '../defaults';
import Customer from './Customer';
import { SELECT_DATE_TYPE_OPTIONS, SELECT_TOTAL_JOB_STATUS_OPTIONS } from 'constants/define';

type Props = {
  onSearchPriceApprovalList: () => void;
  searchLoading: boolean;
  dailyProcessDate: Date | null;
};

/** 価格承認一覧検索 */
const PriceApprovalListSearch: React.VFC<Props> = (props) => {
  // 検索機能ん実行と同時に、検索条件をstoreに保存する
  const { control, reset } = useFormContext();

  const onSearch = () => {
    props.onSearchPriceApprovalList(); // 最新のフォームの値を使用
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Heading>価格承認</Heading>
        <Customer />
        <Box sx={{ display: 'flex' }}></Box>
        <Box sx={{ display: 'flex' }}>
          <ContentsGrid
            hxs={1}
            bxs={1}
            hsx={{
              width: '25%',
            }}
            bsx={{ width: '75%' }}
          >
            <HeadLabel>Job No.</HeadLabel>
            <BodyLabel>
              <Controller
                name='jobNo'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <KanaField onChange={onChange} value={value} name={name} maxLength={20} />
                )}
              />
            </BodyLabel>
          </ContentsGrid>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <ContentsGrid
            hxs={1}
            bxs={1}
            hsx={{
              width: '25%',
            }}
            bsx={{ width: '75%' }}
          >
            <Controller
              name={'selectedDateType'}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  value={value}
                  options={SELECT_DATE_TYPE_OPTIONS}
                  onChange={onChange}
                  name={name}
                  addEmptyRow={true}
                  sx={{ width: '10em' }}
                />
              )}
            />
            <BodyLabel>
              <Controller
                name='dateStart'
                control={control}
                render={({ field }) => (
                  <DatePicker value={field.value} onChange={field.onChange}></DatePicker>
                )}
              ></Controller>
              {'  ～  '}
              <Controller
                name='dateEnd'
                control={control}
                render={({ field }) => (
                  <DatePicker value={field.value} onChange={field.onChange}></DatePicker>
                )}
              ></Controller>
            </BodyLabel>
          </ContentsGrid>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <ContentsGrid
            hxs={1}
            bxs={1}
            hsx={{
              width: '25%',
            }}
            bsx={{ width: '75%' }}
          >
            <HeadLabel>全体ステータス</HeadLabel>
            <BodyLabel>
              <Controller
                name={'totalJobStatus'}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Select
                    value={value}
                    options={SELECT_TOTAL_JOB_STATUS_OPTIONS}
                    onChange={onChange}
                    name={name}
                    addEmptyRow={true}
                    sx={{ width: '15em' }}
                  />
                )}
              />
            </BodyLabel>
          </ContentsGrid>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <ContentsGrid
            hxs={1}
            bxs={1}
            hsx={{
              width: '25%',
            }}
            bsx={{ width: '75%' }}
          >
            <HeadLabel>承認済/未承認</HeadLabel>
            <BodyLabel>
              <Controller
                name='unApproved'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} name={name} />}
                    label='未承認'
                  />
                )}
              />
              <Controller
                name='firstApprovalDone'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} name={name} />}
                    label='未承認（第一承認済み）'
                  />
                )}
              />
              <Controller
                name='secondApprovalDone'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} name={name} />}
                    label='承認済み（第二承認済み）'
                  />
                )}
              />
            </BodyLabel>
          </ContentsGrid>
        </Box>

        <Box sx={{ display: 'flex' }}>
          <ContentsGrid
            hxs={1}
            bxs={1}
            hsx={{
              width: '25%',
            }}
            bsx={{ width: '75%' }}
          >
            <HeadLabel>承認者名</HeadLabel>
            <BodyLabel>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Controller
                  name='approvalEmployeeName'
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <KanaField onChange={onChange} value={value} name={name} maxLength={20} />
                  )}
                />
                <Controller
                  name='firstApproval'
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} name={name} />}
                      label='第一承認者'
                    />
                  )}
                />
                <Controller
                  name='secondApproval'
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} name={name} />}
                      label='第二承認者'
                    />
                  )}
                />
              </Box>
            </BodyLabel>
          </ContentsGrid>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <ContentsGrid
            hxs={1}
            bxs={1}
            hsx={{
              width: '25%',
            }}
            bsx={{ width: '75%' }}
          >
            <HeadLabel>日次処理対象JOB表示</HeadLabel>
            <BodyLabel>
              <Controller
                name='isDailyProcessTargetJobDisplay'
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} name={name} />}
                    label={`（チェック時、他の検索条件は無効）売上計上日：${
                      props.dailyProcessDate ? props.dailyProcessDate.toLocaleDateString() : ''
                    }`}
                  />
                )}
              />
            </BodyLabel>
          </ContentsGrid>
        </Box>
        <FooterButtonsContainer>
          <ClearButton
            onClick={() => {
              reset(defaultSearchCondition);
            }}
          />
          <SearchButton loading={props.searchLoading} onClick={onSearch} />
        </FooterButtonsContainer>
      </Box>
    </>
  );
};

export default PriceApprovalListSearch;
