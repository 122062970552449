import { EigyoItemCode, OtherProductItemName } from 'constants/define';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { OthUnitPrice, OtherOrderEditForm } from '../../types';
import { useOtherOrderEditMaster } from '../useOtherOrderEditMaster';

/** 単価変更時にフォームをリセットするHooks */
export const useResetPrice = (): {
  resetUnitPrice: (othUnitPrice: OthUnitPrice | null) => void;
  resetPrices: (othUnitPrice: OthUnitPrice | null) => void;
} => {
  // フォーム
  const { setValue, getValues } = useFormContext<OtherOrderEditForm>();
  // マスタ
  const { master } = useOtherOrderEditMaster();

  const resetUnitPrice = useCallback(
    (othUnitPrice: OthUnitPrice | null) => {
      if (othUnitPrice) {
        // 東洋売価率をセット
        setValue('price.toyoPriceRate', othUnitPrice?.toyoPriceRate ?? null);

        const otherProductInfos = getValues('price.otherProductInfos');

        /** 項目名が一致する要素の売価を設定する関数 */
        const setPrice = (itemName: string, price: number | null) => {
          const index: number = otherProductInfos.findIndex((e) => e.itemName === itemName);
          setValue(`price.otherProductInfos.${index}.price`, price);
        };

        // 品目に紐づくコードの値を取得
        const eigyoItemCdId = getValues('basis.eigyoItemCdId');
        const eigyoItem = master.eigyoItemList.find((x) => x.value === eigyoItemCdId);

        // コード別に値をセット
        switch (eigyoItem?.decisionCode) {
          case EigyoItemCode.PlateLiquid:
            // メッキ液
            setPrice(
              OtherProductItemName.PartialPlatingEquipUnit,
              othUnitPrice.platePartialplatingequipunit,
            );
            setPrice(
              OtherProductItemName.PartialPlatingEquipSet,
              othUnitPrice.platePartialplatingequipset,
            );
            setPrice(
              OtherProductItemName.DegreasingLiquid,
              othUnitPrice.plateDegreasingliquidPrice,
            );
            setPrice(
              OtherProductItemName.ActivationLiquid,
              othUnitPrice.plateActivationliquidPrice,
            );
            setPrice(OtherProductItemName.NickelLiquid, othUnitPrice.plateNickelliquidPrice);
            setPrice(OtherProductItemName.CRLiquid, othUnitPrice.plateCrliquidPrice);
            setPrice(OtherProductItemName.Tansobo, othUnitPrice.plateTansoboholder);
            setPrice(OtherProductItemName.TansoboHolder, othUnitPrice.plateTansoboholder);
            setPrice(OtherProductItemName.LeadRed, othUnitPrice.plateLeadred);
            setPrice(OtherProductItemName.LeadBlack, othUnitPrice.plateLeadblack);
            setPrice(OtherProductItemName.PlasticTape, othUnitPrice.platePlastictape);
            setPrice(
              OtherProductItemName.SandpaperAttachRubber,
              othUnitPrice.plateSandpaperattachrubber,
            );
            setPrice(OtherProductItemName.Sandpaper, othUnitPrice.plateSandpaper);
            setPrice(OtherProductItemName.Fare, othUnitPrice.plateFarePrice);
            break;
          case EigyoItemCode.Lyocell:
            // リオセル
            setPrice(OtherProductItemName.Lyocell10mmTape, othUnitPrice.lyocell10mmtapePrice);
            setPrice(OtherProductItemName.Lyocell15mmTape, othUnitPrice.lyocell15mmtapePrice);
            setPrice(OtherProductItemName.Lyocell30mmTape, othUnitPrice.lyocell30mmtapePrice);
            setPrice(OtherProductItemName.LyocellOpaque, othUnitPrice.lyocellOpaquePrice);
            setPrice(
              OtherProductItemName.LyocellOpaqueDiluent,
              othUnitPrice.lyocellOpaquediluentPrice,
            );
            setPrice(
              OtherProductItemName.LyocellOpaqueRemove,
              othUnitPrice.lyocellOpaqueremovePrice,
            );
            setPrice(OtherProductItemName.LyocellAdhesive, othUnitPrice.lyocellAdhesivePrice);
            setPrice(
              OtherProductItemName.LyocellAdhesiveRemove,
              othUnitPrice.lyocellAdhesiveremovePrice,
            );
            setPrice(OtherProductItemName.Fare, othUnitPrice.lyocellFarePrice);
            break;
          case EigyoItemCode.Usheet:
            // Uシート（その他2）
            setPrice(OtherProductItemName.UsheetUsheet, othUnitPrice.usheetUsheet);
            setPrice(OtherProductItemName.UsheetPolynet600, othUnitPrice.usheetPolynet600);
            setPrice(OtherProductItemName.UsheetPolynet800, othUnitPrice.usheetPolynet800);
            setPrice(OtherProductItemName.UsheetPolynet1000, othUnitPrice.usheetPolynet1000);
            setPrice(
              OtherProductItemName.UsheetHardnester800850,
              othUnitPrice.usheetHardnester800850,
            );
            setPrice(
              OtherProductItemName.UsheetHardnester900950,
              othUnitPrice.usheetHardnester900950,
            );
            setPrice(
              OtherProductItemName.UsheetHardnesterkaeshin,
              othUnitPrice.usheetHardnesterkaeshin,
            );
            setPrice(OtherProductItemName.Dansheet900, othUnitPrice.dansheet900);
            setPrice(OtherProductItemName.Dansheet1000, othUnitPrice.dansheet1000);
            setPrice(OtherProductItemName.Dansheet1100, othUnitPrice.dansheet1100);
            setPrice(OtherProductItemName.Dansheet1200, othUnitPrice.dansheet1200);
            setPrice(OtherProductItemName.Dansheet1250, othUnitPrice.dansheet1250);
            setPrice(OtherProductItemName.Dansheet1300, othUnitPrice.dansheet1300);
            setPrice(OtherProductItemName.BlackLacquerHGJ001EU, othUnitPrice.blacklacquerhgj001eu);
            break;
        }
      }
    },
    [setValue, getValues, master],
  );

  const resetPrices = (othUnitPrice: OthUnitPrice | null) => {
    resetUnitPrice(othUnitPrice);
  };

  return {
    resetUnitPrice,
    resetPrices,
  };
};
