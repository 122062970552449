import React, { ReactNodeArray } from 'react';

import { HeadGrid, RowGrid, BodyGrid } from 'components/parts/atoms';

/** その他受注製品情報 行コンポーネント */
export const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth, sixth, seventh, eighth],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          {first}
        </HeadGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'center' }}>
          {second}
        </BodyGrid>
        <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
          {third}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'end' }}>
          {fourth}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'center' }}>
          {fifth}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'end' }}>
          {sixth}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'end' }}>
          {seventh}
        </BodyGrid>
        <BodyGrid xs={0.5} sx={{ justifyContent: 'center' }}>
          {eighth}
        </BodyGrid>
      </RowGrid>
    </>
  );
};

/** その他受注製品情報 ヘッダー行コンポーネント */
export const HeadRow: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth, sixth, seventh, eighth],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid vertical xs={3}>
          {first}
        </HeadGrid>
        <HeadGrid vertical xs={1.5}>
          {second}
        </HeadGrid>
        <HeadGrid vertical xs={1}>
          {third}
        </HeadGrid>
        <HeadGrid vertical xs={1.5}>
          {fourth}
        </HeadGrid>
        <HeadGrid vertical xs={1.5}>
          {fifth}
        </HeadGrid>
        <HeadGrid vertical xs={1.5}>
          {sixth}
        </HeadGrid>
        <HeadGrid vertical xs={1.5}>
          {seventh}
        </HeadGrid>
        <HeadGrid vertical xs={0.5}>
          {eighth}
        </HeadGrid>
      </RowGrid>
    </>
  );
};

/** その他受注製品情報 合計行コンポーネント */
export const FinalRow: React.FC<{ children: ReactNodeArray }> = ({
  children: [first, second, third, fourth, fifth, sixth, seventh, eighth],
}) => {
  return (
    <>
      <RowGrid>
        <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
          {first}
        </HeadGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'center' }}>
          {second}
        </BodyGrid>
        <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
          {third}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'center' }}>
          {fourth}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'center' }}>
          {fifth}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'end' }}>
          {sixth}
        </BodyGrid>
        <BodyGrid xs={1.5} sx={{ justifyContent: 'end' }}>
          {seventh}
        </BodyGrid>
        <BodyGrid xs={0.5} sx={{ justifyContent: 'center' }}>
          {eighth}
        </BodyGrid>
      </RowGrid>
    </>
  );
};
