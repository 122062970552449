import React from 'react';

import { Box } from '@mui/material';

import Cylinder9Infos from './components/cylinder9Infos';
import CylinderAddCalculateInfos from './components/cylinderAddCalculateInfos';
import CylinderBasicInfos from './components/cylinderBasicInfos';
import CylinderPremiumRate from './components/cylinderPremiumRate';
import CylinderPricePerPlateInfos from './components/cylinderPricePerPlateInfos';
import CylinderProsessPrice from './components/cylinderProsessPrice';
import { BackButton, ConfirmButton, Typography } from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules/FooterButtonsContainer';

import { CylinderEditProps } from '../types';

const UnitPriceEditCylinder: React.VFC<CylinderEditProps> = (props) => {
  return (
    <>
      <CylinderBasicInfos onSearch={props.onSearch} newCreateFlag={props.newCreateFlag} />

      <Box m={2} />

      <CylinderProsessPrice />

      <Box m={2} />

      <CylinderAddCalculateInfos />

      <Box m={2} />

      <CylinderPricePerPlateInfos />

      <Box m={2} />

      <CylinderPremiumRate />

      <Box m={2} />
      <Box sx={{ width: '100%', display: 'frex' }}>
        <Typography>*****</Typography>
      </Box>

      <Cylinder9Infos />

      <FooterButtonsContainer>
        <BackButton onClick={props.onBack} />
        <ConfirmButton onClick={props.onGoCheck} />
      </FooterButtonsContainer>
    </>
  );
};

export default UnitPriceEditCylinder;
