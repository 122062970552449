import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialCylinderShipmentCancelState } from 'store/topShipment/cylinderShipmentCancel/defaults';
import { CylinderShipmentCancelState } from 'store/topShipment/cylinderShipmentCancel/types';

const slice = createSlice({
  name: 'cylinderShipmentCancelState',
  initialState: InitialCylinderShipmentCancelState,
  reducers: {
    save: (state, action: PayloadAction<CylinderShipmentCancelState>) => action.payload,
    clear: () => ({ ...InitialCylinderShipmentCancelState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
