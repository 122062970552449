import * as React from 'react';

import { Save } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type SaveButtonProps = CustomButtonProps;

const SaveButton: React.VFC<SaveButtonProps> = (props) => {
  return (
    <CustomButton icon={<Save />} color={'careful'} {...props}>
      {'保存'}
    </CustomButton>
  );
};

export default SaveButton;
