import * as React from 'react';

import { Print } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type PrintButtonProps = CustomButtonProps;

const PrintButton: React.VFC<PrintButtonProps> = (props) => {
  return (
    <CustomButton icon={<Print />} {...props}>
      {'印刷'}
    </CustomButton>
  );
};

export default PrintButton;
