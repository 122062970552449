import useApiClient from 'hooks/useApiClient';
import { useState, useEffect } from 'react';

import { GetJobInfoReq, GetJobInfoRes } from 'api/types/common/jobInfo';

import { JobInfoData, JobInfoDetail } from './types';

const useFetchJobInfo = (
  jobId: number,
): {
  jobInfo: JobInfoData | null;
  loading: boolean;
  fetch: () => void;
  updateDetail: (mainMakings: JobInfoDetail[]) => void;
} => {
  const { apiClient } = useApiClient();
  const [loading, setLoading] = useState(false);

  const [jobInfo, setJobInfo] = useState<JobInfoData | null>(null);

  useEffect(() => {
    fetch();
  }, [jobId]);

  const fetch = async () => {
    try {
      const condition: GetJobInfoReq = { jobId: jobId };
      const [jobInfoRes] = await Promise.all([
        apiClient.post<GetJobInfoRes>(`/api/v1/JobInfo/GetJobInfo`, condition),
      ]);
      setJobInfo((jobInfoRes.data.result as JobInfoData) ?? null);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(true);
    }
  };

  const updateDetail = (mainMakings: JobInfoDetail[]) => {
    if (jobInfo) {
      setJobInfo({ ...jobInfo, mainMakings: mainMakings });
    }
  };

  return { jobInfo, loading, fetch, updateDetail };
};

export default useFetchJobInfo;
