import * as React from 'react';

import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type LayoutHeadGridProps = {
  xs?: GridProps['xs'];
  sx?: GridProps['sx'];
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  outline: '1px solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // border: 1,
  // borderColor: theme.palette.makingSpecificationGray.main,
  // paddingTop: theme.spacing(1),
  //paddingBottom: theme.spacing(1),
  //paddingLeft: theme.spacing(1),
  //paddingRight: theme.spacing(1),
  outlineColor: theme.palette.makingSpecificationGray.main,
  backgroundColor: theme.palette.makingSpecificationGray.light,
}));

const LayoutHeadGrid: React.FC<LayoutHeadGridProps> = ({ children, ...props }) => {
  return (
    <>
      <StyledGrid item {...props}>
        {children}
      </StyledGrid>
    </>
  );
};

export default LayoutHeadGrid;
