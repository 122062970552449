import { useFormContext, useWatch } from 'react-hook-form';

import { OnlyTypeKey } from 'utils/typeUtils';

import {
  FlexoOrderEditForm,
  UnitPrice,
  UnitPriceWithRateFlgRow,
  UnitPriceWithRateFlgRowValues,
} from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** 工程あたり単価 行情報 Hooks */
export const useUnitPriceWithRateFlgRow = (
  key: OnlyTypeKey<UnitPrice, UnitPriceWithRateFlgRow>,
): UnitPriceWithRateFlgRowValues => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const row = useWatch({ control, name: `price.unitPrice.${key}` });
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcUnitPrice, calcGrossInvoice } = usePriceCalculater();

  /** 売価 */
  const price = calcUnitPrice(row.unitPrice, row.count);
  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate, row.toyoPriceRateFlg);

  return {
    unitPrice: row.unitPrice ?? 0,
    count: row.count ?? 0,
    price,
    toyoPriceRate,
    toyoPriceRateFlg: row.toyoPriceRateFlg,
    grossInvoice,
  };
};
