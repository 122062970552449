import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState } from 'react';

import { SearchCondition, ApprovalListResponse, ApprovalListModel } from '../types';

export const usePriceApprovalListFetch = (
  messageHandler: MessageApi['pushMessages'],
): {
  loading: boolean;
  searchLoading: boolean;
  displayData: ApprovalListResponse;
  setDisplayData: (list: ApprovalListResponse) => void;
  initialize: () => void;
  searchApprovalList: (searchCondition: SearchCondition) => void;
  fetchUpdateData: (jobId: number) => Promise<ApprovalListModel> | undefined;
  handleUpdateData: (updateData: ApprovalListModel) => void;
  dailyProcessDate: Date | null;
} => {
  const { apiClient } = useApiClient(messageHandler);

  const [displayData, setDisplayData] = useState<ApprovalListResponse>({
    priceApprovalList: [],
    listCount: 0,
    approvalPermissionFlg: false,
    loginUserFamilyName: '',
    loginUserEmployeeId: 0,
    dailyProcessDate: null,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  // 初期表示時検索
  const initialize = () => {
    setLoading(true);
    setSearchLoading(false);
    (async () => {
      try {
        // 価格承認一覧初期表示自取得API呼び出し
        const response = await apiClient.get<ApprovalListResponse>(
          `/api/v1/PriceApprovalList/GetPriceApprovalList`,
        );

        if (response.data.result) {
          setDisplayData({ ...response.data.result });
        }
      } catch (e) {
        console.log(e);
        return;
      } finally {
        setLoading(false);
      }
    })();
  };

  // 検索条件付き検索
  const searchApprovalList = (searchCondition: SearchCondition) => {
    setLoading(true);
    setSearchLoading(true);
    (async () => {
      try {
        // 価格承認一覧検索API呼び出し
        const response = await apiClient.get<ApprovalListResponse>(
          `/api/v1/PriceApprovalList/Search`,
          {
            params: { ...searchCondition, customerName: searchCondition.customerInfo.customerName },
          },
        );

        if (response.data.result) {
          setDisplayData({ ...response.data.result });
        }
      } catch (e) {
        console.log(e);
        return;
      } finally {
        setLoading(false);
        setSearchLoading(false);
      }
    })();
  };

  // 更新後のデータを取得する
  const fetchUpdateData = async (jobId: number): Promise<ApprovalListModel> => {
    try {
      // 価格承認一覧検索API呼び出し
      const response = await apiClient.get<ApprovalListModel>(
        `/api/v1/PriceApprovalList/GetApprovaledData`,
        {
          params: { jobId },
        },
      );

      if (response.data.result) {
        return response.data.result;
      }
    } catch (e) {
      console.log(e);
    }
    return {} as ApprovalListModel;
  };

  // 承認ステータス更新後リスト再生成
  const handleUpdateData = (updateData: ApprovalListModel): void => {
    // 既存のListデータをコピー
    const updatedList = [...displayData.priceApprovalList];

    // 更新されたデータを新しいListに組み込む
    const updateIndex = updatedList.findIndex((item) => item.jobId === updateData.jobId);
    if (updateIndex !== -1) {
      updatedList[updateIndex] = updateData;
    }

    // 新しいListを含むdisplayDataをセット
    const newDisplayData = {
      ...displayData,
      priceApprovalList: updatedList,
    };
    setDisplayData(newDisplayData);
  };

  return {
    loading,
    searchLoading,
    displayData,
    setDisplayData,
    initialize,
    searchApprovalList,
    fetchUpdateData,
    handleUpdateData,
    dailyProcessDate: displayData.dailyProcessDate,
  };
};

export default usePriceApprovalListFetch;
