import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import { Grid } from '@mui/material';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  NumericField,
} from 'components/parts/atoms';

import { OtherForm } from '../../types';

const MekkiAndRioseru: React.VFC = () => {
  // フォームデータ
  const unitPriceOtherFormContext = useFormContext<OtherForm>();

  return (
    <>
      <RowGrid columns={11}>
        <BodyGrid
          xs={5}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            メッキ液
          </BodyLabel>
        </BodyGrid>

        <Grid item xs={1}></Grid>
        <BodyGrid
          xs={5}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            リオセル
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>売価</HeadLabel>
        </HeadGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>売価</HeadLabel>
        </HeadGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>部分メッキ装置単体</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='platePartialPlatingEquipunit'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>10mmテープ</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocell10mmTapePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>部分メッキ装置一式</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='platePartialPlatingEquipset'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>15mmテープ</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocell15mmTapePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>脱脂液</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateDegreasingLiquidPrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>30mmテープ</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocell30mmTapePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>活性液</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateActivationLiquidPrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>オペーク</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocellOpaquePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>プリニッケル液</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateNickelLiquidPrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>オペーク希釈</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocellOpaquEdiluentPrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>硼弗化銅液</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateCrLiquidPrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>オペーク剥離</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocellOpaquEremovePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>炭素棒</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateTansoboPrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>接着剤</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocellAdhesivePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>炭素棒ホルダー</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateTansoboholder'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>接着剤剥離</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocellAdhesiveRemovePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>リード線(赤)</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateLeadRed'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={1}></Grid>
        <HeadGrid xs={3}>
          <HeadLabel>運賃</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='lyocellFarePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>リード線(黒)</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateLeadBlack'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>電気絶縁用テープ</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='platePlastictape'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>紙ヤスリ用工具</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateSandpaperAttachrubber'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>耐水ペーパー800#</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateSandpaper'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>運賃</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='plateFarePrice'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
    </>
  );
};

export default MekkiAndRioseru;
