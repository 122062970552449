import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

import { save, clear } from '.';
import { AppContext } from './types';

export const useAppContextStore = (): {
  state: AppContext;
  save: (state: AppContext) => void;
  clear: () => void;
} => {
  const state = useSelector((state: RootState) => state.appContext);

  const dispatch = useDispatch();
  const dispatchSave = useCallback(
    (state: AppContext) => {
      dispatch(save(state));
    },
    [dispatch],
  );
  const dispatchClear = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  return { state, save: dispatchSave, clear: dispatchClear };
};
