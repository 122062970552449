import * as React from 'react';

import TextField, { TextFieldProps } from './TextField';

type PasswordFieldrops = {
  maxLength: number;
  id?: TextFieldProps['id'];
  name?: TextFieldProps['name'];
  value?: TextFieldProps['value'];
  onChange?: TextFieldProps['onChange'];
  placeholder?: TextFieldProps['placeholder'];
  sx?: TextFieldProps['sx'];
};

const PasswordField: React.VFC<PasswordFieldrops> = (props) => {
  return (
    <TextField
      type={'password'}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      inputProps={{ maxLength: props.maxLength }}
      sx={props.sx}
    />
  );
};

export default PasswordField;
