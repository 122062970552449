import React from 'react';

import { BodyLabel, HeadLabel } from 'components/parts/atoms';

import { useOtherProductInfoTotal } from '../../../hooks/price/priceTotal/useOtherProductInfoTotal';
import { SalesPrice } from '../../../types';
import { FinalRow } from './Row';

/** 合計行コンポーネント */
const TotalRow: React.VFC = () => {
  /** その他受注製品情報 計*/
  const otherProductInfo: SalesPrice = useOtherProductInfoTotal();

  return (
    <>
      <FinalRow>
        <HeadLabel>合計</HeadLabel>
        <HeadLabel>―</HeadLabel>
        <HeadLabel>―</HeadLabel>
        <HeadLabel>―</HeadLabel>
        <HeadLabel>―</HeadLabel>
        <BodyLabel>{otherProductInfo.price.toFixed()}</BodyLabel>
        <BodyLabel>{otherProductInfo.grossInvoice.toFixed()}</BodyLabel>
        <></>
      </FinalRow>
    </>
  );
};

export default React.memo(TotalRow);
