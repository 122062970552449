import * as React from 'react';

import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps,
} from '@mui/lab';

export type LoadingButtonProps = MuiLoadingButtonProps;

const LoadingButton: React.FC<LoadingButtonProps> = ({ children, ...props }) => {
  return <MuiLoadingButton {...props}>{children}</MuiLoadingButton>;
};

export default LoadingButton;
