import React from 'react';

import { usePriceCalculateMatter } from '../../../hooks/price/usePriceCalculateMatter';
import MakingPriceRow, { MakingPriceRowProps } from '../Common/MakingPriceRow';

type Props = Omit<MakingPriceRowProps, 'pricePropertyName' | 'makingsPropertyName' | 'surfaceArea'>;

/** 印刷面(リバース)計算 行コンポーネント */
const ReverseMakingPriceRow: React.VFC<Props> = (props) => {
  const { reverseSurfaceArea } = usePriceCalculateMatter();
  return (
    <MakingPriceRow
      label={props.label}
      pricePropertyName='makingPriceReverse'
      makingsPropertyName='flexoMakingsReverse'
      rowPropertyName={props.rowPropertyName}
      surfaceArea={reverseSurfaceArea}
    />
  );
};

export default React.memo(ReverseMakingPriceRow);
