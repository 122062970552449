import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import PostSearchResultTable from 'pages/common/PostSearchResult';
import { CustomerEditContext } from 'pages/customer/CustomerEdit';
import { DeliveryPlaceEdit } from 'pages/customer/CustomerEdit/types';

import {
  Select,
  HeadLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  BackButton,
  ConfirmButton,
  SearchButton,
  KanaField,
  AlphaNumericField,
} from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules';

import { EditProps, PostSearch } from '../types';

// 納入先入力
const DeliveryAddressEdit: React.VFC<EditProps> = (props) => {
  // フォームデータ
  const master = useContext(CustomerEditContext).selectBoxData;
  const deliveryPlaceFormContext = useFormContext<DeliveryPlaceEdit>();

  // 郵便番号検索
  const onSearch = () => {
    setOpenPostSearch(true);
  };
  // 郵便番号ダイアログ
  const [openPostSearch, setOpenPostSearch] = useState(false);
  const [postSearch, setPostSearch] = useState<PostSearch | null>(null);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <PostSearchResultTable
          open={openPostSearch}
          onClose={(value) => {
            if (value) {
              setPostSearch(value);
            }
            setOpenPostSearch(false);
          }}
          zipcd={deliveryPlaceFormContext.getValues('zipCd')}
        />
      </>
    );
  };
  useEffect(() => {
    if (postSearch !== undefined && postSearch !== null) {
      // セレクトボックスに入れる為IDを取得
      const prefecture = master.prefectures.find(
        (x) => x.text == postSearch.prefecturesName,
      )?.value;
      deliveryPlaceFormContext.setValue('zipCd', postSearch.zipCd ?? '');
      deliveryPlaceFormContext.setValue('prefecturesName', postSearch.prefecturesName);
      deliveryPlaceFormContext.setValue('address1', postSearch.citykanjiName);
      deliveryPlaceFormContext.setValue('address2', postSearch.chouikikanjiName);
      deliveryPlaceFormContext.setValue(
        'prefucturesId',
        prefecture !== undefined ? prefecture : '',
      );
    }
  }, [postSearch]);

  return (
    <>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>納入先名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='deliveryPlaceName'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={20}
                sx={{ minWidth: '35rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>郵便番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='zipCd'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <>
                <AlphaNumericField onChange={field.onChange} value={field.value} maxLength={7} />
                <Box m={1} />
                <SearchButton onClick={onSearch}></SearchButton>
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>運送会社</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='transporterId'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <Select
                options={master.transporters}
                onChange={field.onChange}
                value={field.value}
                sx={{ minWidth: '12rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>都道府県</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='prefucturesId'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <Select
                options={master.prefectures}
                onChange={field.onChange}
                value={field.value}
                sx={{ minWidth: '8rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>市区町村</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='address1'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={40}
                sx={{ minWidth: '35rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>丁目・番地</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='address2'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={40}
                sx={{ minWidth: '35rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>ビル名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='buildingName'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={40}
                sx={{ minWidth: '35rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={10}>
        <HeadGrid xs={1}>
          <HeadLabel>電話番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={9}>
          <Controller
            name='tel'
            control={deliveryPlaceFormContext.control}
            render={({ field }) => (
              <AlphaNumericField onChange={field.onChange} value={field.value} maxLength={12} />
            )}
          />
        </BodyGrid>
      </RowGrid>

      <FooterButtonsContainer>
        <BackButton onClick={props.onBack} />
        <ConfirmButton onClick={props.onGoCheck} />
      </FooterButtonsContainer>

      {renderDialogs()}
    </>
  );
};

export default DeliveryAddressEdit;
