import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const types = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export type Types = typeof types[keyof typeof types];

export interface Message {
  id: string;
  type: Types;
  text: string;
}

export interface MessageContainerProps {
  messages: Message[];
  onRemove: (id: string) => void;
}

export interface MessageProps {
  message: Message;
  onRemove: MessageContainerProps['onRemove'];
  sx: SxProps<Theme>;
}
