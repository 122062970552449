import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialManuscriptArrivalEditState } from 'store/manuscript/manuscriptArrivalEdit/defaults';
import { ManuscriptArrivalEditState } from 'store/manuscript/manuscriptArrivalEdit/types';

const slice = createSlice({
  name: 'manuscriptArrivalEdit',
  initialState: InitialManuscriptArrivalEditState,
  reducers: {
    save: (state, action: PayloadAction<ManuscriptArrivalEditState>) => action.payload,
    clear: () => ({ ...InitialManuscriptArrivalEditState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
