import * as React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Heading, HeadLabel, BodyLabel } from 'components/parts/atoms';
import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms/Grid';

import { ImageStatusModel } from '../types';

type ImageProgress = {
  imageStatusModel?: ImageStatusModel;
};

const Header = styled(HeadGrid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.makingSpecificationGray?.main,
  color: theme.palette.white.main,
}));

const ImageProgress: React.VFC<ImageProgress> = (props) => {
  return (
    <>
      <Box m={2} />
      <Heading>画像進捗詳細</Heading>
      <Box m={2} />
      <RowGrid columns={8}>
        <Header xs={1}>
          <HeadLabel>デザ</HeadLabel>
        </Header>
        <Header xs={1}>
          <HeadLabel>分解</HeadLabel>
        </Header>
        <Header xs={1}>
          <HeadLabel>色分</HeadLabel>
        </Header>
        <Header xs={1}>
          <HeadLabel>提出物</HeadLabel>
        </Header>
        <Header xs={1}>
          <HeadLabel>検版</HeadLabel>
        </Header>
        <Header xs={1}>
          <HeadLabel>提出</HeadLabel>
        </Header>
        <Header xs={1}>
          <HeadLabel>校了</HeadLabel>
        </Header>
        <Header xs={1}>
          <HeadLabel>下版</HeadLabel>
        </Header>
      </RowGrid>
      <RowGrid columns={8} sx={{ height: '3em' }}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: props.imageStatusModel?.designstatus,
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.imageStatusModel?.designemployee}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: props.imageStatusModel?.photoretouchstatus,
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.imageStatusModel?.photoretouchemployee}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: props.imageStatusModel?.colorseparationstatus,
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.imageStatusModel?.colorseparationemployee}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: props.imageStatusModel?.presentallstatus,
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.imageStatusModel?.presentallemployee}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: props.imageStatusModel?.inspectionstatus,
            justifyContent: 'center',
          }}
        >
          <BodyLabel>{props.imageStatusModel?.inspectionemployee}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{ backgroundColor: props.imageStatusModel?.presentstatus, justifyContent: 'center' }}
        >
          <BodyLabel>{props.imageStatusModel?.presentemployee}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{ backgroundColor: props.imageStatusModel?.approvedstatus, justifyContent: 'center' }}
        >
          <BodyLabel>{props.imageStatusModel?.approvedemployee}</BodyLabel>
        </BodyGrid>
        <BodyGrid
          xs={1}
          sx={{ backgroundColor: props.imageStatusModel?.layoutstatus, justifyContent: 'center' }}
        >
          <BodyLabel>{props.imageStatusModel?.layoutemployee}</BodyLabel>
        </BodyGrid>
      </RowGrid>
    </>
  );
};
export default ImageProgress;
