import { ProductionCategoryCode } from 'constants/define';
import useShowMakingSpecification from 'hooks/useShowMakingSpecification';
import React, { useState } from 'react';
import theme from 'themes/theme';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BodyLabel, Heading, HeadLabel } from 'components/parts/atoms';
import { RowGrid, BodyGrid } from 'components/parts/atoms/Grid';
import { ContentsGrid } from 'components/parts/molecules/ContentsGrid';
import { LinkHeading } from 'components/parts/molecules/LinkHeading';

import useFetchImageWorkReportHeader from './hooks';

type Props = {
  jobId: number;
  isHistory: boolean;
  productionCategoryCode: ProductionCategoryCode;
};

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const StyledImg = styled('img')(({ theme }) => ({
  margin: theme.spacing(2),
}));

const ImageWorkReportHeader: React.VFC<Props> = (props) => {
  // spacing
  const [spacing] = useState<number>(1);

  // imageWorkReportHeaderHooks hooks
  const { headerInfo } = useFetchImageWorkReportHeader(props.jobId, props.isHistory);

  // 製販指示書 hooks
  const { showMakingSpecification, showMakingSpecificationHistory } = useShowMakingSpecification();

  //JOBNOリンク_製版指示書の表示
  const onShowMakingSpecification = () => {
    if (headerInfo) {
      if (props.isHistory) {
        showMakingSpecificationHistory(props.productionCategoryCode, props.jobId);
      } else {
        showMakingSpecification(props.productionCategoryCode, props.jobId);
      }
    }
  };

  const renderBasicInfo = (): JSX.Element => {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          <BodyGrid xs={12}>
            <LinkHeading
              component='a'
              onLinkClick={onShowMakingSpecification}
              backgroundColor='cyclonistDarkOrange.main'
              textColor='cyclonistDarkOrange.contrastText'
              sx={{
                cursor: 'pointer'
              }}
            >
              {headerInfo?.imageWorkReportHeader?.jobNo}
            </LinkHeading>
          </BodyGrid>
        </Box>
        <Box my={1} sx={{ width: '100%' }}>
          <Heading
            backgroundColor={'cyclonistLightOrange.main'}
            textColor={'cyclonistLightOrange.contrastText'}
          >
            JOB情報
          </Heading>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3} textAlign={'center'}>
            <StyledImg src={`/images/CyclonistImg/folder_L.png`} />
          </Grid>
          <Grid item xs={9}>
            <RowGrid columns={5}>
              <ContentsGrid hxs={1} bxs={4}>
                <HeadLabel
                  sx={{
                    px: theme.spacing(spacing),
                    py: theme.spacing(spacing),
                    textAlign: 'right',
                  }}
                >
                  得意先発注No
                </HeadLabel>
                <BodyLabel sx={{ px: theme.spacing(spacing), py: theme.spacing(spacing) }}>
                  {headerInfo?.imageWorkReportHeader?.customerOrderNo}
                </BodyLabel>
              </ContentsGrid>
              <ContentsGrid hxs={1} bxs={4}>
                <HeadLabel
                  sx={{
                    px: theme.spacing(spacing),
                    py: theme.spacing(spacing),
                    textAlign: 'right',
                  }}
                >
                  品名
                </HeadLabel>
                <BodyLabel sx={{ px: theme.spacing(spacing), py: theme.spacing(spacing) }}>
                  {headerInfo?.imageWorkReportHeader?.productionName}
                  {headerInfo?.imageWorkReportHeader?.productionName !== null &&
                    headerInfo?.imageWorkReportHeader?.jobName !== null && <>&emsp;</>}
                  {headerInfo?.imageWorkReportHeader?.jobName}
                </BodyLabel>
              </ContentsGrid>
              <ContentsGrid hxs={1} bxs={4}>
                <HeadLabel
                  sx={{
                    px: theme.spacing(spacing),
                    py: theme.spacing(spacing),
                    textAlign: 'right',
                  }}
                >
                  得意先名
                </HeadLabel>
                <BodyLabel sx={{ px: theme.spacing(spacing), py: theme.spacing(spacing) }}>
                  {headerInfo?.imageWorkReportHeader?.customerName}
                </BodyLabel>
              </ContentsGrid>
              <ContentsGrid hxs={1} bxs={4}>
                <HeadLabel
                  sx={{
                    px: theme.spacing(spacing),
                    py: theme.spacing(spacing),
                    textAlign: 'right',
                  }}
                >
                  画像指示事項
                </HeadLabel>
                <BodyLabel sx={{ px: theme.spacing(spacing), py: theme.spacing(spacing) }}>
                  {headerInfo?.imageWorkReportHeader?.imageDirectiononState}
                </BodyLabel>
              </ContentsGrid>
              <ContentsGrid hxs={1} bxs={4}>
                <HeadLabel
                  sx={{
                    px: theme.spacing(spacing),
                    py: theme.spacing(spacing),
                    textAlign: 'right',
                  }}
                >
                  担当営業
                </HeadLabel>
                <BodyLabel sx={{ px: theme.spacing(spacing), py: theme.spacing(spacing) }}>
                  {headerInfo?.imageWorkReportHeader?.fullname}
                </BodyLabel>
              </ContentsGrid>
            </RowGrid>
          </Grid>
        </Grid>
      </>
    );
  };

  return <Wrapper>{renderBasicInfo()}</Wrapper>;
};
export default ImageWorkReportHeader;
