import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState, useEffect } from 'react';

import { SearchReq, SearchRes } from 'api/types/portalTop/jobNoSearchResult';

import { Job } from '../types';

/** JOB No.検索結果のHooks */
export const useFetchJobNoSearchResult = (
  jobNo: string,
  messageHandler: MessageApi['pushMessages'],
): {
  loading: boolean;
  searchSucceed: boolean;
  cylinderJobs: Job[];
  flexoJobs: Job[];
  otherJobs: Job[];
} => {
  const { apiClient, loading } = useApiClient(messageHandler);

  // 検索結果
  const [searchSucceed, setSearchSucceed] = useState<boolean>(false);
  const [cylinderJobs, setCylinderJobs] = useState<Job[]>([]);
  const [flexoJobs, setFlexoJobs] = useState<Job[]>([]);
  const [otherJobs, setOtherJobs] = useState<Job[]>([]);

  /** 初期表示 */
  useEffect(() => {
    (async () => {
      try {
        const params: SearchReq = { jobNo: jobNo };
        const [jobNoSearchRes] = await Promise.all([
          apiClient.get<SearchRes>(`/api/v1/JobNoSearchResult/Search`, {
            params: params,
          }),
        ]);
        if (jobNoSearchRes.data.result) {
          const result = jobNoSearchRes.data.result;
          setSearchSucceed(result.searchSucceed);
          setCylinderJobs(result.cylinderJobs);
          setFlexoJobs(result.flexoJobs);
          setOtherJobs(result.otherJobs);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [jobNo]);

  return {
    loading,
    searchSucceed,
    cylinderJobs,
    flexoJobs,
    otherJobs,
  };
};

export default useFetchJobNoSearchResult;
