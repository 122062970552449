import { PrintTarget } from './types';

export const defaultPrintTarget: PrintTarget = {
  deliveryJobIdList:[],
  receiptJobIdList:[],
  deliveryInkJobIdList:[],
  deliveryDetailInkJobIdList:[],
  isDeliveryPrint:false,
  isReceiptPrint:false,
  isDeliveryInkPrint:false,
  isDeliveryDetailInkPrint:false,
};
