import React from 'react';

import { Box } from '@mui/material';

import FlexoBasicInfos from './components/flexoBasicInfos';
import FlexoUnitPriceInfos from './components/flexoUnitPriceInfos';
import { BackButton, ConfirmButton } from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules/FooterButtonsContainer';

import { FlexoEditCheckProps } from '../types';

const UnitPriceEditCheckFlexo: React.VFC<FlexoEditCheckProps> = (props) => {
  return (
    <>
      <FlexoBasicInfos />

      <Box m={2} />

      <FlexoUnitPriceInfos />

      <FooterButtonsContainer>
        <BackButton onClick={props.onBack} />
        {props.checkFinishFlag === false && <ConfirmButton onClick={props.onAddForm} />}
      </FooterButtonsContainer>
    </>
  );
};

export default UnitPriceEditCheckFlexo;
