import React from 'react';

import MakingSize from './MakingSize';

/** 製版サイズ 印刷面(リバース) */
const MakingSizeReverse: React.VFC = () => {
  return <MakingSize propertyName='makingSizeReverse' />;
};

export default React.memo(MakingSizeReverse);
