import useFileDownload from 'hooks/useFileDownload';
import useSharedMessage from 'hooks/useSharedMessage';
import React, { useState, useCallback } from 'react';
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import DownloadIcon from '@mui/icons-material/Download';
import { Box, Container, useTheme } from '@mui/material';
import { useDemoData } from '@mui/x-data-grid-generator';
import { GridCellParams, useGridApiRef, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  Typography,
  AttentionLabel,
  KanaField,
  NumericField,
  ClearButton,
  SearchButton,
  PrintButton,
  CustomButton,
  Select,
  Button,
  BackButton,
  ConfirmButton,
  SaveButton,
  Heading,
  Checkbox,
  DatePicker,
  DateRangePicker,
  RowGrid,
  HeadGrid,
  BodyGrid,
  HeadLabel,
  BodyLabel,
  DataGrid,
} from 'components/parts/atoms';
import { SelectOption } from 'components/parts/atoms/Select/types';
import { ContentsGrid, TableCount, FooterButtonsContainer } from 'components/parts/molecules';

import { dateText } from 'utils/date';

/** 編集・確認のステータスコード */
const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
type PageState = typeof PageState[keyof typeof PageState];

type ReactHookFormSampleType = {
  onBack: () => void;
  onConfirm: () => void;
};

type ReactHookFormCheckSampleType = {
  onCheckBack: () => void;
  onSubmit: () => void;
};

interface ItemList {
  ItemName: string;
  ItemCount: number;
}

interface ValuesType {
  userName: string;
  age: number;
  companyName: string;
  selectFace: string | null;
  checkWork: boolean;
  myItem: { rows: ItemList[] };
  date: Date;
  rDate: [Date, Date];
  numberValue: number | null;
  digitsValue: number | null;
  outList: ItemList[];
}

const iSelectList: SelectOption[] = [
  { value: '0 ', text: 'トヨタ', decisionCode: '' },
  { value: '1', text: '日産', decisionCode: '' },
  { value: '2', text: '本田', decisionCode: '' },
  { value: '3', text: 'マツダ', decisionCode: '' },
];

const iListValues: ItemList[] = [
  { ItemName: 'シリンダー', ItemCount: 3 },
  { ItemName: 'フレキソ', ItemCount: 10 },
];

const listOuttValue: ItemList[] = [
  { ItemName: '〇〇', ItemCount: 1 },
  { ItemName: '✕✕', ItemCount: 2 },
  { ItemName: '△△', ItemCount: 3 },
];

const iValues: ValuesType = {
  userName: 'Tamura',
  age: 16,
  companyName: 'ネクステージ',
  selectFace: '',
  checkWork: false,
  myItem: {
    rows: iListValues,
  },
  date: new Date(),
  rDate: [new Date(), new Date()],
  numberValue: 0,
  digitsValue: null,
  outList: listOuttValue,
};

const ReactHookFormSubSample: React.VFC = () => {
  const formContext = useFormContext<ValuesType>();
  const [companyName] = useWatch({ control: formContext.control, name: ['companyName'] });
  const fullCompanyName = '株式会社　' + companyName;

  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={2} bxs={10}>
          <HeadLabel>会社名(正式名)</HeadLabel>
          <BodyLabel>{fullCompanyName}</BodyLabel>
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

const ReactHookFormSample: React.VFC<ReactHookFormSampleType> = (props) => {
  const formSet = useFormContext<ValuesType>();

  const hobbyList = useFieldArray({
    control: formSet.control,
    name: 'myItem.rows',
  });

  return (
    <>
      <Box m={2} />
      <Heading>入力サンプル(幅：md)</Heading>
      <Box m={2} />
      <RowGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <Box sx={{ display: 'frex' }}>
            <AttentionLabel>【必須】 </AttentionLabel>
            <HeadLabel>ユーザ名</HeadLabel>
          </Box>

          <Controller
            name='userName'
            control={formSet.control}
            defaultValue={iValues.userName}
            render={({ field }) => (
              <KanaField {...field} maxLength={10} sx={{ minWidth: '15rem' }} />
            )}
          />
        </ContentsGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <HeadLabel>年齢</HeadLabel>
          <Controller
            name='age'
            control={formSet.control}
            defaultValue={iValues.age}
            render={({ field: { onChange, value, name } }) => (
              <NumericField
                onChange={onChange}
                value={value}
                name={name}
                decimalDigits={0}
                min={0}
                max={10}
                sx={{ minWidth: '15rem' }}
              />
            )}
          />
        </ContentsGrid>
      </RowGrid>

      <RowGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <HeadLabel>メーカー</HeadLabel>
          <Controller
            name='selectFace'
            control={formSet.control}
            defaultValue={iValues.selectFace}
            render={({ field: { onChange, value, name } }) => (
              <Select
                options={iSelectList}
                addEmptyRow={true}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ minWidth: '8rem' }}
              />
            )}
          />
        </ContentsGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <HeadLabel>出勤</HeadLabel>
          <Controller
            name='checkWork'
            control={formSet.control}
            defaultValue={iValues.checkWork}
            render={({ field: { onChange, value, name } }) => (
              <Checkbox onChange={onChange} checked={value} name={name} />
            )}
          />
        </ContentsGrid>
      </RowGrid>

      <RowGrid>
        <ContentsGrid hxs={2} bxs={10}>
          <HeadLabel>会社名</HeadLabel>
          <Controller
            name='companyName'
            control={formSet.control}
            defaultValue={iValues.companyName}
            render={({ field: { onChange, value, name } }) => (
              <KanaField onChange={onChange} value={value} name={name} maxLength={20} />
            )}
          />
        </ContentsGrid>
      </RowGrid>

      <ReactHookFormSubSample />

      <Box m={2} />
      <Heading>リストサンプル</Heading>
      <Box m={2} />

      <RowGrid>
        <HeadGrid xs={8} sx={{ justifyContent: 'center' }}>
          <HeadLabel>商品</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>個数</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <Button onClick={() => hobbyList.append({ ItemName: '', ItemCount: 0 })}>追加</Button>
        </HeadGrid>
      </RowGrid>
      {hobbyList.fields.map((item, index) => {
        return (
          <>
            <div key={item.id}>
              <RowGrid>
                <BodyGrid xs={8}>
                  <Controller
                    name={`myItem.rows.${index}.ItemName`}
                    control={formSet.control}
                    defaultValue={item.ItemName}
                    render={({ field: { onChange, value, name } }) => (
                      <KanaField onChange={onChange} value={value} name={name} maxLength={20} />
                    )}
                  />
                </BodyGrid>
                <BodyGrid xs={2}>
                  <Controller
                    name={`myItem.rows.${index}.ItemCount`}
                    control={formSet.control}
                    defaultValue={item.ItemCount}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericField
                        onChange={onChange}
                        value={value}
                        name={name}
                        min={0}
                        max={99}
                        decimalDigits={0}
                      />
                    )}
                  />
                </BodyGrid>
                <BodyGrid xs={2} sx={{ justifyContent: 'center' }}>
                  <Button onClick={() => hobbyList.remove(index)}>削除</Button>
                </BodyGrid>
              </RowGrid>
            </div>
          </>
        );
      })}
      <Box sx={{ width: '100%', display: 'frex' }}>
        <Box paddingRight={2} sx={{ width: '50%' }}>
          <>
            <Box m={2} />
            <Heading>時間サンプル</Heading>
            <Box m={2} />
          </>

          <RowGrid>
            <ContentsGrid hxs={3} bxs={9}>
              <Typography>日付け</Typography>
              <Controller
                name='date'
                control={formSet.control}
                defaultValue={iValues.date}
                render={({ field }) => (
                  <DatePicker value={field.value} onChange={field.onChange}></DatePicker>
                )}
              ></Controller>
            </ContentsGrid>
          </RowGrid>
          <RowGrid>
            <ContentsGrid hxs={3} bxs={9}>
              <HeadLabel>レンジ日付け</HeadLabel>
              <Controller
                name='rDate'
                control={formSet.control}
                defaultValue={iValues.rDate}
                render={({ field }) => (
                  <DateRangePicker value={field.value} onChange={field.onChange}></DateRangePicker>
                )}
              ></Controller>
            </ContentsGrid>
          </RowGrid>
        </Box>
        <Box paddingLeft={2} sx={{ width: '50%' }}>
          <>
            <Box m={2} />
            <Heading>数字コンポーネント</Heading>
            <Box m={2} />
            <RowGrid>
              <ContentsGrid hxs={4} bxs={8}>
                <Typography>-100 ~ 100</Typography>
                <Controller
                  name='numberValue'
                  control={formSet.control}
                  defaultValue={iValues.numberValue}
                  render={({ field }) => (
                    <NumericField
                      value={field.value}
                      onChange={field.onChange}
                      name={field.name}
                      min={-1000}
                      max={1000}
                      decimalDigits={0}
                    />
                  )}
                ></Controller>
              </ContentsGrid>
            </RowGrid>
            <RowGrid>
              <ContentsGrid hxs={4} bxs={8}>
                <HeadLabel>0 ~ 1000(少数3桁)</HeadLabel>
                <Controller
                  name='digitsValue'
                  control={formSet.control}
                  defaultValue={iValues.digitsValue}
                  render={({ field }) => (
                    <NumericField
                      value={field.value}
                      onChange={field.onChange}
                      name={field.name}
                      min={0}
                      max={9999.999}
                      decimalDigits={3}
                    />
                  )}
                ></Controller>
              </ContentsGrid>
            </RowGrid>
          </>
        </Box>
      </Box>

      <FooterButtonsContainer>
        <BackButton onClick={() => props.onBack()} />
        <ConfirmButton onClick={() => props.onConfirm()} />
      </FooterButtonsContainer>
    </>
  );
};

const ReactHookFormCheckSample: React.VFC<ReactHookFormCheckSampleType> = (props) => {
  const formSet = useFormContext<ValuesType>();

  const hobbyList = useFieldArray({
    control: formSet.control,
    name: 'myItem.rows',
  });

  const getSelect = (value: string | null, selectList: SelectOption[]): string => {
    const selectItem = selectList.find((x) => x.value === value);
    if (selectItem) {
      return selectItem.text;
    } else {
      return '';
    }
  };
  return (
    <>
      <Box m={2} />
      <Heading>入力サンプル(幅：md)</Heading>
      <Box m={2} />
      <RowGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <Box sx={{ display: 'frex' }}>
            <AttentionLabel>【必須】 </AttentionLabel>
            <HeadLabel>ユーザ名</HeadLabel>
          </Box>
          <BodyLabel>{formSet.getValues('userName')}</BodyLabel>
        </ContentsGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <HeadLabel>年齢</HeadLabel>
          <BodyLabel>{formSet.getValues('age')}</BodyLabel>
        </ContentsGrid>
      </RowGrid>

      <RowGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <HeadLabel>顔文字</HeadLabel>
          <BodyLabel>{getSelect(formSet.getValues('selectFace'), iSelectList)}</BodyLabel>
        </ContentsGrid>
        <ContentsGrid hxs={2} bxs={4}>
          <HeadLabel>出勤</HeadLabel>
          <BodyLabel>{formSet.getValues('checkWork') ? '●' : ''}</BodyLabel>
        </ContentsGrid>
      </RowGrid>

      <RowGrid>
        <ContentsGrid hxs={2} bxs={10}>
          <HeadLabel>会社名</HeadLabel>
          <BodyLabel>{formSet.getValues('companyName')}</BodyLabel>
        </ContentsGrid>
      </RowGrid>

      <RowGrid>
        <ContentsGrid hxs={2} bxs={10}>
          <HeadLabel>会社名(正式名)</HeadLabel>
          <BodyLabel>{'株式会社' + formSet.getValues('companyName')}</BodyLabel>
        </ContentsGrid>
      </RowGrid>

      <Box m={2} />
      <Heading>リストサンプル</Heading>
      <Box m={2} />

      <RowGrid>
        <HeadGrid xs={8} sx={{ justifyContent: 'center' }}>
          <HeadLabel>ガンプラ</HeadLabel>
        </HeadGrid>
        <HeadGrid xs={4} sx={{ justifyContent: 'center' }}>
          <HeadLabel>個数</HeadLabel>
        </HeadGrid>
        {hobbyList.fields.map((item, index) => {
          return (
            <>
              <div key={item.id}>
                <RowGrid>
                  <BodyGrid xs={8}>
                    <BodyLabel>{formSet.getValues(`myItem.rows.${index}.ItemName`)}</BodyLabel>
                  </BodyGrid>
                  <BodyGrid xs={4}>
                    <BodyLabel>{formSet.getValues(`myItem.rows.${index}.ItemCount`)}</BodyLabel>
                  </BodyGrid>
                </RowGrid>
              </div>
            </>
          );
        })}
      </RowGrid>
      <Box sx={{ width: '100%', display: 'frex' }}>
        <Box sx={{ width: '50%' }}>
          <>
            <Box m={2} />
            <Heading>時間サンプル</Heading>
            <Box m={2} />
          </>

          <RowGrid>
            <ContentsGrid hxs={3} bxs={9}>
              <Typography>日付け</Typography>
              <BodyLabel>{dateText(formSet.getValues('date'))}</BodyLabel>
            </ContentsGrid>
          </RowGrid>
          <RowGrid>
            <ContentsGrid hxs={3} bxs={9}>
              <HeadLabel>レンジ日付け</HeadLabel>
              <>
                <BodyLabel>{dateText(formSet.getValues('rDate')[0])}</BodyLabel>
                {'　～　'}
                <BodyLabel>{dateText(formSet.getValues('rDate')[1])}</BodyLabel>
              </>
            </ContentsGrid>
          </RowGrid>
        </Box>
        <Box sx={{ width: '50%' }}>
          <>
            <Box m={2} />
            <Heading>数字コンポーネント</Heading>
            <Box m={2} />
            <RowGrid>
              <ContentsGrid hxs={4} bxs={8}>
                <Typography>-100 ~ 100</Typography>
                <BodyLabel>{formSet.getValues('numberValue')}</BodyLabel>
              </ContentsGrid>
            </RowGrid>
            <RowGrid>
              <ContentsGrid hxs={4} bxs={8}>
                <HeadLabel>0 ~ 1000(少数3桁)</HeadLabel>
                <BodyLabel>{formSet.getValues('digitsValue')}</BodyLabel>
              </ContentsGrid>
            </RowGrid>
          </>
        </Box>
      </Box>
      <Box>
        <FooterButtonsContainer>
          <BackButton onClick={() => props.onCheckBack()} />
          <SaveButton onClick={() => props.onSubmit()} />
        </FooterButtonsContainer>
      </Box>
    </>
  );
};

const TableSampleLg: React.VFC = () => {
  const [size, setPageSize] = useState<number | null>(10);
  const { data, loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 300,
    maxColumns: 6,
  });
  const columns: GridColDef[] = [
    { field: 'desk', flex: 1 },
    { field: 'commodity', flex: 3 },
    { field: 'quantity', flex: 2 },
    { field: 'traderEmail', flex: 3 },
    { field: 'traderName', flex: 3 },
  ];

  const { pushMessages } = useSharedMessage();
  const { downloadCsv } = useFileDownload(pushMessages);

  const fileDownloadHandler = () => {
    downloadCsv('/api/v1/DevPortal/ExportCSV', 'GET', { params: { value: 'SampleValue' } });
  };

  return (
    <>
      <TableCount count={data.rows.length} />
      <Box m={2} />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HeadLabel>desk</HeadLabel>
          <Box m={1} />
          <KanaField maxLength={5} sx={{ width: '5rem' }} />
          <Box m={1} />
          <HeadLabel>commodity</HeadLabel>
          <Box m={1} />
          <KanaField maxLength={15} />
          <Box m={1} />
          <HeadLabel>行数</HeadLabel>
          <Box m={1} />
          <NumericField
            decimalDigits={0}
            min={0}
            max={99}
            onChange={setPageSize}
            sx={{ width: '5rem' }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ClearButton />
          <Box m={1} />
          <SearchButton />
        </Box>
      </Box>
      <Box m={2} />
      <DataGrid
        rows={data.rows}
        columns={columns}
        pagination={true}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableColumnMenu
        loading={loading}
      />
      <FooterButtonsContainer>
        <>
          <BackButton />
          <CustomButton onClick={fileDownloadHandler} icon={<DownloadIcon />}>
            CSVダウンロード
          </CustomButton>
          <PrintButton />
        </>
      </FooterButtonsContainer>
    </>
  );
};

const DataGridProSample: React.VFC = () => {
  const theme = useTheme();
  const api = useGridApiRef();
  const columns: GridColDef[] = [
    {
      field: 'index',
      headerName: 'Index',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const index = api.current.getRowIndex(params.id);
        console.log(index);
        return index + 1;
      },
    },
    { field: 'name', headerName: '名前', width: 100, sortable: true },
    { field: 'age', headerName: '年齢', type: 'number', width: 100, sortable: true },
    { field: 'address', headerName: '住所', width: 100, sortable: false },
  ];

  /** データグリッドプロのサンプルで使用するデータ */
  const rows: GridRowsProp = [
    {
      id: 'a1',
      name: '佐藤',
      age: 25,
      address: '北海道',
    },
    {
      id: 'a2',
      name: '鈴木',
      age: 36,
      address: '宮城県',
    },
    {
      id: 'a3',
      name: '木村',
      age: 19,
      address: '福島県',
    },
    {
      id: 'a4',
      name: '松本',
      age: 28,
      address: '東京都',
    },
    {
      id: 'a5',
      name: '佐々木',
      age: 23,
      address: '新潟県',
    },
    {
      id: 'a6',
      name: '山口',
      age: 25,
      address: '新潟県',
    },
    {
      id: 'a7',
      name: '佐々木',
      age: 36,
      address: '富山県',
    },
    {
      id: 'a8',
      name: '鈴木',
      age: 19,
      address: '愛知県',
    },
    {
      id: 'a9',
      name: '小林',
      age: 28,
      address: '大阪府',
    },
    {
      id: 'a10',
      name: '加藤',
      age: 23,
      address: '滋賀県',
    },
  ];

  return (
    <>
      <Box m={2} />
      <Heading>Data Grid Pro Sample</Heading>
      <Box m={2} />
      <Box
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        }}
      >
        <DataGrid
          autoHeight={true}
          pagination={true}
          pageSize={5}
          rows={rows}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          apiRef={api}
          stripe={true}
          stripeColor={theme.palette.cyclonistBlue.main}
        />
      </Box>
    </>
  );
};

const FormSample: React.VFC = () => {
  const [pageState, setPageState] = useState<PageState>(PageState.Edit);
  const formSet = useForm<ValuesType>({ defaultValues: iValues });

  //const { control, handleSubmit, reset, watch } = useForm<ValuesType>({ defaultValues: iValues });

  // 戻る処理
  const onBack = useCallback(() => {
    console.log('clicked!');
  }, []);

  // 確認に戻る処理
  const onCheckBack = useCallback(() => {
    setPageState(PageState.Edit);
  }, []);

  // チェック処理
  const onConfirm = useCallback(
    formSet.handleSubmit((data: ValuesType) => {
      console.log(data);
      setPageState(PageState.Check);
    }),
    [],
  );

  // 登録処理
  const onSubmit = useCallback(
    formSet.handleSubmit((data: ValuesType) => {
      alert(JSON.stringify(data));
    }),
    [],
  );

  return (
    <>
      <FormProvider {...formSet}>
        <form>
          <Container maxWidth='md'>
            {(() => {
              switch (pageState) {
                case PageState.Edit:
                  return <ReactHookFormSample onBack={onBack} onConfirm={onConfirm} />;
                case PageState.Check:
                  return <ReactHookFormCheckSample onCheckBack={onCheckBack} onSubmit={onSubmit} />;
              }
            })()}
          </Container>
        </form>
      </FormProvider>
      <Container maxWidth='lg' disableGutters>
        <Box m={2} />
        <Heading>テーブルサンプル1(幅：lg)</Heading>
        <Box m={2} />
        <TableSampleLg />
      </Container>
      <Container maxWidth='xl' disableGutters>
        <Box m={2} />
        <Heading>テーブルサンプル2(幅：xl)</Heading>
        <Box m={2} />
        <DataGridProSample />
      </Container>
    </>
  );
};

export default FormSample;
