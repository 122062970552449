import * as React from 'react';

import { ArrowBack } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type BackButtonProps = CustomButtonProps;

const BackButton: React.VFC<BackButtonProps> = (props) => {
  return (
    <CustomButton icon={<ArrowBack />} color={'minor'} {...props}>
      {'戻る'}
    </CustomButton>
  );
};

export default BackButton;
