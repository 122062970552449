import { useFormContext, useWatch } from 'react-hook-form';

import { FlexoOrderEditForm } from '../../../types';
import { usePriceCalculater } from '../usePriceCalculater';

/** 一式計算 集計情報 Hooks */
export const useSetPriceTotal = (): { price: number; grossInvoice: number } => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { calcGrossInvoice } = usePriceCalculater();

  const [flexoMakingsMain, flexoMakingsReverse] = useWatch({
    control,
    name: ['making.flexoMakingsMain', 'making.flexoMakingsReverse'],
  });
  const flexoMakings = [...flexoMakingsMain, ...flexoMakingsReverse];

  let price = 0;
  let grossInvoice = 0;

  for (const row of flexoMakings) {
    price += row.completeSetCalcUnitPrice ?? 0;
    grossInvoice += calcGrossInvoice(
      row.completeSetCalcUnitPrice,
      row.completeSetCalcToyoPriceRate ?? 1,
    );
  }

  return {
    price,
    grossInvoice,
  };
};
