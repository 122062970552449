import React from 'react';

import { Heading } from '../../Heading';
import MakingPriceRowHeader from '../Common/MakingPriceRowHeader';
import MakingPriceMainRow from './MainMakingPriceRow';

/** 印刷面(メイン)計算 コンポーネント*/
const MakingPriceMain: React.VFC = () => {
  return (
    <>
      <Heading>印刷面(メイン)計算</Heading>
      <MakingPriceRowHeader />
      <MakingPriceMainRow label='ベタ版' rowPropertyName='betaHan' />
      <MakingPriceMainRow label='網版' rowPropertyName='halfTone' />
      <MakingPriceMainRow label='バリヤー処理代' rowPropertyName='barrier' />
      <MakingPriceMainRow label='ネガフィルム代' rowPropertyName='negaFilm' />
      <MakingPriceMainRow label='ポジ返し代' rowPropertyName='posi' />
      <MakingPriceMainRow label='単面ネガ代' rowPropertyName='singleFaceNega' />
      <MakingPriceMainRow label='殖版代' rowPropertyName='composer' />
      <MakingPriceMainRow label='全面マットネガ代' rowPropertyName='matNega' />
      <MakingPriceMainRow label='刷版代' rowPropertyName='plate' />
    </>
  );
};

export default React.memo(MakingPriceMain);
