import { useFormContext, useWatch } from 'react-hook-form';

import { OnlyTypeKey } from 'utils/typeUtils';

import {
  CylinderOrderEditForm,
  MinimumPriceKey,
  UnitPrice,
  UnitPriceRow,
  UnitPriceRowValues,
} from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';
import { useMinimumPrice } from './useMinimumPrice';

/** 工程あたり単価 行情報 Hooks */
export const useUnitPriceRow = (
  key: OnlyTypeKey<UnitPrice, UnitPriceRow>,
  minimumPriceKey?: MinimumPriceKey,
): UnitPriceRowValues => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const row = useWatch({ control, name: `price.unitPrice.${key}` });
  const { surfaceArea, toyoPriceRate } = usePriceCalculateMatter();
  const { calcUnitPrice, calcGrossInvoice } = usePriceCalculater();

  // 九州専用計算hook
  const { applyMinimumPrice } = useMinimumPrice(minimumPriceKey);

  /** 売価 */
  let price = calcUnitPrice(row.unitPrice, surfaceArea);

  // 最低価格を適用（九州専用）
  price = applyMinimumPrice(price);

  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate);

  return {
    unitPrice: row.unitPrice ?? 0,
    surfaceArea,
    price,
    toyoPriceRate,
    grossInvoice,
  };
};
