import { DataTypeNo } from 'constants/define';
import React, { useContext } from 'react';
import theme from 'themes/theme';

import { Upload, Download, ImageSearch } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  styled,
} from '@mui/material';

import { HeadGrid, BodyGrid, Heading, IconButton, Typography } from 'components/parts/atoms';

import * as date from 'utils/date';

import { ImageData, ImageDataInfoOfEachType, ImageDataSet } from './types';

type ImageItemProps = { imageData: ImageData };

type FolderItemProps = {
  processName: string;
  dataTypeNo: DataTypeNo;
  imageDataInfo: ImageDataInfoOfEachType;
};

export type ImageListThumbnailViewProps = {
  imageDataSet: ImageDataSet;
  downloadable: boolean;
  uploadable: boolean;
  thumbnailClickable: boolean;
  headerFlg?: boolean;
  onTypeDownloadlClicked?: (dataTypeNo: DataTypeNo) => void;
  onImageDownloadlClicked?: (imageDataId: number) => void;
  onUploadlClicked?: (dataTypeNo: DataTypeNo) => void;
  onThumbnailClicked?: (imageDataId: number) => void;
};

const StyledImg = styled('img')(() => ({
  width: '100%',
}));

/** アップロードボタンの表示非表示フラグを取得 */
const getUploadFlg = (dataTypeNo: DataTypeNo): boolean => {
  if (dataTypeNo !== DataTypeNo.original) {
    return true;
  } else {
    return false;
  }
};

/** ダウンロードボタンの表示非表示フラグを取得 */
const getDownloadFlg = (list: ImageData[]) => {
  if (list.length > 0) {
    return true;
  } else {
    return false;
  }
};

// ImageListThumbnailViewからFolderItem,ImageItemへのPropsのドリリングを避けるためコンテクストを作成
export const ImageListContext = React.createContext<ImageListThumbnailViewProps>({
  imageDataSet: {
    original: { jobNo: '', fileSize: 0, imageDataList: [] },
    design: { jobNo: '', fileSize: 0, imageDataList: [] },
    photoRetouch: { jobNo: '', fileSize: 0, imageDataList: [] },
    colorSeparation: { jobNo: '', fileSize: 0, imageDataList: [] },
    layout: { jobNo: '', fileSize: 0, imageDataList: [] },
    final: { jobNo: '', fileSize: 0, imageDataList: [] },
    send: { jobNo: '', fileSize: 0, imageDataList: [] },
  },
  downloadable: false,
  uploadable: false,
  thumbnailClickable: false,
  onTypeDownloadlClicked: () => undefined,
  onImageDownloadlClicked: () => undefined,
  onUploadlClicked: () => undefined,
  onThumbnailClicked: () => undefined,
});

const ImageItem: React.VFC<ImageItemProps> = (props) => {
  // ImageListThumbnailViewのPropsを取得
  const imageListContext = useContext(ImageListContext);

  return (
    <ImageListItem cols={2}>
      {!imageListContext.thumbnailClickable && (
        <>
          <Box
            sx={{ height: 'auto', display: 'flex', justifyContent: 'center' }}
            bgcolor={theme.palette.outSourcing.main}
          >
            {props.imageData.fileName}
          </Box>
        </>
      )}
      <ButtonBase
        onClick={() => {
          imageListContext.onThumbnailClicked?.(props.imageData.imageDataId);
        }}
        disabled={!imageListContext.thumbnailClickable}
      >
        <Box sx={{ height: '120px' }}>
          {props.imageData?.thumnailFileNameM ? (
            props.imageData?.thumnailFileNameM.startsWith('/images/CyclonistImg/') ? (
              <StyledImg src={props.imageData.thumnailFileNameM} />
            ) : props.imageData?.thumnailFileNameM.startsWith('/CyclonistImg/') ? (
              <StyledImg src={`/images/${props.imageData.thumnailFileNameM}`} />
            ) : (
              <StyledImg src={props.imageData?.thumnailFileNameM ?? ''} />
            )
          ) : (
            <StyledImg src={''} />
          )}
        </Box>
      </ButtonBase>

      {imageListContext.thumbnailClickable && (
        <ImageListItemBar
          title={props.imageData.fileName}
          subtitle={`${props.imageData.fileSize?.toFixed(2)}MB ${date.dateText(
            props.imageData.registerDate,
            'yy/MM/dd',
          )}`}
          actionIcon={
            <Box display='flex' flexDirection='column'>
              {imageListContext.thumbnailClickable && (
                <IconButton
                  onClick={() => {
                    imageListContext.onThumbnailClicked?.(props.imageData.imageDataId);
                  }}
                >
                  <ImageSearch sx={{ color: '#ffffff' }} />
                </IconButton>
              )}
              {imageListContext.downloadable && (
                <IconButton>
                  <Download
                    sx={{ color: '#ffffff' }}
                    onClick={() => {
                      imageListContext.onImageDownloadlClicked?.(props.imageData.imageDataId);
                    }}
                  />
                </IconButton>
              )}
            </Box>
          }
          sx={{
            '& .MuiImageListItemBar-titleWrap': {
              padding: 1,
            },
            '& .MuiImageListItemBar-title': {
              whiteSpace: 'normal',
            },
          }}
        />
      )}
      {!imageListContext.thumbnailClickable && (
        <>
          <Box
            sx={{ height: '15px', display: 'flex', justifyContent: 'center' }}
            bgcolor={theme.palette.cyclonistLightGrey.main}
          >
            {date.dateText(props.imageData.registerDate, 'yy/MM/dd')}
          </Box>
          <Box sx={{ height: '1px' }}></Box>
          <Box
            sx={{ height: '15px', display: 'flex', justifyContent: 'center' }}
            bgcolor={theme.palette.cyclonistLightGrey.main}
          >
            {props.imageData.fileSize?.toFixed(2)}MB
          </Box>
          <Box sx={{ height: '1px' }}></Box>
          <Box
            sx={{ height: '15px', display: 'flex', justifyContent: 'center' }}
            bgcolor={theme.palette.cyclonistLightGrey.main}
          >
            {props.imageData.extension}
          </Box>
        </>
      )}
    </ImageListItem>
  );
};

const FolderItem: React.VFC<FolderItemProps> = (props) => {
  // ImageListThumbnailViewのPropsを取得
  const imageListContext = useContext(ImageListContext);

  return (
    <Grid direction={'column'}>
      <HeadGrid
        sx={{
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <img src='/images/CyclonistImg/folder_S.png' />
        <Box mx={1} />
        <Typography variant='subtitle1'>{props.processName}</Typography>
        <Box mx={2} />
        <Typography>{props.imageDataInfo.fileSize.toFixed(2)}MB</Typography>
        {imageListContext.uploadable && getUploadFlg(props.dataTypeNo) && (
          <IconButton onClick={() => imageListContext.onUploadlClicked?.(props.dataTypeNo)}>
            <Upload />
          </IconButton>
        )}
        {imageListContext.downloadable && getDownloadFlg(props.imageDataInfo.imageDataList) && (
          <IconButton>
            <Download
              onClick={() => {
                imageListContext.onTypeDownloadlClicked?.(props.dataTypeNo);
              }}
            />
          </IconButton>
        )}
      </HeadGrid>
      <BodyGrid>
        <ImageList cols={12} sx={{ width: '100%', height: 'auto' }}>
          {props.imageDataInfo.imageDataList.map((item) => (
            <ImageItem key={item.imageDataId} imageData={item} />
          ))}
        </ImageList>
      </BodyGrid>
    </Grid>
  );
};

/**
 * 画像ファイルリスト（サムネイル表示）コンポーネント
 */
const ImageListThumbnailView: React.VFC<ImageListThumbnailViewProps> = (props) => {
  return (
    <ImageListContext.Provider value={props}>
      <Box>
        {!props.headerFlg || (props.headerFlg && <Heading>{`FOLDERS AND FILES`}</Heading>)}
        <FolderItem
          processName={'ORIGINAL'}
          dataTypeNo={DataTypeNo.original}
          imageDataInfo={props.imageDataSet.original}
        />

        <Box my={4} />
        <FolderItem
          processName={'DESIGN'}
          dataTypeNo={DataTypeNo.design}
          imageDataInfo={props.imageDataSet.design}
        />

        <Box my={4} />
        <FolderItem
          processName={'PR'}
          dataTypeNo={DataTypeNo.photoRetouch}
          imageDataInfo={props.imageDataSet.photoRetouch}
        />

        <Box my={4} />
        {props.imageDataSet.colorSeparation.imageDataList && (
          <FolderItem
            processName={'CS'}
            dataTypeNo={DataTypeNo.colorSeparation}
            imageDataInfo={props.imageDataSet.colorSeparation}
          />
        )}

        <Box my={4} />
        {props.imageDataSet.layout.imageDataList && (
          <FolderItem
            processName={'LAYOUT'}
            dataTypeNo={DataTypeNo.layout}
            imageDataInfo={props.imageDataSet.layout}
          />
        )}

        <Box my={4} />
        <FolderItem
          processName={'FINAL'}
          dataTypeNo={DataTypeNo.final}
          imageDataInfo={props.imageDataSet.final}
        />

        <Box my={4} />
        <FolderItem
          processName={'SEND'}
          dataTypeNo={DataTypeNo.send}
          imageDataInfo={props.imageDataSet.send}
        />
      </Box>
    </ImageListContext.Provider>
  );
};

export default ImageListThumbnailView;
