import { useFormContext, useWatch } from 'react-hook-form';

import { OtherOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';

/** その他受注製品情報 集計情報 Hooks */
export const useOtherProductInfoTotal = (): { price: number; grossInvoice: number } => {
  const { control } = useFormContext<OtherOrderEditForm>();
  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcPriceSum, calcGrossInvoiceSum } = usePriceCalculater();

  const otherProductInfos = useWatch({
    control,
    name: 'price.otherProductInfos',
  });

  let price = 0;
  let grossInvoice = 0;

  for (const row of otherProductInfos) {
    price += calcPriceSum(row.price, row.count);
    grossInvoice += calcGrossInvoiceSum(row.price, row.count, toyoPriceRate, row.toyoPriceRateFlg);
  }

  return {
    price,
    grossInvoice,
  };
};
