import * as React from 'react';
import theme from 'themes/theme';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { HeadLabel, BodyLabel, ColorLabel, BodyLink } from 'components/parts/atoms';
import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms/Grid';
import { TableCount } from 'components/parts/molecules';

import { ProgressListModel } from '../types';

type ProgressListProps = {
  progressList?: ProgressListModel;
  onClickTransition: (jobno: number) => void;
};

const HeaderGray = styled(HeadGrid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.makingSpecificationGray?.main,
  color: theme.palette.white.main,
}));
const HeaderOreng = styled(HeadGrid)(({ theme }) => ({
  backgroundColor: theme.palette.cyclonistOrange.main,
}));
const HeaderGreen = styled(HeadGrid)(({ theme }) => ({
  backgroundColor: theme.palette.cyclonistGreen.main,
}));

// 取消線、トビ入り
const ReserveRow = (xs: number, item: string, tobiflg: boolean, outflg: boolean) => {
  return (
    <BodyGrid
      xs={xs}
      sx={{
        backgroundColor: outflg ? theme.palette.cyclonistOrange.main : theme.palette.white.main,
      }}
    >
      <Box sx={{ color: tobiflg ? theme.palette.red.main : theme.palette.red.contrastText }}>
        <ColorLabel>{item}</ColorLabel>
      </Box>
    </BodyGrid>
  );
};

const ProgressList: React.VFC<ProgressListProps> = (props) => {
  return (
    <>
      <TableCount count={props.progressList?.length ?? 0}></TableCount>
      <RowGrid columns={32}>
        <HeaderGray xs={2}></HeaderGray>
        <HeaderGray xs={5}></HeaderGray>
        <HeaderGray xs={5}></HeaderGray>
        <HeaderGray xs={5}></HeaderGray>
        <HeaderGray xs={3}></HeaderGray>
        <HeaderGray xs={12}>
          <HeadLabel>進捗状況</HeadLabel>
        </HeaderGray>
      </RowGrid>
      <RowGrid columns={32}>
        <HeaderGray xs={2}></HeaderGray>
        <HeaderGray xs={5}>
          <HeadLabel>JOB NO.</HeadLabel>
        </HeaderGray>
        <HeaderGray xs={5}>
          <HeadLabel>得意先名</HeadLabel>
        </HeaderGray>
        <HeaderGray xs={5}>
          <HeadLabel>品名</HeadLabel>
        </HeaderGray>
        <HeaderGray xs={3}>
          <HeadLabel>状態</HeadLabel>
        </HeaderGray>

        <HeaderOreng xs={1}>
          <HeadLabel>色分</HeadLabel>
        </HeaderOreng>
        <HeaderOreng xs={1}>
          <HeadLabel>検版</HeadLabel>
        </HeaderOreng>
        <HeaderOreng xs={1}>
          <HeadLabel>校了</HeadLabel>
        </HeaderOreng>
        <HeaderOreng xs={1}>
          <HeadLabel>下版</HeadLabel>
        </HeaderOreng>

        <HeaderGreen xs={1}>
          <HeadLabel>入荷</HeadLabel>
        </HeaderGreen>
        <HeaderGreen xs={1}>
          <HeadLabel>Cu</HeadLabel>
        </HeaderGreen>
        <HeaderGreen xs={1}>
          <HeadLabel>L</HeadLabel>
        </HeaderGreen>
        <HeaderGreen xs={1}>
          <HeadLabel>彫刻</HeadLabel>
        </HeaderGreen>
        <HeaderGreen xs={1}>
          <HeadLabel>Cr</HeadLabel>
        </HeaderGreen>
        <HeaderGreen xs={1}>
          <HeadLabel>校正</HeadLabel>
        </HeaderGreen>
        <HeaderGreen xs={1}>
          <HeadLabel>検版</HeadLabel>
        </HeaderGreen>
        <HeaderGreen xs={1}>
          <HeadLabel>出荷</HeadLabel>
        </HeaderGreen>
      </RowGrid>
      {props.progressList?.map((item, index) => {
        return (
          <>
            <RowGrid columns={32}>
              {ReserveRow(
                2,
                (index + 1).toString(),
                item.tobiirijobflg,
                item.cylmakingoutsourcingflg,
              )}
              <BodyGrid
                xs={5}
                sx={{
                  backgroundColor: item.cylmakingoutsourcingflg
                    ? theme.palette.cyclonistOrange.main
                    : theme.palette.white.main,
                }}
              >
                <BodyLink
                  sx={{
                    color: item.tobiirijobflg
                      ? theme.palette.red.main
                      : theme.palette.red.contrastText,
                  }}
                  onClick={() => {
                    props.onClickTransition(item.jobid);
                  }}
                >
                  {item.jobno}
                </BodyLink>
              </BodyGrid>
              {ReserveRow(5, item.customername, item.tobiirijobflg, item.cylmakingoutsourcingflg)}
              {ReserveRow(
                5,
                item.productnamefordisplay,
                item.tobiirijobflg,
                item.cylmakingoutsourcingflg,
              )}
              {ReserveRow(3, item.totalstatus, item.tobiirijobflg, item.cylmakingoutsourcingflg)}

              <BodyGrid xs={1} sx={{ backgroundColor: item.imagecolorseparationstatus }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.imageinspectionstatus }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.imageapprovedstatus }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.imagelayoutstatus }} />

              <BodyGrid xs={1} sx={{ backgroundColor: item.statusarrival }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.statuscuplating }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.statuslaser }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.statusengraving }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.statuscrplating }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.statusproof }}>
                <BodyLabel>{item.proofflg}</BodyLabel>
              </BodyGrid>
              <BodyGrid xs={1} sx={{ backgroundColor: item.statusimageinspection }} />
              <BodyGrid xs={1} sx={{ backgroundColor: item.statusshipment }} />
            </RowGrid>
          </>
        );
      })}
    </>
  );
};
export default ProgressList;
