import * as React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography, TypographyProps } from 'components/parts/atoms';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const TypoGroup = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const TypographySamples: React.VFC = () => {
  const variants: TypographyProps['variant'][] = [
    'h1',
    'h2',
    'h3',
    'subtitle1',
    'body1',
    'caption',
  ];
  return (
    <Container>
      {variants.map((variant) => (
        <TypoGroup key={variant}>
          <Typography>{variant}</Typography>
          <Typography variant={variant}>{'Windowsでコンピュータの世界が広がります。'}</Typography>
        </TypoGroup>
      ))}
    </Container>
  );
};

export default TypographySamples;
