import * as React from 'react';

import { Edit } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type EditButtonProps = CustomButtonProps;

const EditButton: React.VFC<EditButtonProps> = (props) => {
  return (
    <CustomButton icon={<Edit />} {...props}>
      {'編集'}
    </CustomButton>
  );
};

export default EditButton;
