import { FaceDiv, printColorMap } from 'constants/define';
import React, { useCallback, useState, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import { AddCircle } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';

import {
  CylinderOrderEditForm,
  CylinderMaking,
  PageState,
} from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import {
  RowGrid,
  HeadGrid,
  BodyGrid as BodyGridOrigin,
  HeadLabel,
  BodyLabel,
  BodyGridProps,
  AttentionLabel,
} from 'components/parts/atoms';

import { isValidCylinderMaking } from '../../utils/utils';
import { getSelectText } from 'utils/selectUtils';

import { useCylinderOrderEditMaster } from '../../hooks/useCylinderOrderEditMaster';
import { useId } from '../../hooks/useId';
import { usePageContext } from '../../hooks/usePageContext';
import {
  EditButton,
  EditCheckbox,
  EditIconButton,
  EditKanaField,
  EditNumericField,
  EditSelect,
  ShowOnEdit,
} from '../Fields';
import { OpenCloseHeading } from '../OpenCloseHeading';
import {
  MakingCheckbox,
  MakingIconButton,
  MakingKanaField,
  MakingNumericField,
  MakingSelect,
} from './Field';
import PrintColorMap from './PrintColorMap';

/**
 * 製版指示が入力されているかの判断
 * @param row 行データ
 * @returns true:入力あり false:入力なし
 */
const hasInputDetail = (row: CylinderMaking): boolean => {
  //製版の詳細情報が入力されている場合はtrue 未入力はfalse
  if (
    row.lineCount ||
    row.stylus ||
    row.angle ||
    row.depth ||
    row.swidth ||
    row.diameterDifference
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 行に入力があるか
 */
function isValidItem(item: CylinderMaking): boolean {
  return isValidCylinderMaking(item);
}

type PropertyName = 'cylinderMakingsMain' | 'cylinderMakingsReverse';

const BodyGrid: React.FC<BodyGridProps> = ({ children, sx, ...props }) => {
  const { pageState } = usePageContext();
  const theme = useTheme();
  const padding = pageState === PageState.Edit ? theme.spacing(1) : undefined;

  return (
    <BodyGridOrigin
      {...props}
      sx={{
        justifyContent: 'center',
        paddingLeft: padding,
        paddingRight: padding,
        ...sx,
      }}
    >
      {children}
    </BodyGridOrigin>
  );
};

const PrintSideRow: React.VFC<{
  propertyName: PropertyName;
  index: number;
  onDeleteButtonClick: (index: number) => void;
}> = React.memo(({ propertyName, index, onDeleteButtonClick }) => {
  const { control, setValue } = useFormContext<CylinderOrderEditForm>();
  const { master } = useCylinderOrderEditMaster();
  const [item] = useWatch({ control, name: [`making.${propertyName}.${index}`] });

  /** 全角数字を半角数字に変換 */
  const convertToHalfWidth = (value: string): string => {
    const hankakuValue = value.replace(/[０-９．。ー]/g, (s) => {
      switch (s) {
        case '０':
          return '0';
        case '１':
          return '1';
        case '２':
          return '2';
        case '３':
          return '3';
        case '４':
          return '4';
        case '５':
          return '5';
        case '６':
          return '6';
        case '７':
          return '7';
        case '８':
          return '8';
        case '９':
          return '9';
        case '．':
          return '.';
        case '。':
          return '.';
        case 'ー':
          return '-';
        default:
          return s;
      }
    });

    return hankakuValue;
  };

  /** 刷色No変更時 */
  const onPrintColorChange = useCallback(
    (index: number, value: string) => {
      if (!value) return;

      const asciiVal = convertToHalfWidth(value);
      const sanitizedValue = asciiVal.replace(/[^\d.-]|(\.(?=.*\.))|(-(?=.*-))/g, '');
      const numVal = parseInt(sanitizedValue);

      if (isNaN(numVal)) return;

      // 刷色Noから刷色をセットする
      const printColor = printColorMap[numVal];
      if (printColor) {
        setValue(`making.${propertyName}.${index}.printColor`, printColor);
      }
    },
    [setValue],
  );
  return (
    <>
      <RowGrid columns={100}>
        <BodyGrid xs={8.5}>
          <Controller
            name={`making.${propertyName}.${index}.plateMakingId`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingSelect
                addEmptyRow
                options={master.plateMakingList}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={7.5}>
          <Controller
            name={`making.${propertyName}.${index}.summaryCdId`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingSelect
                addEmptyRow
                options={master.summaryList}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={5}>
          <Controller
            name={`making.${propertyName}.${index}.printSequence`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingNumericField
                max={999}
                min={-999}
                decimalDigits={0}
                onChange={onChange}
                value={value}
                name={name}
              />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={5}>
          <Controller
            name={`making.${propertyName}.${index}.printSequence2`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingNumericField
                max={999}
                min={-999}
                decimalDigits={0}
                onChange={onChange}
                value={value}
                name={name}
              />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={10}>
          <Box sx={{ display: 'flex' }}>
            <ShowOnEdit>
              <Controller
                name={`making.${propertyName}.${index}.printColorNo`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <MakingNumericField
                    max={99}
                    min={0}
                    decimalDigits={0}
                    onChange={onChange}
                    value={value}
                    name={name}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      onPrintColorChange(index, e.target.value);
                    }}
                    sx={{ width: '5em' }}
                  />
                )}
              />
            </ShowOnEdit>
            <Controller
              name={`making.${propertyName}.${index}.printColor`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingKanaField
                  maxLength={10}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Box>
        </BodyGrid>
        <BodyGrid xs={10}>
          <Controller
            name={`making.${propertyName}.${index}.colorTip`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingKanaField maxLength={10} onChange={onChange} value={value} name={name} />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={7.5}>
          <Controller
            name={`making.${propertyName}.${index}.orderCdId`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingSelect
                addEmptyRow={true}
                options={master.arrangeOrderList}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`making.${propertyName}.${index}.reOrderFlg`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingCheckbox onChange={onChange} checked={value} name={name} />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={7.5}>
          <Controller
            name={`making.${propertyName}.${index}.awaseChousaCdId`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingSelect
                addEmptyRow
                options={master.awaseChousaList}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={16}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Controller
              name={`making.${propertyName}.${index}.cylMakingType1CdId`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingSelect
                  addEmptyRow
                  options={master.cylMakingType1List}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '50%' }}
                />
              )}
            />
            <Controller
              name={`making.${propertyName}.${index}.cylMakingType2CdId`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingSelect
                  addEmptyRow
                  options={master.cylMakingType2List}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '50%' }}
                />
              )}
            />
          </Box>
        </BodyGrid>

        <BodyGrid xs={2}>
          <BodyLabel>{hasInputDetail(item) ? 'あり' : 'なし'}</BodyLabel>
        </BodyGrid>
        <BodyGrid xs={3}>
          <Controller
            name={`making.${propertyName}.${index}.pmpolishFlg`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingCheckbox onChange={onChange} checked={value} name={name} />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={11.5} sx={{ justifyContent: 'left' }}>
          <Controller
            name={`making.${propertyName}.${index}.awaseBanNo`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingKanaField
                maxLength={14}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </BodyGrid>
        <BodyGrid xs={3.5}>
          <MakingIconButton onClick={() => onDeleteButtonClick(index)} disabled={!item.canDelete}>
            <Delete />
          </MakingIconButton>
        </BodyGrid>
      </RowGrid>
    </>
  );
});

const PrintSideDetailRow: React.VFC<{ propertyName: PropertyName; index: number }> = React.memo(
  ({ propertyName, index }) => {
    const { control } = useFormContext<CylinderOrderEditForm>();
    const { master } = useCylinderOrderEditMaster();

    const [item] = useWatch({ control, name: [`making.${propertyName}.${index}`] });

    const cylMakingTypeText = (() => {
      const array: string[] = [];
      if (item.cylMakingType1CdId) {
        array.push(getSelectText(item.cylMakingType1CdId, master.cylMakingType1List));
      }
      if (item.cylMakingType2CdId) {
        array.push(getSelectText(item.cylMakingType2CdId, master.cylMakingType2List));
      }
      return array.join(' + ');
    })();

    return (
      <>
        <RowGrid columns={100}>
          <BodyGrid xs={6.5}>
            <BodyLabel>{getSelectText(item.plateMakingId, master.plateMakingList)}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={4}>
            <BodyLabel>{getSelectText(item.summaryCdId, master.summaryList)}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={2.5}>
            <BodyLabel>{item.printSequence}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={2.5}>
            <BodyLabel>{item.printSequence2}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={5}>
            <BodyLabel>{item.printColor}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={9}>
            <BodyLabel>{item.colorTip}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={4}>
            <BodyLabel>{getSelectText(item.orderCdId, master.arrangeOrderList)}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={2}>
            <BodyLabel fontSize={'2em'}>{item.reOrderFlg ? '●' : ''}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={5}>
            <BodyLabel>{getSelectText(item.awaseChousaCdId, master.awaseChousaList)}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={9}>
            <BodyLabel>{cylMakingTypeText}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={7}>
            <Controller
              name={`making.${propertyName}.${index}.lineCount`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingNumericField
                  max={999999}
                  min={0}
                  decimalDigits={0}
                  onChange={onChange}
                  value={value}
                  name={name}
                  labelWidth={'grow'}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={5}>
            <Controller
              name={`making.${propertyName}.${index}.stylus`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingNumericField
                  max={999}
                  min={0}
                  decimalDigits={0}
                  onChange={onChange}
                  value={value}
                  name={name}
                  labelWidth={'grow'}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={5}>
            <Controller
              name={`making.${propertyName}.${index}.angle`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingNumericField
                  max={999}
                  min={-999}
                  decimalDigits={0}
                  onChange={onChange}
                  value={value}
                  name={name}
                  labelWidth={'grow'}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={5.5}>
            <Controller
              name={`making.${propertyName}.${index}.depth`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingNumericField
                  max={999.9}
                  min={-999.9}
                  decimalDigits={1}
                  onChange={onChange}
                  value={value}
                  name={name}
                  labelWidth={'grow'}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={5}>
            <Controller
              name={`making.${propertyName}.${index}.swidth`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingNumericField
                  max={999}
                  min={0}
                  decimalDigits={0}
                  onChange={onChange}
                  value={value}
                  name={name}
                  labelWidth={'grow'}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={10}>
            <Controller
              name={`making.${propertyName}.${index}.diameterDifference`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingNumericField
                  max={9999.99}
                  min={-9999.99}
                  decimalDigits={2}
                  onChange={onChange}
                  value={value}
                  name={name}
                  labelWidth={'grow'}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={2}>
            <BodyLabel>{item.pmpolishFlg ? 'あり' : 'なし'}</BodyLabel>
          </BodyGrid>
          <BodyGrid xs={11} sx={{ justifyContent: 'left' }}>
            <BodyLabel>{item.awaseBanNo}</BodyLabel>
          </BodyGrid>
        </RowGrid>
      </>
    );
  },
);

type Props = {
  propertyName: PropertyName;
};

/** 作業予定 印刷面 */
const PrintSide: React.VFC<Props> = ({ propertyName }) => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();
  const { pageState } = usePageContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `making.${propertyName}`,
  });

  // hook
  const { generateId } = useId();

  const [makingDisplay, setMakingDisplay] = useState<boolean>(false);
  const [printColorMapDisplay, setPrintColorMapDisplay] = useState<boolean>(false);

  // 確認画面では製版指示を表示する
  useEffect(() => {
    setMakingDisplay(pageState !== PageState.Edit);
  }, [pageState]);

  /** 印刷面の行追加 */
  const addRow = useCallback(() => {
    const faceDiv = propertyName === 'cylinderMakingsMain' ? FaceDiv.Main : FaceDiv.Reverse;
    const addRow: CylinderMaking = {
      cylMakingJobId: generateId(),
      faceDiv: faceDiv,
      plateMakingId: '',
      summaryCdId: '',
      printSequence: null,
      printSequence2: null,
      printColorNo: null,
      printColor: '',
      colorTip: '',
      orderCdId: '',
      reOrderFlg: false,
      cylUnitPrice: 0,
      awaseChousaCdId: '',
      cylMakingType1CdId: '',
      cylMakingType2CdId: '',
      lineCount: null,
      stylus: null,
      angle: null,
      depth: null,
      swidth: null,
      diameterDifference: null,
      pmpolishFlg: false,
      awaseBanNo: '',
      completeSetCalcUnitPrice: 0,
      completeSetCalcToyoPriceRate: null,
      canDelete: true,
    };

    append(addRow);
  }, [append, generateId]);

  /** 印刷面の行削除 */
  const deleteRow = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  /** 製版指示表示非常時設定 */
  const toggleDisplay = useCallback(() => {
    setMakingDisplay((prev) => !prev);
  }, [setMakingDisplay]);

  /** 色番号表表示非常時設定 */
  const openPrintColorMap = useCallback(() => {
    setPrintColorMapDisplay(true);
  }, [setPrintColorMapDisplay]);

  /** 色番号表ウィンドウ終了時 */
  const onClosePrintColorMap = useCallback(() => {
    setPrintColorMapDisplay(false);
  }, [setPrintColorMapDisplay]);

  const printSideName = propertyName === 'cylinderMakingsMain' ? 'メイン' : 'リバース';
  const diameterBenchmarkPropertyName =
    propertyName === 'cylinderMakingsMain' ? 'surfaceDiameterBenchmark' : 'backDiameterBenchmark';

  return (
    <>
      <OpenCloseHeading
        onClose={toggleDisplay}
        buttonText={makingDisplay ? '製版指示非表示' : '製版指示表示'}
        displayFlg={makingDisplay}
      >
        {`印刷面(${printSideName})`}
      </OpenCloseHeading>

      {/* 基本情報入力時 */}
      {!makingDisplay && (
        <>
          {/* ヘッダー */}
          <RowGrid columns={100}>
            <HeadGrid vertical xs={8.5}>
              <Box>
                <ShowOnEdit>
                  <AttentionLabel>【必須※】</AttentionLabel>
                </ShowOnEdit>
                <HeadLabel>製版区分</HeadLabel>
              </Box>
            </HeadGrid>
            <HeadGrid vertical xs={7.5}>
              <HeadLabel>{'摘要'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={10}>
              <HeadLabel>{'刷順'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={10}>
              <Box flexDirection={'column'}>
                <HeadLabel>{'刷色'}</HeadLabel>
                <Box sx={{ position: 'absolute' }}>
                  <EditButton
                    variant='text'
                    onClick={openPrintColorMap}
                    sx={{ fontWeight: 'bold' }}
                  >
                    色番号表
                  </EditButton>
                </Box>
              </Box>
            </HeadGrid>
            <HeadGrid vertical xs={10}>
              <HeadLabel>{'カラーチップ'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={7.5}>
              <HeadLabel>{'手配'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={3}>
              <HeadLabel>{'再配'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={7.5}>
              <HeadLabel>{'合/調後処理'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={16}>
              <HeadLabel>{'版式'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={2}>
              <HeadLabel>{'製版指示'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={3}>
              <HeadLabel>{'PM研磨'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={11.5}>
              <HeadLabel>{'合版No./各色指示'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={3.5}>
              <MakingIconButton onClick={() => addRow()}>
                <AddCircle />
              </MakingIconButton>
            </HeadGrid>
          </RowGrid>
          {/* ボディ */}
          <Box>
            {fields.map((item, index) => {
              // 確認画面では入力されていない項目を表示しない
              if (pageState === PageState.Check && !isValidItem(item)) {
                return null;
              }
              return (
                <PrintSideRow
                  key={item.id}
                  propertyName={propertyName}
                  index={index}
                  onDeleteButtonClick={deleteRow}
                />
              );
            })}
          </Box>
        </>
      )}

      {/* 製版指示入力時 */}
      {makingDisplay && (
        <>
          {/* ヘッダー */}
          <RowGrid columns={100}>
            <HeadGrid vertical xs={6.5}>
              <HeadLabel>{'製版区分'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={4}>
              <HeadLabel>{'摘要'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={5}>
              <HeadLabel>{'刷順'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={5}>
              <Box flexDirection={'column'}>
                <HeadLabel>{'刷色'}</HeadLabel>
              </Box>
            </HeadGrid>
            <HeadGrid vertical xs={9}>
              <HeadLabel>{'カラーチップ'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={4}>
              <HeadLabel>{'手配'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={2}>
              <HeadLabel>{'再配'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={5}>
              <HeadLabel>{'合/調後処理'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={9}>
              <HeadLabel>{'版式'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={7}>
              <HeadLabel>{'線数'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={5}>
              <HeadLabel>{'スタイラス'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={5}>
              <HeadLabel>{'角度'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={5.5}>
              <HeadLabel>{'深度'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={5}>
              <HeadLabel>{'S巾'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={10}>
              <Box flexDirection={'column'}>
                <HeadLabel>{'径差'}</HeadLabel>
                <Controller
                  name={`making.makingBasis.${diameterBenchmarkPropertyName}`}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <MakingKanaField maxLength={8} onChange={onChange} value={value} name={name} />
                  )}
                />
              </Box>
            </HeadGrid>
            <HeadGrid vertical xs={2}>
              <HeadLabel>{'PM研磨'}</HeadLabel>
            </HeadGrid>
            <HeadGrid vertical xs={11}>
              <HeadLabel>{'合版No./各色指示'}</HeadLabel>
            </HeadGrid>
          </RowGrid>
          {/* ボディ */}
          <Box>
            {fields.map((item, index) => {
              // 確認画面では入力されていない項目を表示しない
              if (pageState === PageState.Check && !isValidItem(item)) {
                return null;
              }
              return <PrintSideDetailRow key={item.id} propertyName={propertyName} index={index} />;
            })}
          </Box>
        </>
      )}

      {printColorMapDisplay && <PrintColorMap onClose={onClosePrintColorMap} />}
    </>
  );
};

export default React.memo(PrintSide);
