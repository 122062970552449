import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialManuscriptShipmentEditState } from 'store/manuscript/manuscriptShipmentEdit/defaults';
import { ManuscriptShipmentEditState } from 'store/manuscript/manuscriptShipmentEdit/types';

const slice = createSlice({
  name: 'manuscriptShipmentEdit',
  initialState: InitialManuscriptShipmentEditState,
  reducers: {
    save: (state, action: PayloadAction<ManuscriptShipmentEditState>) => action.payload,
    clear: () => ({ ...InitialManuscriptShipmentEditState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
