export * from 'api/types/topSales/order/otherOrderEdit';

/**受注入力の画面表示定義 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
  Saved: 'Saved',
  Confirm: 'Confirm',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** 受注入力のタブインデックス */
export const OrderEditTabIndex = {
  Basis: 'Basis',
  Price: 'Price',
};
export type OrderEditTabIndex = typeof OrderEditTabIndex[keyof typeof OrderEditTabIndex];

// #region 価格情報
/** その他受注製品情報 行 */
export type OtherProductInfoRowValues = {
  /** 項目名 */
  itemName: string;
  /** 売価 */
  price: number | null;
  /** 東洋売価率フラグ */
  toyoPriceRateFlg: boolean;
  /** 仕切 */
  grossInvoice: number;
  /** 数量 */
  count: number | null;
  /** 売価計 */
  priceSum: number;
  /** 仕切計 */
  grossInvoiceSum: number;
};

export type SalesPrice = {
  /** 売価 */
  price: number;
  /** 仕切 */
  grossInvoice: number;
};

export type TotalPriceValues = {
  /** 総合計 */
  otherProductInfo: SalesPrice;
  /** 総合計 */
  total: SalesPrice;
};
// #endregion
