import useMessage from 'hooks/useMessage';
import { MessageApi } from 'hooks/useMessage';
import React, { createContext } from 'react';

export const MessageContext = createContext<MessageApi | undefined>(undefined);

export const MessagesProvider: React.FC = ({ children }) => {
  const message = useMessage();

  return <MessageContext.Provider value={message}>{children}</MessageContext.Provider>;
};
