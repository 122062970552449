export const messageStatus = {
  INFO: 1,
  WARNING: 2,
  ERROR: 3,
} as const;
export type MessageStatus = typeof messageStatus[keyof typeof messageStatus];

export type ResponseMessage = {
  id: string;
  message: string;
  status: MessageStatus;
};

export type ApiResponse<T> = {
  status: number;
  messages: ResponseMessage[] | null;
  result: T | null;
};

/** データ登録時に使用する基本型 */
export type RegisterResponse = {
  succeed: boolean;
};
