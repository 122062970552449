import { useReChroniumPriceRow } from '../priceRow/useMakingPriceRow';

/** 再クロム 集計情報 Hooks */
export const useReChroniumPriceTotal = (): { price: number; grossInvoice: number } => {
  const reCr = useReChroniumPriceRow('ReCr');

  const rows = [reCr];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
