import { PlateMakingCode, SummaryCode } from 'constants/define';

import { OnlyTypeKey } from 'utils/typeUtils';

import { UnitPrice, UnitPriceRow } from '../../../types';

export type MakingPriceRowTarget =
  | {
      category: 'Normal';
      type: 'New' | 'Old' | 'Supply' | 'Copper';
    }
  | {
      category: 'Hanberi';
      type: 'New' | 'Old' | 'Supply' | 'Copper';
    }
  | {
      category: 'ReChronium';
      type: 'ReCr';
    };

type MakingPriceRowProperties = {
  plateMakingCodes: PlateMakingCode[] | null;
  summaryCodes: SummaryCode[] | null;
  unitPriceKeys: Exclude<OnlyTypeKey<UnitPrice, UnitPriceRow>, 'cylMaking'>[];
};

/** 製版価格の集計対象を返す Hooks */
export const useMakingPriceRowProperties = ({
  category,
  type,
}: MakingPriceRowTarget): MakingPriceRowProperties => {
  switch (category) {
    // フルコース、 改版、増版
    case 'Normal': {
      const plateMakingCodes = [
        PlateMakingCode.FullDiv,
        PlateMakingCode.KaihanDiv,
        PlateMakingCode.ZouhanDiv,
      ];
      const [summaryCodes, unitPriceKeys] = ((): [
        SummaryCode[] | null,
        Exclude<OnlyTypeKey<UnitPrice, UnitPriceRow>, 'cylMaking'>[],
      ] => {
        switch (type) {
          case 'New':
            return [[SummaryCode.New], ['materials', 'cuPlate', 'crPlate', 'finishingPolish']];
          case 'Old':
            return [[SummaryCode.Old], ['cuPlate', 'crPlate', 'finishingPolish', 'rakuhanPolish']];
          case 'Supply':
            return [[SummaryCode.Supply], ['cuPlate', 'crPlate', 'finishingPolish']];
          case 'Copper':
            return [[SummaryCode.Copper], ['crPlate']];
          default:
            return [[], []];
        }
      })();
      return { plateMakingCodes, summaryCodes, unitPriceKeys };
    }
    // 版べり再版
    case 'Hanberi': {
      const plateMakingCodes = [PlateMakingCode.HanberiDiv];
      const [summaryCodes, unitPriceKeys] = ((): [
        SummaryCode[] | null,
        Exclude<OnlyTypeKey<UnitPrice, UnitPriceRow>, 'cylMaking'>[],
      ] => {
        switch (type) {
          case 'New':
            return [[SummaryCode.New], ['materials', 'cuPlate', 'crPlate', 'finishingPolish']];
          case 'Old':
            return [[SummaryCode.Old], ['cuPlate', 'crPlate', 'finishingPolish', 'rakuhanPolish']];
          case 'Supply':
            return [[SummaryCode.Supply], ['cuPlate', 'crPlate', 'finishingPolish']];
          case 'Copper':
            return [[SummaryCode.Copper], ['crPlate']];
          default:
            return [[], []];
        }
      })();
      return { plateMakingCodes, summaryCodes, unitPriceKeys };
    }
    // 再クロム
    case 'ReChronium': {
      const plateMakingCodes = [PlateMakingCode.ReChromeDiv];
      const [summaryCodes, unitPriceKeys] = ((): [
        SummaryCode[] | null,
        Exclude<OnlyTypeKey<UnitPrice, UnitPriceRow>, 'cylMaking'>[],
      ] => {
        switch (type) {
          case 'ReCr':
            return [null, ['reCr']];
          default:
            return [[], []];
        }
      })();
      return { plateMakingCodes, summaryCodes, unitPriceKeys };
    }
  }
};
