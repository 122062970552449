import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Link } from '@mui/material';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel } from 'components/parts/atoms';

import { usePageContext } from '../../../hooks/usePageContext';
import { PageState } from '../../../types';
import { PriceURLKanaField, PriceURLNumField } from '../Fields';
import { Row } from './Row';

/** URLコンポーネント */
const Url: React.VFC = () => {
  // コンテキスト
  const { control, ...formContext } = useFormContext<CylinderOrderEditForm>();

  // URLは確認でリンクを作成するためここでpageStateを取得
  const { pageState } = usePageContext();
  const confirm = pageState !== PageState.Edit;

  // 詳細画面用のurl
  const urlName = formContext.getValues('price.url.urlName') ?? '';
  const url = formContext.getValues('price.url.url') ?? '';

  const displayFlg = !formContext.getValues('price.customerUserFlg');

  if (displayFlg) {
    if (confirm) {
      return (
        <>
          <Box>
            <Row>
              <HeadLabel>URL</HeadLabel>
              <Link
                href={url.trim()}
                target='_blank'
                rel='noopener noreferrer'
                overflow='hidden'
                whiteSpace='nowrap'
              >
                {urlName.trim() == '' ? url : urlName.trim()}
              </Link>
            </Row>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box>
            <Row>
              <HeadLabel>URL名</HeadLabel>
              <Controller
                name='price.url.urlName'
                control={control}
                render={({ field }) => (
                  <PriceURLKanaField
                    maxLength={250}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Row>
            <Row>
              <HeadLabel>URL</HeadLabel>
              <Controller
                name='price.url.url'
                control={control}
                render={({ field }) => (
                  <PriceURLNumField
                    maxLength={250}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Row>
          </Box>
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default React.memo(Url);
