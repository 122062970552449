import * as React from 'react';

import { useTheme } from '@mui/material';

import Link, { LinkProps } from './Link';

export type BodyLinkProps2 = {
  onClick?: () => void;
  color?: LinkProps['color'];
  variant?: LinkProps['variant'];
  lineThrough?: boolean;
  sx?: LinkProps['sx'];
};

const BodyLink: React.FC<BodyLinkProps2> = ({
  children,
  sx,
  variant = 'body1',
  lineThrough,
  ...props
}) => {
  const theme = useTheme();

  let textDecoration = 'underline';
  if (lineThrough) {
    textDecoration += ' line-through';
  }

  return (
    <Link
      {...props}
      variant={variant}
      sx={{
        cursor: 'pointer',
        textDecoration,
        '&:hover': {
          color: theme.palette.cyclonistRed.main,
        },
        ...sx,
      }}
    >
      {children}
    </Link>
  );
};

export default BodyLink;
