import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Dialog } from 'components/parts/molecules';

import { defaultDeliveryPlaceEdit } from '../CustomerEdit/defaults';
import { DeliveryPlaceEdit } from '../CustomerEdit/types';
import DeliveryAddressEdit from './DeliveryAddressEdit';
import DeliveryAddressEditCheck from './DeliveryAddressEditCheck';
import { useCheckDeliveryAddressEdit } from './hooks/useCheckDeliveryAddressEdit';
import { PageState, MainProps, InputCheck } from './types';

//  納入先入力・確認のメインページ
const DeliveryAddressEditMainPage: React.VFC<MainProps> = (props) => {
  // ページステート
  const [pageState, setPageState] = useState<PageState>(PageState.Edit);

  // hooks
  const { messageApi, IsInputCheckAsync } = useCheckDeliveryAddressEdit();

  // 納入先入力専用フォーム
  const deliveryPlaceFormMethod = useForm<DeliveryPlaceEdit>({
    defaultValues: defaultDeliveryPlaceEdit,
  });

  // 画面表示時
  useEffect(() => {
    deliveryPlaceFormMethod.reset(props.state);
    if (props.checkFinishFlag) {
      setPageState(PageState.Check);
    }
  }, [props.open]);

  /** 納入先入力ボタンイベント */
  // （納入先入力確認に）進む
  const onGoCheck = deliveryPlaceFormMethod.handleSubmit(async (data: DeliveryPlaceEdit) => {
    messageApi.clear();
    // 入力チェック
    const inputCondition: InputCheck = {
      deliveryPlaceName: data.deliveryPlaceName,
      zipCd: data.zipCd,
      tel: data.tel,
    };
    const inputCheckFlag: boolean = await IsInputCheckAsync(inputCondition);
    if (inputCheckFlag) {
      setPageState(PageState.Check);
    }
  });
  // 戻る（ダイアログを閉じる）
  const onBackCustomerEdit = () => {
    setPageState(PageState.Edit);
    deliveryPlaceFormMethod.reset(defaultDeliveryPlaceEdit);
    props.onClose(undefined);
  };

  /** 納入先入力確認ボタンアクション */
  // 得意先入力のフォームに登録
  const onAddFormArray = deliveryPlaceFormMethod.handleSubmit((data: DeliveryPlaceEdit) => {
    setPageState(PageState.Edit);
    props.onClose(data);
    deliveryPlaceFormMethod.reset(defaultDeliveryPlaceEdit);
  });
  // （編集に）戻る
  const onBackEdit = () => {
    if (props.checkFinishFlag) {
      props.onClose(undefined);
    }
    setPageState(PageState.Edit);
  };

  /** ダイアログを閉じる際の処理 */
  const onCloseDialog = () => {
    setPageState(PageState.Edit);
    deliveryPlaceFormMethod.reset(defaultDeliveryPlaceEdit);
    props.onClose(undefined);
  };

  return (
    <Dialog
      open={props.open}
      title={pageState === PageState.Edit ? '納入先入力' : '納入先入力確認'}
      onClose={() => onCloseDialog()}
      messageApi={messageApi}
    >
      <FormProvider {...deliveryPlaceFormMethod}>
        {(() => {
          switch (pageState) {
            // 納入先入力
            case PageState.Edit:
              return <DeliveryAddressEdit onBack={onBackCustomerEdit} onGoCheck={onGoCheck} />;
            // 納入先入力確認
            case PageState.Check:
              return (
                <DeliveryAddressEditCheck
                  onBack={onBackEdit}
                  onAddForm={onAddFormArray}
                  checkFinishFlag={props.checkFinishFlag}
                />
              );
            default:
              throw new Error();
          }
        })()}
      </FormProvider>
    </Dialog>
  );
};

export default DeliveryAddressEditMainPage;
