import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { usePlateMakingId } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/hooks/usePlateMakingId';

import { isValidFlexoMaking } from '../../utils/utils';

import { FlxUnitPrice, FlexoOrderEditForm, FlexoMaking } from '../../types';

/** 単価変更時にフォームをリセットするHooks */
export const useResetPrice = (): {
  resetUnitPrice: (flxUnitPrice: FlxUnitPrice | null) => void;
  resetPriceRate: (flxUnitPrice: FlxUnitPrice | null) => void;
  resetPrices: (flxUnitPrice: FlxUnitPrice | null) => void;
} => {
  // フォーム
  const { setValue, getValues } = useFormContext<FlexoOrderEditForm>();

  const getPlateCount = (makings: FlexoMaking[]): number => {
    const count = makings
      .filter((e) => isValidFlexoMaking(e))
      .filter((x) => x.plateMakingId !== '').length;

    return count;
  };
  const flexoMakingsMain = getValues('making.flexoMakingsMain');
  const flexoMakingsReverse = getValues('making.flexoMakingsReverse');
  const mainPlateCount: number = getPlateCount(flexoMakingsMain) ?? 0;
  const reversePlateCount: number = getPlateCount(flexoMakingsReverse) ?? 0;

  const resetUnitPrice = useCallback(
    (flxUnitPrice: FlxUnitPrice | null) => {
      if (flxUnitPrice) {
        // 印刷面(メイン)計算
        setValue('price.makingPriceMain.betaHan.unitPrice', flxUnitPrice.betaHan);
        setValue('price.makingPriceMain.halfTone.unitPrice', flxUnitPrice.halftone);
        setValue('price.makingPriceMain.barrier.unitPrice', flxUnitPrice.barrierPrice);
        setValue('price.makingPriceMain.negaFilm.unitPrice', flxUnitPrice.negaFilmPrice);
        setValue('price.makingPriceMain.posi.unitPrice', flxUnitPrice.posiReturnPrice);
        setValue(
          'price.makingPriceMain.singleFaceNega.unitPrice',
          flxUnitPrice.singleFaceNegaPrice,
        );
        setValue('price.makingPriceMain.composer.unitPrice', flxUnitPrice.composerPrice);
        setValue('price.makingPriceMain.matNega.unitPrice', flxUnitPrice.matNegaPrice);
        setValue('price.makingPriceMain.plate.unitPrice', flxUnitPrice.platePrice);

        // 印刷面(リバース)計算
        setValue('price.makingPriceReverse.betaHan.unitPrice', flxUnitPrice.betaHan);
        setValue('price.makingPriceReverse.halfTone.unitPrice', flxUnitPrice.halftone);
        setValue('price.makingPriceReverse.barrier.unitPrice', flxUnitPrice.barrierPrice);
        setValue('price.makingPriceReverse.negaFilm.unitPrice', flxUnitPrice.negaFilmPrice);
        setValue('price.makingPriceReverse.posi.unitPrice', flxUnitPrice.posiReturnPrice);
        setValue(
          'price.makingPriceReverse.singleFaceNega.unitPrice',
          flxUnitPrice.singleFaceNegaPrice,
        );
        setValue('price.makingPriceReverse.composer.unitPrice', flxUnitPrice.composerPrice);
        setValue('price.makingPriceReverse.matNega.unitPrice', flxUnitPrice.matNegaPrice);
        setValue('price.makingPriceReverse.plate.unitPrice', flxUnitPrice.platePrice);

        // 単価計算
        setValue('price.unitPrice.dataMake.unitPrice', flxUnitPrice.dataMakePrice);
        setValue('price.unitPrice.dataEdit.unitPrice', flxUnitPrice.dataEditPrice);
        setValue('price.unitPrice.layoutData.unitPrice', flxUnitPrice.layoutDataPrice);
        setValue('price.unitPrice.colorProcess.unitPrice', flxUnitPrice.colorProcessPrice);
        setValue('price.unitPrice.photoRetouch.unitPrice', flxUnitPrice.photoRetouchPrice);
        setValue('price.unitPrice.blockCopy.unitPrice', flxUnitPrice.blockCopyPrice);
        setValue('price.unitPrice.fare.unitPrice', flxUnitPrice.fare);
        setValue('price.unitPrice.fare.count', mainPlateCount + reversePlateCount);

        // 東洋売価率にチェックを入れる
        setValue('price.unitPrice.fare.toyoPriceRateFlg', true);
        const kakakuList = getValues('price.otherImagePrice.kakakuJouhouSonotas');
        if (kakakuList != null && kakakuList?.length > 0) {
          const updateKakakuList = kakakuList.map((item) => ({ ...item, toyoPriceRateFlg: true }));
          setValue('price.otherImagePrice.kakakuJouhouSonotas', updateKakakuList);
        }
      }
    },
    [setValue],
  );

  const resetPriceRate = useCallback(
    (flxUnitPrice: FlxUnitPrice | null) => {
      if (flxUnitPrice) {
        setValue('price.toyoPriceRate', flxUnitPrice.toyoPriceRate);
      }
    },
    [setValue],
  );

  const resetPrices = (flxUnitPrice: FlxUnitPrice | null) => {
    resetUnitPrice(flxUnitPrice);
    resetPriceRate(flxUnitPrice);
  };

  return {
    resetUnitPrice,
    resetPriceRate,
    resetPrices,
  };
};
