import React from 'react';

import { useTheme } from '@mui/material';

import { Heading } from 'components/parts/atoms';

type Props = {
  children: string;
};

const StyledHeading: React.VFC<Props> = ({ children }) => {
  const theme = useTheme();
  return (
    <>
      <Heading
        backgroundColor={theme.palette.cyclonistDarkOrange.main}
        textColor={theme.palette.cyclonistDarkOrange.contrastText}
        sx={{ zIndex: 1, position: 'relative', marginBottom: '2px' }}
      >
        {children}
      </Heading>
    </>
  );
};
export { StyledHeading as Heading };
