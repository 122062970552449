import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { HeadLabel, RowGrid } from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

import { EditKanaField, EditNumericField } from '../Fields';

/** 作業予定 バーコードコンポーネント */
const Barcode: React.VFC = () => {
  // コンテキスト
  const formContext = useFormContext<CylinderOrderEditForm>();

  return (
    <RowGrid>
      <ContentsGrid hxs={2} bxs={4}>
        <HeadLabel>バーコード No.</HeadLabel>
        <Controller
          name='making.barcode.barcodeNo'
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditKanaField
              maxLength={38}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '60em' }}
            />
          )}
        />
      </ContentsGrid>
      <ContentsGrid hxs={1} bxs={2}>
        <HeadLabel>倍率</HeadLabel>
        <Controller
          name='making.barcode.scale'
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditNumericField
              max={9.99}
              min={0}
              decimalDigits={2}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '4em' }}
            />
          )}
        />
      </ContentsGrid>
      <ContentsGrid hxs={1} bxs={2}>
        <HeadLabel>BWR</HeadLabel>
        <Controller
          name='making.barcode.bwr'
          control={formContext.control}
          render={({ field: { onChange, value, name } }) => (
            <EditNumericField
              max={999}
              min={0}
              decimalDigits={0}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '4em' }}
            />
          )}
        />
      </ContentsGrid>
    </RowGrid>
  );
};

export default React.memo(Barcode);
