import * as React from 'react';

import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material';

export type MenuProps = MuiMenuProps;

const Menu: React.FC<MenuProps> = ({ children, ...props }) => {
  return <MuiMenu {...props}>{children}</MuiMenu>;
};

export default Menu;
