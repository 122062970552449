import * as React from 'react';

import { Paper } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Typography, TypographyProps, BodyLink, LinkProps } from 'components/parts/atoms';

export type HeadingProps = {
  component?: string;
  children?: string;
  onLinkClick: () => void;
  variant?: TypographyProps['variant'];
  backgroundColor?: string;
  textColor?: string;
  sx?: LinkProps['sx'];
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const LinkHeading: React.FC<HeadingProps> = ({ variant: typoVariant = 'h3', ...props }) => {
  const theme = useTheme();
  const backgroundColor = props.backgroundColor ?? theme.palette.primary.main;
  const textColor = props.textColor ?? theme.palette.primary.contrastText;

  return (
    <StyledPaper sx={{ backgroundColor: backgroundColor, flexGrow: 1 }}>
      <Typography
        variant={typoVariant}
        sx={{
          color: textColor,
        }}
      >
        <BodyLink
          variant={typoVariant}
          onClick={() => {
            if (props.onLinkClick) {
              props.onLinkClick();
            }
          }}
          sx={props.sx}
        >
          {props.children ?? '　'}
        </BodyLink>
      </Typography>
    </StyledPaper>
  );
};

export default LinkHeading;
