import { Affiliation } from 'constants/define';
import { useAuth } from 'hooks/useAuth';
import React, { useState, useCallback } from 'react';

import { FindInPage } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import ProgressListDialog from 'pages/common/ProgressList/ProgressListDialog';
import DocumentSearchList from 'pages/portalTop/DocumentSearchList';
import JobNoSearchResult from 'pages/portalTop/JobNoSearchResult';

import { Button, SearchButton, Select, AlphaNumericField } from 'components/parts/atoms';
import { SelectOption } from 'components/parts/atoms/Select/types';

const Wrapper = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  width: '800px',
}));

const StyledSelect = styled(Select)(() => ({
  height: '80%',
  width: '120px',
}));

const SearchField = styled(AlphaNumericField)(() => ({
  height: '80%',
}));

const StyledSearchButton = styled(SearchButton)(() => ({
  height: '80%',
}));

const StyledButton = styled(Button)(() => ({
  height: '80%',
}));

const SearchBox: React.VFC = () => {
  // セッション情報
  const { session } = useAuth();

  const [searchTarget, setSearchTarget] = useState<number>(0);
  const [inputJobNo, setInputJobNo] = useState<string>('');
  const [searchJobNo, setSearchJobNo] = useState<string>('');
  const [documentJobNo, setDocumentJobNo] = useState<string>('');
  const [progressJobNo, setProgressJobNo] = useState<string>('');
  const [showJobNoSearchResult, setShowJobNoSearchResult] = useState<boolean>(false);
  const [showDocumentSearchList, setShowDocumentSearchList] = useState<boolean>(false);
  const [showProgressDialog, setShowProgressDialog] = useState<boolean>(false);

  const handleJobNoSearchResultClose = useCallback(() => {
    setShowJobNoSearchResult(false);
  }, []);

  const handleDocumentSearchListClose = useCallback(() => {
    setShowDocumentSearchList(false);
  }, []);

  const handleProgressDialog = useCallback(() => {
    setShowProgressDialog(false);
  }, []);

  const openOrderSearch = useCallback(() => {
    window.open(
      '/OrderSearch',
      'OrderSearch',
      `width=1150,height=600,toolbar=yes,menubar=yes,scrollbars=yes`,
    );
  }, []);

  const searchTargets: SelectOption[] = (() => {
    if (session?.affiliation === Affiliation.Normal) {
      return [
        { value: '0', text: 'JOB情報' },
        { value: '1', text: '進捗' },
        { value: '2', text: '原稿袋' },
      ];
    } else {
      return [
        { value: '0', text: 'JOB情報' },
        { value: '1', text: '進捗' },
      ];
    }
  })();

  return (
    <>
      <Wrapper>
        <StyledSelect
          value={searchTarget.toString()}
          onChange={(e) => setSearchTarget(e.target.value as number)}
          options={searchTargets}
        />
        <Box mx={1}></Box>
        <SearchField
          placeholder='JOB No.を入力'
          value={inputJobNo}
          onChange={(e) => setInputJobNo(e.target.value)}
          maxLength={17}
        />
        <Box mx={1}></Box>
        <StyledSearchButton
          loading={false}
          onClick={() => {
            if (searchTarget == 0) {
              setSearchJobNo(inputJobNo);
              setShowJobNoSearchResult(true);
            } else if (searchTarget == 1) {
              setProgressJobNo(inputJobNo);
              setShowProgressDialog(true);
            } else if (searchTarget == 2) {
              setDocumentJobNo(inputJobNo);
              setShowDocumentSearchList(true);
            }
          }}
        />
        <Box mx={0.5}></Box>
        <StyledButton startIcon={<FindInPage />} onClick={openOrderSearch}>
          {'詳細検索'}
        </StyledButton>
      </Wrapper>
      {showJobNoSearchResult && (
        <JobNoSearchResult jobNo={searchJobNo} onClose={handleJobNoSearchResultClose} />
      )}
      {showDocumentSearchList && (
        <DocumentSearchList jobNo={documentJobNo} onClose={handleDocumentSearchListClose} />
      )}

      {showProgressDialog && (
        <ProgressListDialog
          jobNo={progressJobNo}
          open={showProgressDialog}
          onClose={handleProgressDialog}
        />
      )}
    </>
  );
};
export default SearchBox;
