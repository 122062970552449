import { MessageApi } from 'hooks/useMessage';
import React from 'react';
import theme from 'themes/theme';

import { Box } from '@mui/material';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent as MuiDialogContent,
} from '@mui/material';

import MessageListener from 'components/Message/MessageListener';
import { DialogCloseHeading } from 'components/parts/molecules/DialogCloseHeading';

/** ダイアログコンポーネントの型定義 */
export type DialogProps = {
  /** ダイアログ表示非常時フラグ */
  open: MuiDialogProps['open'];
  /** ダイアログタイトル */
  title: string;
  /** 閉じる時イベント */
  onClose: () => void;
  /** メッセージApi */
  messageApi: MessageApi;
  /** ダイアログ幅 */
  maxWidth?: MuiDialogProps['maxWidth'];
  /** ダイアログ高
   * cssのheightに設定できる以下の値が使用可能です
   * px,em,%,auto
   */
  height?: string;
  /** portal無効フラグ */
  disablePortal?: MuiDialogProps['disablePortal'];
};

/** ダイアログコンポーネント */
const Dialog: React.FC<DialogProps> = (props) => {
  const handleClose = (_: unknown, reason: string) => {
    if (reason !== 'backdropClick') {
      props.messageApi.clear();
      props.onClose();
    }
  };

  const onClose = () => {
    props.messageApi.clear();
    props.onClose();
  };

  return (
    <MuiDialog
      fullWidth={true}
      maxWidth={props.maxWidth ?? 'md'}
      onClose={handleClose}
      open={props.open}
      PaperProps={{
        sx: {
          height: props.height ?? '90%',
          backgroundColor: theme.palette.background.default,
        },
      }}
      disablePortal={props.disablePortal}
    >
      <Box display={'flex'} sx={{ ml: 2, mt: 2, mr: 1 }}>
        <DialogCloseHeading onClose={onClose}>{props.title}</DialogCloseHeading>
      </Box>

      <Box sx={{ m: 1 }}>
        <MessageListener messageApi={props.messageApi} />
      </Box>
      <MuiDialogContent>
        <Box sx={{ width: '100%' }}>{props.children}</Box>
      </MuiDialogContent>
    </MuiDialog>
  );
};

export default Dialog;
