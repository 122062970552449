import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState, useEffect } from 'react';

import { InformationsRes } from 'api/types/common/informations';

import { initialInformations } from './defaults';
import { Division, Informations as InformationsType } from './types';

type Informations = {
  informations: InformationsType;
};
export const useInformations = (division: Division): Informations => {
  // Hooks
  const { pushMessages } = useSharedMessage();
  const { apiClient } = useApiClient(pushMessages);

  // State
  const [informations, setInformations] = useState<InformationsType>(initialInformations);

  /** 初期表示時の取得 */
  useEffect(() => {
    (async () => {
      let response;
      switch (division) {
        case 'Image':
          // 画像課
          response = await apiClient.get<InformationsRes>(
            '/api/v1/Informations/GetImageInformations',
          );
          break;

        default: {
          const strangeDivision: never = division;
          throw new Error(`${strangeDivision} is not Division.`);
        }
      }
      if (response.data.result) {
        setInformations(response.data.result);
      }
    })();
  }, []);

  return { informations };
};
