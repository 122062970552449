import { BigNumber } from 'bignumber.js';
import { useCallback } from 'react';

/** 価格の計算処理Hooks */
export const usePriceCalculater = (): {
  calcSurfaceArea: (plateWidth: number | null, plateGrain: number | null) => number;
  calcMakingPrice: (unitPrice: number | null, surfaceArea: number, count: number | null) => number;
  calcUnitPrice: (unitPrice: number | null, count: number | null) => number;
  calcDiscountRate: (price: number, discountRate: number | null) => number;
  calcGrossInvoice: (
    price: number | null,
    toyoPriceRate: number | null,
    toyoPriceRateFlg?: boolean,
  ) => number;
} => {
  /** 四捨五入 */
  const round = useCallback((price: number, decimalPlaces = 0) => {
    const priceBN = new BigNumber(price);
    const decimalPlacesBN = new BigNumber(decimalPlaces);

    if (decimalPlaces >= 0) {
      return priceBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_HALF_UP).toNumber();
    } else {
      const factor = new BigNumber(10).pow(decimalPlacesBN.abs());
      return priceBN
        .dividedBy(factor)
        .decimalPlaces(0, BigNumber.ROUND_HALF_UP)
        .times(factor)
        .toNumber();
    }
  }, []);

  /** 表面積の計算 */
  const calcSurfaceArea = useCallback(
    (plateWidth: number | null, plateGrain: number | null): number => {
      return new BigNumber(plateWidth ?? 0)
        .times(plateGrain ?? 0)
        .dividedBy(100)
        .integerValue(BigNumber.ROUND_CEIL)
        .toNumber();
    },
    [],
  );

  /** 印刷面計算売価の計算 */
  const calcMakingPrice = useCallback(
    (unitPrice: number | null, surfaceArea: number, count: number | null): number => {
      return round(
        new BigNumber(unitPrice ?? 0)
          .times(surfaceArea)
          .times(count ?? 0)
          .toNumber(),
      );
    },
    [],
  );

  /** 単価計算売価の計算 */
  const calcUnitPrice = useCallback((unitPrice: number | null, count: number | null): number => {
    return new BigNumber(unitPrice ?? 0).times(count ?? 0).toNumber();
  }, []);

  /** 値引き率の計算 */
  const calcDiscountRate = useCallback((price: number, discountRate: number | null): number => {
    return new BigNumber(price)
      .minus(
        new BigNumber(price)
          .times(discountRate ?? 0)
          .dividedBy(100)
          .integerValue(BigNumber.ROUND_UP),
      )
      .toNumber();
  }, []);

  /** 仕切価格の計算 */
  const calcGrossInvoice = useCallback(
    (price: number | null, toyoPriceRate: number | null, toyoPriceRateFlg = true): number => {
      if (toyoPriceRateFlg) {
        return round(new BigNumber(price ?? 0).times(toyoPriceRate ?? 1).toNumber());
      } else {
        return price ?? 0;
      }
    },
    [],
  );

  return {
    calcSurfaceArea,
    calcMakingPrice,
    calcUnitPrice,
    calcDiscountRate,
    calcGrossInvoice,
  };
};
