import { useNormalPriceRow } from '../priceRow/useMakingPriceRow';

/** フルコース、 改版、増版 集計情報 Hooks */
export const useNormalPriceTotal = (): { price: number; grossInvoice: number } => {
  const newCylinder = useNormalPriceRow('New');
  const old = useNormalPriceRow('Old');
  const supply = useNormalPriceRow('Supply');
  const copper = useNormalPriceRow('Copper');

  const rows = [newCylinder, old, supply, copper];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
