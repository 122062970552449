import { ProductionEditModel, ProductionEditResponse } from './types';

/** プロダクション編集初期値 */
export const defaultProductionEditModel: ProductionEditModel = {
  productionId: 0,
  productionNo: '',
  productionName: '',
  productionNameHankakukana: '',
};

export const defaultProductionEdit: ProductionEditResponse = {
  productionContent: defaultProductionEditModel,
};
