import useShowMakingSpecification from 'hooks/useShowMakingSpecification';
import * as React from 'react';

import { Box, Container } from '@mui/material';
import { GridColDef, GridCellParams } from '@mui/x-data-grid-pro';

import MessageListener from 'components/Message/MessageListener';
import { DataGrid, Heading, Typography, BodyLink } from 'components/parts/atoms';
import { NewWindow } from 'components/parts/atoms/NewWindow';
import { TableCount } from 'components/parts/molecules';

import { dateText } from 'utils/date';

import useFetchDocumentSearchList from './hooks/useFetchDocumentSearchList';

type Props = {
  jobNo: string;
  onClose: () => void;
};

const DocumentSearchList: React.VFC<Props> = (props) => {
  const { allLoading, documentsData, messageApi } = useFetchDocumentSearchList(props.jobNo);
  const documentsCount = documentsData.length;

  // 製版指示書表示Hooks
  const { showMakingSpecification } = useShowMakingSpecification();

  //JOBNOリンク_製版指示書の表示
  const onJobNoClickHandler = (jobId: number) => {
    showMakingSpecification('C', jobId);
  };

  const columns: GridColDef[] = [
    {
      field: 'shelfAndRackNo',
      headerName: '棚番号',
      flex: 1,
      sortable: false,
    },
    {
      field: 'jobNo',
      headerName: 'JOB NO',
      flex: 1.5,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <BodyLink
          onClick={() => {
            onJobNoClickHandler(params.row.jobId);
          }}
        >
          {params.row.jobNo}
        </BodyLink>
      ),
    },
    {
      field: 'inDate',
      headerName: '入庫日',
      flex: 1,
      sortable: false,
      align: 'center',
      renderCell: (params: GridCellParams) => <>{dateText(params.row.inDate)}</>,
    },
    { field: 'customerOrderNo', headerName: '得意先発注No.', flex: 1.5, sortable: false },
    { field: 'customerName', headerName: '得意先', flex: 3, sortable: false },
    { field: 'productNameForDisplay', headerName: '品名', flex: 3, sortable: false },
  ];

  return (
    <>
      <NewWindow features={{ height: 720, width: 1280 }} onUnload={props.onClose}>
        <Container maxWidth='lg'>
          <Box m={2} />
          <MessageListener messageApi={messageApi} />
          <Box m={2} />
          <Heading>書類棚管理検索結果一覧</Heading>
          <Box m={2} />
          {/* <Typography>検索結果は{documentsCount}件です。</Typography> */}
          <TableCount count={documentsCount} />
          <Box m={2} />

          <DataGrid
            rows={documentsData ?? []}
            getRowId={(row) => row.jobId}
            columns={columns}
            pagination={true}
            pageSize={10}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            loading={allLoading}
          />

          <Box m={2} />

          <Typography>
            ＊原稿袋の検索につきましては、旧JOBNo.の整合性を取ることが不可能な為、意図しない検索結果が表示されることがありますがご了承ください。
          </Typography>
          <Box m={2} />
        </Container>
      </NewWindow>
    </>
  );
};

export default DocumentSearchList;
