import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FlexoOrderMetaData } from 'api/types/topSales/order/flexoOrderEdit';

import { InitialFlexoOrderManagerState } from './defaults';
import { FlexoOrderManagerState } from './types';

const slice = createSlice({
  name: 'flexoOrderManager',
  initialState: InitialFlexoOrderManagerState,
  reducers: {
    save: (state, action: PayloadAction<FlexoOrderManagerState>) => action.payload,
    saveMetaData: (state, action: PayloadAction<FlexoOrderMetaData>) => {
      if (state.data) {
        state.data.orderMetaData = action.payload;
      }
    },
    clear: () => ({ ...InitialFlexoOrderManagerState }),
  },
});

export const { save, saveMetaData, clear } = slice.actions;

export default slice.reducer;
