import { useReverseMakingPriceRow } from '../priceRow/useMakingPriceRow';

/** 印刷面(リバース)計算 Hooks */
export const useReverseMakingPriceTotal = (): { price: number; grossInvoice: number } => {
  const betaHan = useReverseMakingPriceRow('betaHan');
  const halfTone = useReverseMakingPriceRow('halfTone');
  const barrier = useReverseMakingPriceRow('barrier');
  const negaFilm = useReverseMakingPriceRow('negaFilm');
  const posi = useReverseMakingPriceRow('posi');
  const singleFaceNega = useReverseMakingPriceRow('singleFaceNega');
  const composer = useReverseMakingPriceRow('composer');
  const matNega = useReverseMakingPriceRow('matNega');
  const plate = useReverseMakingPriceRow('plate');

  const rows = [
    betaHan,
    halfTone,
    barrier,
    negaFilm,
    posi,
    singleFaceNega,
    composer,
    matNega,
    plate,
  ];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
