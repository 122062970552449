import React from 'react';

import { HeadLabel } from 'components/parts/atoms';

import { HeadRow } from './Row';

/** 製版価格 行ヘッダーコンポーネント */
const MakingOptionPriceRowHeader: React.VFC = () => {
  return (
    <HeadRow>
      <></>
      <HeadLabel>製版代</HeadLabel>
      <HeadLabel>加算倍率</HeadLabel>
      <HeadLabel>版数</HeadLabel>
      <HeadLabel>売価</HeadLabel>
      <HeadLabel>東洋売価率</HeadLabel>
      <HeadLabel>仕切</HeadLabel>
    </HeadRow>
  );
};

export default MakingOptionPriceRowHeader;
