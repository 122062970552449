import { useUnitPriceRow } from '../priceRow/useUnitPriceRow';
import { useUnitPriceWithRateFlgRow } from '../priceRow/useUnitPriceWithRateFlgRow';

/** 単価計算 集計情報 Hooks */
export const useUnitPriceTotal = (): { price: number; grossInvoice: number } => {
  const dataMake = useUnitPriceRow('dataMake');
  const dataEdit = useUnitPriceRow('dataEdit');
  const layoutData = useUnitPriceRow('layoutData');
  const colorProcess = useUnitPriceRow('colorProcess');
  const photoRetouch = useUnitPriceRow('photoRetouch');
  const blockCopy = useUnitPriceRow('blockCopy');
  const fare = useUnitPriceWithRateFlgRow('fare');

  const rows = [dataMake, dataEdit, layoutData, colorProcess, photoRetouch, blockCopy, fare];

  let price = 0;
  let grossInvoice = 0;

  for (const row of rows) {
    price += row.price;
    grossInvoice += row.grossInvoice;
  }

  return {
    price,
    grossInvoice,
  };
};
