import * as React from 'react';

import { NoteAdd } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type CreateButtonProps = CustomButtonProps;

const CreateButton: React.VFC<CreateButtonProps> = (props) => {
  return (
    <CustomButton icon={<NoteAdd />} {...props}>
      {'作成'}
    </CustomButton>
  );
};

export default CreateButton;
