import * as apiTypePostSearch from 'api/types/common/postSearchResult';

import { DeliveryPlaceEdit } from '../CustomerEdit/types';

/** ページ区分 */
export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];

/** 納入先入力・確認メインのprops */
export type MainProps = {
  index: number;
  state: DeliveryPlaceEdit;
  open: boolean;
  onClose: (value?: DeliveryPlaceEdit) => void;
  checkFinishFlag: boolean;
};

/** 納入先入力props */
export type EditProps = {
  onBack: () => void;
  onGoCheck: () => void;
};

/** 納入先入力確認props */
export type EditCheckProps = {
  onBack: () => void;
  onAddForm: () => void;
  checkFinishFlag: boolean;
};

/** 入力チェック */
export type InputCheck = {
  deliveryPlaceName: string;
  zipCd: string;
  tel: string;
};

/** 郵便番号検索 */
export type PostSearch = apiTypePostSearch.PostSearchResultModel;
