import { FaceDiv, PlateMakingCode } from 'constants/define';
import useMessage from 'hooks/useMessage';
import * as React from 'react';
import { useEffect } from 'react';
import theme from 'themes/theme';

import { Box } from '@mui/material';

import { PostSearchResultModel } from 'api/types/common/postSearchResult';

import { HeadLabel, BodyLabel, CloseButton } from 'components/parts/atoms';
import { RowGrid, HeadGrid, BodyGrid } from 'components/parts/atoms/Grid';
import { Dialog, FooterButtonsContainer } from 'components/parts/molecules';

import useFetcCylinderDetail from './hooks/useFetcCylinderDetail';

type Props = {
  jobId: number;
  open: boolean;
  onClose: (value?: PostSearchResultModel) => void;
};

const CylinderDetail: React.VFC<Props> = (props) => {
  const messageApi = useMessage();
  const { cylinderDetail, getCylinderDetail } = useFetcCylinderDetail(messageApi.pushMessages);
  const lightGray = theme.palette.cyclonistLightGrey?.main;

  useEffect(() => {
    if (props.open) {
      getCylinderDetail(props.jobId);
    } else {
      messageApi.clear();
    }
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
    messageApi.clear();
  };

  // 刷順の背景色取得
  const getPrintSequenceBgColor = (plateMakingCd: number) => {
    switch (plateMakingCd) {
      case FaceDiv.Reverse:
        return theme.palette.cyclonistLightGrey?.main;
      default:
        return theme.palette.cyclonistDarkGrey?.main;
    }
  };

  // 刷順の文字色取得
  const getPrintSequenceColor = (plateMakingCd: number) => {
    switch (plateMakingCd) {
      case FaceDiv.Reverse:
        return theme.palette.cyclonistBlack.main;
      default:
        return theme.palette.primary.contrastText;
    }
  };

  // 刷順2の背景色取得
  const getPrintSequence2BgColor = (plateMakingCd: number) => {
    switch (plateMakingCd) {
      case FaceDiv.Reverse:
        return theme.palette.cyclonistDarkGrey?.main;
      default:
        return theme.palette.cyclonistLightGrey?.main;
    }
  };

  // 刷順2の文字色取得
  const getPrintSequence2Color = (plateMakingCd: number) => {
    switch (plateMakingCd) {
      case FaceDiv.Reverse:
        return theme.palette.primary.contrastText;
      default:
        return theme.palette.cyclonistBlack.main;
    }
  };

  // 合・調の背景色取得
  const getAwaseChosaKubunBgColor = (plateMakingCd: string) => {
    switch (plateMakingCd) {
      case PlateMakingCode.AwaseDiv:
        return theme.palette.cyclonistYellow?.main;
      case PlateMakingCode.ChousaDiv:
        return theme.palette.cyclonistChousahanPink?.main;
      default:
        return;
    }
  };

  // 行の背景色取得
  const getLineBgColor = (index: number) => {
    if (index % 2 === 0) {
      return theme.palette.background.default;
    } else {
      return theme.palette.defaultStripe.main;
    }
  };

  return (
    <Dialog
      open={props.open}
      title={`シリンダー情報`}
      onClose={handleClose}
      messageApi={messageApi}
    >
      <RowGrid columns={30}>
        <HeadGrid
          xs={2}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>刷順</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={2}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>刷色</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={2}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>区分</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={2}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>合・調</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={2}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>摘要</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={4}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>バーコード</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={4}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>TK_パンチ</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={4}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>TP_ABC/刻印No.</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={4}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>刻印No.1</HeadLabel>
        </HeadGrid>
        <HeadGrid
          xs={4}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
          }}
        >
          <HeadLabel>刻印No.2</HeadLabel>
        </HeadGrid>
      </RowGrid>

      {/* apiで取得したデータをreact hook formのreset関数でセットしたものを表示 */}
      {cylinderDetail.map((cylinder, index) => {
        return (
          <>
            <RowGrid columns={30}>
              {/* 刷順1 */}
              <BodyGrid
                xs={1}
                sx={{
                  justifyContent: 'center',
                  backgroundColor: getPrintSequenceBgColor(cylinder.faceDiv),
                }}
              >
                <BodyLabel
                  sx={{
                    color: getPrintSequenceColor(cylinder.faceDiv),
                  }}
                >
                  {cylinder.printSequence}
                </BodyLabel>
              </BodyGrid>

              {/* 刷順2 */}
              <BodyGrid
                xs={1}
                sx={{
                  justifyContent: 'center',
                  backgroundColor: getPrintSequence2BgColor(cylinder.faceDiv),
                }}
              >
                <BodyLabel
                  sx={{
                    color: getPrintSequence2Color(cylinder.faceDiv),
                  }}
                >
                  {cylinder.printSequence2}
                </BodyLabel>
              </BodyGrid>

              {/* 刷色 */}
              <BodyGrid xs={2} sx={{ backgroundColor: lightGray }}>
                <BodyLabel>{cylinder.printColor}</BodyLabel>
              </BodyGrid>

              {/* 区分 */}
              <BodyGrid xs={2} sx={{ backgroundColor: lightGray }}>
                <BodyLabel>{cylinder.kubun}</BodyLabel>
              </BodyGrid>

              {/* 合版・調査版区分文字列 */}
              <BodyGrid
                xs={2}
                sx={{
                  justifyContent: 'center',
                  backgroundColor: getAwaseChosaKubunBgColor(cylinder.plateMakingCd),
                }}
              >
                <BodyLabel>{cylinder.awaseChousa}</BodyLabel>
              </BodyGrid>
              {/* 摘要 */}
              <BodyGrid xs={2} sx={{ backgroundColor: lightGray }}>
                <BodyLabel>{cylinder.summaryCd}</BodyLabel>
              </BodyGrid>
              {/* バーコード */}
              <BodyGrid xs={4} sx={{ backgroundColor: getLineBgColor(index) }}>
                <BodyLabel>{cylinder.ictagNo}</BodyLabel>
              </BodyGrid>
              {/* TK_パンチ */}
              <BodyGrid xs={4} sx={{ backgroundColor: getLineBgColor(index) }}>
                <BodyLabel>{cylinder.tkPunch}</BodyLabel>
              </BodyGrid>
              {/* TP_ABC/刻印No. */}
              <BodyGrid xs={4} sx={{ backgroundColor: getLineBgColor(index) }}>
                <BodyLabel>{cylinder.tpAbcCd}</BodyLabel>
              </BodyGrid>
              {/* 刻印No.1 */}
              <BodyGrid xs={4} sx={{ backgroundColor: getLineBgColor(index) }}>
                <BodyLabel>{cylinder.stampNo1}</BodyLabel>
              </BodyGrid>
              {/* 刻印No.2 */}
              <BodyGrid xs={4} sx={{ backgroundColor: getLineBgColor(index) }}>
                <BodyLabel>{cylinder.stampNo2}</BodyLabel>
              </BodyGrid>
            </RowGrid>
          </>
        );
      })}

      <Box m={2} />
      <FooterButtonsContainer>
        <CloseButton onClick={handleClose} />
      </FooterButtonsContainer>
    </Dialog>
  );
};

export default CylinderDetail;
