import { useEffect } from 'react';

import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { useResetPrice } from '../useResetPrice';

/** シリンダ単価変更時にフォームをリセットするHooks */
export const useUnitPriceWatcher = (enabled: boolean): void => {
  const { othUnitPrice } = usePriceCalculateMatter();
  const { resetUnitPrice } = useResetPrice();

  useEffect(() => {
    if (!enabled) return;

    resetUnitPrice(othUnitPrice);
  }, [othUnitPrice]);
};
