import useApiClient from 'hooks/useApiClient';
import { MessageApi } from 'hooks/useMessage';
import { useState } from 'react';

import { SearchRes, Customer, SearchReq } from 'api/types/common/customerSearch';

export interface OrderDataApi {
  importCsv: (text: string) => void;
}

const useCustomers = (
  pushMessages: MessageApi['pushMessages'],
): {
  customers: Customer[];
  search: (condition: SearchReq) => void;
  clear: () => void;
  loading: boolean;
} => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const { apiClient, loading } = useApiClient(pushMessages);

  const search = async (condition: SearchReq): Promise<void> => {
    try {
      const response = await apiClient.post<SearchRes>(`/api/v1/CustomerSearch/Search`, condition);
      setCustomers(response.data.result?.customers ?? []);
    } catch (e) {
      console.log(e);
    }
    return;
  };

  const clear = () => {
    setCustomers([]);
  };

  return { customers, search, clear, loading };
};

export default useCustomers;
