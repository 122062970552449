import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OtherOrderEditForm } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/types';

import { InitialOtherOrderEditState } from './defaults';
import { OtherOrderEditState } from './types';

const slice = createSlice({
  name: 'otherOrderEdit',
  initialState: InitialOtherOrderEditState,
  reducers: {
    save: (state, action: PayloadAction<OtherOrderEditState>) => action.payload,
    saveForm(state, action: PayloadAction<OtherOrderEditForm>) {
      if (state.data) {
        state.data.form = action.payload;
      }
    },
    clear: () => ({ ...InitialOtherOrderEditState }),
    setData(state, action: PayloadAction<OtherOrderEditState['data']>) {
      state.data = action.payload;
    },
    setPageState(state, action: PayloadAction<OtherOrderEditState['pageState']>) {
      state.pageState = action.payload;
    },
    setTabIndex(state, action: PayloadAction<OtherOrderEditState['tabIndex']>) {
      state.tabIndex = action.payload;
    },
  },
});

export const { save, saveForm, clear, setData, setPageState, setTabIndex } = slice.actions;

export default slice.reducer;
