import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { InformationsRes, WorkPlanCountInfoRes } from 'api/types/portalTop/portal';

import Informations from './components/Informations';
import TodayMakingPlan from './components/TodayMakingPlan';
import { Circular } from 'components/parts/atoms';

const Container = styled(Box)(() => ({}));

const Portal: React.VFC = () => {
  const [workPlanCountInfo, setWorkPlanCountInfo] = useState<WorkPlanCountInfoRes | null>(null);
  const [informations, setInformations] = useState<InformationsRes | null>(null);
  const { pushMessages, clear } = useSharedMessage();
  const { apiClient, loading } = useApiClient(pushMessages);

  useEffect(() => {
    (async () => {
      try {
        const [workPlanCountInfoRes, informationsRes] = await Promise.all([
          apiClient.get<WorkPlanCountInfoRes>(`/api/v1/Portal/WorkPlanCountInfo`),
          apiClient.get<InformationsRes>(`/api/v1/Portal/Informations`),
        ]);
        setWorkPlanCountInfo(workPlanCountInfoRes.data.result);
        setInformations(informationsRes.data.result);
      } catch (e) {
        console.log(e);
        return;
      }
    })();

    return () => {
      clear();
    };
  }, []);

  if (loading) {
    return <Circular />;
  }

  return (
    <>
      <Container>
        <TodayMakingPlan workPlanCountInfo={workPlanCountInfo} />
        <Box my={6}></Box>
        <Informations informations={informations} />
      </Container>
    </>
  );
};

export default Portal;
