import React, { useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { Box } from '@mui/material';

import { useId } from 'pages/topSales/order/OtherOrder/OtherOrderEdit/hooks/useId';

import { HeadLabel } from 'components/parts/atoms';

import { OtherOrderEditForm, OtherProductInfo as OtherProductInfoType } from '../../../types';
import { Heading } from '../../Heading';
import { PriceIconButton, PriceNumericField } from '../Fields';
import { RowOne } from '../Rows';
import OtherProductInfoRow from './OtherProductInfoRow';
import { HeadRow } from './Row';
import TotalRow from './TotalRow';

/** その他製品情報コンポーネント */
const OtherProductInfo: React.VFC<{ isFixedItems: boolean }> = ({ isFixedItems }) => {
  const { control } = useFormContext<OtherOrderEditForm>();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'price.otherProductInfos',
  });

  const { generateId } = useId();

  /** 行追加 */
  const onAddRow = useCallback(() => {
    const addRow: OtherProductInfoType = {
      othProductInfoId: generateId(),
      itemName: '',
      price: 0,
      toyoPriceRateFlg: true,
      count: null,
    };
    append(addRow);
  }, [append, generateId]);

  /**
   * 行削除
   * @param index インデックス
   */
  const onDeleteRow = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <>
      <Heading>合計</Heading>

      <RowOne>
        <Box sx={{ display: 'flex' }}>
          <HeadLabel>東洋売価率</HeadLabel>
        </Box>
        <Controller
          name={'price.toyoPriceRate'}
          control={control}
          render={({ field }) => (
            <PriceNumericField
              min={0}
              max={99.999}
              decimalDigits={3}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '6em' }}
              labelWidth={'shrink'}
            />
          )}
        />
      </RowOne>

      <Box my={2} />

      <HeadRow>
        <HeadLabel>項目</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
        <HeadLabel>数量</HeadLabel>
        <HeadLabel>売価計</HeadLabel>
        <HeadLabel>仕切計</HeadLabel>
        {!isFixedItems && (
          <PriceIconButton color='primary' onClick={() => onAddRow()}>
            <LibraryAddIcon />
          </PriceIconButton>
        )}
        {isFixedItems && <></>}
      </HeadRow>
      {fields.map((item, index) => {
        return (
          <OtherProductInfoRow
            key={item.id}
            index={index}
            isFixedItems={isFixedItems}
            onDeleteClicked={() => onDeleteRow(index)}
          />
        );
      })}

      <TotalRow />
    </>
  );
};

export default React.memo(OtherProductInfo);
