import React from 'react';

import { useUnitPriceCylMakingRow } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/hooks/price/priceRow/useUnitPriceCylMakingRow';

import MakingOptionPriceRow, { MakingOptionPriceRowProps } from '../Common/MakingOptionPriceRow';

type Props = Omit<MakingOptionPriceRowProps, 'pricePropertyName' | 'cylMakingPrice'>;

/** フルコース、 改版、増版 行コンポーネント */
const NormalOptionPriceRow: React.VFC<Props> = (props) => {
  const { price } = useUnitPriceCylMakingRow();
  return (
    <MakingOptionPriceRow
      label={props.label}
      pricePropertyName='normalPrice'
      rowPropertyName={props.rowPropertyName}
      cylMakingPrice={price}
    />
  );
};

export default React.memo(NormalOptionPriceRow);
