import useSharedMessage from 'hooks/useSharedMessage';
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Container } from '@mui/material';

import ApprovalListSearch from './components/PriceApprovalListSearch';
import ApprovalList from './components/PriceApprovalListTable';

import { usePriceApprovalListSearchCondition } from '../../../store/priceApproval/hooks';
import { defaultSearchCondition } from './defaults';
import { usePriceApprovalListFetch } from './hooks/usePriceApprovalListFetch';
import { SearchCondition } from './types';

const PriceApprovalList: React.VFC = () => {
  const messageApi = useSharedMessage();
  // 検索条件store
  const priceApprovalListSearchConditionStore = usePriceApprovalListSearchCondition();
  // 検索flag 検索or初期化で使用
  const history = useHistory();
  // 入力フォームの取得
  const methods = useForm<SearchCondition>({ defaultValues: defaultSearchCondition });
  // hooks
  const {
    loading,
    searchLoading,
    displayData,
    initialize,
    searchApprovalList,
    fetchUpdateData,
    handleUpdateData,
    dailyProcessDate,
  } = usePriceApprovalListFetch(messageApi.pushMessages);

  // 初期表示データ取得
  useEffect(() => {
    initialize();
  }, []);

  /**
   * 検索ボタン押下時処理
   */
  const onSearchApprovalList = methods.handleSubmit(async (searchCondition: SearchCondition) => {
    // メッセージをクリア
    messageApi.clear();
    // 検索実行
    await searchApprovalList(searchCondition);
    // 検索条件をstoreに保存
    priceApprovalListSearchConditionStore.save(searchCondition);
    history.replace('/PriceApprovalList', {});
  });

  // 更新データを取得して子コンポーネントにdataとして渡す
  const onSearchApprovalListByStore = async (jobid: number | null) => {
    if (jobid === null) return;

    // メッセージをクリア
    messageApi.clear();
    // 新しいデータの取得のみを行う
    const updateData = await fetchUpdateData(jobid);
    if (updateData) {
      handleUpdateData(updateData);
    }
  };

  // ダイアログのクローズボタン押下時処理
  const onDialogClose = () => {
    // メッセージをクリア
    messageApi.clear();
  };

  return (
    <>
      <Container maxWidth={'lg'}>
        <FormProvider {...methods}>
          <ApprovalListSearch
            onSearchPriceApprovalList={onSearchApprovalList}
            searchLoading={searchLoading}
            dailyProcessDate={dailyProcessDate}
          />
        </FormProvider>
        <ApprovalList
          displayData={displayData}
          priceApprovalList={displayData.priceApprovalList}
          listCount={displayData.listCount}
          loginUserFamilyName={displayData.loginUserFamilyName}
          loginUserEmployeeId={displayData.loginUserEmployeeId}
          approvalPermissionFlg={displayData.approvalPermissionFlg}
          loading={loading}
          onDialogClose={onDialogClose}
          searchLoading={searchLoading}
          onApproved={onSearchApprovalListByStore}
        ></ApprovalList>
      </Container>
    </>
  );
};

export default PriceApprovalList;
