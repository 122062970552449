import React from 'react';

import { Box } from '@mui/material';

import { AttentionLabel } from 'components/parts/atoms';

import { useCylinderMakingsAggregation } from '../../hooks/useCylinderMakingsAggregation';

/** 注意事項 */
export const Notice: React.VFC = () => {
  const { hasFreeCylinderWithoutAwaseChousa } = useCylinderMakingsAggregation();

  return (
    <Box>
      {hasFreeCylinderWithoutAwaseChousa && <AttentionLabel>無償のデータがあります</AttentionLabel>}
    </Box>
  );
};
