import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { CylinderOrderEditForm } from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import { BodyGrid, BodyLabel, HeadGrid, HeadLabel, RowGrid } from 'components/parts/atoms';

/** 承認コンポーネント */
const Approval: React.VFC = () => {
  // コンテキスト
  const formContext = useFormContext<CylinderOrderEditForm>();
  const approvalName = formContext.getValues('price.approvalName') ?? '';
  const secondApprovalName = formContext.getValues('price.secondApprovalName') ?? '';
  const approvalDate = formContext.getValues('price.approvalDate') ?? '';
  const secondApprovalDate = formContext.getValues('price.secondApprovalDate') ?? '';

  return (
    <>
      <Box>
        <RowGrid sx={{ justifyContent: 'right', alignItems: 'center' }}>
          <HeadGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <HeadLabel variant='body1'>第二承認</HeadLabel>
          </HeadGrid>
          <HeadGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <HeadLabel variant='body1'>第一承認</HeadLabel>
          </HeadGrid>
        </RowGrid>
        <RowGrid sx={{ justifyContent: 'right', alignItems: 'center' }}>
          <BodyGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <BodyLabel variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
              {secondApprovalName == '' ? '未承認' : secondApprovalName}
            </BodyLabel>
          </BodyGrid>
          <BodyGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <BodyLabel variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
              {approvalName == '' ? '未承認' : approvalName}
            </BodyLabel>
          </BodyGrid>
        </RowGrid>
        <RowGrid sx={{ justifyContent: 'right', alignItems: 'center' }}>
          <HeadGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <HeadLabel variant='body1'>第二承認日</HeadLabel>
          </HeadGrid>
          <HeadGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <HeadLabel variant='body1'>第一承認日</HeadLabel>
          </HeadGrid>
        </RowGrid>
        <RowGrid sx={{ justifyContent: 'right', alignItems: 'center' }}>
          <BodyGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <BodyLabel variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
              {secondApprovalDate == '' ? ' ' : secondApprovalDate}
            </BodyLabel>
          </BodyGrid>
          <BodyGrid xs={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <BodyLabel variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
              {approvalDate == '' ? ' ' : approvalDate}
            </BodyLabel>
          </BodyGrid>
        </RowGrid>
      </Box>
    </>
  );
};

export default React.memo(Approval);
