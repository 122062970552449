import { MessageApi } from 'hooks/useMessage';
import React from 'react';

import { MessageContainer } from 'components/Message';

type Props = {
  messageApi: MessageApi;
};

const MessageListener: React.FC<Props> = (props) => {
  const messages = props.messageApi.messages;
  const handleRemove = (id: string) => props.messageApi.remove(id);

  return <MessageContainer messages={messages} onRemove={handleRemove} />;
};

export default MessageListener;
