import * as apiType from 'api/types/topImage/imageWorkEditHistoryLayout';

export type ImageWorkEditLayoutMasterResponse = apiType.ImageWorkEditLayoutMasterResponse;
export type ImageWorkEditLayoutDataResponse = apiType.ImageWorkEditLayoutDataResponse;
export type ImageWorkEditLayoutWorkUpdateRequest = apiType.ImageWorkEditLayoutWorkUpdateRequest;
export type ImageReferenceJobInputRequest = apiType.ImageReferenceJobInputRequest;
export type ImageTemplateInputRequest = apiType.ImageTemplateInputRequest;

export const PageState = {
  Edit: 'Edit',
  Check: 'Check',
} as const;
export type PageState = typeof PageState[keyof typeof PageState];
