import React from 'react';

import { Box } from '@mui/material';

import MekkiAndRioseru from './components/mekkiAndRioseru';
import OtherBasicInfos from './components/otherBasicInfos';
import USheet from './components/uSheet';
import { BackButton, ConfirmButton } from 'components/parts/atoms';
import { FooterButtonsContainer } from 'components/parts/molecules/FooterButtonsContainer';

import { OtherEditCheckProps } from '../types';

const UnitPriceEditCheckOther: React.VFC<OtherEditCheckProps> = (props) => {
  return (
    <>
      <OtherBasicInfos />

      <Box m={2} />

      <MekkiAndRioseru />

      <Box m={2} />

      <USheet />

      <FooterButtonsContainer>
        <BackButton onClick={props.onBack} />
        {props.checkFinishFlag === false && <ConfirmButton onClick={props.onAddForm} />}
      </FooterButtonsContainer>
    </>
  );
};

export default UnitPriceEditCheckOther;
