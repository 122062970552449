import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useCalculateMethodWatcher } from '../hooks/price/priceWatcher/useCalculateMethodWatcher';
import { usePlateMakingWatcher } from '../hooks/price/priceWatcher/usePlateMakingWatcher';
import { usePriceRateWatcher } from '../hooks/price/priceWatcher/usePriceRateWatcher';
import { useTotalPriceWatcher } from '../hooks/price/priceWatcher/useTotalPriceWatcher';
import { useUnitPriceWatcher } from '../hooks/price/priceWatcher/useUnitPriceWatcher';
import { useFormSetState } from '../hooks/useFormSetState';
import { FlexoOrderEditForm } from '../types';

/** 製版情報フォーム監視コンポーネント */
const MakingWatcher: React.VFC<{
  index: number;
  propertyName: 'flexoMakingsMain' | 'flexoMakingsReverse';
  enabled: boolean;
}> = (props) => {
  // 計算方法, シリンダー単価変更時に値をセットするhook
  usePlateMakingWatcher(props.propertyName, props.index, props.enabled);
  return <></>;
};

/** 製版情報フォーム監視コンポーネント */
const MakingsWatcher: React.VFC = () => {
  const { control } = useFormContext<FlexoOrderEditForm>();
  const [flexoMakingsMain, flexoMakingsReverse] = useWatch({
    control,
    name: ['making.flexoMakingsMain', 'making.flexoMakingsReverse'],
  });
  const { afterFormSet } = useFormSetState();

  return (
    <>
      {flexoMakingsMain.map((making, index) => {
        return (
          <MakingWatcher
            key={making.flxMakingId}
            propertyName='flexoMakingsMain'
            index={index}
            enabled={afterFormSet}
          />
        );
      })}
      {flexoMakingsReverse.map((making, index) => {
        return (
          <MakingWatcher
            key={making.flxMakingId}
            propertyName='flexoMakingsReverse'
            index={index}
            enabled={afterFormSet}
          />
        );
      })}
    </>
  );
};

/** 価格情報フォーム監視コンポーネント */
export const PriceWatcher: React.VFC = () => {
  const { afterFormSet } = useFormSetState();

  // 計算方法, フレキソ単価変更時に値をセットするhook
  useCalculateMethodWatcher(afterFormSet);
  useUnitPriceWatcher(afterFormSet);
  usePriceRateWatcher(afterFormSet);
  useTotalPriceWatcher();

  return (
    <>
      <MakingsWatcher />
    </>
  );
};
