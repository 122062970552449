import { Region } from 'constants/define';
import * as React from 'react';

import Login from '../Login';

const LoginHokkaido: React.VFC = () => {
  return <Login region={Region.Hokkaido} />;
};

export default LoginHokkaido;
