import * as React from 'react';

import { Check } from '@mui/icons-material';

import CustomButton, { CustomButtonProps } from './CustomButton';

export type ConfirmuttonProps = CustomButtonProps;

const Confirmutton: React.VFC<ConfirmuttonProps> = (props) => {
  return (
    <CustomButton icon={<Check />} color={'primary'} {...props}>
      {'確認'}
    </CustomButton>
  );
};

export default Confirmutton;
