import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultState } from './defaults';
import { MenuListSearchForm } from './types';

const slice = createSlice({
  name: 'SearchForm',
  initialState: defaultState,
  reducers: {
    save: (state, action: PayloadAction<MenuListSearchForm>) => action.payload,
    clear: () => ({ ...defaultState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
