import { ImageWorkProcessTypes } from 'constants/define';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImageDataDownload } from 'store/imageServer/imageDataDownload/hooks';
import { ImageDataDownloadState } from 'store/imageServer/imageDataDownload/types';
import theme from 'themes/theme';

import { Download } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridColumns, useGridApiRef } from '@mui/x-data-grid-pro';

import { ImageInfoResponse } from 'api/types/topEngraving/engravingData';

import {
  AttentionLabel,
  BodyLabel,
  Button,
  Heading,
  HeadLabel,
  IconButton,
} from 'components/parts/atoms';
import { DataGrid, BodyLink, Typography } from 'components/parts/atoms';
import { RowGrid, BodyGrid, HeadGrid } from 'components/parts/atoms/Grid';

// hooks
import { useFetchImageInfo } from './hooks';
// types
import { ImageInfoProps, LinkProps } from './types';

/**
 * 画像作業工程リンク用コンポーネント
 * @param {boolean} リンクにするかしないかのフラグ
 * @param {string} リンク用文字列
 * @returns
 */
const ImageLinkCreater: React.VFC<LinkProps> = ({
  isWorkProcess,
  title,
  funcOnClick,
}): JSX.Element => {
  return isWorkProcess ? (
    <BodyLink onClick={funcOnClick}>
      <Typography>{title}</Typography>
    </BodyLink>
  ) : (
    <Typography>{title}</Typography>
  );
};

/**
 * 画像情報コンポーネント
 * @param
 * @returns
 */
const ImageInfo: React.VFC<ImageInfoProps> = ({
  jobId,
  defaultOpenFlg,
  url,
  cylMakingDirectionOnState,
}): JSX.Element => {
  /**
   * カスタムフック
   */
  const { getImageInfoState, imageInfoState, isWorkProcess, getWorkReportId } = useFetchImageInfo();
  const api = useGridApiRef();
  const history = useHistory();
  const imageDataDownload = useImageDataDownload();

  /**
   * 初期表示用画像情報取得処理
   */
  useEffect(() => {
    getImageInfoState(jobId);
  }, []);

  /** APIの結果がnullだったときの初期値 */
  const emptyImageInfo: ImageInfoResponse = {
    imageDataDirection: null,
    bunshokuList: [],
    otherWorkProcessList: [],
  };

  const ImageInfo = imageInfoState ?? emptyImageInfo;
  const { imageDataDirection, bunshokuList, otherWorkProcessList } = ImageInfo;

  /**
   * コンポーネントを開くか閉じるかのフラグ。デフォルト値はプロップスで受け取る
   */
  const [openFlg, setOpenFlg] = useState<boolean>(defaultOpenFlg);
  /**
   * 画像情報open/closeフラグ管理メソッド
   */
  const turnImageInfo = (): void => {
    setOpenFlg(!openFlg);
  };

  /** ファイルダウンロードイベント */
  const onDownloadFolder = () => {
    const downloadState: ImageDataDownloadState['processTarget'] = {
      jobId: jobId,
      dataTypeNos: [],
    };

    if (url) {
      // パラメータURLが設定されている場合はURLを遷移元にセット
      const fromPageState: ImageDataDownloadState['fromPage'] = {
        url: url ?? '',
        jobId: jobId,
      };

      imageDataDownload.setFromPage(fromPageState);
    }

    imageDataDownload.setProcessTarget(downloadState);
    history.push('/ImageDataDownloadFolder/');
  };

  const windowsize = 'width=880,height=700,toolbar=yes,menubar=yes,scrollbars=yes';

  /**
   * 画像作業記録入力参照表示メソッド
   */
  const openImageWindow = (title: string) => {
    switch (title) {
      case 'デザイン':
        window.open(`/ImageWorkReportDetailDesignSub/${jobId}`, '', windowsize);
        break;
      case '分解':
        window.open(`/ImageWorkReportDetailPhotoRetouchSub/${jobId}`, '', windowsize);
        break;
      case '色分け':
        window.open(`/ImageWorkReportDetailColorSeparationSub/${jobId}`, '', windowsize);
        break;
      case '提出物':
        window.open(
          `/ImageWorkReportDetailPresentSub/${jobId}/${getWorkReportId(otherWorkProcessList)}`,
          '',
          windowsize,
        );
        break;
      case '検版':
        window.open(`/ImageWorkReportDetailInspectionSub/${jobId}`, '', windowsize);
        break;
      case '下版':
        window.open(`/ImageWorkReportDetailLayoutSub/${jobId}`, '', windowsize);
        break;
    }
  };

  /**
   * 画像情報部グリッドデザイン
   */
  const columns: GridColumns = [
    { field: 'id', headerName: 'No', headerClassName: 'header', flex: 1, hide: true },
    {
      field: 'printSequence',
      headerName: '刷順',
      headerClassName: 'header',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <RowGrid columns={2}>
            <BodyGrid
              xs={1}
              sx={{
                outline: '0px',
              }}
            >
              <Typography>{params.row.printSequence}</Typography>
            </BodyGrid>
            <BodyGrid
              xs={1}
              sx={{
                outline: '0px',
              }}
            >
              <Typography>{params.row.printSequence2}</Typography>
            </BodyGrid>
          </RowGrid>
        );
      },
    },
    {
      field: 'printColor',
      headerName: '刷色',
      headerClassName: 'header',
      flex: 1,
      editable: false,
    },
    {
      field: 'printType',
      headerName: '版式',
      headerClassName: 'header',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <RowGrid columns={1}>
            <BodyGrid
              xs={1}
              sx={{
                outline: '0px',
              }}
            >
              <Typography>
                {params.row.cylMakingType1}
                {'　'}
                {params.row.cylMakingType2}
              </Typography>
            </BodyGrid>
          </RowGrid>
        );
      },
    },
    {
      field: 'bunshoku',
      headerName: '分色',
      headerClassName: 'header',
      flex: 1,
      editable: false,
    },
    {
      field: 'colorTip',
      headerName: 'カラーチップ・特色',
      headerClassName: 'header',
      flex: 10,
      editable: false,
      renderCell: (params) => {
        return (
          <RowGrid columns={2}>
            <BodyGrid
              xs={1}
              sx={{
                outline: '0px',
              }}
            >
              <Typography></Typography>
            </BodyGrid>{' '}
            <BodyGrid
              xs={1}
              sx={{
                outline: '0px',
              }}
            >
              <Typography>{params.row.colorTip}</Typography>
            </BodyGrid>
          </RowGrid>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
        <Button onClick={turnImageInfo}>{openFlg ? 'CLOSE' : 'OPEN'}</Button>
      </Box>
      <Box m={2} />

      {openFlg ? (
        <>
          <Typography>画像情報</Typography>
          <Typography>画像データ指示</Typography>
          <Box m={2} />

          {/* 画像作成情報ヘッダー */}
          <Heading
            backgroundColor={theme.palette.cyclonistOrange.main}
            textColor={theme.palette.primary.contrastText}
          >
            画像作成情報
          </Heading>

          <RowGrid columns={6}>
            <HeadGrid xs={1}>
              <HeadLabel>刷合せトンボ</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={2}>
              <BodyLabel>
                {imageDataDirection?.tombo && imageDataDirection.tombo != '0' ? '下向き' : ''}
              </BodyLabel>
            </BodyGrid>
            <HeadGrid xs={1}>
              <HeadLabel>*特殊</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={2}>
              <BodyLabel>
                マスタークリーンファイル：
                {imageDataDirection?.masterCleanFileFlg &&
                imageDataDirection?.masterCleanFileFlg != 0
                  ? '✕'
                  : ''}
              </BodyLabel>
            </BodyGrid>
          </RowGrid>

          <RowGrid columns={6}>
            <HeadGrid xs={1}>
              <HeadLabel>データ作成機種</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={2}>
              <BodyLabel>{imageDataDirection?.model ? imageDataDirection.model : ''}</BodyLabel>
            </BodyGrid>
            <HeadGrid xs={1}>
              <HeadLabel>フォーマット</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={2}>
              <BodyLabel>{imageDataDirection?.format ? imageDataDirection.format : ''}</BodyLabel>
            </BodyGrid>
          </RowGrid>

          <RowGrid columns={6}>
            <HeadGrid xs={1}>
              <HeadLabel>色校カーブ</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={5}>
              <BodyLabel>
                {'1：'} {imageDataDirection?.irokou1 ? imageDataDirection?.irokou1 : ''} {' 2：'}{' '}
                {imageDataDirection?.irokou2 ? imageDataDirection?.irokou2 : ''}
              </BodyLabel>
            </BodyGrid>
          </RowGrid>

          <RowGrid columns={1}>
            <HeadGrid xs={1} sx={{ justifyContent: 'left' }}>
              <HeadLabel>*レーザー向情報</HeadLabel>
            </HeadGrid>
          </RowGrid>

          <RowGrid columns={6}>
            <HeadGrid xs={1}>
              <HeadLabel>データRes</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={5}>
              <BodyLabel>{imageDataDirection?.res ? imageDataDirection.res : ''}</BodyLabel>
            </BodyGrid>
          </RowGrid>

          <RowGrid columns={6}>
            <HeadGrid xs={1}>
              <HeadLabel>加減張り</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={2}>
              <BodyLabel>
                {imageDataDirection?.addSub && imageDataDirection?.addSub != 0 ? 'あり' : ''}
              </BodyLabel>
            </BodyGrid>
            <HeadGrid xs={1}>
              <HeadLabel>フレーム処理</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={2}>
              <BodyLabel>
                {imageDataDirection?.frameAllColor && imageDataDirection?.frameAllColor != 0
                  ? '全色'
                  : ''}

                {imageDataDirection?.frameSpecification &&
                imageDataDirection?.frameSpecification != 0
                  ? '  刷色指定：' + imageDataDirection.frameSpecificationColor
                  : ''}
              </BodyLabel>
            </BodyGrid>
          </RowGrid>

          <Box m={2} />

          {/* 画像データヘッダー */}
          <RowGrid columns={8}>
            <BodyGrid
              xs={7}
              sx={{
                backgroundColor: theme.palette.cyclonistOrange.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <Typography variant='h3'>画像データ</Typography>
            </BodyGrid>
            <HeadGrid
              xs={1}
              sx={{
                backgroundColor: theme.palette.cyclonistOrange.main,
                justifyContent: 'center',
              }}
            >
              <IconButton>
                <Download onClick={onDownloadFolder} />
              </IconButton>
            </HeadGrid>
          </RowGrid>
          <Box m={2} />

          {/* 最終ファイル名ヘッダー */}
          <RowGrid columns={8}>
            <HeadGrid
              xs={3}
              sx={{
                backgroundColor: theme.palette.cyclonistOrange.main,
                textColor: theme.palette.primary.contrastText,
              }}
            >
              <HeadLabel
                sx={{
                  color: theme.palette.primary.contrastText,
                }}
              >
                最終ファイル名
              </HeadLabel>
            </HeadGrid>
            <BodyGrid xs={5}>
              <BodyLabel>
                1. {imageDataDirection?.lastFileList[0]}
                {'　'}
                2. {imageDataDirection?.lastFileList[1]}
                {'　'}
                3. {imageDataDirection?.lastFileList[2]}
                {'　'}
                4. {imageDataDirection?.lastFileList[3]}{' '}
              </BodyLabel>
            </BodyGrid>
          </RowGrid>

          {bunshokuList ? (
            <Box
              sx={{
                '& .header': {
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              <DataGrid
                rows={bunshokuList}
                getRowId={(row) => row.printSequence + row.printSequence2}
                columns={columns}
                apiRef={api}
                pagination={true}
                // pageSize={1000}
                rowsPerPageOptions={[20]}
                disableSelectionOnClick
                autoHeight={true}
              />
            </Box>
          ) : null}

          <Box m={2} />
          <RowGrid columns={2}>
            <HeadGrid
              xs={1}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                justifyContent: 'left',
              }}
            >
              <Typography variant='h3'>製版向画像データ指示注意事項</Typography>
            </HeadGrid>
            <HeadGrid
              xs={1}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                justifyContent: 'left',
              }}
            >
              <AttentionLabel variant='h3'>製版指示書特記事項</AttentionLabel>
              <Typography>{'　'}※全ての部署で参照できます。</Typography>
            </HeadGrid>
          </RowGrid>

          <RowGrid columns={2}>
            <BodyGrid xs={1}>
              <BodyLabel>{imageDataDirection?.note}</BodyLabel>
            </BodyGrid>
            <BodyGrid xs={1}>
              <BodyLabel>{cylMakingDirectionOnState}</BodyLabel>
            </BodyGrid>
          </RowGrid>

          <Box m={2} />

          <Heading
            backgroundColor={theme.palette.cyclonistOrange.main}
            textColor={theme.palette.primary.contrastText}
          >
            その他工程作業記録
          </Heading>

          <RowGrid columns={6}>
            <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
              <ImageLinkCreater
                isWorkProcess={isWorkProcess(otherWorkProcessList, ImageWorkProcessTypes.design)}
                title='デザイン'
                funcOnClick={() => openImageWindow('デザイン')}
              />
            </BodyGrid>
            <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
              <ImageLinkCreater
                isWorkProcess={isWorkProcess(
                  otherWorkProcessList,
                  ImageWorkProcessTypes.photoRetouch,
                )}
                title='分解'
                funcOnClick={() => openImageWindow('分解')}
              />
            </BodyGrid>
            <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
              <ImageLinkCreater
                isWorkProcess={isWorkProcess(
                  otherWorkProcessList,
                  ImageWorkProcessTypes.colorSeparation,
                )}
                title='色分け'
                funcOnClick={() => openImageWindow('色分け')}
              />
            </BodyGrid>
            <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
              <ImageLinkCreater
                isWorkProcess={
                  isWorkProcess(otherWorkProcessList, ImageWorkProcessTypes.presentItemWithProof) ||
                  isWorkProcess(otherWorkProcessList, ImageWorkProcessTypes.presentItemNoProof) ||
                  isWorkProcess(otherWorkProcessList, ImageWorkProcessTypes.PresentItem)
                }
                // isWorkProcess={isWorkProcess(otherWorkProcessList, ImageWorkProcessTypes.present)}
                title='提出物'
                funcOnClick={() => openImageWindow('提出物')}
              />
            </BodyGrid>
            <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
              <ImageLinkCreater
                isWorkProcess={isWorkProcess(
                  otherWorkProcessList,
                  ImageWorkProcessTypes.inspection,
                )}
                title='検版'
                funcOnClick={() => openImageWindow('検版')}
              />
            </BodyGrid>
            <BodyGrid xs={1} sx={{ justifyContent: 'center' }}>
              <ImageLinkCreater
                isWorkProcess={isWorkProcess(otherWorkProcessList, ImageWorkProcessTypes.layout)}
                title='下版'
                funcOnClick={() => openImageWindow('下版')}
              />
            </BodyGrid>
          </RowGrid>
        </>
      ) : null}
    </>
  );
};

export default ImageInfo;
