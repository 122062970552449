import { CylMakingCalcTypeForMainOffice } from 'constants/define';
import { useFormContext, useWatch } from 'react-hook-form';

import { CylinderOrderEditForm, UnitPriceHanberiMakingRowValues } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';
import { useUnitPriceCylMakingRow } from './useUnitPriceCylMakingRow';

/** 工程あたり単価 - 製版代（版べり） 行情報 Hooks */
export const useUnitPriceHanberiMakingRow = (): UnitPriceHanberiMakingRowValues => {
  const { control } = useFormContext<CylinderOrderEditForm>();
  const hanberiRow = useWatch({ control, name: 'price.unitPrice.hanberiMaking' });
  const { toyoPriceRate, cylUnitPrice } = usePriceCalculateMatter();

  const { calcUnitPriceHanberiMaking, calcGrossInvoice } = usePriceCalculater();

  const { price: cylMakingPrice, unRoundedPrice: unRoundedCylMakingPrice } =
    useUnitPriceCylMakingRow();

  // 本社であれば「四捨五入（四捨五入していない製版代 * 版べり割率）」
  // 本社以外なら「四捨五入した製版代 * 版べり割率」
  // を売価とする。
  const calcSourceCylMakingPrice =
    cylUnitPrice?.cylMakingCalcType === CylMakingCalcTypeForMainOffice
      ? unRoundedCylMakingPrice
      : cylMakingPrice;

  /** 売価 */
  const price = calcUnitPriceHanberiMaking(calcSourceCylMakingPrice, hanberiRow.hanberiRate);
  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, toyoPriceRate);

  return {
    cylMakingPrice,
    hanberiRate: hanberiRow.hanberiRate ?? 0,
    price,
    toyoPriceRate,
    grossInvoice,
  };
};
