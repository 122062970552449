import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './defaults';
import { ImageDataDownloadState } from './types';

const slice = createSlice({
  name: 'imageDataDownload',
  initialState,
  reducers: {
    setProcessTarget(state, action: PayloadAction<ImageDataDownloadState['processTarget']>) {
      state.processTarget = action.payload;
    },
    setFromPage(state, action: PayloadAction<ImageDataDownloadState['fromPage']>) {
      state.fromPage = action.payload;
    },
    setInitialProcessState(
      state,
      action: PayloadAction<ImageDataDownloadState['page']['initialProcessState']>,
    ) {
      state.page.initialProcessState = action.payload;
    },
    setDestinations(state, action: PayloadAction<ImageDataDownloadState['page']['destinations']>) {
      state.page.destinations = action.payload;
    },
    clear: () => ({
      ...initialState,
    }),
  },
});

export const { setProcessTarget, setFromPage, setInitialProcessState, setDestinations, clear } = slice.actions;

export default slice.reducer;
