import { SelectOption } from 'components/parts/atoms/Select/types';

/** セレクトボックスのvalueに紐ずくキーを取得 */
export function getSelectText(value: string | null, selectList: SelectOption[]): string {
  const selectItem = selectList.find((x) => x.value === value);
  if (selectItem) {
    return selectItem.text;
  } else {
    return '';
  }
}

/** セレクトボックスのvalueに紐ずくdecisionCodeを取得 */
export function getSelectDecisionCode(value: string | null, selectList: SelectOption[]): string {
  const selectItem = selectList.find((x) => x.value === value);
  if (selectItem) {
    return selectItem.decisionCode ?? '';
  } else {
    return '';
  }
}
