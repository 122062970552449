import React from 'react';

import { HeadLabel } from 'components/parts/atoms';

import { Heading } from '../../Heading';
import { HeadRow } from './Row';
import UnitPriceRow from './UnitPriceRow';
import UnitPriceWithRateFlgRow from './UnitPriceWithRateFlgRow';

/** 単価計算コンポーネント */
const UnitPrice: React.VFC = () => {
  return (
    <>
      <Heading>単価計算</Heading>
      <HeadRow>
        <HeadLabel>工程</HeadLabel>
        <HeadLabel>単価</HeadLabel>
        <HeadLabel>版数</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>

      <UnitPriceRow label='データ作成代' propertyName='dataMake' />
      <UnitPriceRow label='データ修正代' propertyName='dataEdit' />
      <UnitPriceRow label='下版データ作成代' propertyName='layoutData' />
      <UnitPriceRow label='カラー処理代' propertyName='colorProcess' />
      <UnitPriceRow label='カラー分解代' propertyName='photoRetouch' />
      <UnitPriceRow label='版下代' propertyName='blockCopy' />
      <UnitPriceWithRateFlgRow label='運賃' propertyName='fare' />
    </>
  );
};

export default React.memo(UnitPrice);
