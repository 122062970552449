import useSharedMessage from 'hooks/useSharedMessage';
import React, { useCallback } from 'react';
import { useForm, Controller, useFormContext, useFieldArray } from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import { AddCircle } from '@mui/icons-material';
import { styled } from '@mui/material';

import {
  CylinderOrderEditForm,
  EigReferenceTemplate,
  EigReferenceTemplateForm,
} from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import {
  RowGrid,
  HeadGrid,
  BodyGrid as BodyGridOrigin,
  HeadLabel,
  BodyLabel,
  BodyLink,
  Textarea,
} from 'components/parts/atoms';

import { defaultEigReferenceTemplateForm } from '../../defaults';
import { useId } from '../../hooks/useId';
import { usePageContext } from '../../hooks/usePageContext';
import { useReferenceInputCheck } from '../../hooks/useReferenceInputCheck';
import { EditIconButton, EditKanaField, ShowOnEdit } from '../Fields';

const BodyGrid = styled(BodyGridOrigin)(() => ({
  justifyContent: 'center',
}));

/** 営業 参照テンプレートコンポーネント */
const EigReferenceTemplates: React.VFC = () => {
  const { control } = useFormContext<CylinderOrderEditForm>();
  const { leavePage } = usePageContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'making.eigReferenceTemplates',
  });

  const form = useForm<EigReferenceTemplateForm>({
    defaultValues: defaultEigReferenceTemplateForm,
  });

  const { generateId } = useId();
  const messageApi = useSharedMessage();
  const { loading, checkTemplate } = useReferenceInputCheck(messageApi);

  /** 行追加 */
  const onAdd = useCallback(
    form.handleSubmit((data: EigReferenceTemplateForm) => {
      messageApi.clear();

      checkTemplate(
        { referenceTemplateNo: data.referenceTemplateNo, note: data.note },
        (linkFlg: boolean) => {
          const addRow: EigReferenceTemplate = {
            eigReferenceTemplateId: generateId(),
            referenceTemplateNo: data.referenceTemplateNo,
            note: data.note,
            linkFlg,
          };
          append(addRow);
          form.reset();
        },
      );
    }),
    [form, append, generateId, messageApi],
  );

  /** 行削除 */
  const onDelete = useCallback((index: number) => {
    remove(index);
  }, []);

  /**
   * テンプレートNoリンククリック時
   */
  const onClickTemplateNoLink = useCallback((referenceTemplateNo: string) => {
    // テンプレート/素材 詳細 画面へ遷移
    leavePage('/TemplateImageDetail/' + referenceTemplateNo);
  }, []);

  return (
    <>
      <RowGrid columns={12}>
        <HeadGrid vertical xs={4}>
          <HeadLabel>{'参照テンプレートNo.'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={7}>
          <HeadLabel>{'コメント'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={1}>
          <></>
        </HeadGrid>
      </RowGrid>

      <ShowOnEdit>
        <RowGrid columns={12}>
          <BodyGrid xs={4}>
            <Controller
              name={'referenceTemplateNo'}
              control={form.control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  maxLength={60}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={7}>
            <Controller
              name={'note'}
              control={form.control}
              render={({ field: { onChange, value, name } }) => (
                <Textarea
                  maxLength={80}
                  style={{ width: '100%', height: '4.5em' }}
                  onChange={onChange}
                  value={value}
                  name={name}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={1}>
            <EditIconButton onClick={() => onAdd()} disabled={loading}>
              <AddCircle />
            </EditIconButton>
          </BodyGrid>
        </RowGrid>
      </ShowOnEdit>
      {fields.map((item, index) => {
        return (
          <RowGrid columns={12} key={item.id}>
            <BodyGrid xs={4}>
              {item.linkFlg ? (
                <BodyLink onClick={() => onClickTemplateNoLink(item.referenceTemplateNo)}>
                  {item.referenceTemplateNo}
                </BodyLink>
              ) : (
                <BodyLabel>{item.referenceTemplateNo}</BodyLabel>
              )}
            </BodyGrid>
            <BodyGrid xs={7}>
              <BodyLabel sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
                {item.note}
              </BodyLabel>
            </BodyGrid>
            <BodyGrid xs={1}>
              <EditIconButton onClick={() => onDelete(index)}>
                <Delete />
              </EditIconButton>
            </BodyGrid>
          </RowGrid>
        );
      })}
    </>
  );
};

export default React.memo(EigReferenceTemplates);
