import useApiClient from 'hooks/useApiClient';
import { ResponseMessage } from 'api/types/common/apiResponse';
import { useState } from 'react';

import { PrintTarget } from '../types';

export const usePrint = (messageHandler: (messages: ResponseMessage[]) => void): {
  printProcess: (condition: PrintTarget,) => void,
  loading: boolean,
}=> {
  const { apiClient } = useApiClient(messageHandler);
  const [loading, setLoading] = useState(false);
  
  // 印刷処理
  const printProcess = (condition: PrintTarget): void => {
   (async () => {
      setLoading(true);
      await apiClient.axios.post(`/api/v1/DeliveryPrint/PrintProcess`, condition).then((response) =>{
        const fileData = response.data;
        if (fileData && (typeof fileData === "string")) { 
          
          // PDFのBase64データをBlob後に別ウィンドウにて表示
          const blob = new Blob([Buffer.from(fileData,"base64")], {
            type: "application/pdf",
          });

          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
      } ).catch((e)=>{
        console.log(e)
      }).finally(()=>{
        setLoading(false); 
      });
    })();
};
  
  return {
    printProcess,
    loading,
  };
};

export default usePrint;