import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';

import { RegistData } from '../types';

/** プロダクション編集確認のHooks */
export const useRegistProductionEditCheck = (): {
  registProductionAsync: (param: RegistData) => Promise<boolean>;
} => {
  // メッセージ
  const messageApi = useSharedMessage();
  const { apiClient } = useApiClient(messageApi.pushMessages);

  // プロダクション登録
  const registProductionAsync = async (postData: RegistData): Promise<boolean> => {
    try {
      const [allresults] = await Promise.all([
        apiClient.post<RegistData>(`/api/v1/ProductionEdit/RegistProduction`, postData),
      ]);

      if (allresults.data.result) {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    return false;
  };

  return {
    registProductionAsync,
  };
};

export default useRegistProductionEditCheck;
