import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React, { FC, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function useConst<T>(init: () => T) {
  // We cannot useMemo, because it is not guranteed to never rerun.
  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  const ref = useRef<T | null>(null);
  if (ref.current === null) {
    ref.current = init();
  }
  return ref.current;
}

type NewWindowProps = {
  features: { height: number; width: number };
  onUnload: () => void;
};

const NewWindow: FC<NewWindowProps> = (props) => {
  const containerEl = useConst(() => document.createElement('div'));

  const cache = useConst(() => createCache({ key: 'external', container: containerEl }));

  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    const externalWindow = window.open(
      '',
      '',
      `width=${props.features.width},height=${props.features.height},toolbar=yes,menubar=yes,scrollbars=yes`,
    );

    // if window.open fails
    if (!externalWindow) {
      props.onUnload();
      return;
    }

    externalWindow.addEventListener('beforeunload', props.onUnload);

    externalWindow.document.body.appendChild(containerEl);

    setOpened(true);

    return () => {
      externalWindow.close();
    };
  }, []);

  return isOpened
    ? ReactDOM.createPortal(
        <CacheProvider value={cache}> {props.children}</CacheProvider>,
        containerEl,
      )
    : null;
};

export default NewWindow;
