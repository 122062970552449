import { CalculateMethodCd } from 'constants/define';

import {
  FlexoOrderEditForm,
  FlexoOrderEditMaster,
  SplittedDateTime,
  EigImageReferenceJobForm,
  EigReferenceTemplateForm,
  UrlModel,
} from './types';

const defaultSplittedDateTime: SplittedDateTime = {
  day: null,
  timeDiv: '',
  hour: '',
  minute: '',
};

const defaultUrl: UrlModel = {
  urlId: null,
  url: '',
  urlName: '',
};

/** 受注入力モデルの初期データ */
export const initialFlexoOrderEdit: FlexoOrderEditForm = {
  header: {
    tjobRecordRegistDay: null,
    tjobRecordEditDay: null,
    issueDate: null,
    registEmployeeId: 0,
    registEmployeeName: '',
    editEmployeeId: null,
    editEmployeeName: '',
    jobNo: '',
    referenceJobNo: '',
    seriesMasterId: null,
    seriesMaster: '',
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
  },
  basis: {
    jobNo: '',
    chargedEmployeeId: '',
    customerSectionCd: '',
    customerCd: '',
    customerName: '',
    companyCd: '',
    customerChargeId: '',
    customerOrderNo: '',
    myJobNo: '',
    oldJobNo: '',
    eigyoItemCdId: '',
    productionId: null,
    productionName: '',
    jobName: '',
    jobKanaName: '',
    demandCustomerSectionCd: '',
    demandCustomerCd: '',
    demandCustomerName: '',
    deliveryPlace: {
      deliveryPlaceId: '',
      name: '',
      zipCd: '',
      prefectureId: '',
      address1: '',
      address2: '',
      builName: '',
      tel: '',
    },
  },
  schedule: {
    scheduleDate: {
      orderDate: null,
      appropriateDate: null,
      imageWorkFlg: false,
      designFlg: false,
      phtoRetouchFlg: false,
      colorSeparationFlg: false,
      approvedFlg: false,
      imageJobPlanDate: defaultSplittedDateTime,
      syukkouPlanDate: defaultSplittedDateTime,
      presentationDate: defaultSplittedDateTime,
      proofDate: defaultSplittedDateTime,
      proofFlg: false,
      shipmentPlanDate: defaultSplittedDateTime,
      deliveryDate: defaultSplittedDateTime,
      customerPlanDate: defaultSplittedDateTime,
    },
    outsourcing: {
      imageOutsourcingFlg: false,
      cylMakingOutsourcingFlg: false,
    },
    draftReturns: [],
    presents: [],
    canEditDraftReturns: true,
    canEditPresents: true,
  },
  making: {
    makingBasis: {
      printCdId: '',
      paperName: '',
      materialCdId: '',
      thickness: null,
      gentanWidth: null,
      materialMakerCdId: '',
      coatingCdId: '',
      setterCdId: '',
      resolutionCdId: '',
      outputOption: null,
      microCellCdId: '',
      inlineUvFlg: false,
      beforeCylMakingDate: null,
    },
    makingSizeMain: {
      repeatLength: null,
      plateWidth: null,
      plateGrain: null,
      plateArea: null,
      finishSizeWidth: null,
      finishSizeGrain: null,
      barrierRatioWidth: null,
      barrierRatioGrain: null,
      frontPageSizeWidth: null,
      frontPageSizeGrain: null,
      impositioningWidth: null,
      impositioningGrain: null,
      overAllSizeWidth: null,
      overAllSizeGrain: null,
    },
    makingSizeReverse: {
      repeatLength: null,
      plateWidth: null,
      plateGrain: null,
      plateArea: null,
      finishSizeWidth: null,
      finishSizeGrain: null,
      barrierRatioWidth: null,
      barrierRatioGrain: null,
      frontPageSizeWidth: null,
      frontPageSizeGrain: null,
      impositioningWidth: null,
      impositioningGrain: null,
      overAllSizeWidth: null,
      overAllSizeGrain: null,
    },
    flexoMakingsMain: [],
    flexoMakingsReverse: [],
    accessories: [],
    barcode: {
      barcodeNo: '',
      scale: null,
      bwr: null,
    },
    eigImageReferenceJobs: [],
    eigReferenceTemplates: [],
    gaImageReferenceJobs: [],
    gaReferenceTemplates: [],
    instructions: {
      imageDirectionOnSchedule: '',
      imageDirectionOnState: '',
      plateMakingDirectionOnSchedule: '',
      plateMakingDirectionOnState: '',
      shipmentDirectionOnSchedule: '',
      shipmentDirectionOnState: '',
    },
    approvalFlg: false,
  },
  price: {
    calculateMethodCd: CalculateMethodCd.Unit,
    flxUnitPriceId: '',
    toyoPriceRate: null,
    makingPriceMain: {
      betaHan: { unitPrice: null, count: null },
      halfTone: { unitPrice: null, count: null },
      barrier: { unitPrice: null, count: null },
      negaFilm: { unitPrice: null, count: null },
      posi: { unitPrice: null, count: null },
      singleFaceNega: { unitPrice: null, count: null },
      composer: { unitPrice: null, count: null },
      matNega: { unitPrice: null, count: null },
      plate: { unitPrice: null, count: null },
    },
    makingPriceReverse: {
      betaHan: { unitPrice: null, count: null },
      halfTone: { unitPrice: null, count: null },
      barrier: { unitPrice: null, count: null },
      negaFilm: { unitPrice: null, count: null },
      posi: { unitPrice: null, count: null },
      singleFaceNega: { unitPrice: null, count: null },
      composer: { unitPrice: null, count: null },
      matNega: { unitPrice: null, count: null },
      plate: { unitPrice: null, count: null },
    },
    unitPrice: {
      dataMake: { unitPrice: null, count: null },
      dataEdit: { unitPrice: null, count: null },
      layoutData: { unitPrice: null, count: null },
      colorProcess: { unitPrice: null, count: null },
      photoRetouch: { unitPrice: null, count: null },
      blockCopy: { unitPrice: null, count: null },
      fare: { unitPrice: null, count: null, toyoPriceRateFlg: true },
    },
    otherImagePrice: {
      kakakuJouhouSonotas: [],
    },
    discount: {
      discountRate: null,
      discountPrice: null,
    },
    totalPrice: {
      price: 0,
      grossInvoice: 0,
    },
    estimateNote: '',
    statementOfDeliveryNote: '',
    noteInstateOfDelivery: '',
    approvalFlg: false,
    approvalName: null,
    secondApprovalName: null,
    approvalDate: null,
    secondApprovalDate: null,
    url: defaultUrl,
    priceNote: '',

    secondApprovalFlg: false,
    customerUserFlg: false,
  },
};

/** 受注入力マスタモデルの初期データ */
export const initialFlexoMaster: FlexoOrderEditMaster = {
  chargedEmployeeList: [],
  customerChargeList: [],
  eigyoItemList: [],
  deliveryPlaceList: [],
  receivedPlaceList: [],
  prefecturesList: [],
  draftReturnList: [],
  returnTimeList: [],
  returnList: [],
  presentList: [],
  presentMethodList: [],
  printList: [],
  materialList: [],
  materialMakerList: [],
  coatingList: [],
  setterList: [],
  resolutionList: [],
  microCellList: [],
  plateMakingList: [],
  colorTipList: [],
  accessoriesMasterList: [],
  positoonList: [],
  workProcessList: [],
  flxUnitPriceSelectList: [],
  flxUnitPriceList: [],
};

/** 営業 参照のフォーム初期値 */
export const defaultEigImageReferenceJobForm: EigImageReferenceJobForm = {
  eigImageReferenceJobId: -1,
  referenceJobId: null,
  referenceJobNo: '',
  workProcesscdId: '',
  note: '',
};

/** 営業 テンプレート参照のフォーム初期値 */
export const defaultEigReferenceTemplateForm: EigReferenceTemplateForm = {
  eigReferenceTemplateId: -1,
  referenceTemplateNo: '',
  note: '',
};
