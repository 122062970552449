import React, { ReactNodeArray } from 'react';

import { RowGrid } from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';

/** シリンダー受注 価格情報 1列コンポーネント */
export const RowOne: React.FC<{ children: ReactNodeArray }> = ({
  children: [labelOne, bodyOne],
}) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={2} bxs={10}>
          {labelOne}
          {bodyOne}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};
