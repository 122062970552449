import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';

import { BodyLabel } from 'components/parts/atoms';

import { useOtherImagePriceRow } from '../../../hooks/price/priceRow/useOtherImagePriceRow';
import { CylinderOrderEditForm } from '../../../types';
import { PriceCheckbox, PriceIconButton, PriceKanaField, PriceNumericField } from '../Fields';
import { Row } from './Row';

type Props = {
  index: number;
  onDeleteClicked: () => void;
};

/** その他（画像等） 行コンポーネント */
const OtherImagePriceRow: React.VFC<Props> = ({ index, onDeleteClicked }) => {
  // コンテキスト
  const { control } = useFormContext<CylinderOrderEditForm>();

  const { toyoPriceRate, grossInvoice } = useOtherImagePriceRow(index);

  return (
    <>
      <Row>
        <Controller
          name={`price.otherImagePrice.kakakuJouhouSonotas.${index}.name`}
          control={control}
          render={({ field }) => (
            <PriceKanaField
              maxLength={20}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '100%' }}
            />
          )}
        />
        <Controller
          name={`price.otherImagePrice.kakakuJouhouSonotas.${index}.price`}
          control={control}
          render={({ field }) => (
            <PriceNumericField
              min={-9999999}
              max={9999999}
              decimalDigits={0}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              sx={{ width: '100%' }}
            />
          )}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Controller
            name={`price.otherImagePrice.kakakuJouhouSonotas.${index}.toyoPriceRateFlg`}
            control={control}
            render={({ field }) => (
              <PriceCheckbox onChange={field.onChange} checked={field.value} name={field.name} />
            )}
          />
          <BodyLabel>{toyoPriceRate.toFixed(3)}</BodyLabel>
        </Box>
        <BodyLabel>{grossInvoice.toFixed()}</BodyLabel>
        <PriceIconButton onClick={onDeleteClicked}>
          <DeleteIcon />
        </PriceIconButton>
      </Row>
    </>
  );
};

export default React.memo(OtherImagePriceRow);
