import React from 'react';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography, Heading } from 'components/parts/atoms';

import { useInformations } from './hooks';
import { Division } from './types';

const Container = styled(Box)(() => ({}));

const InformationCategory = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const Information = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(6),
  marginBottom: theme.spacing(2),
}));

const Informations: React.VFC<{ division: Division }> = (props) => {
  const { informations } = useInformations(props.division);

  return (
    <Container>
      {informations.informationCategories.map((category, index) => (
        <>
          <Heading
            backgroundColor={category.backgroundColor ?? undefined}
            textColor={category.foregroundColor ?? undefined}
          >
            {category.informationTitle ?? ''}
          </Heading>
          <Grid key={index} container>
            <Grid item>
              <InformationCategory key={category.informationCategoryId}>
                {category.informations.map((information) => (
                  <Information key={information.informationId}>
                    <Typography variant='body1'>{information.informationContents}</Typography>
                  </Information>
                ))}
                {category.informations.length == 0 && (
                  <>
                    <Information>
                      <Typography variant='body1'>{category.defaultDisplayString}</Typography>
                    </Information>
                  </>
                )}
              </InformationCategory>
            </Grid>
          </Grid>
        </>
      ))}
    </Container>
  );
};

export default Informations;
