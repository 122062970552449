import useSharedMessage from 'hooks/useSharedMessage';
import React, { useCallback, useState } from 'react';
import { useForm, Controller, useFormContext, useFieldArray } from 'react-hook-form';

import { AddCircle, Delete, Search } from '@mui/icons-material';
import { styled } from '@mui/material';

import ImageDataSearch from 'pages/common/ImageDataSearch';
import { SelectImageData } from 'pages/common/ImageDataSearch/types';
import {
  CylinderOrderEditForm,
  EigImageReferenceJob,
  EigImageReferenceJobForm,
} from 'pages/topSales/order/CylinderOrder/CylinderOrderEdit/types';

import {
  RowGrid,
  HeadGrid,
  BodyGrid as BodyGridOrigin,
  HeadLabel,
  BodyLabel,
  BodyLink,
  Textarea,
} from 'components/parts/atoms';

import { getSelectText } from 'utils/selectUtils';

import { defaultEigImageReferenceJobForm } from '../../defaults';
import { useCylinderOrderEditMaster } from '../../hooks/useCylinderOrderEditMaster';
import { useId } from '../../hooks/useId';
import { usePageContext } from '../../hooks/usePageContext';
import { useReferenceInputCheck } from '../../hooks/useReferenceInputCheck';
import { EditIconButton, EditKanaField, EditSelect, ShowOnEdit } from '../Fields';
import { Heading } from '../Heading';

const BodyGrid = styled(BodyGridOrigin)(() => ({
  justifyContent: 'center',
}));

/** 営業 画像参照JOBコンポーネント */
const EigImageReferenceJobs: React.VFC = () => {
  const { control } = useFormContext<CylinderOrderEditForm>();
  const { master } = useCylinderOrderEditMaster();
  const { leavePage } = usePageContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'making.eigImageReferenceJobs',
  });

  const form = useForm<EigImageReferenceJobForm>({
    defaultValues: defaultEigImageReferenceJobForm,
  });

  const { generateId } = useId();
  const messageApi = useSharedMessage();
  const { loading, checkReferenceJob } = useReferenceInputCheck(messageApi);

  // 画像検索ダイアログ表示
  const [openImageDataSearch, setOpenImageDataSearch] = useState<boolean>(false);

  /** 行追加 */
  const onAdd = useCallback(
    form.handleSubmit((data: EigImageReferenceJobForm) => {
      messageApi.clear();

      checkReferenceJob(
        { referenceJobNo: data.referenceJobNo, note: data.note },
        (jobId: number, linkFlg: boolean) => {
          const addRow: EigImageReferenceJob = {
            eigImageReferenceJobId: generateId(),
            referenceJobId: jobId,
            referenceJobNo: data.referenceJobNo,
            workProcesscdId: data.workProcesscdId,
            note: data.note,
            linkFlg,
          };
          append(addRow);
          form.reset();
        },
      );
    }),
    [form, append, generateId, messageApi],
  );

  /**
   * 行削除
   * @param index インデックス
   */
  const onDelete = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  /**
   * 検索
   * @param index インデックス
   */
  const onSearch = useCallback(() => {
    // ダイアログ表示
    setOpenImageDataSearch(true);
  }, []);

  /**
   * 検索ダイアログ終了時
   * @param value 選択した画像情報
   */
  const onImageDataSearchClose = useCallback((value: SelectImageData | null) => {
    // 選択されたJOBをフォームにセット
    if (value) {
      form.setValue('referenceJobId', value.jobId);
      form.setValue('referenceJobNo', value.jobNo);
      form.setValue('note', value.fileName);
    }
    setOpenImageDataSearch(false);
  }, []);

  /**
   * JobNoリンククリック時
   */
  const onClickJobNoLink = useCallback((referenceJobId: number) => {
    // ページ遷移前処理（ストアへの保存）
    leavePage('/ImageDataDetail/' + referenceJobId);
  }, []);

  return (
    <>
      <Heading>営業 参照/テンプレート</Heading>
      <RowGrid columns={12}>
        <HeadGrid vertical xs={4}>
          <HeadLabel> {'画像参照JOB No.'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={2.5}>
          <HeadLabel>{'工程'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={4.5}>
          <HeadLabel>{'コメント'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={1}>
          <></>
        </HeadGrid>
      </RowGrid>

      <ShowOnEdit>
        <RowGrid columns={12}>
          <BodyGrid sx={{ justifyContent: 'space-around' }} xs={4}>
            <Controller
              name={'referenceJobNo'}
              control={form.control}
              render={({ field: { onChange, value, name } }) => (
                <EditKanaField
                  maxLength={14}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '100%' }}
                />
              )}
            />
            <EditIconButton onClick={() => onSearch()}>
              <Search />
            </EditIconButton>
          </BodyGrid>
          <BodyGrid xs={2.5}>
            <Controller
              name={'workProcesscdId'}
              control={form.control}
              render={({ field: { onChange, value, name } }) => (
                <EditSelect
                  addEmptyRow={true}
                  options={master.workProcessList}
                  onChange={onChange}
                  value={value}
                  name={name}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={4.5}>
            <Controller
              name={'note'}
              control={form.control}
              render={({ field: { onChange, value, name } }) => (
                <Textarea
                  maxLength={80}
                  style={{ width: '100%', height: '4.5em' }}
                  onChange={onChange}
                  value={value}
                  name={name}
                />
              )}
            />
          </BodyGrid>
          <BodyGrid xs={1}>
            <EditIconButton onClick={() => onAdd()} disabled={loading}>
              <AddCircle />
            </EditIconButton>
          </BodyGrid>
        </RowGrid>
      </ShowOnEdit>
      {fields.map((item, index) => {
        return (
          <RowGrid key={item.id} columns={12}>
            <BodyGrid xs={4}>
              {item.linkFlg ? (
                <BodyLink onClick={() => onClickJobNoLink(item.referenceJobId)}>
                  {item.referenceJobNo}
                </BodyLink>
              ) : (
                <BodyLabel>{item.referenceJobNo}</BodyLabel>
              )}
            </BodyGrid>
            <BodyGrid xs={2.5}>
              <BodyLabel>{getSelectText(item.workProcesscdId, master.workProcessList)}</BodyLabel>
            </BodyGrid>
            <BodyGrid xs={4.5}>
              <BodyLabel sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {item.note}
              </BodyLabel>
            </BodyGrid>
            <BodyGrid xs={1}>
              <EditIconButton onClick={() => onDelete(index)}>
                <Delete />
              </EditIconButton>
            </BodyGrid>
          </RowGrid>
        );
      })}

      <ImageDataSearch open={openImageDataSearch} onClose={onImageDataSearchClose} />
    </>
  );
};

export default React.memo(EigImageReferenceJobs);
