import { UnregisterCallback } from 'history';
import { useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export const usePrompt = (
  when = true,
): {
  unblockPush: (...args: Parameters<typeof history.push>) => void;
  unblockGoBack: () => void;
  unblockReplace: (...args: Parameters<typeof history.replace>) => void;
  unblock: UnregisterCallback;
} => {
  const message = '変更内容は消去されます。';
  const history = useHistory();

  const unblock = useRef<UnregisterCallback>(() => undefined);
  const whenRef = useRef<boolean>(when);
  const forgive = useRef(false);
  whenRef.current = when;

  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    if (whenRef.current) {
      event.preventDefault();
      event.returnValue = '';
    }
  }, []);

  // 遷移時のブロック
  useEffect(() => {
    unblock.current = history.block(() => {
      if (!whenRef.current) {
        return;
      }
      if (forgive.current) {
        forgive.current = false;
        return;
      }
      return message;
    });

    return () => {
      unblock.current();
    };
  }, []);

  // リロード時のブロック
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  /** ブロックなしで遷移する */
  const unblockPush = (...args: Parameters<typeof history.push>) => {
    unblock.current();
    history.push(...args);
  };

  /** ブロックなしで戻る */
  const unblockGoBack = () => {
    unblock.current();
    history.goBack();
  };

  /** ブロックなしでリプレイス */
  const unblockReplace = (...args: Parameters<typeof history.replace>) => {
    forgive.current = true;
    history.replace(...args);
  };

  return {
    unblockPush,
    unblockGoBack,
    unblockReplace,
    unblock: () => {
      unblock.current();
    },
  };
};
