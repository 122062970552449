import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialStoreData } from 'store/common/imageWork/defaults';
import { ImageWorkState } from 'store/common/imageWork/types';

const slice = createSlice({
  name: 'imageWork',
  initialState: InitialStoreData,
  reducers: {
    save: (state, action: PayloadAction<ImageWorkState>) => action.payload,
    clear: () => ({ ...InitialStoreData }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
