import { CalculateMethodCd } from 'constants/define';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CylinderOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { useResetPrice } from '../useResetPrice';

/** 計算方法変更時にフォームをリセットするHooks */
export const useCalculateMethodWatcher = (enabled: boolean): void => {
  // フォーム
  const { control, getValues, setValue } = useFormContext<CylinderOrderEditForm>();

  const [calculateMethodCd] = useWatch({ control, name: ['price.calculateMethodCd'] });
  const { defaultCylUnitPrice } = usePriceCalculateMatter();
  const defaultToyoPriceRate = defaultCylUnitPrice?.toyoPriceRate ?? null;

  const { cylUnitPrice, rawCylSizeEnsyuu } = usePriceCalculateMatter();
  const { resetPrices } = useResetPrice();

  useEffect(() => {
    if (!enabled) return;

    switch (calculateMethodCd) {
      // 単価計算(手入力)
      case CalculateMethodCd.Unit:
        {
          const [cylinderMakingsMain, cylinderMakingsReverse] = getValues([
            'making.cylinderMakingsMain',
            'making.cylinderMakingsReverse',
          ]);
          cylinderMakingsMain.forEach((_, index) => {
            setValue(`making.cylinderMakingsMain.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.cylinderMakingsMain.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
          cylinderMakingsReverse.forEach((_, index) => {
            setValue(`making.cylinderMakingsReverse.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.cylinderMakingsReverse.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
        }
        break;

      // 単価計算
      case CalculateMethodCd.FixedUnit:
        {
          const [cylinderMakingsMain, cylinderMakingsReverse] = getValues([
            'making.cylinderMakingsMain',
            'making.cylinderMakingsReverse',
          ]);
          cylinderMakingsMain.forEach((_, index) => {
            setValue(`making.cylinderMakingsMain.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.cylinderMakingsMain.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
          cylinderMakingsReverse.forEach((_, index) => {
            setValue(`making.cylinderMakingsReverse.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.cylinderMakingsReverse.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });

          // 製版代(九州)
          setValue('price.unitPrice.kyushuMaking.price', 0.0);
          // 版べり割率
          setValue('price.unitPrice.hanberiMaking.hanberiRate', 0.8);
          resetPrices(cylUnitPrice, rawCylSizeEnsyuu);
        }
        break;

      // 一式計算
      case CalculateMethodCd.Set:
        {
          // 工程あたり単価
          setValue('price.unitPrice.materials.unitPrice', 0);
          setValue('price.unitPrice.cuPlate.unitPrice', 0);
          setValue('price.unitPrice.crPlate.unitPrice', 0);
          setValue('price.unitPrice.finishingPolish.unitPrice', 0);
          setValue('price.unitPrice.rakuhanPolish.unitPrice', 0);
          setValue('price.unitPrice.reCr.unitPrice', 0);
          setValue('price.unitPrice.cylMaking.unitPrice', 0);

          // フルコース、 改版、増版
          setValue('price.normalPrice.colorCylMaking.scale', 0);
          setValue('price.normalPrice.hanchoushi.scale', 0);
          setValue('price.normalPrice.choushi.scale', 0);
          setValue('price.normalPrice.renzoku.scale', 0);
          setValue('price.normalPrice.tuikafusyoku.scale', 0);

          // 版べり再版
          setValue('price.hanberiPrice.colorCylMaking.scale', 0);
          setValue('price.hanberiPrice.hanchoushi.scale', 0);
          setValue('price.hanberiPrice.choushi.scale', 0);
          setValue('price.hanberiPrice.renzoku.scale', 0);
          setValue('price.hanberiPrice.tuikafusyoku.scale', 0);

          // その他（シリンダ）
          setValue('price.otherCylinderPrice.herio.unitPrice', 0);
          setValue('price.otherCylinderPrice.gaikeisessaku.unitPrice', 0);
          setValue('price.otherCylinderPrice.fare.unitPrice', 0);

          // シリンダ単価を未選択にセット
          setValue('price.cylUnitPriceId', '');

          // 一式計算の東洋売価率セット
          const [cylinderMakingsMain, cylinderMakingsReverse] = getValues([
            'making.cylinderMakingsMain',
            'making.cylinderMakingsReverse',
          ]);
          cylinderMakingsMain.forEach((_, index) => {
            setValue(`making.cylinderMakingsMain.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.cylinderMakingsMain.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
          cylinderMakingsReverse.forEach((_, index) => {
            setValue(`making.cylinderMakingsReverse.${index}.completeSetCalcUnitPrice`, 0);
            setValue(
              `making.cylinderMakingsReverse.${index}.completeSetCalcToyoPriceRate`,
              defaultToyoPriceRate,
            );
          });
        }
        break;
    }
  }, [calculateMethodCd]);
};
