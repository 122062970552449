import { useFormContext, useWatch } from 'react-hook-form';

import { FlexoOrderEditForm, SetPriceRowValues } from '../../../types';
import { usePriceCalculater } from '../usePriceCalculater';

/** 一式計算行情報 Hooks */
export const useSetPriceRow = (
  key: 'flexoMakingsMain' | 'flexoMakingsReverse',
  index: number,
): SetPriceRowValues => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { calcGrossInvoice } = usePriceCalculater();

  const [printSequence, completeSetCalcUnitPrice, completeSetCalcToyoPriceRate] = useWatch({
    control,
    name: [
      `making.${key}.${index}.printSequence`,
      `making.${key}.${index}.completeSetCalcUnitPrice`,
      `making.${key}.${index}.completeSetCalcToyoPriceRate`,
    ],
  });

  /** 刷順 */
  let printSequenceText = `${printSequence ?? ''}`;
  if (key === 'flexoMakingsReverse') {
    printSequenceText = `R${printSequenceText}`;
  }
  /** 単価 */
  const unitPrice = completeSetCalcUnitPrice ?? 0;

  /** 売価 */
  const price = unitPrice;

  /** 東洋売価率 */
  const toyoPriceRate = completeSetCalcToyoPriceRate ?? 1;

  /** 仕切 */
  const grossInvoice = calcGrossInvoice(price, completeSetCalcToyoPriceRate);

  return {
    printSequence: printSequenceText,
    unitPrice,
    price,
    toyoPriceRate,
    grossInvoice,
  };
};
