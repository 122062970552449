import { Affiliation, Region } from 'constants/define';
import { useAuth } from 'hooks/useAuth';
import DefaultLayout from 'layouts/DefaultLayout';
import EmptyLayout from 'layouts/EmptyLayout';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { entryRoutes, publicRoutes, privateRoutes, privateEmptyRoutes } from 'routes';
import { useAppContextStore } from 'store/appContext/hooks';

import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import NotFound from 'pages/error/NotFound';

import { Circular } from 'components/parts/atoms';

import { getState } from 'utils/routerHistory';

import './custom.css';
import useSharedMessage from './hooks/useSharedMessage';

/**
 *  key の過剰切り替えを防ぐために用意した変数
 * このファイルの中でのみ使用し、現在のURLというただ一つのものに紐づく
 */
let ROUTE_KEY = '';
/** ルーティングProps生成関数 */
const makeRouteComponentProps = (props: RouteProps) => {
  const routeUniqueKey = getState(props.location?.state, 'routeUniqueKey');
  ROUTE_KEY = routeUniqueKey || ROUTE_KEY;
  return {
    key: ROUTE_KEY,
    ...props,
  };
};

const EntryRoute: React.FC<RouteProps> = ({ ...props }) => {
  // ログインに来た段階でローカスストレージをクリア
  localStorage.clear();
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Redirect to={'/Portal'} />;
  } else {
    return <Route {...makeRouteComponentProps(props)} />;
  }
};

const PublicRoute: React.FC<RouteProps> = (props) => {
  localStorage.clear();
  return <Route {...makeRouteComponentProps(props)} />;
};

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { isLoggedIn, session } = useAuth();
  const {
    state: { loggedInRegion },
  } = useAppContextStore();
  // 不正なログインかの確認
  if (isLoggedIn) {
    if (location.pathname.includes('Portal') && session?.affiliation !== Affiliation.Normal) {
      // 得意先ユーザーであれば営業TOPをホーム画面とする
      return <Redirect to={'/OrderList'} />;
    } else {
      return <Route {...makeRouteComponentProps(props)} />;
    }
  } else {
    return redirectLogin(props.location?.pathname ?? null, loggedInRegion);
  }
};

const PrivateEmptyRoute: React.FC<RouteProps> = (props) => {
  const { isLoggedIn } = useAuth();
  const {
    state: { loggedInRegion },
  } = useAppContextStore();
  if (isLoggedIn) {
    return <Route {...makeRouteComponentProps(props)} />;
  } else {
    return redirectLogin(props.location?.pathname ?? null, loggedInRegion);
  }
};

/** ログイン画面へリダイレクト */
const redirectLogin = (pathName: string | null, region: Region | null) => {
  //ローカスストレージをクリアしつつログイン画面に遷移
  localStorage.clear();
  switch (region) {
    case Region.Hokkaido:
      return <Redirect to={{ pathname: '/Cyclonist2GBRNHK', state: { from: pathName } }} />;
    case Region.Kyushu:
      return <Redirect to={{ pathname: '/Cyclonist2GBRN9', state: { from: pathName } }} />;
    default:
      return <Redirect to={{ pathname: '/', state: { from: pathName } }} />;
  }
};

const App: React.FC = () => {
  LicenseInfo.setLicenseKey(
    'bec670ef0967fd941be326e63b83f90cT1JERVI6Mzg1NzksRVhQSVJZPTE2NzczODIzMTkwMDAsS0VZVkVSU0lPTj0x',
  );

  // メッセージ削除（URLが変わった場合の共通処理）
  const location = useLocation();
  const [referer, setReferer] = useState(location.pathname);
  const messageApi = useSharedMessage();
  const { session } = useAuth();
  useEffect(() => {
    if (location.pathname !== referer && messageApi.messages.length > 0) {
      messageApi.clear();
    }
    setReferer(location.pathname);
  });

  // ログイン情報からログイン状態を取得

  const title = (() => {
    const baseTitle = 'Cyclonist2';
    switch (session?.region) {
      case Region.Kyushu:
        return `${baseTitle}${'（九州）'}`;
      case Region.Hokkaido:
        return `${baseTitle}${'（北海道）'}`;
      default:
        return `${baseTitle}`;
    }
  })();

  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Switch>
        <Route exact path={entryRoutes.map((e) => e.path)}>
          <EmptyLayout>
            <Switch>
              {entryRoutes.map((route, index) => (
                <EntryRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
            </Switch>
          </EmptyLayout>
        </Route>
        <Route exact path={publicRoutes.map((e) => e.path)}>
          <EmptyLayout>
            <Suspense fallback={<Circular />}>
              <Switch>
                {publicRoutes.map((route, index) => (
                  <PublicRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </Suspense>
          </EmptyLayout>
        </Route>
        <Route exact path={privateRoutes.map((e) => e.path)}>
          <DefaultLayout>
            <Suspense fallback={<Circular />}>
              <Switch>
                {privateRoutes.map((route, index) => (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </Suspense>
          </DefaultLayout>
        </Route>
        <Route exact path={privateEmptyRoutes.map((e) => e.path)}>
          <EmptyLayout>
            <Suspense fallback={<Circular />}>
              <Switch>
                {privateEmptyRoutes.map((route, index) => (
                  <PrivateEmptyRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
                <PrivateEmptyRoute component={NotFound} />
              </Switch>
            </Suspense>
          </EmptyLayout>
        </Route>
        <Route>
          <EmptyLayout>
            <Suspense fallback={<Circular />}>
              <PublicRoute component={NotFound} />
            </Suspense>
          </EmptyLayout>
        </Route>
      </Switch>
    </>
  );
};

export default App;
