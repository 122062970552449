import React from 'react';

import MakingSize from './MakingSize';

/** 製版サイズ 印刷面(メイン) */
const MakingSizeMain: React.VFC = () => {
  return <MakingSize propertyName='makingSizeMain' />;
};

export default React.memo(MakingSizeMain);
