import { SalesPrice, TotalPriceValues } from '../../../types';
import { useOtherProductInfoTotal } from './useOtherProductInfoTotal';

/** 合計情報 Hooks */
export const useTotalPrice = (): TotalPriceValues => {
  /** その他受注製品情報 計*/
  const otherProductInfo: SalesPrice = useOtherProductInfoTotal();

  const prices = [otherProductInfo];

  /** 合計*/
  let totalPrice = 0;
  let totalGrossInvoice = 0;
  for (const price of prices) {
    totalPrice += price.price;
    totalGrossInvoice += price.grossInvoice;
  }

  /** 総合計*/
  const total: SalesPrice = {
    price: totalPrice,
    grossInvoice: totalGrossInvoice,
  };

  return {
    otherProductInfo,
    total,
  };
};
