import React from 'react';

import { HeadLabel } from 'components/parts/atoms';

import { Heading } from '../../Heading';
import OtherCylinderPricePerAreaRow from './OtherCylinderPricePerAreaRow';
import OtherCylinderPriceRow from './OtherCylinderPriceRow';
import { HeadRow } from './Row';

/** その他（シリンダ）コンポーネント */
const OtherCylinderPrice: React.VFC = () => {
  return (
    <>
      <Heading>その他（シリンダ）</Heading>
      <HeadRow>
        <></>
        <HeadLabel>単価</HeadLabel>
        <HeadLabel>表面積</HeadLabel>
        <HeadLabel>版数</HeadLabel>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>東洋売価率</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>

      <OtherCylinderPriceRow label='ヘリオ' propertyName='herio' />
      <OtherCylinderPricePerAreaRow label='外型切削' propertyName='gaikeisessaku' />
      <OtherCylinderPriceRow label='運賃' propertyName='fare' />
    </>
  );
};

export default React.memo(OtherCylinderPrice);
