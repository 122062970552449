import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'themes/theme';

import { Grid } from '@mui/material';

import {
  HeadGrid,
  HeadLabel,
  BodyLabel,
  BodyGrid,
  RowGrid,
  NumericField,
} from 'components/parts/atoms';

import { OtherForm } from '../../types';

const USheet: React.VFC = () => {
  // フォームデータ
  const unitPriceOtherFormContext = useFormContext<OtherForm>();

  return (
    <>
      <RowGrid columns={11}>
        <BodyGrid
          xs={5}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            Uシート
          </BodyLabel>
        </BodyGrid>

        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <HeadGrid xs={2} sx={{ justifyContent: 'center' }}>
          <HeadLabel>売価</HeadLabel>
        </HeadGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>

      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>Uシート</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='usheetUsheet'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ポリネット#600</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='usheetPolyneT600'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ポリネット#800</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='usheetPolyneT800'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ポリネット#1000</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='usheetPolyneT1000'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ﾊ-ﾄﾞﾈｽﾀ-800HV/850HV</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='usheetHardnester800850'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ﾊ-ﾄﾞﾈｽﾀ-900HV/950HV</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='usheetHardnester900950'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ﾊ-ﾄﾞﾈｽﾀ-替芯</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='usheetHardnesterKaeshin'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ダンシート900 x 50m</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dansheet900'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ダンシート1000 x 50m</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dansheet1000'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ダンシート1100 x 50m</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dansheet1100'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ダンシート1200 x 50m</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dansheet1200'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ダンシート1250 x 50m</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dansheet1250'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>ダンシート1300 x 50m</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='dansheet1300'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
      <RowGrid columns={11}>
        <HeadGrid xs={3}>
          <HeadLabel>HGJ-001 EU（ブラックラッカー）</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='blackLacquerhgj001Eu'
            control={unitPriceOtherFormContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={field.value ? (field.value === 0 ? undefined : field.value) : undefined}
                name={field.name}
                min={0}
                max={9999999}
                decimalDigits={0}
              />
            )}
          />
        </BodyGrid>
        <Grid item xs={6}></Grid>
      </RowGrid>
    </>
  );
};

export default USheet;
