import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { useTotalPrice } from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/hooks/price/priceTotal/useTotalPrice';

import { HeadLabel, BodyLabel } from 'components/parts/atoms';

import { FlexoOrderEditForm } from '../../../types';
import { Heading } from '../../Heading';
import { PriceNumericField } from '../Fields';
import { Row, HeadRow } from './Row';

/** 合計コンポーネント */
const TotalPrice: React.VFC = () => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();

  const prices = useTotalPrice();

  return (
    <>
      <Heading>合計</Heading>
      <HeadRow>
        <></>
        <HeadLabel>売価</HeadLabel>
        <HeadLabel>仕切</HeadLabel>
      </HeadRow>
      <Row>
        <HeadLabel>印刷面(メイン)計算 計</HeadLabel>
        <BodyLabel>{prices.mainMaking.price.toLocaleString()}</BodyLabel>
        <BodyLabel>{prices.mainMaking.grossInvoice.toLocaleString()}</BodyLabel>
      </Row>
      <Row>
        <HeadLabel>印刷面(リバース)計算 計</HeadLabel>
        <BodyLabel>{prices.reverseMaking.price.toLocaleString()}</BodyLabel>
        <BodyLabel>{prices.reverseMaking.grossInvoice.toLocaleString()}</BodyLabel>
      </Row>
      <Row>
        <HeadLabel>単価計算 計</HeadLabel>
        <BodyLabel>{prices.unit.price.toLocaleString()}</BodyLabel>
        <BodyLabel>{prices.unit.grossInvoice.toLocaleString()}</BodyLabel>
      </Row>
      <Row>
        <HeadLabel>その他 計</HeadLabel>
        <BodyLabel>{prices.otherImage.price.toLocaleString()}</BodyLabel>
        <BodyLabel>{prices.otherImage.grossInvoice.toLocaleString()}</BodyLabel>
      </Row>
      <Row>
        <HeadLabel>一式計算 計</HeadLabel>
        <BodyLabel>{prices.set.price.toLocaleString()}</BodyLabel>
        <BodyLabel>{prices.set.grossInvoice.toLocaleString()}</BodyLabel>
      </Row>
      <Row>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <HeadLabel>値引き率</HeadLabel>
          <Controller
            name={'price.discount.discountRate'}
            control={control}
            render={({ field }) => (
              <PriceNumericField
                min={0}
                max={100}
                decimalDigits={2}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em', marginLeft: 1 }}
              />
            )}
          />
          <Box sx={{ width: '2em' }}>
            <HeadLabel>%</HeadLabel>
          </Box>
        </Box>
        <BodyLabel>{prices.discountRate.price.toLocaleString()}</BodyLabel>
        <BodyLabel>{prices.discountRate.grossInvoice.toLocaleString()}</BodyLabel>
      </Row>
      <Row>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <HeadLabel>値引き額</HeadLabel>
          <Controller
            name={'price.discount.discountPrice'}
            control={control}
            render={({ field }) => (
              <PriceNumericField
                min={0}
                max={9999999}
                decimalDigits={0}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ width: '6em', marginLeft: 1 }}
              />
            )}
          />
          <Box sx={{ width: '2em' }} />
        </Box>
        <BodyLabel>{`${
          prices.discountPrice.price > 0 ? '- ' : ''
        }${prices.discountPrice.price.toLocaleString()}`}</BodyLabel>
        <BodyLabel>{`${
          prices.discountPrice.grossInvoice > 0 ? '- ' : ''
        }${prices.discountPrice.grossInvoice.toLocaleString()}`}</BodyLabel>
      </Row>
      <Row>
        <HeadLabel>総合計</HeadLabel>
        <BodyLabel>{prices.total.price.toLocaleString()}</BodyLabel>
        <BodyLabel>{prices.total.grossInvoice.toLocaleString()}</BodyLabel>
      </Row>
    </>
  );
};

export default React.memo(TotalPrice);
