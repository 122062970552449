import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './defaults';
import { AuthState } from './types';

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<AuthState>) => action.payload,
    clear: () => ({ ...initialState }),
  },
});

export const { save, clear } = slice.actions;

export default slice.reducer;
