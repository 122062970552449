import { FaceDiv, printColorMap } from 'constants/define';
import React, { useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import { AddCircle } from '@mui/icons-material';
import { Box, styled } from '@mui/material';

import {
  FlexoOrderEditForm,
  FlexoMaking,
  PageState,
} from 'pages/topSales/order/FlexoOrder/FlexoOrderEdit/types';

import { RowGrid, HeadGrid, BodyGrid as BodyGridOrigin, HeadLabel } from 'components/parts/atoms';

import { isValidFlexoMaking } from '../../utils/utils';

import { useFlexoOrderEditMaster } from '../../hooks/useFlexoOrderEditMaster';
import { useId } from '../../hooks/useId';
import { usePageContext } from '../../hooks/usePageContext';
import {
  EditCheckbox,
  EditIconButton,
  EditKanaField,
  EditNumericField,
  EditSelect,
  ShowOnEdit,
} from '../Fields';
import {
  MakingCheckbox,
  MakingIconButton,
  MakingKanaField,
  MakingNumericField,
  MakingSelect,
} from './Field';

const BodyGrid = styled(BodyGridOrigin)(() => ({
  justifyContent: 'center',
}));

/**
 * 行に入力があるか
 */
function isValidItem(item: FlexoMaking): boolean {
  return isValidFlexoMaking(item);
}

type PropertyName = 'flexoMakingsMain' | 'flexoMakingsReverse';

const PrintSideRow: React.VFC<{
  propertyName: PropertyName;
  index: number;
  onDeleteButtonClick: (index: number) => void;
}> = ({ propertyName, index, onDeleteButtonClick }) => {
  const { control, setValue } = useFormContext<FlexoOrderEditForm>();
  const { master } = useFlexoOrderEditMaster();

  /** 全角数字を半角数字に変換 */
  const convertToHalfWidth = (value: string): string => {
    const hankakuValue = value.replace(/[０-９．。ー]/g, (s) => {
      switch (s) {
        case '０':
          return '0';
        case '１':
          return '1';
        case '２':
          return '2';
        case '３':
          return '3';
        case '４':
          return '4';
        case '５':
          return '5';
        case '６':
          return '6';
        case '７':
          return '7';
        case '８':
          return '8';
        case '９':
          return '9';
        case '．':
          return '.';
        case '。':
          return '.';
        case 'ー':
          return '-';
        default:
          return s;
      }
    });

    return hankakuValue;
  };

  /** 刷色No変更時 */
  const onPrintColorChange = useCallback(
    (index: number, value: string) => {
      if (!value) return;

      const asciiVal = convertToHalfWidth(value);
      const sanitizedValue = asciiVal.replace(/[^\d.-]|(\.(?=.*\.))|(-(?=.*-))/g, '');
      const numVal = parseInt(sanitizedValue);

      if (isNaN(numVal)) return;

      // 刷色Noから刷色をセットする
      const printColor = printColorMap[numVal];
      if (printColor) {
        setValue(`making.${propertyName}.${index}.printColor`, printColor);
      }
    },
    [setValue],
  );

  return (
    <RowGrid columns={100}>
      <BodyGrid xs={9}>
        <Controller
          name={`making.${propertyName}.${index}.plateMakingId`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingSelect
              addEmptyRow={true}
              options={master.plateMakingList}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
            />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={6}>
        <Controller
          name={`making.${propertyName}.${index}.printSequence`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingNumericField
              max={999}
              min={-999}
              decimalDigits={0}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
              labelWidth={'shrink'}
            />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={12}>
        <Box sx={{ display: 'flex' }}>
          <ShowOnEdit>
            <Controller
              name={`making.${propertyName}.${index}.printColorNo`}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <MakingNumericField
                  max={99}
                  min={0}
                  decimalDigits={0}
                  onChange={onChange}
                  value={value}
                  name={name}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    onPrintColorChange(index, e.target.value);
                  }}
                  sx={{ width: '6em' }}
                />
              )}
            />
          </ShowOnEdit>
          <Controller
            name={`making.${propertyName}.${index}.printColor`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingKanaField
                maxLength={10}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Box>
      </BodyGrid>
      <BodyGrid xs={16}>
        <Box sx={{ display: 'flex' }}>
          <Controller
            name={`making.${propertyName}.${index}.colorTipCdId`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingSelect
                addEmptyRow={true}
                options={master.colorTipList}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '5em' }}
              />
            )}
          />
          <Box mx={1} />
          <Controller
            name={`making.${propertyName}.${index}.colorTip`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MakingKanaField
                maxLength={10}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Box>
      </BodyGrid>
      <BodyGrid xs={7.5}>
        <Controller
          name={`making.${propertyName}.${index}.lineCount`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingNumericField
              max={999999}
              min={0}
              decimalDigits={0}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
              labelWidth={'grow'}
            />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={7}>
        <Controller
          name={`making.${propertyName}.${index}.angle`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingNumericField
              max={999.99}
              min={0}
              decimalDigits={2}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
              labelWidth={'grow'}
            />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={6.5}>
        <Controller
          name={`making.${propertyName}.${index}.reliefDepth`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingNumericField
              max={99.99}
              min={0}
              decimalDigits={2}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
              labelWidth={'grow'}
            />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={9}>
        <Controller
          name={`making.${propertyName}.${index}.aniloxLineCount`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingNumericField
              max={999999}
              min={0}
              decimalDigits={2}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
              labelWidth={'grow'}
            />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={3}>
        <Controller
          name={`making.${propertyName}.${index}.awaseBanFlg`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingCheckbox onChange={onChange} checked={value} name={name} />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={20.5} sx={{ justifyContent: 'left' }}>
        <Controller
          name={`making.${propertyName}.${index}.awaseBanNo`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <MakingKanaField
              maxLength={14}
              onChange={onChange}
              value={value}
              name={name}
              sx={{ width: '100%' }}
            />
          )}
        />
      </BodyGrid>
      <BodyGrid xs={3.5}>
        <MakingIconButton onClick={() => onDeleteButtonClick(index)}>
          <Delete />
        </MakingIconButton>
      </BodyGrid>
    </RowGrid>
  );
};

type Props = {
  propertyName: PropertyName;
};

/** 作業予定 印刷面 */
const PrintSide: React.VFC<Props> = ({ propertyName }) => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();
  const { pageState } = usePageContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `making.${propertyName}`,
  });

  // hook
  const { generateId } = useId();

  /** 印刷面の行追加 */
  const onAdd = useCallback(() => {
    const faceDiv = propertyName === 'flexoMakingsMain' ? FaceDiv.Main : FaceDiv.Reverse;
    const addRow: FlexoMaking = {
      flxMakingId: generateId(),
      faceDiv: faceDiv,
      plateMakingId: '',
      printSequence: null,
      printColorNo: null,
      printColor: '',
      colorTipCdId: '',
      colorTip: '',
      lineCount: null,
      angle: null,
      reliefDepth: null,
      aniloxLineCount: null,
      awaseBanFlg: false,
      awaseBanNo: '',
      completeSetCalcUnitPrice: 0,
      completeSetCalcToyoPriceRate: null,
    };

    append(addRow);
  }, [append, generateId]);

  /** 印刷面の行削除 */
  const deleteRow = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <>
      <RowGrid columns={100}>
        <HeadGrid vertical xs={9}>
          <HeadLabel>{'製版区分'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={6}>
          <HeadLabel>{'刷順'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={12}>
          <HeadLabel>{'刷色'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={16}>
          <HeadLabel>{'カラーチップ'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={7.5}>
          <HeadLabel>{'線数'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={7}>
          <HeadLabel>{'角度'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={6.5}>
          <HeadLabel>{'レリーフ深度'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={9}>
          <HeadLabel>{'アニロックス線数'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={3}>
          <HeadLabel>{'合版'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={20.5}>
          <HeadLabel>{'合版No.／各色指示'}</HeadLabel>
        </HeadGrid>
        <HeadGrid vertical xs={3.5}>
          <MakingIconButton onClick={() => onAdd()}>
            <AddCircle />
          </MakingIconButton>
        </HeadGrid>
      </RowGrid>
      {fields.map((item, index) => {
        // 確認画面では入力されていない項目を表示しない
        if (pageState === PageState.Check && !isValidItem(item)) {
          return null;
        }
        return (
          <PrintSideRow
            key={item.id}
            propertyName={propertyName}
            index={index}
            onDeleteButtonClick={deleteRow}
          />
        );
      })}
    </>
  );
};

export default React.memo(PrintSide);
