import React, { useState, useContext } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import theme from 'themes/theme';

import { Box, Grid, SelectChangeEvent } from '@mui/material';

import CustomerSearch from 'pages/common/CustomerSearch';

import {
  Select,
  Checkbox,
  HeadLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  TextField,
  SearchButton,
  AddButton,
  DeleteButton,
  NumericField,
  KanaField,
  BodyLabel,
  AlphaNumericField,
} from 'components/parts/atoms';

import { getSelectText } from 'utils/selectUtils';

import { CustomerEditContext } from '../..';
import { CustomerEdit } from '../../types';

const CustomerEditPayment: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();
  const data = useContext(CustomerEditContext);
  const master = data.selectBoxData;

  /** 締日のデータ変更 */
  // ステート
  const closeDateArray = useFieldArray({
    control: formContext.control,
    name: 'closeDates',
  });
  const [closeDate, setCloseDate] = useState<string>('');
  // セレクトボックスで選択
  const onSelectCloseDate = (event: SelectChangeEvent<unknown>) => {
    setCloseDate(String(event.target.value));
  };
  // 追加ボタンによる追加処理
  const onAddCloseDate = () => {
    if (
      closeDateArray.fields.some((x) => x.closeDateId === Number(closeDate)) === false &&
      closeDate !== ''
    ) {
      closeDateArray.append({
        closeDateId: Number(closeDate),
        closeDateExplanation: getSelectText(closeDate, master.closeDates),
      });
      setCloseDate('');
    }
  };
  // 削除ボタンによる削除処理
  const onDeleteCloseDate = (index: number) => {
    closeDateArray.remove(index);
  };

  // 得意先検索
  const [openCustomerSearch, setOpenCustomerSearch] = React.useState(false);
  const [searchType, setSearchType] = useState(0);
  const renderDialogs = (): JSX.Element => {
    return (
      <>
        <CustomerSearch
          open={openCustomerSearch}
          onClose={(value) => {
            setOpenCustomerSearch(false);
            if (value) {
              if (searchType === 0) {
                formContext.setValue(
                  'customerEditSingleInfo.demandSectionCd',
                  value.customerSectionCd,
                );
                formContext.setValue('customerEditSingleInfo.demandCd', value.customerCd);
                formContext.setValue('customerEditSingleInfo.demandName', value.customerName);
                formContext.setValue('customerEditSingleInfo.demandId', value.customerId);
              } else {
                formContext.setValue(
                  'customerEditSingleInfo.supplierSectionCd',
                  value.customerSectionCd,
                );
                formContext.setValue('customerEditSingleInfo.supplierCd', value.customerCd);
                formContext.setValue(
                  'customerEditSingleInfo.toyoInkoyoInkSupplierId',
                  value.customerId,
                );
              }
            }
          }}
        />
      </>
    );
  };

  return (
    <>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel>締日</BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>締日</HeadLabel>
        </HeadGrid>
        <Grid item xs={10}>
          <RowGrid columns={10}>
            <BodyGrid xs={8}>
              <Select
                options={master.closeDates}
                onChange={onSelectCloseDate}
                value={closeDate}
                addEmptyRow={true}
                sx={{ minWidth: '10rem' }}
              />
            </BodyGrid>
            <BodyGrid xs={2} sx={{ justifyContent: 'center' }}>
              <AddButton onClick={() => onAddCloseDate()}></AddButton>
            </BodyGrid>
          </RowGrid>
          {closeDateArray.fields.map((item, index) => {
            return (
              <div key={item.id}>
                <RowGrid columns={10}>
                  <BodyGrid xs={8}>
                    <Controller
                      name={`closeDates.${index}.closeDateExplanation`}
                      control={formContext.control}
                      render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                    />
                  </BodyGrid>
                  <BodyGrid xs={2} sx={{ justifyContent: 'center' }}>
                    <DeleteButton onClick={() => onDeleteCloseDate(index)}></DeleteButton>
                  </BodyGrid>
                </RowGrid>
              </div>
            );
          })}
        </Grid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書用締日</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={10}>
          <Controller
            name={`customerEditSingleInfo.stateOfDeliveryCloseDate`}
            control={formContext.control}
            render={({ field }) => (
              <>
                <NumericField
                  onChange={field.onChange}
                  value={field.value === 0 ? null : field.value}
                  name={field.name}
                  decimalDigits={0}
                  min={1}
                  max={99}
                  sx={{ maxWidth: '5rem' }}
                />
                <Box m={1} />
                <BodyLabel>日締</BodyLabel>
              </>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            入金情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>入金条件</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.paymentConditionCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.paymentAndLimitOvers}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>相殺区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.offsetDivision`}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <BodyLabel>相殺あり</BodyLabel>
                <Checkbox onChange={onChange} value={value} name={name} checked={value} />
              </>
            )}
          />
        </BodyGrid>

        <HeadGrid xs={2}>
          <HeadLabel>現金限度額</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.cashLimit`}
            control={formContext.control}
            render={({ field }) => (
              <>
                <NumericField
                  onChange={field.onChange}
                  value={field.value === 0 ? null : field.value}
                  name={field.name}
                  decimalDigits={0}
                  min={0}
                  max={999999999}
                />
                <Box m={1} />
                <BodyLabel>円</BodyLabel>
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>現金限度額を超えた時</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.cashOverPaymentConditionCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.paymentAndLimitOvers}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>

        <HeadGrid xs={2}>
          <HeadLabel>与信限度額</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.creditLimit`}
            control={formContext.control}
            render={({ field }) => (
              <>
                <NumericField
                  onChange={field.onChange}
                  value={field.value === 0 ? null : field.value}
                  name={field.name}
                  decimalDigits={0}
                  min={0}
                  max={999999999}
                />
                <Box m={1} />
                <BodyLabel>円</BodyLabel>
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>手形サイト</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.billSight`}
            control={formContext.control}
            render={({ field }) => (
              <>
                <NumericField
                  onChange={field.onChange}
                  value={field.value === 0 ? null : field.value}
                  name={field.name}
                  decimalDigits={0}
                  min={0}
                  max={999}
                />
                <Box m={1} />
                <BodyLabel>日</BodyLabel>
              </>
            )}
          />
        </BodyGrid>

        <HeadGrid xs={2}>
          <HeadLabel>入金予定日</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.paymentPlanMonthId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.paymentDates}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '5rem' }}
              />
            )}
          />
          <Box m={1} />
          <Controller
            name={`customerEditSingleInfo.paymentPlanDate`}
            control={formContext.control}
            render={({ field }) => (
              <>
                <KanaField
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  maxLength={2}
                  sx={{ maxWidth: '5rem' }}
                />
                <Box m={1} />
                <BodyLabel>日</BodyLabel>
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel> </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <HeadLabel> </HeadLabel>
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            請求先情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>請求先コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.demandSectionCd`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={4}
                sx={{ width: '5rem' }}
              />
            )}
          />
          <Box m={1} />
          <BodyLabel>-</BodyLabel>
          <Box m={1} />
          <Controller
            name={`customerEditSingleInfo.demandCd`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={6}
                sx={{ width: '5rem' }}
              />
            )}
          />
          <Box m={1} />
          <SearchButton
            onClick={() => {
              setSearchType(0);
              setOpenCustomerSearch(true);
            }}
          ></SearchButton>
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求先名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.demandName`}
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>請求条件</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.demandCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.invoiceTerms}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求書送付条件</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.billSendConditionCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.invoiceSendTerms}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            取引情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={6}>
          <HeadLabel>銀行コード - 支店コード - 口座番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={6}>
          <Controller
            name={`customerEditSingleInfo.tradeBankCd`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={4}
                sx={{ width: '5rem' }}
              />
            )}
          />
          <Box m={1} />
          <BodyLabel>-</BodyLabel>
          <Box m={1} />
          <Controller
            name={`customerEditSingleInfo.tradeBankBranchCd`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={3}
                sx={{ width: '5rem' }}
              />
            )}
          />
          <Box m={1} />
          <BodyLabel>-</BodyLabel>
          <Box m={1} />
          <Controller
            name={`customerEditSingleInfo.tradeBankAccountCd`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={7}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>取引口座名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.tradeAccountName`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={15}
                sx={{ minWidth: '15rem' }}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>取引振込方法</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.tradeTransFerCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.transactionTransfers}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                addEmptyRow={true}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            消費税情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>計算区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.taxCalcCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.calAndInvoiceOutputs}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>金額端数処理</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.taxAmountFractionProcessCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.amountFractions}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書出力方法</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.taxDeliveryOutputCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.deliveryShips}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求書出力方法</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.taxBillOutputCdId`}
            control={formContext.control}
            render={({ field }) => (
              <Select
                options={master.calAndInvoiceOutputs}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                sx={{ minWidth: '10rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            得意先指定伝票情報
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.customerAppointSlipDeliveryFlg`}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <BodyLabel>あり </BodyLabel>
                <Checkbox onChange={onChange} value={value} name={name} checked={value} />
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>請求書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.customerAppointSlipBillFlg`}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <BodyLabel>あり </BodyLabel>
                <Checkbox onChange={onChange} value={value} name={name} checked={value} />
              </>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <Box m={2} />
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            東洋インキ向け専用情報（東洋インキのみ入力してください）
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>仕入伝票</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.toyoInkStockingSlipFlg`}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <BodyLabel>あり </BodyLabel>
                <Checkbox onChange={onChange} value={value} name={name} checked={value} />
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>製版納品明細書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.toyoInkDeliveryDetailsFlg`}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <BodyLabel>あり </BodyLabel>
                <Checkbox onChange={onChange} value={value} name={name} checked={value} />
              </>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>納品書</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.toyoInkDeliveryFlg`}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <BodyLabel>あり </BodyLabel>
                <Checkbox onChange={onChange} value={value} name={name} checked={value} />
              </>
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>製版計算区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.toyoInkCylmakingStateFlg`}
            control={formContext.control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <BodyLabel>あり </BodyLabel>
                <Checkbox onChange={onChange} value={value} name={name} checked={value} />
              </>
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>最終得意先名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.toyoInkFinalCustomerName`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={20}
                sx={{ minWidth: '16rem' }}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>仕入先コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`customerEditSingleInfo.supplierSectionCd`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={4}
                sx={{ width: '5rem' }}
              />
            )}
          />
          <Box m={1} />
          <BodyLabel>-</BodyLabel>
          <Box m={1} />
          <Controller
            name={`customerEditSingleInfo.supplierCd`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={6}
                sx={{ width: '5rem' }}
              />
            )}
          />
          <Box m={1} />
          <SearchButton
            onClick={() => {
              setSearchType(1);
              setOpenCustomerSearch(true);
            }}
          ></SearchButton>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>東洋売価率</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={10}>
          <Controller
            name={`customerEditSingleInfo.toyoPriceRate`}
            control={formContext.control}
            render={({ field }) => (
              <NumericField
                onChange={field.onChange}
                value={
                  field.value === 0 || field.value == null
                    ? null
                    : Math.round(field.value * 1000) / 1000
                }
                name={field.name}
                decimalDigits={3}
                min={0.001}
                max={9.999}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={1}>
        <BodyGrid
          xs={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <BodyLabel
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            GoogleドライブURL
          </BodyLabel>
        </BodyGrid>
      </RowGrid>
      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>URL名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`url.urlName`}
            control={formContext.control}
            render={({ field }) => (
              <KanaField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={250}
                sx={{ minWidth: '16rem' }}
              />
            )}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>URL</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name={`url.url`}
            control={formContext.control}
            render={({ field }) => (
              <AlphaNumericField
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                maxLength={250}
                sx={{ minWidth: '16rem' }}
              />
            )}
          />
        </BodyGrid>
      </RowGrid>
      {renderDialogs()}
    </>
  );
};

export default CustomerEditPayment;
