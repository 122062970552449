import React, { useState, ReactNodeArray } from 'react';

import { Download } from '@mui/icons-material';
import { Box, ButtonBase, Grid, styled } from '@mui/material';

import {
  HeadGrid,
  BodyGrid,
  RowGrid,
  HeadLabel,
  BodyLabel,
  IconButton,
} from 'components/parts/atoms';
import { ContentsGrid } from 'components/parts/molecules';
import { OpenCloseHeading } from 'components/parts/molecules/OpenCloseHeading';

import * as date from 'utils/date';

const Row: React.FC<{ children: ReactNodeArray }> = ({
  children: [head1, body1, head2, body2],
}) => {
  return (
    <>
      <RowGrid>
        <ContentsGrid hxs={1.5} bxs={8}>
          {head1}
          {body1}
        </ContentsGrid>
        <ContentsGrid hxs={1} bxs={1.5}>
          {head2}
          {body2}
        </ContentsGrid>
      </RowGrid>
    </>
  );
};

const StyledImg = styled('img')(() => ({}));

export type ImageDetailProps = {
  image: {
    imageDataId: number;
    fileName: string;
    folder: string;
    fileComment: string;
    fileTypeName: string;
    registerDate: Date | null;
    updateDate: Date | null;
    registerName: string;
    fileSize: number | null;
    thumnailFileNameL: string;
  } | null;
  downloadable: boolean;
  thumbnailClickable: boolean;
  onDownloadlClicked?: (imageDataId: number) => void;
  onThumbnailClicked?: (imageDataId: number) => void;
};

/**
 * 画像情報コンポーネント
 */
const ImageDetail: React.VFC<ImageDetailProps> = (props) => {
  const [displayFlg, setDisplayFlg] = useState<boolean>(true);

  // 詳細表示イベント
  const toggleDisplay = () => {
    setDisplayFlg((prev) => !prev);
  };

  const displayImageData: ImageDetailProps['image'] = props.image ?? {
    imageDataId: -1,
    fileName: '',
    folder: '',
    fileComment: '',
    fileTypeName: '',
    registerDate: null,
    updateDate: null,
    registerName: '',
    fileSize: null,
    thumnailFileNameL: '',
  };

  return (
    <Box>
      <OpenCloseHeading
        onClose={toggleDisplay}
        buttonText={displayFlg ? 'CLOSE' : 'OPEN'}
        displayFlg={displayFlg}
      >
        {`DETAIL：${displayImageData.fileName}`}
      </OpenCloseHeading>
      <Box my={2} />
      {displayFlg && (
        <>
          <Grid container>
            <HeadGrid
              xs={2.5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ButtonBase
                onClick={() => {
                  props.onThumbnailClicked?.(displayImageData.imageDataId);
                }}
                disabled={!props.thumbnailClickable}
                sx={{ width: '100%', height: '100%' }}
              >
                <StyledImg src={`${displayImageData.thumnailFileNameL}`} width={200} height={200} />
              </ButtonBase>
            </HeadGrid>
            <Grid item container xs={9.5}>
              <Row>
                <HeadLabel>フォルダ</HeadLabel>
                <BodyLabel>{displayImageData.folder}</BodyLabel>
                <></>
                <></>
              </Row>
              <Row>
                <HeadLabel>ファイル名</HeadLabel>
                <BodyLabel>{displayImageData.fileName}</BodyLabel>
                <></>
                <></>
              </Row>
              <Row>
                <HeadLabel>ファイルコメント</HeadLabel>
                <BodyLabel>{displayImageData.fileComment}</BodyLabel>
                <></>
                <></>
              </Row>
              <Row>
                <HeadLabel>タイプ</HeadLabel>
                <BodyLabel>{displayImageData.fileTypeName}</BodyLabel>
                <HeadLabel>登録日</HeadLabel>
                <BodyLabel>{date.dateText(displayImageData.registerDate)}</BodyLabel>
              </Row>
              <Row>
                <></>
                <></>
                <HeadLabel>修正日</HeadLabel>
                <BodyLabel>{date.dateText(displayImageData.updateDate)}</BodyLabel>
              </Row>
              <Row>
                <></>
                <></>
                <HeadLabel>登録者</HeadLabel>
                <BodyLabel>{displayImageData.registerName ?? ''}</BodyLabel>
              </Row>
              <Row>
                <HeadLabel>容量</HeadLabel>
                <BodyLabel>{displayImageData.fileSize?.toFixed(2) ?? ''}MB</BodyLabel>
                <></>
                <></>
              </Row>
              {props.downloadable && (
                <Grid item container xs={12}>
                  <BodyGrid xs={12}>
                    <IconButton>
                      <Download
                        onClick={() => {
                          props.onDownloadlClicked?.(displayImageData.imageDataId);
                        }}
                      />
                    </IconButton>
                  </BodyGrid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ImageDetail;
