import useApiClient from 'hooks/useApiClient';
import useSharedMessage from 'hooks/useSharedMessage';
import { useState, useCallback } from 'react';

import {
  ImageInfoResponse,
  OtherWorkProcessResponseModel,
} from 'api/types/topEngraving/engravingData';
import { ImageWorkProcessTypes } from 'constants/define';

/**
 * 彫刻データ設定値入力画面用カスタムフック
 * @returns
 */
export const useFetchImageInfo = (): {
  imageInfoState: ImageInfoResponse;
  getImageInfoState: (jobId: number) => void;
  isWorkProcess: (
    otherWorkProcessList: OtherWorkProcessResponseModel[],
    workProcessType: number,
  ) => boolean;
  getWorkReportId: (
    otherWorkProcessList: OtherWorkProcessResponseModel[],
  ) => number;
} => {
  const [imageInfoState, setImageInfoState] = useState<ImageInfoResponse>(null);

  const { pushMessages } = useSharedMessage();
  const { apiClient } = useApiClient(pushMessages);

  const endpoint = '/api/v1/EngravingDataEdit/GetImageInfo';

  /**
   * 指定のJOBIDに紐づく画像情報を取得する
   */
  const getImageInfoState = useCallback((jobId: number) => {
    (async () => {
      try {
        const [res] = await Promise.all([
          apiClient.get<ImageInfoResponse>(endpoint, {
            params: { jobId },
          }),
        ]);

        if (res.data.result) {
          setImageInfoState(res.data.result);
        }
      } catch (e) {
        console.log(e);
        return;
      }
    })();
  }, []);

  /**
   * その他工程作業記録の各工程をリンクにするかどうかを返す
   */
  const isWorkProcess = (
    otherWorkProcessList: OtherWorkProcessResponseModel[],
    workProcessType: number,
  ) => {
    if (otherWorkProcessList != null) {
      const isWorkProcess = otherWorkProcessList.find(
        (workProccess) => workProccess.workProcessType === workProcessType,
      );
      return isWorkProcess ? true : false;
    } else {
      return false;
    }
  };

  /**
   *  workReportIdの取得
  */
  const getWorkReportId = (
    otherWorkProcessList: OtherWorkProcessResponseModel[],
  ) => {
    if (otherWorkProcessList != null) {
      const isWorkProcess = otherWorkProcessList.find(
        (workProccess) => workProccess.workProcessType === ImageWorkProcessTypes.presentItemWithProof || workProccess.workProcessType === ImageWorkProcessTypes.presentItemNoProof || workProccess.workProcessType === ImageWorkProcessTypes.PresentItem,
      );
      return isWorkProcess?.workReportId ?? 0;
    } else {
      return 0;
    }
  }

  return {
    getImageInfoState,
    imageInfoState,
    isWorkProcess,
    getWorkReportId,
  };
};
