import { useFormContext, useWatch } from 'react-hook-form';

import { SalesPrice, TotalPriceValues } from '../../../types';
import { FlexoOrderEditForm } from '../../../types';
import { usePriceCalculateMatter } from '../usePriceCalculateMatter';
import { usePriceCalculater } from '../usePriceCalculater';
import { useMainMakingPriceTotal } from './useMainMakingPriceTotal';
import { useOtherImagePriceTotal } from './useOtherImagePriceTotal';
import { useReverseMakingPriceTotal } from './useReverseMakingPriceTotal';
import { useSetPriceTotal } from './useSetPriceTotal';
import { useUnitPriceTotal } from './useUnitPriceTotal';

/** 合計情報 Hooks */
export const useTotalPrice = (): TotalPriceValues => {
  // コンテキスト
  const { control } = useFormContext<FlexoOrderEditForm>();

  const { toyoPriceRate } = usePriceCalculateMatter();
  const { calcDiscountRate, calcGrossInvoice } = usePriceCalculater();

  const [discountRateValue, discountPriceValue] = useWatch({
    control,
    name: ['price.discount.discountRate', 'price.discount.discountPrice'],
  });

  /** 印刷面(メイン)計算 計*/
  const mainMaking: SalesPrice = useMainMakingPriceTotal();
  /** 印刷面(リバース)計算 計*/
  const reverseMaking: SalesPrice = useReverseMakingPriceTotal();
  /** 単価計算 計*/
  const unit: SalesPrice = useUnitPriceTotal();
  /** その他（画像） 計*/
  const otherImage: SalesPrice = useOtherImagePriceTotal();
  /** 一式計算 計*/
  const set: SalesPrice = useSetPriceTotal();

  const prices = [mainMaking, reverseMaking, unit, otherImage, set];

  /** 合計*/
  let totalPrice = 0;
  let totalGrossInvoice = 0;
  for (const price of prices) {
    totalPrice += price.price;
    totalGrossInvoice += price.grossInvoice;
  }

  /** 値引き率 計*/
  const discountRate: SalesPrice = {
    price: calcDiscountRate(totalPrice, discountRateValue),
    grossInvoice: calcDiscountRate(totalGrossInvoice, discountRateValue),
  };

  /** 値引き 計*/
  const discountPrice: SalesPrice = {
    price: discountPriceValue ?? 0,
    grossInvoice: calcGrossInvoice(discountPriceValue, toyoPriceRate),
  };

  /** 総合計*/
  const total: SalesPrice = {
    price: discountRate.price - discountPrice.price,
    grossInvoice: discountRate.grossInvoice - discountPrice.grossInvoice,
  };

  return {
    mainMaking,
    reverseMaking,
    unit,
    otherImage,
    set,
    discountRate,
    discountPrice,
    total,
  };
};
